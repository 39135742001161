import React from 'react'
import { Heading2, Page, Text } from '../../../components/layout'

interface Props {
  message: string
}

export const ShiftErrorPage: React.FunctionComponent<Props> = ({ message }) => {
  return (
    <Page>
      <Heading2>Vuorot</Heading2>
      <Text>{message}</Text>
    </Page>
  )
}
