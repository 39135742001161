export enum ScalarType {
  SHORTDATE = 'SHORTDATE',
  DATETIME = 'DATETIME',
  TIME = 'TIME',
  BUSINESS_ID = 'BUSINESS_ID',
  PERSONAL_IDENTITY_CODE = 'PERSONAL_IDENTITY_CODE',
  CUSTOMER_IDENTIFIER = 'CUSTOMER_IDENTIFIER',
  POSTAL_CODE = 'POSTAL_CODE',
  PHONE = 'PHONE',
  EMAIL = 'EMAIL',
  MUNICIPALITY = 'MUNICIPALITY',
  JOB_DETAILS_SERVICE_TYPE = 'JOB_DETAILS_SERVICE_TYPE',
}

export interface ScalarDate
  extends Readonly<{
    type: ScalarType.SHORTDATE
    value: string
  }> {}

export interface ScalarDateTime
  extends Readonly<{
    type: ScalarType.DATETIME
    value: string
  }> {}

export interface ScalarTime
  extends Readonly<{
    type: ScalarType.TIME
    value: string
  }> {}

export interface ScalarBusinessID
  extends Readonly<{
    type: ScalarType.BUSINESS_ID
    value: string
  }> {}

export interface ScalarPersonalIdentityCode
  extends Readonly<{
    type: ScalarType.PERSONAL_IDENTITY_CODE
    value: string
  }> {}

export interface ScalarCustomerIdentifier
  extends Readonly<{
    type: ScalarType.CUSTOMER_IDENTIFIER
    value: string
  }> {}

export interface ScalarPostalCode
  extends Readonly<{
    type: ScalarType.POSTAL_CODE
    value: string
  }> {}

export interface ScalarPhone
  extends Readonly<{
    type: ScalarType.PHONE
    value: string
  }> {}

export interface ScalarEmail
  extends Readonly<{
    type: ScalarType.EMAIL
    value: string
  }> {}

export interface ScalarMunicipality
  extends Readonly<{
    type: ScalarType.MUNICIPALITY
    value: string
  }> {}
