import { Field } from 'formik'
import React, { useCallback } from 'react'
import { PasswordField } from '../../field'

interface Props {
  name: string
  label?: string
  placeholder?: string
  required?: boolean
}

export const PasswordInput: React.FunctionComponent<Props> = ({ name, label, placeholder, required }) => {
  const validate = useCallback(
    (value: string | null): string | undefined => {
      if (required && (!value || !value.trim())) {
        return 'Kenttä on pakollinen'
      }
      return undefined
    },
    [required]
  )

  return (
    <Field
      component={PasswordField}
      id={name}
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      validate={validate}
    />
  )
}
