import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { Row } from '../../..'
import { Coords, Maybe } from '../../../../../common/types'
import { StateStore } from '../../../../../containers/StoreProvider'
import { GeoLocateAddressData } from '../../../../../containers/TowingRecordPage/components/routes/types'
import { Button } from '../../../../Button'
import { getFormData, GMapModal } from '../../../../Map'
import { GetValueType, MapData, MapType } from '../../../../Map/types'
import { ReverseGeocodeButton } from '../../../../ReverseGeocodeButton'
import { LocationInputMapView } from './types'
import { LocationInputWrapperWithComposition } from './LocationInputWrapperWithComposition'

export interface LocationInputMapProps {
  addressLabel: string
  addressName: string
  cityName: string
  cityLabel: string
  coordsName: string
  zipcodeName: string
  descriptionName?: string
  values: MapData
  required?: boolean
  disabled?: boolean
  getValue?: GetValueType
  setValue: (field: string, value: any) => void
  reverse?: boolean
  coordsRequired?: boolean
  includeNameInAddress?: boolean
  setRouteLocation?: (addressData: GeoLocateAddressData | null) => void
  stationId: Maybe<number>
  clear?: () => void
  children?: React.ReactNode
}

export const LocationInputMapWithComposition: React.FunctionComponent<LocationInputMapProps> = ({
  addressLabel,
  addressName,
  cityLabel,
  cityName,
  coordsName,
  zipcodeName,
  descriptionName,
  required,
  disabled,
  getValue,
  values,
  setValue,
  reverse,
  coordsRequired,
  setRouteLocation,
  stationId,
  includeNameInAddress = true,
  clear,
  children,
}) => {
  const [showView, setShowView] = useState<LocationInputMapView>(LocationInputMapView.INPUT)
  const store = useContext(StateStore)
  const stations = store.state.settings && store.state.settings.stations ? store.state.settings.stations : []

  const station = stations.find(s => s.id === stationId)

  return (
    <>
      {showView === LocationInputMapView.INPUT && (
        <>
          {!disabled && reverse && (
            <Row alignItems="start">
              <MapButtonWrapper>
                <Button category="info" label="Kartta" size="s" onClick={() => setShowView(LocationInputMapView.MAP)} />
              </MapButtonWrapper>
            </Row>
          )}
          <LocationInputWrapperWithComposition
            {...{
              addressLabel,
              addressName,
              cityLabel,
              cityName,
              coordsName,
              zipcodeName,
              descriptionName,
              required,
              disabled,
              getValue,
              values,
              setValue,
              reverse,
              coordsRequired,
              includeNameInAddress,
              stationId,
              clear,
            }}
          >
            {children}
          </LocationInputWrapperWithComposition>
          {!disabled && !reverse && (
            <Row alignItems="start" justify="start" direction="row" marginMobile="0">
              <MapButtonWrapper>
                <Button category="info" label="Kartta" size="s" onClick={() => setShowView(LocationInputMapView.MAP)} />
              </MapButtonWrapper>
              <MapButtonWrapper>
                <ReverseGeocodeButton
                  onReverseGeocode={result => {
                    if (setRouteLocation) {
                      setRouteLocation(result)
                    }
                  }}
                  category={'info'}
                  useStyle={false}
                  size="s"
                />
              </MapButtonWrapper>
            </Row>
          )}
        </>
      )}

      {showView === LocationInputMapView.MAP && (
        <>
          <GMapModal
            type={MapType.FORM}
            onClose={() => {
              setShowView(LocationInputMapView.INPUT)
            }}
            formData={getFormData(addressName, cityName, coordsName, zipcodeName)}
            getValue={getValue}
            originalValues={values}
            setValue={setValue}
            includeNameInAddress={includeNameInAddress}
            station={station}
          />
        </>
      )}
    </>
  )
}

const MapButtonWrapper = styled.div`
  margin-top: 1rem;
  margin-right: 1rem;
`

export function toMapData(
  address: Maybe<string>,
  city: Maybe<string>,
  coords: Coords,
  zipcode: Maybe<string>
): MapData {
  return {
    address,
    city,
    coords,
    zipcode,
  }
}
