import React from 'react'
import { EditCompanyCustomerPage } from './edit-company-customer'
import { EditPersonCustomerPage } from './edit-person-customer'
import { CustomerFormValues } from './types'
import { companyToOrderer, personToOrderer } from './CustomerFormFields/customerToOrderer'

interface EditCustomerProps {
  orderer: CustomerFormValues
  onClose: () => void
  onUpdate: (customer: CustomerFormValues) => void
}

export const EditCustomer: React.FunctionComponent<EditCustomerProps> = ({ orderer, onClose, onUpdate }) => {
  if (!orderer || !orderer.id || !orderer.type) {
    return null
  }

  return (
    <>
      {orderer.companyCustomer && orderer.type === 'company' && (
        <EditCompanyCustomerPage
          id={orderer.id}
          companyCustomer={orderer.companyCustomer}
          onCancelForm={onClose}
          onCloseForm={onCloseCompanyCustomerForm => onUpdate(companyToOrderer(onCloseCompanyCustomerForm))}
        />
      )}
      {orderer.personCustomer && orderer.type === 'person' && (
        <EditPersonCustomerPage
          id={orderer.id}
          personCustomer={orderer.personCustomer}
          onCancelForm={onClose}
          onCloseForm={onClosePersonCustomerForm => onUpdate(personToOrderer(onClosePersonCustomerForm))}
        />
      )}
    </>
  )
}
