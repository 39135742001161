import { Formik } from 'formik'
import React, { useContext } from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { customerNotificationOptions } from '../../../common/options'

import { Button } from '../../../components/Button'
import {
  ButtonColumn,
  ButtonRow,
  FormSubGroup,
  FormSubGroupHeader,
  FormSubGroupHeading,
  FormSubGroupWrapper,
  FormSubGroupsList,
  Section,
  SectionColumn,
} from '../../../components/layout'
import {
  EmailInput,
  PersonalIdentityCodeInput,
  PhoneInput,
  SelectInput,
  TextAreaInput,
  TextInput,
} from '../../../components/layout/form/input'
import { ScrollToError } from '../../../components/layout/form/ScrollToError'
import { FormikForm as Form } from '../../../components/layout/form/style'
import { translateInputOptions } from '../../../util/translateInputOptions'
import { StateStore } from '../../StoreProvider'
import { acceptedRoles } from '../../TowingOrderPage/AddTowingOrder/TowingOrderSettingsForm'
import { deliveryMethodPersonOptions } from '../options'
import { EditPersonResponse, PersonCustomerFormValues } from '../types'
import { EditPersonCustomerResponse } from './types'

interface FormProps {
  editPersonCustomer: (values: PersonCustomerFormValues) => any
  onCloseForm: (t: EditPersonResponse) => void
  onCancelForm: () => void
  initialValues: PersonCustomerFormValues
  loading: boolean
}

const EditPersonCustomerFormIntl: React.FunctionComponent<FormProps & InjectedIntlProps> = ({
  onCancelForm,
  onCloseForm,
  editPersonCustomer,
  initialValues,
  intl,
  loading,
}) => {
  const { formatMessage } = intl
  const translatedDeliveryMethodOptions = translateInputOptions(deliveryMethodPersonOptions, formatMessage)
  const {
    state: { settings, currentUser },
  } = useContext(StateStore)

  const disableSsn = !!initialValues.ssn

  const handleSubmit = async (values: PersonCustomerFormValues) => {
    try {
      const fullResponse = await editPersonCustomer(values)
      const response: EditPersonCustomerResponse = fullResponse.data
      if (response.editPersonCustomer.__typename === 'EditPersonCustomerSuccess') {
        onCloseForm(response.editPersonCustomer)
      } else {
        console.log('Error saving: ', response.editPersonCustomer.__typename)
      }
    } catch (err) {
      console.log('Error updating customer', err)
    }
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: PersonCustomerFormValues) => {
        handleSubmit(values)
      }}
      render={({ values, submitForm }) => {
        return (
          <Form>
            <FormSubGroupsList>
              <FormSubGroupWrapper>
                <FormSubGroupHeader>
                  <FormSubGroupHeading>
                    {`Asiakasrekisteri: ${values.firstName} ${values.lastName}`}
                  </FormSubGroupHeading>
                </FormSubGroupHeader>

                <FormSubGroup>
                  <Section>
                    <SectionColumn>
                      <TextInput label="Asiakastunniste" name="identifier" />
                    </SectionColumn>
                    <SectionColumn>
                      <PersonalIdentityCodeInput label="Henkilötunnus" name="ssn" disabled={disableSsn} />
                    </SectionColumn>
                    <SectionColumn>
                      <TextInput label="Etunimi" name="firstName" />
                    </SectionColumn>
                    <SectionColumn>
                      <TextInput label="Sukunimi" name="lastName" />
                    </SectionColumn>
                    <SectionColumn>
                      <TextInput label="c/o" name="co" />
                    </SectionColumn>
                    <SectionColumn>
                      <TextInput label="Osoite" name="address"></TextInput>
                    </SectionColumn>
                    <SectionColumn>
                      <TextInput label="Postinumero" name="zipcode"></TextInput>
                    </SectionColumn>
                    <SectionColumn>
                      <TextInput label="Toimipaikka" name="city"></TextInput>
                    </SectionColumn>
                    <SectionColumn>
                      <EmailInput label="Sähköposti" name="email" />
                    </SectionColumn>
                    <SectionColumn>
                      <PhoneInput label="Puhelinnumero" name="phone" />
                    </SectionColumn>
                    <SectionColumn>
                      <SelectInput
                        label="Laskun lähetystapa"
                        name="deliveryMethod"
                        options={translatedDeliveryMethodOptions}
                      />
                    </SectionColumn>
                    <SectionColumn>
                      <TextInput label="Lisätieto (laskun osoiteriville)" name="extraInfo" />
                    </SectionColumn>
                    {settings &&
                      settings.notifications.enabled &&
                      currentUser &&
                      acceptedRoles.includes(currentUser.role) && (
                        <SectionColumn>
                          <SelectInput
                            label="Seurantalinkin lähetys"
                            name="notificationMethod"
                            options={customerNotificationOptions}
                            searchable={false}
                          />
                        </SectionColumn>
                      )}
                  </Section>
                  <Section>
                    <SectionColumn>
                      <TextAreaInput label="Muistiinpanot" name="additionalInfo" />
                    </SectionColumn>
                  </Section>
                  <ButtonRow>
                    <ButtonColumn>
                      <Button category="save" label="Tallenna" onClick={() => submitForm()} loading={loading} />
                    </ButtonColumn>
                    <ButtonColumn>
                      <Button category="cancel" label="Sulje" onClick={onCancelForm} />
                    </ButtonColumn>
                  </ButtonRow>
                </FormSubGroup>
              </FormSubGroupWrapper>
            </FormSubGroupsList>
            <ScrollToError />
          </Form>
        )
      }}
    />
  )
}

export const EditPersonCustomerForm = injectIntl(EditPersonCustomerFormIntl)
