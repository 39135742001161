import { Form, Formik } from 'formik'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

import { Button } from '../../components/Button'
import { Column, Heading1, Heading3, Notification, Row, Text } from '../../components/layout'
import { PasswordInput, TextInput } from '../../components/layout/form/input'
import { ScrollToError } from '../../components/layout/form/ScrollToError'

interface LoginFormValues {
  username: string
  password: string
}

interface Props {
  login: (values: LoginFormValues) => void
  loginFailed: boolean
  usernamePlaceholder: string
  passwordPlaceholder: string
  loginLabel: string
}

export const LoginForm: FunctionComponent<Props> = ({
  login,
  loginFailed,
  passwordPlaceholder,
  usernamePlaceholder,
  loginLabel,
}) => {
  return (
    <>
      <Row justify="center">
        <Heading1>InnoHinaus</Heading1>
      </Row>
      <Row justify="center">
        <Heading3>Kuljettajan työlista ja kuormakirja</Heading3>
      </Row>
      <Formik
        initialValues={{ username: '', password: '' }}
        onSubmit={(values: LoginFormValues) => login(values)}
        render={() => (
          <Form onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>
            <LoginFormContainer>
              <LoginRow>
                <Column>
                  <TextInput placeholder={usernamePlaceholder} name={'username'} required />
                </Column>
              </LoginRow>
              <LoginRow>
                <Column>
                  <PasswordInput placeholder={passwordPlaceholder} name="password" required />
                </Column>
              </LoginRow>
              <LoginRow>
                <Column>
                  <Button category="regular" label={loginLabel} submit />
                </Column>
              </LoginRow>
              <LoginRow>
                <Column>
                  {loginFailed && (
                    <Notification type="error">! Kirjautuminen epäonnistui. Tarkista tunnus ja salasana.</Notification>
                  )}
                </Column>
              </LoginRow>
            </LoginFormContainer>
            <ScrollToError />
          </Form>
        )}
      />
      <InfoTextRow>
        <InfoTextColumn>
          <Heading3>Asiakaspalvelu</Heading3>
          <Text>09 4241 2300</Text>
          <Text>asiakaspalvelu@innovoice.fi</Text>
        </InfoTextColumn>
      </InfoTextRow>
    </>
  )
}

const LoginFormContainer = styled.div`
  padding: 1rem;
  margin: auto;
  max-width: 320px;
`

const LoginRow = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 0 1rem 0;
`

const InfoTextRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 2rem;
  width: 100%;
  position: absolute;
  bottom: 2rem;
  left: 0;
  right: 0;
  @media (max-width: ${(props) => props.theme.screenSize.mobile}) {
    flex-direction: column;
    justify-content: flex-start;
  }
`

interface InfoTextColumnProps {
  align?: string
}

const InfoTextColumn = styled.div<InfoTextColumnProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: ${(props) => props.align};
  @media (max-width: ${(props) => props.theme.screenSize.tablet}) {
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
  }
`
