import gql from 'graphql-tag'

export const REVERSE_GEOCODE = gql`
  query ReverseGeocode($location: ReverseGeocodeLocation!) {
    reverseGeocode(location: $location) {
      __typename
      ... on GetReverseGeocodeSuccess {
        result {
          address
          city
          zipcode
        }
      }
    }
  }
`
