import { ConfirmBillingMutationVariables } from './confirmMutation'
import { ConfirmBillingFormValues } from './types'

export function toConfirmBillingMutationVariables(values: ConfirmBillingFormValues): ConfirmBillingMutationVariables {
  return {
    input: {
      towingRecordId: values.towingRecordId,
      sendReceipt: values.sendReceipt === true,
    },
  }
}
