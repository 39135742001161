export const geolocationOptions = {
  enableHighAccuracy: true,
  timeout: 20000,
  maximumAge: 2000,
}

export interface GeolocationPosition {
  coords: {
    latitude: number
    longitude: number
    accuracy: number
    altitude: number | null
    altitudeAccuracy: number | null
    heading: number | null
    speed: number | null
  }
}
