import { cloneDeep } from 'lodash'

import { Maybe } from '../../../../common/types'
import { toTowingOrderInput } from '../../towingOrderInput/toTowingOrderInput'
import { EditTowingOrderFormValues } from '../types'
import { EditTowingOrderMutationVariables } from './types'

export const toMutationVariables = (
  inputValues: EditTowingOrderFormValues,
  operatorId: Maybe<number>
): EditTowingOrderMutationVariables => {
  const values = cloneDeep(inputValues)
  return {
    input: {
      id: values.id,
      towingOrder: toTowingOrderInput(values, operatorId),
    },
  }
}
