import gql from 'graphql-tag'
import { ConfirmBillingInputMutation } from './types'

export interface ConfirmBillingMutationVariables
  extends Readonly<{
    input: ConfirmBillingInputMutation
  }> {}

export const CONFIRM_BILLING = gql`
  mutation ConfirmBilling($input: ConfirmBillingInput!) {
    confirmBilling(input: $input) {
      __typename
      ... on UnauthorizedError {
        error {
          code
          message
        }
      }
      ... on GenericError {
        genericError: error {
          code
          message
        }
      }
      ... on ConfirmBillingSuccess {
        success
      }
    }
  }
`
