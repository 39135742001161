import { Field } from 'formik'
import React, { useCallback } from 'react'
import { DateField } from '../../field'

interface Props {
  name: string
  label?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  className?: string
}

export const DateInput: React.FunctionComponent<Props> = ({
  name,
  label,
  placeholder,
  required,
  disabled,
  ...rest
}) => {
  const validate = useCallback(
    (value: Date | null): string | undefined => {
      if (required && !value) {
        return 'Kenttä on pakollinen'
      }
      return undefined
    },
    [required]
  )
  return (
    <Field
      {...rest}
      component={DateField}
      id={name}
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      validate={validate}
      disabled={disabled}
    />
  )
}
