import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { Button } from '../../../../../components/Button'
import { Icon } from '../../../../../components/icons/Icon'
import { Heading2, Row, SimpleRow } from '../../../../../components/layout'
import { TextInput } from '../../../../../components/layout/form/input/TextInput'
import { Navigate } from '../../../../../components/NavigateTo'

interface RouteHeaderProps {
  label?: string
  regNo?: string
  cancel?: () => void
  marginBottom?: string
  disableEditRegNo?: boolean
  toggleImageModal?: () => void
}
export const RouteHeader: React.FunctionComponent<RouteHeaderProps> = ({
  cancel,
  label,
  regNo,
  marginBottom,
  disableEditRegNo,
  toggleImageModal,
}) => {
  const [editRegNo, setEditRegNo] = useState<boolean>(false)
  const [displayRegNo, setDisplayRegNo] = useState<string | undefined>(regNo)
  const disableEdit = disableEditRegNo || false
  const openEditRegNo = useCallback(() => {
    const el = document.querySelector('#vehicleRegNo')

    if (displayRegNo && el) {
      el.setAttribute('size', ((displayRegNo.length + 1) as unknown) as string)
    }
  }, [displayRegNo])
  return (
    <RouteHeaderContainer marginBottom={marginBottom}>
      <SimpleRow margin="0 0 0 -0.5rem" justify="space-between">
        <Navigate direction="back" label="Takaisin" onClick={cancel} />
        {toggleImageModal && <Button category="camera" onClick={toggleImageModal} buttonWidth="auto" />}
      </SimpleRow>
      {label && (
        <Row justify="center">
          <Heading2>{label}</Heading2>
        </Row>
      )}
      {regNo && (
        <Row justify="center">
          {!editRegNo ? (
            <RegNoWrapper
              onClick={() => {
                if (!disableEdit) {
                  setEditRegNo(true)
                }
              }}
            >
              <Heading2>{displayRegNo}</Heading2>
              {!disableEdit && <Icon icon="edit" size="small" />}
            </RegNoWrapper>
          ) : (
            <EditRegNoWrapper>
              <TextInput
                name="vehicleRegNo"
                toUpperCase={true}
                onBlur={form => {
                  if (form.values.vehicleRegNo) {
                    setDisplayRegNo(form.values.vehicleRegNo)
                  }
                  setEditRegNo(false)
                }}
                onChange={(_, form) => {
                  const id = `#${_.target.getAttribute('id')}`
                  const el = document.querySelector(id)

                  if (form.values.vehicleRegNo && el) {
                    setDisplayRegNo(form.values.vehicleRegNo)
                    el.setAttribute('size', (form.values.vehicleRegNo.length + 1) as string)
                  }
                }}
                afterRender={() => openEditRegNo()}
              />
            </EditRegNoWrapper>
          )}
        </Row>
      )}
    </RouteHeaderContainer>
  )
}

interface RouteHeaderContainerProps {
  marginBottom?: string
}

const RouteHeaderContainer = styled.div<RouteHeaderContainerProps>`
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '1rem')};
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '1rem')};
  }
`

const RegNoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  && > h2 {
    margin-right: 0.2em;
  }
`

const EditRegNoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    // width: 100%;
  }
  && > div > input {
    text-align: center;
  }
`
