import React, { FunctionComponent } from 'react'
import { InjectedIntlProps, defineMessages, injectIntl } from 'react-intl'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { NotificationDelay } from '../../common/types'
import { hasStoredLogin, removeStoredBackendNotifications, storeLogout } from '../../graphql-client/storedState'
import { canSendMessageToWebView, sendMessageToWebView } from '../../util/react-native'
import { WebMessageType } from '../../util/react-native/types'
import { Button } from '../Button'
import { Icon } from '../icons/Icon'
import { Loading } from '../responses'
import { Container, Content, IconWrapper, Message, Title } from './styles'
import { dismissToast } from '.'

const toastId = 'unauthorized'

const messages = defineMessages({
  unauthorizedTitle: {
    id: 'containers.graphql.unauthorized.title',
    defaultMessage: 'Your access token has expired',
  },
  unauthorizedMessage: {
    id: 'containers.graphql.unauthorized.message',
    defaultMessage: 'Please login to obtain new access token',
  },
  unauthorizedButton: {
    id: 'containers.graphql.unauthorized.button',
    defaultMessage: 'Back to login',
  },
})

export const setUnauthorizedNotification = () => {
  if (!toast.isActive(toastId)) {
    removeStoredBackendNotifications()
    toast.error(<UnauthorizedNotification />, {
      toastId,
      autoClose: false,
      closeButton: false,
      delay: 1,
    })
    dismissToast(toastId, NotificationDelay.unauthorizedNotificationDelay)
  }
}

const UnauthorizedNotificationIntl: FunctionComponent<InjectedIntlProps> = ({ intl }) => {
  const { formatMessage } = intl

  if (!hasStoredLogin() && canSendMessageToWebView()) {
    sendMessageToWebView({ type: WebMessageType.LOGIN })
    return <Loading loading={true} />
  }

  return (
    <InfoContainer>
      <IconWrapper>
        <Icon size="big" icon={'lock'} color="blue900" />
      </IconWrapper>
      <Content>
        <Title> {formatMessage(messages.unauthorizedTitle)}</Title>
        <Message>
          {formatMessage(messages.unauthorizedMessage)}

          <Button
            label={formatMessage(messages.unauthorizedButton)}
            category="info"
            onClick={async () => {
              storeLogout()
            }}
          />
        </Message>
      </Content>
    </InfoContainer>
  )
}

export const UnauthorizedNotification = injectIntl(UnauthorizedNotificationIntl)

const InfoContainer = styled(Container)`
  background-color: ${props => props.theme.colors.blue100};
  color: ${props => props.theme.colors.blue900};
  border-bottom: 1px solid ${props => props.theme.colors.blue500};
`
