import { format } from 'date-fns'
import { find } from 'lodash'

import { Time, TowingRecordRouteType } from '../../../../../../common/types'
import { Station } from '../../../../../Parameters/types'
import { createNewRoute } from '../../createNewRoute'
import { TowingRecordWithRoutes } from '../../types'
import { EditEndRouteFormValues, EndRoute } from './types'

export function getInitialValues(
  towingRecord: TowingRecordWithRoutes,
  stations: Station[]
): EditEndRouteFormValues {
  const routes = towingRecord.routes
  const routeFound = find(routes, ['type', TowingRecordRouteType.end])

  const route =
    routeFound && routeFound.type === TowingRecordRouteType.end
      ? ({ ...routeFound } as EndRoute)
      : (createNewRoute(TowingRecordRouteType.end, true) as EndRoute)

  if (!route.arrivalDate) {
    route.arrivalDate = new Date()
  }

  if (!route.arrivalTime) {
    route.arrivalTime = (format(new Date(), 'HH:mm') as unknown) as Time
  }

  if (!route.id && stations && towingRecord.sosServiceOrder && towingRecord.stationId) {
    const stationFound = find(stations, ['id', towingRecord.stationId])
    if (stationFound) {
      route.address = stationFound.address
      route.city = stationFound.city
      route.coords = stationFound.coords
      route.zipcode = stationFound.zipcode
    }
  }

  const defaultToStationDataIfFirstSave = ! route.id && ! towingRecord.sosServiceOrder
  if(stations.length === 1 && defaultToStationDataIfFirstSave) {
    route.address = stations[0].address
    route.city = stations[0].city
    route.coords = stations[0].coords
    route.zipcode = stations[0].zipcode
  }

  return {
    endRoute: route,
    id: towingRecord.id,
    notes: towingRecord.jobDetails.notes,
    vehicleRegNo: towingRecord.vehicleDetails.registrationNumber,
    additionalInfo: towingRecord.sosServiceOrder ? '' : towingRecord.jobDetails.additionalInfo
  }
}