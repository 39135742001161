import React from 'react'
import { ButtonColumn, Heading2, Page, Row, Column, ButtonRow, FlexContainer } from '../../components/layout'
import Logout from '../../components/Logout'
import { JobViewFilters } from '../../components/JobViewFilters'
import styled from 'styled-components'

const HeaderContainer = styled(FlexContainer)`
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    justify-content: space-between;
  }
`

export const Settings: React.FunctionComponent = () => {
  return (
    <Page>
      <Row space="double" paddingMobile="0 0.5rem">
        <Column>
          <HeaderContainer>
            <Heading2>Asetukset</Heading2>
          </HeaderContainer>
        </Column>
        <Column>
          <ButtonRow padding="0">
            <Logout />
          </ButtonRow>
        </Column>
      </Row>
      {/* <Row space="double">
        <Heading2>Asetukset</Heading2>
      </Row>

      {/* <Row>
        <ButtonColumn width="100%">
          <Logout />
        </ButtonColumn>
      </Row> */}
      <Row>
        <ButtonColumn width="100%">
          <JobViewFilters />
        </ButtonColumn>
      </Row>
    </Page>
  )
}
