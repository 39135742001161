export const eInvoiceOperatorOptions = [
  { label: 'Aktia', value: 'Aktia' },
  { label: 'Apix', value: 'Apix' },
  { label: 'Basware', value: 'Basware' },
  { label: 'CGI / TeliaSonera', value: 'CGI / TeliaSonera' },
  { label: 'DNB', value: 'DNB' },
  { label: 'Danske Bank', value: 'Danske Bank' },
  { label: 'Ropo Capital (Enfo Zender)', value: 'Ropo Capital (Enfo Zender)' },
  { label: 'Handelsbanken', value: 'Handelsbanken' },
  { label: 'Liaison', value: 'Liaison' },
  { label: 'Nordea', value: 'Nordea' },
  { label: 'Notebeat', value: 'Notebeat' },
  { label: 'OpusCapita Group / Itella', value: 'OpusCapita Group / Itella' },
  { label: 'OpusCapita Solutions', value: 'OpusCapita Solutions' },
  { label: 'OP-Pohjola-ryhmä', value: 'OP-Pohjola-ryhmä' },
  { label: 'Pagero', value: 'Pagero' },
  { label: 'POP Pankki', value: 'POP Pankki' },
  { label: 'Strålfors', value: 'Strålfors' },
  { label: 'Säästöpankit', value: 'Säästöpankit' },
  { label: 'S-Pankki', value: 'S-Pankki' },
  { label: 'Tapiola', value: 'Tapiola' },
  { label: 'Tieto', value: 'Tieto' },
  { label: 'Tradeshift', value: 'Tradeshift' },
  { label: 'Maventa', value: 'Maventa' },
  { label: 'Yap', value: 'Yap' },
  { label: 'Ålandsbanken', value: 'Ålandsbanken' },
]
