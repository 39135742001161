import { FormikProps } from 'formik'
import React, { useCallback } from 'react'
import { AddressInput } from '../'

import { GeolocationAutocompleteAddressDetails } from '../../field/AddressField/types'

interface Props {
  addressLabel: string
  addressName: string
  cityName: string
  coordsName: string
  zipcodeName: string
  descriptionName?: string
  disabled?: boolean
  required?: boolean
  coordsRequired?: boolean
  onBlur?: (form: FormikProps<any>) => void
  includeNameInAddress: boolean
}

export const LocationInput: React.FunctionComponent<Props> = ({
  addressLabel,
  addressName,
  cityName,
  coordsName,
  zipcodeName,
  disabled,
  required,
  descriptionName,
  onBlur,
  includeNameInAddress = true,
}) => {
  const onAutoSuggestComplete = useCallback(
    (details: GeolocationAutocompleteAddressDetails, form: FormikProps<any>) => {
      form.setFieldValue(cityName, details.city)
      form.setFieldTouched(cityName, true)
      form.setFieldValue(coordsName, details.location)
      form.setFieldValue(zipcodeName, details.zipcode)
      if (descriptionName) {
        form.setFieldValue(descriptionName, details.name)
        form.setFieldTouched(descriptionName, true)
      }
    },
    [cityName, coordsName, zipcodeName, descriptionName]
  )

  const onAddressChange = useCallback(
    (_, form: FormikProps<any>) => {
      form.setFieldValue(coordsName, { long: null, lat: null })
      form.setFieldValue(zipcodeName, null)
      if (descriptionName) {
        form.setFieldValue(descriptionName, '')
      }
    },
    [coordsName, zipcodeName, descriptionName]
  )

  const validate = useCallback(
    (value: string | null): string | undefined => {
      if (required && (!value || !value.trim())) {
        return 'Kenttä on pakollinen'
      }
    },
    [required]
  )

  return (
    <AddressInput
      label={addressLabel}
      name={addressName}
      onAutoSuggestComplete={onAutoSuggestComplete}
      onChange={onAddressChange}
      onBlur={onBlur}
      disabled={disabled}
      coordsName={coordsName}
      zipcodeName={zipcodeName}
      required={required}
      validate={validate}
      includeNameInAddress={includeNameInAddress}
    />
  )
}
