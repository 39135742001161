import React, { useState } from 'react'
import { ShiftHeader, ShiftHeading } from '../../../components/ShiftTable/layout'
import { Button } from '../../../components/Button'
import { BigButtonSection } from '../../../components/layout'
import { AddEventPage } from '../AddEvent'
import { EventTypeOption, NoShiftPageViews, ShiftPageProps } from '../types'

export const NoShiftPage: React.FunctionComponent<ShiftPageProps> = ({ currentShift, refetch, shiftGroup }) => {
  const [view, setView] = useState<NoShiftPageViews>(NoShiftPageViews.default)
  return (
    <>
      {view === NoShiftPageViews.default && (
        <ShiftHeader color="grey300">
          <ShiftHeading label="Tila: Ei aktiivinen" />
          <BigButtonSection>
            <Button
              category="new"
              label="Aloita työvuoro"
              onClick={() => {
                setView(NoShiftPageViews.work_start_form)
              }}
              size="m"
              fontWeight="normal"
              maxWidth={shiftGroup && shiftGroup.enableStandby ? '100%' : undefined}
            />
            {shiftGroup && shiftGroup.enableStandby && (
              <Button
                category="new"
                label="Aloita varallaolovuoro"
                size="m"
                fontWeight="normal"
                maxWidth="100%"
                onClick={() => {
                  setView(NoShiftPageViews.standby_start_form)
                }}
              />
            )}
          </BigButtonSection>
        </ShiftHeader>
      )}

      {view === NoShiftPageViews.work_start_form && (
        <AddEventPage
          type={EventTypeOption.work_start}
          onClose={() => setView(NoShiftPageViews.default)}
          shift={currentShift}
          refetch={refetch}
        />
      )}

      {view === NoShiftPageViews.standby_start_form && (
        <AddEventPage
          type={EventTypeOption.standby_start}
          onClose={() => setView(NoShiftPageViews.default)}
          shift={currentShift}
          refetch={refetch}
        />
      )}
    </>
  )
}
