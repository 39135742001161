import gql from 'graphql-tag'

export const ACCEPT_AND_ASSIGN_TOWING_ORDER = gql`
  mutation AcceptAndAssignTowingOrder($id: Int!) {
    acceptAndAssignTowingOrder(input: { id: $id }) {
      __typename
      ... on AcceptAndAssignTowingOrderSuccess {
        towingOrder {
          id
          status
        }
      }
      ... on StatusChangeForbiddenError {
        error {
          message
        }
      }
    }
  }
`

export const ACCEPT_TOWING_ORDER = gql`
  mutation AcceptTowingOrder($id: Int!) {
    acceptTowingOrder(input: { id: $id }) {
      __typename
      ... on AcceptTowingOrderSuccess {
        towingOrder {
          id
          status
        }
      }
      ... on StatusChangeForbiddenError {
        error {
          message
        }
      }
    }
  }
`

export const APPROVE_TOWING_ORDER_ASSIGNMENT = gql`
  mutation ApproveTowingOrderAssignment($id: Int!) {
    approveTowingOrderAssignment(input: { id: $id }) {
      __typename
      ... on ApproveTowingOrderAssignmentSuccess {
        towingOrder {
          id
          status
        }
      }
    }
  }
`

export const CANCEL_TOWING_ORDER = gql`
  mutation CancelTowingOrder($input: CancelTowingOrderInput!) {
    cancelTowingOrder(input: $input) {
      __typename
      ... on CancelTowingOrderSuccess {
        towingOrder {
          id
          status
          visibility
        }
      }
    }
  }
`

export const REJECT_TOWING_ORDER = gql`
  mutation RejectTowingOrderAssignment($input: RejectTowingOrderAssignmentInput!) {
    rejectTowingOrderAssignment(input: $input) {
      __typename
      ... on RejectTowingOrderAssignmentSuccess {
        towingOrder {
          id
          status
          visibility
        }
      }
    }
  }
`

export const CREATE_TOWING_RECORD_FROM_ORDER = gql`
  mutation TowingRecordFromOrder($towingOrderId: Int!) {
    towingRecordFromOrder(input: { towingOrderId: $towingOrderId }) {
      __typename
      ... on TowingRecordFromOrderSuccess {
        towingRecord {
          id
          jobStatus
          type
        }
      }
      ... on StatusChangeForbiddenError {
        error {
          message
        }
      }
    }
  }
`

// SOS specific queries

export const ACCEPT_SOS_SERVICE_ORDER = gql`
  mutation AcceptSosServiceOrder($input: AcceptSosServiceOrderInput!) {
    acceptSosServiceOrder(input: $input) {
      __typename
      ... on ServiceOrderAcceptedSuccess {
        towingOrder {
          id
          status
        }
      }
    }
  }
`
