import gql from 'graphql-tag'
import { ShiftError, UnauthorizedError } from '../errorTypes'
import { CurrentWorkShift } from './types'

export const GET_CURRENT_SHIFT = gql`
  query CurrentShift {
    currentShift {
      ... on UnauthorizedError {
        error {
          code
          message
        }
      }
      ... on ShiftError {
        error {
          code
          message
        }
      }
      ... on GetCurrentShiftSuccess {
        currentShift {
          shift {
            id
            userId
            companyId
            comment
            shiftStart
            shiftEnd
          }
          events {
            id
            userId
            companyId
            shiftId
            type
            timestamp
            info
          }
        }
      }
    }
  }
`

export interface CurrentShiftResponse {
  currentShift: CurrentShiftOutput
}

type CurrentShiftOutput = GetCurrentShiftSuccess | UnauthorizedError | ShiftError

interface GetCurrentShiftSuccess
  extends Readonly<{
    __typename: 'GetCurrentShiftSuccess'
    currentShift: CurrentWorkShift
  }> {}
