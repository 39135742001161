import gql from 'graphql-tag'

export const DELETE_SIGNATURE = gql`
  mutation DeleteSignature($input: DeleteSignatureInput!) {
    deleteSignature(input: $input) {
      __typename
      ... on UnauthorizedError {
        error {
          code
          message
          details
          context
        }
      }
      ... on DeleteSignatureSuccess {
        success
      }
    }
  }
`

export const ADD_SIGNATURE = gql`
  mutation AddSignature($input: AddSignatureInput!) {
    addSignature(input: $input) {
      __typename
      ... on UnauthorizedError {
        error {
          code
          message
          details
          context
        }
      }
      ... on InvalidStateError {
        error {
          code
          message
          details
          code
        }
      }
      ... on AddSignatureSuccess {
        signature {
          id
          type
          agreements {
            text
            isAgreed
          }
          signatorName
          signatorEmail
          signatorPhone
          signedAt
          createdAt
          sentAt
        }
      }
    }
  }
`

export const RESEND_ACCEPTANCE_EMAIL = gql`
  mutation ResendOnlineAcceptanceEmail($input: ResendOnlineAcceptanceEmailInput!) {
    resendOnlineAcceptanceEmail(input: $input) {
      __typename
      ... on ResendOnlineAcceptanceEmailSuccess {
        signature {
          id
          type
          agreements {
            text
            isAgreed
          }
          signatorName
          signatorEmail
          signatorPhone
          signedAt
          createdAt
          sentAt
        }
      }
      ... on UnauthorizedError {
        error {
          code
          message
          details
          context
        }
      }
      ... on ResourceNotFoundError {
        error {
          code
          message
          details
          context
        }
      }
      ... on InvalidStateError {
        error {
          code
          message
          details
          context
        }
      }
    }
  }
`
