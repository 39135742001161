import { FieldArray } from 'formik'
import React, { RefObject, useContext, useEffect, useState, useCallback } from 'react'
import { StateStore } from '../../containers/StoreProvider'
import { Column, FormSubGroup, FormSubGroupHeader, FormSubGroupHeading, Row } from '../layout'
import { JobItemToolbar } from './JobItemToolbar'
import { Totals } from './Totals'
import { TowingRecordItem } from './TowingRecordItem'
import { DiscountItem } from './DiscountItem'
import { JobItemInput } from './types'
import { ItemVatCategory, JobItemDiscountType, JobItemType } from '../../common/types'

interface Props {
  jobItems: JobItemInput[]
  discountItem: JobItemInput | null
  itemsIncludeVat: boolean
  setValue: (field: string, value: any) => void
  towingRecordId?: number
  disabled?: boolean
  paymentRef?: RefObject<any>
  scrollToPayment?: boolean
}

export const TowingRecordItems: React.FunctionComponent<Props> = ({
  towingRecordId,
  itemsIncludeVat,
  jobItems,
  setValue,
  discountItem,
  disabled = false,
  paymentRef = null,
  scrollToPayment = false,
}) => {
  const { state } = useContext(StateStore)
  const [shouldScroll, setShouldScroll] = useState<boolean>(scrollToPayment)
  const createDiscountItem = () => {
    setValue('discountItem.id', null)
    setValue('discountItem.type', JobItemType.discount)
    setValue('discountItem.description', '')
    setValue('discountItem.discount', 0)
    setValue('discountItem.discountType', JobItemDiscountType.amount)
    setValue('discountItem.grossAmount', 0)
    setValue('discountItem.name', 'Kokonaisalennus')
    setValue('discountItem.netAmount', 0)
    setValue('discountItem.quantity', 1)
    setValue('discountItem.unit', '')
    setValue('discountItem.unitPrice', 0)
    setValue('discountItem.vat', 24)
    setValue('discountItem.unitPriceHasVatIncluded', true)
    setValue('discountItem.vatAmount', 24)
    setValue('discountItem.vatCategory', ItemVatCategory.standard)
  }

  const deleteDiscountItem = () => {
    setValue('discountItem', null)
  }

  const setIncludeVat = (includeVat: boolean) => {
    setValue('itemsIncludeVat', includeVat)
  }

  const handleScroll = useCallback(() => {
    if (paymentRef) {
      paymentRef.current.scrollIntoView()
    }

    setShouldScroll(false)
  }, [paymentRef, setShouldScroll])

  useEffect(() => {
    if (shouldScroll) {
      handleScroll()
    }
  }, [shouldScroll, handleScroll])

  return (
    <div ref={paymentRef}>
      <FormSubGroupHeader padding="0.5rem 0 1rem 0" onClick={() => handleScroll()}>
        <Row>
          <Column>
            <FormSubGroupHeading>Maksurivit</FormSubGroupHeading>
          </Column>
          <Column></Column>
        </Row>
      </FormSubGroupHeader>
      <FormSubGroup padding="0.5rem 0 1rem 0">
        <FieldArray
          name="jobItems"
          render={arrayHelpers => {
            const push = (item: JobItemInput) => {
              arrayHelpers.push(item)
            }
            const remove = (index: number) => {
              arrayHelpers.remove(index)
            }
            const towingRecordItems =
              jobItems && jobItems.length > 0
                ? jobItems.map((jobItem: JobItemInput, index: number) => (
                    <TowingRecordItem
                      key={index}
                      jobItem={jobItem}
                      index={index}
                      removeItem={() => remove(index)}
                      itemsIncludeVat={itemsIncludeVat}
                      towingRecordId={towingRecordId}
                      disabled={disabled}
                    />
                  ))
                : null
            return (
              <>
                <JobItemToolbar
                  items={state.items}
                  itemGroups={state.itemGroups}
                  createJobItem={push}
                  createDiscountItem={createDiscountItem}
                  hasDiscountItem={discountItem ? true : false}
                  setIncludeVat={setIncludeVat}
                  itemsIncludeVat={itemsIncludeVat}
                  disabled={disabled}
                />
                {towingRecordItems}
              </>
            )
          }}
        />
        {discountItem && (
          <DiscountItem
            jobItem={discountItem}
            removeItem={deleteDiscountItem}
            towingRecordId={towingRecordId}
            itemsIncludeVat={true}
            disabled={disabled}
          />
        )}
      </FormSubGroup>
      <FormSubGroup>
        <Totals jobItems={jobItems} itemsIncludeVat={itemsIncludeVat} discountItem={discountItem} />
      </FormSubGroup>
    </div>
  )
}
