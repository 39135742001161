import { format } from 'date-fns'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Time } from '../../../common/types'
import { Button } from '../../../components/Button'

import { RouteColumn as EventColumn, RouteRow as EventRow, RouteWrapper } from '../../../components/layout'
import { TextAreaInput } from '../../../components/layout/form/input'
import { DateTimeInput } from '../../../components/layout/form/input/DateTimeInput'
import { RouteHeader as EventHeader } from '../../TowingRecordPage/components/routes/subforms/RouteHeader'
import { AutoliittoSeparatorLine } from '../../TowingRecordPage/components/routes/subforms/styled'
import { EventTypeOption } from '../types'
import { AddEventFormValues } from './types'

interface AddEventProps {
  type: EventTypeOption
  onClose: () => void
  setCurrentTime: (prefix: string) => void
  setFieldValue: (field: string, value: any) => void
  values: AddEventFormValues
}

export const AddEvent: React.FunctionComponent<AddEventProps> = ({ type, onClose, setCurrentTime, setFieldValue, values }) => {
  const label = resolveLabel(type)

  const modifyTime = useCallback((value: number) => {

    const currentDate = (values.event.date && !isNaN(values.event.date.valueOf())) ? values.event.date : new Date()
    const [hours, minutes] = values.event.time && /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test((values.event.time as never) as string) ? values.event.time.split(':') : `${currentDate.getHours()}:${currentDate.getMinutes()}`.split(':')
    currentDate.setHours(parseInt(hours))
    currentDate.setMinutes(parseInt(minutes))

    const remainder = currentDate.getMinutes() % 5;
   
    const modifier = remainder !== 0 
      ? value < 0 ? -remainder : 5 - remainder
      : value
    setFieldValue('event.date', new Date(currentDate.getTime() + (modifier * 60000)))
    setFieldValue('event.time', (format(new Date(currentDate.getTime() + (modifier * 60000)), 'HH:mm') as unknown) as Time)
  }, [setFieldValue, values.event.date, values.event.time])

 
  return (
    <EventWrapper>
      <EventHeader label={label} cancel={onClose} />
      <AutoliittoSeparatorLine marginTop="2rem" />
      <EventRow columns="1fr">
        <EventColumn>
          <div style={{display: 'flex', gap: '1rem', justifyContent: 'flex-start', alignItems: 'flex-end', flexWrap: 'wrap'}}>
            <div style={{width: '50%', minWidth:'300px'}}>
              <DateTimeInput label="Aika" name="event" required customOnChange={() => {setCurrentTime("event")}} showCurrentTimeBtn />
            </div>
            <div style={{display: 'flex', gap: '1rem'}}>
              <Button maxWidth='30px' category='new' onClick={() => {modifyTime(-5)}}>-</Button>
              <Button maxWidth='30px' category='new' onClick={() => {modifyTime(5)}}>+</Button>
            </div>
          </div>
        </EventColumn>
      </EventRow>
      <EventRow columns="1fr">
        <EventColumn>
          <TextAreaInput label="Lisätiedot" name="event.comment" />
        </EventColumn>
      </EventRow>
      {(type === EventTypeOption.work_start ||
        type === EventTypeOption.standby_start) && (
          <>
            <AutoliittoSeparatorLine marginTop="2rem" />
            <EventRow columns="1fr 1fr 1fr">
              <EventColumn>
                <DateTimeInput
                  label="Suunniteltu päättymisaika:"
                  name="event.estimatedEnd"
                  customOnChange={() => {setCurrentTime("event.estimatedEnd")}}
                  showCurrentTimeBtn
                />
              </EventColumn>
            </EventRow>
          </>
        )}
    </EventWrapper>
  )
}

const EventWrapper = styled(RouteWrapper)`
  padding: 0.5rem 1.5rem;
`

const resolveLabel = (type: EventTypeOption) => {
  switch (type) {
    case EventTypeOption.work_start:
      return 'Aloita työvuoro'
    case EventTypeOption.work_end:
      return 'Lopeta työvuoro'
    case EventTypeOption.break_start:
      return 'Aloita tauko'
    case EventTypeOption.break_end:
      return 'Lopeta tauko'
    case EventTypeOption.standby_start:
      return 'Aloita varallaolovuoro'
    case EventTypeOption.standby_end:
      return 'Lopeta varallaolovuoro'
    case EventTypeOption.standby_work_start:
      return 'Aloita työ'
    case EventTypeOption.standby_work_end:
      return 'Lopeta työ'
    case EventTypeOption.wait_start:
      return 'Aloita odotus'
    case EventTypeOption.wait_end:
      return 'Lopeta odotus'
    default:
      return ''
  }
}
