import { useMutation } from '@apollo/react-hooks'
import { Formik, getIn } from 'formik'
import React, { useContext, useState } from 'react'
import { InjectedIntlProps, defineMessages, injectIntl } from 'react-intl'

import { ACS } from '../../../../../../common/AccessControl'
import { towingServiceTypeOptions } from '../../../../../../common/options'
import {
  TowingRecordJobStatus,
  TowingRecordServiceType,
  TowingRecordType,
  TypeIdentifier,
} from '../../../../../../common/types'
import { Maybe } from '../../../../../../common/types'
import { getBatteryServiceBattery, getDeliveredBattery } from '../../../../../../components/ALSubForm/options'
import { Button } from '../../../../../../components/Button'
import { Geolocation } from '../../../../../../components/Geolocation/types'
import { ScrollToError } from '../../../../../../components/layout/form/ScrollToError'
import { setErrorNotification, setSuccessNotification } from '../../../../../../components/notification'
import { Loading } from '../../../../../../components/responses'
import {
  setErrorNotifications,
  setFatalErrorNotification,
} from '../../../../../../components/responses/setErrorNotifications'
import { FinishRouteAction } from '../../../../../../components/SosSubForm/FinishRoute/types'
import {
  UPDATE_ARRIVED_AT_DESTINATION_MUTATION,
  UPDATE_FINISHED_AT_DESTINATION_MUTATION,
} from '../../../../../../components/SosSubForm/mutation/mutation'
import {
  updateArrivedAtDestinationToMutationVariables,
  updateFinishedAtDestinationToMutationVariables,
} from '../../../../../../components/SosSubForm/mutation/toMutationVariables'
import {
  UpdateArrivedAtDestinationResponse,
  UpdateFinishedAtDestinationResponse,
} from '../../../../../../components/SosSubForm/mutation/types'
import { translateInputOptions } from '../../../../../../util/translateInputOptions'
import { StateStore } from '../../../../../StoreProvider'
import { SetTowingRecordType, TowingRecord } from '../../../../ViewTowingRecord/types'
import { GeoLocateAddressData, SubmitFormType } from '../../types'
import {
  validateRouteTimes,
  getRoutesTimelineAtDestination,
  getCurrentRouteTimeAtDestination,
} from '../../validateRouteTimes'
import { ButtonContainer, RouteForm } from '../styled'
import { FinishRoute, ShowAddressOptions } from './FinishRoute'
import { getInitialValues } from './getInitialValues'
import { EditFinishRouteFormValues } from './types'
import { EDIT_FINISH_ROUTE_MUTATION, EditFinishRouteResponse } from './mutation/mutation'
import { useHistoryPush } from '../../../../../../components/router'
import { toMutationVariables } from './mutation/toMutationVariables'
import { EditMenu } from '../../EditMenu'
import { autoliittoFields } from '../../createRouteMenuConfig'

interface EditTowingRecordRoutesProps {
  onCancel: () => void
  towingRecord: TowingRecord
  routeDone: boolean
  onRefetch?: () => void
  setTowingRecord?: SetTowingRecordType
}

const routeMessages = defineMessages({
  route_edit_notification_success_title: {
    id: 'route.edit.notification.success.title',
    defaultMessage: 'Route updated',
  },
  route_edit_notification_success_message: {
    id: 'route.edit.notification.success.message',
    defaultMessage: 'Route updated successfully.',
  },
})

export const RoutesIntl: React.FunctionComponent<EditTowingRecordRoutesProps & InjectedIntlProps> = ({
  onCancel,
  towingRecord,
  intl,
  routeDone,
  setTowingRecord,
}) => {
  const historyPush = useHistoryPush()
  const { formatMessage } = intl
  const {
    state: { currentUser, items, itemGroups, settings },
  } = useContext(StateStore)

  const isSos = towingRecord.type === TowingRecordType.sos
  const isAutoliitto = towingRecord.typeIdentifier === TypeIdentifier.autoliitto
  const useExtendedRoutes = settings && settings.modules.useExtendedRoutes ? true : false

  const accessControl = new ACS(currentUser).setTowingRecord(towingRecord).setExtendedRoutes(useExtendedRoutes)

  const [done, setDone] = useState<boolean>(() => {
    if (towingRecord.type === TowingRecordType.sos || useExtendedRoutes) {
      return accessControl.canTowingRecord('editFinishedAtDestination')
    }

    return routeDone
  })

  const [action, setAction] = useState<FinishRouteAction>(() => {
    if (isSos || useExtendedRoutes) {
      if (towingRecord.jobStatus === TowingRecordJobStatus.finished_at_breakdown_location) {
        return 'edit-arrived'
      }
      if (towingRecord.jobStatus === TowingRecordJobStatus.arrived_at_destination) {
        return 'edit-finished'
      }
    }
    return 'default'
  })

  const [editTowingRecord, { loading: finishRouteLoading }] = useMutation<EditFinishRouteResponse>(
    EDIT_FINISH_ROUTE_MUTATION,
    {
      onCompleted(test) {
        const { editFinishRoute } = test
        if (editFinishRoute.__typename === 'EditTowingRecordSuccess') {
          setSuccessNotification(
            formatMessage(routeMessages.route_edit_notification_success_title),
            formatMessage(routeMessages.route_edit_notification_success_message)
          )
          if (towingRecord.type !== TowingRecordType.sos) {
            if (setTowingRecord) {
              setTowingRecord(editFinishRoute.towingRecord, 'view-towing-record')
              return
            }
            historyPush(`/towing-record/${towingRecord.id}`)
          }
        } else {
          setErrorNotifications({ data: editFinishRoute })
        }
      },
      onError(err) {
        setFatalErrorNotification(err.message)
      },
    }
  )

  const [updateArrivedAtDestination, { loading: updateArrivedAtDestinationLoading }] = useMutation<
    UpdateArrivedAtDestinationResponse
  >(UPDATE_ARRIVED_AT_DESTINATION_MUTATION, {
    onCompleted(response) {
      const { updateArrivedAtDestination } = response
      if (updateArrivedAtDestination.__typename === 'UpdateArrivedAtDestinationSuccess') {
        setSuccessNotification(
          formatMessage(routeMessages.route_edit_notification_success_title),
          formatMessage(routeMessages.route_edit_notification_success_message)
        )
        setDone(true)
        if (setTowingRecord) {
          setTowingRecord(updateArrivedAtDestination.towingRecord)
        }
        if (updateArrivedAtDestination.towingRecord.jobStatus === TowingRecordJobStatus.arrived_at_destination) {
          setAction('edit-finished')
        } else {
          setAction('default')
        }
      } else {
        setErrorNotifications({ data: updateArrivedAtDestination })
      }
    },
    onError(err) {
      setFatalErrorNotification(err.message)
    },
  })

  const [updateFinishedAtDestination, { loading: updateFinishedAtDestinationLoading }] = useMutation<
    UpdateFinishedAtDestinationResponse
  >(UPDATE_FINISHED_AT_DESTINATION_MUTATION, {
    onCompleted({ updateFinishedAtDestination }) {
      if (updateFinishedAtDestination.__typename === 'UpdateFinishedAtDestinationSuccess') {
        setSuccessNotification(
          formatMessage(routeMessages.route_edit_notification_success_title),
          formatMessage(routeMessages.route_edit_notification_success_message)
        )
        setDone(true)
        if (setTowingRecord) {
          setTowingRecord(updateFinishedAtDestination.towingRecord, 'view-towing-record')
        }
      } else {
        setErrorNotifications({ data: updateFinishedAtDestination })
      }
    },
  })

  const deliveredBattery = getDeliveredBattery(towingRecord)
  const batteryOption = getBatteryServiceBattery(itemGroups, items, deliveredBattery)
  const initialValues = getInitialValues(towingRecord, batteryOption)

  const saveLabel = isSos ? (done ? 'Tallenna valmis' : 'Tallenna saapunut') : 'Tallenna valmis'

  const translatedServiceTypeOptions = translateInputOptions(towingServiceTypeOptions, formatMessage)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selectedForm, setSelectedForm] = useState<SubmitFormType>('arrived')

  async function submit(values: EditFinishRouteFormValues) {
    if (!isSos && !useExtendedRoutes) {
      editTowingRecord({ variables: toMutationVariables(values) })
      return
    }

    if (selectedForm === 'arrived') {
      if (!accessControl.canTowingRecord('editArrivedAtDestination')) {
        return setErrorNotification('Virhe', accessControl.getLatestErrorMessage())
      }

      if (isSos) {
        const {
          finishRoute: { coords },
        } = values
        if (!coords.lat || !coords.long) {
          return setErrorNotification(
            'Virhe',
            'Määränpään koordinaatteja ei saatu haettua. Hae osoite kartalta tai valitse osoite osoitehaun ehdotuksista.'
          )
        }
      }
      await updateArrivedAtDestination({ variables: updateArrivedAtDestinationToMutationVariables(values) })
    }

    if (selectedForm === 'finished') {
      if (!accessControl.canTowingRecord('editFinishedAtDestination')) {
        return setErrorNotification('Virhe', accessControl.getLatestErrorMessage())
      }
      await updateFinishedAtDestination({ variables: updateFinishedAtDestinationToMutationVariables(values) })
    }
  }
  const disabledAutoliittoFields = autoliittoFields(initialValues)

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values: EditFinishRouteFormValues, { resetForm }) => {
        try {
          const routes = getRoutesTimelineAtDestination(towingRecord, useExtendedRoutes, done)
          const currentRouteTime = getCurrentRouteTimeAtDestination(values, done, useExtendedRoutes || isSos)
          validateRouteTimes(currentRouteTime, routes)
        } catch (e) {
          return setErrorNotification('Virhe', e.message)
        }

        await submit(values)
        // On SOS records additional_info should always be '' on initial render, 
        // and won't trigger a rerender of the form UNLESS some other field has also changed
        // use resetForm after submit
        isSos && resetForm()
      }}

      render={({ setFieldValue, values, initialValues }) => {
        const setValue = (field: string, value: any) => {
          setFieldValue(field, value)
        }

        const getValue = (fieldName: string) => {
          const value = getIn(values, fieldName)
          return value
        }

        const setRouteLocation = (locationData: Maybe<GeoLocateAddressData>) => {
          if (locationData) {
            setValue(`finishRoute.address`, locationData.address)
            setValue(`finishRoute.city`, locationData.city)
            setValue(`finishRoute.zipcode`, locationData.zipcode)
            setValue(`finishRoute.coords.lat`, locationData.coords.lat)
            setValue(`finishRoute.coords.long`, locationData.coords.long)
            if (locationData.description) {
              setValue(`finishRoute.description`, locationData.description)
            }
          }
        }

        const updateRouteDate = () => {
          setValue(`finishRoute.date`, new Date())
        }

        const setAutoliittoRepairShopField = (value: string | null) => {
          setValue('automobileAndTouringClubFinland.repairShopId', value)
        }

        const showAddresInputAs = () => {
          if (initialValues.sosServiceOrder) {
            return ShowAddressOptions.SOS_REPAIR_SHOP
          }

          if (!initialValues.automobileAndTouringClubFinland) {
            return initialValues.serviceType !== TowingRecordServiceType.ROAD_SERVICE
              ? ShowAddressOptions.ADDRESS
              : ShowAddressOptions.DISABLED
          }

          if (initialValues.serviceType !== TowingRecordServiceType.TOWING) {
            return ShowAddressOptions.DISABLED
          }

          if (initialValues.automobileAndTouringClubFinland.repairShopId) {
            return ShowAddressOptions.REPAIR_SHOP
          }

          if (initialValues.automobileAndTouringClubFinland && !initialValues.finishRoute.address) {
            return ShowAddressOptions.REPAIR_SHOP
          }

          return ShowAddressOptions.ADDRESS
        }

        const setGeolocatedField = (geolocated: boolean) => {
          setValue('finishRoute.geolocated', geolocated)
        }

        const clear = () => {
          setFieldValue('finishRoute.address', '')
          setFieldValue('finishRoute.city', '')
          setFieldValue('finishRoute.coords.lat', null)
          setFieldValue('finishRoute.coords.long', null)
          setFieldValue('finishRoute.geolocated', false)
        }

        const reportPosition = (location: Geolocation, name: string) => {
          setFieldValue(`${name}.address`, location.address)
          setFieldValue(`${name}.city`, location.city)
          setFieldValue(`${name}.coords.lat`, location.coords.lat)
          setFieldValue(`${name}.coords.long`, location.coords.long)
        }

        const setRouteAction = (action: FinishRouteAction) => {
          setAction(action)
          if (action === 'edit-arrived') {
            setDone(false)
          } else {
            setDone(true)
          }
        }

        return (
          <RouteForm>
            <FinishRoute
              regNo={towingRecord.vehicleDetails.registrationNumber}
              onCancel={() => onCancel()}
              isAutoliitto={isAutoliitto}
              isSos={isSos}
              useExtendedRoutes={useExtendedRoutes}
              setRouteLocation={setRouteLocation}
              updateRouteDate={() => updateRouteDate}
              repairShopId={
                towingRecord.automobileAndTouringClubFinland
                  ? towingRecord.automobileAndTouringClubFinland.repairShopId
                  : null
              }
              showAddresInputAs={showAddresInputAs()}
              setAutoliittoRepairShopField={setAutoliittoRepairShopField}
              translatedServiceTypeOptions={translatedServiceTypeOptions}
              serviceChannel={
                towingRecord.automobileAndTouringClubFinland
                  ? towingRecord.automobileAndTouringClubFinland.serviceChannel
                  : null
              }
              clear={clear}
              setGeolocatedField={setGeolocatedField}
              initialValues={initialValues}
              setLoading={setIsLoading}
              formatMessage={formatMessage}
              routeDone={done}
              reportPosition={reportPosition}
              setValue={setValue}
              getValue={getValue}
              action={action}
              setAction={setRouteAction}
              jobStatus={towingRecord.jobStatus}
              stationId={towingRecord.stationId}
              selectedForm={selectedForm}
              setSelectedForm={setSelectedForm}
              towingRecordId={towingRecord.id}
              autoliittoFields={disabledAutoliittoFields}
            />

            <ButtonContainer floatAtBottom>
              <Loading
                loading={
                  finishRouteLoading ||
                  isLoading ||
                  updateArrivedAtDestinationLoading ||
                  updateFinishedAtDestinationLoading
                }
              />

              {!useExtendedRoutes && !isSos && (
                <Button
                  category="save"
                  size="l"
                  label={saveLabel}
                  mobileLabel="Tallenna"
                  submit
                  maxWidth="100%"
                ></Button>
              )}

              {(useExtendedRoutes || isSos) &&
                towingRecord.jobStatus === TowingRecordJobStatus.finished_at_breakdown_location &&
                selectedForm === 'arrived' && (
                  <Button
                    category="save"
                    size="l"
                    label={'Tallenna saapunut'}
                    mobileLabel="Tallenna"
                    submit
                    maxWidth="100%"
                  ></Button>
                )}

              {(useExtendedRoutes || isSos) &&
                towingRecord.jobStatus === TowingRecordJobStatus.arrived_at_destination &&
                selectedForm === 'finished' && (
                  <Button
                    category="save"
                    size="l"
                    label={'Tallenna valmis'}
                    mobileLabel="Tallenna"
                    submit
                    maxWidth="100%"
                  ></Button>
                )}

              {(useExtendedRoutes || isSos) && (
                <EditMenu
                  selectedForm={selectedForm}
                  action={action}
                  setAction={setAction}
                  canEditArrived={done}
                  canEditFinished={[
                    TowingRecordJobStatus.finished_at_destination,
                    TowingRecordJobStatus.stopped,
                  ].includes(towingRecord.jobStatus)}
                />
              )}
            </ButtonContainer>
            <ScrollToError />
          </RouteForm>
        )
      }}
    />
  )
}

export const FinishRoutePage = injectIntl(RoutesIntl)
