import { Time } from '../common/types'
import {
  ScalarBusinessID,
  ScalarDate,
  ScalarDateTime,
  ScalarEmail,
  ScalarMunicipality,
  ScalarPersonalIdentityCode,
  ScalarCustomerIdentifier,
  ScalarPhone,
  ScalarPostalCode,
  ScalarTime,
  ScalarType,
} from '../graphql-client/types'
import { format } from 'date-fns'

export function toScalarShortDate(date: Date): ScalarDate {
  return {
    type: ScalarType.SHORTDATE,
    value: format(date, 'YYYY-MM-DD'),
  }
}

export function toScalarTime(time: Time): ScalarTime {
  return {
    type: ScalarType.TIME,
    value: time.toString(),
  }
}

export function toScalarPersonalIdentityCode(personalIdentityCode: string): ScalarPersonalIdentityCode {
  return {
    type: ScalarType.PERSONAL_IDENTITY_CODE,
    value: personalIdentityCode,
  }
}

export function toScalarPostalCode(postalCode: string): ScalarPostalCode {
  return {
    type: ScalarType.POSTAL_CODE,
    value: postalCode.toString(),
  }
}

export function toScalarPhone(phone: string): ScalarPhone {
  return {
    type: ScalarType.PHONE,
    value: phone,
  }
}

export function toScalarEmail(email: string): ScalarEmail {
  return {
    type: ScalarType.EMAIL,
    value: email.toString(),
  }
}

export function toScalarMunicipality(municipality: string): ScalarMunicipality {
  return {
    type: ScalarType.MUNICIPALITY,
    value: municipality,
  }
}

export function toScalarBusinessId(businessId: string): ScalarBusinessID {
  return {
    type: ScalarType.BUSINESS_ID,
    value: businessId,
  }
}

export function toScalarCustomerIdentifier(customerIdentifier: string): ScalarCustomerIdentifier {
  return {
    type: ScalarType.CUSTOMER_IDENTIFIER,
    value: customerIdentifier,
  }
}

export function toScalarDateTime(date: Date): ScalarDateTime {
  return {
    type: ScalarType.DATETIME,
    value: date.toISOString(),
  }
}
