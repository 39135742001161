import { Formik } from 'formik'
import React, { useContext } from 'react'
import { Button } from '../../../components/Button'
import {
  ButtonColumn,
  ButtonRow,
  FormSubGroupsList,
  FormSubGroupWrapper,
  Section,
  SectionColumn,
  FormSubGroup,
  FormSubGroupHeader,
  FormSubGroupHeading,
} from '../../../components/layout'
import {
  BusinessIdInput,
  TextInput,
  SelectInput,
  TextAreaInput,
  PhoneInput,
  EmailInput,
} from '../../../components/layout/form/input'
import { FormikForm as Form } from '../../../components/layout/form/style'
import { CompanyCustomerFormValues, EditCompanyResponse } from '../types'
import { EditCompanyCustomerResponse } from './types'
import { ScrollToError } from '../../../components/layout/form/ScrollToError'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { deliveryMethodCompanyOptions } from '../options'
import { translateInputOptions } from '../../../util/translateInputOptions'
import { DeliveryMethod } from '../../../common/types'
import { eInvoiceOperatorOptions } from './eInvoiceOperatorOptions'
import { StateStore } from '../../StoreProvider'
import { acceptedRoles } from '../../TowingOrderPage/AddTowingOrder/TowingOrderSettingsForm'
import { customerNotificationOptions } from '../../../common/options'

interface FormProps {
  editCompanyCustomer: (values: CompanyCustomerFormValues) => any
  onCloseForm: (t: EditCompanyResponse) => void
  onCancelForm: () => void
  initialValues: CompanyCustomerFormValues
  loading: boolean
}

const EditCompanyCustomerFormIntl: React.FunctionComponent<FormProps & InjectedIntlProps> = ({
  onCancelForm,
  onCloseForm,
  editCompanyCustomer,
  initialValues,
  intl,
  loading,
}) => {
  const { formatMessage } = intl
  const translatedDeliveryMethodOptions = translateInputOptions(deliveryMethodCompanyOptions, formatMessage)
  const {
    state: { settings, currentUser },
  } = useContext(StateStore)

  const handleSubmit = async (values: CompanyCustomerFormValues) => {
    try {
      const fullResponse = await editCompanyCustomer(values)
      const response: EditCompanyCustomerResponse = fullResponse.data
      if (response.editCompanyCustomer.__typename === 'EditCompanyCustomerSuccess') {
        onCloseForm(response.editCompanyCustomer)
      } else {
        console.log('Error saving: ', response.editCompanyCustomer.__typename)
      }
    } catch (err) {
      console.log('Error updating customer', err)
    }
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: CompanyCustomerFormValues) => {
        handleSubmit(values)
      }}
      render={({ values, submitForm }) => {
        console.log('EditCompanyCustomerFormIntl', values)
        return (
          <Form>
            <FormSubGroupsList>
              <FormSubGroupWrapper>
                <FormSubGroupHeader>
                  <FormSubGroupHeading>{`Asiakasrekisteri: ${values.companyName}`}</FormSubGroupHeading>
                </FormSubGroupHeader>

                <FormSubGroup>
                  <Section>
                    <SectionColumn>
                      <TextInput label="Asiakastunniste" name="identifier" />
                    </SectionColumn>
                    <SectionColumn>
                      <BusinessIdInput label="Y-tunnus" name="businessId" />
                    </SectionColumn>
                    <SectionColumn>
                      <TextInput label="Yritys" name="companyName" />
                    </SectionColumn>
                    <SectionColumn>
                      <TextInput label="c/o" name="co" />
                    </SectionColumn>
                    <SectionColumn>
                      <TextInput label="Osoite" name="address" />
                    </SectionColumn>
                    <SectionColumn>
                      <TextInput label="Postinumero" name="zipcode" />
                    </SectionColumn>
                    <SectionColumn>
                      <TextInput label="Toimipaikka" name="city" />
                    </SectionColumn>
                    <SectionColumn>
                      <TextInput label="Sähköposti" name="email" />
                    </SectionColumn>
                    <SectionColumn>
                      <TextInput label="Yhteyshenkilö" name="contactPerson" />
                    </SectionColumn>
                    <SectionColumn>
                      <PhoneInput label="Yhteyshenkilön puhelinnumero" name="phone" />
                    </SectionColumn>
                    <SectionColumn>
                      <EmailInput label="Yhteyshenkilön sähköposti" name="contactPersonEmail" />
                    </SectionColumn>
                    <SectionColumn>
                      <TextInput label="Verkkolaskuosoite" name="eInvoiceAddress" />
                    </SectionColumn>
                    <SectionColumn>
                      <SelectInput
                        label="Verkkolaskuoperaattori"
                        name="eInvoiceOperator"
                        disabled={Boolean(values.deliveryMethod !== DeliveryMethod.e_invoice)}
                        options={eInvoiceOperatorOptions}
                      />
                    </SectionColumn>
                    <SectionColumn>
                      <SelectInput
                        label="Laskun lähetystapa"
                        name="deliveryMethod"
                        options={translatedDeliveryMethodOptions}
                        searchable={false}
                      />
                    </SectionColumn>
                    <SectionColumn>
                      <TextInput label="Lisätieto (laskun osoiteriville)" name="extraInfo" />
                    </SectionColumn>
                    {settings &&
                      settings.notifications.enabled &&
                      currentUser &&
                      acceptedRoles.includes(currentUser.role) && (
                        <SectionColumn>
                          <SelectInput
                            label="Seurantalinkin lähetys"
                            name="notificationMethod"
                            options={customerNotificationOptions}
                            searchable={false}
                          />
                        </SectionColumn>
                      )}
                  </Section>
                  <Section>
                    <SectionColumn>
                      <TextAreaInput label="Muistiinpanot" name="additionalInfo" />
                    </SectionColumn>
                  </Section>
                  <ButtonRow>
                    <ButtonColumn>
                      <Button category="save" label="Tallenna" onClick={() => submitForm()} loading={loading} />
                    </ButtonColumn>
                    <ButtonColumn>
                      <Button category="cancelSecondary" label="Sulje" onClick={onCancelForm} />
                    </ButtonColumn>
                  </ButtonRow>
                </FormSubGroup>
              </FormSubGroupWrapper>
            </FormSubGroupsList>
            <ScrollToError />
          </Form>
        )
      }}
    />
  )
}

export const EditCompanyCustomerForm = injectIntl(EditCompanyCustomerFormIntl)
