import { Field, FormikProps } from 'formik'
import React from 'react'
import { AddressField } from '../../field'
import { GeolocationAutocompleteAddressDetails } from '../../field/AddressField/types'
interface Props {
  name: string
  label?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  onAutoSuggestComplete?: (details: GeolocationAutocompleteAddressDetails, form: FormikProps<any>) => void
  onChange?: (value: string, form: FormikProps<any>) => void
  onBlur?: (form: FormikProps<any>) => void
  coordsName: string
  zipcodeName: string
  validate: any
  includeNameInAddress: boolean
}

export const AddressInput: React.FunctionComponent<Props> = ({
  name,
  label,
  placeholder,
  required,
  onAutoSuggestComplete,
  onChange,
  onBlur,
  disabled,
  coordsName,
  zipcodeName,
  validate,
  includeNameInAddress,
}) => {
  return (
    <Field
      component={AddressField}
      id={name}
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      validate={validate}
      onValueChange={onChange}
      onAutoSuggestComplete={onAutoSuggestComplete}
      onBlur={onBlur}
      disabled={disabled}
      coordsName={coordsName}
      zipcodeName={zipcodeName}
      includeNameInAddress={includeNameInAddress}
    />
  )
}
