import { useMutation } from '@apollo/react-hooks'
import { Formik } from 'formik'
import React, { useContext, useState } from 'react'
import { InjectedIntlProps, defineMessages, injectIntl } from 'react-intl'
import { ordererTypeOptions } from '../../../common/options'
import { Button } from '../../../components/Button'
import { Icon } from '../../../components/icons/Icon'
import { Page, SimpleRow } from '../../../components/layout'
import { ScrollToError } from '../../../components/layout/form/ScrollToError'
import { FormikForm as Form } from '../../../components/layout/form/style'
import { setSuccessNotification } from '../../../components/notification'
import { Loading } from '../../../components/responses'
import { resizeImage } from '../../../components/Signature'
import { setErrorNotifications, setFatalErrorNotification } from '../../../components/responses/setErrorNotifications'
import { useHistoryPush } from '../../../components/router'
import { useSignatureCanvasRef } from '../../../components/Signature/useSignatureCanvasRef'
import { Styling } from '../../../components/Styling'
import { translateInputOptions } from '../../../util/translateInputOptions'
import { EditCustomer } from '../../Customer/EditCustomer'
import { CustomerFormValues } from '../../Customer/types'
import { StateStore } from '../../StoreProvider'
import { getInitialValues } from './initialValues'
import { ADD_TOWING_RECORD_MUTATION } from './mutation/mutation'
import { toMutationVariables } from './mutation/toMutationVariables'
import { AddTowingRecordResponse } from './mutation/types'
import { TowingRecordFormFields } from './TowingRecordFormFields'
import { InitialValues } from './types'

const AddTowingRecordForm: React.FunctionComponent<InjectedIntlProps> = ({ intl }) => {
  const [showCustomerEditForm, setShowCustomerEditForm] = useState<boolean>(false)

  const historyPush = useHistoryPush()
  const { formatMessage } = intl
  const { state } = useContext(StateStore)

  const signaturePadRef = useSignatureCanvasRef()

  const [addTowingRecord, { loading }] = useMutation<AddTowingRecordResponse>(ADD_TOWING_RECORD_MUTATION, {
    onCompleted({ addTowingRecord }) {
      if (addTowingRecord.__typename === 'AddTowingRecordSuccess') {
        historyPush(`/towing-record/${addTowingRecord.towingRecord.id}`)
        setSuccessNotification(
          formatMessage(messages.addTowingRecordNotificationSuccessTitle),
          formatMessage(messages.addTowingRecordNotificationSuccessMessage)
        )
        return
      }
      setErrorNotifications({ data: addTowingRecord })
    },
    onError(err) {
      setFatalErrorNotification(err.message)
    },
  })

  const onCancel = () => {
    historyPush(`/towing-jobs`)
  }

  const initialValues = getInitialValues()
  const agreements = state.settings ? state.settings.agreements : []
  if (agreements.length > 0 && initialValues.signature) {
    initialValues.signature.agreements = agreements.map(a => {
      return {
        id: a.id,
        text: a.text,
        isAgreed: true,
      }
    })
  }

  const translatedTypesOptions = translateInputOptions(ordererTypeOptions, formatMessage)

  const handleSubmit = async (values: InitialValues) => {
    if (values.saveSignature && values.signature) {
      const writtenSignatureData = signaturePadRef.current ? resizeImage(signaturePadRef.current.getCanvas()) : ''
      values.signature.data = writtenSignatureData
    }

    await addTowingRecord({ variables: toMutationVariables(values) })
  }

  return (
    <Page noPadding>
      <Loading loading={loading} />
      <Formik
        initialValues={initialValues}
        onSubmit={(values: InitialValues) => handleSubmit(values)}
        render={({ values, setValues, setFieldValue }) => {
          const setValue = (field: string, value: any) => {
            setFieldValue(field, value)
          }

          const setOrderer = (o: CustomerFormValues) => {
            if (!values.orderer) {
              return
            }
            const currentOrdererType = values.orderer.type
            values.orderer = Object.assign({}, o)
            values.orderer.type = currentOrdererType
            setValues(values)
          }

          const setOrdererFromTrafi = (o: CustomerFormValues) => {
            values.orderer = Object.assign({}, o)
            setValues(values)
          }

          return (
            <>
              {showCustomerEditForm && values.orderer && (
                <EditCustomer
                  orderer={values.orderer}
                  onClose={() => setShowCustomerEditForm(false)}
                  onUpdate={orderer => {
                    setOrderer(orderer)
                    setShowCustomerEditForm(false)
                  }}
                />
              )}
              <Form onKeyDown={e => e.key === 'Enter' && e.preventDefault()}>
                <SimpleRow>
                  <div onClick={() => onCancel()}>
                    <Icon icon="arrow-left" />
                  </div>
                </SimpleRow>
                <TowingRecordFormFields
                  setValues={setValues}
                  values={values}
                  translatedTypesOptions={translatedTypesOptions}
                  setValue={setValue}
                  setShowCustomerEditForm={setShowCustomerEditForm}
                  setOrderer={setOrderer}
                  signaturePadRef={signaturePadRef}
                  setOrdererFromTrafi={setOrdererFromTrafi}
                />
                <Styling padding="0 1rem 0 1rem">
                  <Button category="save" size="l" label="Tallenna" submit></Button>
                </Styling>
                <ScrollToError />
              </Form>
            </>
          )
        }}
      />
    </Page>
  )
}

const messages = defineMessages({
  addTowingRecordNotificationSuccessTitle: {
    id: 'towingRecord.add.notification.success.title',
    defaultMessage: 'Towing record created',
  },
  addTowingRecordNotificationSuccessMessage: {
    id: 'towingRecord.add.notification.success.message',
    defaultMessage: 'New towing record created',
  },
})

export const AddTowingRecord = injectIntl(AddTowingRecordForm)
