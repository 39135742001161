import React, { useContext } from 'react'
import { useQuery } from 'react-apollo'
import { GenericResultHandler, GenericResultHandlerDefinition } from '../../components/responses/GenericResultHandler'
import { setFatalErrorNotification } from '../../components/responses/setErrorNotifications'
import { StateStore } from '../StoreProvider'
import { ShiftErrorPage } from './ShiftErrorPage'
import { CurrentShiftResponse, GET_CURRENT_SHIFT } from './ShiftQuery'
import { ViewShiftsPage } from './ViewShiftsPage'

const QueryResultHandler = GenericResultHandler as GenericResultHandlerDefinition<CurrentShiftResponse>

export const ShiftsPage: React.FunctionComponent = () => {
  const currentShiftResponse = useQuery<CurrentShiftResponse>(GET_CURRENT_SHIFT, {
    fetchPolicy: 'network-only',
    onError(err) {
      setFatalErrorNotification(err.message)
    },
  })
  const {
    state: { settings },
  } = useContext(StateStore)

  if (settings && settings.modules && settings.modules.shifts === false) {
    return <ShiftErrorPage message="Työajanseuranta ei ole käytössä yrityksellänne" />
  }
  return (
    <QueryResultHandler queryResult={currentShiftResponse} loading unauthorized>
      {data => {
        if (data && data.currentShift.__typename === 'GetCurrentShiftSuccess') {
          return (
            <ViewShiftsPage
              currentShift={data.currentShift.currentShift}
              refetch={() => currentShiftResponse.refetch()}
            />
          )
        }
        if (data && data.currentShift.__typename === 'ShiftError') {
          return <ShiftErrorPage message={data.currentShift.error.message} />
        }
      }}
    </QueryResultHandler>
  )
}
