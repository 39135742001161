export const transformDrive = (value: string) => {
  switch (value) {
    case '2WD Etuveto':
    case 'Hybrid':
      return 'FWD'
    case '2WD Takaveto':
      return 'RWD'
    case '4WD':
      return '4WD'
    default:
      return '-'
  }
}

export const transformTransmission = (value: string) => {
  if (value === '' || value === 'Ei tiedossa' || value === 'Ei tiedoss') {
    return '-'
  }
  return value
}

export const transformFuel = (value: string) => {
  return transformTransmission(value)
}

export const transformVehicleClass = (value: string) => {
  return transformTransmission(value)
}
