import { FieldProps, getIn } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { FieldContainer, FormikTextAreaField as Field, Label, Error } from '../../style'

interface Props {
  id: string
  label?: string
  name: string
  disabled?: boolean
  required?: boolean
  placeholder?: string
}

export const TextAreaField: React.FunctionComponent<Props & FieldProps> = ({
  id,
  field,
  form,
  label,
  name,
  placeholder,
  required,
  disabled = false,
  ...rest
}) => {
  const [value, setValue] = useState<string>(field.value || '')

  useEffect(() => {
    setValue(field.value)
  }, [field.value])

  const handleChange = useCallback(
    event => {
      const targetValue = event.target.value

      setValue(targetValue)

      const trimmedValue = targetValue.trim()

      form.setFieldValue(field.name, trimmedValue)
      form.setFieldTouched(field.name, true)
    },
    [field, form]
  )

  const fieldError = getIn(form.errors, field.name)
  const fieldTouched = getIn(form.touched, field.name)
  const hasErrors = Boolean(fieldError) && Boolean(fieldTouched)

  return (
    <FieldContainer {...rest}>
      {label && <Label required={required}>{label}</Label>}

      <Field
        id={id}
        component="textarea"
        rows={4}
        name={name}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={handleChange}
      />
      {hasErrors && <Error className="error-tooltip">{fieldError}</Error>}
    </FieldContainer>
  )
}
