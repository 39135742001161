import { useQuery } from '@apollo/react-hooks'
import React, { FunctionComponent, useEffect } from 'react'
import {
  GenericResultHandler,
  GenericResultHandlerDefinition,
} from '../../../components/responses/GenericResultHandler'
import { setFatalErrorNotification } from '../../../components/responses/setErrorNotifications'
import { TOWING_ORDER_QUERY, TowingOrderQueryVariables, TowingOrderResponse } from './towingOrderQuery'
import { ViewTowingOrderContent } from './ViewTowingOrderContent'
import { useSocket } from '../../../util/useSocket'
import { getStoredCompanyId } from '../../../graphql-client/storedState'

interface Props {
  id: number
}

export const ViewTowingOrderPage: FunctionComponent<Props> = ({ id }) => {
  const queryResult = useQuery<TowingOrderResponse, TowingOrderQueryVariables>(TOWING_ORDER_QUERY, {
    fetchPolicy: 'network-only',
    variables: { id },
    onError(err) {
      setFatalErrorNotification(err.message)
    },
  })
  const { refetch } = queryResult
  const socket = useSocket();
  const companyId = getStoredCompanyId();
  
  useEffect(()=>{
    if(!companyId){
      return;
    }

    const callback = ()=>{refetch()};

    socket.on(`company-${companyId}.towing_order-${id}.locked`, callback);
    socket.on(`company-${companyId}.towing_order-${id}.unlocked`, callback);
    return ()=>{
      console.log('removing event listeners from towing order');
      socket.removeEventListener(`company-${companyId}.towing_order-${id}.locked`, callback);
      socket.removeEventListener(`company-${companyId}.towing_order-${id}.unlocked`, callback); 
    }
  },[companyId, socket, refetch, id]);

  const TypedGenericResultHandler = GenericResultHandler as GenericResultHandlerDefinition<TowingOrderResponse>

  return (
    <TypedGenericResultHandler queryResult={queryResult} loading unauthorized notFound>
      {data => {
        const refetchData = () => refetch()

        if (data && data.towingOrder.__typename === 'GetTowingOrderSuccess') {
          return <ViewTowingOrderContent refetchData={refetchData} towingOrder={data.towingOrder.towingOrder}/>
        }
      }}
    </TypedGenericResultHandler>
  )
}
