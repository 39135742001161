import ApolloClient from 'apollo-client'
import { FormikProps, getIn } from 'formik'
import React, { useCallback } from 'react'
import { useApolloClient } from 'react-apollo'
import { LocationInputMapProps } from '../..'
import { LocationInput } from '../../..'

import { ucfirst } from '../../../../../../../util/common'
import { GET_COORDS } from '../../../../../../RouteAddressSelector/query'
import { GeocodeResponse } from '../../../../../../RouteAddressSelector/types'
import { CityInput } from '../../../CityInput'
import styled from 'styled-components'

import { LocationButtonRow } from '../../../../../buttonlayout'
import { Coords } from '../../../../../../../common/types'
import { Icon } from '../../../../../../icons/Icon'

interface Props extends LocationInputMapProps {}

export const LocationInputWrapper: React.FunctionComponent<Props> = ({
  addressLabel,
  addressName,
  cityLabel,
  cityName,
  coordsName,
  zipcodeName,
  descriptionName,
  required,
  disabled,
  coordsRequired,
  setValue,
  getValue,
  includeNameInAddress = true,
  clear,
}) => {
  const client = useApolloClient()

  const getCoords = useCallback(
    async (client: ApolloClient<any>, variables: { address: string }, address: string, city: string) => {
      try {
        const response = await client.query<GeocodeResponse>({ variables, query: GET_COORDS })

        if (response.data && response.data.geocode && response.data.geocode.__typename === 'GetGeocodeSuccess') {
          console.log('GetGeocodeSuccess')
          const { lat, long } = response.data.geocode.result
          const formattedAddress = ucfirst(address)
          const formattedCity = ucfirst(city)
          setValue(coordsName, { lat, long })
          setValue(addressName, formattedAddress)
          setValue(cityName, formattedCity)
        }
      } catch (e) {
        console.error(e)
      }
    },
    [coordsName, setValue, addressName, cityName]
  )

  const handleLocationBlur = useCallback(
    (form: FormikProps<any>) => {
      if (coordsRequired) {
        const address = getIn(form.values, addressName)
        const coords = getIn(form.values, coordsName)
        const city = getIn(form.values, cityName)
        if ((!coords || (!coords.lat || !coords.long)) && city && address) {
          if (address.length >= 3 && city.length >= 2) {
            getCoords(client, { address: `${address}, ${city}` }, address, city)
          }
        }
      }
    },
    [coordsRequired, coordsName, cityName, addressName, getCoords, client]
  )

  const handleCityBlur = useCallback(
    (form: FormikProps<any>) => {
      if (coordsRequired) {
        const address = getIn(form.values, addressName)
        const coords = getIn(form.values, coordsName)
        const city = getIn(form.values, cityName)
        if ((!coords || (!coords.lat || !coords.long)) && city && address) {
          if (address.length >= 3 && city.length >= 2) {
            getCoords(client, { address: `${address}, ${city}` }, address, city)
          }
        }
      }
    },
    [coordsRequired, coordsName, cityName, addressName, getCoords, client]
  )

  const hasCoordinates = getValue && checkForCoordinates(getValue(cityName), getValue(coordsName))
  return (
    <ComboInputWrapper>
      <LocationInput
        addressLabel={addressLabel}
        addressName={addressName}
        cityName={cityName}
        coordsName={coordsName}
        zipcodeName={zipcodeName}
        required={required}
        disabled={disabled}
        descriptionName={descriptionName}
        coordsRequired={coordsRequired}
        onBlur={handleLocationBlur}
        includeNameInAddress={includeNameInAddress}
      />
      <CityInput
        label={cityLabel}
        name={cityName}
        coordsName={coordsName}
        required={required}
        disabled={disabled}
        onBlur={handleCityBlur}
        shrink
      />
      <LocationButtonRow>
        {clear && (
          <AddressButton disabled={disabled} type="button" backgroundColor={'red400'} onClick={clear}>
            <Icon icon="trash" size="x-small" color="white" />
          </AddressButton>
        )}
        <AddressButton
          type="button"
          backgroundColor={hasCoordinates ? 'green100' : 'grey300'}
          borderRadius="0 5px 5px 0"
        >
          <Icon icon="world" size="x-small" color={hasCoordinates ? 'green700' : 'grey700'} />
        </AddressButton>
      </LocationButtonRow>
    </ComboInputWrapper>
  )
}

export const checkForCoordinates = (city?: string, coords?: Coords) => {
  if (!city) return false
  if (coords && coords.lat && coords.long) return true
  return false
}

export const ComboInputWrapper = styled.div`
  display: flex;

  & div:first-of-type > input {
    border-radius: 5px 0 0 5px;
  }

  & > div:not(:last-of-type) > input {
    border-radius: 0;
  }
`
// border-top-right-radius: ${props => (props.disabled ? '5px' : '0')};
//     border-bottom-right-radius: ${props => (props.disabled ? '5px' : '0')};
interface AddressButtonProps {
  backgroundColor?: string
  margin?: string
  borderRadius?: string
  disabled?: boolean
}

export const AddressButton = styled.button<AddressButtonProps>`
  display: ${props => (props.disabled ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '0')}
  background-color: ${props =>
    props.backgroundColor ? props.theme.colors[props.backgroundColor] : props.theme.colors.white};
  height: 38px;
  width: 40px;
  margin: ${props => (props.margin ? props.margin : '0 0 0 0rem')}
`
