import React, { useEffect, useContext, useCallback } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { StateStore, DispatchStore } from '../../containers/StoreProvider'
import { TOWING_JOBS_OPEN_QUERY, TowingJobsResponse, OpenTowingJob } from './queries'
import { isActiveJob } from '../../containers/TowingJobsPage/countJobs'
import { setOpenJobsCount } from '../../containers/StoreProvider/actions'
import { useTowingJobsListChange } from '../../containers/TowingJobsPage/towingJobsWatcher'

export const countJobs = (orders: OpenTowingJob[]): number =>
  orders.reduce((noOfOpenJobs, currentOrder) => {
    if (isActiveJob(currentOrder.status, currentOrder.operatorId)) {
      return noOfOpenJobs + 1
    }
    return noOfOpenJobs
  }, 0)

export const OpenJobCountWatcher: React.FC = () => {
  const {
    state: { openJobsCount },
  } = useContext(StateStore)
  const { dispatch } = useContext(DispatchStore)

  const { refetch } = useQuery<TowingJobsResponse>(TOWING_JOBS_OPEN_QUERY, {
    variables: {
      status: 'OPEN',
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted(response) {
      if (response.towingOrders.__typename === 'GetTowingOrdersSuccess') {
        const openJobs = countJobs(response.towingOrders.towingOrders)
        dispatch(setOpenJobsCount(openJobs))
      }
    },
  })

  const callback = useCallback(()=>{
    setTimeout(() => {
      refetch()
    }, 5000)
  }, [refetch])

  useTowingJobsListChange(callback)

  useEffect(() => {
    if (openJobsCount === null) {
      refetch()
    }
  }, [openJobsCount, refetch])

  return null
}
