import { Field, FieldProps } from 'formik'
import styled, { css } from 'styled-components'

export const FieldStyles = css`
  padding: 0.25rem 0.5rem;
  border-radius: ${props => props.theme.radius.medium};
  border: 1px solid ${props => props.theme.colors.grey300};
  color: ${props => props.theme.colors.black}
`

export const FormikField = styled(Field)<FieldProps>`
  ${FieldStyles}
  height: ${props => props.theme.inputFieldHeight};
  background-color: ${props => (props.disabled ? props.theme.colors.grey200 : props.theme.colors.white)};
  ${props =>
    props.disabled &&
    css`
      color: ${props => props.theme.colors.greyDisabled};
    `};
 
`

export const FormikTextAreaField = styled(Field)<FieldProps>`
  ${FieldStyles}
  resize: none;
  background-color: ${props => (props.disabled ? props.theme.colors.grey200 : props.theme.colors.white)};
 
`
