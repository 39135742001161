import { ShiftPageProps, WorkShiftPageViews, EventTypeOption } from '../types'
import React, { useState } from 'react'
import { ShiftHeader, ShiftHeading } from '../../../components/ShiftTable/layout'
import { BigButtonSection } from '../../../components/layout'
import { Button } from '../../../components/Button'
import { AddEventPage } from '../AddEvent'
import { ShiftTable } from '../../../components/ShiftTable'

interface WorkPageProps extends ShiftPageProps {
  standByWork?: boolean
}

export const WorkPage: React.FunctionComponent<WorkPageProps> = ({
  currentShift,
  standByWork,
  refetch,
  shiftGroup,
}) => {
  const [view, setView] = useState<WorkShiftPageViews>(WorkShiftPageViews.default)
  const label = standByWork ? 'Töissä (varalla)' : 'Töissä'
  return (
    <>
      {view === WorkShiftPageViews.default && (
        <>
          <ShiftHeader color="green300">
            <ShiftHeading label={label} />
            <BigButtonSection>
              <Button
                category="new"
                label="Aloita tauko"
                onClick={() => {
                  setView(WorkShiftPageViews.break_start_form)
                }}
                size="m"
                fontWeight="normal"
                maxWidth="100%"
                disabled={standByWork}
              />
              {shiftGroup && shiftGroup.enableWait && (
                <Button
                  category="new"
                  label="Aloita odotus"
                  size="m"
                  fontWeight="normal"
                  maxWidth="100%"
                  onClick={() => {
                    setView(WorkShiftPageViews.wait_start_form)
                  }}
                  disabled={standByWork}
                />
              )}
              <Button
                category="new"
                label="Lopeta työvuoro"
                size="m"
                fontWeight="normal"
                maxWidth="100%"
                onClick={() => {
                  setView(WorkShiftPageViews.work_end_form)
                }}
              />
            </BigButtonSection>
          </ShiftHeader>
          <ShiftTable shift={currentShift} />
        </>
      )}

      {view === WorkShiftPageViews.break_start_form && (
        <AddEventPage
          type={EventTypeOption.break_start}
          onClose={() => setView(WorkShiftPageViews.default)}
          shift={currentShift}
          refetch={refetch}
        />
      )}

      {view === WorkShiftPageViews.wait_start_form && (
        <AddEventPage
          type={EventTypeOption.wait_start}
          onClose={() => setView(WorkShiftPageViews.default)}
          shift={currentShift}
          refetch={refetch}
        />
      )}

      {view === WorkShiftPageViews.work_end_form && (
        <>
          {standByWork && (
            <AddEventPage
              type={EventTypeOption.standby_work_end}
              onClose={() => setView(WorkShiftPageViews.default)}
              shift={currentShift}
              refetch={refetch}
            />
          )}
          {!standByWork && (
            <AddEventPage
              type={EventTypeOption.work_end}
              onClose={() => setView(WorkShiftPageViews.default)}
              shift={currentShift}
              refetch={refetch}
            />
          )}
        </>
      )}
    </>
  )
}
