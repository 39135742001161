import React, { useContext, useEffect, useState } from 'react'
import { RouteColumn, RouteRow, RouteWrapper } from '../../../../../../components/layout/'
import { TextAreaInput, TimeSelectorInput } from '../../../../../../components/layout/form/input'
import { AutoliittoDisableFields, GeoLocateAddressData, SubmitFormType } from '../../types'
import { RouteHeader } from '../RouteHeader'
import { AutoliittoSeparatorLine } from '../styled'
import { Maybe, Time, TowingRecordJobStatus, TowingRecordType } from '../../../../../../common/types'
import { Geolocation, GeolocationType } from '../../../../../../components/Geolocation/types'
import GetGeolocation from '../../../../../../components/Geolocation'
import { useApolloClient } from 'react-apollo'
import { setErrorNotification } from '../../../../../../components/notification'
import { toMapData, LocationInputMap } from '../../../../../../components/layout/form/input/LocationInputMap'
import { ArrivalDateTimeInput } from '../../../../../../components/layout/form/input/DateTimeInput'
import { format } from 'date-fns'
import { RouteNotes } from '../../RouteNotes'
import { ArrivalRouteAction } from '../../../../../../components/SosSubForm/ArrivalRoute/types'
import { RoutesFormSelector } from '../../RoutesFormSelector'
import { getBatteryServiceOptions } from '../../../../../../components/ALSubForm/options'
import { StateStore } from '../../../../../StoreProvider'
import { Images } from '../../../../../../components/images'
import { ArrivedForm } from './forms/ArrivedForm'
import { FinishedForm } from './forms/FinishedForm'

export interface LocationOption {
  value: string
  label: string
}
export const startLocationOther = 'OTHER'

interface ArrivalRouteProps {
  onCancel: () => void
  regNo: string
  isAutoliitto: boolean
  setAutoliittoDateField: (fieldName: string) => void
  setRouteLocation: (addressData: GeoLocateAddressData | null) => void
  clear: () => void
  setGeolocatedField: (geolocated: boolean) => void
  initialValues: any
  isSos: boolean
  // serviceChannel: string | null
  // formatMessage: FormatMessageType
  routeDone: boolean
  setLocation?: (locationData: Geolocation, fieldName: string) => void
  getCoordsFromAddress: boolean
  // values: any
  setValue: (field: string, value: any) => void
  getValue: (field: string) => any
  action: ArrivalRouteAction
  setAction: (action: ArrivalRouteAction) => void
  jobStatus: TowingRecordJobStatus
  stationId: Maybe<number>
  useExtendedRoutes: boolean
  translatedServiceTypeOptions: object[]
  setSelectedForm: (form: SubmitFormType) => void
  selectedForm: SubmitFormType
  towingRecordId: number
  autoliittoFields: AutoliittoDisableFields
}

export const ArrivalRoute: React.FunctionComponent<ArrivalRouteProps> = ({
  onCancel,
  regNo,
  isAutoliitto,
  setAutoliittoDateField,
  setRouteLocation,
  clear,
  initialValues,
  isSos,
  routeDone,
  setLocation,
  getCoordsFromAddress,
  setValue,
  getValue,
  action,
  setAction,
  jobStatus,
  stationId,
  useExtendedRoutes,
  translatedServiceTypeOptions,
  setSelectedForm,
  selectedForm,
  towingRecordId,
  autoliittoFields,
}) => {
  const { state } = useContext(StateStore)

  const client = useApolloClient()

  const [showImageModal, setShowImageModal] = useState<boolean>(false)

  const label = isSos ? 'Kohde' : 'Kohteessa'
  const disableCommonForm = isSos && routeDone

  const showExtendedroutes = useExtendedRoutes || isSos

  const batteryServiceOptions = getBatteryServiceOptions(state.itemGroups, state.items)

  useEffect(() => {
    if (routeDone && action === 'edit-finished') {
      setSelectedForm('finished')
    }
  }, [action, routeDone, setSelectedForm])

  const toggleImageModal = () => {
    setShowImageModal(!showImageModal)
  }

  const finishedJobStatus = [
    TowingRecordJobStatus.finished_at_breakdown_location,
    TowingRecordJobStatus.arrived_at_destination,
    TowingRecordJobStatus.finished_at_destination,
    TowingRecordJobStatus.stopped,
  ]
  const towingRecordType = isSos
    ? TowingRecordType.sos
    : isAutoliitto
    ? TowingRecordType.autoliitto
    : TowingRecordType.default

  return (
    <RouteWrapper>
      <RouteHeader label={label} regNo={regNo} cancel={onCancel} toggleImageModal={toggleImageModal} />
      {showImageModal && <Images onClose={toggleImageModal} towingRecordId={towingRecordId} vehicleRegNo={regNo} />}
      
      {showExtendedroutes ? (
        <RoutesFormSelector
          action={action}
          setAction={setAction}
          routeDone={routeDone}
          arrived={[]}
          finished={finishedJobStatus}
          jobStatus={jobStatus}
          selectedForm={selectedForm}
          setSelectedForm={setSelectedForm}
        >
          {selectedForm === 'arrived' && (
            <ArrivedForm
              setDateField={setAutoliittoDateField}
              setRouteLocation={setRouteLocation}
              clear={clear}
              setValue={setValue}
              getValue={getValue}
              action={action}
              type={towingRecordType}
              stationId={stationId}
              setAction={setAction}
              jobStatus={jobStatus}
            />
          )}
          {selectedForm === 'finished' && (
            <FinishedForm
              setValue={setValue}
              getValue={getValue}
              setAction={setAction}
              setRouteLocation={setRouteLocation}
              jobStatus={jobStatus}
              clear={clear}
              setDateField={setAutoliittoDateField}
              action={action}
              autoliittoFields={autoliittoFields}
              type={towingRecordType}
              translatedServiceTypeOptions={translatedServiceTypeOptions}
              batteryServiceOptions={batteryServiceOptions}
            />
          )}
        </RoutesFormSelector>
      ) : (
        <>
          <AutoliittoSeparatorLine marginTop="1.5rem" marginBottom="1.5rem" />
          <RouteRow disabled={disableCommonForm}>
            <RouteColumn>
              <LocationInputMap
                addressLabel={`Osoite`}
                addressName={`arrivalRoute.address`}
                cityLabel={`Kaupunki`}
                cityName={`arrivalRoute.city`}
                coordsName={`arrivalRoute.coords`}
                zipcodeName={`arrivalRoute.zipcode`}
                required={isSos}
                coordsRequired={isSos}
                getValue={getValue}
                values={toMapData(
                  getValue(`arrivalRoute.address`),
                  getValue(`arrivalRoute.city`),
                  getValue(`arrivalRoute.coords`),
                  getValue(`arrivalRoute.zipcode`)
                )}
                setValue={setValue}
                disabled={disableCommonForm}
                setRouteLocation={setRouteLocation}
                stationId={stationId}
                clear={clear}
              />
            </RouteColumn>

            <RouteColumn>
              <ArrivalDateTimeInput
                label="Saapumisaika"
                name={`arrivalRoute`}
                customOnChange={() => {
                  const value = (format(new Date(), 'HH:mm') as unknown) as Time
                  setAutoliittoDateField('arrivalRoute.arrivalDate')
                  setValue('arrivalRoute.arrivalTime', value)
                }}
                required={isAutoliitto || isSos}
                disabled={disableCommonForm}
                showCurrentTimeBtn
              />
            </RouteColumn>
          </RouteRow>
          {isAutoliitto && (
            <>
              <AutoliittoSeparatorLine />
              <RouteRow>
                <RouteColumn>
                  <TimeSelectorInput
                    timeSelectorLabel="Arvioitu työnkesto"
                    timeFieldLabel="Arvioitu valmistumisaika"
                    name="automobileAndTouringClubFinland.estimatedTimeOfCompletion.time"
                    required={isAutoliitto}
                    customOnChange={() =>
                      setAutoliittoDateField('automobileAndTouringClubFinland.estimatedTimeOfCompletion.date')
                    }
                  />
                </RouteColumn>
              </RouteRow>
            </>
          )}
          {isSos && (
            <>
              <AutoliittoSeparatorLine />
              {getCoordsFromAddress && (
                <GetGeolocation
                  client={client}
                  setError={setErrorNotification}
                  type={GeolocationType.geocode}
                  reportGeolocation={setLocation}
                  fieldName={`sosServiceOrder.currentLocation`}
                  address={initialValues.arrivalRoute.address || ''}
                  city={initialValues.arrivalRoute.city || ''}
                />
              )}
            </>
          )}
        </>
      )}

      {showExtendedroutes && (
        <RouteColumn>
          <TextAreaInput label="Lisäselvitykset" name="additionalInfo" />
        </RouteColumn>
      )}
      <RouteNotes removeLine={isSos || useExtendedRoutes} />
    </RouteWrapper>
  )
}
