import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  cancel: { id: 'button.cancel', defaultMessage: 'cancel' },
  clear: { id: 'button.clear', defaultMessage: 'clear' },
  edit: { id: 'button.edit', defaultMessage: 'edit' },
  save: { id: 'button.save', defaultMessage: 'save' },
  saveAndAssign: { id: 'button.save_and_assign', defaultMessage: 'save and assign' },
  saveAndList: { id: 'button.save_and_list', defaultMessage: 'save and list' },
  search: { id: 'button.search', defaultMessage: 'search' },
  leave: { id: 'button.leave', defaultMessage: 'leave' },
})
