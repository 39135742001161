import { useCallback, useEffect, useState } from 'react'
import { useApolloClient } from 'react-apollo'
import { Maybe } from '../../common/types'
import { DIRECTIONS } from '../../components/layout/form/field/TimeField/queries'
import { REVERSE_GEOCODE } from '../../components/ReverseGeocodeButton/queries'
import { ArrivalRouteArgs } from '../../containers/TowingRecordPage/components/routes/subforms/StartRoute/types'

interface EtaCalculationOptions extends PositionOptions {
  calculate: boolean
  destination?: ArrivalRouteArgs
}

interface DirectionsResponse {
  data: any
}

interface LocationData {
  readonly accuracy: number | null
  readonly altitude: number | null
  readonly altitudeAccuracy: number | null
  readonly heading: number | null
  readonly latitude: number | null
  readonly longitude: number | null
  readonly speed: number | null
  readonly timestamp: number | null
  readonly error: string | null
}

type EtaCalculationCallBack = (eta: Date) => void

export const useEtaCalculation = (
  { enableHighAccuracy, maximumAge, timeout, calculate, destination }: EtaCalculationOptions = { calculate: false },
  callback: EtaCalculationCallBack
) => {
  const [coordinates, setCoordinates] = useState<LocationData>({
    accuracy: null,
    altitude: null,
    altitudeAccuracy: null,
    heading: null,
    latitude: null,
    longitude: null,
    speed: null,
    timestamp: null,
    error: null,
  })

  const client = useApolloClient()

  const getRouteTimeEstimate = useCallback(
    async (waypoints: any) => {
      const { data }: DirectionsResponse = await client.query({
        variables: { waypoints },
        query: DIRECTIONS,
      })

      if (data.directions.__typename === 'GoogleAPIGeolocationError') {
        console.log('Route calculation returned an error')
      }

      console.log('data', data)

      if (
        data &&
        data.directions &&
        data.directions.result &&
        data.directions.result.routes[0].legs[0].duration.value
      ) {
        const seconds = data.directions.result.routes[0].legs[0].duration.value
        const estimatedTimeSeconds = new Date().getSeconds() + seconds
        const estimatedTime = new Date().setSeconds(estimatedTimeSeconds)
        const eta = new Date(estimatedTime)

        if (callback) {
          callback(eta)
        }
      }
    },
    [client, callback]
  )

  const calculateEstimate = useCallback(
    async (latitude: number, longitude: number) => {
      const { data }: DirectionsResponse = await client.query({
        variables: { location: { lat: latitude, long: longitude } },
        query: REVERSE_GEOCODE,
      })

      if (data.reverseGeocode.__typename === 'GoogleAPIGeolocationError') {
        console.error('getCurrentPosition returned with error')
      } else {
        const currentPosition = {
          address: data.reverseGeocode.result.address ? data.reverseGeocode.result.address : '',
          city: data.reverseGeocode.result.city ? data.reverseGeocode.result.city : '',
          lat: latitude ? latitude : null,
          long: longitude ? longitude : null,
        }
        if (!currentPosition || !destination) return
        const waypoints = {
          data: [currentPosition, destination],
        }

        await getRouteTimeEstimate(waypoints)
      }
    },
    [getRouteTimeEstimate, client, destination]
  )

  useEffect(() => {
    let didCancel: boolean = false
    const updateCoordinates = ({ coords, timestamp }: Position) => {
      const { accuracy, altitude, altitudeAccuracy, heading, latitude, longitude, speed } = coords
      if (!didCancel) {
        setCoordinates({
          accuracy,
          altitude,
          altitudeAccuracy,
          heading,
          latitude,
          longitude,
          speed,
          timestamp,
          error: null,
        })
        if (calculate) {
          calculateEstimate(latitude, longitude)
        }
      }
    }

    const setError = (error: any) => {
      if (!didCancel) {
        setCoordinates({
          accuracy: null,
          altitude: null,
          altitudeAccuracy: null,
          heading: null,
          latitude: null,
          longitude: null,
          speed: null,
          timestamp: null,
          error,
        })
      }
    }

    let watchId: Maybe<number> = null
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(updateCoordinates, setError)
      watchId = navigator.geolocation.watchPosition(updateCoordinates, setError, {
        enableHighAccuracy,
        maximumAge,
        timeout,
      })
    }
    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId)
      }
      didCancel = true
    }
  }, [callback, enableHighAccuracy, maximumAge, timeout, calculate, calculateEstimate])

  return coordinates
}
