import { CancelOrEndTowingRecordFormValues } from '../types'
import { CancelTowingRecordMutationVariables } from './types'

export function toCancelMutationVariables(values: CancelOrEndTowingRecordFormValues): CancelTowingRecordMutationVariables {
  return {
    input: {
      id: values.towingRecordId,
      cancelReason: values.cancelReason,
      withFee: values.withFee,
    },
  }
}
