import React from 'react'
import styled from 'styled-components'

const StyledLink = styled('a')`
  font-size: 1.25rem;
  text-decoration: underline;
  color: ${props => props.theme.colors.blueDarkLight};
  cursor: pointer;
  :hover {
    color: ${props => props.theme.colors.blue600};
  }
`

interface Props {
  onClick: () => void
  children: React.ReactNode
}

const LinkButton: React.FunctionComponent<Props> = ({ onClick, children, ...rest }) => {
  return (
    <StyledLink {...rest} onClick={onClick}>
      {children}
    </StyledLink>
  )
}

export default LinkButton
