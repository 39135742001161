import React from 'react'
import { Maybe, TowingRecordJobStatus, TowingRecordType } from '../../../../../../../common/types'
import { FinishRouteAction } from '../../../../../../../components/SosSubForm/FinishRoute/types'
import { SosArrivedAtDestinationForm } from '../../../../../../../components/SosSubForm/FinishRouteForm/ArrivalForm'
import { GeoLocateAddressData } from '../../../types'
import { ArrivedAtDestinationFormAL } from './ArrivedAtDestinationFormAL'
import { ShowAddressOptions } from '../FinishRoute'
import { ArrivedAtDestinationForm } from './ArrivedAtDestinationForm'

export interface ArrivedAtDestinationProps {
  setValue: (field: string, value: any) => void
  getValue: any
  setDateField: (fieldName: string) => void
  setRouteLocation: (addressData: GeoLocateAddressData | null) => void
  clear: () => void
  action: FinishRouteAction
  setAction: (action: FinishRouteAction) => void
  jobStatus: TowingRecordJobStatus
  stationId: Maybe<number>
  type: TowingRecordType
}

export interface DefaultProps extends ArrivedAtDestinationProps {}

export interface SosProps extends ArrivedAtDestinationProps {}

export interface ALProps extends ArrivedAtDestinationProps {
  serviceChannel: string | null
  repairShopId: string | null
  setShowLocationChange: (value: boolean) => void
  showAddressInput: ShowAddressOptions
}

export const ArrivedForm = ({ type, ...props }: ArrivedAtDestinationProps | ALProps) => {
  if (type === TowingRecordType.sos) return <SosArrivedAtDestinationForm {...(props as SosProps)} />
  if (type === TowingRecordType.autoliitto) return <ArrivedAtDestinationFormAL {...(props as ALProps)} />
  if (type === TowingRecordType.default) return <ArrivedAtDestinationForm {...(props as DefaultProps)} />
  return null
}
