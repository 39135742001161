import React, { FunctionComponent, useContext } from 'react'
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl'
import { StateStore } from '../../containers/StoreProvider'
import { logout } from '../../util/login'
import { Button } from '../Button'
import { ButtonColumn, Text } from '../layout'

const messages = defineMessages({
  logout: {
    id: 'containers.logout.logout',
    defaultMessage: 'logout',
  },
})

const Logout: FunctionComponent<InjectedIntlProps> = ({ intl }) => {
  const { formatMessage } = intl

  const { state } = useContext(StateStore)

  if (!state || !state.currentUser) {
    return null
  }

  return (
    <>
      <ButtonColumn>
        <Text size="m">{state.currentUser.username}</Text>
      </ButtonColumn>
      <ButtonColumn mobileMargin="0">
        <Button
          label={formatMessage(messages.logout)}
          category="logout"
          mobileWidth="100%"
          onClick={() => {
            logout()
          }}
        />
      </ButtonColumn>
    </>
  )

  // return (
  //   <FormSubGroupWrapper>
  //     <FormSubGroupHeader>
  //       <FormSubGroupHeading>Kirjautunut käyttäjä</FormSubGroupHeading>
  //     </FormSubGroupHeader>
  //     <FormSubGroup>
  //       <Section>
  //         <UserSectionColum>
  //           <UserLabel>Käyttäjänimi</UserLabel>
  //           {state.currentUser.username}
  //         </UserSectionColum>
  //       </Section>

  //       <ButtonRow>
  //         <ButtonColumn>
  //           <Button
  //             label={formatMessage(messages.logout)}
  //             category="logout"
  //             onClick={() => {
  //               logout()
  //             }}
  //           />
  //         </ButtonColumn>
  //       </ButtonRow>
  //     </FormSubGroup>
  //   </FormSubGroupWrapper>
  // )
}

export default injectIntl(Logout)
