import React, { useContext } from 'react'
import { TowingRecord } from '../../ViewTowingRecord/types'
import { RouteForm, AutoliittoSeparatorLine } from '../routes/subforms/styled'
import { RouteWrapper, BigButtonSection, Text } from '../../../../components/layout'
import { RouteHeader } from '../routes/subforms/RouteHeader'
import { Styling } from '../../../../components/Styling'
import { Button } from '../../../../components/Button'
import { StateStore } from '../../../StoreProvider'
import { ACS } from '../../../../common/AccessControl'
import { EditRouteSubforms } from '../routes/types'

interface EndTowingRecordProps {
  onCancel: () => void
  towingRecord: TowingRecord
  setNextForm: (id: EditRouteSubforms, type: 'end'|'cancel') => void
}

export const EndTowingRecord: React.FunctionComponent<EndTowingRecordProps> = ({
  onCancel,
  towingRecord,
  setNextForm
}) => {

  const { state } = useContext(StateStore)
  const accessControl = new ACS(state.currentUser).setTowingRecord(towingRecord)

  return (
    <>
      <RouteForm>
        <RouteWrapper>
          <RouteHeader label={`Lopeta työ`} regNo={towingRecord.vehicleDetails.registrationNumber} cancel={onCancel} />
          <Styling padding="0 2rem" paddingMobile="0 0.5rem">
            <BigButtonSection>
              <Button
                category="on"
                label="Lopeta työ"
                mobileLabel="Lopeta työ"
                onClick={() => {
                  setNextForm('cancel-or-end-towing-record', 'end')
                }}
                size="m"
                fontWeight="normal"
                maxWidth="100%"
                disabled={!accessControl.canTowingRecord('finalize')}
              />
              <Button
                category="cancel"
                label="Työ peruutettu"
                onClick={() => {
                  setNextForm('cancel-or-end-towing-record', 'cancel')
                }}
                size="m"
                fontWeight="normal"
                maxWidth="100%"
              />
            </BigButtonSection>
            <AutoliittoSeparatorLine marginTop="1rem" marginBottom="1rem" />
            {!accessControl.canTowingRecord('finalize') && (
            <Text style={{fontWeight: 'bold'}}>
              Huom! Paikan päällä suoritetussa työssä pitää olla kirjattu kohteen tiedot ja hinauksissa kohteen sekä määränpään tiedot ennen kuin työ voidaan merkitä valmiiksi
            </Text>)}
          </Styling>
        </RouteWrapper>
      </RouteForm>
    </>
  )
}

