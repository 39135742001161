import gql from 'graphql-tag'

export const DELETE_JOB_ITEM = gql`
  mutation DeleteTowingRecordJobItem($input: DeleteTowingRecordJobItemInput!) {
    deleteTowingRecordJobItem(input: $input) {
      __typename
      ... on DeleteTowingRecordJobItemSuccess {
        success
      }
      ... on UnauthorizedError {
        error {
          code
          message
          details
          context
        }
      }
      ... on ResourceNotFoundError {
        error {
          code
          message
          details
          context
        }
      }
    }
  }
`
