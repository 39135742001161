import { DefaultTheme } from 'styled-components'

const defaultColors = {
  black: '#000',
  white: '#fff',
  yellow600: '#fabf40',
  grey600: '#333',
  green600: '#43a047',
  orange600: '#fb8c00',
  red600: '#e53935',
  blue600: '#1e88e5',
}
export const theme: DefaultTheme = {
  radius: {
    small: '2px',
    medium: '5px',
    large: '10px',
    none: '0px',
  },

  colors: {
    black: defaultColors.black,
    white: defaultColors.white,
    yellow: defaultColors.yellow600,
    orange: defaultColors.orange600,
    green: defaultColors.green600,
    red: defaultColors.red600,
    blue: defaultColors.blue600,
    grey: defaultColors.grey600,

    sosBackgroundColor: 'rgba(221, 41, 30, 0.1)',
    sosBorderColor: 'rgba(221, 41, 30, 0.5) !important',
    autoliittoYellow: '#fd0',
    towingRecordWhite: '#fff',
    blueDarkLight: '#1565c0',
    greyDisabled: '#999999',
    blackTransparent: 'rgba(0, 0, 0, 0.2)',

    yellow50: '#fff9ea',
    yellow100: '#feecc0',
    yellow200: '#fde096',
    yellow300: '#fdd981',
    yellow400: '#fccd57',
    yellow500: '#fbc02d',
    yellow600: '#fabf40',
    yellow700: '#eeb11a',
    yellow800: '#d6a326',
    yellow900: 'c28c08',

    grey50: '#f8f9f9',
    grey100: '#edeff0',
    grey200: '#e2e4e6',
    grey300: '#d6dadc',
    grey400: '#838c91',
    grey500: '#676d70',
    grey600: '#333',
    grey700: '#222',
    grey800: '#111',
    grey900: '#000',

    green50: '#e8f5e9',
    green100: '#c8e6c9',
    green200: '#a5d6a7',
    green300: '#81c784',
    green400: '#66bb6a',
    green500: '#4caf50',
    green600: '#43a047',
    green700: '#388e3c',
    green800: '#2e7d32',
    green900: '#1b5e20',

    blue50: '#e3f2fd',
    blue100: '#bbdefb',
    blue200: '#90caf9',
    blue300: '#64b5f6',
    blue400: '#64b5f6',
    blue500: '#2196f3',
    blue600: '#1e88e5',
    blue700: '#1976d2',
    blue800: '#1565c0',
    blue900: '#0d47a1',

    red50: '#ffebee',
    red100: '#ffcdd2',
    red200: '#ef9a9a',
    red300: '#e57373',
    red400: '#ef5350',
    red500: '#f44336',
    red600: '#e53935',
    red700: '#d32f2f',
    red800: '#c62828',
    red900: '#b71c1c',

    orange50: '#fff3e0',
    orange100: '#ffe0b2',
    orange200: '#ffcc80',
    orange300: '#ffb74d',
    orange400: '#ffa726',
    orange500: '#ff9800',
    orange600: '#fb8c00',
    orange700: '#f57c00',
    orange800: '#ef6c00',
    orange900: '#e65100',
  },

  screenSize: {
    mobile: '600px',
    tablet: '900px',
    computer: '1024px',
  },

  fontWeight: {
    light: '300',
    normal: '400',
    bold: '600',
  },
  inputFieldHeight: '38px',
}

const darkColors = {
  black: '#d6dadc',
  white: '#222',
  yellow600: '#fabf40',
  grey600: '#78716c',
  green600: '#43a047',
  orange600: '#78716c',
  red600: '#e53935',
  blue600: '#1e88e5',
}
export const darkTheme: DefaultTheme = {
  radius: {
    small: '2px',
    medium: '5px',
    large: '10px',
    none: '0px',
  },

  colors: {
    black: darkColors.black,
    white: darkColors.white,
    yellow: defaultColors.black,
    orange: darkColors.orange600,
    green: defaultColors.green600,
    red: defaultColors.red600,
    blue: defaultColors.blue600,
    grey: darkColors.grey600,

    sosBackgroundColor: 'rgba(255, 204, 204, 0.8)',
    sosBorderColor: 'rgba(153, 102, 102, 0.5) !important',
    autoliittoYellow: theme.colors.yellow700,
    towingRecordWhite: '#444',
    blueDarkLight: theme.colors.blue200,
    greyDisabled: '#999999',
    blackTransparent: 'rgba(255, 255, 255, 0.2)',

    yellow50: '#fff9ea',
    yellow100: defaultColors.black,
    yellow200: '#fde096',
    yellow300: '#fdd981',
    yellow400: '#fccd57',
    yellow500: '#fbc02d',
    yellow600: theme.colors.yellow600,
    yellow700: theme.colors.yellow700,
    yellow800: '#d6a326',
    yellow900: 'c28c08',

    grey50: '#f8f9f9',
    grey100: '#676d70',
    grey200: '#333',
    grey300: theme.colors.grey400,
    grey400: '#838c91',
    grey500: '#676d70',
    grey600: '#333',
    grey700: '#222',
    grey800: '#111',
    grey900: '#000',

    green50: '#e8f5e9',
    green100: theme.colors.green700,
    green200: theme.colors.green700,
    green300: '#81c784',
    green400: '#66bb6a',
    green500: '#4caf50',
    green600: '#43a047',
    green700: theme.colors.green100,
    green800: '#2e7d32',
    green900: theme.colors.green100,

    blue50: theme.colors.grey600,
    blue100: theme.colors.blue900,
    blue200: '#fff',
    blue300: '#fff',
    blue400: '#fff',
    blue500: '#fff',
    blue600: theme.colors.blue600 ,
    blue700: '#fff',
    blue800: theme.colors.blue600,
    blue900: theme.colors.blue100,

    red50: '#ffebee',
    red100: theme.colors.red800,
    red200: '#ef9a9a',
    red300: '#e57373',
    red400: theme.colors.red800,
    red500: '#f44336',
    red600: '#e53935',
    red700: '#d32f2f',
    red800: '#c62828',
    red900: theme.colors.red100,

    orange50: theme.colors.grey700,
    orange100: '#ffe0b2',
    orange200: '#ffcc80',
    orange300: '#ffb74d',
    orange400: theme.colors.orange400,
    orange500: '#ff9800',
    orange600: '#fb8c00',
    orange700: '#f57c00',
    orange800: '#ef6c00',
    orange900: '#e65100',
  },

  screenSize: {
    mobile: theme.screenSize.mobile,
    tablet: theme.screenSize.tablet,
    computer: theme.screenSize.computer,
  },

  fontWeight: {
    light: '300',
    normal: '400',
    bold: '600',
  },
  inputFieldHeight: '38px',
}
