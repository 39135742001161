import React from 'react'
import { TowingRecordJobStatus, TowingRecordType } from '../../../../../../../common/types'
import { FinishRouteAction } from '../../../../../../../components/SosSubForm/FinishRoute/types'

import { AutoliittoDisableFields, GeoLocateAddressData } from '../../../types'
import { FinishedAtBreakdownLocationFormSos } from './FinishedAtBreakdownLocationFormSos'
import { FinishedAtBreakdownLocationFormAL } from './FinishedAtBreakdownLocationFormAL'
import { FinishedAtBreakdownLocationForm } from './FinishedAtBreakdownLocationForm'

export interface FinishedAtBreakdownLocationProps {
  setValue: (field: string, value: any) => void
  getValue: any
  setDateField: (fieldName: string) => void
  setRouteLocation: (addressData: GeoLocateAddressData | null) => void
  clear: () => void
  action: FinishRouteAction
  setAction: (action: FinishRouteAction) => void
  jobStatus: TowingRecordJobStatus
  type: TowingRecordType
  autoliittoFields: AutoliittoDisableFields
}

export interface DefaultProps extends FinishedAtBreakdownLocationProps {}

export interface SosProps extends FinishedAtBreakdownLocationProps {}

export interface ALProps extends FinishedAtBreakdownLocationProps {
  translatedServiceTypeOptions: object[]
  batteryServiceOptions: object[]
}

export const FinishedForm = ({ type, ...props }: FinishedAtBreakdownLocationProps | ALProps) => {
  if (type === TowingRecordType.sos) return <FinishedAtBreakdownLocationFormSos {...(props as SosProps)} />
  if (type === TowingRecordType.autoliitto) return <FinishedAtBreakdownLocationFormAL {...(props as ALProps)} />
  if (type === TowingRecordType.default) return <FinishedAtBreakdownLocationForm {...(props as DefaultProps)} />
  return null
}
