import React, { useContext, useEffect, useState } from 'react'
import { CollapseContent } from '../../../../../../../components/Collapse/CollapseContent'
import { RouteColumn, RouteRow } from '../../../../../../../components/layout'
import { SelectInput } from '../../../../../../../components/layout/form/input'
import { FinishedDateTimeInput } from '../../../../../../../components/layout/form/input/DateTimeInput'
import { format } from 'date-fns'
import { Time } from '@google/maps'
import { TowingRecordJobStatus } from '../../../../../../../common/types'
import { StateStore } from '../../../../../../StoreProvider'
import { serviceTypeOptions } from '../../../../../../TowingOrderPage/options'
import { DefaultProps, FinishedAtDestinationProps } from './FinishedForm'

export const FinishedAtDestinationForm: React.FunctionComponent<FinishedAtDestinationProps & DefaultProps> = ({
  setValue,
  action,
}) => {
  const [edit, setEdit] = useState<boolean>(action === 'edit-finished')
  const { state } = useContext(StateStore)

  useEffect(() => {
    if (action === 'edit-finished') {
      if (!edit) setEdit(true)
    } else {
      if (edit) setEdit(false)
    }
  }, [edit, action, setEdit])

  const options = (state.settings && state.settings.towingReasons) || []

  return (
    <>
      <CollapseContent>
        <RouteRow columns={`1fr 1fr 1fr`}>
          <RouteColumn>
            <SelectInput label="Toimenpide" name="serviceType" options={serviceTypeOptions} disabled required />
          </RouteColumn>
          <RouteColumn>
            <SelectInput
              label="Syy"
              name="towingReason"
              options={options.map((label: string) => ({
                label,
                value: label,
              }))}
              required
              disabled={!edit}
            />
          </RouteColumn>
          <RouteColumn>
            <FinishedDateTimeInput
              name="finishRoute"
              label="Valmistumisaika"
              customOnChange={() => {
                const value = (format(new Date(), 'HH:mm') as unknown) as Time
                setValue('finishRoute.finishedTime', value)
                setValue('finishRoute.finishedDate', new Date())
              }}
              required
              disabled={!edit}
              showCurrentTimeBtn
            />
          </RouteColumn>
        </RouteRow>
      </CollapseContent>
    </>
  )
}

export const finishedAtDestinationEditableJobStatuses = [TowingRecordJobStatus.finished_at_destination]
