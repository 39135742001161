import { useMutation } from '@apollo/react-hooks'
import React, { useContext, useState } from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'

import { useRouteMatch } from 'react-router'
import { setSuccessNotification } from '../../../components/notification'
import { setErrorNotifications, setFatalErrorNotification } from '../../../components/responses/setErrorNotifications'
import { useHistoryPush } from '../../../components/router'
import { getStoredUserId } from '../../../graphql-client/storedState'
import { persistentDelete } from '../../../util/localStorage/localStorage'
import { DispatchStore, StateStore } from '../../StoreProvider'
import { handleNetworkError, STORED_ADD_TOWING_ORDER } from '../../StoreProvider/OfflineMode'
import { TowingOrderForm } from './AddTowingOrderForm'
import { ADD_TOWING_ORDER_MUTATION } from './mutation/mutation'
import { toMutationVariables } from './mutation/toMutationVariables'
import { AddTowingOrderResponse } from './mutation/types'

const TOWING_ORDER = STORED_ADD_TOWING_ORDER

const AddTowingOrderIntl: React.FunctionComponent<InjectedIntlProps> = ({ intl }) => {
  const historyPush = useHistoryPush()
  const { formatMessage } = intl
  const routeMatch = useRouteMatch()
  const {
    state: { offlineMode, unSavedJobCount, settings },
  } = useContext(StateStore)
  const { dispatch } = useContext(DispatchStore)
  const [assign, setAssign] = useState<boolean>(false)

  const [addTowingOrder, { loading }] = useMutation<AddTowingOrderResponse>(ADD_TOWING_ORDER_MUTATION, {
    onCompleted(addTowingOrderResponse) {
      if (addTowingOrderResponse.addTowingOrder.__typename === 'AddTowingRecordSuccess') {
        historyPush(`/towing-record/${addTowingOrderResponse.addTowingOrder.towingRecord.id}`)
      } else if (addTowingOrderResponse.addTowingOrder.__typename === 'AddTowingOrderSuccess') {
        persistentDelete(TOWING_ORDER)
        const url = !assign ? '/' : `/towing-order/${addTowingOrderResponse.addTowingOrder.towingOrder.id}`
        historyPush(url)
        setSuccessNotification(
          formatMessage({ id: 'towingOrder.add.success.notification.title', defaultMessage: 'New towing order added' }),
          formatMessage({ id: 'towingOrder.add.success.notification.message', defaultMessage: '' })
        )
      } else {
        setErrorNotifications({ data: addTowingOrderResponse.addTowingOrder })
      }
    },
    onError(err) {
      setFatalErrorNotification(err.message)

      if (!offlineMode) {
        return handleNetworkError(dispatch, unSavedJobCount + 1)
      }
    },
  })

  const canCreateSosOrder = settings ? settings.modules.sos : false
  const isSos: boolean =
    canCreateSosOrder && routeMatch && routeMatch.params && 'action' in routeMatch.params ? true : false
  return (
    <TowingOrderForm
      addTowingOrder={async values => {
        console.log('addTowingOrder')
        setAssign(values.visibility === 'assigned')
        await addTowingOrder({ variables: toMutationVariables(values, getStoredUserId()) })
      }}
      loading={loading}
      isSos={isSos}
    />
  )
}

export const AddTowingOrder = injectIntl(AddTowingOrderIntl)
