import styled, { css } from 'styled-components'

const triangle = css`
  &:before {
    content: '';
    position: absolute;
    height: 0;
    width: 0;
    bottom: -1rem;
    left: 1rem;
    transform: rotate(90deg);
    border-left: 0.5rem solid ${props => props.theme.colors.red100};
    border-right: 0.5rem solid transparent;
    border-bottom: 0.5rem solid transparent;
    border-top: 0.5rem solid transparent;
  }
`

export const Error = styled.span`
  position: absolute;
  z-index: 0;
  top: -1.5rem;
  padding: 0.25rem 0.5rem;
  margin: 0.25 0 0.25rem 0;
  color: ${props => props.theme.colors.black};
  background-color: ${props => props.theme.colors.red100};
  border-radius: ${props => props.theme.radius.medium};
  ${triangle}
`
