import { Field } from 'formik'
import React, { ReactNode, useCallback, useContext } from 'react'
import { SelectField } from '../../field'
import { StateStore } from '../../../../../containers/StoreProvider'

interface Props {
  name: string
  label?: string
  onChange?: (arg?: string | number) => void
  required?: boolean
  clearable?: boolean
  searchable?: boolean
  placeholder?: string
  children?: ReactNode
  disabled?: boolean
  isAl?: boolean
}

export const TowingTypeInput: React.FC<Props> = ({
  clearable,
  name,
  label,
  onChange,
  placeholder,
  required,
  searchable,
  disabled = false,
  isAl = false,
}) => {
  const validate = useCallback(
    (value: string | null): string | undefined => {
      if (required && (!value || !value.trim())) {
        return 'Kenttä on pakollinen'
      }
      return undefined
    },
    [required]
  )

  const { state } = useContext(StateStore)

  if (!state.settings) {
    return null
  }

  const towingRecordTypes = isAl ? ['AL'] : state.settings.labels

  return (
    <Field
      disabled={disabled}
      clearable={clearable}
      component={SelectField}
      id={name}
      name={name}
      label={label}
      required={required}
      validate={validate}
      onChange={onChange}
      searchable={searchable}
      placeholder={placeholder}
      options={towingRecordTypes.map(type => ({
        label: type,
        value: type,
      }))}
    />
  )
}
