import { Maybe, Time, TowingRecordRouteType, TowingRecordServiceType, TowingRecordType } from '../../../../common/types'
import { AutoliittoDisableFields, TowingRecordRoute } from './types'
import { getIndexOfRoute } from './getIndexOfRoute'
import { EditArrivalRouteFormValues } from './subforms/ArrivalRoute/types'
import { EditFinishRouteFormValues } from './subforms/FinishRoute/types'

export interface RouteMenuConfig {
  routeId: TowingRecordRouteType
  label: string
  time: Maybe<Time>
  timeOfCompletion: Maybe<Time>
  done: boolean
}

export function createRouteMenuConfig(
  routes: TowingRecordRoute[],
  finishRouteAddressRequired: boolean,
  towingRecordType: TowingRecordType,
  useExtendedRoutes: boolean
): RouteMenuConfig[] {
  const timeOfCompletionRequired = towingRecordType === TowingRecordType.sos || useExtendedRoutes

  const getRouteTime = (t: TowingRecordRouteType) =>
    getIndexOfRoute(routes, t) > -1 ? routes[getIndexOfRoute(routes, t)].arrivalTime : null

  const getFinishedRouteTime = (t: TowingRecordRouteType) =>
    getIndexOfRoute(routes, t) > -1 ? routes[getIndexOfRoute(routes, t)].finishedTime : null

  const hasRoute = (t: TowingRecordRouteType) => {
    const routeIndex = getIndexOfRoute(routes, t)
    if (routeIndex === -1) {
      return false
    }

    const route = routes[routeIndex]

    if (!finishRouteAddressRequired && route.type === TowingRecordRouteType.finish) return Boolean(route.arrivalTime)

    return Boolean(route.arrivalTime && route.address)
  }

  return [
    {
      routeId: TowingRecordRouteType.start,
      label: 'Lähtö',
      time: getRouteTime(TowingRecordRouteType.start),
      done: hasRoute(TowingRecordRouteType.start),
      timeOfCompletion: null,
    },
    {
      routeId: TowingRecordRouteType.arrival,
      label: timeOfCompletionRequired ? 'Kohde' : 'Kohteessa',
      time: getRouteTime(TowingRecordRouteType.arrival),
      done: hasRoute(TowingRecordRouteType.arrival),
      timeOfCompletion: timeOfCompletionRequired ? getFinishedRouteTime(TowingRecordRouteType.arrival) : null,
    },
    {
      routeId: TowingRecordRouteType.finish,
      label: timeOfCompletionRequired ? 'Määränpää' : 'Työ valmis',
      time: getRouteTime(TowingRecordRouteType.finish),
      done: hasRoute(TowingRecordRouteType.finish),
      timeOfCompletion: timeOfCompletionRequired ? getFinishedRouteTime(TowingRecordRouteType.finish) : null,
    },
    {
      routeId: TowingRecordRouteType.end,
      label: 'Lopetus',
      time: getRouteTime(TowingRecordRouteType.end),
      done: hasRoute(TowingRecordRouteType.end),
      timeOfCompletion: null,
    },
  ]
}

export function finishRouteAddressRequired(serviceType: TowingRecordServiceType, towingRecordType: TowingRecordType) {
  if (serviceType !== TowingRecordServiceType.TOWING && towingRecordType === TowingRecordType.autoliitto) return false
  if (serviceType !== TowingRecordServiceType.TOWING && serviceType !== TowingRecordServiceType.OTHER && towingRecordType === TowingRecordType.default) return false
  return true
}

export function autoliittoFields(
  initialValues: EditArrivalRouteFormValues | EditFinishRouteFormValues,
  route?: TowingRecordRoute | undefined
): AutoliittoDisableFields {
  if (initialValues.automobileAndTouringClubFinland) {
    return {
      disableVehicleMileage: !!initialValues.automobileAndTouringClubFinland.vehicleMileage,
      disableArcComponent: !!initialValues.automobileAndTouringClubFinland.arcCodes.component.code,
      disableArcDescription: !!initialValues.automobileAndTouringClubFinland.arcCodes.description.code,
      hasFinishRouteInfo: route && route.address ? true : false,
      disableTowingReason: false,
      disableVehicleKeyLocation: false,
    }
  }

  if (initialValues.sosServiceOrder) {
    return {
      disableVehicleMileage: false,
      disableArcComponent: false,
      disableArcDescription: false,
      hasFinishRouteInfo: route && route.address ? true : false,
      disableTowingReason: !!initialValues.sosServiceOrder.cause,
      disableVehicleKeyLocation: !!initialValues.sosServiceOrder.keyLocation,
    }
  }

  return {
    disableVehicleMileage: false,
    disableArcComponent: false,
    disableArcDescription: false,
    hasFinishRouteInfo: route && route.address ? true : false,
    disableTowingReason: !!initialValues.towingReason,
    disableVehicleKeyLocation: false,
  }
}
