import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'

const StyledLink = styled(RouterLink)`
  font-size: 1.3rem;
  text-decoration: underline;
  color: ${props => props.theme.colors.black}

  :hover {
    color: ${props => props.theme.colors.grey400}
  }
`

interface Props {
  to: string
  children: React.ReactNode
}

const Link: React.FunctionComponent<Props> = ({ to, children, ...rest }) => {
  return <StyledLink to={{ pathname: to }} {...rest}> {children} </StyledLink>
}

export default Link
