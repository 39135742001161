import gql from 'graphql-tag'

import { TrackedTowingRecord } from './types'

export const TOWING_RECORDS_ACTIVE = gql`
  query ActiveTowingRecords {
    activeTowingRecords {
      __typename
      ... on ActiveJobsSuccess {
        activeJobs {
          id
          type
          typeIdentifier
          jobStatus
        }
      }
    }
  }
`

export const GEOLOCATION_REPORT_POSITION = gql`
  query ReportLocation($location: ReportLocation!) {
    reportLocation(location: $location) {
      __typename
    }
  }
`

export interface ActiveJobsResponse {
  activeTowingRecords: ActiveJobsResult
}

interface ActiveJobsResult
  extends Readonly<{
    __typename: string
    activeJobs: TrackedTowingRecord[]
  }> {}
