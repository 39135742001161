import { get } from 'lodash'
import { Maybe } from '../../../common/types'

export const errorMessage = (data: object): Maybe<string> => {
  const typename = get(data, [Object.keys(data)[0], '__typename'])
  const errorData = get(data, [Object.keys(data)[0], 'error'])
  const message = typeof errorData !== 'undefined' && errorData.hasOwnProperty('message') ? errorData.message : null
  switch (typename) {
    case 'StatusChangeForbiddenError':
      return !message ? 'Statuksen vaihtaminen ei sallittu' : message
    case 'UnauthorizedError':
      return 'Statuksen vaihtaminen ei onnistunut käyttöoikeusrajoitusten takia.'
    case 'ResourceNotFoundError':
      return 'Tilausta ei enää löytynyt.'
    case 'TowingRecordAlreadyCreatedError':
      return 'Tilauksella on jo kuormakirja.'
    default:
      return message ? message : null
  }
}
