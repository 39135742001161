import React from 'react'
import { TowingOrderType } from '../../../common/types'
import { LocationInputMap, toMapData } from '../../../components/layout/form/input/LocationInputMap'
import { TextInput } from '../../../components/layout/form/input/TextInput'
import {
  FormSubGroup,
  FormSubGroupHeader,
  FormSubGroupHeading,
  FormSubGroupWrapper,
} from '../../../components/layout/formsubgroup'
import { Section, SectionColumn } from '../../../components/layout/section'

interface Props {
  getValue: any
  setValue: any
  orderType: TowingOrderType
  deliverableName?: string
  disableDestinationRoute?: boolean
}

export const TowingOrderRoutesForm: React.FunctionComponent<Props> = ({
  getValue,
  setValue,
  orderType,
  disableDestinationRoute = false,
}) => {
  const stationId = getValue('stationId')

  return (
    <FormSubGroupWrapper>
      <FormSubGroupHeader>
        <FormSubGroupHeading>Kohteet</FormSubGroupHeading>
      </FormSubGroupHeader>
      <FormSubGroup>
        <Section columnCss="2fr 1fr" tabletColumnCss="2fr 1fr" justify="text">
          <SectionColumn>
            <LocationInputMap
              addressLabel="Mistä"
              addressName="from.address"
              cityName="from.city"
              cityLabel="Kaupunki"
              coordsName="from.coords"
              zipcodeName="from.zipcode"
              descriptionName={orderType === TowingOrderType.sos ? 'from.description' : undefined}
              getValue={getValue}
              setValue={setValue}
              required={orderType === TowingOrderType.sos}
              coordsRequired={orderType === TowingOrderType.sos}
              includeNameInAddress={!(orderType === TowingOrderType.sos)}
              values={toMapData(
                getValue(`from.address`),
                getValue(`from.city`),
                getValue(`from.coords`),
                getValue(`from.zipcode`)
              )}
              reverse
              stationId={stationId}
              clear={() => {
                setValue('from.address', '')
                setValue('from.city', '')
                setValue('from.coords.lat', null)
                setValue('from.coords.long', null)
                setValue('from.geolocated', false)
              }}
            />
          </SectionColumn>
          <SectionColumn>
            <TextInput label={'Lisätieto'} name="from.description" />
          </SectionColumn>
        </Section>
        {!disableDestinationRoute ? (
          <Section columnCss="2fr 1fr" tabletColumnCss="2fr 1fr" justify="text">
            <SectionColumn>
              <LocationInputMap
                addressLabel="Mihin"
                addressName="to.address"
                cityName="to.city"
                cityLabel="Kaupunki"
                coordsName="to.coords"
                zipcodeName="to.zipcode"
                descriptionName={orderType === TowingOrderType.sos ? 'to.description' : undefined}
                getValue={getValue}
                setValue={setValue}
                // required={toRequired}
                coordsRequired={orderType === TowingOrderType.sos}
                includeNameInAddress={!(orderType === TowingOrderType.sos)}
                values={toMapData(
                  getValue(`to.address`),
                  getValue(`to.city`),
                  getValue(`to.coords`),
                  getValue(`to.zipcode`)
                )}
                reverse
                stationId={stationId}
                clear={() => {
                  setValue('to.address', '')
                  setValue('to.city', '')
                  setValue('to.coords.lat', null)
                  setValue('to.coords.long', null)
                  setValue('to.geolocated', false)
                }}
              />
            </SectionColumn>
            <SectionColumn>
              <TextInput label={'Lisätieto'} name="to.description" />
            </SectionColumn>
          </Section>
        ) : null}
      </FormSubGroup>
    </FormSubGroupWrapper>
  )
}
