import React, { useContext } from 'react'
import { DispatchStore, StateStore } from '../../containers/StoreProvider'
import { MenuContainer, MenuList } from '../layout'
import { MenuLink } from '../MenuLink'
import { OfflineIconContainer, OfflineMenuIcon } from '../OfflineMode'
import { showSocketModal } from '../../containers/StoreProvider/actions'


import { ThemeButton } from '../ThemeButton'

export const NavigationMenu: React.FunctionComponent = () => {
  const { state } = useContext(StateStore);
  const {dispatch} = useContext(DispatchStore);

  const openMessages = ()=>{
    dispatch(showSocketModal());
  }
  return (
    <>
      <OfflineIconContainer>
        {state.offlineMode && <OfflineMenuIcon offline={state.offlineMode} />}
      </OfflineIconContainer>
      <MenuContainer>
          <MenuList>
            <MenuLink to="/towing-jobs" label="Työt" category="orders" exact count={state.openJobsCount} />
            {/* <MenuLink to="/search" label="Haku" category="search" /> */}
            <MenuLink to="/shifts" label="Vuorot" category="shifts" />
            <MenuLink to='' label="Viestit" category='messages' count={state.messageCount} onClick={openMessages} />
            <MenuLink to="/settings" label="Asetukset" category="settings" />
            <ThemeButton />
          </MenuList>
      </MenuContainer>
    </>
  )
}
