import { FieldProps, FormikProps, getIn } from 'formik'
import React, { useEffect, useState } from 'react'
import AsyncCreatable from 'react-select/async-creatable'
import styled from 'styled-components'

import { Error, FieldContainer, Label } from '../../style'

// tslint:disable-next-line: no-submodule-imports

interface Props<T> {
  label?: string
  placeholder?: string
  getOptions: GetOptionsCallback
  onChange: OnChangeCallback<T>
  disabled?: boolean
  required?: boolean
}

export type GetOptionsCallback = (searchString: string) => Promise<Suggestion[]>
export type OnChangeCallback<T> = (
  value: OnChangeSuggestion<T> | Array<OnChangeSuggestion<T>> | null,
  form: FormikProps<any>
) => void

interface OnChangeSuggestion<T> {
  value?: string | number
  label?: string
  object?: T
}

export interface Suggestion {
  value: string | number
  label: string
  color?: string
  isFixed?: boolean
  object?: object
}

interface SimpleChangeSuggestion {
  value?: string | number
  label?: string
}

type SaveSuggestion = boolean

type CreatableAutosuggestFieldInterface<T = any> = React.FC<Props<T> & FieldProps>
export const CreatableAutosuggestField: CreatableAutosuggestFieldInterface = ({
  field,
  form,
  label,
  placeholder,
  onChange,
  required,
  disabled = false,
  getOptions,
}) => {
  const [getSuggestion, setSuggestion] = useState<SimpleChangeSuggestion | null>(null)
  const [getSaveSuggestion, setSaveSuggestion] = useState<SaveSuggestion>(false)

  const fieldError = getIn(form.errors, field.name)
  const fieldTouched = getIn(form.touched, field.name)
  const hasErrors = Boolean(fieldError) && Boolean(fieldTouched)

  const getOptionsByValue = async (inputValue: string): Promise<Suggestion[]> => {
    if (!inputValue) {
      return []
    }
    return await getOptions(inputValue)
  }

  /*
   Needed this complex workaround because there is issue on android mobile that
   launches input-change a onChange (cliked on menu). This would reset selections
   */
  useEffect(() => {
    if (getSuggestion && getSaveSuggestion) {
      form.setFieldValue(field.name, getSuggestion.label)
      onChange(getSuggestion, form)
      setSaveSuggestion(false)
    }
  }, [getSuggestion, getSaveSuggestion, field.name, form, onChange])

  useEffect(() => {
    setSaveSuggestion(false)
    setSuggestion(null)
  }, [disabled, setSaveSuggestion, setSuggestion])

  return (
    <StyledFieldContainer>
      {label && <Label required={required}>{label}</Label>}
      <AsyncCreatable
        inputId={field.name}
        classNamePrefix={'react-select'}
        isDisabled={disabled}
        cacheOptions
        defaultOptions
        isSearchable
        placeholder={placeholder}
        loadOptions={getOptionsByValue}
        formatCreateLabel={(input: string) => `Luo: ${input}`}
        value={getInitialValue(field.value)}
        createOptionPosition={'first'}
        onChange={onChangeSuggestion => {
          if (onChangeSuggestion != null && 'value' in onChangeSuggestion) {
            setSuggestion(onChangeSuggestion)
            setSaveSuggestion(true)
          }
        }}
        onInputChange={(inputValue, action) => {
          if (action.action === 'input-change') {
            setSuggestion({ value: inputValue, label: inputValue })
          }
          if (action.action === 'menu-close') {
            setSaveSuggestion(true)
          }
        }}
        onBlurResetsInput={false}
      />

      {hasErrors && <Error className="error-tooltip">{fieldError}</Error>}
    </StyledFieldContainer>
  )
}

const StyledFieldContainer = styled(FieldContainer)`
  & .react-select__control, .react-select__menu {
    background-color: ${props => props.theme.colors.white};
  }

  & .react-select__menu {
    background-color: ${props => props.theme.colors.white};
  }

  & .react-select__option {
    background-color: ${props => props.theme.colors.blue50};
    color: ${props => props.theme.colors.black};
  }
  
  & .react-select__input, .react-select__single-value  {
    color: ${props => props.theme.colors.black};
  }

  &--is-disabled {
    background-color: ${props => props.theme.colors.grey200} !important;
  }
`

const getInitialValue = (value: string) => {
  if (!value) {
    return null
  }
  return { value, label: value }
}
// & .react-select__option {
//   background-color: ${props => props.theme.colors.grey100};
// }