import styled, { css } from 'styled-components'

interface Props {
  justifyContent?: string
}

export const FlexContainer = styled.div<Props>`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  ${props =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `}
  padding: 0;
  margin: 0;
`
