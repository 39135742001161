import React, { useEffect, useState } from 'react'
import { RouteRow, RouteColumn } from '../../layout'
import { TextInput, SelectInput } from '../../layout/form/input'
import { FinishedDateTimeInput } from '../../layout/form/input/DateTimeInput'
import { format } from 'date-fns'
import { Time, TowingRecordJobStatus } from '../../../common/types'
import { FinishRouteProps } from '../FinishRoute/types'
import { CollapseContent } from '../../Collapse/CollapseContent'
import { sosVariables } from '../types'

export const SosFinishedAtDestinationForm: React.FunctionComponent<FinishRouteProps> = ({ setValue, action }) => {
  const [edit, setEdit] = useState<boolean>(action === 'edit-finished')

  useEffect(() => {
    if (action === 'edit-finished') {
      if (!edit) setEdit(true)
    } else {
      if (edit) setEdit(false)
    }
  }, [edit, action, setEdit])

  const deliverables = sosVariables.deliverable
  const causeOptions = sosVariables.cause

  const disableKeyLocationInitially = false

  const disableCauseInitially = false

  return (
    <>
      <CollapseContent>
        <RouteRow columns={`1fr 1fr 1fr`}>
          <RouteColumn>
            <SelectInput
              label="Toimenpide"
              name="sosServiceOrder.deliverable"
              options={deliverables}
              onChange={value => console.log(value)}
              disabled
              required
            />
          </RouteColumn>
          <RouteColumn>
            <SelectInput
              label="Vika"
              name="sosServiceOrder.cause"
              options={causeOptions}
              disabled={!edit || disableCauseInitially}
              required
            />
          </RouteColumn>
          <RouteColumn>
            <FinishedDateTimeInput
              name="finishRoute"
              label="Valmistumisaika"
              customOnChange={() => {
                const value = (format(new Date(), 'HH:mm') as unknown) as Time
                setValue('finishRoute.finishedTime', value)
                setValue('finishRoute.finishedDate', new Date())
              }}
              required={edit}
              disabled={!edit}
              showCurrentTimeBtn
            />
          </RouteColumn>
        </RouteRow>
        <RouteRow columns={`3fr`}>
          <RouteColumn>
            <TextInput
              name="sosServiceOrder.keyLocation"
              label="Avainten jättöpaikka"
              disabled={!edit || disableKeyLocationInitially}
            />
          </RouteColumn>
        </RouteRow>
      </CollapseContent>
    </>
  )
}

export const finishedAtDestinationEditableJobStatuses = [TowingRecordJobStatus.finished_at_destination]
