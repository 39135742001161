import { RejectOrderFormValues } from '../types'
import { CancelTowingOrderMutationVariables } from './types'

export function toMutationVariables(values: RejectOrderFormValues): CancelTowingOrderMutationVariables {
  return {
    input: {
      id: values.towingOrderId,
      reason: values.reason,
    },
  }
}
