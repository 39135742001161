import styled, { css } from 'styled-components'
import { FieldContainer, FieldStyles } from '../../style'

interface AutosuggestProps {
  direction?: string
}

export const AutosuggestFieldContainer = styled(FieldContainer)<AutosuggestProps>`
  .react-autosuggest__suggestions-container {
    position: absolute;
    z-index: 1;
    width: 100%;
    background-color: ${props => props.theme.colors.white};
    &--open {
      background-color: ${props => props.theme.colors.white};
      color: ${props => props.theme.colors.black};
    }
    ${props =>
      props.direction &&
      css`
        bottom: 30px;
      `}

    .react-autosuggest__suggestions-list {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        padding: 0.5rem 1rem;
        border: 1px solid ${props => props.theme.colors.grey200};
        cursor: pointer;

        &:hover {
          background-color: ${props => props.theme.colors.green200};
        }
        &:not(first-child) {
          border-top: none;
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
    border-radius: 0 0 5px 5px;
  }
`
interface AdditionalProps {
  hasCoordinates?: boolean
}

export const StyledInput = styled.input<AdditionalProps>`
  ${FieldStyles}
  background-color: ${props => props.theme.colors.white};
  ${props =>
    props.disabled &&
    css`
      color: ${props => props.theme.colors.greyDisabled};
    `}
  width: 100%;
  height: ${props => props.theme.inputFieldHeight};
  background-color: ${props => (props.disabled ? props.theme.colors.grey200 : props.theme.colors.white)};
      ${props =>
        !props.disabled &&
        props.hasCoordinates &&
        css`
          border: 1px solid ${props.theme.colors.grey300};
          border-right: 0;
        `}
  `
