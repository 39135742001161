import { format } from 'date-fns'
import { find } from 'lodash'

import { Maybe, Time, TowingRecordRouteType, TowingRecordType } from '../../../../../../common/types'
import { SosFinishRouteType } from '../../../../../../components/SosSubForm/types'
import { createNewRoute } from '../../createNewRoute'
import { TowingRecordWithRoutes } from '../../types'
import { EditFinishRouteFormValues, FinishRoute } from './types'

export function getInitialValues(
  towingRecord: TowingRecordWithRoutes,
  batteryOption: Maybe<string>,
): EditFinishRouteFormValues {
  const routes = towingRecord.routes
  const routeFound = find(routes, ['type', TowingRecordRouteType.finish])

  const route =
    routeFound && routeFound.type === TowingRecordRouteType.finish
      ? ({ ...routeFound } as FinishRoute)
      : (createNewRoute(TowingRecordRouteType.finish, true) as FinishRoute)

  if (!route.arrivalDate) {
    route.arrivalDate = new Date()
  }
 
  if (!route.arrivalTime) {
    route.arrivalTime = (format(new Date(), 'HH:mm') as unknown) as Time
  }

  let alValues = null
  let sosValues = null

  if (towingRecord.automobileAndTouringClubFinland && towingRecord.type === TowingRecordType.autoliitto) {
    alValues = {
      repairShopId: towingRecord.automobileAndTouringClubFinland.repairShopId,
      arcCodes: towingRecord.automobileAndTouringClubFinland.arcCodes,
      vehicleMileage: towingRecord.automobileAndTouringClubFinland.vehicleMileage,
      batteryService: {
        deliveredBattery: towingRecord.automobileAndTouringClubFinland.batteryService.deliveredBattery,
        paymentType: towingRecord.automobileAndTouringClubFinland.batteryService.paymentType,
        battery: batteryOption,
      },
    }
  }

  if (towingRecord.sosServiceOrder && towingRecord.type === TowingRecordType.sos) {
    const actualTimeOfCompletion = towingRecord.sosServiceOrder.jobTimestamps.completedAtDestination

    sosValues = {
      keyLocation: towingRecord.sosServiceOrder.vehicleKeyLocation,
      locationType: SosFinishRouteType.REPAIR_SHOP,
      repairShopName: route.description || '',
      completedAtDestination: {
        date: actualTimeOfCompletion ? actualTimeOfCompletion.date : new Date(),
        time: actualTimeOfCompletion ? actualTimeOfCompletion.time : null,
      },
      vehicleMileage: towingRecord.sosServiceOrder.vehicleMileage,
      deliverable: towingRecord.sosServiceOrder.deliverable,
      cause: towingRecord.sosServiceOrder.cause,
      
    }

    route.finishedTime = sosValues.completedAtDestination.time
    route.finishedDate = sosValues.completedAtDestination.date as Maybe<Date> // Fix
  }

  return {
    finishRoute: route,
    id: towingRecord.id,
    additionalInfo: towingRecord.sosServiceOrder ? '' : towingRecord.jobDetails.additionalInfo,
    serviceType: towingRecord.jobDetails.serviceType,
    disabledAddress: '',
    automobileAndTouringClubFinland: alValues,
    sosServiceOrder: sosValues,
    notes: towingRecord.jobDetails.notes,
    vehicleRegNo: towingRecord.vehicleDetails.registrationNumber,
    towingReason: towingRecord.jobDetails.towingReason,
    deliverable: 'Towage',
    cause: 'Kolari'
  }
}
