import React from 'react'
import { Maybe } from '../../common/types'
import { Item, ItemGroup } from '../../containers/Parameters/types'
import { Button } from '../Button'
import { FormButton } from '../layout/form/FormButton'
import { SelectInput } from '../layout/form/input'
import { convertItemToJobItem } from './convertItemToJobItem'
import { newItem } from './defaults'
import { ToolbarWrapper } from './styled'
import { JobItemInput } from './types'

interface Props {
  items: Maybe<Item[]>
  itemGroups: Maybe<ItemGroup[]>
  createJobItem: (item: JobItemInput) => void
  createDiscountItem: () => void
  hasDiscountItem: boolean
  itemsIncludeVat: boolean
  setIncludeVat: (includeVat: boolean) => void
  disabled?: boolean
}

export const JobItemToolbar: React.FunctionComponent<Props> = ({
  createJobItem,
  createDiscountItem,
  items,
  itemGroups,
  hasDiscountItem,
  itemsIncludeVat,
  setIncludeVat,
  disabled = false,
}) => {
  const itemGroupOptions = itemGroups ? itemGroups.map(group => ({ value: group.id, label: group.name })) : null
  const itemOptions = items ? items.map(item => ({ value: item.id, label: item.name })) : null
  return (
    <ToolbarWrapper>
      {itemGroupOptions && (
        <SelectInput
          placeholder="Lisää tuoteryhmä"
          name="itemGroup"
          label={''}
          options={itemGroupOptions}
          clearable={true}
          disabled={disabled}
          onChange={groupId => {
            if (items) {
              for (const item of items) {
                if (item.groups && item.groups.find(group => group === groupId)) {
                  createJobItem(convertItemToJobItem(item))
                }
              }
            }
          }}
        />
      )}
      {itemOptions && items && (
        <SelectInput
          onChange={itemId => {
            const item = items.find(i => i.id === itemId)
            if (item) {
              createJobItem(convertItemToJobItem(item))
            }
          }}
          placeholder="Lisää tuote"
          name="item"
          label={''}
          options={itemOptions}
          clearable={true}
          disabled={disabled}
        />
      )}
      <Button
        label="Lisää alennus"
        category="regular"
        onClick={createDiscountItem}
        disabled={hasDiscountItem || disabled}
      />

      <FormButton
        label="Tyhjä rivi"
        name="newItem"
        category="new"
        onClick={() => createJobItem(newItem)}
        disabled={disabled}
      />
      <Button
        label={itemsIncludeVat ? 'Hinnat sis. ALV' : 'Hinnat ilman ALV'}
        category={itemsIncludeVat ? 'on' : 'off'}
        onClick={() => setIncludeVat(!itemsIncludeVat)}
        disabled={disabled}
      />
    </ToolbarWrapper>
  )
}
