import { Field } from 'formik'
import React, { useCallback } from 'react'
import { CreatableAutosuggestField, GetOptionsCallback, OnChangeCallback } from '../../field/CreatableAutosuggestField'

interface Props<T> {
  name: string
  label?: string
  required?: boolean
  getOptions: GetOptionsCallback
  onChange: OnChangeCallback<T>
  placeholder?: string
  disabled?: boolean
}

type CreatableAutosuggestInputInterface<T = any> = React.FC<Props<T>>
export const CreatableAutosuggestInput: CreatableAutosuggestInputInterface = ({
  name,
  label,
  onChange,
  getOptions,
  required,
  placeholder,
  disabled,
}) => {
  const validate = useCallback(
    (value: string | null): string | undefined => {
      if (required && (!value || !value.trim())) {
        return 'Kenttä on pakollinen'
      }
      return undefined
    },
    [required]
  )
  return (
    <Field
      disabled={disabled}
      onChange={onChange}
      getOptions={getOptions}
      component={CreatableAutosuggestField}
      id={name}
      name={name}
      label={label}
      required={required}
      validate={validate}
      placeholder={placeholder}
    />
  )
}
