import { vatCategoryToPercentage } from '../../util/vatCalculations'
import { JobItemInput } from './types'

export const calculateVat = (item: JobItemInput, vatIncluded: boolean) => {
  const vatPercentage = vatCategoryToPercentage(item.vatCategory)
  return calculateNetSum(item, vatIncluded) * vatPercentage
}

const calculateNetSumWithoutDiscount = (item: JobItemInput, vatIncluded: boolean) => {
  const sum = item.quantity * item.unitPrice
  return !vatIncluded ? sum : sum / (1 + vatCategoryToPercentage(item.vatCategory))
}

export const calculateNetSum = (item: JobItemInput, vatIncluded: boolean) => {
  const sum = item.quantity * item.unitPrice * ((100 - item.discount) / 100)
  return !vatIncluded ? sum : sum / (1 + vatCategoryToPercentage(item.vatCategory))
}

export const calculateDiscountNetAmount = (item: JobItemInput, vatIncluded: boolean) => {
  return (calculateNetSumWithoutDiscount(item, vatIncluded) * item.discount) / 100
}

export const calculateDiscountGrossAmount = (item: JobItemInput, vatIncluded: boolean) => {
  return (
    (calculateNetSumWithoutDiscount(item, vatIncluded) *
      item.discount *
      (1 + vatCategoryToPercentage(item.vatCategory))) /
    100
  )
}

export const hasUndefinedPrice = (item: JobItemInput) => item.unitPrice === null

export const anyHasUndefinedPrice = (items: JobItemInput[]) => items.some(hasUndefinedPrice)
