import styled from 'styled-components'

interface Props {
  color?: string
  space?: string
}

export const VerticalLine = styled.div<Props>`
  position: relative;
  height: 1rem;
  width: 1rem;
  &:after {
    content: '';
    width: 3px;
    height: 1.4rem;
    position: absolute;
    top: -2px;
    left: 50%;
    background-color: ${props => props.theme.colors[props.color]};
    transform: translateX(-50%);
  }
`

export const Circle = styled.div<Props>`
  width: 16px;
  height: 16px;
  margin-left: 0.01rem;
  border: 3px solid ${props => props.theme.colors[props.color]};
  margin-right: ${props => (props.space === 'medium' ? '0.5rem' : '0rem')};
  border-radius: 100%;
`
