import styled, { css } from 'styled-components'

export const ItemRow = styled.div`
  display: grid;
  :not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.colors.grey300};
  }
  padding: 0.75rem 0 0.5rem;
  grid-gap: 0.5rem;
  grid-template-columns: 5fr 2fr 2fr 2fr;

  @media (max-width: ${props => props.theme.screenSize.tablet}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    grid-template-columns: 1fr;
    padding: 1.2rem 0;
  }
`

const ItemRowCommonStyling = css`
  display: grid;
  grid-gap: 0.5rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
  background-color: ${props => props.theme.colors.grey100};
  border-radius: ${props => props.theme.radius.medium};
`

export const JobItemRow = styled.div`
  ${ItemRowCommonStyling}
  grid-template-columns: 5fr 2fr 2fr 3fr 2fr 3fr 2fr minmax(3rem, 1fr);
  @media (max-width: ${props => props.theme.screenSize.computer}) {
    grid-template-columns: 5fr 3fr 2fr minmax(4rem, 3fr);
    grid-row-gap: 0.25rem;
    grid-column-gap: 0.5rem;
  }
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`

export const DiscountItemRow = styled.div`
  ${ItemRowCommonStyling}
  background-color: ${props => props.theme.colors.yellow100};
  grid-template-columns: 5fr 2fr 4fr 8fr minmax(3rem, 1fr);
  @media (max-width: ${props => props.theme.screenSize.computer}) {
    grid-template-columns: 7fr 5fr 7fr 0fr 3rem;
  }
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    grid-template-columns: 1fr;
  }
`

export const ItemColumn = styled.div`
  padding: 0 0.5rem 0 0;
  min-width: 0;
  margin: 0.2rem 0;
`

export const ToolbarWrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(150px, 0.5fr));
`
