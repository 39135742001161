import { useApolloClient, useQuery } from '@apollo/react-hooks'
import ApolloClient from 'apollo-client'
import React, { useCallback, useContext, useState } from 'react'

import { Maybe, TypeIdentifier } from '../../common/types'
import { StateStore } from '../../containers/StoreProvider/index'
import { getStoredTrackedRecords, hasStoredTrackedRecords, storeTrackedRecords } from '../../graphql-client/storedState'
import { LocationData, useGeolocation } from '../../util/useGeolocation'
import { useInterval } from '../../util/useInterval/useInterval'
import { ActiveJobsResponse, GEOLOCATION_REPORT_POSITION, TOWING_RECORDS_ACTIVE } from './queries'
import { GeolocationPosition } from './types'
import { toGeolocationPosition } from './util'

const REPORT_INTERVAL = 30 * 1000
const MAX_ERROR_COUNT = 5

export const GeolocationWatcher: React.FunctionComponent = () => {
  const { state } = useContext(StateStore)
  const userId = state.currentUser ? state.currentUser.userId : 0
  const trackedJobs = getStoredTrackedRecords()
  const locationData = useGeolocation(hasStoredTrackedRecords() ? 'watch' : 'disabled')
  const client = useApolloClient()
  const [errors, setErrors] = useState<number>(0)

  useQuery<ActiveJobsResponse>(TOWING_RECORDS_ACTIVE, {
    fetchPolicy: 'network-only',
    skip: hasStoredTrackedRecords(),
    onCompleted(response) {
      if (response.activeTowingRecords.__typename === 'ActiveJobsSuccess') {
        if (response.activeTowingRecords.activeJobs) {
          const activeJobs = response.activeTowingRecords.activeJobs.filter(
            (job) =>
              job.type === 'autoliitto' || (job.type === 'sos' && job.typeIdentifier !== TypeIdentifier.lahitapiola)
          )

          const trackedIds = activeJobs.map((job) => {
            return job.id
          })

          if (trackedIds.length > 0) storeTrackedRecords(trackedIds)
        }
      }
    },
  })

  const reportPositions = useCallback(
    async (locationData: LocationData, trackedJobs: Maybe<number[]>, userId: number) => {
      if (!trackedJobs || !locationData.timestamp) return
      if (trackedJobs && trackedJobs.length === 0) return
      if (locationData.error) return
      if (userId === 0 || errors > MAX_ERROR_COUNT) return
      const coords = locationData as Coordinates
      try {
        const response = await reportPosition(client, toQueryVariables(toGeolocationPosition(coords)))
        if (response.data.__typename === 'UnauthorizedError') {
          setErrors(MAX_ERROR_COUNT + 1)
        }
      } catch (e) {
        setErrors(errors + 1)
      }
    },
    [client, errors]
  )

  const positionCallback = useCallback(() => {
    reportPositions(locationData, trackedJobs, userId)
  }, [trackedJobs, userId, locationData, reportPositions])

  useInterval(positionCallback, REPORT_INTERVAL)

  return null
}

const reportPosition = (client: ApolloClient<any>, variables: any) => {
  return client.query({ fetchPolicy: 'network-only', variables: variables, query: GEOLOCATION_REPORT_POSITION })
}

const toQueryVariables = ({
  coords: { latitude, longitude, accuracy, altitude, altitudeAccuracy, heading, speed },
}: GeolocationPosition) => {
  return {
    location: {
      accuracy,
      altitude,
      altitudeAccuracy,
      heading,
      latitude,
      longitude,
      speed,
    },
  }
}
