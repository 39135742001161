import { useMutation } from '@apollo/react-hooks'
import React from 'react'

import { Loading } from '../../components/responses'
import { setErrorNotifications, setFatalErrorNotification } from '../../components/responses/setErrorNotifications'
import { DisplayMoney } from '../DisplayMoney'
import { NumberInput, SelectInput, TextInput } from '../layout/form/input'
import { Delete } from './Delete'
import { vatUnitOptions } from './options'
import { calculateNetSum, calculateVat, hasUndefinedPrice } from './priceCalculations'
import { DELETE_JOB_ITEM } from './queries'
import { ItemColumn, JobItemRow } from './styled'
import { DeleteTowingRecordJobItemResponse, JobItemInput } from './types'

interface Props {
  jobItem: JobItemInput
  index?: number
  removeItem: () => void
  itemsIncludeVat: boolean
  towingRecordId?: number
  disabled?: boolean
}

export const TowingRecordItem: React.FC<Props> = ({
  index,
  itemsIncludeVat,
  jobItem,
  removeItem,
  towingRecordId,
  disabled = false,
}) => {
  const [deleteJobItem, { loading }] = useMutation<DeleteTowingRecordJobItemResponse>(DELETE_JOB_ITEM, {
    onCompleted({ deleteTowingRecordJobItem }) {
      if (deleteTowingRecordJobItem.__typename === 'DeleteTowingRecordJobItemSuccess') {
        removeItem()
      } else {
        setErrorNotifications({ data: deleteTowingRecordJobItem })
      }
    },
    onError(err) {
      setFatalErrorNotification(err.message)
    },
  })

  const prefix = `jobItems[${index}]`
  const deleteNewJobItem = () => {
    removeItem()
  }
  const deleteExistingJobItem = () => {
    deleteJobItem({ variables: { input: { towingRecordId, jobItemId: jobItem.id } } })
  }

  return (
    <JobItemRow>
      <Loading loading={loading} />
      <ItemColumn>
        <TextInput label="Tuotenimike" name={`${prefix}.name`} disabled={disabled} />
      </ItemColumn>
      <ItemColumn>
        <NumberInput label="Määrä" name={`${prefix}.quantity`} disabled={disabled} />
      </ItemColumn>
      <ItemColumn>
        <TextInput label="Yksikkö" name={`${prefix}.unit`} disabled={disabled} />
      </ItemColumn>
      <ItemColumn>
        <NumberInput label="á-hinta" name={`${prefix}.unitPrice`} disabled={disabled} />
      </ItemColumn>
      <ItemColumn>
        <NumberInput label="Ale-%" name={`${prefix}.discount`} disabled={disabled} />
      </ItemColumn>
      <ItemColumn>
        <SelectInput
          label="ALV-%"
          name={`${prefix}.vatCategory`}
          options={vatUnitOptions}
          searchable={false}
          disabled={disabled}
        />
      </ItemColumn>
      <ItemColumn>
        <DisplayMoney
          label="Yhteensä"
          currency={calculateNetSum(jobItem, itemsIncludeVat) + calculateVat(jobItem, itemsIncludeVat)}
          disabled={hasUndefinedPrice(jobItem)}
        />
      </ItemColumn>
      <ItemColumn>
        <Delete onClick={jobItem.id && towingRecordId ? deleteExistingJobItem : deleteNewJobItem} disabled={disabled} />
      </ItemColumn>
    </JobItemRow>
  )
}
