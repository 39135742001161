import React, { FunctionComponent } from 'react'
import { InjectedIntlProps, defineMessages, injectIntl } from 'react-intl'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { NotificationDelay } from '../../common/types'
import { Icon } from '../icons/Icon'
import { Container, Content, IconWrapper, Message, Title } from './styles'
import { dismissToast } from '.'

const toastId = 'invalid_visibility_settings'

const messages = defineMessages({
  invalidVisibilitySettingsTitle: {
    id: 'containers.graphql.invalid_visibility_settings.title',
    defaultMessage: 'Open order not assignable to operator',
  },
  invalidVisibilitySettingsMessage: {
    id: 'containers.graphql.invalid_visibility_settings.message',
    defaultMessage: 'You cannot assign an open order to an operator.',
  },
})

export const setInvalidVisibilitySettingsNotification = () => {
  if (!toast.isActive(toastId)) {
    toast.error(<InvalidVisibilitySettingsNotification />, {
      toastId,
      autoClose: false,
      closeButton: false,
      delay: 1,
    })
    dismissToast(toastId, NotificationDelay.invalidVisibilitySettingsNotificationDelay)
  }
}

const InvalidVisibilitySettingsNotificationIntl: FunctionComponent<InjectedIntlProps> = ({ intl }) => {
  const { formatMessage } = intl

  return (
    <InfoContainer>
      <IconWrapper>
        <Icon size="big" icon={'lock'} color="red900" />
      </IconWrapper>
      <Content>
        <Title> {formatMessage(messages.invalidVisibilitySettingsTitle)}</Title>
        <Message>{formatMessage(messages.invalidVisibilitySettingsMessage)}</Message>
      </Content>
    </InfoContainer>
  )
}

export const InvalidVisibilitySettingsNotification = injectIntl(InvalidVisibilitySettingsNotificationIntl)

const InfoContainer = styled(Container)`
  background-color: ${props => props.theme.colors.red100};
  color: ${props => props.theme.colors.red900};
  border-bottom: 1px solid ${props => props.theme.colors.red500};
`
