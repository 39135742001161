import React from 'react'
import { ApolloProvider, Query } from 'react-apollo'
import { addLocaleData, IntlProvider } from 'react-intl'
// tslint:disable-next-line: no-submodule-imports
import en from 'react-intl/locale-data/en'
// tslint:disable-next-line: no-submodule-imports
import fi from 'react-intl/locale-data/fi'
import { BrowserRouter } from 'react-router-dom'
import { Normalize } from 'styled-normalize'
import { App } from './App'
import { createGraphqlClient, IS_AUTHENTICATED_QUERY } from '../../graphql-client/graphql-client'
import { GlobalStyle } from '../../styled'
import localeData from '../../translations/fi.json'
import { StoreProvider } from '../StoreProvider'
import { handleEnvironmentSettings } from '../../util/environment'
import { SocketContext, socket } from '../SocketProvider'

// Override methods for different environments
handleEnvironmentSettings()

addLocaleData([...en, ...fi])
const language = 'fi'
const apolloClient = createGraphqlClient()

interface QueryData {
  data: {
    authenticated: boolean
  }
}

// define different date and time display formats here for react-intl <FormattedDate> and <FormattedTime> components
const dateAndTimeFormats = {
  date: {
    full: { weekday: 'short', day: 'numeric', month: 'numeric', year: 'numeric' },
  },
}

export const AppContainer: React.FunctionComponent = () => (
  <IntlProvider locale={language} messages={localeData} formats={dateAndTimeFormats}>
    <ApolloProvider client={apolloClient}>
      <SocketContext.Provider value={socket} >
      <StoreProvider>
        <Normalize />
        <GlobalStyle />
        <BrowserRouter>
          <Query query={IS_AUTHENTICATED_QUERY}>
            {({ data: { authenticated } }: QueryData) => <App authenticated={authenticated} />}
          </Query>
        </BrowserRouter>
      </StoreProvider>
      </SocketContext.Provider>
    </ApolloProvider>
  </IntlProvider>
)
