import gql from 'graphql-tag'
import { UnauthorizedError } from '../../../../../../errorTypes'
import { towingRecordFragment } from '../../../../../ViewTowingRecord/TowingRecordQuery'
import { TowingRecord } from '../../../../../ViewTowingRecord/types'
import { StartRouteInputMutation } from './types'

export interface StartRouteMutationVariables
  extends Readonly<{
    input: StartRouteInputMutation
  }> {}

export interface EditStartRouteResponse {
  editStartRoute: StartRouteOutput
}

type StartRouteOutput = StartRouteSuccess | UnauthorizedError

interface StartRouteSuccess
  extends Readonly<{
    __typename: 'EditTowingRecordSuccess'
    towingRecord: TowingRecord
  }> {}

export const EDIT_START_ROUTE_MUTATION = gql`
  mutation EditStartRoute($input: EditStartRouteInput!) {
    editStartRoute(input: $input) {
      __typename
      ... on UnauthorizedError {
        error {
          code
          message
        }
      }
      ... on GenericError {
        genericError: error {
          code
          message
        }
      }
      ... on EditTowingRecordSuccess {
        towingRecord {
          ...TowingRecordFields
        }
      }
    }
  }
  ${towingRecordFragment.success}
`
