import { defineMessages } from 'react-intl'

// fix translations when form is working
// change id structure? to more generic towingOrder structure
export const messages = defineMessages({
  towingOrder: { id: 'forms.towingOrder.towingOrder', defaultMessage: 'id' },
  type: { id: 'towingOrder.type', defaultMessage: 'type' },
  visibility: { id: 'towingOrder.visibility', defaultMessage: 'visibility' },
  vehicleDetails: { id: 'forms.towingOrder.vehicleDetails', defaultMessage: 'vehicle details' },
  registrationNumber: { id: 'forms.towingOrder.vehicleDetails.registrationNumber', defaultMessage: 'reg nro' },
  vehicleClass: { id: 'forms.towingOrder.vehicleDetails.vehicleClass', defaultMessage: 'class' },
  makeAndModel: {
    id: 'forms.towingOrder.vehicleDetails.makeAndModel',
    defaultMessage: 'make and model',
  },
  insuranceCompany: {
    id: 'forms.towingOrder.vehicleDetails.insuranceCompany',
    defaultMessage: 'insurance company',
  },
  owner: { id: 'forms.towingOrder.vehicleDetails.owner', defaultMessage: 'owner' },
  holder: { id: 'forms.towingOrder.vehicleDetails.holder', defaultMessage: 'holder' },

  visibility_open: { id: 'towingOrder.visibility_open', defaultMessage: 'open' },
  visibility_restricted: { id: 'towingOrder.visibility_restricted', defaultMessage: 'restricted' },
  visibility_assigned: { id: 'towingOrder.visibility_assigned', defaultMessage: 'assigned' },
  visibility_subcontract: { id: 'towingOrder.visibility_subcontract', defaultMessage: 'subcontract' },

  status_pending_acceptance: { id: 'towingOrder.status_pending_acceptance', defaultMessage: 'pending acceptance' },
  status_open: { id: 'towingOrder.status_open', defaultMessage: 'open' },
  status_accepted: { id: 'towingOrder.status_accepted', defaultMessage: 'accepted' },
  status_assigned: { id: 'towingOrder.status_assigned', defaultMessage: 'assigned' },
  status_towing_record_created: {
    id: 'towingOrder.status_towing_record_created',
    defaultMessage: 'tow record created',
  },
  status_cancelled: { id: 'towingOrder.status_cancelled', defaultMessage: 'status_cancelled' },

  from: { id: 'towingOrder.from', defaultMessage: 'from' },
  settings_type: { id: 'towingOrder.settings.type', defaultMessage: 'Type' },
  settings_operator: { id: 'towingOrder.settings.operator', defaultMessage: 'Operator' },
  settings_station: { id: 'towingOrder.settings.station', defaultMessage: 'Station' },
  settings_subcontract: { id: 'towingOrder.settings.subcontract', defaultMessage: 'Subcontractor' },
  settings_header: { id: 'towingOrder.settings.header', defaultMessage: 'Order settings' },
  scheduling_fixed: { id: 'towingOrder.scheduling_fixed', defaultMessage: 'Fixed schedule' },
  scheduling_flexible: { id: 'towingOrder.scheduling_flexible', defaultMessage: 'Flexible schedule' },
  addTowingOrderSuccessTitle: {
    id: 'towingOrder.add.success.notification.title',
    defaultMessage: 'New towing order added',
  },
  addTowingOrderSuccessMessage: { id: 'towingOrder.add.success.notification.message', defaultMessage: 'Success' },
  editTowingOrderSuccessTitle: {
    id: 'towingOrder.edit.success.notification.title',
    defaultMessage: 'Towing order saved',
  },
  editTowingOrderSuccessMessage: {
    id: 'towingOrder.edit.success.notification.message',
    defaultMessage: 'Changes to towing order has been saved',
  },
  convertToSosOrderSuccessTitle: {
    id: 'towingOrder.convert.success.notification.title',
    defaultMessage: 'Towing order converted',
  },
  convertToSosOrderSuccessMessage: {
    id: 'towingOrder.convert.success.notification.message',
    defaultMessage: 'Changes to towing order has been saved',
  },
})
