import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'

import { sosVariables } from '../../../../../../../components/SosSubForm/types'
import { CollapseContent } from '../../../../../../../components/Collapse/CollapseContent'
import { RouteColumn, RouteRow } from '../../../../../../../components/layout'
import { SelectInput, TextInput } from '../../../../../../../components/layout/form/input'
import { FinishedDateTimeInput } from '../../../../../../../components/layout/form/input/DateTimeInput'
import { Time } from '@google/maps'
import { useSosCauseOptions } from '../../../../../../../util/useSosCauseOptions'
import { Modal } from '../../../../../../../components/Modal'
import { FinishedAtBreakdownLocationProps } from './FinishedForm'
import { SosProps } from './ArrivedForm'

export const FinishedAtBreakdownLocationFormSos: React.FunctionComponent<FinishedAtBreakdownLocationProps & SosProps> = ({
  setValue,
  action,
  setDateField,
  getValue,
  autoliittoFields,
}) => {
  const [edit, setEdit] = useState<boolean>(action === 'edit-finished')
  const [showModal, setShowModal] = useState(false)
  const [warned, setWarned] = useState(() => {
    if (autoliittoFields.hasFinishRouteInfo) return false
    return true
  })

  const deliverable = getValue('sosServiceOrder.deliverable')
  const { causeOptions, onDeliverableChange } = useSosCauseOptions(deliverable, setValue)

  const causeIsRequired = (deliverable !== 'Towage' && deliverable !== "Transport")
  const deliverables = sosVariables.deliverable

  const handleOnDeliverableChange = (deliverable: string | number | undefined): void => {
    if (!warned && (deliverable !== 'Towage' && deliverable !== 'Transport')) {
      setWarned(true)
      setShowModal(true)
    }
    setValue('sosServiceOrder.deliverable', deliverable)
    onDeliverableChange(deliverable)
  }

  useEffect(() => {
    if (action === 'edit-finished') {
      if (!edit) setEdit(true)
    } else {
      if (edit) setEdit(false)
    }
  }, [edit, action, setEdit])

  return (
    <CollapseContent>
      {showModal && (
        <Modal
          label={'Huomio'}
          closeLabel="Peruuta"
          disableCloseOnConfirm
          message={`Valitsemalla toimenpiteeksi muun kuin hinauspalvelu, määränpään
              reittipisteen tiedot tyhjennetään tallennuksen yhteydessä.`}
          onConfirm={() => {
            setShowModal(false)
          }}
          close={() => {
            setShowModal(false)
            setValue('sosServiceOrder.deliverable', 'Towage')
          }}
        />
      )}
      <RouteRow columns={`1fr 1fr 1fr`}>
        <RouteColumn>
          <SelectInput
            label="Toimenpide"
            name="sosServiceOrder.deliverable"
            options={deliverables}
            disabled={!edit}
            onChange={handleOnDeliverableChange}
            required
          />
        </RouteColumn>
        <RouteColumn>
          <SelectInput
            label="Vika"
            name="sosServiceOrder.cause"
            options={causeOptions}
            disabled={!edit && autoliittoFields.disableTowingReason}
            required={causeIsRequired}
          />
        </RouteColumn>
        <RouteColumn>
          <FinishedDateTimeInput
            name="arrivalRoute"
            label="Valmistumisaika"
            customOnChange={() => {
              const value = (format(new Date(), 'HH:mm') as unknown) as Time
              setDateField('arrivalRoute.finishedDate')
              setValue('arrivalRoute.finishedTime', value)
            }}
            required={edit}
            disabled={!edit}
            showCurrentTimeBtn
          />
        </RouteColumn>
      </RouteRow>
      <RouteRow columns={`3fr`}>
        <RouteColumn>
          <TextInput
            name="sosServiceOrder.keyLocation"
            label="Avainten jättöpaikka"
            disabled={!edit && autoliittoFields.disableVehicleKeyLocation}
          />
        </RouteColumn>
      </RouteRow>
    </CollapseContent>
  )
}
