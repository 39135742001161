import React from 'react'
import { defineMessages, FormattedDate, FormattedMessage } from 'react-intl'

const messages = defineMessages({
  today: {
    id: 'components.displayDate.today',
    defaultMessage: 'today'
  }
})

interface Props {
  date: Date | null
  format?: string
}

export const DisplayDate: React.FunctionComponent<Props> = ({ date, format }) => {
  if (date) {
    const today = new Date()
    const dateIsToday = today.toDateString() === date.toDateString() ? true : false
    if (dateIsToday) {
      return <FormattedMessage {...messages.today} />
    }
    return <>{date && <FormattedDate value={date} format={format ? format : undefined} />}</>
  }
  return null
}
