import gql from 'graphql-tag'

export const SAVE_PREFERENCES = gql`
  mutation savePreferences($input: PreferencesInput!) {
    savePreferences(input: $input) {
      ... on UnauthorizedError {
        error {
          code
          message
          details
          context
        }
      }
      ... on SavePreferencesSuccess {
        preferences {
          id
          filters
        }
      }
    }
  }
`
