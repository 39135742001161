import gql from 'graphql-tag'
import { towingRecordFragment } from '../../../containers/TowingRecordPage/ViewTowingRecord/TowingRecordQuery'

export const UPDATE_STARTED_MUTATION = gql`
  mutation UpdateStarted($input: UpdateStartedInput!) {
    updateStarted(input: $input) {
      __typename
      ... on UnauthorizedError {
        error {
          code
          message
        }
      }
      ... on GenericError {
        genericError: error {
          code
          message
        }
      }
      ... on StatusChangeForbiddenError {
        error {
          code
          message
        }
      }
      ... on UpdateStartedSuccess {
        towingRecord {
          ...TowingRecordFields
        }
      }
    }
  }
  ${towingRecordFragment.success}
`

export const UPDATE_ARRIVED_AT_BREAKDOWN_LOCATION_MUTATION = gql`
  mutation UpdateArrivedAtBreakdownLocation($input: UpdateArrivedAtBreakdownLocationInput!) {
    updateArrivedAtBreakdownLocation(input: $input) {
      __typename
      ... on UnauthorizedError {
        error {
          code
          message
        }
      }
      ... on GenericError {
        genericError: error {
          code
          message
        }
      }
      ... on StatusChangeForbiddenError {
        error {
          code
          message
        }
      }
      ... on UpdateArrivedAtBreakdownLocationSuccess {
        towingRecord {
          ...TowingRecordFields
        }
      }
    }
  }
  ${towingRecordFragment.success}
`

export const UPDATE_FINISHED_AT_BREAKDOWN_LOCATION_MUTATION = gql`
  mutation UpdateFinishedAtBreakdownLocation($input: UpdateFinishedAtBreakdownLocationInput!) {
    updateFinishedAtBreakdownLocation(input: $input) {
      __typename
      ... on UnauthorizedError {
        error {
          code
          message
        }
      }
      ... on GenericError {
        genericError: error {
          code
          message
        }
      }
      ... on StatusChangeForbiddenError {
        error {
          code
          message
        }
      }
      ... on UpdateFinishedAtBreakdownLocationSuccess {
        towingRecord {
          ...TowingRecordFields
        }
      }
    }
  }
  ${towingRecordFragment.success}
`

export const UPDATE_ARRIVED_AT_DESTINATION_MUTATION = gql`
  mutation UpdateArrivedAtDestination($input: UpdateArrivedAtDestinationInput!) {
    updateArrivedAtDestination(input: $input) {
      __typename
      ... on UnauthorizedError {
        error {
          code
          message
        }
      }
      ... on GenericError {
        genericError: error {
          code
          message
        }
      }
      ... on StatusChangeForbiddenError {
        error {
          code
          message
        }
      }
      ... on UpdateArrivedAtDestinationSuccess {
        towingRecord {
          ...TowingRecordFields
        }
      }
    }
  }
  ${towingRecordFragment.success}
`

export const UPDATE_FINISHED_AT_DESTINATION_MUTATION = gql`
  mutation UpdateFinishedAtDestination($input: UpdateFinishedAtDestinationInput!) {
    updateFinishedAtDestination(input: $input) {
      __typename
      ... on UnauthorizedError {
        error {
          code
          message
        }
      }
      ... on GenericError {
        genericError: error {
          code
          message
        }
      }
      ... on StatusChangeForbiddenError {
        error {
          code
          message
        }
      }
      ... on UpdateFinishedAtDestinationSuccess {
        towingRecord {
          ...TowingRecordFields
        }
      }
    }
  }
  ${towingRecordFragment.success}
`

export const UPDATE_FINALIZED_MUTATION = gql`
  mutation UpdateFinalized($input: UpdateFinalizedInput!) {
    updateFinalized(input: $input) {
      __typename
      ... on UnauthorizedError {
        error {
          code
          message
        }
      }
      ... on GenericError {
        genericError: error {
          code
          message
        }
      }
      ... on StatusChangeForbiddenError {
        error {
          code
          message
        }
      }
      ... on UpdateFinalizedSuccess {
        towingRecord {
          id
        }
      }
    }
  }
`
