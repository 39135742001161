import { WebMessage } from './types'

export const sendMessageToWebView = (event: WebMessage) => {
  if ('ReactNativeWebView' in window && 'postMessage' in window.ReactNativeWebView) {
    const data = JSON.stringify(event)
    window.ReactNativeWebView.postMessage(data)
  }
}

export const canSendMessageToWebView = () => {
  return 'ReactNativeWebView' in window && 'postMessage' in window.ReactNativeWebView
}
