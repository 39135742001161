import React, { FunctionComponent } from 'react'
import { InjectedIntlProps, defineMessages, injectIntl } from 'react-intl'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { NotificationDelay } from '../../common/types'
import { Icon } from '../icons/Icon'
import { Container, Content, IconWrapper, Message, Title } from './styles'
import { dismissToast } from '.'

const toastId = 'invalid_param'

const messages = defineMessages({
  invalidParamTitle: {
    id: 'containers.graphql.invalid_param.title',
    defaultMessage: 'Missing field value',
  },
  invalidParamMessage: {
    id: 'containers.graphql.invalid_param.message',
    defaultMessage: 'A mandatory field is empty.',
  },
})

export const setInvalidParamNotification = (message: string = '') => {
  if (!toast.isActive(toastId)) {
    toast.error(<InvalidParamNotification message={message} />, {
      toastId,
      autoClose: false,
      closeButton: false,
      delay: 1,
    })
    dismissToast(toastId, NotificationDelay.invalidParameterErrorNotificationDelay)
  }
}

const InvalidParamNotificationIntl: FunctionComponent<InjectedIntlProps & { message: string }> = ({
  intl,
  message,
}) => {
  const { formatMessage } = intl

  return (
    <InfoContainer>
      <IconWrapper>
        <Icon size="big" icon={'lock'} color="red900" />
      </IconWrapper>
      <Content>
        <Title> {formatMessage(messages.invalidParamTitle)}</Title>
        <Message>{`${formatMessage(messages.invalidParamMessage)} ${message}`}</Message>
      </Content>
    </InfoContainer>
  )
}

export const InvalidParamNotification = injectIntl(InvalidParamNotificationIntl)

const InfoContainer = styled(Container)`
  background-color: ${props => props.theme.colors.red100};
  color: ${props => props.theme.colors.red900};
  border-bottom: 1px solid ${props => props.theme.colors.red500};
`
