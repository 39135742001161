import { FinnishBusinessIds } from 'finnish-business-ids'
import { Field } from 'formik'
import React, { useCallback } from 'react'
import { BusinessIdField } from '../../field'

interface Props {
  name: string
  label?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
}

export const BusinessIdInput: React.FunctionComponent<Props> = ({ name, label, placeholder, disabled, required }) => {
  const validate = useCallback(
    (value: string | null): string | undefined => {
      if (required && (!value || !value.trim())) {
        return 'Kenttä on pakollinen'
      }
      if (value && !FinnishBusinessIds.isValidBusinessId(value)) {
        return 'Virheellinen Y-tunnus'
      }

      return undefined
    },
    [required]
  )

  return (
    <Field
      component={BusinessIdField}
      id={name}
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      validate={validate}
      disabled={disabled}
    />
  )
}
