import { cloneDeep } from 'lodash'
import { toScalarShortDate, toScalarTime } from '../../../../../../../util/convertToScalar'
import { toALScalarTimestamp, arrivalRouteToMutationVariables } from '../../ArrivalRoute/mutation/toMutationVariables'
import { EditStartRouteFormValues, SosServiceOrderRouteValues } from '../types'
import { StartRouteMutationVariables } from './mutation'
import { StartRoute, StartRouteMutationInput, StartRouteSosServiceOrderInput } from './types'

export const toMutationVariables = (inputValues: EditStartRouteFormValues): StartRouteMutationVariables => {
  const values = cloneDeep(inputValues)

  let estimatedTimeOfArrival = null

  if (values.automobileAndTouringClubFinland) {
    estimatedTimeOfArrival = values.automobileAndTouringClubFinland.estimatedTimeOfArrival
  }

  if (values.sosServiceOrder) {
    estimatedTimeOfArrival = values.sosServiceOrder.estimatedTimeOfArrival
  }

  return {
    input: {
      id: values.id,
      startRoute: {
        startRoute: routeToMutationVariables(values.startRoute),
        estimatedTimeOfArrival: estimatedTimeOfArrival ? toALScalarTimestamp(estimatedTimeOfArrival) : null,
        sosServiceOrder: values.sosServiceOrder ? sosServiceOrderToMutationVariables(values.sosServiceOrder) : null,
      },
      arrivalRoute: values.arrivalRoute ? arrivalRouteToMutationVariables(values.arrivalRoute) : null,
      notes: values.notes,
      vehicleRegNo: values.vehicleRegNo,
    },
  }
}

const routeToMutationVariables = (route: StartRoute): StartRouteMutationInput => {
  return {
    id: route.id,
    ordering: 0,
    address: route.address,
    city: route.city,
    zipcode: route.zipcode,
    coords: {
      lat: route.coords.lat,
      long: route.coords.long,
    },
    description: route.description,
    displayTimestampOnPrintables: route.displayTimestampOnPrintables,
    type: route.type,
    geolocated: route.geolocated,
    arrivalDate: route.arrivalDate && route.arrivalTime ? toScalarShortDate(route.arrivalDate) : null,
    arrivalTime: route.arrivalTime ? toScalarTime(route.arrivalTime) : null,
  }
}

const sosServiceOrderToMutationVariables = (
  serviceOrder: SosServiceOrderRouteValues
): StartRouteSosServiceOrderInput => {
  const { currentLocation } = serviceOrder
  return {
    address: currentLocation.address,
    city: currentLocation.city,
    zipcode: currentLocation.zipcode,
    coords: {
      lat:
        serviceOrder && serviceOrder.currentLocation && serviceOrder.currentLocation.coords
          ? serviceOrder.currentLocation.coords.lat
          : null,
      long:
        serviceOrder && serviceOrder.currentLocation && serviceOrder.currentLocation.coords
          ? serviceOrder.currentLocation.coords.long
          : null,
    },
  }
}

// const arrivalRouteToMutationInput = (arrivalRoute: Maybe<CurrentLocation
//   >): Maybe<ArrivalRouteMutationInput> => {
//   if (!arrivalRoute) return null

//   const {
//     id,
//     ordering,
//     type,
//     address,
//     city,
//     description,
//     date,
//     time,
//     displayTimestampOnPrintables,
//     coords,
//     geolocated,
//   } = arrivalRoute

//   return {
//     id,
//     ordering,
//     type,
//     address,
//     city,
//     description,
//     date: date && time ? toScalarShortDate(date) : null,
//     time: date && time ? toScalarTime(time) : null,
//     displayTimestampOnPrintables,
//     coords,
//     geolocated,
//   }
// }
