import React, { useContext, useEffect } from 'react'
import { useMutation, useQuery } from 'react-apollo'
import { DispatchStore, StateStore } from '.'
import { setGenericResponseErrorNotification, setSuccessNotification } from '../../components/notification'
import { setErrorNotifications } from '../../components/responses/setErrorNotifications'
import { getStoredUserId } from '../../graphql-client/storedState'
import { GenericStorageRetrieveFn, persistentDelete, persistentGet } from '../../util/localStorage/localStorage'
import { ADD_TOWING_ORDER_MUTATION } from '../TowingOrderPage/AddTowingOrder/mutation/mutation'
import { toMutationVariables as addTowingOrderMutationVariables } from '../TowingOrderPage/AddTowingOrder/mutation/toMutationVariables'
import { AddTowingOrderResponse } from '../TowingOrderPage/AddTowingOrder/mutation/types'
import { AddTowingOrderFormValues } from '../TowingOrderPage/AddTowingOrder/types'
import { ADD_TOWING_ORDER_MUTATION as EDIT_TOWING_ORDER_MUTATION } from '../TowingOrderPage/EditTowingOrder/mutation/mutation'
import { toMutationVariables as editTowingOrderMutationVariables } from '../TowingOrderPage/EditTowingOrder/mutation/toMutationVariables'
import { EditTowingOrderResponse } from '../TowingOrderPage/EditTowingOrder/mutation/types'
import { EditTowingOrderFormValues } from '../TowingOrderPage/EditTowingOrder/types'
import { EDIT_TOWING_RECORD } from '../TowingRecordPage/EditTowingRecord/mutation/mutation'
import { toMutationVariables as toTowingRecordMutationVariables } from '../TowingRecordPage/EditTowingRecord/mutation/toMutationVariables'
import { EditTowingRecordResponse } from '../TowingRecordPage/EditTowingRecord/mutation/types'
import { EditTowingRecordFormValues } from '../TowingRecordPage/EditTowingRecord/types'
import { setOfflineMode, setUnSavedJobCount } from './actions'
import { PING_QUERY, PingResponse } from './queries'
import { maybeStartTrackingRecord } from '../../components/TrackedJobs/util'

export const STORED_ADD_TOWING_ORDER = 'addTowingOrder'
export const STORED_EDIT_TOWING_ORDER = 'editTowingOrder'
export const STORED_TOWING_RECORD = 'towingRecord'

export const OfflineMode: React.FunctionComponent = () => {
  const { state } = useContext(StateStore)
  const { dispatch } = useContext(DispatchStore)

  const handleSubmit = () => {
    const persistentGetNewTowingOrder: GenericStorageRetrieveFn<AddTowingOrderFormValues> = persistentGet
    const persistentGetUpdatedTowingOrder: GenericStorageRetrieveFn<EditTowingOrderFormValues> = persistentGet
    const persistentGetTowingRecord: GenericStorageRetrieveFn<EditTowingRecordFormValues> = persistentGet

    const newTowingOrder = persistentGetNewTowingOrder(STORED_ADD_TOWING_ORDER)

    if (newTowingOrder && !('id' in newTowingOrder)) {
      addTowingOrder({ variables: addTowingOrderMutationVariables(newTowingOrder, getStoredUserId()) })
    }

    const updatedTowingOrder = persistentGetUpdatedTowingOrder(STORED_EDIT_TOWING_ORDER)

    if (updatedTowingOrder && 'id' in updatedTowingOrder) {
      editTowingOrder({ variables: editTowingOrderMutationVariables(updatedTowingOrder, getStoredUserId()) })
    }

    const towingRecord = persistentGetTowingRecord(STORED_TOWING_RECORD)

    if (towingRecord) {
      editTowingRecord({ variables: toTowingRecordMutationVariables(towingRecord) })
    }
  }

  const { startPolling, stopPolling } = useQuery<PingResponse>(PING_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted(response) {
      if (response.ping.__typename === 'PingSuccess') {
        if (state.unSavedJobCount > 0) handleSubmit()
        dispatch(setOfflineMode(false))
      }
    },
  })

  useEffect(() => {
    if (state.offlineMode && state.unSavedJobCount > 0) {
      startPolling(5000)
    } else {
      stopPolling()
    }
  }, [state.offlineMode, state.unSavedJobCount, startPolling, stopPolling])

  const [addTowingOrder] = useMutation<AddTowingOrderResponse>(ADD_TOWING_ORDER_MUTATION, {
    onCompleted(addTowingOrderResponse) {
      if (addTowingOrderResponse.addTowingOrder.__typename === 'AddTowingRecordSuccess') {
        persistentDelete(STORED_ADD_TOWING_ORDER)
        setSuccessNotification('Kuormakirja tallennettu', '')
        dispatch(setUnSavedJobCount(state.unSavedJobCount - 1))
      } else if (addTowingOrderResponse.addTowingOrder.__typename === 'AddTowingOrderSuccess') {
        persistentDelete(STORED_ADD_TOWING_ORDER)
        setSuccessNotification('Tilaus tallennettu', '')
        dispatch(setUnSavedJobCount(state.unSavedJobCount - 1))
      } else {
        setErrorNotifications({ data: addTowingOrderResponse.addTowingOrder })
      }
    },
    onError() {
      if (!state.offlineMode) {
        dispatch(setOfflineMode(true))
      }
    },
  })

  const [editTowingOrder] = useMutation<EditTowingOrderResponse>(EDIT_TOWING_ORDER_MUTATION, {
    onCompleted(editTowingOrderResponse) {
      if (editTowingOrderResponse.editTowingOrder.__typename === 'EditTowingOrderSuccess') {
        persistentDelete(STORED_EDIT_TOWING_ORDER)
        setSuccessNotification('Tilaus tallennettu', '')
        dispatch(setUnSavedJobCount(state.unSavedJobCount - 1))
        return
      }
      if (editTowingOrderResponse.editTowingOrder.__typename === 'AddTowingRecordSuccess') {
        persistentDelete(STORED_EDIT_TOWING_ORDER)
        setSuccessNotification('Tilaus tallennettu', '')
        dispatch(setUnSavedJobCount(state.unSavedJobCount - 1))
        return
      }
      setErrorNotifications({ data: editTowingOrderResponse.editTowingOrder })
    },
    onError() {
      if (!state.offlineMode) {
        dispatch(setOfflineMode(true))
      }
    },
  })

  const [editTowingRecord] = useMutation<EditTowingRecordResponse>(EDIT_TOWING_RECORD, {
    onCompleted({ editTowingRecord }) {
      if ('towingRecord' in editTowingRecord) {
        maybeStartTrackingRecord(editTowingRecord.towingRecord)
      }
      if (
        editTowingRecord.__typename === 'EditTowingRecordSuccess' ||
        editTowingRecord.__typename === 'EditTowingRecordWithBillingUpdateSuccess'
      ) {
        setSuccessNotification('Kuormakirja tallennettu', '')
        persistentDelete(STORED_TOWING_RECORD)
        dispatch(setUnSavedJobCount(state.unSavedJobCount - 1))
        return
      }

      if (editTowingRecord.__typename === 'ConcurrencyConflict') {
        persistentDelete(STORED_TOWING_RECORD)
        dispatch(setUnSavedJobCount(state.unSavedJobCount - 1))
        return
      }

      setErrorNotifications({ data: editTowingRecord })
    },
    onError() {
      if (!state.offlineMode) {
        dispatch(setOfflineMode(true))
      }
    },
  })

  return null
}

export const handleNetworkError = (dispatch: any, jobCount: number) => {
  setGenericResponseErrorNotification('NetworkError')
  dispatch(setOfflineMode(true))
  dispatch(setUnSavedJobCount(jobCount))
}
