import React, { useContext } from 'react'
import { useTheme } from '../../containers/ThemeProvider'
import { IconWrapper } from '../MenuLink'
import { Icon } from '../icons/Icon'
import { MenuItem } from '../layout'
import LinkButton from '../LinkButton'
import { StateStore } from '../../containers/StoreProvider'

export const ThemeButton = () => {
  const { toggleTheme } = useTheme()
  const { state } = useContext(StateStore)
  const disableDarkmode = state.currentUser && state.currentUser.companyId !== 521
  
  if (disableDarkmode) return null

  return (
    <MenuItem>
      <LinkButton onClick={toggleTheme}>
        <IconWrapper>
          <Icon color="grey500" icon="theme" />
        </IconWrapper>
      </LinkButton>
    </MenuItem>
  )
}
