import { FormatMessageType, Options } from '../../common/types'

export const translateInputOptions = (optionObject: Options, formatMessage: FormatMessageType) =>
  Object.entries(optionObject.options).map(([key, value]) => {
    return {
      value: key,
      label: formatMessage({
        id: value,
      }),
    }
  })
