import React, { useEffect, useState } from 'react'
import { PoseGroup } from 'react-pose'
import styled from 'styled-components'

import { LockMessage, TowingRecordRouteType, TypeIdentifier } from '../../common/types'
import autoliitto from '../../images/autoliitto.png'
import lahitapiola from '../../images/lahitapiola.png'
import sos from '../../images/sos.png'
import { DisplayDate } from '../DisplayDate'
import { Icon } from '../icons/Icon'
import { JobStatus } from '../JobStatus'
import { JobTypeDescription, getDeliverable } from '../JobTypeDescription'
import {
  ExpandedList,
  Heading3,
  ImageWrapper,
  List,
  ListColumn,
  ListRowWrapper,
  ListStatus,
  SimpleRow,
  Text,
} from '../layout'
import { NavigateTo } from '../NavigateTo'
import { VehicleMarkAndModel } from '../VehicleMarkAndModel'
import { TowingRecordRowInterface } from './types'
import { getStoredCompanyId } from '../../graphql-client/storedState'
import { useSocket } from '../../util/useSocket'
import { getRepairShop } from './getRepairShop'
import { parseAddress } from '../Parsers'

interface Props {
  towingRecord: TowingRecordRowInterface
  translateMessage: (messageId: string) => string
  slug: string
  expandedRow: number | null
  handleItemClick: (id: number) => void
  station: {
    name: string
  } | null
  operatorName: string
}

const StyledIcon = styled(Icon)`
  svg {
    width: 1rem;
    height: 1rem;
    margin-left: -2px;
  }
`

export const TowingRecordRow: React.FunctionComponent<Props> = ({
  expandedRow,
  handleItemClick,
  towingRecord,
  slug,
  station,
  operatorName,
}) => {
  const fromIndex = towingRecord.routes.findIndex(route => route.type === TowingRecordRouteType.arrival)
  const toIndex = towingRecord.routes.findIndex(route => route.type === TowingRecordRouteType.finish)
  const repairShop = getRepairShop(towingRecord)

  const recordNumber = towingRecord.recordNumber || '-'
  const registrationNumber = towingRecord.vehicleDetails.registrationNumber || '-'
  const notUnfinished = towingRecord.status !== 'unfinished'
  const regNumber = notUnfinished ? registrationNumber + ' / ' + recordNumber : registrationNumber

  const [locked, setLocked] = useState<string | null>(towingRecord.locked)
  const companyId = getStoredCompanyId()
  const socket = useSocket()
  useEffect(() => {
    if (!companyId) {
      return
    }
    const lockedCallback = (message: string) => {
      const data: LockMessage = message ? JSON.parse(message) : null
      setLocked(data && data.userName ? data.userName : null)
    }
    const unlockedCallback = () => {
      setLocked(null)
    }
    socket.on(`company-${companyId}.towing_record-${towingRecord.id}.locked`, lockedCallback)
    socket.on(`company-${companyId}.towing_record-${towingRecord.id}.unlocked`, unlockedCallback)
    return () => {
      console.log('removing event listeners from towing record')
      socket.removeEventListener(`company-${companyId}.towing_record-${towingRecord.id}.locked`, lockedCallback)
      socket.removeEventListener(`company-${companyId}.towing_record-${towingRecord.id}.unlocked`, unlockedCallback)
    }
  }, [setLocked, companyId, socket, towingRecord.id])

  return (
    <ListRowWrapper
      onClick={() => handleItemClick(towingRecord.id)}
      isCanceled={
        towingRecord.sosServiceOrder && towingRecord.sosServiceOrder.orderStatus === 'cancelled_by_sos' ? true : false
      }
    >
      <List>
        <ListColumn justify="center">
          <Heading3>{regNumber}</Heading3>
          <VehicleMarkAndModel markAndModel={towingRecord.vehicleDetails.makeAndModel} />
          <Text>
            <DisplayDate date={towingRecord.jobDetails.towingDate} format="full" />
          </Text>
        </ListColumn>
        <ListColumn justify="center">
          {towingRecord.typeIdentifier === TypeIdentifier.autoliitto && (
            <ImageWrapper justifyContent="center" alignItems="center">
              <img src={autoliitto} alt="Autoliitto" width="55rem" />
            </ImageWrapper>
          )}
          {towingRecord.typeIdentifier === TypeIdentifier.sos && (
            <ImageWrapper justifyContent="center" alignItems="center">
              <img src={sos} alt="SOS" width={`40rem`} />
            </ImageWrapper>
          )}
          {towingRecord.typeIdentifier === TypeIdentifier.lahitapiola && (
            <ImageWrapper justifyContent="center" alignItems="center">
              <img src={lahitapiola} alt="Lähitapiola" width={`40rem`} />
            </ImageWrapper>
          )}
        </ListColumn>
        <ListColumn>
          <ListStatus status={towingRecord.status}>
            <JobStatus status={towingRecord.status} operatorName={operatorName} jobStatus={towingRecord.jobStatus} />
          </ListStatus>
          <Text color="grey400">
            <JobTypeDescription
              serviceType={towingRecord.jobDetails.serviceType}
              type={towingRecord.typeIdentifier}
              label={towingRecord.label}
              station={station}
              deliverable={getDeliverable(towingRecord.sosServiceOrder)}
            />
          </Text>
        </ListColumn>
        <ListColumn>
          <NavigateTo direction={locked ? 'locked' : 'forward'} slug={slug} />
        </ListColumn>
      </List>
      <PoseGroup>
        {towingRecord.id === expandedRow && (
          <ExpandedList key={expandedRow}>
            <ListColumn>
              <SimpleRow justify={'flex-start'} marginMobileBottom="0">
                <StyledIcon icon="map-marker" color="black" marginRight="0.3rem" size="x-small" />
                {fromIndex > -1 ? (
                  <Text>{`${towingRecord.routes[fromIndex].address} ${towingRecord.routes[fromIndex].city}`}</Text>
                ) : (
                  '-'
                )}
              </SimpleRow>
              {fromIndex > -1 && towingRecord.routes[fromIndex].description !== '' && (
                <SimpleRow marginMobileBottom="0" margin="0.25rem 0 0.25rem 1.25rem">
                  <Text>{`(${towingRecord.routes[fromIndex].description})`}</Text>
                </SimpleRow>
              )}
              <SimpleRow justify={'flex-start'} marginMobileBottom="0">
                <StyledIcon icon="map-marker" color="black" marginRight="0.3rem" size="x-small" />
                {toIndex > -1 && towingRecord.routes[toIndex].address && towingRecord.routes[toIndex].city ? (
                  <Text>{`${towingRecord.routes[toIndex].address} ${towingRecord.routes[toIndex].city}`}</Text>
                ) : repairShop &&
                  towingRecord.typeIdentifier === TypeIdentifier.autoliitto &&
                  towingRecord.jobDetails.serviceType !== 'towing' ? (
                  <Text fontWeight="bold">{`Jos työ muuttuu hinaukseksi:`}</Text>
                ) : (
                  '-'
                )}
              </SimpleRow>
              {toIndex > -1 && towingRecord.routes[toIndex].description !== '' ? (
                <SimpleRow marginMobileBottom="0" margin="0.25rem 0 0.25rem 1.25rem">
                  <Text>{`(${towingRecord.routes[toIndex].description})`}</Text>
                </SimpleRow>
              ) : (
                towingRecord.typeIdentifier === TypeIdentifier.autoliitto &&
                towingRecord.jobDetails.serviceType !== 'towing' &&
                repairShop && (
                  <>
                    <SimpleRow marginMobileBottom="0" margin="0.25rem 0 0.25rem 1.25rem">
                      <Text>{parseAddress(repairShop.location.address, repairShop.location.city)}</Text>
                    </SimpleRow>
                    <SimpleRow marginMobileBottom="0" margin="0 0 0.25rem 1.25rem">
                      <Text>{repairShop.name}</Text>
                    </SimpleRow>
                  </>
                )
              )}
            </ListColumn>
            <ListColumn />
            <ListColumn>
              <Text>{towingRecord.jobDetails.notes}</Text>
            </ListColumn>
            <ListColumn />
          </ExpandedList>
        )}
      </PoseGroup>
    </ListRowWrapper>
  )
}
