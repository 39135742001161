import React from 'react'
import { Maybe, TowingRecordJobStatus, TowingRecordType } from '../../../../../../../common/types'
import { FinishRouteAction } from '../../../../../../../components/SosSubForm/FinishRoute/types'
import { SosFinishedAtDestinationForm } from '../../../../../../../components/SosSubForm/FinishRouteForm/FinishedForm'
import { GeoLocateAddressData } from '../../../types'
import { FinishedAtDestinationFormAL } from './FinishedAtDestinationFormAL'
import { FinishedAtDestinationForm } from './FinishedAtDestinationFrom'

export interface FinishedAtDestinationProps {
  setValue: (field: string, value: any) => void
  getValue: any
  setDateField: (fieldName: string) => void
  setRouteLocation: (addressData: GeoLocateAddressData | null) => void
  clear: () => void
  action: FinishRouteAction
  setAction: (action: FinishRouteAction) => void
  jobStatus: TowingRecordJobStatus
  stationId: Maybe<number>
  type: TowingRecordType
}

export interface DefaultProps extends FinishedAtDestinationProps {}

export interface SosProps extends FinishedAtDestinationProps {}

export interface ALProps extends FinishedAtDestinationProps {
  translatedServiceTypeOptions: object[]
  batteryServiceOptions: object[]
  changeServiceType: (serviceType: string | number | undefined) => void
}

export const FinishedForm = ({ type, ...props }: FinishedAtDestinationProps | ALProps) => {
  if (type === TowingRecordType.sos) return <SosFinishedAtDestinationForm {...(props as SosProps)} />
  if (type === TowingRecordType.autoliitto) return <FinishedAtDestinationFormAL {...(props as ALProps)} />
  if (type === TowingRecordType.default) return <FinishedAtDestinationForm {...(props as DefaultProps)} />
  return null
}
