import React, { useState, useContext } from 'react'
import { RouteColumn, RouteRow, RouteWrapper } from '../../../../../../components/layout/'
import { GeoLocateAddressData } from '../../types'
import { RouteHeader } from '../RouteHeader'
import { StateStore } from '../../../../../../containers/StoreProvider'
import { Button, ButtonCategory } from '../../../../../../components/Button'
import { AutoliittoSeparatorLine } from '../styled'
import { StationButtonGroup } from '../../../../../../components/StationButtonGroup'
import { LocationInputMap, toMapData } from '../../../../../../components/layout/form/input/LocationInputMap'
import { ArrivalDateTimeInput } from '../../../../../../components/layout/form/input/DateTimeInput'
import { format } from 'date-fns'
import { Coords, Maybe, Time } from '../../../../../../common/types'
import { RouteNotes } from '../../RouteNotes'
import { useEtaCalculation } from '../../../../../../util/useEtaCalculation'
import { arrivalRouteToQueryVariable } from '../StartRoute/StartRoute'
import { ClearButton } from '../FinishRoute/FinishRoute'
import { RouteColumnAlignRight } from '../../../../../../components/layout/routesmenu'

export interface LocationOption {
  value: string
  label: string
}
export const startLocationOther = 'OTHER'

interface EndRouteProps {
  onCancel: () => void
  regNo: string
  setRouteLocation: (addressData: GeoLocateAddressData | null) => void
  updateRouteDate?: () => void
  showCustomAddressInitially: boolean
  initialValues?: any
  isAutoliitto?: boolean
  isSos?: boolean
  useExtendedRoutes: boolean
  clear: () => void
  setGeolocatedField: (geolocated: boolean) => void
  setLoading: (loading: boolean) => void
  getValue: any
  setValue: (field: string, value: any) => void
  disableEtaBtn?: boolean
  stationId: Maybe<number>
}

export const EndRoute: React.FunctionComponent<EndRouteProps> = ({
  onCancel,
  regNo,
  setRouteLocation,
  showCustomAddressInitially,
  initialValues,
  isAutoliitto,
  setGeolocatedField,
  setLoading,
  getValue,
  setValue,
  isSos,
  disableEtaBtn,
  stationId,
  useExtendedRoutes,
  clear,
}) => {
  const [calculateEta, setCalculateEta] = useState<boolean>(false)
  useEtaCalculation(
    {
      enableHighAccuracy: true,
      calculate: calculateEta,
      destination: arrivalRouteToQueryVariable({
        address: getValue(`endRoute.address`) as string,
        city: getValue(`endRoute.city`) as string,
        coords: getValue(`endRoute.coords`) as Coords,
      }),
    },
    eta => {
      const date = new Date(eta)
      const time = (format(new Date(eta), 'HH:mm') as unknown) as Time

      if (date) {
        setValue('endRoute.arrivalDate', date)
      }

      if (time) {
        setValue('endRoute.arrivalTime', time)
      }

      setCalculateEta(false)
    }
  )

  const { state } = useContext(StateStore)
  const selectedBtnCategory: ButtonCategory = 'yellow'
  const unSelectedBtnCategory: ButtonCategory = 'new'

  const endRoute = initialValues && initialValues.endRoute ? initialValues.endRoute : null
  const routeAddress = endRoute && endRoute.address && endRoute.city ? `${endRoute.address}, ${endRoute.city}` : ''

  const showStations = state.settings && state.settings.stations && state.settings.stations.length > 0
  const rowColumns = showStations === true ? '1fr 1fr 1fr' : '1fr 1fr'

  const stations = state.settings ? state.settings.stations : null
  const selectedStation =
    (stations && stations.find(station => `${station.address}, ${station.city}` === routeAddress)) || null

  let selection = showStations && !showCustomAddressInitially ? 'station' : 'manual'

  if (selectedStation) {
    selection = 'station'
  }

  if (!selectedStation && routeAddress) {
    selection = 'manual'
  }

  const selectOption = (selectedOption: string) => {
    setCleared(false)
    setSelectedOption(selectedOption)
  }

  const [showCustomAddress, setShowCustomAddress] = useState<boolean>(showCustomAddressInitially)
  const [selectedOption, setSelectedOption] = useState<string>(selection)
  const [selectedStationId, setStation] = useState<number | null>(selectedStation ? selectedStation.id : null)
  const [cleared, setCleared] = useState<boolean>(false)

  const oldRoutes = !useExtendedRoutes && !isSos
  return (
    <RouteWrapper>
      <RouteHeader label="Lopetuspaikka" regNo={regNo} cancel={onCancel} />
      <>
        <RouteRow columns={rowColumns}>
          {showStations && (
            <RouteColumn>
              <Button
                label="Asemapaikka"
                category={selectedOption && selectedOption === 'station' ? selectedBtnCategory : unSelectedBtnCategory}
                onClick={() => {
                  selectOption('station')
                  setGeolocatedField(false)
                }}
                size="ml"
                maxWidth="100%"
              />
            </RouteColumn>
          )}
          <RouteColumn>
            <Button
              label="Muu"
              category={selectedOption && selectedOption === 'manual' ? selectedBtnCategory : unSelectedBtnCategory}
              onClick={() => {
                setShowCustomAddress(true)
                selectOption('manual')
                setGeolocatedField(false)
              }}
              size="ml"
              maxWidth="100%"
            />
          </RouteColumn>
        </RouteRow>
      </>

      <AutoliittoSeparatorLine marginTop="1.5rem" marginBottom="1.5rem" />
      <RouteRow>
        <RouteColumn>
          {showCustomAddress && selectedOption !== 'station' && (
            <RouteColumn>
              <LocationInputMap
                addressLabel={`Osoite`}
                addressName={`endRoute.address`}
                cityLabel={`Kaupunki`}
                cityName={`endRoute.city`}
                coordsName={`endRoute.coords`}
                zipcodeName={`endRoute.zipcode`}
                getValue={getValue}
                values={toMapData(
                  getValue(`endRoute.address`),
                  getValue(`endRoute.city`),
                  getValue(`endRoute.coords`),
                  getValue(`endRoute.zipcode`)
                )}
                setValue={setValue}
                setRouteLocation={setRouteLocation}
                stationId={stationId}
                clear={() => {
                  clear()
                  setStation(null)
                }}
              />
            </RouteColumn>
          )}
          {selectedOption === 'station' && (
            <StationButtonGroup
              stations={state.settings ? state.settings.stations : null}
              label="Kuormakirjan lopetuspaikka"
              setRouteLocation={setRouteLocation}
              stationId={selectedStationId}
              setStation={stationId => {
                setStation(stationId)
              }}
              cleared={cleared}
              setLoading={setLoading}
              required={isSos}
            />
          )}
        </RouteColumn>
        <RouteColumnAlignRight>
          <ArrivalDateTimeInput
            label="Aika"
            name="endRoute"
            customOnChange={() => {
              const value = (format(new Date(), 'HH:mm') as unknown) as Time
              setValue(`endRoute.arrivalDate`, new Date())
              setValue(`endRoute.arrivalTime`, value)
            }}
            required={isAutoliitto || isSos}
            showCurrentTimeBtn
          />
          <ClearButton
            label="Laske"
            category="info"
            loading={calculateEta}
            onClick={() => {
              setCalculateEta(true)
            }}
            fontWeight="bold"
            maxWidth="50%"
            size="s"
            disabled={disableEtaBtn}
          />
        </RouteColumnAlignRight>
      </RouteRow>
      <RouteNotes removeLine={!oldRoutes} />
    </RouteWrapper>
  )
}
