import { useMutation } from '@apollo/react-hooks'
import React from 'react'

import { JobItemDiscountType } from '../../common/types'
import { Loading } from '../../components/responses'
import { setErrorNotifications, setFatalErrorNotification } from '../../components/responses/setErrorNotifications'
import { NumberInput, SelectInput, TextInput } from '../layout/form/input'
import { Delete } from './Delete'
import { DELETE_JOB_ITEM } from './queries'
import { DiscountItemRow, ItemColumn } from './styled'
import { DeleteTowingRecordJobItemResponse, JobItemInput } from './types'

interface Props {
  jobItem: JobItemInput
  index?: number
  removeItem: () => void
  itemsIncludeVat: boolean
  towingRecordId?: number
  disabled?: boolean
}

const discountTypeOptions = [
  { value: JobItemDiscountType.amount, label: '€ sis. ALV' },
  { value: JobItemDiscountType.percentage, label: '%' },
]

export const DiscountItem: React.FC<Props> = ({ jobItem, removeItem, towingRecordId, disabled = false }) => {
  const [deleteJobItem, { loading }] = useMutation<DeleteTowingRecordJobItemResponse>(DELETE_JOB_ITEM, {
    onCompleted({ deleteTowingRecordJobItem }) {
      if (deleteTowingRecordJobItem.__typename === 'DeleteTowingRecordJobItemSuccess') {
        removeItem()
      } else {
        setErrorNotifications({ data: deleteTowingRecordJobItem })
      }
    },
    onError(err) {
      setFatalErrorNotification(err.message)
    },
  })

  const deleteNewJobItem = () => {
    removeItem()
  }
  const deleteExistingJobItem = () => {
    deleteJobItem({ variables: { input: { towingRecordId, jobItemId: jobItem.id } } })
  }

  return (
    <DiscountItemRow>
      <Loading loading={loading} />
      <ItemColumn>
        <TextInput label="Alennus" name={`discountItem.name`} disabled />
      </ItemColumn>
      <ItemColumn>
        <NumberInput label="Määrä" name={`discountItem.unitPrice`} disabled={disabled}/>
      </ItemColumn>
      <ItemColumn>
        <SelectInput
          label="Tyyppi"
          name={`discountItem.discountType`}
          options={discountTypeOptions}
          searchable={false}
          disabled={disabled}
        />
      </ItemColumn>
      <ItemColumn />
      <ItemColumn>
        <Delete onClick={jobItem.id && towingRecordId ? deleteExistingJobItem : deleteNewJobItem} disabled={disabled} />
      </ItemColumn>
    </DiscountItemRow>
  )
}
