import { DeleteBillingMutationVariables } from './mutations'
import { DeleteBillingFormValues } from './types'

export function toDeleteBillingMutationVariables(values: DeleteBillingFormValues): DeleteBillingMutationVariables {
  return {
    input: {
      towingRecordId: values.towingRecordId,
    },
  }
}
