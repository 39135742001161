import gql from 'graphql-tag'

export const GET_COORDS = gql`
  query Geocode($address: String!) {
    geocode(address: $address) {
      __typename
      ... on GetGeocodeSuccess {
        result {
          lat
          long
        }
      }
      ... on GoogleAPIGeolocationError {
        error {
          code
          message
          details
          context
        }
      }
    }
  }
`
