import Select from 'react-select'
import styled from 'styled-components'

export const SelectField = styled(Select)`
  & .Select__control {
    background-color: ${props => (props.isDisabled ? props.theme.colors.grey200 : 'transparent')};
    border-color: ${props => props.theme.colors.grey300};
  }

  & .Select__menu {
    background-color: ${props => props.theme.colors.white};
  }

  & .Select__input > input {
    width: 100% !important;
  }
 
  & .Select__option {
    color: ${props => props.theme.colors.black};

    &--is-selected {
      background-color: ${props => props.theme.colors.blue600}; 
    }

    &--is-focused {
      background-color: ${props => props.theme.colors.blue50};
    }
   
    &:active {
      background-color: ${props => props.theme.colors.blue100};
    }
  }

  & .Select__indicators > div {
    display: ${props => (props.hideArrow ? 'none' : 'flex')};
  }

  & .Select__single-value {

    color: ${props => props.theme.colors.black};
    &--is-disabled {
      color: ${props => props.theme.colors.greyDisabled};
    }
  }
`
