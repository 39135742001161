import React from 'react'
import { BigButtonSection } from '../../../../components/layout'
import { Styling } from '../../../../components/Styling'
import { SaveButton } from './SaveButton'
import { TowingOrderSaveButtonsProps } from './types'

export const DutyOfficerButtons: React.FC<TowingOrderSaveButtonsProps> = ({ userId, operatorId, handleSubmit }) => {
  const unAssigned = operatorId == null
  const assignToSelf = operatorId === userId
  const assignToOther = operatorId && operatorId !== userId
  return (
    <BigButtonSection justifyContent="end">
      <>
        {unAssigned && (
          <>
            <SaveButton onClick={() => handleSubmit(false, null, 'open')}>
              <Styling fontSize="m">Tallenna</Styling>
              <Styling fontSize="m">avoimeksi kaikille</Styling>
            </SaveButton>
            <SaveButton onClick={() => handleSubmit(false, userId, 'assigned')}>
              <Styling fontSize="m">Tallenna ja</Styling>
              <Styling fontSize="m">kuittaa itsellesi</Styling>
            </SaveButton>
          </>
        )}
        {assignToSelf && (
          <SaveButton onClick={() => handleSubmit(false, userId, 'assigned')}>
            <Styling fontSize="m">Tallenna ja</Styling>
            <Styling fontSize="m">kuittaa itsellesi</Styling>
          </SaveButton>
        )}
        {assignToOther && (
          <SaveButton onClick={() => handleSubmit(false, operatorId, 'assigned')}>
            <Styling fontSize="m">Tallenna ja</Styling>
            <Styling fontSize="m">osoita</Styling>
          </SaveButton>
        )}
      </>
    </BigButtonSection>
  )
}
