import { cloneDeep } from 'lodash'
import { InitialValues } from '../types'
import { toNewTowingRecordInput } from './toNewTowingRecordInput'
import { AddTowingRecordMutationVariables } from './types'

export const toMutationVariables = (inputValues: InitialValues): AddTowingRecordMutationVariables => {
  const values = cloneDeep(inputValues)
  // do not try to save signature fields if the user does not want to
  // this to avoid validation errors for empty signature

  if (!values.saveSignature) {
    values.signature = null
  }

  return {
    input: {
      towingRecord: toNewTowingRecordInput(values),
    },
  }
}
