import { getIn } from 'formik'
import { useCallback, useState } from 'react'
import { sosCauseFilters, sosVariables } from '../../components/SosSubForm/types'
import { Option } from '../../styled/styled'

export const useSosCauseOptions = (deliverable: unknown, setValue: (field: string, value: any) => void) => {
  const [causeOptions, setCauseOptions] = useState<Option[]>(() => {
    if (!deliverable) {
      return []
    }

    const causes = getIn(sosCauseFilters, deliverable as string)

    if (!causes) {
      return []
    }

    const options = sosVariables.cause.filter(cause => causes.includes(cause.value))

    return options
  })

  const onDeliverableChange = useCallback(
    (deliverable: string | number | undefined) => {
      if (!deliverable) {
        setCauseOptions([])
      }

      const causes = getIn(sosCauseFilters, deliverable as string)

      if (!causes) {
        setCauseOptions([])
      }

      const options = sosVariables.cause.filter(cause => causes.includes(cause.value))

      setCauseOptions(options)
      if (setValue) setValue('sosServiceOrder.cause', null)
    },
    [setValue]
  )

  return { causeOptions, onDeliverableChange }
}
