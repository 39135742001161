import React from 'react'
import styled from 'styled-components'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { ListRowWrapper, Heading3 } from '../../components/layout'
import { messages } from './messages'

const NoTowingJobsIntl: React.FunctionComponent<InjectedIntlProps> = ({ intl }) => {
  const { formatMessage } = intl

  return (
    <ListRowWrapper>
      <EmptyContainer>
        <Heading3>{formatMessage(messages.noTowingJobs)}</Heading3>
      </EmptyContainer>
    </ListRowWrapper>
  )
}

const EmptyContainer = styled.div`
  text-align: center;
  padding: 2rem;
`

export const NoTowingJobs = injectIntl(NoTowingJobsIntl)
