import { useMutation } from '@apollo/react-hooks'
import React from 'react'

import { Container, ModalContainer, Page } from '../../../components/layout'
import { setErrorNotifications, setFatalErrorNotification } from '../../../components/responses/setErrorNotifications'
import { CompanyCustomerFormValues, EditCompanyResponse } from '../types'
import { EditCompanyCustomerForm } from './EditCompanyCustomerForm'
import { EDIT_COMPANY_CUSTOMER } from './mutation'
import { toMutationVariables } from './toMutationVariables'
import { EditCompanyCustomerResponse } from './types'

interface EditCompanyCustomerProps {
  id: number
  companyCustomer: CompanyCustomerFormValues
  onCloseForm: (updatedCompany: EditCompanyResponse) => void
  onCancelForm: () => void
}

export const EditCompanyCustomerPage: React.FunctionComponent<EditCompanyCustomerProps> = ({
  onCloseForm,
  onCancelForm,
  companyCustomer,
  id,
}) => {
  const [editCompanyCustomer, { loading }] = useMutation<EditCompanyCustomerResponse>(EDIT_COMPANY_CUSTOMER, {
    onCompleted({ editCompanyCustomer }) {
      if (editCompanyCustomer.__typename !== 'EditCompanyCustomerSuccess') {
        setErrorNotifications({ data: editCompanyCustomer })
      }
    },
    onError(err) {
      setFatalErrorNotification(err.message)
    },
  })

  return (
    <ModalContainer>
      <Container>
        <Page>
          <EditCompanyCustomerForm
            onCancelForm={onCancelForm}
            onCloseForm={onCloseForm}
            initialValues={companyCustomer}
            editCompanyCustomer={values => editCompanyCustomer({ variables: toMutationVariables(id, values) })}
            loading={loading}
          />
        </Page>
      </Container>
    </ModalContainer>
  )
}
