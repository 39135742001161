import { Maybe } from '../../common/types'
import { CurrentWorkShift, EventTypeOption } from './types'

export const resolveShiftStatus = (currentShift: CurrentWorkShift) => {
  if (!currentShift) return null
  if (!currentShift.events || !currentShift.shift) return null

  const lastEvent = [...currentShift.events].pop()
  if (!lastEvent) return null

  switch (lastEvent.type) {
    case EventTypeOption.work_start:
      return EventTypeOption.work_start as string
    case EventTypeOption.work_end:
      return EventTypeOption.work_end as string
    case EventTypeOption.break_start:
      return EventTypeOption.break_start as string
    case EventTypeOption.break_end:
      return EventTypeOption.break_end as string
    case EventTypeOption.standby_start:
      return EventTypeOption.standby_start as string
    case EventTypeOption.standby_end:
      return EventTypeOption.standby_end as string
    case EventTypeOption.wait_start:
      return EventTypeOption.wait_start as string
    case EventTypeOption.wait_end:
      return EventTypeOption.wait_end as string
    case EventTypeOption.standby_work_start:
      return EventTypeOption.standby_work_start as string
    case EventTypeOption.standby_work_end:
      return EventTypeOption.standby_work_end as string
    default:
      return null
  }
}

export const hasWorkEnded = (shiftStatus: Maybe<string>) => {
  if (!shiftStatus) return true
  if (shiftStatus === EventTypeOption.work_end) return true

  return false
}

export const inStandby = (shiftStatus: Maybe<string>) => {
  if (shiftStatus === EventTypeOption.standby_start) return true
  if (shiftStatus === EventTypeOption.standby_work_end) return true

  return false
}

export const onBreak = (shiftStatus: Maybe<string>) => {
  if (shiftStatus === EventTypeOption.break_start) return true

  return false
}

export const isWaiting = (shiftStatus: Maybe<string>) => {
  if (shiftStatus === EventTypeOption.wait_start) return true

  return false
}

export const isWorking = (shiftStatus: Maybe<string>) => {
  if (shiftStatus === EventTypeOption.work_start) return true
  if (shiftStatus === EventTypeOption.standby_work_start) return true
  if (shiftStatus === EventTypeOption.break_end) return true
  if (shiftStatus === EventTypeOption.wait_end) return true

  return false
}

export const standbyWork = (shiftStatus: Maybe<string>) => {
  if (shiftStatus === EventTypeOption.standby_work_start) return true

  return false
}
