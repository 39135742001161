import React, { RefObject, useContext } from 'react';
import { StateStore } from '../../../../../containers/StoreProvider';
import { Maybe, TowingRecordPaymentType } from '../../../../../common/types';
import {
  FormSubGroup,
  FormSubGroupHeader,
  FormSubGroupHeading,
  Section,
  SectionColumn,
} from '../../../../../components/layout';
import { DateInput, SelectInput, TextInput } from '../../../../../components/layout/form/input';
import { injectIntl, InjectedIntlProps } from 'react-intl';
import { messages as billingMessages } from '../BillingConfirmation/messages';
import { PaymentTerminals } from '../../../../../components/PaymentTerminals';
import { EditTowingRecordFormValues } from '../../../EditTowingRecord/types';

interface Props
  extends Readonly<{
    itemsTotal: Maybe<number>;
    values?: EditTowingRecordFormValues;
    hasFormChanged?: () => boolean
    paymentType: Maybe<TowingRecordPaymentType>;
    onChange: (debtorType: string) => void;
    disabled: boolean;
    billingRef?: RefObject<any>
  }> {}

const paymentTypeOptions = [
  { value: null, label: 'Kuormakirja kesken' },
  { value: TowingRecordPaymentType.define_later, label: 'Valmis laskutettavaksi' },
  {
    value: TowingRecordPaymentType.card,
    label: 'Korttimaksu',
  },
  { value: TowingRecordPaymentType.cash, label: 'Käteismaksu' },
];

const debtorOptions = [
  {
    value: 'orderer',
    label: 'Työn tilaaja',
  },
  { value: 'holder', label: 'Ajoneuvon haltija' },
  { value: 'owner', label: 'Ajoneuvon omistaja' },
  { value: 'other', label: 'Muu' },
];

const isClosedPaymentType = (paymentType: TowingRecordPaymentType | null) =>
  paymentType &&
  [
    TowingRecordPaymentType.person,
    TowingRecordPaymentType.company,
    TowingRecordPaymentType.insurance_company,
    TowingRecordPaymentType.aggregate,
    TowingRecordPaymentType.define_later,
    TowingRecordPaymentType.settlement,

    TowingRecordPaymentType.autoliitto,
  ].includes(paymentType);

const TowingRecordBillingIntl: React.FunctionComponent<Props & InjectedIntlProps> = ({
  intl,
  itemsTotal,
  values,
  hasFormChanged,
  paymentType,
  onChange,
  disabled,
  billingRef,
}) => {
  const open =
    !isClosedPaymentType(paymentType) &&
    (paymentType === null || paymentType === TowingRecordPaymentType.define_later ? false : true);
  const { state } = useContext(StateStore);
  let paymentTerminalsEnabled: boolean = state.settings
    ? state.settings.paymentTerminalsEnabled
    : false;
  return (
    <>
      <FormSubGroupHeader>
        <FormSubGroupHeading>Maksutiedot</FormSubGroupHeading>
      </FormSubGroupHeader>
      <FormSubGroup ref={billingRef}>
        <Section>
          <SectionColumn>
            <SelectInput
              label="Valitse maksutapa"
              name="paymentType"
              options={paymentTypeOptions}
              disabled={disabled}
              searchable={false}
              placeholder={
                disabled && paymentType
                  ? intl.formatMessage(billingMessages[paymentType])
                  : 'Valitse...'
              }
            />
          </SectionColumn>
        </Section>
      </FormSubGroup>
      {open && (
        <FormSubGroup>
          <Section>
            <SectionColumn>
              <SelectInput
                label="Maksun suorittaja"
                name="debtorType"
                options={debtorOptions}
                disabled={disabled}
                onChange={(selection) => {
                  if (selection && typeof selection !== 'number') {
                    onChange(selection);
                  }
                }}
              />
            </SectionColumn>
            <SectionColumn>
              <TextInput
                label="Maksajan nimi"
                name="billing.paymentDetails.debtorName"
                disabled={disabled}
              />
            </SectionColumn>
            <SectionColumn>
              <DateInput
                label="Maksupäivä"
                name="billing.paymentDetails.paymentDate"
                disabled={disabled}
              />
            </SectionColumn>
            <SectionColumn>
              <TextInput
                label="Sähköpostiosoite kuittia varten"
                name="billing.paymentDetails.receiptEmail"
                disabled={disabled}
                type="email"
              />
            </SectionColumn>
          </Section>
        </FormSubGroup>
      )}
      {paymentType === 'card' && paymentTerminalsEnabled && (
        <PaymentTerminals itemsTotal={itemsTotal} values={values} hasFormChanged={hasFormChanged} />
      )}
    </>
  );
};

export const TowingRecordBilling = injectIntl(TowingRecordBillingIntl);
