import { Formik } from 'formik'
import React from 'react'
import { useMutation } from 'react-apollo'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { RouteColumn, RouteRow, RouteWrapper } from '../../../../components/layout'
import { TextAreaInput } from '../../../../components/layout/form/input'
import { setErrorNotification, setSuccessNotification } from '../../../../components/notification'
import { Loading } from '../../../../components/responses'
import {
  setErrorNotifications,
  setFatalErrorNotification,
} from '../../../../components/responses/setErrorNotifications'
import { useHistoryPush } from '../../../../components/router'
import { Styling } from '../../../../components/Styling'
import { RouteHeader } from '../../../TowingRecordPage/components/routes/subforms/RouteHeader'
import { RouteForm } from '../../../TowingRecordPage/components/routes/subforms/styled'
import { CANCEL_TOWING_ORDER, REJECT_TOWING_ORDER } from '../../ViewTowingOrder/queries'
import { TowingOrder } from '../../ViewTowingOrder/types'
import { rejectMessages } from './messages'
import { toMutationVariables } from './mutation/toMutationVariables'
import { CancelTowingOrderResponse, RejectTowingOrderResponse } from './mutation/types'
import { RejectOrderFormValues, RejectType } from './types'

interface Props {
  onClose: () => void
  towingOrder: TowingOrder
  rejectType: RejectType
}

const RejectTowingOrderIntl: React.FunctionComponent<Props & InjectedIntlProps> = ({
  onClose,
  towingOrder,
  rejectType,
  intl,
}) => {
  const { formatMessage } = intl
  const historyPush = useHistoryPush()

  const initialValues: RejectOrderFormValues = {
    towingOrderId: towingOrder.id,
    reason: '',
  }

  const [cancelTowingOrder, { loading: cancelLoading }] = useMutation<CancelTowingOrderResponse>(CANCEL_TOWING_ORDER, {
    onCompleted({ cancelTowingOrder }) {
      if (cancelTowingOrder.__typename === 'CancelTowingOrderSuccess') {
        setSuccessNotification(
          formatMessage(rejectMessages.cancel_success_title),
          formatMessage(rejectMessages.cancel_success_message)
        )
        historyPush('/')
        return
      }
      setErrorNotifications({ data: cancelTowingOrder })
    },
    onError(error) {
      setFatalErrorNotification(error.message)
    },
  })

  const [rejectTowingOrder, { loading: rejectLoading }] = useMutation<RejectTowingOrderResponse>(REJECT_TOWING_ORDER, {
    onCompleted({ rejectTowingOrderAssignment }) {
      if (rejectTowingOrderAssignment.__typename === 'RejectTowingOrderAssignmentSuccess') {
        setSuccessNotification(
          formatMessage(rejectMessages.cancel_success_title),
          formatMessage(rejectMessages.cancel_success_message)
        )
        historyPush('/')
        return
      }
      setErrorNotifications({ data: rejectTowingOrderAssignment })
    },
    onError(error) {
      setFatalErrorNotification(error.message)
    },
  })

  const getHeader = (type: RejectType)=>{
    switch(type){
      case 'cancel':
      case 'cancel-sos':
        return 'Peru työ';
      default:
        return 'Hylkää työ'
    }
  }
  const getLabel = (type: RejectType)=>{
    switch(type){
      case 'cancel':
      case 'cancel-sos':
        return 'Peruutuksen syy';
      default:
        return "Hylkäämisen syy"
    }
  }

  //   const submit = async (values: RejectOrderFormValues) => {}

  const loading = cancelLoading || rejectLoading
  return (
    <>
      <RouteForm>
        <RouteWrapper>
          <RouteHeader label={getHeader(rejectType)} regNo={towingOrder.vehicleDetails.registrationNumber} cancel={onClose} disableEditRegNo />
          <Styling padding="0" paddingMobile="0 0.5rem">
            <Formik
              initialValues={initialValues}
              onSubmit={async (values: RejectOrderFormValues) => {
                if (rejectType === 'cancel' || rejectType === 'cancel-sos') {
                  await cancelTowingOrder({ variables: toMutationVariables(values) })
                }

                if (rejectType === 'reject') {
                  await rejectTowingOrder({ variables: toMutationVariables(values) })
                }
              }}
              render={({ submitForm, values }) => {
                return (
                  <>
                    {rejectType === 'cancel-sos' && (
                      <RouteRow columns={'1fr'}>
                        <RouteColumn style={{fontWeight: 'bold', textAlign: 'center', width: '100%'}}>
                          <span>HUOM! Työ poistetaan järjestelmästä.</span>
                        </RouteColumn>
                      </RouteRow>
                    )}
                    <RouteRow columns={'1fr'}>
                      <RouteColumn>
                        <TextAreaInput label={getLabel(rejectType)} name="reason" required />
                      </RouteColumn>
                    </RouteRow>
                    <RouteRow columns={'1fr'}>
                      <RouteColumn>
                        <Button
                          label="Tallenna"
                          size="l"
                          category="save"
                          onClick={() => {
                            console.log('reason', values.reason)
                            if (values.reason === '') {
                              console.log('No reason given')
                              setErrorNotification(
                                formatMessage(rejectMessages.invalid_value_title),
                                formatMessage(rejectMessages.invalid_value_message)
                              )
                              return
                            }
                            submitForm()
                          }}
                          maxWidth="100%"
                          disabled={loading}
                        />
                      </RouteColumn>
                    </RouteRow>
                  </>
                )
              }}
            />
          </Styling>
          <Loading loading={loading} />
        </RouteWrapper>
      </RouteForm>
    </>
  )
}

export const RejectTowingOrder = injectIntl(RejectTowingOrderIntl)
