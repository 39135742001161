import React from 'react'
import styled from 'styled-components'
import { FieldContainer, Label } from '../layout/form/style'
import { Currency } from '../Currency'

interface Props {
  currency: number
  label?: string
  disabled?: boolean
}

export const DisplayMoney: React.FunctionComponent<Props> = ({ currency, label, disabled = false }) => {
  return (
    <FieldContainer>
      {label && <Label>{label}</Label>}
      <StyledMoney>{disabled ? 'N/A' : <Currency>{currency}</Currency>}</StyledMoney>
    </FieldContainer>
  )
}

const StyledMoney = styled.div`
  padding: 0.25rem;
  height: ${props => props.theme.inputFieldHeight};
  display: flex;
  align-items: center;
  font-weight: ${props => props.theme.fontWeight.bold};
  color: ${props => (props.color ? props.theme.colors[props.color] : props.theme.colors.black)};
`
