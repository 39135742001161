import React from 'react'
import { StartRoutePage } from './subforms/StartRoute'
import { ArrivalRoutePage } from './subforms/ArrivalRoute'
import { FinishRoutePage } from './subforms/FinishRoute'
import { EndRoutePage } from './subforms/EndRoute'
import { SetTowingRecordType, TowingRecord } from '../../ViewTowingRecord/types'

interface EditTowingRecordRoutesProps {
  onCancel: () => void
  subformId: string
  towingRecord: TowingRecord
  routeDone: boolean
  onRefetch?: () => void
  setTowingRecord?: SetTowingRecordType
}

export const Routes: React.FunctionComponent<EditTowingRecordRoutesProps> = ({
  onCancel,
  subformId,
  towingRecord,
  routeDone,
  onRefetch,
  setTowingRecord,
}) => {
  return (
    <>
      {subformId === 'start' && (
        <StartRoutePage onCancel={() => onCancel()} towingRecord={towingRecord} setTowingRecord={setTowingRecord} />
      )}
      {subformId === 'arrival' && (
        <ArrivalRoutePage
          onCancel={() => onCancel()}
          towingRecord={towingRecord}
          routeDone={routeDone}
          onRefetch={onRefetch}
          setTowingRecord={setTowingRecord}
        />
      )}
      {subformId === 'finish' && (
        <FinishRoutePage
          onCancel={() => onCancel()}
          towingRecord={towingRecord}
          routeDone={routeDone}
          onRefetch={onRefetch}
          setTowingRecord={setTowingRecord}
        />
      )}
      {subformId === 'end' && (
        <EndRoutePage onCancel={() => onCancel()} towingRecord={towingRecord} setTowingRecord={setTowingRecord} />
      )}
    </>
  )
}
