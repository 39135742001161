import { Field } from 'formik'
import React, { useCallback } from 'react'
import { TimeSelectorField } from '../../field'

interface Props {
  name: string
  timeSelectorLabel?: string
  timeFieldLabel?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  customOnChange?: () => void
  className?: string
}

export const TimeSelectorInput: React.FunctionComponent<Props> = ({
  customOnChange,
  name,
  timeSelectorLabel,
  timeFieldLabel,
  placeholder,
  required,
  ...rest
}) => {
  const validate = useCallback(
    (value: string | null): string | undefined => {
      if (required && (!value || !value.trim())) {
        return 'Kenttä on pakollinen'
      }
      const timeRegex = /^(([0-9])|([0-1][0-9])|([2][0-3]))(:(([0-9])|([0-5][0-9])))$/

      if (value && !value.match(timeRegex)) {
        return 'Esitä aika muodossa tt:mm'
      }
      return undefined
    },
    [required]
  )

  return (
    <Field
      {...rest}
      component={TimeSelectorField}
      id={name}
      name={name}
      timeSelectorLabel={timeSelectorLabel}
      timeFieldLabel={timeFieldLabel}
      placeholder={placeholder}
      required={required}
      validate={validate}
      customOnChange={customOnChange}
    />
  )
}
