import styled, { css } from 'styled-components'

interface Props {
  justify?: string
  marginMobile?: string
}

export const Column = styled.div<Props>`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  min-width: 200px;
  padding: 0 0.25rem 0 0.25rem;
  justify-content: flex-start;
  ${props =>
    props.justify &&
    css`
      justify-content: ${props.justify};
    `}
  width: 33%;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    width: 100%;
    ${props =>
      props.marginMobile &&
      css`
        margin: ${props.marginMobile};
      `}
  }
`
