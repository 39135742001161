import { Field } from 'formik'
import React, { useCallback } from 'react'
import { TimeField } from '../../field'
import { ArrivalRouteArgs } from '../../../../../containers/TowingRecordPage/components/routes/subforms/StartRoute/types'

interface Props {
  name: string
  label?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  customOnChange?: () => void
  className?: string
  isAutoliitto?: boolean
  calculateEta?: boolean
  arrivalRoute?: ArrivalRouteArgs
  setCalculateEta?: (loading: boolean) => void
  setTimeButton?: boolean
  onSetCurrentTime?: () => void
}

export const TimeInput: React.FunctionComponent<Props> = ({
  customOnChange,
  name,
  label,
  placeholder,
  required,
  isAutoliitto,
  calculateEta,
  arrivalRoute,
  setCalculateEta,
  onSetCurrentTime,
  setTimeButton = true,
  ...rest
}) => {
  const validate = useCallback(
    (value: string | null): string | undefined => {
      if (required && (!value || !value.trim())) {
        return 'Kenttä on pakollinen'
      }

      const timeRegex = /^(([0-9])|([0-1][0-9])|([2][0-3]))(:|.(([0-9])|([0-5][0-9])))$/

      if (value && !value.match(timeRegex)) {
        return 'Esitä aika muodossa tt:mm'
      }
      return undefined
    },
    [required]
  )

  return (
    <Field
      {...rest}
      component={TimeField}
      id={name}
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      validate={validate}
      customOnChange={customOnChange}
      isAutoliitto={isAutoliitto}
      calculateEta={calculateEta}
      arrivalRoute={arrivalRoute}
      setCalculateEta={setCalculateEta}
      setTimeButton={setTimeButton}
      onSetCurrentTime={onSetCurrentTime}
    />
  )
}
