import {
  setUnauthorizedNotification,
  setStatusChangeForbiddenNotification,
  setGoogleAPIGeolocationErrorNotification,
  setResourceNotFoundNotification,
  setGenericResponseErrorNotification,
  setInvalidVisibilitySettingsNotification,
  setInvalidParamNotification,
} from '../notification'

import {
  UnauthorizedError,
  ResourceNotFoundError,
  StatusChangeForbiddenError,
  GoogleAPIGeolocationError,
  BadRequestError,
  ForbiddenError,
  InvalidStateError,
  BillingAlreadyDefinedError,
  TowingRecordAlreadyCreatedError,
  InvalidCustomerIdentifierError,
  InvalidVisibilitySettingsError,
  InvalidParameterError,
  ConcurrencyConflict,
  NetworkError,
  InvalidCredentialsError,
  MultifactorAuthenticationError,
  ShiftError,
  GenericError,
} from '../../containers/errorTypes'
import { dataHasError } from './dataHasError'
import { setGenericErrorNotification } from '../notification/genericResponseErrorNotification'

interface ResultNotificationsDefinition {
  data: ErrorsTypes
}

export type ErrorsTypes =
  | UnauthorizedError
  | ResourceNotFoundError
  | StatusChangeForbiddenError
  | GoogleAPIGeolocationError
  | BadRequestError
  | ForbiddenError
  | InvalidCredentialsError
  | InvalidStateError
  | BillingAlreadyDefinedError
  | TowingRecordAlreadyCreatedError
  | InvalidCustomerIdentifierError
  | InvalidVisibilitySettingsError
  | InvalidParameterError
  | ConcurrencyConflict
  | NetworkError
  | ShiftError
  | MultifactorAuthenticationError
  | GenericError

export const setErrorNotifications = (params: ResultNotificationsDefinition) => {
  const { data } = params

  if (data == null) {
    setGenericResponseErrorNotification('BadRequestError')
    return
  }

  if (dataHasError(data, 'UnauthorizedError')) {
    return setUnauthorizedNotification()
  } else if (dataHasError(data, 'ResourceNotFoundError')) {
    return setResourceNotFoundNotification()
  }

  if (dataHasError(data, 'StatusChangeForbiddenError')) {
    return setStatusChangeForbiddenNotification(data.error.message)
  } else if (dataHasError(data, 'BadRequestError')) {
    return setGenericResponseErrorNotification('BadRequestError')
  } else if (dataHasError(data, 'ForbiddenError')) {
    return setGenericResponseErrorNotification('ForbiddenError')
  } else if (dataHasError(data, 'InvalidStateError')) {
    return setGenericResponseErrorNotification('InvalidStateError')
  } else if (dataHasError(data, 'BillingAlreadyDefinedError')) {
    return setGenericResponseErrorNotification('BillingAlreadyDefinedError')
  } else if (dataHasError(data, 'TowingRecordAlreadyCreatedError')) {
    return setGenericResponseErrorNotification('TowingRecordAlreadyCreatedError')
  } else if (dataHasError(data, 'InvalidCustomerIdentifierError')) {
    return setGenericResponseErrorNotification('InvalidCustomerIdentifierError')
  } else if (dataHasError(data, 'GoogleAPIGeolocationError')) {
    return setGoogleAPIGeolocationErrorNotification()
  } else if (dataHasError(data, 'InvalidVisibilitySettingsError')) {
    return setInvalidVisibilitySettingsNotification()
  } else if (dataHasError(data, 'InvalidParameterError')) {
    return setInvalidParamNotification(data.error.message)
  } else if (dataHasError(data, 'ConcurrencyConflict')) {
    return setGenericResponseErrorNotification('ConcurrencyConflict')
  } else if (dataHasError(data, 'MultifactorAuthenticationError')) {
    return setGenericErrorNotification('Virhe', data.error.message)
  }

  if (process.env.NODE_ENV === 'development') {
    console.error('Unable to map data to error, escalating to fatal error', data)
  }

  setFatalErrorNotification(null)
}

export const setFatalErrorNotification = (errorMessage: string | null) => {
  if (process.env.NODE_ENV === 'development') {
    console.error('Fatal Error:', errorMessage)
  }
  setGenericResponseErrorNotification('FatalError')
}
