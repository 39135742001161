import { defineMessages } from 'react-intl';

export const paymentTerminalMessages = defineMessages({
    transaction_updated_success_title: {
      id: 'paymentTerminals.updated.success.title',
      defaultMessage: 'Success',
    },
    transaction_updated_success_message: {
      id: 'paymentTerminals.updated.success.message',
      defaultMessage: 'Payment terminal transaction updated successfully',
    },
    transaction_purchase_success_message: {
      id: 'paymentTerminals.purchase.success.message',
      defaultMessage: 'Payment terminal purchase transaction completed',
    },
    transaction_refund_success_message: {
      id: 'paymentTerminals.refund.success.message',
      defaultMessage: 'Payment terminal refund transaction completed',
    },
    transaction_pending_error_title: {
      id: 'paymentTerminals.pending.error.title',
      defaultMessage: 'Towing record has pending payment terminal transactions',
    },
    transaction_pending_error_message: {
      id: 'paymentTerminals.pending.error.message',
      defaultMessage: 'Check payment status from payment details section',
    },
  });