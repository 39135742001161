import React, { useEffect, useState } from 'react'
import { CollapseContent } from '../../../../../../../components/Collapse/CollapseContent'
import { RouteColumn, RouteRow } from '../../../../../../../components/layout'
import { NumberInput, SelectInput, TextInput } from '../../../../../../../components/layout/form/input'
import { FinishedDateTimeInput } from '../../../../../../../components/layout/form/input/DateTimeInput'
import { format } from 'date-fns'
import { Time } from '@google/maps'
import { TowingRecordJobStatus } from '../../../../../../../common/types'
import {
  arcComponentOptions,
  arcDescriptionOptions,
  paymentTypeOptions,
} from '../../../../../../../components/ALSubForm/options'
import { ServiceType } from '../../../../../../../functions/graphqlApi/types/jobDetails'
import { Modal } from '../../../../../../../components/Modal'
import { ALProps, FinishedAtBreakdownLocationProps } from './FinishedForm'

export const FinishedAtBreakdownLocationFormAL: React.FunctionComponent<FinishedAtBreakdownLocationProps & ALProps> = ({
  setValue,
  action,
  getValue,
  translatedServiceTypeOptions,
  batteryServiceOptions,
  autoliittoFields,
}) => {
  const [showModal, setShowModal] = useState(false)
  const [edit, setEdit] = useState<boolean>(action === 'edit-finished')
  const causeIsRequired = getValue('serviceType') !== ServiceType.TOWING

  const [warned, setWarned] = useState(() => {
    if (autoliittoFields.hasFinishRouteInfo) return false
    return true
  })

  useEffect(() => {
    if (action === 'edit-finished') {
      if (!edit) setEdit(true)
    } else {
      if (edit) setEdit(false)
    }
  }, [edit, action, setEdit])

  return (
    <CollapseContent>
      {showModal && (
        <Modal
          label={'Huomio'}
          closeLabel="Peruuta"
          disableCloseOnConfirm
          message={`Valitsemalla toimenpiteeksi muun kuin hinauspalvelu, määränpään
              reittipisteen tiedot tyhjennetään tallennuksen yhteydessä.`}
          onConfirm={() => {
            setShowModal(false)
          }}
          close={() => {
            setShowModal(false)
            setValue('serviceType', 'towing')
          }}
        />
      )}
      <RouteRow columns={`1fr 1fr 1fr`}>
        <RouteColumn>
          <SelectInput
            label="Toimenpide"
            name="serviceType"
            options={translatedServiceTypeOptions}
            onChange={value => {
              if (!warned && value !== 'towing') {
                setWarned(true)
                setShowModal(true)
              }
              setValue('serviceType', value)
            }}
            disabled={!edit}
            required
          />
        </RouteColumn>
        <RouteColumn>
          <SelectInput
            label="Vian syy (ARC-vikakoodi)"
            name="automobileAndTouringClubFinland.arcCodes.description.code"
            options={arcDescriptionOptions}
            disabled={!edit && autoliittoFields.disableArcDescription}
            required={causeIsRequired}
          />
        </RouteColumn>
        <RouteColumn>
          <FinishedDateTimeInput
            name="arrivalRoute"
            label="Valmistumisaika"
            customOnChange={() => {
              const value = (format(new Date(), 'HH:mm') as unknown) as Time
              setValue('arrivalRoute.finishedTime', value)
              setValue('arrivalRoute.finishedDate', new Date())
            }}
            required
            disabled={!edit}
            showCurrentTimeBtn
          />
        </RouteColumn>
      </RouteRow>
      <RouteRow columns={'1fr 1fr 1fr'}>
        <RouteColumn>
          <SelectInput
            label="Komponentti (ARC-vikakoodi)"
            name="automobileAndTouringClubFinland.arcCodes.component.code"
            options={arcComponentOptions}
            disabled={!edit && autoliittoFields.disableArcComponent}
            required={causeIsRequired}
          />
        </RouteColumn>
        <RouteColumn>
          <NumberInput
            label="Mittarilukema, km"
            name="automobileAndTouringClubFinland.vehicleMileage"
            decimals={0}
            disabled={!edit && autoliittoFields.disableVehicleMileage}
            required={causeIsRequired}
          />
        </RouteColumn>
      </RouteRow>
      {getValue('serviceType') === 'battery_service' && (
        <>
          <RouteRow columns="1fr 1fr 1fr">
            <RouteColumn>
              <SelectInput
                label="Toimitettu akku"
                name="automobileAndTouringClubFinland.batteryService.battery"
                options={batteryServiceOptions}
                required
                searchable
                onChange={e => {
                  if (e !== 'other') {
                    setValue('automobileAndTouringClubFinland.batteryService.deliveredBattery', e)
                  } else {
                    setValue('automobileAndTouringClubFinland.batteryService.deliveredBattery', '')
                  }
                }}
              />
            </RouteColumn>
            <RouteColumn>
              <SelectInput
                label="Asiakkaan maksutapa"
                name="automobileAndTouringClubFinland.batteryService.paymentType"
                options={paymentTypeOptions}
                required
                disabled={!edit}
              />
            </RouteColumn>
            {getValue('automobileAndTouringClubFinland.batteryService.battery') === 'other' && (
              <RouteColumn>
                <TextInput
                  label="Toimitettu akku, muu"
                  name="automobileAndTouringClubFinland.batteryService.deliveredBattery"
                  required
                  disabled={!edit}
                />
              </RouteColumn>
            )}
          </RouteRow>
        </>
      )}
    </CollapseContent>
  )
}

export const finishedAtDestinationEditableJobStatuses = [TowingRecordJobStatus.finished_at_destination]
