import { Options } from '../../common/types'

// option values = translation message id

export const ordererTypeOptions: Options = {
  options: {
    company: 'orderer.type_company',
    person: 'orderer.type_person',
  },
}

export const deliveryMethodCompanyOptions: Options = {
  options: {
    mail: 'orderer.delivery_method.mail',
    email: 'orderer.delivery_method.email',
    e_invoice: 'orderer.delivery_method.e_invoice',
    no_delivery: 'orderer.delivery_method.no_delivery',
    sms: 'orderer.delivery_method.sms',
  },
}

export const deliveryMethodPersonOptions: Options = {
  options: {
    mail: 'orderer.delivery_method.mail',
    email: 'orderer.delivery_method.email',
    sms: 'orderer.delivery_method.sms',
  },
}
