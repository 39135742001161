import React from 'react'
import { MutationResult, QueryResult } from 'react-apollo'
import { Error, Loading } from '../../components/responses'
import {
  setUnauthorizedNotification,
  setStatusChangeForbiddenNotification,
  setGoogleAPIGeolocationErrorNotification,
  setResourceNotFoundNotification,
} from '../notification'
import { dataHasError } from './dataHasError'

interface OperationVariables {
  [key: string]: any
}

interface Props<TData = any, TVariables = OperationVariables> {
  children: (response?: TData) => React.ReactNode
  queryResult?: QueryResult<TData, TVariables>
  mutationResult?: MutationResult<TData>
  loading?: boolean
  notFound?: boolean
  statusChangeForbidden?: boolean
  unauthorized?: boolean
  googleAPIGeolocationError?: boolean
}

export type GenericResultHandlerDefinition<TData = any> = React.FC<Props<TData>>

export const GenericResultHandler: GenericResultHandlerDefinition = ({
  children,
  queryResult,
  mutationResult,
  googleAPIGeolocationError = false,
  loading = false,
  notFound = false,
  statusChangeForbidden = false,
  unauthorized = false,
}) => {
  const result = queryResult ? queryResult : mutationResult ? mutationResult : null

  if (result == null) {
    return <Error />
  }

  // useQuery.refetch() signals refetch in progress with networkStatus === 4
  if (queryResult) {
    if (queryResult.networkStatus === 4) return <Loading />
  }

  if (result.error) {
    return <Error error={result.error} />
  }

  if (loading && result.loading) {
    return <Loading />
  }

  if (result.data && unauthorized && dataHasError(result.data, 'UnauthorizedError')) {
    setUnauthorizedNotification()
    return <></>
  }

  if (result.data && notFound && dataHasError(result.data, 'ResourceNotFoundError')) {
    setResourceNotFoundNotification()
    return <></>
  }

  if (result.data && statusChangeForbidden && dataHasError(result.data, 'StatusChangeForbidden')) {
    setStatusChangeForbiddenNotification(result.data.error.message)
    return <></>
  }

  if (result.data && googleAPIGeolocationError && dataHasError(result.data, 'GoogleAPIGeolocationError')) {
    setGoogleAPIGeolocationErrorNotification()
    return <></>
  }

  return <>{children(result.data)}</>
}
