// import React, { ComponentProps } from 'react'
import styled, { css } from 'styled-components'

interface StylingProps {
  color?: string
  bold?: boolean
  uppercase?: boolean
  italic?: boolean
  spacing?: 'some' | 'lots' | 'extra'
  fontSize?: 's' | 'm' | 'l'
  padding?: string
  paddingMobile?: string
  textAlign?: string
}

export const Styling = styled.div<StylingProps>`
  margin: 0;
  padding: 0;
  font-size: 1rem;
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${props =>
    props.spacing &&
    props.spacing === 'some' &&
    css`
      padding: 0.5rem;
    `}
    ${props =>
      props.spacing &&
      props.spacing === 'lots' &&
      css`
        padding: 1rem;
      `}
      ${props =>
        props.spacing &&
        props.spacing === 'extra' &&
        css`
          padding: 2rem;
        `}
  ${props =>
    props.color &&
    css`
      color: ${props.theme.colors[props.color]};
    `}
  ${props =>
    props.bold &&
    css`
      font-weight: 700;
    `}
  ${props =>
    props.uppercase &&
    css`
      text-transform: uppercase;
    `}
  ${props =>
    props.italic &&
    css`
      font-style: italic;
    `}
  ${props =>
    props.fontSize &&
    props.fontSize === 's' &&
    css`
      font-size: 0.8rem;
    `}
  ${props =>
    props.fontSize &&
    props.fontSize === 'l' &&
    css`
      font-size: 1.4rem;
    `}
    ${props =>
      props.paddingMobile &&
      css`
        @media (max-width: ${props => props.theme.screenSize.mobile}) {
          padding: ${props.paddingMobile};
        }
      `}
      ${props =>
        props.textAlign &&
        css`
          text-align: ${props.textAlign};
        `}
`
