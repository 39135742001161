import React from 'react'
import { isNull } from 'util'
import { v4 as uuidv4 } from 'uuid'
import { Maybe } from '../common/types'
import { Text } from '../components/layout/typography'

export function isStringNullOrEmpty(value: Maybe<string>): boolean {
  if (value === null || value === '') return true
  return false
}

export function toMultilineText(text: Maybe<string>) {
  if (!text) return null
  const arr = text.split(/\r?\n/)
  return arr.map(s => {
    return (
      <Text size="medium" key={uuidv4()}>
        {s}
      </Text>
    )
  })
}

export function tryParseInt(val: any): Maybe<number> {
  if (isNull(val)) {
    return null
  }

  if (isNumber(val)) {
    return val
  }

  if (isString(val)) {
    return parseInt(val, 10)
  }

  return null
}

export function isNumber(val: any): val is number {
  return typeof val === 'number'
}

export function isString(val: any): val is string {
  return typeof val === 'string'
}

export const ucfirst = (val: string) => {
  return val.charAt(0).toUpperCase() + val.slice(1)
}

export function maskSsn(value: string){
  const datePart = value.slice(0, 7)
  return `${datePart}****`
}

export function validateSsn(value: string){
  const regex = /^[0-9]{6}[+Aa-][0-9]{3}[A-z0-9]$/
  return regex.test(value)
}
