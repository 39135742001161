import gql from 'graphql-tag'
import { UnauthorizedError } from '../../../../../../errorTypes'
import { towingRecordFragment } from '../../../../../ViewTowingRecord/TowingRecordQuery'
import { TowingRecord } from '../../../../../ViewTowingRecord/types'
import { FinishRouteInputMutation } from './types'

export interface FinishRouteMutationVariables
  extends Readonly<{
    input: FinishRouteInputMutation
  }> {}

export interface EditFinishRouteResponse {
  editFinishRoute: FinishRouteOutput
}

type FinishRouteOutput = FinishRouteSuccess | UnauthorizedError

interface FinishRouteSuccess
  extends Readonly<{
    __typename: 'EditTowingRecordSuccess'
    towingRecord: TowingRecord
  }> {}

export const EDIT_FINISH_ROUTE_MUTATION = gql`
  mutation EditFinishRoute($input: EditFinishRouteInput!) {
    editFinishRoute(input: $input) {
      __typename
      ... on UnauthorizedError {
        error {
          code
          message
        }
      }
      ... on GenericError {
        genericError: error {
          code
          message
        }
      }
      ... on EditTowingRecordSuccess {
        towingRecord {
          ...TowingRecordFields
        }
      }
    }
  }
  ${towingRecordFragment.success}
`
