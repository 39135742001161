import React, { FunctionComponent } from 'react'
import { Route } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { Container } from '../../components/layout/'
import { SocketNotificationModal } from '../../components/SocketNotificationModal'
import { Routes } from '../../components/Routes/Routes'
import { GetParameters } from '../Parameters'
import { GetCurrentUser } from '../user'
import BackendNotificationModal from '../../components/BackendNotificationModal'
import { Theme } from '../ThemeProvider'

interface Props {
  authenticated: boolean
}

declare global {
  interface Window {
    ReactNativeWebView: any
    postNativeMessage: any
  }
}

export const App: FunctionComponent<Props> = ({ authenticated }) => {
  return (
    <Theme>
      <Container>
        {authenticated && <GetParameters />}
        {authenticated && <GetCurrentUser />}
        {authenticated && <SocketNotificationModal />}
        {authenticated && <BackendNotificationModal />}
        <ToastContainer autoClose={false} position={toast.POSITION.TOP_CENTER} />
        <Route render={({ location }) => <Routes authenticated={authenticated} location={location} />} />
      </Container>
    </Theme>
  )
}
