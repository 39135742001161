import styled from 'styled-components'

import { FormSubGroupHeader, FormSubGroupWrapper } from '../../layout'

const LahitapiolaFormSubGroupWrapper = styled(FormSubGroupWrapper)`
  border-color: rgba(132, 207, 234, 0.2);
`

const LahitapiolaFormSubGroupHeader = styled(FormSubGroupHeader)`
  background-color: rgba(132, 207, 234, 0.2);
`

export { LahitapiolaFormSubGroupWrapper, LahitapiolaFormSubGroupHeader }
