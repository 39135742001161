import { Formik } from 'formik'
import React, { useState } from 'react'
import { useMutation } from 'react-apollo'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { BigButtonSection, RouteColumn, RouteRow, RouteWrapper } from '../../../../components/layout'
import { setErrorNotification, setSuccessNotification } from '../../../../components/notification'
import { Loading } from '../../../../components/responses'
import {
  setErrorNotifications,
  setFatalErrorNotification,
} from '../../../../components/responses/setErrorNotifications'
import { useHistoryPush } from '../../../../components/router'
import { Styling } from '../../../../components/Styling'
import { SetTowingRecordType, TowingRecord } from '../../ViewTowingRecord/types'
import { RouteHeader } from '../routes/subforms/RouteHeader'
import { RouteForm } from '../routes/subforms/styled'
import { messages } from '../../messages'
import { messages as cancelMessage } from './messages'
import { CANCEL_TOWING_RECORD } from './mutation/mutation'
import { toCancelMutationVariables } from './mutation/toMutationVariables'
import { toMutationVariables } from '../../ViewTowingRecord/mutation/toMutationVariables'
import { CancelTowingRecordResponse } from './mutation/types'
import { CancelOrEndTowingRecordFormValues } from './types'
import { maybeStopTrackingRecord } from '../../../../components/TrackedJobs/util'
import { EndJobResponse } from '../../ViewTowingRecord/mutation/types'
import { END_TOWING_RECORD } from '../../EditTowingRecord/mutation/mutation'
import { format } from 'date-fns'
import { DateTimeInput } from '../../../../components/layout/form/input/DateTimeInput'
import { Maybe, Time, TowingRecordType } from '../../../../common/types'
import { TextAreaInput } from '../../../../components/layout/form/input'
import { ButtonContainer } from '../routes/subforms/styled'

type ActionButtons = Maybe<'stopAndReady' | 'stopAndCard' | 'stopAndCash' | 'stopAndCancel' | 'stopAndModify'>

interface CancelOrEndTowingRecordProps {
  onCancel: () => void
  towingRecord: TowingRecord
  setTowingRecord?: SetTowingRecordType
  subFormType: 'end' | 'cancel'
}

const CancelOrEndTowingRecordIntl: React.FunctionComponent<CancelOrEndTowingRecordProps & InjectedIntlProps> = ({
  onCancel,
  towingRecord,
  intl,
  setTowingRecord,
  subFormType,
}) => {
  const { formatMessage } = intl
  const historyPush = useHistoryPush()
  const [path, setPath] = useState<string>(`/towing-record/${towingRecord.id}`)
  const [selectedButton, setSelectedButton] = useState<ActionButtons>(null)

  const [endJob, endQuery] = useMutation<EndJobResponse>(END_TOWING_RECORD, {
    onCompleted: data => {
      const { endJob } = data
      if (endJob.__typename === 'EndJobSuccess') {
        maybeStopTrackingRecord(endJob.towingRecord)
        setSuccessNotification(
          formatMessage(messages.job_end_success_title),
          formatMessage(messages.job_end_success_message)
        )

        if (!path.includes('edit') && setTowingRecord) {
          setTowingRecord(endJob.towingRecord, 'view-towing-record')
          return
        }

        historyPush(path)

        return
      }
      setErrorNotifications({ data: endJob })
    },
    onError: error => {
      setFatalErrorNotification(error.message)
    },
  })

  const getUrl = (selectedButton: ActionButtons) => {
    const editTowingRecord =
      selectedButton === 'stopAndCard' || selectedButton === 'stopAndCash' || selectedButton === 'stopAndModify'
    let url = editTowingRecord ? `/towing-record/${towingRecord.id}/edit` : `/towing-record/${towingRecord.id}`

    if (selectedButton === 'stopAndCard') {
      url += '/payment/card'
    }
    if (selectedButton === 'stopAndCash') {
      url += '/payment/cash'
    }

    return url
  }

  const finishJob = async (values: CancelOrEndTowingRecordFormValues, selectedButton: ActionButtons) => {
    const editTowingRecord =
      selectedButton === 'stopAndCard' || selectedButton === 'stopAndCash' || selectedButton === 'stopAndModify'

    const newPath = getUrl(selectedButton)
    if (path !== newPath) {
      setPath(newPath)
    }

    if (subFormType === 'end') {
      endJob({ variables: toMutationVariables(towingRecord, !editTowingRecord, values) })
      return
    }

    if (subFormType === 'cancel') {
      await cancelTowingRecord({ variables: toCancelMutationVariables(values) })
    }
  }

  const [cancelTowingRecord, cancelQuery] = useMutation<CancelTowingRecordResponse>(CANCEL_TOWING_RECORD, {
    onCompleted: data => {
      const { cancelTowingRecord } = data
      if (cancelTowingRecord.__typename === 'CancelTowingRecordSuccess') {
        maybeStopTrackingRecord(cancelTowingRecord.towingRecord)
        setSuccessNotification(
          formatMessage(cancelMessage.cancel_success_title),
          formatMessage(cancelMessage.cancel_success_message)
        )

        if (path.includes('edit')) {
          historyPush(path)
          return
        }

        historyPush(`/towing-jobs`)

        return
      }
      setErrorNotifications({ data: cancelTowingRecord })
    },
    onError: error => {
      setFatalErrorNotification(error.message)
    },
  })

  const initialValues: CancelOrEndTowingRecordFormValues = {
    towingRecordId: towingRecord.id,
    cancelReason: '',
    withFee: false,
    jobEndedAt: { date: new Date(), time: (format(new Date(), 'HH:mm') as unknown) as Time },
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values: CancelOrEndTowingRecordFormValues) => {
          // validate fields and selected buttons
          if (!selectedButton) {
            setErrorNotification('Valinta puuttuu', 'valitse miten haluat lopettaa työn')
            return
          }
          await finishJob(values, selectedButton)
        }}
        render={({ setFieldValue }) => {
          return (
            <RouteForm>
              <RouteWrapper>
                <RouteHeader
                  label={subFormType === 'cancel' ? `Peruuta työ` : `Työ valmis`}
                  regNo={towingRecord.vehicleDetails.registrationNumber}
                  cancel={onCancel}
                />
                <Styling padding="0 2rem" paddingMobile="0 0.5rem">
                  {subFormType === 'end' ? (
                    <>
                      <BigButtonSection>
                        <Button
                          category="on"
                          secondaryCategory="yellow"
                          selectButton
                          selected={selectedButton === 'stopAndReady'}
                          label="Siirrä laskutettavaksi"
                          onClick={() => {
                            setFieldValue('withFee', false)
                            setSelectedButton('stopAndReady')
                          }}
                          size="m"
                          fontWeight="normal"
                          maxWidth="100%"
                        />
                        <Button
                          category="on"
                          secondaryCategory="yellow"
                          selectButton
                          selected={selectedButton === 'stopAndCard'}
                          label="Merkitse korttimaksuksi"
                          onClick={() => {
                            setFieldValue('withFee', false)
                            setSelectedButton('stopAndCard')
                          }}
                          size="m"
                          fontWeight="normal"
                          maxWidth="100%"
                        />
                      </BigButtonSection>
                      <BigButtonSection>
                        <Button
                          category="on"
                          secondaryCategory="yellow"
                          selectButton
                          selected={selectedButton === 'stopAndCash'}
                          label="Merkitse käteismaksuksi"
                          onClick={() => {
                            setFieldValue('withFee', false)
                            setSelectedButton('stopAndCash')
                          }}
                          size="m"
                          fontWeight="normal"
                          maxWidth="100%"
                        />
                        <Button
                          category="on"
                          secondaryCategory="yellow"
                          selectButton
                          selected={selectedButton === 'stopAndModify'}
                          label="Muokkaa kuormakirja"
                          onClick={() => {
                            setFieldValue('withFee', false)
                            setSelectedButton('stopAndModify')
                          }}
                          size="m"
                          fontWeight="normal"
                          maxWidth="100%"
                        />
                      </BigButtonSection>
                    </>
                  ) : (
                    <>
                      <BigButtonSection>
                        <Button
                          category="on"
                          secondaryCategory="yellow"
                          selectButton
                          selected={selectedButton === 'stopAndReady'}
                          label="Siirrä laskutettavaksi"
                          onClick={() => {
                            setFieldValue('withFee', true)
                            setSelectedButton('stopAndReady')
                          }}
                          size="m"
                          fontWeight="normal"
                          maxWidth="100%"
                        />
                        <Button
                          category="on"
                          secondaryCategory="yellow"
                          selectButton
                          selected={selectedButton === 'stopAndCard'}
                          label="Merkitse korttimaksuksi"
                          onClick={() => {
                            setFieldValue('withFee', true)
                            setSelectedButton('stopAndCard')
                          }}
                          size="m"
                          fontWeight="normal"
                          maxWidth="100%"
                        />
                      </BigButtonSection>
                      <BigButtonSection>
                        <Button
                          category="on"
                          secondaryCategory="yellow"
                          selectButton
                          selected={selectedButton === 'stopAndCash'}
                          label="Merkitse käteismaksuksi"
                          onClick={() => {
                            setFieldValue('withFee', true)
                            setSelectedButton('stopAndCash')
                          }}
                          size="m"
                          fontWeight="normal"
                          maxWidth="100%"
                        />
                        <Button
                          category="on"
                          secondaryCategory="yellow"
                          selectButton
                          selected={selectedButton === 'stopAndCancel'}
                          label="Poista kuormakirja"
                          onClick={() => {
                            setFieldValue('withFee', false)
                            setSelectedButton('stopAndCancel')
                          }}
                          size="m"
                          fontWeight="normal"
                          maxWidth="100%"
                        />
                      </BigButtonSection>
                    </>
                  )}
                  {towingRecord.type === TowingRecordType.sos ? (
                    <RouteRow columns={'1fr 1fr'}>
                      <RouteColumn>
                        <DateTimeInput label="Työ lopetettu" name="jobEndedAt" required />
                      </RouteColumn>
                      <RouteColumn />
                    </RouteRow>
                  ) : null}
                  {subFormType === 'cancel' ? (
                    <RouteRow columns={'1fr'}>
                      <RouteColumn>
                        <TextAreaInput label="Peruutuksen syy" name="cancelReason" required />
                      </RouteColumn>
                    </RouteRow>
                  ) : null}
                  <ButtonContainer floatAtBottom>
                    <Button
                      label="Tallenna"
                      size="l"
                      category="save"
                      submit
                      maxWidth="100%"
                      disabled={endQuery.loading || cancelQuery.loading}
                    />
                  </ButtonContainer>
                </Styling>
                <Loading loading={endQuery.loading || cancelQuery.loading} />
              </RouteWrapper>
            </RouteForm>
          )
        }}
      />
    </>
  )
}

export const CancelOrEndTowingRecord = injectIntl(CancelOrEndTowingRecordIntl)
