import React, { PropsWithChildren } from 'react'
import { Button } from '../../../../components/Button'
import { BigButtonContent } from '../../../../components/layout'
import { Styling } from '../../../../components/Styling'

interface SaveButtonProps {
  onClick: () => void
}

export const SaveButton: React.FC<PropsWithChildren<SaveButtonProps>> = ({ children, onClick }) => {
  return (
    <Button category="save" onClick={onClick} size="l" padding="0 4rem">
      <BigButtonContent>{children ? children : <Styling fontSize="m">Tallenna</Styling>}</BigButtonContent>
    </Button>
  )
}
