import React, { FunctionComponent } from 'react'
import { InjectedIntlProps, defineMessages, injectIntl } from 'react-intl'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { NotificationDelay } from '../../common/types'
import { Icon } from '../icons/Icon'
import { Container, Content, IconWrapper, Message, Title } from './styles'
import { dismissToast } from '.'

const toastId = 'resource_not_found'

const messages = defineMessages({
  notFoundTitle: {
    id: 'containers.graphql.not_found.title',
    defaultMessage: 'Resource not found',
  },
  notFoundMessage: {
    id: 'containers.graphql.not_found.message',
    defaultMessage: 'The resource that you are looking for does not exist',
  },
})

export const setResourceNotFoundNotification = () => {
  if (!toast.isActive(toastId)) {
    toast.info(<ResourceNotFoundNotification />, {
      toastId,
      autoClose: false,
      closeButton: false,
      delay: 0,
    })
    dismissToast(toastId, NotificationDelay.resourceNotFoundNotificationDelay)
  }
}

const ResourceNotFoundNotificationIntl: FunctionComponent<InjectedIntlProps> = ({ intl }) => {
  const { formatMessage } = intl

  return (
    <ResourceNotFoundNotificationContainer>
      <IconWrapper>
        <Icon size="big" icon={'search'} color="blue900" />
      </IconWrapper>
      <Content>
        <Title>{formatMessage(messages.notFoundTitle)}</Title>
        <Message>{formatMessage(messages.notFoundMessage)}</Message>
      </Content>
    </ResourceNotFoundNotificationContainer>
  )
}

const ResourceNotFoundNotificationContainer = styled(Container)`
  background-color: ${props => props.theme.colors.blue100};
  color: ${props => props.theme.colors.blue900};
  border-bottom: 1px solid ${props => props.theme.colors.blue500};
`

export const ResourceNotFoundNotification = injectIntl(ResourceNotFoundNotificationIntl)
