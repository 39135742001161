
export const persistentSave = (key: string, data: any) => {
  window.localStorage.setItem(key, JSON.stringify(data))
}

export const persistentDelete = (key: string) => {
  window.localStorage.removeItem(key)
}

export type GenericStorageRetrieveFn<T> = (key: string) => T

export function persistentGet<T>(key: string): T {
  const getResult = window.localStorage.getItem(key)
  return getResult ? JSON.parse(getResult) : null
}

export function persistentGetOrDefault<T>(key: string, defaultVal: T): T {
  const items = localStorage.getItem(key);
  if(items){
    return JSON.parse(items)
  }
  return defaultVal;
}

export const persistentDeleteMessage = (id: string) => {
  const items = persistentGetOrDefault<any>('socketMessages', []);
  const filtered = items.filter((item: any) => item.id !== id);
  persistentSave('socketMessages', filtered);
}

export const persistentAddMessage = (item: any) => {
  const items = persistentGetOrDefault<any[]>('socketMessages', []);
  items.unshift(item);
  persistentSave('socketMessages', items);
}