import React from 'react'
import { InjectedIntl, InjectedIntlProps, injectIntl } from 'react-intl'

import { towingServiceTypeOptions } from '../../common/options'
import { Maybe, TowingRecordType } from '../../common/types'
import { SosServiceOrder, sosVariables } from '../SosSubForm/types'

interface Props {
  serviceType: string
  type: string
  station: {
    name: string
  } | null
  label: string
  deliverable: string
}

export const getJobTypeDescription = (
  intl: InjectedIntl,
  serviceType: string,
  station: {
    name: string
  } | null,
  label: string,
  type: string,
  deliverable: string
): string => {
  const { formatMessage } = intl
  const translateMessage = (messageId: string) => formatMessage({ id: messageId })

  const serviceTypeString = translateMessage(towingServiceTypeOptions.options[serviceType])

  if (type === TowingRecordType.default) {
    return `${serviceTypeString}${label ? ` / ${label}` : ''}${station ? ` / ${station.name}` : ''}`
  }

  if (type === 'autoliitto') {
    return `${serviceTypeString}${label ? ` / ${label}` : ''}${station ? ` / ${station.name}` : ''}`
  }

  if (type === TowingRecordType.sos) {
    if (label === 'SOS') {
      return `${deliverable !== '' ? `${deliverable} / ` : ''}${label}${station ? ` / ${station.name}` : ''}`
    }
    return `${deliverable !== '' ? `${deliverable} / ` : ''}SOS${label ? ` / ${label}` : ''}${
      station ? ` / ${station.name}` : ''
    }`
  }

  const set = [label]

  if (station) {
    set.push(station.name)
  }
  return set.join(' / ')
}

const JobTypeDescriptionIntl: React.FC<Props & InjectedIntlProps> = ({
  intl,
  serviceType,
  station,
  label,
  type,
  deliverable,
}) => {
  return <>{getJobTypeDescription(intl, serviceType, station, label, type, deliverable)}</>
}

export function getDeliverable(sosServiceOrder: Maybe<SosServiceOrder>): string {
  if (!sosServiceOrder) {
    return ''
  }

  if (sosServiceOrder.deliverable) {
    const deliverable = sosVariables.deliverable.find(d => d.value === sosServiceOrder.deliverable)

    if (deliverable) {
      return deliverable.label
    }
  }

  if (sosServiceOrder.estimatedServiceType) {
    const estimatedServiceType = sosVariables.deliverable.find(d => d.value === sosServiceOrder.estimatedServiceType)

    if (estimatedServiceType) {
      return estimatedServiceType.label
    }
  }

  return sosServiceOrder.deliverable || sosServiceOrder.estimatedServiceType || ''
}

export const JobTypeDescription = injectIntl(JobTypeDescriptionIntl)
