import React from 'react'
import { FormattedDate } from 'react-intl'

import { SectionBody, SectionHeader, SectionTextColumn } from '../layout/section'
import { Text } from '../layout/typography'

interface HeaderAndValueProps {
  header: string | null
  value: string | number | null | Date
}

interface HeaderAndMultipleValueProps {
  header: string | null
  values: (string | number | null | Date)[]
}

interface HeaderAndDateToDateProps {
  header: string
  startDate: Date | string
  endDate: Date | string | null
}

export const HeaderAndValue: React.FunctionComponent<HeaderAndValueProps> = ({ header, value }) => {
  return (
    <SectionTextColumn width="100%">
      <SectionHeader>
        <Text size="medium">{header}</Text>
      </SectionHeader>
      <SectionBody>
        {value instanceof Date ? (
          <Text size="medium">
            <FormattedDate value={value} />
          </Text>
        ) : (
          <Text size="medium">{value}</Text>
        )}
      </SectionBody>
    </SectionTextColumn>
  )
}

export const HeaderAndMultipleValues: React.FunctionComponent<HeaderAndMultipleValueProps> = ({ header, values }) => {
  return (
    <SectionTextColumn width="100%">
      <SectionHeader>
        <Text size="medium">{header}</Text>
      </SectionHeader>
      <SectionBody>
        {values.map(value => {
          return (
            <div key={values.indexOf(value)}>
              {value instanceof Date ? (
                <Text size="medium">
                  <FormattedDate value={value} />
                </Text>
              ) : (
                <Text size="medium">{value}</Text>
              )}
            </div>
          )
        })}
      </SectionBody>
    </SectionTextColumn>
  )
}

export const HeaderAndDateToDate: React.FunctionComponent<HeaderAndDateToDateProps> = ({
  header,
  startDate,
  endDate,
}) => {
  return (
    <SectionTextColumn width="100%">
      <SectionHeader>
        <Text size="medium">{header}</Text>
      </SectionHeader>
      <SectionBody>
        <Text size='medium'>
          {startDate ? <FormattedDate value={typeof startDate === 'string' ? new Date(startDate) : startDate} /> : ''}
          {' - '}
          {endDate ? <FormattedDate value={typeof endDate === 'string' ? new Date(endDate) : endDate} /> : ''}
        </Text>
      </SectionBody>
    </SectionTextColumn>
  )
}
