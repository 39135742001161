import React, { RefObject, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  DateSectionColumn,
  HalfDateInput,
} from '../../../containers/TowingOrderPage/EditTowingOrder/TowingOrderDetailsSubFormContents'
import sos from '../../../images/sos.gif'
import { Column, FormSubGroup, FormSubGroupHeading, ImageWrapper, Row, Section, SectionColumn } from '../../layout'
import { NumberInput, SelectInput, TextAreaInput, TextInput } from '../../layout/form/input'
import { SosFormSubGroupHeader, SosFormSubGroupWrapper } from '../TowingOrderForm/EditTowingOrder'
import { sosVariables } from '../types'
import { useSosCauseOptions } from '../../../util/useSosCauseOptions'

interface SosServiceOrderDetailsFormProps {
  getValue: any
  setValue: any
  isDraft?: boolean
  sosRef?: RefObject<any>
  scrollToThis?: boolean
}

export const SosServiceOrderDetailsForm: React.FunctionComponent<SosServiceOrderDetailsFormProps> = ({
  isDraft,
  sosRef,
  scrollToThis,
  getValue,
  setValue,
}) => {
  const [shouldScroll, setShouldScroll] = useState<boolean>(scrollToThis || false)
  const handleScroll = useCallback(() => {
    if (sosRef && sosRef.current) {
      sosRef.current.scrollIntoView()
    }

    setShouldScroll(false)
  }, [sosRef, setShouldScroll])

  useEffect(() => {
    if (shouldScroll) {
      handleScroll()
    }
  }, [shouldScroll, handleScroll])

  const deliverable = getValue('sosServiceOrder.deliverable')
  const { causeOptions, onDeliverableChange } = useSosCauseOptions(deliverable, setValue)

  const deliverables = sosVariables.deliverable

  return (
    <div ref={sosRef}>
      <SosFormSubGroupWrapper>
        <SosFormSubGroupHeader>
          <Row>
            <Column>
              <FormSubGroupHeading>
                <ImageWrapper>
                  <img src={sos} alt="SOS" width={`95px`} />
                </ImageWrapper>
              </FormSubGroupHeading>
            </Column>
          </Row>
        </SosFormSubGroupHeader>
        <FormSubGroup>
          <Section columns={2} marginMobile="0">
            <DateSectionColumn>
              <HalfDateInput label={'Työn pvm'} name="jobDetails.towingDate" required disabled />
              {/* <HalfTimeInput label={'Aika'} name="sosServiceOrder.towingTimeAndTime.time" disabled /> */}
              <MileageContainer>
                <NumberInput
                  label="Mittarilukema"
                  name="sosServiceOrder.vehicleMileage"
                  disabled={!isDraft}
                  decimals={0}
                />
              </MileageContainer>
            </DateSectionColumn>
            <SectionColumn>
              {!!isDraft && (
                <SelectInput
                  label="Vakuutusyhtiö"
                  name="sosServiceOrder.insuranceCompany"
                  required={!!isDraft}
                  options={sosVariables.insuranceCompany}
                />
              )}
            </SectionColumn>
          </Section>
          <Section columns={2}>
            <SectionColumn>
              <TextInput label={'SOS Viite'} name="sosServiceOrder.caseNumber" disabled />
            </SectionColumn>
            <SectionColumn>
              <TextInput label="Konsepti" name="sosServiceOrder.agreementName" disabled />
            </SectionColumn>
          </Section>
          <Section columns={2}>
            <SectionColumn>
              <SelectInput
                label="Toimenpide"
                name="sosServiceOrder.deliverable"
                options={deliverables}
                disabled={!isDraft}
                required={!!isDraft}
                onChange={(value) => onDeliverableChange(value)}
              />
            </SectionColumn>
            <SectionColumn>
              <SelectInput
                label="Vika"
                name="sosServiceOrder.cause"
                options={causeOptions}
                disabled={!isDraft}
                required={!!isDraft}
              />
            </SectionColumn>
          </Section>

          <Section columns={2}>
            <SectionColumn>
              <TextAreaInput label={'Työn lisätiedot'} name="jobDetails.additionalInfo" />
            </SectionColumn>
            <SectionColumn>
              <TextAreaInput label={'Muistiinpanot (sisäiset)'} name="jobDetails.notes" />
            </SectionColumn>
          </Section>
          <Section columns={2}>
            <SectionColumn>
              <TextAreaInput label="SOS Merkinnät" name="sosServiceOrder.situationDescription" disabled />
            </SectionColumn>
            <SectionColumn />
          </Section>
        </FormSubGroup>
      </SosFormSubGroupWrapper>
    </div>
  )
}

const MileageContainer = styled.div`
  width: calc(50% - 1rem) !important;
  @media (max-width: ${(props) => props.theme.screenSize.mobile}) {
    width: calc(50% - 0.125rem);
  }
`
