import React, { useContext } from 'react'
import {
  FormSubGroup,
  FormSubGroupHeader,
  FormSubGroupHeading,
  Section,
  SectionColumn,
  Row,
  Column,
} from '../../../../components/layout'
import { DateInput, SelectInput, TextAreaInput, TextInput } from '../../../../components/layout/form/input'
import { StateStore } from '../../../StoreProvider'
import { serviceTypeOptions } from '../../../TowingOrderPage/options'

export const TowingRecordDetailsSubFormContents: React.FunctionComponent = () => {
  const { state } = useContext(StateStore)

  if (!state.settings) {
    return null
  }

  return (
    <>
      <FormSubGroupHeader>
        <Row justify="space-between">
          <Column>
            <FormSubGroupHeading>Työn tiedot</FormSubGroupHeading>
          </Column>
        </Row>
      </FormSubGroupHeader>
      <FormSubGroup>
        <Section columns={2}>
          <SectionColumn>
            <DateInput label={'Päivämäärä'} name="jobDetails.towingDate" required />
          </SectionColumn>
        </Section>
        <Section columns={2}>
          <SectionColumn>
            <TextInput label={'Tilauksen viite'} name="jobDetails.orderReference" />
          </SectionColumn>
          <SectionColumn>
            <TextInput label="Vahinkotunnus" name="jobDetails.incidentCaseNo" />
          </SectionColumn>
        </Section>
        <Section columns={2}>
          <SelectInput
            label="Hinauksen syy"
            name="jobDetails.towingReason"
            options={state.settings.towingReasons.map((label: string) => ({
              label,
              value: label,
            }))}
          />
          <SelectInput label="Toimenpide" name="jobDetails.serviceType" options={serviceTypeOptions} />
        </Section>
        <Section columns={2}>
          <SectionColumn>
            <TextAreaInput label={'Lisäselvitykset'} name="jobDetails.additionalInfo" />
          </SectionColumn>
          <SectionColumn>
            <TextAreaInput label={'Muistiinpanot'} name="jobDetails.notes" />
          </SectionColumn>
        </Section>
      </FormSubGroup>
    </>
  )
}
