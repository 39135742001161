import { EditTowingRecordFormValues } from './types'

interface Errors {
  newItem?: string
}

export const validate = (values: EditTowingRecordFormValues): Errors => {
  const errors = {} as Errors

  if (values.paymentType && ['card', 'cash'].includes(values.paymentType) && values.jobItems.length < 1) {
    errors.newItem = 'Lisää vähintään yksi maksurivi'
  }
  return errors
}
