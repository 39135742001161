import {TowingRecordRowInterface} from "./types";
import {
  RepairShop,
  repairShops
} from "../../containers/TowingRecordPage/components/routes/subforms/FinishRoute/options";

export function getRepairShop(towingRecord: TowingRecordRowInterface): RepairShop | undefined {

  if(towingRecord.automobileAndTouringClubFinland && towingRecord.automobileAndTouringClubFinland.repairShopId){

    const repairShopId = towingRecord.automobileAndTouringClubFinland.repairShopId;
    return repairShops.find(shop => shop.id === repairShopId);
  }
}
