import React from 'react'
import styled from 'styled-components'
import { Time } from '../../common/types'

interface Props {
  time: Time | null
}

export const DisplayTime: React.FunctionComponent<Props> = ({ time }) => {
  if (time) {
    return <DisplayTimeWrapper>{`klo ${time.toString()}`}</DisplayTimeWrapper>
  }
  return null
}

const DisplayTimeWrapper = styled.div`
  padding: 0;
`
