import React, { FunctionComponent } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { NotificationDelay } from '../../common/types';
import { Icon } from '../icons/Icon';
import { Container, Content, IconWrapper, Message, Title } from './styles';
import { dismissToast } from '.';

export const setInfoNotification = (title: string, message: string) => {
  if (!toast.isActive(title)) {
    toast.success(<InfoNotification title={title} message={message} />, {
      autoClose: false,
      closeButton: false,
      delay: 0,
      toastId: title,
    });
    dismissToast(title, NotificationDelay.infoNotificationDelay);
  }
};

interface InfoNotificationProps {
  title: string;
  message: string;
}

export const InfoNotification: FunctionComponent<InfoNotificationProps> = ({ title, message }) => {
  return (
    <InfoContainer>
      <IconWrapper>
        <Icon size="big" icon={'info'} color="blue900" />
      </IconWrapper>
      <Content>
        <Title>{title}</Title>
        <Message>{message}</Message>
      </Content>
    </InfoContainer>
  );
};

const InfoContainer = styled(Container)`
  background-color: ${(props) => props.theme.colors.blue100};
  color: ${(props) => props.theme.colors.blue900};
  border-bottom: 1px solid ${(props) => props.theme.colors.blue500};
`;
