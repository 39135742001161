import { useMutation } from '@apollo/react-hooks'
import { Formik, getIn } from 'formik'
import React, { useContext, useState } from 'react'
import { InjectedIntlProps, defineMessages, injectIntl } from 'react-intl'

import { Maybe, TowingRecordType, TypeIdentifier } from '../../../../../../common/types'
import { Button } from '../../../../../../components/Button'
import { Geolocation } from '../../../../../../components/Geolocation/types'
import { ScrollToError } from '../../../../../../components/layout/form/ScrollToError'
import {
  dismissNotifications,
  setErrorNotification,
  setSuccessNotification,
} from '../../../../../../components/notification'
import { Loading } from '../../../../../../components/responses'
import {
  setErrorNotifications,
  setFatalErrorNotification,
} from '../../../../../../components/responses/setErrorNotifications'
import { useHistoryPush } from '../../../../../../components/router'
import { UPDATE_STARTED_MUTATION } from '../../../../../../components/SosSubForm/mutation/mutation'
import { updateStartedToMutationVariables } from '../../../../../../components/SosSubForm/mutation/toMutationVariables'
import { UpdateStartedResponse } from '../../../../../../components/SosSubForm/mutation/types'
import { extractDate } from '../../../../../../util/dateConversion'
import { StateStore } from '../../../../../StoreProvider'
import { SetTowingRecordType, TowingRecord } from '../../../../ViewTowingRecord/types'
import { GeoLocateAddressData } from '../../types'
import { ButtonContainer, RouteForm } from '../styled'
import { getInitialValues } from './getInitialValues'
import { EDIT_START_ROUTE_MUTATION, EditStartRouteResponse } from './mutation/mutation'
import { toMutationVariables } from './mutation/toMutationVariables'
import { StartRoute } from './StartRoute'
import { EditStartRouteFormValues } from './types'

interface EditTowingRecordRoutesProps {
  onCancel: () => void
  towingRecord: TowingRecord
  onRefetch?: () => void
  setTowingRecord?: SetTowingRecordType
}

const routeMessages = defineMessages({
  route_edit_notification_success_title: {
    id: 'route.edit.notification.success.title',
    defaultMessage: 'Route updated',
  },
  route_edit_notification_success_message: {
    id: 'route.edit.notification.success.message',
    defaultMessage: 'Route updated successfully.',
  },
})

export const RoutesIntl: React.FunctionComponent<EditTowingRecordRoutesProps & InjectedIntlProps> = ({
  onCancel,
  towingRecord,
  intl,
  setTowingRecord,
}) => {
  const historyPush = useHistoryPush()
  const { formatMessage } = intl
  const {
    state: { settings },
  } = useContext(StateStore)
  const useExtendedRoutes = settings && settings.modules.useExtendedRoutes ? true : false

  const [editTowingRecord, { loading: startRouteLoading }] = useMutation<EditStartRouteResponse>(
    EDIT_START_ROUTE_MUTATION,
    {
      onCompleted(test) {
        const { editStartRoute } = test
        if (editStartRoute.__typename === 'EditTowingRecordSuccess') {
          setSuccessNotification(
            formatMessage(routeMessages.route_edit_notification_success_title),
            formatMessage(routeMessages.route_edit_notification_success_message)
          )
          if (towingRecord.type !== TowingRecordType.sos && !useExtendedRoutes) {
            if (setTowingRecord) {
              setTowingRecord(editStartRoute.towingRecord, 'view-towing-record')
              return
            }
            historyPush(`/towing-record/${towingRecord.id}`)
          }
        } else {
          setErrorNotifications({ data: editStartRoute })
        }
      },
      onError(err) {
        setFatalErrorNotification(err.message)
      },
    }
  )

  const [updateStarted, { loading: updateStartedLoading }] = useMutation<UpdateStartedResponse>(
    UPDATE_STARTED_MUTATION,
    {
      onCompleted({ updateStarted }) {
        if (updateStarted.__typename === 'UpdateStartedSuccess') {
          if (setTowingRecord) {
            setTowingRecord(updateStarted.towingRecord, 'view-towing-record')
            return
          }
          historyPush(`/towing-record/${towingRecord.id}`)
        } else {
          setErrorNotifications({ data: updateStarted })
        }
      },
      onError(error) {
        setFatalErrorNotification(error.message)
      },
    }
  )

  async function submit(values: EditStartRouteFormValues) {
    try {
      dismissNotifications()

      await editTowingRecord({ variables: toMutationVariables(values) })

      if (values.sosServiceOrder || useExtendedRoutes) {
        await updateStarted({ variables: updateStartedToMutationVariables(values, towingRecord.id) })
      }

    } catch (e) {
      console.error(e)
    }
  }

  const stations = settings ? settings.stations : []
  
  const initialValues = getInitialValues(towingRecord, stations)
  const isAutoliitto = towingRecord.typeIdentifier === TypeIdentifier.autoliitto
  const isSos = towingRecord.type === TowingRecordType.sos
  const [isLoading, setIsloading] = useState<boolean>(false)

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values: EditStartRouteFormValues) => {
        if (isSos) {
          const { startRoute, sosServiceOrder, arrivalRoute } = values
          if (!startRoute.address) {
            return setErrorNotification('Virhe', 'Valitse asemapaikka')
          }

          if (sosServiceOrder && sosServiceOrder.currentLocation) {
            const { coords } = sosServiceOrder.currentLocation
            if (!coords.lat || !coords.long) {
              return setErrorNotification(
                'Virhe',
                'Nykyisen sijainnin koordinaatteja ei saatu haettua. Hae osoite kartalta tai valitse osoite osoitehaun ehdotuksista.'
              )
            }
          }

          if (sosServiceOrder && arrivalRoute) {
            const { coords: arrivalCoords } = arrivalRoute
            if (!arrivalCoords.lat || !arrivalCoords.long) {
              return setErrorNotification(
                'Virhe',
                'Kohdeosoitteen koordinaatteja ei saatu haettua. Hae osoite kartalta tai valitse osoite osoitehaun ehdotuksista.'
              )
            }
          }
        }
        await submit(values)
      }}
      render={({ setFieldValue, values }) => {
        const setValue = (field: string, value: any) => {
          setFieldValue(field, value)
        }

        const getValue = (fieldName: string) => {
          const value = getIn(values, fieldName)
          return value
        }

        const setRouteLocation = (locationData: Maybe<GeoLocateAddressData>) => {
          if (locationData) {
            setValue(`startRoute.address`, locationData.address)
            setValue(`startRoute.city`, locationData.city)
            setValue(`startRoute.coords.lat`, locationData.coords.lat)
            setValue(`startRoute.coords.long`, locationData.coords.long)
            setValue(`startRoute.zipcode`, locationData.zipcode)
          }
        }

        const updateRouteDate = () => {
          setValue(`startRoute.date`, extractDate(new Date()))
        }

        const setAutoliittoDateField = (fieldName: string) => {
          const date = extractDate(new Date())
          setValue(fieldName, date)
        }

        const setGeolocated = (geolocated: boolean) => {
          setValue('startRoute.geolocated', geolocated)
        }

        const clear = () => {
          setFieldValue('startRoute.address', '')
          setFieldValue('startRoute.city', '')
          setFieldValue('startRoute.coords.lat', null)
          setFieldValue('startRoute.coords.long', null)
          setFieldValue('startRoute.geolocated', false)
          setFieldValue('startRoute.zipcode', '')
        }
        
        const reportPosition = (location: Geolocation, name: string) => {
          console.log('reportPosition', location)

          setFieldValue(`${name}.address`, location.address)
          setFieldValue(`${name}.city`, location.city)
          setFieldValue(`${name}.coords.lat`, location.coords.lat)
          setFieldValue(`${name}.coords.long`, location.coords.long)
        }

        return (
          <RouteForm>
            <StartRoute
              regNo={towingRecord.vehicleDetails.registrationNumber}
              onCancel={() => onCancel()}
              isAutoliitto={isAutoliitto}
              isSos={isSos}
              useExtendedRoutes={useExtendedRoutes}
              setAutoliittoDateField={setAutoliittoDateField}
              setRouteLocation={setRouteLocation}
              updateRouteDate={() => updateRouteDate}
              //showCustomAddressInitially={initialValues.startRoute.address ? true : false}
              initialValues={initialValues}
              clear={clear}
              setGeolocatedField={setGeolocated}
              setLoading={setIsloading}
              reportPosition={reportPosition}
              getValue={getValue}
              setValue={setValue}
              stationId={towingRecord.stationId}
              stations={stations}
            />

            <ButtonContainer floatAtBottom>
              <Loading loading={startRouteLoading || updateStartedLoading || isLoading} />
              <Button
                category="save"
                size="l"
                label="Tallenna lähtö"
                mobileLabel="Tallenna"
                submit
                maxWidth="100%"
                disabled={isLoading}
              ></Button>
            </ButtonContainer>
            <ScrollToError />
          </RouteForm>
        )
      }}
    />
  )
}

export const StartRoutePage = injectIntl(RoutesIntl)