import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components'
import { Maybe } from '../../common/types';
import { SelectInput } from '../layout/form/input';
import { Text } from '../layout/typography'
import { CheckboxToggle } from '../layout/form/input/CheckboxToggle';
import { Section, SectionColumn, SectionTextColumn, ModalText, Row} from '../layout';
import { ButtonContainer } from '../../containers/TowingRecordPage/components/routes/subforms/styled';
import { Button } from '../Button';
import { Currency } from '../Currency'
import { FullscreenModal } from '../ModalPage';
import { Modal } from '../Modal';

interface IPurchaseModal {
  wsConnectionData: { [key: string]: any };
  wsSendAbort: () => void;
  closeConnection: () => void;
  paymentTerminals: any[];
  paymentTerminalOptions: any[];
  loading: boolean;
  setSelectedTerminal: (
    value: React.SetStateAction<{
      [key: string]: any;
    }>,
  ) => void;
  itemsTotal: Maybe<number>;
  selectedPaymentTerminal: { [key: string]: any };
  resetWsConnectionData: () => void;
  updateWsConnectionData: (newValues: any) => void;
  setloading: React.Dispatch<React.SetStateAction<boolean>>;
  wsConnect: () => void;
  receiptEmail: string | null;
}

interface IRefundModal {
  wsConnectionData: { [key: string]: any };
  wsSendAbort: () => void;
  closeConnection: () => void;
  loading: boolean;
}

interface ICheckModal {
  wsConnectionData: { [key: string]: any };
  closeConnection: () => void;
  onClick: () => void;
  loading: boolean;
}

interface IConfirmPurchaseModal {
  onCancel: () => void;
  onConfirm: () => void;
}

export const PaymentTerminalPurchaseModal: React.FunctionComponent<IPurchaseModal> = ({
  wsConnectionData,
  wsSendAbort,
  closeConnection,
  paymentTerminals,
  paymentTerminalOptions,
  loading,
  setSelectedTerminal,
  itemsTotal,
  selectedPaymentTerminal,
  resetWsConnectionData,
  updateWsConnectionData,
  setloading,
  wsConnect,
  receiptEmail,
}) => {
  const [modalError, setError] = useState<string>();
  const [sendReceipt, setSendReceipt] = useState<boolean>(false);
  const hasError = useCallback(() => {
    if (modalError){
      setloading(false);
      return { title: 'virhe!', message: modalError };
    }
    if (wsConnectionData.status === 'error' && !wsConnectionData.response) {
      setloading(false);
      return { title: 'virhe!', message: wsConnectionData.msg };
    }
    if (wsConnectionData.status === 'error' && wsConnectionData.response) {
      setloading(false);
      return {
        title: 'Maksupäätetapahtuma virheellinen!',
        message: wsConnectionData.response.message,
      };
    }
    return undefined;
  }, [wsConnectionData, modalError, setloading]);

  const hasSuccess = useCallback(() => {
    if (wsConnectionData.status === 'success' && wsConnectionData.response) {
      return { title: 'Tapahtuma onnistui!', message: wsConnectionData.response.message };
    }
    return undefined;
  }, [wsConnectionData]);

  const selectTerminal = (selection: string | number | undefined) => {
    if (selection && typeof selection !== 'number') {
      var terminalData;
      if (paymentTerminals.length > 1) {
        terminalData = paymentTerminals.find((terminal: any) => terminal.terminalId === selection);
      } else {
        terminalData = paymentTerminals[0];
      }
      resetWsConnectionData();
      setError(undefined);
      setSendReceipt(false);
      setSelectedTerminal(terminalData);
    }
  };

  const connectPaymentTerminalPurchase = useCallback(() => {
    setloading(true);
    resetWsConnectionData();
    setError(undefined);
    if (
      !selectedPaymentTerminal ||
      (selectedPaymentTerminal && Object.keys(selectedPaymentTerminal).length === 0)
    ) {
      setError('Valitse ensin maksupäätelaite!');
      return;
    }

    if (
      !selectedPaymentTerminal ||
      !selectedPaymentTerminal['userName'] ||
      !selectedPaymentTerminal['password'] ||
      !selectedPaymentTerminal['terminalId']
    ) {
      setError('Maksupäätelaitteen tiedot puutteelliset!');
      return;
    }
    if (!itemsTotal) {
      setError('Maksun summa puuttuu!');
      return;
    }
    var amount = itemsTotal;
    amount = Math.round(100 * amount);
    updateWsConnectionData({
      connected: false,
      connectionReason: 'purchase',
      status: '',
      msg: '',
      selectedTerminal: selectedPaymentTerminal,
      amountToPay: amount,
      response: undefined,
      terminalInfo: [],
      sendReceipt: sendReceipt
    });
    wsConnect();
  }, [
    setloading,
    updateWsConnectionData,
    wsConnect,
    resetWsConnectionData,
    selectedPaymentTerminal,
    itemsTotal,
    sendReceipt
  ]);
  return (
    <FullscreenModal
      label={wsConnectionData.connected ? 'Maksupäätelaite yhdistetty' : 'Valitse maksupäätelaite'}
      onClose={() => {
        if (wsConnectionData.connected) {
          wsSendAbort();
        } else {
          closeConnection();
        }
      }}
      loading={loading}
      error={hasError()}
      success={hasSuccess()}
    >
      {!wsConnectionData.connected && (
        <Section columns={1} justify="text">
          <SectionColumn alignItems="center">
            <SectionTextColumn width="50%" widthMobile="100%">
              <SelectInput
                label="Maksupäätteet"
                name="selectedPaymentTerminal"
                options={paymentTerminalOptions}
                onChange={(selection) => {
                  selectTerminal(selection);
                }}
              />
            </SectionTextColumn>
          </SectionColumn>
          {receiptEmail != null && receiptEmail !== '' && (
            <Row justify="center">
              <CheckboxToggle inputValue={sendReceipt} label={`Lähetä kuitti ${receiptEmail}`} onChange={(value) => {setSendReceipt(value)}} />
            </Row>
          )}
        </Section>
      )}
      {wsConnectionData.connected && (
        <Section columns={1} justify="text">
          <SectionColumn alignItems="center">
            <ModalText>
              <Row>
                <Description bold>Yhdistetty maksupäätelaite:</Description>
                <Text margin={'margin-left: 0.5rem;'}>{selectedPaymentTerminal.terminalName}</Text>
              </Row>
              <Row>
                <Description bold>Yhteensä</Description>
                <Currency>{itemsTotal}</Currency>
              </Row>
            </ModalText>
          </SectionColumn>
        </Section>
      )}
      {!wsConnectionData.connected && (
        <ButtonContainer>
          <Button
            category="save"
            //size="l"
            label="Suorita maksu"
            disabled={loading}
            onClick={() => connectPaymentTerminalPurchase()}
          />
        </ButtonContainer>
      )}
    </FullscreenModal>
  );
};

export const PaymentTerminalRefundModal: React.FunctionComponent<IRefundModal> = ({
  wsConnectionData,
  wsSendAbort,
  closeConnection,
  loading,
}) => {
  const hasError = useCallback(() => {
    if (wsConnectionData.status === 'error' && !wsConnectionData.response) {
      return { title: 'virhe!', message: wsConnectionData.msg };
    }
    if (wsConnectionData.status === 'error' && wsConnectionData.response) {
      return {
        title: 'Maksupäätetapahtuma virheellinen!',
        message: wsConnectionData.response.message,
      };
    }
    return undefined;
  }, [wsConnectionData]);
  const hasSuccess = useCallback(() => {
    if (wsConnectionData.status === 'success' && wsConnectionData.response) {
      return { title: 'Maksupäätehyvitys onnistunut', message: wsConnectionData.response.message };
    }
    return undefined;
  }, [wsConnectionData]);
  return (
    <FullscreenModal
      label="Hyvitä maksupäätteellä"
      onClose={() => {
        if (wsConnectionData.connected) {
          wsSendAbort();
        } else {
          closeConnection();
        }
      }}
      loading={loading}
      error={hasError()}
      success={hasSuccess()}
      info={{
        title: 'Huom!',
        message: 'hyvitys on tehtävä samalla maksupääteellä, millä asiakasta on veloitettu',
      }}
    ></FullscreenModal>
  );
};

export const PaymentTerminalCheckModal: React.FunctionComponent<ICheckModal> = ({
  wsConnectionData,
  closeConnection,
  onClick,
  loading,
}) => {
  const hasError = useCallback(() => {
    if (wsConnectionData.status === 'error' && !wsConnectionData.response) {
      return { title: 'virhe!', message: wsConnectionData.msg };
    }
    if (wsConnectionData.status === 'error' && wsConnectionData.response) {
      return {
        title: wsConnectionData.response.message,
        message: wsConnectionData.response.result.message,
      };
    }
    return undefined;
  }, [wsConnectionData]);

  const hasSuccess = useCallback(() => {
    if (wsConnectionData.status === 'success' && wsConnectionData.response) {
      return { title: wsConnectionData.response.message, message: '' };
    }
    return undefined;
  }, [wsConnectionData]);
  return (
    <FullscreenModal
      label="Maksupäätetapahtuman tiedot"
      onClose={() => {
        closeConnection();
      }}
      loading={loading}
      error={hasError()}
      success={hasSuccess()}
      info={
        hasError()
          ? {
              title: 'Huom!',
              message:
                'Voit tarkistaa tapahtuman tarkemmat tiedot Poplapayn online tools -sivustolta.',
            }
          : undefined
      }
    >
      {wsConnectionData.status === 'error' && (
        <ButtonContainer>
          <Button
            category="danger"
            //size="l"
            label="poista tapahtuma"
            disabled={loading}
            onClick={() => {
              onClick();
            }}
          />
        </ButtonContainer>
      )}
    </FullscreenModal>
  );
};

export const PaymentConfirmationModal: React.FunctionComponent<IConfirmPurchaseModal> = ({
  onCancel,
  onConfirm
}) => {
  return (
    <Modal 
      label='Kuormakirjaan on tehty muutoksia' 
      message='Kuormakirjan muutokset tulee tallentaa ennenkuin maksupäätteeseen voidaan yhdistää. Haluatko tallentaa muutokset?' 
      confirmButtonCategory='save' 
      onConfirm={onConfirm}
      close={onCancel}
      closeLabel='Peruuta'
      confirmLabel='Tallenna muutokset'
      border='thin solid'>
    </Modal>)
}


interface DescriptionProps {
  bold?: boolean
}

const Description = styled.div<DescriptionProps>`
  text-align: left;
  ${props =>
    props.bold &&
    css`
      font-weight: 600;
    `};
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    margin: 0.75rem 0 0.25rem 0;
    font-weight: 600;
  }
`
