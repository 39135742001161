import gql from 'graphql-tag'

export const CANCEL_TOWING_RECORD = gql`
  mutation CancelTowingRecord($input: CancelTowingRecordInput!) {
    cancelTowingRecord(input: $input) {
      __typename
      ... on UnauthorizedError {
        error {
          code
          message
        }
      }
      ... on GenericError {
        genericError: error {
          code
          message
        }
      }
      ... on StatusChangeForbiddenError {
        error {
          code
          message
        }
      }
      ... on CancelTowingRecordSuccess {
        towingRecord {
          id
          jobStatus
          type
        }
      }
    }
  }
`
