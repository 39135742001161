import styled from 'styled-components'

// if a need for differently colored labels / tags arises, use a type to specify
interface Props {
  type?: string
}

export const LabelTag = styled.div<Props>`
  padding: 0.25rem;
  text-align: center;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.black};
  border-radius: ${props => props.theme.radius.medium};
  font-weight: ${props => props.theme.fontWeight.bold};
`
