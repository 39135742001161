import { useMutation } from '@apollo/react-hooks'
import { Formik } from 'formik'
import React, { useCallback, useEffect, useContext } from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { StateStore } from '../../containers/StoreProvider'
import { Label, Section, SectionColumn, Column } from '../../components/layout'
import {
  DateSectionColumn,
  Divider,
  HalfDateInput,
  HalfTimeInput,
  orderScheduleOptions,
} from '../../containers/TowingOrderPage/EditTowingOrder/TowingOrderDetailsSubFormContents'
import { EditTowingOrder } from '../../containers/TowingOrderPage/EditTowingOrder/types'
import { messages } from '../../containers/TowingOrderPage/messages'
import { TowingOrder } from '../../containers/TowingOrderPage/ViewTowingOrder/types'
import {
  AutoliittoSeparatorLine,
  ButtonContainer,
} from '../../containers/TowingRecordPage/components/routes/subforms/styled'
import { extractTime, isCorrectTimeFormat } from '../../util/dateConversion'
import { translateInputOptions } from '../../util/translateInputOptions'
import { Button } from '../Button'
import { RadioButtonGroup } from '../layout/form/input'
import { DateTimeInput } from '../layout/form/input/DateTimeInput'
import { DateTimeInputWrapper } from '../layout/form/style/ComboInputWrapper'
//import { DateTimeInput } from '../layout/form/input/DateTimeInput'
import { FullscreenModal } from '../ModalPage'
import { ModalProps } from '../ModalPage/types'
import { setInfoNotification, setSuccessNotification } from '../notification'
import { setErrorNotifications, setFatalErrorNotification } from '../responses/setErrorNotifications'
import { ChangeTimeModalFormValues, getInitialValuesQuery } from './initialValues'
import { EDIT_ORDER_SCHEDULE } from './mutation/mutation'
import { toMutationVariables } from './mutation/toMutationVariables'
import { EditOrderScheduleMutationVariables, EditOrderScheduleResponse } from './mutation/types'

interface ALTimeChangeModalProps extends ModalProps {
  label: string
  setFieldValue?: (field: string, value: any) => void
  isVisibleTimeInput?: boolean
  towingOrder: TowingOrder | EditTowingOrder
  useQueryOnly: boolean
  reFetch?: () => void
  onChangeOnlyReason?: () => void
  buttonSectionHeader?: string
  getOriginalFieldValue?: (field: string) => any
}

const ALTimeChangeModalIntl: React.FunctionComponent<ALTimeChangeModalProps & InjectedIntlProps> = ({
  intl,
  label,
  onClose,
  setFieldValue,
  isVisibleTimeInput = false,
  towingOrder,
  useQueryOnly,
  reFetch,
  onChangeOnlyReason,
  getOriginalFieldValue,
  buttonSectionHeader = 'Valitse tilausajan muutoksen syy',
}) => {
  const {
    state: { settings },
  } = useContext(StateStore)
  const { formatMessage } = intl
  // initiate formik values
  const delayedTowingReasons = settings && settings.AlDelayedTowingReasons ? settings.AlDelayedTowingReasons : []
  const getOptions = () => {
    const options: any[] = []
    delayedTowingReasons.forEach(reason => {
      options.push({ value: reason.value, label: reason.label })
    })
    return options
  }
  const delayedTowingOptions = getOptions()

  const source = getOriginalFieldValue
    ? ({
        id: getOriginalFieldValue('id'),
        jobDetails: {
          towingDate: getOriginalFieldValue('jobDetails.towingDate'),
          towingTime: getOriginalFieldValue('jobDetails.towingTime'),
          towingEndDate: getOriginalFieldValue('jobDetails.towingEndDate'),
          towingEndTime: getOriginalFieldValue('jobDetails.towingEndTime'),
          towingDateType: getOriginalFieldValue('jobDetails.towingDateType'),
        },
        automobileAndTouringClubFinland: {
          delayedTowing: getOriginalFieldValue('automobileAndTouringClubFinland.delayedTowing'),
          delayedTowingReason: getOriginalFieldValue('automobileAndTouringClubFinland.delayedTowingReason'),
        },
      } as TowingOrder)
    : towingOrder

  const initialEditTowingOrderFormValues = getInitialValuesQuery(source)

  useEffect(() => {
    const body = document.querySelector('body')
    if (body) {
      body.scrollTo(0, 0)
    }
  }, [])

  const [editOrderSchedule, { loading }] = useMutation<EditOrderScheduleResponse>(EDIT_ORDER_SCHEDULE, {
    onCompleted(editOrderScheduleResponse) {
      if (editOrderScheduleResponse.editOrderSchedule.__typename === 'EditOrderScheduleSuccess') {
        setSuccessNotification(
          //"Towing order saved", ""
          formatMessage(messages.editTowingOrderSuccessTitle),
          formatMessage(messages.editTowingOrderSuccessMessage)
        )
        console.log('success', editOrderScheduleResponse.editOrderSchedule.towingOrder)
        return true
      } else {
        setErrorNotifications({ data: editOrderScheduleResponse.editOrderSchedule })
        return false
      }
    },
    onError(error) {
      console.log('testi', error)

      setFatalErrorNotification(null)
      return false
    },
  })

  // resolve whether to update database from modal or transfer editted values to EditPage
  const queryOrTransfer = useCallback(
    async (values: ChangeTimeModalFormValues) => {
      if (!values.jobDetails.delayedTowingReason || values.jobDetails.delayedTowingReason === '') {
        setInfoNotification('Tiedot puutteelliset', 'Valitse syy')
        return
      }
      if (isVisibleTimeInput && !isCorrectTimeFormat(values.jobDetails.startTime.time)) {
        setInfoNotification('Aika virheellinen', 'Syötä aika muodossa HH:mm')
        return
      }

      if (
        isVisibleTimeInput &&
        values.jobDetails.towingDateType === 'span' &&
        !isCorrectTimeFormat(values.jobDetails.endTime.time)
      ) {
        setInfoNotification('Aika viimeistään virheellinen', 'Syötä aika muodossa HH:mm')
        return
      }

      const reasonMatch = delayedTowingReasons.find(reason => {
        return reason.value === values.jobDetails.delayedTowingReason
      })
      const matchValue = reasonMatch && reasonMatch.delayedTowing === '1' ? true : false
      const matchReason = reasonMatch ? reasonMatch.value : ''

      const inputVariables: EditOrderScheduleMutationVariables = toMutationVariables(
        values.id,
        values.jobDetails.startTime.date,
        values.jobDetails.startTime.time,
        matchValue,
        matchReason,
        values.jobDetails.endTime.date,
        values.jobDetails.endTime.time,
        values.jobDetails.towingDateType
      )

      const response = await editOrderSchedule({ variables: inputVariables })

      //If the query was successful, update form values or run callbacks (else do nada)
      if (response.data && response.data.editOrderSchedule.__typename === 'EditOrderScheduleSuccess') {
        if (useQueryOnly && values.jobDetails) {
          // if both date, time and reason were changed -->
          if (!onChangeOnlyReason) {
            if (onClose) {
              onClose()
            }
            reFetch && reFetch()
          }
          // if only allow change of reason -->
          if (onChangeOnlyReason && !isVisibleTimeInput) {
            onChangeOnlyReason()
          }
        } else {
          //handle transfer of values back to the EditOrderPage Formik form. No database update in ModalWindow - Edit page must be saved for values to be stored in database
          if (setFieldValue && values.jobDetails) {
            setFieldValue('jobDetails.towingDate', values.jobDetails.startTime.date)
            setFieldValue('jobDetails.towingTime', values.jobDetails.startTime.time)
            setFieldValue('jobDetails.towingDateType', values.jobDetails.towingDateType)
            setFieldValue('automobileAndTouringClubFinland.delayedTowing', matchValue)
            setFieldValue('automobileAndTouringClubFinland.delayedTowingReason', values.jobDetails.delayedTowingReason)
            if (values.jobDetails.towingDateType === 'span') {
              setFieldValue('jobDetails.towingEndDate', values.jobDetails.endTime.date)
              setFieldValue('jobDetails.towingEndTime', values.jobDetails.endTime.time)
            }
            if (values.jobDetails.towingDateType === 'single') {
              setFieldValue('jobDetails.towingEndDate', '')
              setFieldValue('jobDetails.towingEndTime', '')
            }
          }
          if (onClose) {
            onClose()
          }
        }
      }
    },
    [
      useQueryOnly,
      onClose,
      reFetch,
      setFieldValue,
      onChangeOnlyReason,
      editOrderSchedule,
      delayedTowingReasons,
      isVisibleTimeInput,
    ]
  )

  const translatedOrderScheduleOptions = translateInputOptions(orderScheduleOptions, formatMessage)
  console.log('render modal')

  return (
    <FullscreenModal label={label} onClose={onClose} loading={loading}>
      {initialEditTowingOrderFormValues && (
        <Formik
          initialValues={initialEditTowingOrderFormValues}
          onSubmit={async (values: ChangeTimeModalFormValues) => await queryOrTransfer(values)}
          render={({ values, handleSubmit, setFieldValue }) => {
            const setCurrentTime = (target: string) => {
              const currentTime = new Date()
              setFieldValue(`${target}.time`, extractTime(currentTime))
              setFieldValue(`${target}.date`, currentTime)
            }

            return (
              <>
                <AutoliittoSeparatorLine marginTop="1.5rem" marginBottom="1.5rem" />
                {isVisibleTimeInput && (
                  <Column justify="flex-start">
                    <RadioButtonGroup
                      label={''}
                      name="jobDetails.towingDateType"
                      options={translatedOrderScheduleOptions}
                      justifyContent="flex-start"
                    ></RadioButtonGroup>
                  </Column>
                )}
                {isVisibleTimeInput && values.jobDetails.towingDateType === 'single' ? (
                  <Section
                    justify="text"
                    columns={2}
                    marginMobile="1rem 0 1rem 0"
                    height="auto"
                    margin="1rem 0 1.5rem 0"
                  >
                    <SectionColumn>
                      <DateTimeInput
                        name="jobDetails.startTime"
                        label={'Aika'}
                        showCurrentTimeBtn
                        customOnChange={() => {
                          setCurrentTime('jobDetails.startTime')
                        }}
                        required
                      />
                    </SectionColumn>
                    <SectionColumn />
                  </Section>
                ) : null}
                {isVisibleTimeInput && values.jobDetails.towingDateType === 'span' ? (
                  <Section
                    justify="text"
                    columns={2}
                    marginMobile="1rem 0 1rem 0"
                    height="auto"
                    margin="1rem 0 1.5rem 0"
                  >
                    <SectionColumn>
                      <DateTimeInputWrapper>
                        <HalfDateInput name="jobDetails.startTime.date" label={'Pvm alkaen'} required />
                        &nbsp;
                        <Divider alignSelfEnd />
                        &nbsp;
                        <HalfDateInput name="jobDetails.endTime.date" label={'Pvm viimeistään'} required />
                      </DateTimeInputWrapper>
                    </SectionColumn>
                    <SectionColumn>
                      <DateTimeInputWrapper>
                        <HalfTimeInput name="jobDetails.startTime.time" label={'Alkaen klo'} required />
                        &nbsp;
                        <Divider alignSelfEnd />
                        &nbsp;
                        <HalfTimeInput name="jobDetails.endTime.time" label={'Viimeistään klo'} required />
                      </DateTimeInputWrapper>
                    </SectionColumn>
                  </Section>
                ) : null}
                <Section columns={1} height="auto" gap="0.25rem">
                  <SectionColumn>
                    <Label>{buttonSectionHeader}</Label>
                  </SectionColumn>
                  <DateSectionColumn>
                    <RadioButtonGroup
                      label={''}
                      name="jobDetails.delayedTowingReason"
                      options={delayedTowingOptions}
                      justifyContent="flex-start"
                    ></RadioButtonGroup>
                  </DateSectionColumn>
                </Section>
                <ButtonContainer floatAtBottomNoMenu={true}>
                  <Button label="Tallenna uusi aika" size="l" category="save" maxWidth="100%" onClick={handleSubmit} />
                </ButtonContainer>
              </>
            )
          }}
        />
      )}
    </FullscreenModal>
  )
}

export const ALTimeChangeModal = injectIntl(ALTimeChangeModalIntl)
