import gql from 'graphql-tag'

export const GET_CURRENT_USER = gql`
  query {
    user {
      __typename
      ... on UnauthorizedError {
        error {
          code
          message
          details
          context
        }
      }
      ... on GetUserOutputSuccess {
        user {
          companyId
          userId
          role
          username
          realName
          mfa {
            status
            active
            rememberMeForDays
            phone
          }
        }
      }
    }
  }
`
