import gql from 'graphql-tag'

export const ADD_TOWING_ORDER_MUTATION = gql`
  mutation AddTowingOrder($input: AddTowingOrderInput!) {
    addTowingOrder(input: $input) {
      ... on AddTowingRecordSuccess {
        towingRecord {
          id
        }
      }
      ... on AddTowingOrderSuccess {
        towingOrder {
          id
        }
      }
      ... on UnauthorizedError {
        error {
          code
          message
        }
      }
      ... on InvalidVisibilitySettingsError {
        error {
          code
          message
        }
      }
      ... on InvalidParameterError {
        error {
          code
          message
        }
      }
    }
  }
`
