import React, { useContext } from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import Loader from 'react-loader-spinner'
import styled from 'styled-components'

import { getTowingRecordActions } from '../../../common/AccessControl'
import { towingRecordServiceTypeOptions } from '../../../common/options'
import { TypeIdentifier } from '../../../common/types'
import { Button } from '../../../components/Button'
import { getStatus } from '../../../components/JobStatus'
import { getDeliverable, getJobTypeDescription } from '../../../components/JobTypeDescription'
import { Text } from '../../../components/layout'
import { BigButtonSection } from '../../../components/layout/buttonlayout'
import { useHistoryPush } from '../../../components/router'
import { Styling } from '../../../components/Styling'
import { TopNavigation } from '../../../components/TopNavigation/TopNavigation'
import { SummaryHeader } from '../../../components/TowingCommon/SummaryHeader'
import { trackedSosStatuses, trackedStatuses } from '../../../components/TrackedJobs/types'
import { StateStore } from '../../../containers/StoreProvider'
import { getStationByIdFromSettings } from '../../Parameters/station'
import { getOperatorLabelFromId } from '../../Parameters/user'
import { createRouteMenuConfig, finishRouteAddressRequired } from '../components/routes/createRouteMenuConfig'
import { RoutesMenu } from '../components/routes/RoutesMenu'
import { EditRouteSubforms } from '../components/routes/types'
import { SummaryBody } from '../components/SummaryBody'
import { TowingRecord } from './types'
import Maybe from 'graphql/tsutils/Maybe'
import { toALScalarTimestamp } from '../components/routes/subforms/ArrivalRoute/mutation/toMutationVariables'
import { useGetCurrentTimeDifference } from '../../../util/useGetCurrentTimeDifference'

const SOS_GPS_EMIT_DELAY = 60

const Tracking = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
`

const TrackingLoader = styled(Loader)`
  color: ${props => (props.color ? props.theme.colors[props.color] : props.theme.color.black)}
  margin-top: 3px;
  margin-right: 5px;
`
interface Props {
  towingRecord: TowingRecord
  onEditAction: () => void
  onSelectSubform: (id: EditRouteSubforms, done: boolean) => void
  onRefetch?: () => void
  onVehicleInfoLinkClick: () => void
  lockedFor: Maybe<string>
  lockRoutes: boolean
  refetchData?: () => void
  imagesCount: number
}

const getSlug = (towingRecord: TowingRecord): string => {
  const completeStatuses = ['payment_undefined', 'aggregate', 'complete', 'billing']

  return towingRecord && towingRecord.status && completeStatuses.includes(towingRecord.status) ? 'completed' : ''
}

function getJobStartedDate(towingRecord: TowingRecord) {
  const result =
    towingRecord.jobStartedAt && towingRecord.jobStartedAt.date instanceof Date ? towingRecord.jobStartedAt : null

  if (!result) return null

  const dateTime = toALScalarTimestamp(result)

  if (!dateTime.date || !dateTime.time) return null

  return new Date(dateTime.date.value + ' ' + dateTime.time.value)
}

const ViewTowingRecordContentIntl: React.FunctionComponent<Props & InjectedIntlProps> = ({
  intl,
  onEditAction,
  towingRecord,
  onSelectSubform,
  onVehicleInfoLinkClick,
  lockedFor,
  refetchData,
  imagesCount
}) => {
  const { formatMessage } = intl
  const { state } = useContext(StateStore)
  const jobStarted = getJobStartedDate(towingRecord)

  const { difference } = useGetCurrentTimeDifference(jobStarted, {
    onUpdate: (value, timerId) => {
      if (value >= SOS_GPS_EMIT_DELAY && timerId) clearInterval(timerId)
    },
  })

  const broadcastGpsCoordinates = difference >= SOS_GPS_EMIT_DELAY

  const useExtendedRoutes = state.settings ? state.settings.modules.useExtendedRoutes : false

  const routeMenuConfig = createRouteMenuConfig(
    towingRecord.routes,
    finishRouteAddressRequired(towingRecord.jobDetails.serviceType, towingRecord.type),
    towingRecord.type,
    useExtendedRoutes
  )

  const slug: string = getSlug(towingRecord)
  const { useJobButtons, editRoutes } = getTowingRecordActions(state.currentUser, towingRecord)

  const historyPush = useHistoryPush()

  return (
    <>
      <TopNavigation
        editAction={onEditAction}
        slug={slug}
        editAddress={`/towing-record/${towingRecord.id}/edit`}
        disableEdit={!!lockedFor}
      />
      <SummaryHeader
        jobTypeDescription={getJobTypeDescription(
          intl,
          towingRecord.jobDetails.serviceType,
          getStationByIdFromSettings(towingRecord.stationId, state.settings),
          towingRecord.label,
          towingRecord.typeIdentifier,
          getDeliverable(towingRecord.sosServiceOrder)
        )}
        status={getStatus(
          intl,
          towingRecord.status,
          getOperatorLabelFromId(
            towingRecord.operatorId,
            state.currentUser,
            state.settings ? state.settings.operators : null
          )
        )}
        type={formatMessage({ id: towingRecordServiceTypeOptions.options[towingRecord.jobDetails.serviceType] })}
        station={getStationByIdFromSettings(towingRecord.stationId, state.settings)}
        vehicleDetails={towingRecord.vehicleDetails}
        towingDate={towingRecord.jobDetails.towingDate}
      />
      {((towingRecord.typeIdentifier === TypeIdentifier.sos && trackedSosStatuses.includes(towingRecord.jobStatus)) ||
        (towingRecord.typeIdentifier === TypeIdentifier.autoliitto &&
          trackedStatuses.includes(towingRecord.jobStatus))) && (
        <>
          {towingRecord.typeIdentifier === TypeIdentifier.autoliitto && (
            <Tracking>
              <TrackingLoader type="Rings" color="grey" width={25} height={25} />
              <Text>Sijaintia lähetetään Autoliitolle</Text>
            </Tracking>
          )}
          {towingRecord.typeIdentifier === TypeIdentifier.sos && (
            <Tracking>
              <TrackingLoader type="Rings" color="grey" width={25} height={25} />
              {broadcastGpsCoordinates ? (
                <Text>Sijaintia lähetetään SOS:ille</Text>
              ) : (
                <Text>Sijainnin lähetys SOS:ille aloitetaan {SOS_GPS_EMIT_DELAY - difference} sekunnin kuluttua</Text>
              )}
            </Tracking>
          )}
        </>
      )}
     
      <RoutesMenu
        onClick={onSelectSubform}
        routes={routeMenuConfig}
        towingRecord={towingRecord}
        user={state.currentUser}
        canEditRoutes={editRoutes}
        useExtendedRoutes={useExtendedRoutes}
      />

      {useJobButtons && !lockedFor && (
        <Styling padding="0 2rem" paddingMobile="0 0.5rem">
          <BigButtonSection>
            <Button
              category="on"
              label="Lopeta työ"
              onClick={() => {
                onSelectSubform('end-towing-record', false)
              }}
              size="m"
              fontWeight="normal"
              maxWidth="100%"
            />
            <Button
              category="on"
              label="Siirry allekirjoitukseen"
              mobileLabel="Allekirjoitus"
              size="m"
              fontWeight="normal"
              maxWidth="100%"
              onClick={() => historyPush(`/towing-record/${towingRecord.id}/edit/payment`)}
            />
          </BigButtonSection>
        </Styling>
      )}
      <SummaryBody
        towingRecord={towingRecord}
        onVehicleInfoLinkClick={onVehicleInfoLinkClick}
        vehicleInfoCheck={towingRecord.vehicleInfoCheck}
        refetchData={refetchData}
        imagesCount={imagesCount}
      />
    </>
  )
}

export const ViewTowingRecordContent = injectIntl(ViewTowingRecordContentIntl)
