import React, { useState } from 'react'
import {
  FieldButtonRow,
  FormPadder,
  FormSubGroup,
  FormSubGroupHeader,
  FormSubGroupHeading,
  FormSubGroupsList,
  FormSubGroupWrapper,
  Heading2,
  Section,
  SectionColumn,
} from '../../../components/layout'
import { TextInput } from '../../../components/layout/form/input'
import { SearchVehicleInfo } from '../../../components/SearchVehicleInfo'
import { VehicleInfo } from '../../../components/VehicleSearchModal/types'
import { Signature } from '../../../components/Signature'
import { TowingRecordItems } from '../../../components/TowingRecordItems'
import { getItemsTotal } from '../../../components/TowingRecordItems/Totals'
import { TowingRecordBilling } from '../components/billing/TowingRecordBilling'
import { populateBillingFieldsByDebtorType } from '../components/billing/TowingRecordBilling/populateBillingFieldsByDebtorType'
import { AddTowingRecordFormValues } from './types'
import { CustomerFormFields } from '../../Customer/CustomerFormFields'
import { initialOrderer } from '../../Customer/CustomerFormFields/customerToOrderer'
import { CustomerFormValues } from '../../Customer/types'
import { TowingRecordSettings } from '../components/settings/form/TowingRecordSettings'
import { SignatureCanvasProps } from '../../../components/Signature/useSignatureCanvasRef'
import { OwnersHoldersTrafi } from '../../../components/VehicleDetails/types'
import { setOwnersHoldersData } from '../../../components/VehicleDetails/functions'

interface TowingRecordFormFields {
  values: AddTowingRecordFormValues
  setValues: (values: any) => void
  translatedTypesOptions: object[]
  setValue: (field: string, value: any) => void
  setOrderer: (orderer: CustomerFormValues) => void
  setShowCustomerEditForm: (value: boolean) => void
  signaturePadRef: React.RefObject<SignatureCanvasProps>
  setOrdererFromTrafi: (customer: CustomerFormValues) => void
}

export const TowingRecordFormFields: React.FunctionComponent<TowingRecordFormFields> = ({
  setValue,
  setValues,
  values,
  setShowCustomerEditForm,
  setOrderer,
  signaturePadRef,
  setOrdererFromTrafi
}) => {
  const [ownersHolders, setOwnersHolders] = useState<OwnersHoldersTrafi[]>([])
  const handleVehicleResults = (res: VehicleInfo) => {
    if (res) {
      setOwnersHoldersData(res, setOwnersHolders)
      values.vehicleDetails.registrationNumber = res.vehicle.registrationNumber
      values.vehicleDetails.insuranceCompany = res.insurance.insuranceCompany
      values.vehicleDetails.makeAndModel = res.vehicle.makeAndModel
      setValues(values)
    }
  }

  const handleChangeBillingDebtorType = (debtorType: string) => {
    setValues(populateBillingFieldsByDebtorType(values, debtorType))
  }

  return (
    <>
      <FormPadder>
        <Heading2>{`Uusi kuormakirja`}</Heading2>
      </FormPadder>
      <FormSubGroupsList>
        <TowingRecordSettings />

        <FormSubGroupWrapper>
          <FormSubGroupHeader>
            <FormSubGroupHeading>Ajoneuvo</FormSubGroupHeading>
          </FormSubGroupHeader>
          <FormSubGroup>
            <Section>
              <SectionColumn>
                <FieldButtonRow sameBorderRadius>
                  <TextInput label="Rekisteritunnus" name="vehicleDetails.registrationNumber" required />
                  <SearchVehicleInfo
                    buttonText="Vie kuormakirjalle"
                    handleResults={handleVehicleResults}
                    searchString={values.vehicleDetails.registrationNumber}
                    vehicleInfoCheckId={null}
                  />
                </FieldButtonRow>
              </SectionColumn>
              <SectionColumn>
                <TextInput label="Merkki ja malli" name="vehicleDetails.makeAndModel" />
              </SectionColumn>
              <SectionColumn>
                <TextInput label="Vakuutusyhtiö" name="vehicleDetails.insuranceCompany" />
              </SectionColumn>
            </Section>
          </FormSubGroup>
        </FormSubGroupWrapper>
        <CustomerFormFields
          editCustomer={() => setShowCustomerEditForm(true)}
          orderer={values.orderer}
          clearOrderer={() => setOrderer(initialOrderer)}
          onUpdate={orderer => setOrderer(orderer)}
          ssn={
            values.orderer.personCustomer && values.orderer.personCustomer.ssn ? values.orderer.personCustomer.ssn : ''
          }
          setFieldValue={setValue}
          businessId={
            values.orderer.companyCustomer && values.orderer.companyCustomer.businessId
              ? values.orderer.companyCustomer.businessId
              : ''
          }
          infoSearchModalButtonLabel='Vie kuormakirjalle'
          ownersHolders={ownersHolders}
          setOrdererFromTrafi={setOrdererFromTrafi}
        />
        <FormSubGroupWrapper>
          <TowingRecordItems
            discountItem={values.discountItem}
            setValue={setValue}
            jobItems={values.jobItems}
            itemsIncludeVat={values.itemsIncludeVat}
          />
        </FormSubGroupWrapper>

        <FormSubGroupWrapper>
          <TowingRecordBilling
            itemsTotal={
              getItemsTotal({
                discountItem: values.discountItem,
                jobItems:values.jobItems,
                itemsIncludeVat: values.itemsIncludeVat
              })
            }
            paymentType={values.paymentType}
            onChange={handleChangeBillingDebtorType}
            disabled={values.status !== 'unfinished' && values.status !== 'billing'}
          />
        </FormSubGroupWrapper>
        <FormSubGroupWrapper>
          {values.signature && (
            <Signature
              signature={values.signature}
              setSignatureFields={() => undefined}
              sigPadRef={signaturePadRef}
              setSaveSignature={(value: boolean) => setValue('saveSignature', value)}
              orderer={values.orderer}
            />
          )}
        </FormSubGroupWrapper>
      </FormSubGroupsList>
    </>
  )
}
