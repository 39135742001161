import { cloneDeep } from 'lodash'
import { Maybe } from '../../../../common/types'
import { toTowingOrderInput } from '../../towingOrderInput/toTowingOrderInput'
import { AddTowingOrderFormValues } from '../types'
import { AddTowingOrderMutationVariables } from './types'

export const toMutationVariables = (
  inputValues: AddTowingOrderFormValues,
  operatorId: Maybe<number>
): AddTowingOrderMutationVariables => {
  const values = cloneDeep(inputValues)

  return {
    input: {
      towingOrder: toTowingOrderInput(values, operatorId),
    },
  }
}
