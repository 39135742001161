import React from 'react'
import styled, { css } from 'styled-components'

import { Maybe } from '../../common/types'

interface Props {
  phone: Maybe<string>
  size?: Size
}

type Size = 'big' | 'normal' | 'medium'

export const PhoneNumber: React.FunctionComponent<Props> = ({ phone, size }) => {
  if (!phone) return null
  return (
    <StyledHyperlink size={size} href={`tel:${phone}`} target="_blank" rel="nofollow noreferrer noopener">
      {`Puh. ${phone}`}
    </StyledHyperlink>
  )
}

interface StyledHyperlinkProps {
  size?: Size
}

const StyledHyperlink = styled.a<StyledHyperlinkProps>`
  font-size: ${(props) => (props.size && props.size === 'big' ? '1.5rem' : '1rem')};
  ${(props) =>
    props.size === 'medium' &&
    css`
      font-size: 1.25rem;
    `}
  text-decoration: underline;
  color: ${(props) => props.theme.colors.black};
`
