import { Maybe } from '../../../../../common/types'

interface Values
  extends Readonly<{
    orderer: Maybe<{
      personCustomer: Maybe<{
        firstName: Maybe<string>
        lastName: Maybe<string>
        email: Maybe<string>
      }>
      companyCustomer: Maybe<{
        companyName: Maybe<string>
        contactPersonEmail: Maybe<string>
      }>
    }>
    billing: {
      paymentDetails: {
        debtorName: Maybe<string>
        receiptEmail: Maybe<string>
      }
    }
    vehicleDetails: {
      holder: Maybe<string>
      owner: Maybe<string>
    }
  }> {}

export const populateBillingFieldsByDebtorType = (values: Values, debtorType: string) => {
  if (debtorType === 'orderer') {
    const personCustomer = values.orderer ? values.orderer.personCustomer : null
    const companyCustomer = values.orderer ? values.orderer.companyCustomer : null

    if (personCustomer) {
      values.billing.paymentDetails.debtorName = `${personCustomer.firstName} ${personCustomer.lastName}`
      values.billing.paymentDetails.receiptEmail = personCustomer.email
    }
    if (companyCustomer) {
      values.billing.paymentDetails.debtorName = companyCustomer.companyName
      values.billing.paymentDetails.receiptEmail = companyCustomer.contactPersonEmail
    }
  }

  if (debtorType === 'holder') {
    values.billing.paymentDetails.debtorName = values.vehicleDetails.holder
  }

  if (debtorType === 'owner') {
    values.billing.paymentDetails.debtorName = values.vehicleDetails.owner
  }

  if (debtorType === 'other') {
    values.billing.paymentDetails.debtorName = ''
  }
  return values
}
