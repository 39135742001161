import React from 'react'
import styled from 'styled-components'

import { Icon } from '../icons/Icon'

interface EditItemProps {
  onClick?: () => void
}

export const EditItem: React.FunctionComponent<EditItemProps> = ({ onClick }) => {
  return (
    <NavigateToWrapper onClick={onClick && onClick}>
      <Icon size="small" icon={'edit'} />
    </NavigateToWrapper>
  )
}

const NavigateToWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
`
