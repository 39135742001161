import styled from 'styled-components'

interface Props {
  flexDirection?: string
  shrink?: boolean
}

export const FieldContainer = styled.div<Props>`
  display: flex;
  position: relative;
  width: ${props => (props.shrink ? '50%' : '100%')};
  flex-direction: ${props => (props.flexDirection ? props.flexDirection : 'column')};
  padding: 0 0 0 0;
`
