import { ShiftPageProps, WaitingShiftPageViews, EventTypeOption } from '../types'
import React, { useState } from 'react'
import { BigButtonSection } from '../../../components/layout'
import { Button } from '../../../components/Button'
import { AddEventPage } from '../AddEvent'
import { ShiftTable } from '../../../components/ShiftTable'
import { ShiftHeader, ShiftHeading } from '../../../components/ShiftTable/layout'

export const WaitingPage: React.FunctionComponent<ShiftPageProps> = ({ currentShift, refetch }) => {
  const [view, setView] = useState<WaitingShiftPageViews>(WaitingShiftPageViews.default)
  return (
    <>
      {view === WaitingShiftPageViews.default && (
        <>
          <ShiftHeader color="red300">
            <ShiftHeading label="Odotus" />
            <BigButtonSection>
              <Button
                category="new"
                label="Lopeta odotus"
                onClick={() => setView(WaitingShiftPageViews.wait_end_form)}
                size="m"
                fontWeight="normal"
              />
            </BigButtonSection>
          </ShiftHeader>
          <ShiftTable shift={currentShift} />
        </>
      )}

      {view === WaitingShiftPageViews.wait_end_form && (
        <AddEventPage
          type={EventTypeOption.wait_end}
          onClose={() => setView(WaitingShiftPageViews.default)}
          shift={currentShift}
          refetch={refetch}
        />
      )}
    </>
  )
}
