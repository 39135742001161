import { CustomerType, DeliveryMethod, NotificationMethod } from '../../common/types'
import { OwnersHolder } from '../../components/VehicleSearchModal/types'
import { initialCompanyCustomer, initialPersonCustomer } from './CustomerFormFields/customerToOrderer'
import { Customer, CustomerFormValues } from './types'

export function toCustomerInput(orderer: Customer): CustomerFormValues {
  if (orderer.__typename === 'PersonCustomer') {
    return {
      id: orderer.id,
      type: CustomerType.person,
      personCustomer: {
        identifier: orderer.identifier,
        firstName: orderer.firstName,
        lastName: orderer.lastName,
        ssn: orderer.ssn ? orderer.ssn : '',
        maskedSsn: orderer.maskedSsn ? orderer.maskedSsn : '',
        email: orderer.email ? orderer.email : '',
        phone: orderer.phone ? orderer.phone : '',
        address: orderer.address,
        additionalInfo: orderer.additionalInfo,
        city: orderer.city ? orderer.city : '',
        co: orderer.co,
        deliveryMethod: orderer.deliveryMethod,
        eInvoiceAddress: orderer.eInvoiceAddress,
        eInvoiceOperator: orderer.eInvoiceOperator,
        extraInfo: orderer.extraInfo,
        zipcode: orderer.zipcode ? orderer.zipcode : '',
        notificationMethod: orderer.notificationMethod,
        originatorApp: orderer.originatorApp,
      },
      companyCustomer: null,
    }
  }

  return {
    id: orderer.id,
    type: CustomerType.company,
    personCustomer: null,
    companyCustomer: {
      companyName: orderer.companyName,
      identifier: orderer.identifier,
      businessId: orderer.businessId ? orderer.businessId : '',
      contactPerson: orderer.contactPerson,
      contactPersonEmail: orderer.contactPersonEmail ? orderer.contactPersonEmail : '',
      phone: orderer.phone ? orderer.phone : '',
      address: orderer.address,
      additionalInfo: orderer.additionalInfo,
      city: orderer.city ? orderer.city : '',
      co: orderer.co,
      deliveryMethod: orderer.deliveryMethod,
      eInvoiceAddress: orderer.eInvoiceAddress,
      eInvoiceOperator: orderer.eInvoiceOperator,
      extraInfo: orderer.extraInfo,
      zipcode: orderer.zipcode ? orderer.zipcode : '',
      email: orderer.email ? orderer.email : '',
      notificationMethod: orderer.notificationMethod,
      originatorApp: orderer.originatorApp,
    },
  }
}

export function ownersHolderToCustomerInput(ownerHolder: OwnersHolder, discardFaultySsn: boolean): CustomerFormValues {
  if (ownerHolder.customer && ownerHolder.customer.type === 'person') {
    const nameParts = ownerHolder.customer.name.split(' ')
    const lastName = nameParts.shift()
    const firstName = nameParts.join(' ')
    return {
      id: null,
      type: CustomerType.person,
      ownerHolderId: ownerHolder.customer.id ? ownerHolder.customer.id : undefined,
      personCustomer: {
        identifier: '',
        firstName,
        lastName: lastName ? lastName : '',
        ssn: ownerHolder.customer.identifier && !discardFaultySsn ? ownerHolder.customer.identifier : '',
        email: '',
        phone: '',
        address: ownerHolder.customer.address ? ownerHolder.customer.address : '',
        additionalInfo: '',
        city: ownerHolder.customer.city ? ownerHolder.customer.city : '',
        co: '',
        deliveryMethod: DeliveryMethod.no_delivery,
        eInvoiceAddress: '',
        eInvoiceOperator: '',
        extraInfo: '',
        zipcode: ownerHolder.customer.zipcode ? ownerHolder.customer.zipcode : '',
        notificationMethod: 'disabled' as NotificationMethod,
        originatorApp: null,
      },
      companyCustomer: initialCompanyCustomer,
    }
  }

  return {
    id: null,
    type: CustomerType.company,
    personCustomer: initialPersonCustomer,
    companyCustomer: {
      companyName: ownerHolder.customer ? ownerHolder.customer.name : '',
      identifier: '',
      businessId: ownerHolder.customer ? ownerHolder.customer.identifier : '',
      contactPerson: '',
      contactPersonEmail: '',
      phone: '',
      address: ownerHolder.customer ? ownerHolder.customer.address : '',
      additionalInfo: '',
      city: ownerHolder.customer ? ownerHolder.customer.city : '',
      co: '',
      deliveryMethod: DeliveryMethod.no_delivery,
      eInvoiceAddress: '',
      eInvoiceOperator: '',
      extraInfo: '',
      zipcode: ownerHolder.customer ? ownerHolder.customer.zipcode : '',
      email: '',
      notificationMethod: 'disabled' as NotificationMethod,
      originatorApp: null,
    },
  }
}
