import React from 'react'

export const Currency: React.FC<{}> = ({ children }) => {
  let numberValue = children as number

  if (isNaN(numberValue - 0)) {
    console.warn('You have given Currency component a non number child value. Please give a number')
    numberValue = 0
  }

  return <>{`${numberValue.toFixed(2).replace('.', ',')} €`}</>
}
