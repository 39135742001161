import { Maybe } from '../../../common/types'
import { TowingDateType } from '../../../containers/TowingOrderPage/types'
import { Time } from '../../../functions/graphqlApi/types/scalarTypes'
import { toScalarShortDate, toScalarTime } from '../../../util/convertToScalar'
import { EditOrderScheduleMutationVariables } from './types'

export const toMutationVariables = (
  id: number,
  towingDate: Maybe<Date>,
  towingTime: Maybe<Time>,
  delayedTowing: boolean,
  delayedTowingReason: string,
  towingEndDate: Maybe<Date>,
  towingEndTime: Maybe<Time>,
  towingDateType: TowingDateType

): EditOrderScheduleMutationVariables => {
  return {
    input: {
      id: id,
      towingDate: towingDate ? toScalarShortDate(towingDate) : null,
      towingTime: towingTime ? toScalarTime(towingTime) : null,
      delayedTowing: delayedTowing,
      delayedTowingReason: delayedTowingReason,
      towingEndDate: towingEndDate ? toScalarShortDate(towingEndDate) : null,
      towingEndTime: towingEndTime ? toScalarTime(towingEndTime) : null,
      towingDateType: towingDateType
    },
  }
}
