import React, { useCallback} from "react"
import styled from 'styled-components'
import { ErrorNotification } from "../../notification/errorNotification"

interface Props {
  title: string
  message: string
  refetch?: () => void
}

export const WarningBanner: React.FunctionComponent<Props> = ({
  title,
  message,
  refetch
}) => {

  // just a wee hack to show that reload has been pressed
  const refresh = useCallback(function(){
    if(refetch){
      refetch();
    }
  }, [refetch])

  return (
    <WarningContainer onClick={refresh}>
      <ErrorNotification title={title} message={message}></ErrorNotification>
    </WarningContainer>
  )
}

interface WarningContainerProps {
  background?: string
}

export const WarningContainer = styled.div<WarningContainerProps>`
  top: 0;
  left: 0;
  position: sticky;
  width: 100%;
  z-index: 9;
`
