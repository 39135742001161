import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  cash: {
    id: 'towingRecord.billing.paymentType.cash',
    defaultMessage: 'cash',
  },
  card: {
    id: 'towingRecord.billing.paymentType.card',
    defaultMessage: 'card',
  },
  person: {
    id: 'towingRecord.billing.paymentType.person',
    defaultMessage: 'person',
  },
  company: {
    id: 'towingRecord.billing.paymentType.company',
    defaultMessage: 'company',
  },
  insurance_company: {
    id: 'towingRecord.billing.paymentType.insurance_company',
    defaultMessage: 'insurance_company',
  },
  aggregate: {
    id: 'towingRecord.billing.paymentType.aggregate',
    defaultMessage: 'aggregate',
  },
  define_later: {
    id: 'towingRecord.billing.paymentType.define_later',
    defaultMessage: 'define_later',
  },
  settlement: {
    id: 'towingRecord.billing.paymentType.settlement',
    defaultMessage: 'settlement',
  },
  autoliitto: {
    id: 'towingRecord.billing.paymentType.autoliitto',
    defaultMessage: 'autoliitto',
  },
  sos: {
    id: 'towingRecord.billing.paymentType.sos',
    defaultMessage: 'sos',
  },
})
