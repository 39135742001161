import { addMinutes, format } from 'date-fns'
import { FieldProps, getIn } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Error, FieldContainer, FormikField as Field, Label } from '../../style'

interface Props {
  id: string
  timeSelectorLabel?: string
  timeFieldLabel?: string
  name: string
  disabled?: boolean
  placeholder?: string
  customOnChange?: () => void
  className?: string
}

export const TimeSelectorField: React.FunctionComponent<Props & FieldProps> = ({
  id,
  customOnChange,
  field,
  form,
  timeSelectorLabel,
  timeFieldLabel,
  placeholder,
  disabled = false,
  ...rest
}) => {
  const [value, setValue] = useState<string>(field.value || '')
  const [fieldVisible, setFieldVisible] = useState<boolean>(field.value != null)
  const [selectedTimeSelector, setTimeSelector] = useState<number | null>(null)

  useEffect(() => {
    setValue(field.value)
  }, [field.value])

  const handleChange = useCallback(
    event => {
      const targetValue = event.target.value
      setValue(targetValue)

      if (customOnChange) {
        customOnChange()
      }
    },
    [customOnChange]
  )

  const handleBlur = useCallback(
    event => {
      let trimmedValue = event.target.value.trim()

      const trailingMinuteFillerRegex = /^(([0-9])|([0-1][0-9])|([2][0-3])):([0-6])$/
      if (trimmedValue.match(trailingMinuteFillerRegex)) {
        trimmedValue = `${trimmedValue.substring(0, 4)}0`
      }

      const zeroMinuteFillerRegex = /^(([0-9])|([0-1][0-9])|([2][0-3])):?$/
      if (trimmedValue.match(zeroMinuteFillerRegex)) {
        trimmedValue = `${trimmedValue.substring(0, 2)}:00`
      }

      const leadingZeroFillRegex = /^([0-9]):/
      if (trimmedValue.match(leadingZeroFillRegex)) {
        trimmedValue = `0${trimmedValue}`
      }

      if (trimmedValue.length === 3) {
        trimmedValue = `0${trimmedValue.substring(0, 1)}:${trimmedValue.substring(1, 3)}`
      }

      if (trimmedValue.length === 4) {
        trimmedValue = `${trimmedValue.substring(0, 2)}:${trimmedValue.substring(2, 4)}`
      }

      form.setFieldValue(field.name, trimmedValue === '' ? null : trimmedValue)

      form.setFieldTouched(field.name, true)
    },
    [form, field]
  )

  const setTime = useCallback(
    (minutes: number) => {
      const time = addMinutes(new Date(), minutes)
      const value = format(time, 'HH:mm')
      form.setFieldValue(field.name, value)
      form.setFieldTouched(field.name, true)
      setTimeSelector(minutes)
      setFieldVisible(true)
      const event = { target: { value: value } }
      handleChange(event)
    },
    [form, field, handleChange]
  )

  const fieldError = getIn(form.errors, field.name)
  const hasErrors = Boolean(fieldError) && Boolean(getIn(form.touched, field.name))

  return (
    <StyledFieldContainer {...rest}>
      <SubFieldContainer>
        {timeSelectorLabel && <Label>{timeSelectorLabel}</Label>}
        <TimeSelectors>
          <TimeSelector selected={selectedTimeSelector === 15} type="button" onClick={() => setTime(15)}>
            <Min>15</Min>
            <Min>min</Min>
          </TimeSelector>
          <TimeSelector selected={selectedTimeSelector === 30} type="button" onClick={() => setTime(30)}>
            <Min>30</Min>
            <Min>min</Min>
          </TimeSelector>
          <TimeSelector selected={selectedTimeSelector === 45} type="button" onClick={() => setTime(45)}>
            <Min>45</Min>
            <Min>min</Min>
          </TimeSelector>
          <TimeSelector selected={selectedTimeSelector === 60} type="button" onClick={() => setTime(60)}>
            <Min>60</Min>
            <Min>min</Min>
          </TimeSelector>
        </TimeSelectors>
      </SubFieldContainer>

      {fieldVisible && (
        <SubFieldContainer>
          {timeFieldLabel && <Label>{timeFieldLabel}</Label>}
          <Field
            id={id}
            type="text"
            name={field.name}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="off"
          />
        </SubFieldContainer>
      )}

      {hasErrors && <Error className="error-tooltip">{fieldError}</Error>}
    </StyledFieldContainer>
  )
}

const StyledFieldContainer = styled(FieldContainer)`
  flex-direction: row;
  flex-wrap: wrap;
`
const SubFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`

const TimeSelectors = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 1rem;
`

interface TimeSelectorProps {
  selected: boolean | null
}

const TimeSelector = styled.button<TimeSelectorProps>`
  justify-content: center;
  margin-right: 0.5rem;
  text-align: center;
  padding: 0.25rem 0.5rem;
  border-radius: ${props => props.theme.radius.medium};
  border: 1px solid ${props => props.theme.colors.grey300};
  height: 3rem;
  width: 3rem;
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  &:active,
  &:focus {
    background-color: ${props => props.theme.colors.green500};
    transition: all 0.5s;
    outline: none;
  }
  ${props =>
    props.selected &&
    css`
      background-color: ${props.theme.colors.green500};
    `}
`

const Min = styled.span`
  justify-self: flex-end;
  font-size: 1rem;
  line-height: 1rem;
  align-self: center;
`
