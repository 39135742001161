import { useContext } from 'react'
import { __RouterContext, RouteComponentProps } from 'react-router-dom'
import { toast } from 'react-toastify'

export function useRouter<TParams = {}>() {
  return useContext(__RouterContext) as RouteComponentProps<TParams>
}

export function useHistoryPush() {
  const { history } = useRouter()

  const push = (path: string, clearNotifications: boolean = true) => {
    if (clearNotifications) {
      toast.dismiss()
    }
    history.push(path)
  }
  return push
}
