import React from 'react'
import { UserRole } from '../../../../common/types'
import { BigButtonSection } from '../../../../components/layout'
import { Styling } from '../../../../components/Styling'
import { DutyOfficerButtons } from './DutyOfficer'
import { MainUserButtons } from './MainUser'
import { SaveButton } from './SaveButton'
import { TowingOrderSaveButtonsProps } from './types'

export const TowingOrderSaveButtons: React.FunctionComponent<TowingOrderSaveButtonsProps> = (
  props: TowingOrderSaveButtonsProps
) => {
  const { userId, handleSubmit, userRole } = props

  if (userRole === UserRole.main_user) {
    return <MainUserButtons {...props} />
  }

  if (userRole === UserRole.duty_officer) {
    return <DutyOfficerButtons {...props} />
  }

  return (
    <BigButtonSection justifyContent="end">
      <>
        <SaveButton onClick={() => handleSubmit(false, null, 'open')}>
          <Styling fontSize="m">Tallenna</Styling>
          <Styling fontSize="m">avoimeksi kaikille</Styling>
        </SaveButton>
        <SaveButton onClick={() => handleSubmit(false, userId, 'assigned')}>
          <Styling fontSize="m">Tallenna ja</Styling>
          <Styling fontSize="m">kuittaa itsellesi</Styling>
        </SaveButton>
      </>
    </BigButtonSection>
  )
}
