import { FieldProps, getIn } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { Error, FieldContainer, FormikField as Field, Label } from '../../style'
import { BrowserLocationResponse } from '../../../../ReverseGeocodeButton'
import { REVERSE_GEOCODE } from '../../../../ReverseGeocodeButton/queries'
import { useApolloClient } from '@apollo/react-hooks'
import { DIRECTIONS } from './queries'
import { ArrivalRouteArgs } from '../../../../../containers/TowingRecordPage/components/routes/subforms/StartRoute/types'
import { FieldButtonRow } from '../../..'
import { Button } from '../../../../Button'
import { format } from 'date-fns'
import { Time } from '../../../../../common/types'
import styled, { css } from 'styled-components'
import { geolocationOptions } from '../../../../TrackedJobs/types'

interface Props {
  id: string
  label?: string
  name: string
  disabled?: boolean
  required?: boolean
  placeholder?: string
  customOnChange?: () => void
  className?: string
  isAutoliitto?: boolean
  calculateEta?: boolean
  arrivalRoute?: ArrivalRouteArgs
  setCalculateEta?: (loading: boolean) => void
  setTimeButton?: boolean
  onSetCurrentTime?: () => void
}

interface DirectionsResponse {
  data: any
}

const FullWidthField = styled(Field)`
  color: ${props => (props.color ? props.theme.colors[props.color] : props.theme.colors.black)};
  ${props =>
    props.disabled &&
    css`
      color: ${props => props.theme.colors.greyDisabled};
    `};
  width: 100%;
  min-width: 70px;
`

export const TimeField: React.FunctionComponent<Props & FieldProps> = ({
  id,
  customOnChange,
  field,
  form,
  label,
  placeholder,
  disabled = false,
  isAutoliitto,
  calculateEta,
  arrivalRoute,
  setCalculateEta,
  required,
  setTimeButton,
  onSetCurrentTime,
  ...rest
}) => {
  const [value, setValue] = useState<string>(field.value || '')
  const client = useApolloClient()

  useEffect(() => {
    setValue(field.value)
  }, [field.value])

  const handleChange = useCallback(
    event => {
      const targetValue = event.target.value

      setValue(targetValue)

      if (customOnChange) {
        customOnChange()
      }
    },
    [customOnChange]
  )

  const handleBlur = useCallback(
    event => {
      let trimmedValue = event.target.value.trim()

      trimmedValue = trimmedValue.replace('.', ':')

      const trailingMinuteFillerRegex = /^(([0-9])|([0-1][0-9])|([2][0-3])):([0-6])$/
      if (trimmedValue.match(trailingMinuteFillerRegex)) {
        trimmedValue = `${trimmedValue.substring(0, 4)}0`
      }

      const zeroMinuteFillerRegex = /^(([0-9])|([0-1][0-9])|([2][0-3])):?$/
      if (trimmedValue.match(zeroMinuteFillerRegex)) {
        trimmedValue = `${trimmedValue.substring(0, 2)}:00`
      }

      const leadingZeroFillRegex = /^([0-9]):/
      if (trimmedValue.match(leadingZeroFillRegex)) {
        trimmedValue = `0${trimmedValue}`
      }

      if (trimmedValue.length === 3) {
        trimmedValue = `0${trimmedValue.substring(0, 1)}:${trimmedValue.substring(1, 3)}`
      }

      if (trimmedValue.length === 4) {
        trimmedValue = `${trimmedValue.substring(0, 2)}:${trimmedValue.substring(2, 4)}`
      }

      form.setFieldValue(field.name, trimmedValue === '' ? '' : trimmedValue)

      form.setFieldTouched(field.name, true)
    },
    [form, field]
  )

  const getRouteTimeEstimate = useCallback(
    async (waypoints: any) => {
      const { data }: DirectionsResponse = await client.query({
        variables: { waypoints },
        query: DIRECTIONS,
      })

      if (data.directions.__typename === 'GoogleAPIGeolocationError') {
        console.log('Route calculation returned an error')
      }

      console.log('data', data)

      if (
        data &&
        data.directions &&
        data.directions.result &&
        data.directions.result.routes[0].legs[0].duration.value
      ) {
        const seconds = data.directions.result.routes[0].legs[0].duration.value
        const estimatedTimeSeconds = new Date().getSeconds() + seconds
        const estimatedTime = new Date().setSeconds(estimatedTimeSeconds)
        const eta = new Date(estimatedTime)
          .toLocaleTimeString('fi')
          .slice(0, -3)
          .replace('.', ':')
        const event = { target: { value: eta } }
        handleBlur(event)
        if (setCalculateEta) setCalculateEta(false)
        if (customOnChange) {
          customOnChange()
        }
      }
    },
    [client, customOnChange, handleBlur, setCalculateEta]
  )

  const getPosition = useCallback(async () => {
    window.navigator.geolocation.getCurrentPosition(
      async (position: { coords: { latitude: number; longitude: number } }) => {
        const { data }: BrowserLocationResponse = await client.query({
          variables: { location: { lat: position.coords.latitude, long: position.coords.longitude } },
          query: REVERSE_GEOCODE,
        })

        if (data.reverseGeocode.__typename === 'GoogleAPIGeolocationError') {
          console.error('getCurrentPosition returned with error')
        } else {
          const currentPosition = {
            address: data.reverseGeocode.result.address ? data.reverseGeocode.result.address : '',
            city: data.reverseGeocode.result.city ? data.reverseGeocode.result.city : '',
            lat: position.coords.latitude ? position.coords.latitude : null,
            long: position.coords.longitude ? position.coords.longitude : null,
          }
          const waypoints = {
            data: [currentPosition, arrivalRoute],
          }
          if (!currentPosition || !arrivalRoute) return
          getRouteTimeEstimate(waypoints)
        }
      },
      error => {
        console.error('TimeField::getPosition failed: ', error)
        // getPosition()
      },
      geolocationOptions
    )
  }, [getRouteTimeEstimate, arrivalRoute, client])

  const getCurrentPosition = useCallback(async () => {
    await getPosition()
  }, [getPosition])

  useEffect(() => {
    if (isAutoliitto && calculateEta) {
      getCurrentPosition()
    }
  }, [calculateEta, isAutoliitto, getCurrentPosition])

  const setCurrentTime = useCallback(() => {
    const value = (format(new Date(), 'HH:mm') as unknown) as Time
    handleBlur({ target: { value: value } })
    if (onSetCurrentTime) {
      onSetCurrentTime()
    }
  }, [handleBlur, onSetCurrentTime])

  const fieldError = getIn(form.errors, field.name)
  const hasErrors = Boolean(fieldError) && Boolean(getIn(form.touched, field.name))
  return (
    <FieldContainer {...rest}>
      {label && <Label required={required}>{label}</Label>}
      <FieldButtonRow sameBorderRadius>
        <FullWidthField
          id={id}
          type="text"
          name={field.name}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={handleChange}
          onBlur={handleBlur}
          autoComplete="off"
          width="100%"
        />
        {setTimeButton && !disabled && (
          <Button category="clock" label="" size="s" onClick={setCurrentTime} disabled={disabled} />
        )}
        {hasErrors && <Error className="error-tooltip">{fieldError}</Error>}
      </FieldButtonRow>
    </FieldContainer>
  )
}

// function waypointsToQueryVariables(waypoints: any) {
//   if (!waypoints) return null

//   return waypoints.map(waypoint => {
//     return {
//       address: waypoint.address,
//     }
//   })
// }
