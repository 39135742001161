import { Options } from '../../common/types'

// option values = translation message id

export const visibilityOptions: Options = {
  options: {
    open: 'towingOrder.visibility_open',
    restricted: 'towingOrder.visibility_restricted',
    assigned: 'towingOrder.visibility_assigned',
  },
}

export const serviceTypeOptions = [
  { label: 'Hinaus', value: 'towing' },
  { label: 'Tiepalvelu', value: 'road_service' },
  { label: 'Muu', value: 'other' },
  // { label: 'Akkupalvelu', value: 'battery_service' },
  // { label: 'Puhelinneuvonta', value: 'phone_service' },
]
