import { EDIT_TOWING_RECORD_QUERY, TowingRecordResponse } from './EditTowingRecordQuery'
import {
  GenericResultHandler,
  GenericResultHandlerDefinition,
} from '../../../components/responses/GenericResultHandler'

import { EditTowingRecordPage } from './EditTowingRecordPage'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import { setFatalErrorNotification } from '../../../components/responses/setErrorNotifications'
import { useQuery } from '@apollo/react-hooks'
import { TowingRecordPaymentType } from '../../../common/types'

interface Props {
  id?: string
  paymentType?: TowingRecordPaymentType
  scrollTo?: string
}

const QueryResultHandler = GenericResultHandler as GenericResultHandlerDefinition<TowingRecordResponse>

export const EditTowingRecord: React.FunctionComponent<RouteComponentProps<Props>> = ({
  match: {
    params: { id, scrollTo, paymentType },
  },
}) => {
  console.log('scrollTo', scrollTo)
  const towingRecordId = id ? parseInt(id, 10) : null
  const type = paymentType && ['cash', 'card'].indexOf(paymentType) !== -1 ? paymentType : null;

  const towingRecordResponse = useQuery<TowingRecordResponse>(EDIT_TOWING_RECORD_QUERY, {
    fetchPolicy: 'network-only',
    skip: !towingRecordId,
    variables: { id: towingRecordId },
    onError(err) {
      setFatalErrorNotification(err.message)
    },
  })

  return (
    <QueryResultHandler queryResult={towingRecordResponse} loading unauthorized>
      {data => {
        if (data && data.towingRecord.__typename === 'GetTowingRecordSuccess') {
          return (
            <EditTowingRecordPage towingRecord={data.towingRecord.towingRecord} scrollTo={scrollTo} paymentType={type} />
          )
        }
      }}
    </QueryResultHandler>
  )
}
