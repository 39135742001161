import { useEffect, useState, useCallback } from 'react'

export function useClientSize() {
  const isClient = typeof window === 'object'

  const getSize = useCallback(() => {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }
  }, [isClient])

  const [clientSize, setClientSize] = useState<ClientSize>(getSize())

  useEffect(() => {
    if (!isClient) return

    function handleResize() {
      setClientSize(getSize())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [getSize, isClient])

  return clientSize
}

interface ClientSize {
  width: number | undefined
  height: number | undefined
}
