import { useMutation } from '@apollo/react-hooks'
import { Formik, getIn } from 'formik'
import React, { useContext, useState } from 'react'
import { InjectedIntlProps, defineMessages, injectIntl } from 'react-intl'

import { ACS } from '../../../../../../common/AccessControl'
import { Maybe, TowingRecordJobStatus, TowingRecordType, TypeIdentifier } from '../../../../../../common/types'
import { Button } from '../../../../../../components/Button'
import { Geolocation } from '../../../../../../components/Geolocation/types'
import { ScrollToError } from '../../../../../../components/layout/form/ScrollToError'
import {
  dismissNotifications,
  setErrorNotification,
  setSuccessNotification,
} from '../../../../../../components/notification'
import { Loading } from '../../../../../../components/responses'
import {
  setErrorNotifications,
  setFatalErrorNotification,
} from '../../../../../../components/responses/setErrorNotifications'
import { ArrivalRouteAction } from '../../../../../../components/SosSubForm/ArrivalRoute/types'
import {
  UPDATE_ARRIVED_AT_BREAKDOWN_LOCATION_MUTATION,
  UPDATE_FINISHED_AT_BREAKDOWN_LOCATION_MUTATION,
} from '../../../../../../components/SosSubForm/mutation/mutation'
import {
  UpdateArrivedAtBreakdownLocationResponse,
  UpdateFinishedAtBreakdownLocationResponse,
} from '../../../../../../components/SosSubForm/mutation/types'
import { extractDate } from '../../../../../../util/dateConversion'
import { StateStore } from '../../../../../StoreProvider'
import { SetTowingRecordType, TowingRecord } from '../../../../ViewTowingRecord/types'
import { GeoLocateAddressData, SubmitFormType } from '../../types'
import {
  validateRouteTimes,
  getCurrentRouteTimeAtBreakdown,
  getRoutesTimelineAtBreakdown,
} from '../../validateRouteTimes'
import { ButtonContainer, RouteForm } from '../styled'
import { ArrivalRoute } from './ArrivalRoute'
import { getInitialValues } from './getInitialValues'
import { EditArrivalRouteFormValues } from './types'
import { translateInputOptions } from '../../../../../../util/translateInputOptions'
import { towingServiceTypeOptions } from '../../../../../../common/options'
import {
  toMutationVariables,
  updateArrivedAtBreakdownLocationToMutationVariables,
  updateFinishedAtBreakdownLocationToMutationVariables,
} from './mutation/toMutationVariables'
import { getBatteryServiceBattery, getDeliveredBattery } from '../../../../../../components/ALSubForm/options'
import { EDIT_ARRIVAL_ROUTE_MUTATION, EditArrivalRouteResponse } from './mutation/mutation'
import { useHistoryPush } from '../../../../../../components/router'
import { EditMenu } from '../../EditMenu'
import { autoliittoFields } from '../../createRouteMenuConfig'

interface EditTowingRecordRoutesProps {
  onCancel: () => void
  towingRecord: TowingRecord
  routeDone: boolean
  onRefetch?: () => void
  setTowingRecord?: SetTowingRecordType
}

const routeMessages = defineMessages({
  route_edit_notification_success_title: {
    id: 'route.edit.notification.success.title',
    defaultMessage: 'Route updated',
  },
  route_edit_notification_success_message: {
    id: 'route.edit.notification.success.message',
    defaultMessage: 'Route updated successfully.',
  },
})

export const RoutesIntl: React.FunctionComponent<EditTowingRecordRoutesProps & InjectedIntlProps> = ({
  onCancel,
  towingRecord,
  intl,
  routeDone,
  setTowingRecord,
}) => {
  const historyPush = useHistoryPush()
  const { formatMessage } = intl
  const {
    state: { currentUser, settings, itemGroups, items },
  } = useContext(StateStore)

  const useExtendedRoutes = settings && settings.modules.useExtendedRoutes ? true : false
  const accessControl = new ACS(currentUser).setTowingRecord(towingRecord).setExtendedRoutes(useExtendedRoutes)

  const [done, setDone] = useState<boolean>(() => {
    if (towingRecord.type === TowingRecordType.sos || useExtendedRoutes) {
      return accessControl.canTowingRecord('editFinishedAtBreakdownLocation')
    }
    return routeDone
  })

  const [getCoords, setGetCoords] = useState<boolean>(false)

  const [action, setAction] = useState<ArrivalRouteAction>(() => {
    if (towingRecord.type === TowingRecordType.sos || useExtendedRoutes) {
      if (towingRecord.jobStatus === TowingRecordJobStatus.started) {
        return 'edit-arrived'
      }

      if (towingRecord.jobStatus === TowingRecordJobStatus.arrived_at_breakdown_location) {
        return 'edit-finished'
      }
    }
    return 'default'
  })

  const [editTowingRecord, { loading: arrivalRouteLoading }] = useMutation<EditArrivalRouteResponse>(
    EDIT_ARRIVAL_ROUTE_MUTATION,
    {
      onCompleted(test) {
        const { editArrivalRoute } = test
        if (editArrivalRoute.__typename === 'EditTowingRecordSuccess') {
          setSuccessNotification(
            formatMessage(routeMessages.route_edit_notification_success_title),
            formatMessage(routeMessages.route_edit_notification_success_message)
          )
          if (towingRecord.type !== TowingRecordType.sos) {
            if (setTowingRecord) {
              setTowingRecord(editArrivalRoute.towingRecord, 'view-towing-record')
              return
            }
            historyPush(`/towing-record/${towingRecord.id}`)
          }
        } else {
          setErrorNotifications({ data: editArrivalRoute })
        }
      },
      onError(err) {
        setFatalErrorNotification(err.message)
      },
    }
  )

  const [updateArrivedAtBreakdownLocation, { loading: updateArrivedLoading }] = useMutation<
    UpdateArrivedAtBreakdownLocationResponse
  >(UPDATE_ARRIVED_AT_BREAKDOWN_LOCATION_MUTATION, {
    onCompleted(response) {
      const { updateArrivedAtBreakdownLocation } = response
      if (updateArrivedAtBreakdownLocation.__typename === 'UpdateArrivedAtBreakdownLocationSuccess') {
        setSuccessNotification(
          formatMessage(routeMessages.route_edit_notification_success_title),
          formatMessage(routeMessages.route_edit_notification_success_message)
        )
        setDone(true)
        if (setTowingRecord) {
          setTowingRecord(updateArrivedAtBreakdownLocation.towingRecord)
        }
        if (
          updateArrivedAtBreakdownLocation.towingRecord.jobStatus ===
          TowingRecordJobStatus.arrived_at_breakdown_location
        ) {
          setAction('edit-finished')
        } else {
          setAction('default')
        }
      } else {
        setErrorNotifications({ data: updateArrivedAtBreakdownLocation })
      }
    },
    onError(err) {
      console.error('err', err)
      setFatalErrorNotification(err.message)
    },
  })

  const [updateFinishedAtBreakdownLocation, { loading: updateFinishedLoading }] = useMutation<
    UpdateFinishedAtBreakdownLocationResponse
  >(UPDATE_FINISHED_AT_BREAKDOWN_LOCATION_MUTATION, {
    onCompleted(response) {
      const { updateFinishedAtBreakdownLocation } = response
      if (updateFinishedAtBreakdownLocation.__typename === 'UpdateFinishedAtBreakdownLocationSuccess') {
        setSuccessNotification(
          formatMessage(routeMessages.route_edit_notification_success_title),
          formatMessage(routeMessages.route_edit_notification_success_message)
        )
        if (setTowingRecord) {
          setTowingRecord(updateFinishedAtBreakdownLocation.towingRecord, 'view-towing-record')
        }
      } else {
        setErrorNotifications({ data: updateFinishedAtBreakdownLocation })
      }
    },
    onError(err) {
      console.error('updateFinishedAtBreakdownLocation', err)
      setFatalErrorNotification(err.message)
    },
  })

  const deliveredBattery = getDeliveredBattery(towingRecord)
  const batteryOption = getBatteryServiceBattery(itemGroups, items, deliveredBattery)
  const initialValues = getInitialValues(towingRecord, batteryOption)

  const isAutoliitto = towingRecord.typeIdentifier === TypeIdentifier.autoliitto
  const isSos = towingRecord.type === TowingRecordType.sos
  const saveLabel = isSos ? (done ? 'Tallenna valmis' : 'Tallenna saapunut') : 'Tallenna kohteessa'

  const [selectedForm, setSelectedForm] = useState<SubmitFormType>('arrived')

  async function submit(values: EditArrivalRouteFormValues) {
    setGetCoords(false)
    dismissNotifications()

    if (!isSos && !useExtendedRoutes) {
      editTowingRecord({ variables: toMutationVariables(values) })
      return
    }

    if (selectedForm === 'arrived') {
      if (!accessControl.canTowingRecord('editArrivedAtBreakdownLocation')) {
        return setErrorNotification('Virhe', accessControl.getLatestErrorMessage())
      }

      if (isSos) {
        const {
          arrivalRoute: { coords },
        } = values
        if (!coords.lat || !coords.long) {
          return setErrorNotification(
            'Virhe',
            'Kohdeosoitteen koordinaatteja ei saatu haettua. Hae osoite kartalta tai valitse osoite osoitehaun ehdotuksista.'
          )
        }
      }

      await updateArrivedAtBreakdownLocation({
        variables: updateArrivedAtBreakdownLocationToMutationVariables(values, towingRecord.id),
      })
    }

    if (selectedForm === 'finished') {
      await updateFinishedAtBreakdownLocation({
        variables: updateFinishedAtBreakdownLocationToMutationVariables(values, towingRecord.id),
      })
    }
  }

  const translatedServiceTypeOptions = translateInputOptions(towingServiceTypeOptions, formatMessage)

  const disabledAutoliittoFields = autoliittoFields(
    initialValues,
    towingRecord.routes.find(route => route.type === 'finish')
  )
 
  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
      onSubmit={async (values, { resetForm }) => {
        try {
          const routes = getRoutesTimelineAtBreakdown(towingRecord, useExtendedRoutes, done)
          const currentRoute = getCurrentRouteTimeAtBreakdown(values, done, useExtendedRoutes || isSos)
          validateRouteTimes(currentRoute, routes)
        } catch (e) {
          return setErrorNotification('Virhe', e.message)
        }

        await submit(values)
        // On SOS records additional_info should always be '' on initial render, 
        // and won't trigger a rerender of the form UNLESS some other field has also changed
        // use resetForm after submit
        isSos && resetForm()
      }}
      render={({ setFieldValue, values }) => {
        const setValue = (field: string, value: any) => {
          setFieldValue(field, value)
        }

        const getValue = (fieldName: string) => {
          const value = getIn(values, fieldName)
          return value
        }

        const setRouteLocation = (locationData: Maybe<GeoLocateAddressData>) => {
          if (locationData) {
            const location: Geolocation = locationData as Geolocation
            setLocation(location, `arrivalRoute`)

            if (isSos) {
              setLocation(location, `sosServiceOrder.currentLocation`)
            }
          }
        }

        const setAutoliittoDateField = (fieldName: string) => {
          const date = extractDate(new Date())
          setValue(fieldName, date)
        }

        const setGeolocatedField = (geolocated: boolean) => {
          setValue('arrivalRoute.geolocated', geolocated)
        }

        const clear = () => {
          setLocation({ address: '', city: '', coords: { lat: null, long: null }, zipcode: '' }, `arrivalRoute`)

          setFieldValue('arrivalRoute.geolocated', false)

          if (isSos) {
            setLocation(
              { address: '', city: '', coords: { lat: null, long: null }, zipcode: '' },
              `sosServiceOrder.currentLocation`
            )
          }
        }

        const setLocation = (locationData: Geolocation, fieldName: string) => {
          setValue(`${fieldName}.address`, locationData.address)
          setValue(`${fieldName}.city`, locationData.city)
          setValue(`${fieldName}.coords.lat`, locationData.coords.lat)
          setValue(`${fieldName}.coords.long`, locationData.coords.long)
          setValue(`${fieldName}.zipcode`, locationData.zipcode)
        }

        const setRouteAction = (action: ArrivalRouteAction) => {
          setAction(action)
          if (action === 'edit-arrived') {
            setDone(false)
          } else {
            setDone(true)
          }
        }

        return (
          <RouteForm>
            <ArrivalRoute
              regNo={towingRecord.vehicleDetails.registrationNumber}
              onCancel={() => onCancel()}
              isAutoliitto={isAutoliitto}
              setAutoliittoDateField={setAutoliittoDateField}
              setRouteLocation={setRouteLocation}
              clear={clear}
              setGeolocatedField={setGeolocatedField}
              initialValues={initialValues}
              isSos={isSos}
              routeDone={done}
              setLocation={setLocation}
              getCoordsFromAddress={getCoords}
              setValue={setValue}
              getValue={getValue}
              action={action}
              setAction={setRouteAction}
              jobStatus={towingRecord.jobStatus}
              stationId={towingRecord.stationId}
              useExtendedRoutes={useExtendedRoutes}
              translatedServiceTypeOptions={translatedServiceTypeOptions}
              selectedForm={selectedForm}
              setSelectedForm={setSelectedForm}
              towingRecordId={towingRecord.id}
              autoliittoFields={disabledAutoliittoFields}
            />

            <ButtonContainer floatAtBottom>
              <Loading loading={arrivalRouteLoading || updateArrivedLoading || updateFinishedLoading} />
              {!useExtendedRoutes && !isSos && (
                <Button
                  category="save"
                  size="l"
                  label={saveLabel}
                  mobileLabel="Tallenna"
                  submit
                  maxWidth="100%"
                ></Button>
              )}
              {(useExtendedRoutes || isSos) &&
                towingRecord.jobStatus === TowingRecordJobStatus.started &&
                selectedForm === 'arrived' && (
                  <Button
                    category="save"
                    size="l"
                    label={'Tallenna saapunut'}
                    mobileLabel="Tallenna"
                    submit
                    maxWidth="100%"
                  ></Button>
                )}
              {(useExtendedRoutes || isSos) &&
                towingRecord.jobStatus === TowingRecordJobStatus.arrived_at_breakdown_location &&
                selectedForm === 'finished' && (
                  <Button
                    category="save"
                    size="l"
                    label={'Tallenna valmis'}
                    mobileLabel="Tallenna"
                    submit
                    maxWidth="100%"
                  ></Button>
                )}

              {(useExtendedRoutes || isSos) && (
                <EditMenu
                  selectedForm={selectedForm}
                  action={action}
                  setAction={setAction}
                  canEditArrived={done}
                  canEditFinished={[
                    TowingRecordJobStatus.finished_at_breakdown_location,
                    TowingRecordJobStatus.arrived_at_destination,
                    TowingRecordJobStatus.finished_at_destination,
                    TowingRecordJobStatus.stopped
                  ].includes(towingRecord.jobStatus)}
                />
              )}
            </ButtonContainer>
            <ScrollToError />
          </RouteForm>
        )
      }}
    />
  )
}
export const ArrivalRoutePage = injectIntl(RoutesIntl)
