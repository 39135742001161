import { format } from 'date-fns'
import { Formik } from 'formik'
import React from 'react'
import { useMutation } from 'react-apollo'
import { InjectedIntlProps, defineMessages, injectIntl } from 'react-intl'

import { Time } from '../../../common/types'
import { Button } from '../../../components/Button'
import { setSuccessNotification } from '../../../components/notification'
import { setGenericErrorNotification } from '../../../components/notification/genericResponseErrorNotification'
import { Loading } from '../../../components/responses'
import { setFatalErrorNotification } from '../../../components/responses/setErrorNotifications'
import { useHistoryPush } from '../../../components/router'
import { ButtonContainer, RouteForm as EventForm } from '../../TowingRecordPage/components/routes/subforms/styled'
import { ADD_EVENT_MUTATION, AddEventResponse } from '../mutation/mutations'
import { toMutationVariables } from '../mutation/toMutationVariables'
import { CurrentWorkShift, EventTypeOption } from '../types'
import { AddEvent } from './AddEvent'
import { AddEventFormValues } from './types'

interface AddEventPageProps {
  type: EventTypeOption
  onClose: () => void
  shift: CurrentWorkShift
  refetch: () => void
}

const messages = defineMessages({
  create_title: {
    id: 'shift.event.created.title',
    defaultMessage: 'Event created',
  },
  create_message: {
    id: 'shift.event.created.message',
    defaultMessage: 'Event created',
  },
})

const AddEventPageIntl: React.FunctionComponent<AddEventPageProps & InjectedIntlProps> = ({
  type,
  onClose,
  intl,
  shift,
  refetch,
}) => {
  const { formatMessage } = intl
  const historyPush = useHistoryPush()

  const [addEvent, { loading }] = useMutation<AddEventResponse>(ADD_EVENT_MUTATION, {
    onCompleted(response) {
      console.log('response', response)
      if (response.addEvent.__typename === 'AddEventSuccess') {
        setSuccessNotification(formatMessage(messages.create_title), formatMessage(messages.create_message))
        historyPush(`/shifts`)
      }
    },
    onError(err) {
      console.error(err.message)
      const keyword = `Message:`
      const message = err.message

      const parsedMessage = message.slice(message.indexOf(keyword) + keyword.length)
      console.log('parsedMessage', parsedMessage)
      if (parsedMessage.length > 0) {
        setGenericErrorNotification('Virhe', parsedMessage)
        return refetch()
      }

      setFatalErrorNotification(err.message)
      return refetch()
    },
  })
  console.log(shift)
  const initialValues = getInitialValues()
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: AddEventFormValues) => addEvent({ variables: toMutationVariables(values, type, shift) })}
      render={({ setFieldValue, values }) => {
        const setCurrentTime = (prefix: string) => {
          const date = new Date()
          setFieldValue(`${prefix}.date`, date)
          setFieldValue(`${prefix}.time`, (format(date, 'HH:mm') as unknown) as Time)
        }
        return (
          <EventForm>
            <AddEvent type={type} onClose={onClose} setCurrentTime={setCurrentTime} setFieldValue={setFieldValue} values={values} />
            <ButtonContainer floatAtBottom>
              <Loading loading={loading} />
              <Button category="save" size="l" label="Tallenna" submit maxWidth="100%"></Button>
            </ButtonContainer>
          </EventForm>
        )
      }}
    />
  )
}

export const AddEventPage = injectIntl(AddEventPageIntl)

function getInitialValues(): AddEventFormValues {
  return {
    event: { date: new Date(), time: (format(new Date(), 'HH:mm') as unknown) as Time, comment: '', estimatedEnd: {date: null, time: null}},
  }
}
