import { ApolloClient } from 'apollo-client'
import React, { Component } from 'react'
import { ApolloConsumer } from 'react-apollo'

import { OnChangeCallback, Suggestion } from '../../../components/layout/form/field/CreatableAutosuggestField'
import { CreatableAutosuggestInput } from '../../../components/layout/form/input/CreatableAutosuggestInput'
import { CustomersOutput, query } from './CustomerAutoSuggestQuery'
import { Customer } from './types'

interface Props {
  name: string
  label?: string
  required?: boolean
  searchType: SearchType
  onChange: OnChangeCallback<Customer>
  placeholder?: string
  disabled?: boolean
}

type SearchType = 'company' | 'person'

export class CustomerAutoSuggest extends Component<Props> {
  public render() {
    const { name, label, searchType, onChange, placeholder, disabled, required } = this.props
    return (
      <ApolloConsumer>
        {client => (
          <CreatableAutosuggestInput
            disabled={disabled}
            name={name}
            label={label}
            getOptions={async searchString => {
              return await this.handleSearch(client, searchString, searchType)
            }}
            onChange={onChange}
            placeholder={placeholder}
            required={required}
          />
        )}
      </ApolloConsumer>
    )
  }

  private handleSearch = async (
    client: ApolloClient<any>,
    searchString: string,
    searchType: SearchType
  ): Promise<Suggestion[]> => {
    if (searchString.length > 1) {
      const { data } = await client.query({
        query,
        variables: { searchString, type: searchType },
        fetchPolicy: 'network-only',
      })
      return this.onCustomerFetched(data.customers)
    }
    return []
  }

  private onCustomerFetched = (customersOutput: CustomersOutput): Suggestion[] => {
    if (customersOutput.__typename === 'GetCustomersSuccess') {
      return customersOutput.customers.map(customer => {
        if (customer.__typename === 'CompanyCustomer') {
          return { label: `${customer.companyName} / ${customer.contactPerson}`, value: customer.id, object: customer }
        }
        if (customer.__typename === 'PersonCustomer') {
          return { label: `${customer.firstName} ${customer.lastName}`, value: customer.id, object: customer }
        }
        return { label: 'Undefined', value: 0 }
      })
    }
    return []
  }
}
