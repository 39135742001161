import { Maybe } from './common'
import { BusinessID, Email, Municipality, PersonalIdentityCode, Phone, PostalCode } from './scalarTypes'

export interface CustomerQueryParams {
  id: number
}

export interface CustomersQueryParams {
  searchString: Maybe<string>
  type: Maybe<CustomerType>
}

export enum CustomerType {
  person = 'person',
  company = 'company',
}

export enum DeliveryMethod {
  mail = 'mail',
  email = 'email',
  e_invoice = 'e_invoice',
  no_delivery = 'no_delivery',
  sms = 'sms',
}

export enum CustomerOriginatorApp {
  order_api = 'tilaus-api',
  order_widget = 'tilaus-widget',
  towing_record_web = 'kuormakirja-web',
  order_web = 'tilaus-web',
  towing_record_app = 'kuormakirja-app',
  order_app = 'tilaus-app',
  autofutur = 'autofutur',
  adabase = 'adabase',
  tujaus = 'tujaus',
  autoliitto = 'autoliitto',
  sos = 'sos',
  customer_registry = 'customer-registry',
  credit_check = 'credit-check',
  customer_info_search = 'customer-info-search',
  innokorjaamo = 'innokorjaamo',
}

export type Customer = PersonCustomer | CompanyCustomer

export interface PersonCustomer {
  id: number
  identifier: string
  type: CustomerType.person
  ssn: Maybe<PersonalIdentityCode>
  maskedSsn: Maybe<string>
  firstName: string
  lastName: string
  extraInfo: string
  additionalInfo: string
  co: string
  address: string
  zipcode: Maybe<PostalCode>
  city: Maybe<Municipality>
  email: Maybe<Email>
  phone: Maybe<Phone>
  eInvoiceAddress: string
  eInvoiceOperator: string
  paymentTerms: number
  deliveryMethod: Maybe<DeliveryMethod>
  notificationMethod: Maybe<string>
  originatorApp: Maybe<CustomerOriginatorApp>
}

export interface CompanyCustomer {
  id: number
  identifier: string
  type: CustomerType.company
  companyName: string
  businessId: Maybe<BusinessID>
  contactPerson: string
  contactPersonEmail: Maybe<Email>
  extraInfo: string
  additionalInfo: string
  co: string
  address: string
  zipcode: Maybe<PostalCode>
  city: Maybe<Municipality>
  phone: Maybe<Phone>
  eInvoiceAddress: string
  eInvoiceOperator: string
  paymentTerms: number
  deliveryMethod: DeliveryMethod
  email: Maybe<Email>
  notificationMethod: Maybe<string>
  originatorApp: Maybe<CustomerOriginatorApp>
}

export interface PersonCustomerInput {
  type: CustomerType
  ssn: Maybe<PersonalIdentityCode>
  firstName: string
  lastName: string
  email: Maybe<Email>
  phone: Maybe<Phone>
  identifier: string
  extraInfo: string
  additionalInfo: string
  co: string
  address: string
  zipcode: Maybe<PostalCode>
  city: Maybe<Municipality>
  eInvoiceAddress: string
  eInvoiceOperator: string
  deliveryMethod: DeliveryMethod
  notificationMethod: Maybe<string>
}

export interface CompanyCustomerInput {
  type: CustomerType
  companyName: string
  businessId: Maybe<BusinessID>
  contactPerson: string
  contactPersonEmail: Maybe<Email>
  phone: Maybe<Phone>
  identifier: string
  extraInfo: string
  additionalInfo: string
  co: string
  address: string
  zipcode: Maybe<PostalCode>
  city: Maybe<Municipality>
  eInvoiceAddress: string
  eInvoiceOperator: string
  deliveryMethod: DeliveryMethod
  email: Maybe<Email>
  notificationMethod: Maybe<string>
}
