import { default as raw_repair_shops } from './repair_shops.json'

export interface RepairShop {
  id: string
  name: string
  location: {
    address: string
    postcode: string
    city: string
    coordinates: AutoliittoCoords
  }
  serviceChannels?: ServiceChannel[]
}

export interface AutoliittoCoords {
  lat: number
  lon: number
}

interface ServiceChannel {
  id: string
  type: string
  arcId: string
}

export const repairShops = raw_repair_shops as RepairShop[]

export const repairShopOptions = (serviceChannel: string | null, repairShopId: string | null) => {
  // return only the currently selected repair shop AND
  // repair shops with channel names equal to towingRecord.automobileAndTouringClubFinland.serviceChannel
  const filteredRepairShops = repairShops.filter(shop =>
    shop.serviceChannels
      ? shop.serviceChannels.find(channel => channel.type === serviceChannel) || shop.id === repairShopId
      : false
  )
  return filteredRepairShops.map(shop => {
    return {
      value: shop.id,
      label: `${shop.name}`,
    }
  })
}



export const variables = {
  insuranceCompany: [
    { value: 'If', label: 'If' },
    { value: 'Pohjola', label: 'Pohjola' },
    { value: 'LähiTapiola', label: 'LähiTapiola' },
    { value: 'Suomen Vahinkovakuutus', label: 'Suomen Vahinkovakuutus' },
    { value: 'Fennia', label: 'Fennia' },
    { value: 'Pohjantähti', label: 'Pohjantähti' },
    { value: 'Protector Forsikring ASA', label: 'Protector Forsikring ASA' },
    { value: 'A-vakuutus', label: 'A-vakuutus' },
    { value: 'Turva', label: 'Turva' },
    { value: 'Euro Insurances', label: 'Euro Insurances' },
    { value: 'Greenval Insurance', label: 'Greenval Insurance' },
    { value: 'Volvia', label: 'Volvia' },
    { value: 'Axa', label: 'Axa' },
    { value: 'Valtiokonttori', label: 'Valtiokonttori' },
    { value: 'Ålands ömsesidiga försäkringsbolag', label: 'Ålands ömsesidiga försäkringsbolag' },
    { value: 'Tapiola', label: 'Tapiola' },
    { value: 'Fennia Skadeförsäkring', label: 'Fennia Skadeförsäkring' },
    { value: 'Ups', label: 'Ups' },
    { value: 'Ei vakuutusta', label: 'Ei vakuutusta' },
  ],
  deliverable: [
    { value: 'Towage', label: 'Hinaus' },
    { value: 'Transport', label: 'Kuljetus' },
    { value: 'Pull free', label: 'Tielle nosto' },
    { value: 'Start car', label: 'Apukäynnistys' },
    { value: 'Repair', label: 'Korjaus paikanpäällä' },
    { value: 'Change of wheel', label: 'Rengasapu' },
    { value: 'Opening of door', label: 'Ovien aukaisu' },
    { value: 'Delivery of fuel', label: 'Polttoaineen toimitus' },
    { value: 'Empty ride', label: 'Turha lähtö' },
   
  ],
  towingDeliverables: ['Towage', 'Transport'],
  cause: [
    { value: 'Immobiliser defect', label: 'Ajonestovika' },
    { value: 'Battery defect', label: 'Akkuvika' },
    { value: 'Key missing', label: 'Avain hävinnyt' },
    { value: 'Key broken', label: 'Avain rikki' },
    { value: 'Key locked in car', label: 'Avain lukitussa autossa' },
    { value: 'Can not start', label: 'Ei käynnisty' },
    { value: 'Hydraulic defect', label: 'Hydrauliikkavika' },
    { value: 'Vandalism', label: 'Ilkivalta' },
    { value: 'Ventilation or Heating defect', label: 'Ilmastointi- tai lämmitysvika' },
    { value: 'Brakes defect', label: 'Jarruvika' },
    { value: 'Transport', label: 'Jatkohinaus/Kuljetus' },
    { value: 'Stuck in snow', label: 'Jumissa lumessa' },
    { value: 'Stuck on field/grass', label: 'Jumissa pellolla/nurmikolla' },
    { value: 'Leaking coolant', label: 'Jäähdytinnestevuoto' },
    { value: 'Cooler defect', label: 'Jäähdytysjärjestelmän vika' },
    { value: 'Frozen', label: 'Jäätynyt' },
    { value: 'Moving of vehicle', label: 'Kiireetön siirto' },
    { value: 'Chassis', label: 'Kori' },
    { value: 'Clutch defect', label: 'Kytkinvika' },
    { value: 'Glass damage', label: 'Lasivaurio' },
    { value: 'Lock defect', label: 'Lukkovika' },
    { value: 'Engine failure', label: 'Moottorivika' },
    { value: 'Burglary', label: 'Murto' },
    { value: 'Steering defect', label: 'Ohjauslaitteen vika' },
    { value: 'Driven into ditch', label: 'Ojaanajo' },
    { value: 'Driven into ditch avoiding deer', label: 'Ojaanajo, hirvieläimen väistö' },
    { value: 'Driven into ditch avoiding other animal', label: 'Ojaanajo, muun eläimen väistö' },
    { value: 'Door damaged', label: 'Ovi vaurioitunut' },
    { value: 'Exhaust', label: 'Pakoputkiston vika' },
    { value: 'Fire Damage', label: 'Palovahinko' },
    { value: 'Fuel system defect', label: 'Polttainejärjestelmävika' },
    { value: 'Run out of fuel', label: 'Polttoaine loppu' },
    { value: 'Red Light', label: 'Punainen vikavalo palaa' },
    { value: 'Wheel/suspension defect', label: 'Pyörän tai ripustuksen vaurioituminen' },
    { value: 'Flat tire with kit', label: 'Rengasrikko, paikkaussarja' },
    { value: 'Flat tire with spare tire', label: 'Rengasrikko, vararengas' },
    { value: 'Flat tire', label: 'Rengasvaurio' },
    { value: 'Stalled while driving', label: 'Sammunut ajosta' },
    { value: 'Electronic failure', label: 'Sähkövika' },
    { value: 'Stuck by road', label: 'Tieltä suistuminen' },
    { value: 'Flat battery', label: 'Tyhjä akku' },
    { value: 'Accident solo', label: 'Törmäys, ei toista ajoneuvoa' },
    { value: 'Collision with deer', label: 'Törmäys, hirvikolari' },
    { value: 'Accident', label: 'Törmäys, liikenneonnettomuus' },
    { value: 'Collision with animal', label: 'Törmäys, muu eläin' },
    { value: 'Transmission fault', label: 'Vaihteistovika' },
    { value: 'Parked with light on', label: 'Valot unohtuneet päälle' },
    { value: 'Theft', label: 'Varkaus' },
    { value: 'Def. windshield wiper', label: 'Vioittunut tuulilasinpyyhkijä' },
    { value: 'Ignition lock defect', label: 'Virtalukon vika' },
    { value: 'Gear defect', label: 'Voimansiirron vika' },
    { value: 'Parked at inconvenience', label: 'Väärin pysäköinti' },
    { value: 'Wrong fuel', label: 'Väärintankkaus' },
    { value: 'Empty ride', label: 'Turha lähtö' },
   
  ],
}
