import React from 'react'
import styled from 'styled-components'

import { RouteHeader } from '../../containers/TowingRecordPage/components/routes/subforms/RouteHeader'
import { RouteWrapper } from '../layout'
import { ModalContainer, ModalWindow } from '../Map'
import { Loading } from '../responses'
import { ModalProps } from './types'
import { ErrorNotification } from '../notification/errorNotification'
import { SuccessNotification } from '../notification/successNotification'
import { InfoNotification } from '../notification/infoNotification'

interface FullscreenModalProps extends ModalProps {
  loading?: boolean
  error?: {
    title?: string
    message?: string
  }
  success?: {
    title?: string
    message?: string
  }
  info?: {
    title?: string
    message?: string
  }
  showHeader?: boolean
}

const Container = styled.div`
  padding: 0 1rem 0.5rem 1rem;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    padding: 0 0.5rem;
  }
`

export const FullscreenModal: React.FunctionComponent<FullscreenModalProps> = ({
  label,
  regNo,
  onClose,
  children,
  loading,
  error,
  success,
  info,
  showHeader = true,
}) => {
  return (
    <>
      <ModalContainer>
        <ModalWindow>
          {error && (
            <ErrorNotification
              title={error.title ? error.title : 'Virhe!'}
              message={error.message ? error.message : ''}
            ></ErrorNotification>
          )}
          {success && (
            <SuccessNotification
              title={success.title ? success.title : 'Onnistui!'}
              message={success.message ? success.message : ''}
            ></SuccessNotification>
          )}
          {info && (
            <InfoNotification
              title={info.title ? info.title : 'Huom.'}
              message={info.message ? info.message : ''}
            ></InfoNotification>
          )}
          {showHeader && (
            <RouteWrapper>
              <RouteHeader label={label} cancel={onClose} regNo={regNo} />
            </RouteWrapper>
          )}
          <Container>
            {children && children}
            {loading && <Loading loading={loading} />}
          </Container>
        </ModalWindow>
      </ModalContainer>
    </>
  )
}
