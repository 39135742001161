import { cloneDeep } from 'lodash'
import { EditTowingRecordFormValues } from '../types'
import { EditTowingRecordMutationVariables } from './types'
import { toTowingRecordInput } from '../../towingRecordInput/toTowingRecordInput'

export const toMutationVariables = (
  inputValues: EditTowingRecordFormValues,
  useSosServiceOrderEditMutation?: boolean
): EditTowingRecordMutationVariables => {
  const values = cloneDeep(inputValues)

  return {
    input: {
      id: values.id,
      towingRecord: toTowingRecordInput(values, useSosServiceOrderEditMutation),
    },
  }
}
