import { Field } from 'formik'
import React, { useCallback } from 'react'
import { RadioField } from '../../field/RadioField'
interface Props {
  name?: string
  label: string
  id?: string
  required?: boolean
  disabled?: boolean
}
export function RadioInput(props: Props): JSX.Element {
  const { name, label, required, id, disabled } = props
  const validate = useCallback(
    (value: string | null): string | undefined => {
      if (required && (!value || !value.trim())) {
        return 'Kenttä on pakollinen'
      }
      return undefined
    },
    [required]
  )
  return (
    <Field
      disabled={disabled}
      component={RadioField}
      id={id}
      componentName={name}
      name={name}
      label={label}
      required={required}
      validate={validate}
    />
  )
}
