import React, { useState } from 'react'
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo'
import 'react-html5-camera-photo/build/css/index.css'
import './styles/index.css'
import styled from 'styled-components'
import { Icon } from '../../icons/Icon'
import { Loading } from '../../responses'
import { ImagePreview } from './ImagePreview'

interface CameraViewProps {
  onClose: () => void
  onTakePhoto: (dataUri: string) => void
}

export const CameraView: React.FC<CameraViewProps> = ({ onClose, onTakePhoto }) => {
  const [dataUri, setDataUri] = useState('')
  const [loading, setLoading] = useState(true)

  function handleTakePhoto(dataUri: string) {
    setDataUri(dataUri)
  }

  const handleDiscard = () => {
    setDataUri('')
    setLoading(true)
  }

  const handleCameraStart = () => {
    setLoading(false)
  }

  return (
    <CameraContainer>
      {loading && <Loading loading={loading} />}
      {dataUri ? (
        <ImagePreview dataUri={dataUri} onSelect={onTakePhoto} onDiscard={handleDiscard} />
      ) : (
        <>
          <FloatButtonContainer>
            <OuterButton onClick={onClose}>
              <Icon icon="close" size="medium" marginTop="1.1rem" />
            </OuterButton>
          </FloatButtonContainer>
          <Camera
            onTakePhotoAnimationDone={dataUri => {
              handleTakePhoto(dataUri)
            }}
            isFullscreen
            onCameraStart={handleCameraStart}
            imageType={IMAGE_TYPES.PNG}
            idealFacingMode={FACING_MODES.ENVIRONMENT}
          />
        </>
      )}
    </CameraContainer>
  )
}

export const CameraContainer = styled.div`
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
`
const FloatButtonContainer = styled.div`
  position: absolute;
  left: 50%;
  bottom: 150px;
`
const OuterButton = styled.div`
  position: absolute;
  border-radius: 50%;

  left: -37px;
  height: 75px;
  width: 75px;
  background-color: hsla(0, 0%, 100%, 0.4);
  z-index: 1;
`
