import { Time } from '@google/maps'
import { format } from 'date-fns'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { TowingRecordJobStatus } from '../../../../../../../common/types'
import { Button } from '../../../../../../../components/Button'
import { CollapseContent } from '../../../../../../../components/Collapse/CollapseContent'
import { RouteColumn, RouteRow } from '../../../../../../../components/layout'
import { SelectInput } from '../../../../../../../components/layout/form/input'
import { ArrivalDateTimeInput } from '../../../../../../../components/layout/form/input/DateTimeInput'
import React from 'react'
import { repairShopOptions } from '../options'
import {
  AddressButton,
  ComboInputWrapper,
  checkForCoordinates,
} from '../../../../../../../components/layout/form/input/LocationInputMap/components/InputWrapper'
import { LocationButtonRow } from '../../../../../../../components/layout/buttonlayout'
import { ShowAddressOptions } from '../FinishRoute'
import { toMapData } from '../../../../../../../components/layout/form/input/LocationInputMap'
import { LocationInputMapWithComposition } from '../../../../../../../components/layout/form/input/LocationInputMap/LocationInputMapWithComposition'
import { Icon } from '../../../../../../../components/icons/Icon'
import { FullRouteColumn } from '../../../../../../../components/layout/routesmenu'
import { ALProps, ArrivedAtDestinationProps } from './ArrivedForm'

export const ArrivedAtDestinationFormAL: React.FunctionComponent<ArrivedAtDestinationProps & ALProps> = ({
  setValue,
  action,
  serviceChannel,
  repairShopId,
  setShowLocationChange,
  getValue,
  showAddressInput,
  stationId,
  setRouteLocation,
  clear,
}) => {
  const [edit, setEdit] = useState<boolean>(action === 'edit-arrived')

  useEffect(() => {
    if (action === 'edit-arrived') {
      if (!edit) setEdit(true)
    } else {
      if (edit) setEdit(false)
    }
  }, [edit, action, setEdit])

  const hasCoordinates = checkForCoordinates(getValue('finishRoute.city'), getValue('finishRoute.coords'))

  return (
    <>
      <CollapseContent>
        <RouteRow>
          <RouteColumn>
            <ComboInputWrapper>
              {showAddressInput === ShowAddressOptions.ADDRESS && (
                <FullRouteColumn>
                  <LocationInputMapWithComposition
                    addressLabel={`Osoite`}
                    addressName={`finishRoute.address`}
                    cityLabel={`Kaupunki`}
                    cityName={`finishRoute.city`}
                    coordsName={`finishRoute.coords`}
                    zipcodeName={`finishRoute.zipcode`}
                    getValue={getValue}
                    values={toMapData(
                      getValue(`finishRoute.address`),
                      getValue(`finishRoute.city`),
                      getValue(`finishRoute.coords`),
                      getValue(`finishRoute.zipcode`)
                    )}
                    setValue={setValue}
                    setRouteLocation={setRouteLocation}
                    stationId={stationId}
                    disabled={!edit}
                    required
                  >
                    <AddressButton disabled={!edit} type="button" backgroundColor="red400" onClick={clear}>
                      <Icon icon="trash" size="x-small" color="white" />
                    </AddressButton>
                    <AddressButton
                      type="button"
                      style={{ cursor: !edit ? 'default' : 'pointer' }}
                      onClick={() => {
                        if (!edit) return
                        setShowLocationChange(true)
                      }}
                      backgroundColor={!edit ? 'grey400' : 'orange400'}
                    >
                      <Icon icon="edit2" size="small" color="white" />
                    </AddressButton>
                    <AddressButton
                      type="button"
                      backgroundColor={hasCoordinates ? 'green100' : 'grey300'}
                      borderRadius="0 5px 5px 0"
                    >
                      <Icon icon="world" size="x-small" color={hasCoordinates ? 'green700' : 'grey700'} />
                    </AddressButton>
                  </LocationInputMapWithComposition>
                </FullRouteColumn>
              )}
              {showAddressInput === ShowAddressOptions.REPAIR_SHOP && (
                <>
                  <SelectInput
                    label="Korjaamo"
                    name="automobileAndTouringClubFinland.repairShopId"
                    options={repairShopOptions(serviceChannel, repairShopId)}
                    disabled
                    hideArrow
                    required
                  />
                  <LocationButtonRow>
                    <AddressButton
                      disabled={false}
                      type="button"
                      onClick={() => {
                        if (!edit) return
                        setShowLocationChange(true)
                      }}
                      backgroundColor={!edit ? 'grey400' : 'orange400'}
                      borderRadius="0 5px 5px 0"
                    >
                      <Icon icon="edit2" size="small" color="white"/>
                    </AddressButton>
                  </LocationButtonRow>
                </>
              )}
            </ComboInputWrapper>
          </RouteColumn>
          <RouteColumn>
            <ArrivalDateTimeInput
              label="Saapumisaika"
              name={`finishRoute`}
              customOnChange={() => {
                const value = (format(new Date(), 'HH:mm') as unknown) as Time
                setValue('finishRoute.arrivalDate', new Date())
                setValue('finishRoute.arrivalTime', value)
              }}
              required
              showCurrentTimeBtn
              disabled={!edit}
            />
          </RouteColumn>
        </RouteRow>
        <RouteRow columns={`1fr 1fr 1fr`}></RouteRow>
      </CollapseContent>
    </>
  )
}

export const arrivedAtDestinationEditableJobStatuses = [
  TowingRecordJobStatus.arrived_at_destination,
  TowingRecordJobStatus.finished_at_destination,
]

export const CustomButton = styled(Button)`
  margin-right: 1rem;
`
