export enum WebMessageType {
  LOGOUT = 'logout',
  LOGIN = 'login',
}

export type WebMessageData = string

export interface WebMessage {
  type: WebMessageType
  data?: WebMessageData
}
