import gql from 'graphql-tag'

import { Customer } from '../../containers/Customer/types'
import { InvalidCustomerIdentifierError, InvalidParameterError, UnauthorizedError } from '../../containers/errorTypes'
import { CreditInfo, InfoSearch } from './types'

interface CreditInfoSuccess {
  __typename: 'CreditInfoSearchSuccess'
  creditInfo: {
    customer: Customer
    creditInfo: CreditInfo
  }
}

export type CreditInfoOutput = CreditInfoSuccess | UnauthorizedError

export interface CreditInfoResponse {
  creditInfoSearch: CreditInfoOutput
}

export const CREDIT_INFO_QUERY = gql`
  mutation CreditInfoSearch($input: CreditInfoSearchInput!) {
    creditInfoSearch(input: $input) {
      __typename
      ... on UnauthorizedError {
        error {
          code
          message
          details
          context
        }
      }
      ... on CreditInfoSearchSuccess {
        creditInfo {
          customer {
            ... on PersonCustomer {
              id
              ssn
              maskedSsn
              firstName
              lastName
              phone
              email
              address
              identifier
              extraInfo
              co
              city
              zipcode
              paymentTerms
              deliveryMethod
              additionalInfo
              notificationMethod
              originatorApp
            }
          }
          creditInfo {
            invoice {
              serviceName
              creditDecision
              message
            }
            partPayment {
              serviceName
              creditDecision
              message
            }
          }
        }
      }
    }
  }
`

type InfoSearchCustomerType = 'person' | 'company'

export interface InfoSearchInput {
  customerType: InfoSearchCustomerType
  customerIdentifier: string
  refetch?: boolean
}

interface InfoSearchSuccess {
  __typename: 'InfoSearchSuccess'
  infoSearch: InfoSearch
}

export type InfoSearchOutput =
  | InfoSearchSuccess
  | UnauthorizedError
  | InvalidCustomerIdentifierError
  | InvalidParameterError

export interface InfoSearchResponse {
  infoSearch: InfoSearchOutput
}

export const INFO_SEARCH_QUERY = gql`
  mutation InfoSearch($input: InfoSearchInput!) {
    infoSearch(input: $input) {
      __typename
      ... on UnauthorizedError {
        error {
          code
          message
          details
          context
        }
      }
      ... on InvalidCustomerIdentifierError {
        error {
          code
          message
          details
          context
        }
      }
      ... on InvalidParameterError {
        error {
          code
          message
          details
          context
        }
      }
      ... on ResourceNotFoundError {
        error {
          code
          message
          details
          context
        }
      }
      ... on InfoSearchSuccess {
        infoSearch {
          id
          searchMeta {
            userName
            customerIdentifier
            searchTimestamp
          }
          customer {
            ... on PersonCustomer {
              id
              firstName
              lastName
              email
              phone
              address
              city
              zipcode
              type
            }
            ... on CompanyCustomer {
              id
              companyName
              businessId
              contactPerson
              contactPersonEmail
              email
              phone
              address
              city
              zipcode
              type
            }
          }
          searchResults {
            paymentDefaults {
              amount
              info
              creditor
              source
              code
              type
              date
              paidDate
              validToDate
              expireDate
              caseTypeCode
            }
            registers {
              name
              registered
              startDate
              endDate
            }
          }
        }
      }
    }
  }
`
