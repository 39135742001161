import io from 'socket.io-client'
import React from 'react'

const REACT_APP_SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_SERVER_URL

export const socket = io(REACT_APP_SOCKET_SERVER_URL ? REACT_APP_SOCKET_SERVER_URL : '', {
  autoConnect: false,
  secure: true,
});
// export const socket = io('https://sos-test.innovoice.fi:3300',{
//   autoConnect: false,
//   secure: true,
// });


socket.connect()


export const SocketContext = React.createContext({} as SocketIOClient.Socket);
