import React from 'react'
import styled from 'styled-components'
import { Icon } from '../icons/Icon'
import { CountCircle, MenuItem } from '../layout'
import { RouterNavLink } from '../RouterNavLink'
import { Maybe } from '../../common/types'

type IconCategory = 'orders' | 'search' | 'shifts' | 'settings' | 'messages'
type IconType = 'work-wrench' | 'search' | 'clock' | 'settings' | 'messages'

interface MenuLinkConfig {
  icon: IconType
}

const menuIcon: Record<IconCategory, MenuLinkConfig> = {
  orders: { icon: 'work-wrench' },
  search: { icon: 'search' },
  shifts: { icon: 'clock' },
  settings: { icon: 'settings' },
  messages: { icon: 'messages' }
}

interface Props {
  category: keyof typeof menuIcon
  to: string
  label: string
  exact?: boolean
  count?: Maybe<number>
  onClick?: ()=>void
}

export const MenuLink: React.FunctionComponent<Props> = ({ category, count, /*label,*/ to, exact = false, onClick }) => {
  const { icon } = menuIcon[category]
  return (
    <MenuItem>
      {!!count && <CountCircle smallFont={count > 9 ? true : false}>{count}</CountCircle>}
      {category !== 'messages' && (
        <RouterNavLink to={to} exact={exact}>
          <IconWrapper>
            <Icon color="grey500" icon={icon} />
          </IconWrapper>
          {/*<MenuItemLabel color={'grey500'}>{label}</MenuItemLabel>*/}
        </RouterNavLink>
      )}
      {category === 'messages' && (
        <MessageNavLink>
          <IconWrapperMessage onClick={onClick}>
            <Icon color="grey500" icon={icon} />
          </IconWrapperMessage>
        </MessageNavLink>
      )}
    </MenuItem>
  )
}

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 2.5rem;
  color: ${props => props.color ? props.theme.colors[props.color] : props.theme.colors.black };
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    svg {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
`

export const MenuItemLabel = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
`

export const IconWrapperMessage = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  padding-top: 0.5rem;
  cursor: pointer;
  svg {
    width: 2.8rem;
    height: 2.8rem;
  }
  color: ${props => props.color ? props.theme.colors[props.color] : props.theme.colors.black};
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    svg {
      width: 2.0rem;
      height: 2.0rem;
    }
  }
`

const MessageNavLink = styled.div`
  padding: 0.25rem;
  font-size: 2rem;
  text-decoration: none;
  color: ${props => props.color ? props.theme.colors[props.color] : props.theme.colors.grey500};
  &.active {
    color: ${props => props.color ? props.theme.colors[props.color] : props.theme.colors.black};
    text-decoration: none;

    svg * {
      fill: ${props => props.color ? props.theme.colors[props.color] : props.theme.colors.black};
    }
  }
  &:hover {
    color: ${props => props.color ? props.theme.colors[props.color] : props.theme.colors.black};
    text-decoration: underline;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
`
