import { Field, FormikProps } from 'formik'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import React, { useCallback } from 'react'

import { TextField } from '../../field'

interface Props {
  name: string
  label?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  onChange?: (_: any, form: FormikProps<any>) => void
}

export const PhoneInput: React.FunctionComponent<Props> = ({
  name,
  label,
  placeholder,
  disabled,
  required,
  onChange,
}) => {
  /** 
   * we only validate the phone number in the input field when changed because 
   * phonenumbers retrieved from pertti can be invalid according to validation 
   * rules. We still want to show them! 
   */
  const validate = useCallback(
    (value: string | null): string | undefined => {
      if (disabled) return undefined
      if (required && (!value || !value.trim())) {
        return 'Kenttä on pakollinen'
      }

      if (value) {
        const parsed = parsePhoneNumberFromString(value, 'FI')
        if (!parsed || !parsed.isValid()) {
          return 'Virheellinen puhelinnumero'
        }
      }

      // if (value && !value.match(/^\+?[\d\-\s]{5}[\d\-\s]+$/)){
      //   return 'Virheellinen puhelinnumero'
      // }

      return undefined
    },
    [required, disabled]
  )

  return (
    <Field
      component={TextField}
      id={name}
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      validate={validate}
      disabled={disabled}
      onValueChange={onChange}
    />
  )
}
