import { SignatureType } from '../../common/types'
import { SignatureInput } from './types'

export const writtenSignatureToMutationVariables = (
  towingRecordId: number | null | undefined,
  signature: SignatureInput,
  writtenSignatureData: string
) => {
  return {
    input: {
      towingRecordId,
      signature: {
        agreements: signature.agreements,
        type: signature.type,
        signatorName: signature.signatorName,
        signatorEmail: '',
        signatorPhone: '',
        data: writtenSignatureData,
      },
    },
  }
}

export const onlineAcceptanceSignatureToMutationVariables = (
  towingRecordId: number | null | undefined,
  signature: SignatureInput
) => {
  return {
    input: {
      towingRecordId,
      signature: {
        agreements: null,
        type: signature.type,
        signatorName: '',
        signatorEmail: signature.type === SignatureType.online_acceptance ? signature.signatorEmail : '',
        signatorPhone: signature.type === SignatureType.online_acceptance_sms ? signature.signatorPhone : '',
        data: '',
      },
    },
  }
}
