import React, { useState } from 'react'
import { Button } from '../../../components/Button'
import { BigButtonSection } from '../../../components/layout'
import { ShiftTable } from '../../../components/ShiftTable'
import { ShiftHeader, ShiftHeading } from '../../../components/ShiftTable/layout'
import { AddEventPage } from '../AddEvent'
import { BreakShiftPageViews, EventTypeOption, ShiftPageProps } from '../types'

export const BreakPage: React.FunctionComponent<ShiftPageProps> = ({ currentShift, refetch }) => {
  const [view, setView] = useState<BreakShiftPageViews>(BreakShiftPageViews.default)
  return (
    <>
      {view === BreakShiftPageViews.default && (
        <>
          <ShiftHeader color="blue300">
            <ShiftHeading label="Tauko" />
            <BigButtonSection>
              <Button
                category="new"
                label="Lopeta tauko"
                onClick={() => {
                  setView(BreakShiftPageViews.break_end_form)
                }}
                size="m"
                fontWeight="normal"
              />
            </BigButtonSection>
          </ShiftHeader>
          <ShiftTable shift={currentShift} />
        </>
      )}

      {view === BreakShiftPageViews.break_end_form && (
        <AddEventPage
          type={EventTypeOption.break_end}
          onClose={() => setView(BreakShiftPageViews.default)}
          shift={currentShift}
          refetch={refetch}
        />
      )}
    </>
  )
}
