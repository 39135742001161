import { cloneDeep } from 'lodash'

import { toScalarShortDate, toScalarTime } from '../../../../../../../util/convertToScalar'
import { EndRouteMutationVariables, UpdateEndedRouteMutationVariables } from './mutation'
import { EditEndRouteFormValues } from '../types'
import { EndRoute, EndRouteMutationInput } from './types'

export const toMutationVariables = (inputValues: EditEndRouteFormValues): EndRouteMutationVariables => {
  const values = cloneDeep(inputValues)

  return {
    input: {
      id: values.id,
      endRoute: {
        endRoute: routeToMutationVariables(values.endRoute),
      },
      notes: values.notes,
      vehicleRegNo: values.vehicleRegNo,
    },
  }
}

const routeToMutationVariables = (route: EndRoute): EndRouteMutationInput => {
  return {
    id: route.id,
    ordering: 102,
    address: route.address,
    city: route.city,
    zipcode: route.zipcode,
    coords: {
      lat: route.coords.lat,
      long: route.coords.long,
    },
    description: route.description,
    displayTimestampOnPrintables: route.displayTimestampOnPrintables,
    type: route.type,
    arrivalTime: route.arrivalTime ? toScalarTime(route.arrivalTime) : null,
    arrivalDate: route.arrivalDate && route.arrivalTime ? toScalarShortDate(route.arrivalDate) : null,
    geolocated: route.geolocated,
  }
}

export const toEndedMutationVariables = (inputValues: EditEndRouteFormValues): UpdateEndedRouteMutationVariables => {
  const values = cloneDeep(inputValues)

  return {
    input: {
      id: values.id,
      endedDetails: {
        additionalInfo: values.additionalInfo
      }
    },
  }
}