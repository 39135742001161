import gql from 'graphql-tag';

export const LOG_TRANSACTION = gql`
  mutation LogPaymentTransaction($input: LogPaymentTerminalInput!) {
    logPaymentTerminalRequest(input: $input) {
      __typename
      ... on UnauthorizedError {
        error {
          code
          message
          details
          context
        }
      }
      ... on LogPaymentTransactionSuccess {
        success
      }
    }
  }
`;

export const GET_PAYMENT_TERMINALS = gql`
  query GetPaymentTerminals {
    activePaymentTerminals {
      __typename
      ... on UnauthorizedError {
        error {
          code
          message
          details
          context
        }
      }
      ... on PaymentTerminalsSuccess {
        paymentTerminals {
          terminalName
          terminalId
          userName
          password
          url
          isDefault
        }
        success
      }
    }
  }
`;

export const SET_TRANSACTION_TO_PAYMENT = gql`
  mutation SetTransactionToPayment($input: setTransactionToPaymentInput!) {
    setTransactionToPaymentRequest(input: $input) {
      __typename
      ... on UnauthorizedError {
        error {
          code
          message
          details
          context
        }
      }
      ... on SetTransactionToPaymentSuccess {
        paymentTerminalTransaction {
          id
          receiptId
          previousTransaction
          transactionStatus
          transactionType
          transactionId
          transactionUniqueId
          authorizationCode
          amount
          referenceNumber
          transactionTime
          panMaskedForCustomer
          panMaskedForClerk
          cardReadingMethod
          terminalId
          terminalName
          merchantReceipt
          customerReceipt
          created
          updated
          deletedAt
        }
        success
      }
    }
  }
`;

export const UPDATE_TRANSACTION_ON_PAYMENT = gql`
  mutation UpdateTransactionOnPayment($input: updateTransactionOnPaymentInput!) {
    updateTransactionOnPaymentRequest(input: $input) {
      __typename
      ... on UnauthorizedError {
        error {
          code
          message
          details
          context
        }
      }
      ... on UpdateTransactionOnPaymentSuccess {
        paymentTerminalTransaction {
          id
          receiptId
          previousTransaction
          transactionStatus
          transactionType
          transactionId
          transactionUniqueId
          authorizationCode
          amount
          referenceNumber
          transactionTime
          panMaskedForCustomer
          panMaskedForClerk
          cardReadingMethod
          terminalId
          terminalName
          merchantReceipt
          customerReceipt
          created
          updated
          deletedAt
        }
        success
      }
    }
  }
`;

export const REMOVE_TRANSACTION_FROM_PAYMENT = gql`
  mutation RemoveTransactionFromPayment($input: RemoveTransactionFromPaymentInput!) {
    removeTransactionFromPaymentRequest(input: $input) {
      __typename
      ... on UnauthorizedError {
        error {
          code
          message
          details
          context
        }
      }
      ... on RemoveTransactionFromPaymentSuccess {
        success
      }
    }
  }
`;

export const REVERT_TRANSACTION_FROM_PAYMENT = gql`
  mutation RevertTransactionFromPayment($input: RevertTransactionFromPaymentInput!) {
    revertTransactionFromPaymentRequest(input: $input) {
      __typename
      ... on UnauthorizedError {
        error {
          code
          message
          details
          context
        }
      }
      ... on RevertTransactionFromPaymentSuccess {
        paymentTerminalTransaction {
          id
          receiptId
          previousTransaction
          transactionStatus
          transactionType
          transactionId
          transactionUniqueId
          authorizationCode
          amount
          referenceNumber
          transactionTime
          panMaskedForCustomer
          panMaskedForClerk
          cardReadingMethod
          terminalId
          terminalName
          merchantReceipt
          customerReceipt
          created
          updated
          deletedAt
        }
        success
      }
    }
  }
`;
