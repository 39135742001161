import { FieldProps, getIn } from 'formik'
import React, { useCallback, useState } from 'react'
import { Maybe } from '../../../../../common/types'
import { Error, FieldContainer, FormikField as Field, Label } from '../../style'

interface Props {
  id: string
  label?: string
  name: string
  disabled?: boolean
  required?: boolean
  decimals?: number
}

export const NumberField: React.FunctionComponent<Props & FieldProps> = ({
  id,
  field,
  form,
  label,
  disabled = false,
  required,
  decimals = 2,
}) => {
  const fieldValue: Maybe<number> = field.value

  const [value, setValue] = useState<string>(() => {
    if (fieldValue != null) {
      return fieldValue.toFixed(decimals).toString()
    }

    return ''
  })

  const setFieldValue = useCallback(
    (floatValue: number) => {
      if (isNaN(floatValue)) {
        form.setFieldValue(field.name, null)
      } else {
        const fixed = setDecimals(floatValue, decimals)

        form.setFieldValue(field.name, fixed)
      }
    },
    [form, field, decimals]
  )

  const setDecimals = (val: number, noOfDecimals: number) => parseFloat(val.toFixed(noOfDecimals))

  const handleChange = useCallback(
    event => {
      const targetValue = event.target.value

      setValue(targetValue)

      setFieldValue(parseFloat(targetValue))
      form.setFieldTouched(field.name, true)
    },
    [field, form, setFieldValue]
  )

  const handleBlur = useCallback(
    event => {
      const floatValue = parseFloat(event.target.value)

      setValue(floatValue.toFixed(decimals))

      setFieldValue(setDecimals(floatValue, decimals))
    },
    [setFieldValue, decimals]
  )

  const fieldError = getIn(form.errors, field.name)
  const fieldTouched = getIn(form.touched, field.name)
  const hasErrors = Boolean(fieldError) && Boolean(fieldTouched)

  return (
    <FieldContainer>
      {label && <Label required={required}>{label}</Label>}
      <Field
        id={id}
        type="number"
        step={fraction(decimals)}
        name={field.name}
        value={value}
        disabled={disabled}
        onChange={handleChange}
        autoComplete="off"
        onBlur={handleBlur}
      />
      {hasErrors && <Error className="error-tooltip">{fieldError}</Error>}
    </FieldContainer>
  )
}

const fraction = (decimals: number) => (1 / Math.pow(10, decimals)).toString()
