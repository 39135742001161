import React from 'react'
import { CurrentWorkShift } from '../../containers/Shifts/types'
import { Styling } from '../Styling'
import { Table, TableHeader, TableRow } from './layout'

interface ShiftTableProps {
  shift: CurrentWorkShift
}

export const ShiftTable: React.FunctionComponent<ShiftTableProps> = ({ shift }) => {
  const { events } = shift

  if (!events) return null

  return (
    <>
      <Styling padding="1rem" paddingMobile="0.5rem">
        <Table>
          <thead>
            <TableHeader />
          </thead>
          <tbody>
            {events.map((event, key) => {
              return <TableRow event={event} key={key} />
            })}
          </tbody>
        </Table>
      </Styling>
    </>
  )
}
