import React from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { notificationOptions } from '../../../../../common/options'
import { Maybe, UserRole } from '../../../../../common/types'
import { FormSubGroup, FormSubGroupWrapper, Section, SectionColumn } from '../../../../../components/layout'
import { TowingTypeInput, StationInput, OperatorInput, SelectInput } from '../../../../../components/layout/form/input'
import { Notifications } from '../../../../Parameters/types'
import { acceptedRoles } from '../../../../TowingOrderPage/AddTowingOrder/TowingOrderSettingsForm'
import { messages } from './messages'

interface TowingRecordSettingsProps extends InjectedIntlProps {
  isAl?: boolean
  disableOperator?: boolean
  notificationSettings?: Maybe<Notifications>
  userRole?: Maybe<UserRole>
  isSos?: boolean
}

const TowingRecordSettingsIntl: React.FunctionComponent<TowingRecordSettingsProps> = ({
  intl,
  notificationSettings,
  userRole,
  isSos,
  disableOperator = true,
  isAl = false,
}) => {
  const { formatMessage } = intl

  if (!isSos && notificationSettings && notificationSettings.enabled && userRole && acceptedRoles.includes(userRole)) {
    return (
      <FormSubGroupWrapper>
        <FormSubGroup>
          <Section marginBottom="0" margin="0.5rem 0" columns={2}>
            <SectionColumn>
              <TowingTypeInput label={formatMessage(messages.settings_type)} name="label" isAl={isAl} disabled={isAl} />
            </SectionColumn>
            <SectionColumn>
              <StationInput label={formatMessage(messages.settings_station)} name="stationId" />
            </SectionColumn>
          </Section>
          <Section columns={2}>
            <SectionColumn>
              <OperatorInput
                label={formatMessage(messages.settings_operator)}
                name="operatorId"
                disabled={disableOperator}
                clearable
                placeholder="Ei osoitettu"
              />
            </SectionColumn>
            <SectionColumn>
              <SelectInput
                label={'Seurantalinkki'}
                name="notificationMethod"
                placeholder="Älä lähetä"
                options={notificationOptions}
              />
            </SectionColumn>
          </Section>
        </FormSubGroup>
      </FormSubGroupWrapper>
    )
  }

  return (
    <FormSubGroupWrapper>
      <FormSubGroup>
        <Section marginBottom="0" margin="0.5rem 0" columns={3}>
          <SectionColumn>
            <TowingTypeInput label={formatMessage(messages.settings_type)} name="label" isAl={isAl} disabled={isAl} />
          </SectionColumn>
          <SectionColumn>
            <StationInput label={formatMessage(messages.settings_station)} name="stationId" />
          </SectionColumn>
          <SectionColumn>
            <OperatorInput
              label={formatMessage(messages.settings_operator)}
              name="operatorId"
              disabled={disableOperator}
            />
          </SectionColumn>
        </Section>
      </FormSubGroup>
    </FormSubGroupWrapper>
  )
}

export const TowingRecordSettings = injectIntl(TowingRecordSettingsIntl)
