import ApolloClient from 'apollo-client'
import { FieldProps, FormikProps, getIn } from 'formik'
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { ApolloConsumer } from 'react-apollo'
import Autosuggest, { InputProps, SuggestionsFetchRequestedParams } from 'react-autosuggest'
// import styled from 'styled-components'
import { v4 as uuid4 } from 'uuid'

// import { Icon } from '../../../../icons/Icon'
import { Error, FormikField as Field, Label } from '../../style'
import { GEOLOCATION_AUTOSUGGEST_ADDRESS, GEOLOCATION_AUTOSUGGEST_ADDRESS_DETAILS } from './queries'
import { AutosuggestFieldContainer, StyledInput } from './styles'
import {
  GeolocationAutocompleteAddressDetails,
  GeolocationAutocompleteAddressDetailsResponse,
  GeolocationAutocompleteAddressResponse,
  Suggestion,
} from './types'

// const StyledIcon = styled(Icon)`
//   position: absolute;
//   right: 2px;
//   bottom: 2px;
//   width: 34px;
//   height: 34px;
// `
interface Props {
  id: string
  label?: string
  name: string
  disabled?: boolean
  required?: boolean
  placeholder?: string
  onAutoSuggestComplete?: (details: GeolocationAutocompleteAddressDetails, form: FormikProps<any>) => void
  onValueChange?: (value: string, form: FormikProps<any>) => void
  onBlur?: (form: FormikProps<any>) => void
  coordsName: string
  zipcodeName: string
  includeNameInAddress: boolean
}

const getSuggestionValue = (suggestion: Suggestion): string => suggestion.placeId

const renderSuggestion = (suggestion: Suggestion): React.ReactNode => (
  <div>
    <b>{suggestion.main} </b>
    {suggestion.secondary}
  </div>
)

const inputChanged = (
  value: string,
  setValue: Dispatch<SetStateAction<string>>,
  form: FormikProps<any>,
  fieldName: string,
  onValueChange?: (value: string, form: FormikProps<any>) => void
) => {
  setValue(value)

  form.setFieldValue(fieldName, value === '' ? null : value)
  form.setFieldTouched(fieldName, true)

  if (onValueChange) {
    onValueChange(value, form)
  }
}

interface CustomAddressInputProps extends InputProps<Suggestion> {
  disabled?: boolean
  hasCoordinates?: boolean
}

const CustomAddressInput: React.FunctionComponent<CustomAddressInputProps> = ({
  inputProps,
  disabled,
  hasCoordinates,
}) => <StyledInput {...inputProps} disabled={disabled} hasCoordinates={hasCoordinates} />

export const AddressField: React.FunctionComponent<Props & FieldProps> = ({
  id,
  field,
  form,
  label,
  placeholder,
  onAutoSuggestComplete,
  onValueChange,
  onBlur,
  coordsName,
  required,
  includeNameInAddress = true,
  disabled = false,
}) => {
  const [value, setValue] = useState<string>(field.value || '')
  const [suggestions, setSuggestions] = useState<Suggestion[]>([])
  const [sessionToken, setSessionToken] = useState<string | null>(null)
  const [dropdownDirection, setDropDowndirection] = useState<string>('')

  useEffect(() => {
    setValue(field.value || '')
  }, [field.value])

  const location = coordsName.split('.').reduce((o, i) => o[i], form.values)
  // const zipcode = zipcodeName.split('.').reduce((o, i) => o[i], form.values)

  const handleChange = useCallback(
    (client: ApolloClient<any>) => async (_: any, { newValue, method }: { newValue: string; method: string }) => {
      if (method === 'click') {
        const token = sessionToken || uuid4()

        const { data }: GeolocationAutocompleteAddressDetailsResponse = await client.query({
          variables: { placeId: newValue, sessionToken: token, includeName: includeNameInAddress },
          query: GEOLOCATION_AUTOSUGGEST_ADDRESS_DETAILS,
        })

        if (data.autocompleteAddressDetails.__typename === 'GoogleAPIGeolocationError') {
          console.error('Failed to fetch google places autocomplete details')
          inputChanged(newValue, setValue, form, field.name, onValueChange)
          return
        }

        inputChanged(data.autocompleteAddressDetails.details.address, setValue, form, field.name, onValueChange)

        if (onAutoSuggestComplete) {
          onAutoSuggestComplete(data.autocompleteAddressDetails.details, form)
        }

        setSessionToken(null)
      } else {
        inputChanged(newValue, setValue, form, field.name, onValueChange)
      }
    },
    [field, form, onAutoSuggestComplete, onValueChange, sessionToken, includeNameInAddress]
  )

  const onSuggestionsFetchRequested = useCallback(
    (client: ApolloClient<any>) => async ({ value: search }: SuggestionsFetchRequestedParams) => {
      const token = sessionToken || uuid4()

      if (!sessionToken) {
        setSessionToken(token)
      }

      const { data }: GeolocationAutocompleteAddressResponse = await client.query({
        variables: { search, sessionToken: token },
        query: GEOLOCATION_AUTOSUGGEST_ADDRESS,
      })

      if (data.autocompleteAddress.__typename === 'GoogleAPIGeolocationError') {
        console.error('Failed to fetch google places autocomplete suggestions')
        return
      }

      setSuggestions(data.autocompleteAddress.predictions)
    },
    [sessionToken]
  )

  const onSuggestionsClearRequested = useCallback(() => {
    setSuggestions([])
  }, [])

  const handleBlur = useCallback(
    () => () => {
      if (!location.lat && !location.long && field.value && onBlur) {
        onBlur(form)
      }
    },
    [location.lat, location.long, field.value, onBlur, form]
  )

  const fieldError = getIn(form.errors, field.name)
  const fieldTouched = getIn(form.touched, field.name)
  const hasErrors = Boolean(fieldError) && Boolean(fieldTouched)

  return (
    <AutosuggestFieldContainer
      onChange={() => {
        const bounding = document.querySelector('.react-autosuggest__suggestions-container')!.getBoundingClientRect()
        const isOutOfView = bounding.bottom + 120 > (window.innerHeight || document.documentElement.clientHeight)

        if (isOutOfView) {
          setDropDowndirection('top')
        } else {
          setDropDowndirection('bottom')
        }
      }}
      direction={dropdownDirection}
    >
      {label && <Label required={required}>{label}</Label>}
      <ApolloConsumer>
        {client => (
          <>
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested(client)}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              inputProps={{
                placeholder,
                value,
                onChange: handleChange(client),
                onBlur: handleBlur(),
              }}
              renderInputComponent={(inputProps: InputProps<Suggestion>) => (
                <Field
                  id={id}
                  component={CustomAddressInput}
                  name={field.name}
                  disabled={disabled}
                  placeholder={placeholder}
                  inputProps={inputProps}
                  hasCoordinates={location.lat && location.long}
                />
              )}
            />
            {/* {location.lat && location.long && <StyledIcon icon="done" color="green800" />} */}
          </>
        )}
      </ApolloConsumer>

      {hasErrors && <Error className="error-tooltip">{fieldError}</Error>}
    </AutosuggestFieldContainer>
  )
}
