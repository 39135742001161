import { TowingRecordJobStatus, TypeIdentifier } from '../../common/types'

export interface TrackedTowingRecord {
  id: number
  jobStatus: TowingRecordJobStatus
  type: string
  typeIdentifier: TypeIdentifier
}

export const trackedStatuses = [
  TowingRecordJobStatus.started,
  TowingRecordJobStatus.arrived_at_breakdown_location,
  TowingRecordJobStatus.finished_at_breakdown_location,
  TowingRecordJobStatus.arrived_at_destination,
  TowingRecordJobStatus.finished_at_destination,
]

export const trackedSosStatuses = [TowingRecordJobStatus.started, TowingRecordJobStatus.arrived_at_breakdown_location]

export interface GeolocationPosition {
  coords: {
    latitude: number
    longitude: number
    accuracy: number
    altitude: number | null
    altitudeAccuracy: number | null
    heading: number | null
    speed: number | null
  }
}

export const geolocationOptions = {
  enableHighAccuracy: true,
  timeout: 20000,
  maximumAge: 2000,
}

export interface ReportLocationErrors {
  errorCount: number
  authorized: boolean
}
