import { defineMessages } from 'react-intl'

export const rejectMessages = defineMessages({
  cancel_success_title: { id: 'towingRecord.cancel.success.notification.title', defaultMessage: 'Success' },
  cancel_success_message: { id: 'towingRecord.cancel.success.notification.message', defaultMessage: 'Success' },
  cancel_error_title: { id: 'towingRecord.cancel.error.notification.title', defaultMessage: 'Error' },
  cancel_error_message: { id: 'towingRecord.cancel.error.notification.message', defaultMessage: 'Error' },
  invalid_value_title: { id: 'containers.graphql.invalid_param.title', defaultMessage: 'Error' },
  invalid_value_message: { id: 'containers.graphql.invalid_param.message', defaultMessage: 'Invalid value' },
})
