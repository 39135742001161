import { FieldProps, getIn } from 'formik'
import React from 'react'
import styled from 'styled-components'

import { Error, FormikField as Field } from '../../style'

type Props = OwnProps & FieldProps
interface OwnProps {
  id: string
  label: string
  placeholder?: string
  required?: boolean
  componentName?: string
  disabled?: boolean
}
export function RadioField(props: Props): JSX.Element {
  const { field, form, id, label, placeholder, componentName, disabled = false } = props

  const fieldError = getIn(form.errors, field.name)
  const fieldTouched = getIn(form.touched, field.name)
  const hasErrors = Boolean(fieldError) && Boolean(fieldTouched)

  return (
    <>
      <RadioInput
        disabled={disabled}
        type="radio"
        id={id}
        value={id}
        checked={id === field.value}
        name={componentName}
        placeholder={placeholder}
        onChange={field.onChange}
      />
      <RadioLabel htmlFor={id} disabled={disabled}>
        ​{label}
      </RadioLabel>
      ​{hasErrors && <Error className="error-tooltip">{fieldError}</Error>}
    </>
  )
}
const RadioInput = styled(Field)`
  margin-right: 0.25rem;
  position: absolute;
  visibility: hidden;
  &:checked + label {
    background-color: ${props => props.theme.colors.green800};
    color: ${props => props.backgroundColor !== 'green800' ? 'white' : props.theme.colors.black};
  }
 
`

interface RadioLabelProps {
  readonly disabled: boolean
}

const RadioLabel = styled.label<RadioLabelProps>`
  padding: 0.5rem 1rem;
  text-align: center;
  border: 1px solid ${props => props.theme.colors.grey300};
  margin-right: -1px;
  color: ${props => props.theme.colors.black};
  background-color: ${props => (props.disabled ? props.theme.colors.grey200 : props.theme.colors.white)};

  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    font-size: 0.8rem;
  }
  &:first-of-type {
    border-radius: ${props => props.theme.radius.large} 0 0 ${props => props.theme.radius.large};
  }

  &:last-of-type {
    border-radius: 0 ${props => props.theme.radius.large} ${props => props.theme.radius.large} 0;
  }
`
