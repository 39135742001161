import { TowingOrder } from "./types";
import { RepairShop, repairShops } from "../../TowingRecordPage/components/routes/subforms/FinishRoute/options";

export function getRepairShop(towingOrder: TowingOrder): RepairShop | undefined {

  if(towingOrder.automobileAndTouringClubFinland && towingOrder.automobileAndTouringClubFinland.repairShopId){

    const repairShopId = towingOrder.automobileAndTouringClubFinland.repairShopId;
    return repairShops.find(shop => shop.id === repairShopId);
  }
}
