import React, { FunctionComponent } from 'react'
import { RouteComponentProps } from 'react-router'
import { ViewTowingOrderPage } from './ViewTowingOrderPage'

interface Props {
  id?: string
}

export const ViewTowingOrder: FunctionComponent<RouteComponentProps<Props>> = ({
  match: {
    params: { id },
  },
}) => {
  const towingOrderId = id ? parseInt(id, 10) : null

  if (!towingOrderId) {
    return null
  }

  return <ViewTowingOrderPage id={towingOrderId} />
}
