import React, { useState } from 'react'
import styled from 'styled-components'
import { Icon } from '../icons/Icon'
import { Label } from '../layout'

interface ClipboardButtonProps {
  copy: string
  copyLabel?: string
}

export const ClipboardButton = ({ copy, copyLabel = 'Kopioitu' }: ClipboardButtonProps) => {
  const [copied, setCopied] = useState(false)

  const handleCopyToClipboard = () => {
    setCopied(true)
    navigator.clipboard.writeText(copy)
    
    setTimeout(() => {
      setCopied(false)
    }, 1000)
  }

  const iconColor = copied ? 'grey400' : ''

  return copy ? (
    <Button onClick={handleCopyToClipboard}>
      <Icon color={iconColor} size="medium" icon={'copy'} />
      {copied ? <CopyLabel> {copyLabel} </CopyLabel> : null}
    </Button>
  ) : null
}

const Button = styled.button`
  border: none;
  position: relative;
  cursor: copy;
  background-color: transparent;
`

const CopyLabel = styled(Label)`
  position: absolute;
  top: -34px;
  right: -8px;
  background-color: ${props => props.theme.colors.white};
  padding: 5px;

  border: 1px;
  border-radius: 5px;
  border-color: red;
  border-color: ${props => props.theme.colors.grey400};
  border-style: solid;
`
