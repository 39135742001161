import React, { FunctionComponent } from 'react'
import { InjectedIntlProps, defineMessages, injectIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { NotificationDelay } from '../../common/types'
import { ErrorNotification } from './errorNotification'
import { dismissToast } from '.'

const toastId = 'google_geolocation_error'

const messages = defineMessages({
  googleAPIGeolocationErrorTitle: {
    id: 'containers.graphql.google_api_geolocation_error.title',
    defaultMessage: 'Getting coordinates for the address failed.',
  },
  googleAPIGeolocationErrorMessage: {
    id: 'containers.graphql.google_api_geolocation_error.message',
    defaultMessage: 'The resource that you are looking for does not exist',
  },
})

export const setGoogleAPIGeolocationErrorNotification = () => {
  if (!toast.isActive(toastId)) {
    const toastId = toast.error(<GoogleAPIGeolocationErrorNotification />, {
      autoClose: false,
      closeButton: false,
      delay: 0,
      toastId: 'google_geolocation_error',
    })
    dismissToast(toastId, NotificationDelay.googleAPIGeolocationErrorNotificationDelay)
  }
}

const GoogleAPIGeolocationErrorNotificationIntl: FunctionComponent<InjectedIntlProps> = ({ intl }) => {
  const { formatMessage } = intl

  return (
    <ErrorNotification
      title={formatMessage(messages.googleAPIGeolocationErrorTitle)}
      message={formatMessage(messages.googleAPIGeolocationErrorMessage)}
    />
  )
}

export const GoogleAPIGeolocationErrorNotification = injectIntl(GoogleAPIGeolocationErrorNotificationIntl)
