import React, { useState } from 'react'
import Linkify from 'react-linkify'

import { Maybe, TowingRecordRouteType, TypeIdentifier } from '../../../../common/types'
import { FlexContainer, Section, SectionTextColumn, Text } from '../../../../components/layout'
import { SectionBody, SectionHeader } from '../../../../components/layout/section'
import LinkButton from '../../../../components/LinkButton'
import { parseAddress } from '../../../../components/Parsers'
import { PhoneNumber } from '../../../../components/PhoneNumber'
import { Styling } from '../../../../components/Styling'
import { buildJobDetails } from '../../../../components/TowingCommon/JobDetails'
import {
  transformDrive,
  transformFuel,
  transformTransmission,
  transformVehicleClass,
} from '../../../../components/VehicleSearchModal/transformers'
import { VehicleInfo } from '../../../../components/VehicleSearchModal/types'
import { toMultilineText } from '../../../../util/common'
import { getIndexOfRoute } from '../routes/getIndexOfRoute'
import { SummaryTowingRecord } from './types'
import { getRepairShop } from './getRepairShop'
import { MapLink } from '../../../../components/MapLink'
import { Images } from '../../../../components/images'
import { ClipboardButton } from '../../../../components/ClipboardButton'

interface Props {
  towingRecord: SummaryTowingRecord
  onVehicleInfoLinkClick: () => void
  vehicleInfoCheck: Maybe<VehicleInfo>
  refetchData?: () => void
  imagesCount: number
}

export const SummaryBody: React.FC<Props> = ({ towingRecord, onVehicleInfoLinkClick, vehicleInfoCheck, refetchData, imagesCount }) => {
  const [showImageModal, setShowImageModal] = useState<boolean>(false)

  const fromRoute = getIndexOfRoute(towingRecord.routes, TowingRecordRouteType.arrival)
  const toRoute = getIndexOfRoute(towingRecord.routes, TowingRecordRouteType.finish)
  const repairShop = getRepairShop(towingRecord)

  const toggleImageModal = () => {
    if(showImageModal && refetchData) refetchData()
    setShowImageModal(!showImageModal)
  }

  return (
    <Styling padding="0 2rem" paddingMobile="0 0.5rem">
      <Section gap="2rem" justify="text" columns={2}>
        <SectionTextColumn width="100%">
          <SectionHeader>
            <Text size="medium">Ajoneuvotiedot</Text>
            <LinkButton onClick={onVehicleInfoLinkClick}>ATJ-tiedot</LinkButton>
          </SectionHeader>
          <SectionBody>
            {vehicleInfoCheck ? (
              <>
                <Text size="medium">{transformVehicleClass(vehicleInfoCheck.vehicle.vehicleClass)}</Text>
                <Text size="medium">
                  {`${transformTransmission(vehicleInfoCheck.vehicle.transmission)} / ${transformDrive(
                    vehicleInfoCheck.vehicle.driveTrain
                  )} / ${transformFuel(vehicleInfoCheck.vehicle.fuel)}`}
                </Text>
              </>
            ) : (
              <Text size="medium">{towingRecord.vehicleDetails.vehicleClass}</Text>
            )}
          </SectionBody>
        </SectionTextColumn>
        <SectionTextColumn width="100%">
          <SectionHeader>
            <Text size="medium">Asiakas</Text>
          </SectionHeader>
          <SectionBody>
            {towingRecord.orderer && towingRecord.orderer.__typename === 'PersonCustomer' && (
              <Text size="medium">{`${towingRecord.orderer.firstName} ${towingRecord.orderer.lastName}`}</Text>
            )}
            {towingRecord.orderer && towingRecord.orderer.__typename === 'CompanyCustomer' && (
              <Text size="medium">{`${towingRecord.orderer.companyName}`}</Text>
            )}
            {towingRecord.orderer && <PhoneNumber size="medium" phone={towingRecord.orderer.phone} />}
          </SectionBody>
        </SectionTextColumn>
        <SectionTextColumn width="100%">
          <SectionHeader>
            <Text size="medium">Mistä</Text>
          </SectionHeader>
          <SectionBody>
            {fromRoute > -1 ? (
              <>
                <FlexContainer style={{gap: "10px"}}>
                  <MapLink
                    size="medium"
                    address={parseAddress(towingRecord.routes[fromRoute].address, towingRecord.routes[fromRoute].city)}
                  />
                  <ClipboardButton copy={parseAddress(towingRecord.routes[fromRoute].address, towingRecord.routes[fromRoute].city)}/>
                </FlexContainer>
                <Text size="medium">{`${towingRecord.routes[fromRoute].description}`}</Text>
              </>
            ) : (
              <Text>-</Text>
            )}
          </SectionBody>
        </SectionTextColumn>
        <SectionTextColumn width="100%">
          <SectionHeader>
            <Text size="medium">Mihin</Text>
          </SectionHeader>
          <SectionBody>
            {toRoute > -1 && towingRecord.routes[toRoute].address && towingRecord.routes[toRoute].city ? (
              <>
              <FlexContainer style={{gap: "10px"}}>
                  <MapLink
                    size="medium"
                    address={parseAddress(towingRecord.routes[toRoute].address, towingRecord.routes[toRoute].city)}
                  />
                  <ClipboardButton copy={parseAddress(towingRecord.routes[toRoute].address, towingRecord.routes[toRoute].city)}/>
                </FlexContainer>
                <Text size="medium">{`${towingRecord.routes[toRoute].description}`}</Text>
              </>
            ) : repairShop &&
              towingRecord.typeIdentifier === TypeIdentifier.autoliitto &&
              towingRecord.jobDetails.serviceType !== 'towing' ? (
              <>
                <Text size="medium" fontWeight="bold">
                  Jos työ muuttuu hinaukseksi:
                </Text>
                <Text size="medium">{parseAddress(repairShop.location.address, repairShop.location.city)}</Text>
                <Text size="medium">{repairShop.name}</Text>
              </>
            ) : (
              <Text>-</Text>
            )}
          </SectionBody>
        </SectionTextColumn>
        {buildJobDetails(towingRecord)}
        {towingRecord.typeIdentifier !== TypeIdentifier.sos && (
          <SectionTextColumn width="100%">
            <SectionHeader>
              <Text size="medium">Lisäselvitykset</Text>
            </SectionHeader>
            <SectionBody>
              <Linkify>{toMultilineText(towingRecord.jobDetails.additionalInfo)}</Linkify>
            </SectionBody>
          </SectionTextColumn>
        )}
        <SectionTextColumn width="100%">
          <SectionHeader>
            <Text size="medium">Muistiinpanot</Text>
          </SectionHeader>
          <SectionBody>
            <Linkify>{toMultilineText(towingRecord.jobDetails.notes)}</Linkify>
          </SectionBody>
        </SectionTextColumn>
      </Section>
      {showImageModal && (
        <Images
          onClose={toggleImageModal}
          towingRecordId={towingRecord.id}
          vehicleRegNo={towingRecord.vehicleDetails.makeAndModel}
        />
      )}
      <SectionTextColumn width="100%">
        <SectionHeader>
          <Text size="medium">Kuvat</Text>
        </SectionHeader>
        <SectionBody>
          <Linkify>
            <LinkButton onClick={toggleImageModal}>
              {imagesCount > 0 ? `Lisättyjä kuvia (${imagesCount})` : `Ei lisättyjä kuvia`}
            </LinkButton>
          </Linkify>
        </SectionBody>
      </SectionTextColumn>
    </Styling>
  )
}
