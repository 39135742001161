import { FieldProps, getIn } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { ValueType } from 'react-select/src/types'

import { Error, FieldContainer, Label } from '../../../../layout'
import { SelectField as Select } from '../../style'

// tslint:disable-next-line: no-submodule-imports

type Props = OwnProps & FieldProps

interface OwnProps {
  options: Option[]
  label?: string
  required?: boolean
  clearable?: boolean
  onChange: (arg: string | number | undefined | null) => void
  autoSelectOnlyOption?: boolean
  searchable?: boolean
  placeholder?: string
  disabled: boolean
  unsetAfterSelect?: boolean
  hideArrow: boolean
}

interface Option {
  label: string
  value: string
}

export const SelectField: React.FunctionComponent<Props> = ({
  clearable,
  field,
  form,
  onChange,
  options,
  label,
  placeholder,
  required,
  searchable,
  disabled,
  autoSelectOnlyOption,
  unsetAfterSelect = false,
  hideArrow = false
}) => {
  const findOption = useCallback(
    (val: string) => {
      const option = options.find(o => o.value === val)
      if (option) {
        return {
          label: option.label,
          value: val,
        }
      }
      return null
    },
    [options]
  )

  const [selectedOption, setSelectedOption] = useState<ValueType<Option>>(() => {
    if (field.value) {
      const initialOption = options.find(option => option.value === field.value)

      if (initialOption) {
        return {
          label: initialOption.label,
          value: field.value,
        }
      }
    }

    return null
  })

  const handleAutoSelect = useCallback(
    (value: any) => {
      setSelectedOption(findOption(value))

      if (onChange && value !== field.value) {
        onChange(value)
      }

      form.setFieldValue(field.name, value)
      form.setFieldTouched(field.name, true)
    },
    [form, field, onChange, findOption]
  )

  useEffect(() => {
    setSelectedOption(findOption(field.value))
  }, [field.value, findOption])

  useEffect(() => {
    if (autoSelectOnlyOption && options.length === 1 && !selectedOption) {
      handleAutoSelect(options[0].value || '')
    }
  }, [options, selectedOption, autoSelectOnlyOption, handleAutoSelect])

  const handleChange = useCallback(
    (option: ValueType<Option>) => {
      if (!Array.isArray(option)) {
        if(unsetAfterSelect){
          onChange(option ? (option as Option).value : null)
          setSelectedOption(null)
          return;
        }
        setSelectedOption(option || null)
        const value = option ? (option as Option).value : null
        // for performing custom operations when the field value changes
        if (onChange && value !== field.value) {
          onChange(value)
        }

        form.setFieldValue(field.name, option ? value : null)
        form.setFieldTouched(field.name, true)
      }
    },
    [field, form, onChange, unsetAfterSelect]
  )

  const handleNoOptionsMessage = useCallback(() => '- Ei tuloksia -', [])

  const fieldError = getIn(form.errors, field.name)
  const fieldTouched = getIn(form.touched, field.name)
  const hasErrors = Boolean(fieldError) && Boolean(fieldTouched)

  return (
    <FieldContainer>
      {label && (
        <Label htmlFor={field.name} required={required}>
          {label}
        </Label>
      )}

      <Select
        classNamePrefix={'Select'}
        isDisabled={disabled}
        value={selectedOption}
        onChange={handleChange}
        options={options}
        placeholder={placeholder ? placeholder : 'Valitse...'}
        noOptionsMessage={handleNoOptionsMessage}
        isClearable={clearable}
        isSearchable={searchable}
        menuPlacement="auto"
        hideArrow={hideArrow}
      />
      {hasErrors && field.name !== '' && <Error className="error-tooltip">{fieldError}</Error>}
    </FieldContainer>
  )
}
