import gql from 'graphql-tag'

export const EDIT_ORDER_SCHEDULE = gql`
  mutation EditOrderSchedule($input: EditOrderScheduleInput!) {
    editOrderSchedule(input: $input) {
      ... on EditOrderScheduleSuccess {
        towingOrder {
          id
        }
      }
      ... on ResourceNotFoundError {
        error {
          code
          message
        }
      }
      ... on UnauthorizedError {
        error {
          code
          message
        }
      }
      ... on InvalidParameterError {
        error {
          code
          message
        }
      }
    }
  }
`
