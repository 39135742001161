import React from 'react'
import styled from 'styled-components'
import { Icon } from '../icons/Icon'
import { setInfoNotification } from '../notification'

interface OfflineIconProps {
  offline: boolean
}

interface OfflineIconState {
  colorIdx: number
}

const colorArray = ['grey500', 'red600']

export class OfflineIcon extends React.Component<OfflineIconProps, OfflineIconState> {
  public timeout = 0
  constructor(props: OfflineIconProps) {
    super(props)
    this.state = { colorIdx: 0 }
  }

  public componentDidMount() {
    this.timeout = setInterval(() => {
      const currentIdx = this.state.colorIdx
      this.setState({ colorIdx: currentIdx + 1 })
    }, 1000)
  }

  public componentWillUnmount() {
    clearInterval(this.timeout)
  }

  public render() {
    if (!this.props.offline) {
      return null
    }

    const displayInfoMessage = () => {
      setInfoNotification(
        'Yhteysongelma',
        'Järjestelmä siirretty offline-tilaan. Offline-tilassa käytössä on vain ominaisuudet, jotka eivät edellytä internet yhteyttä. Kuormakirja ja työhön liittyvät kirjaukset tallentuu väliaikaisesti laitteen muistiin ja siirtyvät palvelimelle, kun yhteys on jälleen muodostettu.'
      )
    }

    const color = colorArray[this.state.colorIdx % colorArray.length]

    return (
      <Container onClick={() => displayInfoMessage()}>
        <IconWrapper>
          <Icon color={color} icon="info" size="offline" />
        </IconWrapper>
      </Container>
    )
  }
}

export const OfflineMenuIcon = styled(OfflineIcon)``

export const OfflineIconContainer = styled.div`
  position: fixed;
  bottom: 0.75rem;
  right: 0.75rem;
  z-index: 1000;
  cursor: pointer;
`

const Container = styled.div`
  margin: 0;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    display: none;
  }
`
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 2.5rem;
`
