import gql from 'graphql-tag'
import { DeleteBillingInputMutation } from './types'

export interface DeleteBillingMutationVariables
  extends Readonly<{
    input: DeleteBillingInputMutation
  }> {}

export const DELETE_BILLING = gql`
  mutation DeleteBilling($input: DeleteBillingInput!) {
    deleteBilling(input: $input) {
      __typename
      ... on UnauthorizedError {
        error {
          code
          message
        }
      }
      ... on GenericError {
        genericError: error {
          code
          message
        }
      }
      ... on DeleteBillingSuccess {
        success
      }
    }
  }
`
