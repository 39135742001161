import React, { FunctionComponent, useEffect, useState } from 'react'
import { getStoredBackendNotifications } from '../../graphql-client/storedState'
import { BackendNotification } from '../../util/notifications/types'
import { RouteWrapper } from '../layout'
import { FullscreenModal } from '../ModalPage'
import BackendNotificationList from './BackendNotificationList'

const BackendNotificationModal: FunctionComponent = () => {
  const [notifications, setNotifications] = useState<BackendNotification[]>([])

  useEffect(() => {
    const handleStorage = (event?: Event) => {
      const storedNotifications = getStoredBackendNotifications()
      console.log('storageEvent', event, storedNotifications)
      if (storedNotifications) {
        setNotifications(storedNotifications)
      }
    }

    handleStorage()

    window.addEventListener('storage', handleStorage)
    return () => window.removeEventListener('storage', handleStorage)
  }, [])

  return (
    <>
      {notifications.length > 0 && (
        <FullscreenModal onClose={() => {}} label="" showHeader={false}>
          <RouteWrapper />
          <BackendNotificationList notifications={notifications} />
        </FullscreenModal>
      )}
    </>
  )
}

export default BackendNotificationModal
