import React, { useContext, useEffect, useState } from 'react'
import { CollapseContent } from '../../../../../../../components/Collapse/CollapseContent'
import { RouteColumn, RouteRow } from '../../../../../../../components/layout'
import { SelectInput } from '../../../../../../../components/layout/form/input'
import { FinishedDateTimeInput } from '../../../../../../../components/layout/form/input/DateTimeInput'
import { format } from 'date-fns'
import { Time } from '@google/maps'
import { TowingRecordJobStatus } from '../../../../../../../common/types'
import { StateStore } from '../../../../../../StoreProvider'
import { serviceTypeOptions } from '../../../../../../TowingOrderPage/options'
import { ServiceType } from '../../../../../../../functions/graphqlApi/types/jobDetails'
import { Modal } from '../../../../../../../components/Modal'
import { DefaultProps, FinishedAtBreakdownLocationProps } from './FinishedForm'

export const FinishedAtBreakdownLocationForm: React.FunctionComponent<
  FinishedAtBreakdownLocationProps & DefaultProps
> = ({ setValue, getValue, action, jobStatus, autoliittoFields }) => {
  const [showModal, setShowModal] = useState(false)
  const [warned, setWarned] = useState(() => {
    if (autoliittoFields.hasFinishRouteInfo) return false
    return true
  })

  const [edit, setEdit] = useState<boolean>(
    action === 'edit-finished' || jobStatus === TowingRecordJobStatus.arrived_at_breakdown_location
  )
  const { state } = useContext(StateStore)

  const handleOnServiceTypeChange = (serviceType: string | number | undefined): void => {
    const alert = !warned && (serviceType !== 'towing' && serviceType !== 'other')
    if (alert) {
      setWarned(true)
      setShowModal(true)
    }
    setValue('serviceType', serviceType)
  }

  const options = (state.settings && state.settings.towingReasons) || []
  const towingReasonIsRequired = ![ServiceType.TOWING, ServiceType.OTHER].includes(getValue('serviceType'))

  useEffect(() => {
    if (action === 'edit-finished') {
      if (!edit) setEdit(true)
    } else {
      if (edit) setEdit(false)
    }
  }, [edit, action, setEdit])

  return (
    <CollapseContent>
      {showModal && (
        <Modal
          label={'Huomio'}
          closeLabel="Peruuta"
          message={`Valitsemalla toimenpiteeksi tiepalvelun, määränpään
              reittipisteen tiedot tyhjennetään tallennuksen yhteydessä.`}
          disableCloseOnConfirm
          onConfirm={() => {
            setShowModal(false)
          }}
          close={() => {
            setShowModal(false)
            setValue('serviceType', 'towing')
          }}
        />
      )}
      <RouteRow columns={`1fr 1fr 1fr`}>
        <RouteColumn>
          <SelectInput
            label="Toimenpide"
            name="serviceType"
            options={serviceTypeOptions}
            onChange={handleOnServiceTypeChange}
            disabled={!edit}
            required
          />
        </RouteColumn>
        <RouteColumn>
          <SelectInput
            label="Syy"
            name="towingReason"
            options={options.map((label: string) => ({
              label,
              value: label,
            }))}
            required={towingReasonIsRequired}
            disabled={!edit && autoliittoFields.disableTowingReason}
          />
        </RouteColumn>
        <RouteColumn>
          <FinishedDateTimeInput
            name="arrivalRoute"
            label="Valmistumisaika"
            customOnChange={() => {
              const value = (format(new Date(), 'HH:mm') as unknown) as Time
              setValue('arrivalRoute.finishedTime', value)
              setValue('arrivalRoute.finishedDate', new Date())
            }}
            required={edit}
            disabled={!edit}
            showCurrentTimeBtn
          />
        </RouteColumn>
      </RouteRow>
    </CollapseContent>
  )
}

export const finishedAtDestinationEditableJobStatuses = [TowingRecordJobStatus.finished_at_destination]
