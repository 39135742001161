import { Field } from 'formik'
import React, { useCallback } from 'react'
import { PersonalIdentityCodeField } from '../../field'
import ssn from 'finnish-ssn'

interface Props {
  name: string
  label: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
}

export function PersonalIdentityCodeInput(props: Props): JSX.Element {
  const { name, label, placeholder, required, disabled } = props

  const validate = useCallback(
    (value: string | null): string | undefined => {
      if (required && (!value || !value.trim())) {
        return 'Kenttä on pakollinen'
      }

      if (value && value.length < 10) {
        return 'Liian lyhyt Henkilötunnus'
      }

      if (value && !ssn.validate(value)) {
        return 'Virheellinen Henkilötunnus'
      }

      return undefined
    },
    [required]
  )

  return (
    <Field
      component={PersonalIdentityCodeField}
      id={name}
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      validate={validate}
      disabled={disabled}
    />
  )
}
