import { JobItemType, Maybe, SignatureType, TowingRecordPaymentType, TowingRecordRouteType, TowingRecordType } from '../../../common/types'
import { AutomobileAndTouringClubFinland } from '../../../components/ALSubForm/types'
import { SignatureInput } from '../../../components/Signature/types'
import { initialSosServiceOrder } from '../../../components/SosSubForm/types'
import { JobItemInput } from '../../../components/TowingRecordItems/types'
import { toCustomerInput } from '../../Customer/toCustomerInput'
import { initialOrderer } from '../AddTowingRecord/initialValues'
import { BillingInput } from '../components/billing/TowingRecordBilling/types'
import { getIndexOfRoute } from '../components/routes/getIndexOfRoute'
import { AutomobileAndTouringClubFinlandInput, EditTowingRecord, InitialValues } from './types'

export function getInitialValues(towingRecord: EditTowingRecord, defaultItemsIncludeVat: boolean, overRidePaymentType: Maybe<TowingRecordPaymentType> = null): InitialValues {
  const getJobItemsDiscount = (items: JobItemInput[] | null): JobItemInput | null => {
    if (!items) return null
    const result = items.filter(item => item.type === JobItemType.discount)
    if (result.length > 0) return result[0]
    return null
  }
  const canOverRidePaymentType = towingRecord.paymentType === TowingRecordPaymentType.define_later || !towingRecord.paymentType
  return {
    status: towingRecord.status,
    id: towingRecord.id,
    recordNumber: towingRecord.recordNumber,
    type: towingRecord.type,
    typeIdentifier: towingRecord.typeIdentifier,
    operatorId: towingRecord.operatorId,
    stationId: towingRecord.stationId,
    vehicleInfoCheckId: towingRecord.vehicleInfoCheckId ? towingRecord.vehicleInfoCheckId : null,
    paymentType: canOverRidePaymentType && overRidePaymentType ? overRidePaymentType : towingRecord.paymentType,
    label: towingRecord.label,
    itemsIncludeVat: towingRecord.jobItems.length === 0 ? defaultItemsIncludeVat : towingRecord.itemsIncludeVat,
    onCall: towingRecord.onCall,
    tags: towingRecord.tags,
    vehicleDetails: towingRecord.vehicleDetails,
    jobDetails: towingRecord.jobDetails,
    jobItems: towingRecord.jobItems.filter(jobItem => jobItem.type === JobItemType.normal),
    discountItem: getJobItemsDiscount(towingRecord.jobItems),
    routes: towingRecord.routes,
    billing: getInitialBilling(towingRecord),
    signature: getInitialSignature(towingRecord),
    orderer: towingRecord.orderer ? toCustomerInput(towingRecord.orderer) : initialOrderer,
    startPlaceOption: 'write_address',
    itemGroup: null,
    item: null,
    automobileAndTouringClubFinland: towingRecord.automobileAndTouringClubFinland
      ? toALInput(towingRecord.automobileAndTouringClubFinland)
      : null,
    lastModified: towingRecord.lastModified,
    sosServiceOrder:
      towingRecord.type === TowingRecordType.sos && !towingRecord.sosServiceOrder
        ? initialSosServiceOrder
        : towingRecord.sosServiceOrder,
    from: towingRecord.routes[getIndexOfRoute(towingRecord.routes, TowingRecordRouteType.arrival)],
    to: towingRecord.routes[getIndexOfRoute(towingRecord.routes, TowingRecordRouteType.finish)],
    notificationMethod: towingRecord.notificationMethod,
  }
}

function getInitialSignature(towingRecord: EditTowingRecord): SignatureInput {
  if (towingRecord.signature) {
    return { ...towingRecord.signature, agreements: [] }
  }
  let defaultSignatorName = ''
  if (towingRecord.orderer && towingRecord.orderer.__typename === 'CompanyCustomer') {
    defaultSignatorName = towingRecord.orderer.companyName
  }
  if (towingRecord.orderer && towingRecord.orderer.__typename === 'PersonCustomer') {
    defaultSignatorName = `${towingRecord.orderer.firstName} ${towingRecord.orderer.lastName}`
  }
  return {
    id: null,
    type: SignatureType.written,
    signatorEmail: '',
    signatorPhone: '',
    signatorName: defaultSignatorName,
    signedAt: null,
    agreements: [],
    createdAt: null,
    sentAt: null,
  }
}

function getInitialBilling(towingRecord: EditTowingRecord): BillingInput {
  const { billing } = towingRecord

  if (!billing || !billing.length) {
    return {
      type: null,
      paymentDetails: {
        id: null,
        debtorName: '',
        receiptEmail: '',
        paymentDate: new Date(),
      },
      paymentTerminalTransactions: [],
    }
  }

  const first = billing[0]

  return {
    type: first.type,
    paymentDetails: {
      id: first.paymentDetails && first.paymentDetails.id ? first.paymentDetails.id : null,
      debtorName: first.paymentDetails && first.paymentDetails.debtorName ? first.paymentDetails.debtorName : '',
      receiptEmail: first.paymentDetails && first.paymentDetails.receiptEmail ? first.paymentDetails.receiptEmail : '',
      paymentDate:
        first.paymentDetails && first.paymentDetails.paymentDate
          ? new Date(first.paymentDetails.paymentDate)
          : new Date(),
    },
    paymentTerminalTransactions: first.paymentTerminalTransactions,
  }
}

function toALInput(AL: AutomobileAndTouringClubFinland): AutomobileAndTouringClubFinlandInput {
  return {
    jobId: AL.jobId,
    caseNumber: AL.caseNumber,
    serviceChannel: AL.serviceChannel,
    billingInfo: AL.billingInfo,
    personCount: AL.personCount,
    vehicleMileage: AL.vehicleMileage,
    arcCodes: AL.arcCodes,
    repairShopId: AL.repairShopId,
    distanceTowed: AL.distanceTowed,
    towingInsistedByCustomer: AL.towingInsistedByCustomer,
    delayedTowing: AL.delayedTowing,
    delayedTowingReason: AL.delayedTowingReason,
    jobTimestamps: {
      estimatedTimeOfArrival: {
        date: AL.jobTimestamps.estimatedTimeOfArrival ? AL.jobTimestamps.estimatedTimeOfArrival.date : null,
        time: AL.jobTimestamps.estimatedTimeOfArrival ? AL.jobTimestamps.estimatedTimeOfArrival.time : null,
      },
      actualTimeOfArrival: {
        date: AL.jobTimestamps.actualTimeOfArrival ? AL.jobTimestamps.actualTimeOfArrival.date : null,
        time: AL.jobTimestamps.actualTimeOfArrival ? AL.jobTimestamps.actualTimeOfArrival.time : null,
      },
      estimatedTimeOfCompletion: {
        date: AL.jobTimestamps.estimatedTimeOfCompletion ? AL.jobTimestamps.estimatedTimeOfCompletion.date : null,
        time: AL.jobTimestamps.estimatedTimeOfCompletion ? AL.jobTimestamps.estimatedTimeOfCompletion.time : null,
      },
      actualTimeOfCompletion: {
        date: AL.jobTimestamps.actualTimeOfCompletion ? AL.jobTimestamps.actualTimeOfCompletion.date : null,
        time: AL.jobTimestamps.actualTimeOfCompletion ? AL.jobTimestamps.actualTimeOfCompletion.time : null,
      },
    },
  }
}
