import React, { FunctionComponent } from 'react'
import { defineMessages, InjectedIntlProps, injectIntl } from 'react-intl'
import styled from 'styled-components'

interface Props {
  error?: any
}

const messages = defineMessages({
  error: {
    id: 'containers.graphql.error',
    defaultMessage: 'Unexpected error occured',
  },
})

const Error: FunctionComponent<Props & InjectedIntlProps> = ({ intl, error }) => {
  const { formatMessage } = intl

  const message = error && typeof error === 'string' ? error : formatMessage(messages.error)
  return (
    <Container>
      <ErrorMessage>{message}</ErrorMessage>
    </Container>
  )
}

export const Container = styled.div`
  padding: 3em;
  background-color: ${props => props.theme.colors.red100};
`

export const ErrorMessage = styled.div`
  text-align: center;
  color: ${props => props.theme.colors.red900};
  font-size: 2em;
`

export default injectIntl(Error)
