import {
  UpdateStartedMutationVariables,
  UpdateFinishedAtBreakdownLocationMutationVariables,
  UpdateFinalizedMutationVariables,
  UpdateFinishedAtDestinationMutationVariables,
  UpdateArrivedAtDestinationMutationVariables,
} from './types'
import { EditStartRouteFormValues } from '../../../containers/TowingRecordPage/components/routes/subforms/StartRoute/types'
import { Geolocation } from '../../Geolocation/types'
import { toALScalarTimestamp } from '../../../containers/TowingRecordPage/components/routes/subforms/ArrivalRoute/mutation/toMutationVariables'
import { EditArrivalRouteFormValues } from '../../../containers/TowingRecordPage/components/routes/subforms/ArrivalRoute/types'
import {
  EditFinishRouteFormValues,
  FinishRoute,
} from '../../../containers/TowingRecordPage/components/routes/subforms/FinishRoute/types'
import { toScalarShortDate, toScalarTime } from '../../../util/convertToScalar'
import { TowingRecord } from '../../../containers/TowingRecordPage/ViewTowingRecord/types'
import { SosServiceOrder, SosServiceOrderMutationInput } from '../types'
import { FinishRouteMutationInput } from '../../../containers/TowingRecordPage/components/routes/subforms/FinishRoute/mutation/types'
import { cloneDeep } from 'lodash'
import {
  batteryServiceToMutationVariables,
  resolveALCompletionARC,
} from '../../../containers/TowingRecordPage/components/routes/subforms/FinishRoute/mutation/toMutationVariables'

export function updateStartedToMutationVariables(
  values: EditStartRouteFormValues,
  towingRecordId: number
): UpdateStartedMutationVariables {
  if (!values.sosServiceOrder) {
    return {
      input: {
        id: towingRecordId,
        startedDetails: {
          additionalInfo: values.additionalInfo,
          estimatedTimeOfArrival: null,
          location: {
            address: null,
            city: null,
            zipcode: null,
            description: null,
            coords: {
              lat: null,
              long: null,
            },
          },
          jobStartedAt: null,
        },
      },
    }
  }
  const {
    sosServiceOrder: { estimatedTimeOfArrival, currentLocation, jobStarted },
    startRoute,
  } = values

  return {
    input: {
      id: towingRecordId,
      startedDetails: {
        additionalInfo: values.additionalInfo,
        estimatedTimeOfArrival: estimatedTimeOfArrival ? toALScalarTimestamp(estimatedTimeOfArrival) : null,
        location: {
          address: currentLocation.address,
          city: currentLocation.city,
          zipcode: currentLocation.zipcode,
          description: startRoute.description,
          coords: getLatLong(currentLocation),
        },
        jobStartedAt: jobStarted ? toALScalarTimestamp(jobStarted) : null,
      },
    },
  }
}

export function updateFinishedAtBreakdownLocationToMutationVariables(
  values: EditArrivalRouteFormValues,
  towingRecordId: number
): UpdateFinishedAtBreakdownLocationMutationVariables {
  if (!values) {
    return {
      input: {
        id: towingRecordId,
        finishedAtBreakdownLocationDetails: {
          actualTimeOfCompletion: null,
          vehicleMileage: null,
          additionalInfo: '',
          deliverable: null,
          cause: null,
          notes: null,
          vehicleRegNo: null,
        },
      },
    }
  }

  const {
    sosServiceOrder,
    arrivalRoute: { finishedDate, finishedTime },
  } = values

  return {
    input: {
      id: towingRecordId,
      finishedAtBreakdownLocationDetails: {
        actualTimeOfCompletion: {
          date: finishedDate ? toScalarShortDate(finishedDate) : null,
          time: finishedTime ? toScalarTime(finishedTime) : null,
        },
        vehicleMileage: sosServiceOrder ? sosServiceOrder.vehicleMileage : null,
        additionalInfo: sosServiceOrder ? sosServiceOrder.additionalInfo : null,
        cause: sosServiceOrder && sosServiceOrder.cause ? sosServiceOrder.cause : null,
        deliverable: sosServiceOrder && sosServiceOrder.deliverable ? sosServiceOrder.deliverable : null,
        notes: values.notes,
        vehicleRegNo: values.vehicleRegNo,
      },
    },
  }
}

export function updateArrivedAtDestinationToMutationVariables(
  values: EditFinishRouteFormValues
): UpdateArrivedAtDestinationMutationVariables {
  const { id, finishRoute, additionalInfo } = values
  const { address, city, coords, zipcode, description } = finishRoute
  const location: Geolocation = { address, city, coords, zipcode }

  if (values.automobileAndTouringClubFinland) {
    return {
      input: {
        id: values.id,
        arrivedAtDestinationDetails: {
          additionalInfo: values.additionalInfo,
          notes: values.notes,
          vehicleRegNo: values.vehicleRegNo,
          location: {
            address,
            city,
            zipcode,
            description,
            coords: getLatLong(location),
          },
        },
        finishRoute: {
          finishRoute: routeToMutationVariables(values.finishRoute),
          additionalInfo: additionalInfo,
          serviceType: values.serviceType,
          repairShopId: values.automobileAndTouringClubFinland.repairShopId,
          componentCode: values.automobileAndTouringClubFinland.arcCodes.component.code,
          descriptionCode: values.automobileAndTouringClubFinland.arcCodes.description.code,
          completionCode: resolveALCompletionARC(values.serviceType),
          vehicleMileage: values.automobileAndTouringClubFinland.vehicleMileage,
          batteryService:
            values.serviceType === 'battery_service'
              ? batteryServiceToMutationVariables(values.automobileAndTouringClubFinland.batteryService)
              : null,
        },
      },
    }
  }

  return {
    input: {
      id,
      arrivedAtDestinationDetails: {
        additionalInfo: values.additionalInfo,
        notes: values.notes,
        vehicleRegNo: values.vehicleRegNo,
        location: {
          address,
          city,
          zipcode,
          description,
          coords: getLatLong(location),
        },
      },
      finishRoute: {
        finishRoute: routeToMutationVariables(finishRoute),
        additionalInfo: values.sosServiceOrder ? '' : additionalInfo,
        serviceType: values.serviceType,
        repairShopId: null,
        componentCode: null,
        descriptionCode: null,
        completionCode: null,
        vehicleMileage: null,
        batteryService: null,
      },
    },
  }
}

export function updateFinishedAtDestinationToMutationVariables(
  inputValues: EditFinishRouteFormValues
): UpdateFinishedAtDestinationMutationVariables {
  const values = cloneDeep(inputValues)
  const { id, sosServiceOrder, additionalInfo, finishRoute } = inputValues

  if (values.automobileAndTouringClubFinland) {
    return {
      input: {
        id: values.id,
        finishedAtDestinationDetails: {
          notes: values.notes,
          vehicleRegNo: values.vehicleRegNo,
          additionalInfo: '',
          vehicleMileage: null,
          keyLocation: '',
          deliverable: values.serviceType ? values.serviceType : null,
          towingReason: values.towingReason ? values.towingReason : null,
        },
        finishRoute: {
          finishRoute: routeToMutationVariables(values.finishRoute),
          additionalInfo: additionalInfo,
          serviceType: values.serviceType,
          repairShopId: values.automobileAndTouringClubFinland.repairShopId,
          componentCode: values.automobileAndTouringClubFinland.arcCodes.component.code,
          descriptionCode: values.automobileAndTouringClubFinland.arcCodes.description.code,
          completionCode: resolveALCompletionARC(values.serviceType),
          vehicleMileage: values.automobileAndTouringClubFinland.vehicleMileage,
          batteryService:
            values.serviceType === 'battery_service'
              ? batteryServiceToMutationVariables(values.automobileAndTouringClubFinland.batteryService)
              : null,
        },
      },
    }
  }

  if (sosServiceOrder) {
    return {
      input: {
        id,
        finishedAtDestinationDetails: {
          additionalInfo: additionalInfo,
          vehicleMileage: sosServiceOrder.vehicleMileage,
          keyLocation: sosServiceOrder.keyLocation,
          deliverable: sosServiceOrder.deliverable,
          notes: values.notes,
          vehicleRegNo: values.vehicleRegNo,
          towingReason: values.towingReason,
        },
        finishRoute: {
          finishRoute: routeToMutationVariables(finishRoute),
          additionalInfo: '',
          serviceType: values.serviceType,
          repairShopId: null,
          componentCode: null,
          descriptionCode: null,
          completionCode: null,
          vehicleMileage: null,
          batteryService: null,
        },
      },
    }
  }

  return {
    input: {
      id,
      finishedAtDestinationDetails: {
        additionalInfo: '',
        vehicleMileage: null,
        keyLocation: '',
        deliverable: values.serviceType ? values.serviceType : null,
        towingReason: values.towingReason ? values.towingReason : null,
        notes: values.notes,
        vehicleRegNo: values.vehicleRegNo,
      },
      finishRoute: {
        finishRoute: routeToMutationVariables(finishRoute),
        additionalInfo: values.additionalInfo,
        serviceType: values.serviceType,
        repairShopId: null,
        componentCode: null,
        descriptionCode: null,
        completionCode: null,
        vehicleMileage: null,
        batteryService: null,
      },
    },
  }
}

const routeToMutationVariables = (route: FinishRoute): FinishRouteMutationInput => {
  return {
    id: route.id,
    ordering: 100,
    address: route.address,
    city: route.city,
    zipcode: route.zipcode,
    coords: {
      lat: route.coords.lat,
      long: route.coords.long,
    },
    description: route.description,
    displayTimestampOnPrintables: route.displayTimestampOnPrintables,
    displayFinishedTimestampOnPrintables: route.displayTimestampOnPrintables,
    type: route.type,
    geolocated: route.geolocated,
    arrivalDate: route.arrivalDate && route.arrivalTime ? toScalarShortDate(route.arrivalDate) : null,
    arrivalTime: route.arrivalTime ? toScalarTime(route.arrivalTime) : null,
    finishedDate: route.finishedDate && route.finishedTime ? toScalarShortDate(route.finishedDate) : null,
    finishedTime: route.finishedTime ? toScalarTime(route.finishedTime) : null,
  }
}

export function updateFinalizedToMutationVariables(values: TowingRecord): UpdateFinalizedMutationVariables {
  const {
    id,
    sosServiceOrder,
    jobDetails: { additionalInfo },
  } = values
  if (!sosServiceOrder) {
    return {
      input: {
        id,
        finalizedDetails: {
          additionalInfo,
          keyLocation: '',
        },
      },
    }
  }
  const { vehicleKeyLocation } = sosServiceOrder
  return {
    input: {
      id,
      finalizedDetails: {
        additionalInfo,
        keyLocation: vehicleKeyLocation,
      },
    },
  }
}

function getLatLong(location: Geolocation) {
  const {
    coords: { lat, long },
  } = location
  return { lat, long }
}

export function toSosServiceOrderMutationVariables(values: SosServiceOrder): SosServiceOrderMutationInput {
  const {
    jobId,
    caseNumber,
    priority,
    agreementName,
    estimatedCause,
    estimatedServiceType,
    incidentDescription,
    situationDescription,
    personCount,
    vehicleKeyLocation,
    serviceType,
    serviceDescription,
    cause,
    detailedCause,
    deliverable,
    insuranceCompany,
    isPreOrder,
    vehicleMileage,
    originatorApp,
    towingDateAndTime,
    preOrder,
    jobTimestamps,
  } = values
  return {
    jobId,
    caseNumber,
    priority,
    agreementName,
    estimatedCause,
    estimatedServiceType,
    incidentDescription,
    situationDescription,
    personCount,
    vehicleKeyLocation,
    serviceType,
    serviceDescription,
    cause,
    detailedCause,
    deliverable,
    insuranceCompany,
    isPreOrder,
    vehicleMileage,
    originatorApp,
    towingDateAndTime: towingDateAndTime ? toALScalarTimestamp(towingDateAndTime) : null,
    preOrder: {
      beginEarliestAt: preOrder.beginEarliestAt ? toALScalarTimestamp(preOrder.beginEarliestAt) : null,
      beginLatestAt: preOrder.beginLatestAt ? toALScalarTimestamp(preOrder.beginLatestAt) : null,
    },
    jobTimestamps: {
      onHoldUntil: jobTimestamps.onHoldUntil ? toALScalarTimestamp(jobTimestamps.onHoldUntil) : null,
      estimatedTimeOfArrival: jobTimestamps.onHoldUntil ? toALScalarTimestamp(jobTimestamps.onHoldUntil) : null,
      arrivedAtBreakdownLocation: jobTimestamps.onHoldUntil ? toALScalarTimestamp(jobTimestamps.onHoldUntil) : null,
      arrivedAtDestination: jobTimestamps.onHoldUntil ? toALScalarTimestamp(jobTimestamps.onHoldUntil) : null,
      completedAtBreakdownLocation: jobTimestamps.onHoldUntil ? toALScalarTimestamp(jobTimestamps.onHoldUntil) : null,
      completedAtDestination: jobTimestamps.onHoldUntil ? toALScalarTimestamp(jobTimestamps.onHoldUntil) : null,
    },
  }
}

export function toSosServiceOrderEditMutationVariables(values: SosServiceOrder): SosServiceOrderMutationInput {
  const {
    jobId,
    caseNumber,
    priority,
    agreementName,
    incidentDescription,
    situationDescription,
    personCount,
    vehicleKeyLocation,
    serviceType,
    serviceDescription,
    cause,
    detailedCause,
    deliverable,
    insuranceCompany,
    isPreOrder,
    vehicleMileage,
    originatorApp,
    towingDateAndTime,
    preOrder,
    jobTimestamps,
  } = values
  return {
    jobId,
    caseNumber,
    priority,
    agreementName,
    incidentDescription,
    situationDescription,
    personCount,
    vehicleKeyLocation,
    serviceType,
    serviceDescription,
    cause,
    detailedCause,
    deliverable,
    insuranceCompany,
    isPreOrder,
    vehicleMileage,
    originatorApp,
    estimatedCause: cause,
    estimatedServiceType: deliverable,
    towingDateAndTime: towingDateAndTime ? toALScalarTimestamp(towingDateAndTime) : null,
    preOrder: {
      beginEarliestAt: preOrder.beginEarliestAt ? toALScalarTimestamp(preOrder.beginEarliestAt) : null,
      beginLatestAt: preOrder.beginLatestAt ? toALScalarTimestamp(preOrder.beginLatestAt) : null,
    },
    jobTimestamps: {
      onHoldUntil: jobTimestamps.onHoldUntil ? toALScalarTimestamp(jobTimestamps.onHoldUntil) : null,
      estimatedTimeOfArrival: jobTimestamps.onHoldUntil ? toALScalarTimestamp(jobTimestamps.onHoldUntil) : null,
      arrivedAtBreakdownLocation: jobTimestamps.onHoldUntil ? toALScalarTimestamp(jobTimestamps.onHoldUntil) : null,
      arrivedAtDestination: jobTimestamps.onHoldUntil ? toALScalarTimestamp(jobTimestamps.onHoldUntil) : null,
      completedAtBreakdownLocation: jobTimestamps.onHoldUntil ? toALScalarTimestamp(jobTimestamps.onHoldUntil) : null,
      completedAtDestination: jobTimestamps.onHoldUntil ? toALScalarTimestamp(jobTimestamps.onHoldUntil) : null,
    },
  }
}
