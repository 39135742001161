import React from 'react'
import { Button } from '../../../../components/Button'
import { ArrivalRouteAction } from '../../../../components/SosSubForm/ArrivalRoute/types'
import { SubmitFormType } from './types'

interface EditMenuProps {
  selectedForm: SubmitFormType
  action: ArrivalRouteAction
  setAction: (action: ArrivalRouteAction) => void
  canEditArrived: boolean
  canEditFinished: boolean
}

export const EditMenu = ({ selectedForm, action, setAction, canEditArrived, canEditFinished }: EditMenuProps) => {
  const showEditArrived = canEditArrived && selectedForm === 'arrived'
  const showEditFinished = canEditFinished && selectedForm === 'finished'
  
  const onEditArrivalCancelMoveToFinished = canEditArrived && !canEditFinished && action === "edit-arrived"

  const handleEditArrived = () => {
    if(onEditArrivalCancelMoveToFinished) {
      setAction("edit-finished")
      return
    }
    if (action === 'edit-arrived') {
      setAction('default')
    } else {
      setAction('edit-arrived')
    }
  }

  const handleEditFinished = () => {
    if (action === 'edit-finished') {
      setAction('default')
    } else {
      setAction('edit-finished')
    }
  }
  
  return (
    <>
      {showEditArrived && (
        <>
          <Button
            onClick={handleEditArrived}
            category="yellow"
            size="l"
            label={action === 'edit-arrived' ? 'Peruuta' : 'Muokkaa'}
            maxWidth="100%"
          />
          <Button category="save" size="l" label={'Tallenna saapunut'} mobileLabel="Tallenna" submit maxWidth="100%"></Button>
        </>
      )}
      {showEditFinished && (
        <>
          <Button
            onClick={handleEditFinished}
            category="yellow"
            size="l"
            label={action === 'edit-finished' ? 'Peruuta' : 'Muokkaa'}
            maxWidth="100%"
          />
          <Button category="save" size="l" label={'Tallenna valmis'} mobileLabel="Tallenna" submit maxWidth="100%"></Button>
        </>
      )}
    </>
  )
}
