import { Maybe, Time, TowingRecordRouteType, TowingRecordType } from '../../../../common/types'
import { TowingRecord } from '../../ViewTowingRecord/types'
import { EditArrivalRouteFormValues } from './subforms/ArrivalRoute/types'
import { EditFinishRouteFormValues } from './subforms/FinishRoute/types'
import { TowingRecordRoute } from './types'

interface RouteDateTime {
  type: TowingRecordRouteType
  date: Maybe<Date>
  time: Maybe<Time | string>
}

interface ParsedDateTime {
  type: TowingRecordRouteType
  date: Date
}

export const validateRouteTimes = (route: RouteDateTime, routes: RouteDateTime[]) => {
  const parsedDateTime: ParsedDateTime = parseDateTime(route)
  const parsedRoutes: ParsedDateTime[] = routes.map(r => parseDateTime(r))

  parsedRoutes.forEach(route => {
    if (parsedDateTime.date < route.date) {
      throw Error(`${translateRouteType(route.type)} aikaleiman tulee olla ennen nykyistä reittipistettä`)
    }
  })
}

// export const getRouteDateTimeByType = (type: TowingRecordRouteType, routes: TowingRecordRoute[]) => {
//   const route = routes.find(route => route.type === type)
//   const date = route && route.date ? route.date : new Date()
//   const time = route ? route.time : ''
//   return { date, time, type }
// }

export const getRouteArrivalDateTimeByType = (type: TowingRecordRouteType, routes: TowingRecordRoute[]) => {
  const route = routes.find(route => route.type === type)

  const date = route && route.arrivalDate ? route.arrivalDate : new Date()
  const time = route ? route.arrivalTime : ''
  return { date, time, type }
}

export const getRouteFinishedDateTimeByType = (type: TowingRecordRouteType, routes: TowingRecordRoute[]) => {
  const route = routes.find(route => route.type === type)

  const date = route && route.finishedDate ? route.finishedDate : new Date()
  const time = route ? route.finishedTime : ''
  return { date, time, type }
}

const parseDateTime = (route: RouteDateTime) => {
  const routeDate = route.date ? route.date.toDateString() : ''
  return { date: new Date(`${routeDate} ${route.time}`), type: route.type }
}

const translateRouteType = (type: TowingRecordRouteType) => {
  switch (type) {
    case TowingRecordRouteType.start:
      return 'Lähtöpaikan'
    case TowingRecordRouteType.arrival:
      return 'Kohteen'
    case TowingRecordRouteType.finish:
      return 'Työ valmis'
    case TowingRecordRouteType.finish_breakdown_location:
      return 'Valmis kohteessa'
    case TowingRecordRouteType.finish_destination:
      return 'Valmis määränpäässä'
  }
}

export function getRoutesTimelineAtBreakdown(towingRecord: TowingRecord, useExtendedRoutes: boolean, done: boolean) {
  const extendedRoutes = towingRecord.type === TowingRecordType.sos || useExtendedRoutes

  if (extendedRoutes && !done) {
    return [getRouteArrivalDateTimeByType(TowingRecordRouteType.start, towingRecord.routes)]
  }

  if (extendedRoutes && done) {
    return [
      getRouteArrivalDateTimeByType(TowingRecordRouteType.start, towingRecord.routes),
      getRouteArrivalDateTimeByType(TowingRecordRouteType.arrival, towingRecord.routes),
    ]
  }

  return [getRouteArrivalDateTimeByType(TowingRecordRouteType.start, towingRecord.routes)]
}

export const getCurrentRouteTimeAtBreakdown = (
  values: EditArrivalRouteFormValues,
  done: boolean,
  useExtendedRoutes: boolean
) => {
  if (useExtendedRoutes && !done) {
    return {
      date: values.arrivalRoute.arrivalDate,
      time: values.arrivalRoute.arrivalTime,
      type: values.arrivalRoute.type,
    }
  }
  if (useExtendedRoutes && done) {
    return {
      date: values.arrivalRoute.finishedDate as Date,
      time: values.arrivalRoute.finishedTime,
      type: TowingRecordRouteType.finish_breakdown_location,
    }
  }
  return {
    date: values.arrivalRoute.arrivalDate as Date,
    time: values.arrivalRoute.arrivalTime,
    type: values.arrivalRoute.type,
  }
}

export const getRoutesTimelineAtDestination = (
  towingRecord: TowingRecord,
  useExtendedRoutes: boolean,
  done: boolean
) => {
  const extendedRoutes = towingRecord.type === TowingRecordType.sos || useExtendedRoutes // if towing record type sos OR company has useExtendedRoutes

  if (extendedRoutes && !done) {
    return [
      getRouteArrivalDateTimeByType(TowingRecordRouteType.start, towingRecord.routes),
      getRouteArrivalDateTimeByType(TowingRecordRouteType.arrival, towingRecord.routes),
      getRouteFinishedDateTimeByType(TowingRecordRouteType.arrival, towingRecord.routes),
    ]
  }

  if (extendedRoutes && done) {
    return [
      getRouteArrivalDateTimeByType(TowingRecordRouteType.start, towingRecord.routes),
      getRouteArrivalDateTimeByType(TowingRecordRouteType.arrival, towingRecord.routes),
      getRouteFinishedDateTimeByType(TowingRecordRouteType.arrival, towingRecord.routes),
      getRouteArrivalDateTimeByType(TowingRecordRouteType.finish, towingRecord.routes),
    ]
  }

  return [
    getRouteArrivalDateTimeByType(TowingRecordRouteType.start, towingRecord.routes),
    getRouteArrivalDateTimeByType(TowingRecordRouteType.arrival, towingRecord.routes),
  ]
}

export const getCurrentRouteTimeAtDestination = (values: EditFinishRouteFormValues, done: boolean, useExtendedRoutes: boolean) => {
  
  if (useExtendedRoutes && !done) {
    return {
      date: values.finishRoute.arrivalDate,
      time: values.finishRoute.arrivalTime,
      type: values.finishRoute.type,
    }
  }

  if(useExtendedRoutes && done) {
    return {
      date: values.finishRoute.finishedDate as Date,
      time: values.finishRoute.finishedTime,
      type: TowingRecordRouteType.finish_destination,
    }
  }

  return {
    date: values.finishRoute.arrivalDate as Date,
    time: values.finishRoute.arrivalTime,
    type: TowingRecordRouteType.finish_destination,
  }
}
