import { useMutation } from '@apollo/react-hooks'
import React, { useCallback, useEffect, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'

import styled from 'styled-components'
import { Maybe, SignatureType } from '../../common/types'
import { Loading } from '../../components/responses'
import { setErrorNotifications, setFatalErrorNotification } from '../../components/responses/setErrorNotifications'
import { Button } from '../Button'
import {
  ButtonColumn,
  ButtonRow,
  Column,
  FormPadder,
  FormSubGroup,
  FormSubGroupHeader,
  FormSubGroupHeading,
  Notification,
  Row,
} from '../layout'
import { SignatorFieldButtonRow } from '../layout/buttonlayout'
import { RadioButtonGroup, TextInput } from '../layout/form/input'
import { Modal } from '../Modal'
import { Agreements } from './Agreements'
import { ADD_SIGNATURE, DELETE_SIGNATURE, RESEND_ACCEPTANCE_EMAIL } from './mutations'
import {
  onlineAcceptanceSignatureToMutationVariables,
  writtenSignatureToMutationVariables,
} from './toMutationVariables'
import {
  AddSignatureResponse,
  CanvasRefProps,
  DeleteSignatureResponse,
  ResendAcceptanceEmailResponse,
  SignatureInput,
} from './types'
import { SignatureCanvasProps } from './useSignatureCanvasRef'
import { CustomerFormValues } from '../../containers/Customer/types'

const SignatureContainer = styled.div`
  width: 100%;
  padding-top: 33.333333%;
  position: relative;
  margin-bottom: 1rem;
  border: 1px solid ${props => props.theme.colors.grey300};
  border-radius: 5px;

  .sigCanvas {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
  }
`

const nearestNeighbor = (src: ImageData, ctx: CanvasRenderingContext2D) => {
  const dst = ctx.createImageData(480, 160)
  let pos = 0
  for (let y = 0; y < dst.height; y++) {
    for (let x = 0; x < dst.width; x++) {
      const srcX = Math.floor((x * src.width) / dst.width)
      const srcY = Math.floor((y * src.height) / dst.height)

      let srcPos = (srcY * src.width + srcX) * 4

      dst.data[pos++] = src.data[srcPos++] // R
      dst.data[pos++] = src.data[srcPos++] // G
      dst.data[pos++] = src.data[srcPos++] // B
      dst.data[pos++] = src.data[srcPos++] // A
    }
  }
  return dst
}

export const resizeImage = (sourceCanvas: HTMLCanvasElement) => {
  const width = sourceCanvas.width
  const height = sourceCanvas.height
  const sourceContext = sourceCanvas.getContext('2d')

  if (!sourceContext) {
    return ''
  }

  const imageData = sourceContext.getImageData(0, 0, width, height)

  if (!imageData) {
    return ''
  }

  const canvas: HTMLCanvasElement = document.createElement('canvas')
  canvas.width = 480
  canvas.height = 160
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return ''
  }

  const resampledData = nearestNeighbor(imageData, ctx)
  ctx.putImageData(resampledData, 0, 0)
  return canvas.toDataURL('image/jpeg')
}

interface ISignature {
  signature: SignatureInput
  towingRecordId?: Maybe<number>
  setSignatureFields: (signature: Maybe<SignatureInput>) => void
  sigPadRef: React.RefObject<SignatureCanvasProps>
  setSaveSignature: (value: boolean) => void
  orderer: Maybe<CustomerFormValues>
}

const signatureOptions = [
  { value: SignatureType.written, label: 'Käsin' },
  { value: SignatureType.online_acceptance, label: 'Sähköpostitse' },
  { value: SignatureType.online_acceptance_sms, label: 'Tekstiviestitse' },
]

export const Signature: React.FunctionComponent<ISignature> = ({
  signature,
  towingRecordId,
  setSignatureFields,
  sigPadRef,
  setSaveSignature,
  orderer,
}) => {
  const [cachedSignature, setCachedSignature] = useState<string>('')
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [resendEmailModal, setresendEmailModal] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [showWrittenSignature, setStateShowWrittenSignature] = useState<boolean>(false)

  useEffect(() => {
    const handleResize = () => {
      if (!cachedSignature || !sigPadRef.current) {
        return
      }

      sigPadRef.current.fromDataURL(cachedSignature)
    }

    window.addEventListener('resize', handleResize)
  }, [sigPadRef, cachedSignature])

  const cacheSignature = useCallback(() => {
    if (!sigPadRef.current) {
      return
    }
    const data = resizeImage(sigPadRef.current.getCanvas())

    setCachedSignature(data)
  }, [sigPadRef, setCachedSignature])

  const setShowWrittenSignature = useCallback(
    value => {
      setStateShowWrittenSignature(value)
      setSaveSignature(value)
    },
    [setSaveSignature]
  )

  const [deleteExistingSignature, deleteResult] = useMutation<DeleteSignatureResponse>(DELETE_SIGNATURE, {
    onCompleted({ deleteSignature }) {
      if (deleteSignature.__typename === 'DeleteSignatureSuccess') {
        setSignatureFields(null)
      }
      if (deleteSignature.__typename !== 'DeleteSignatureSuccess') {
        setErrorNotifications({ data: deleteSignature })
      }
    },
    onError(err) {
      setFatalErrorNotification(err.message)
    },
  })
  const [createSignature, createSignatureResult] = useMutation<AddSignatureResponse>(ADD_SIGNATURE, {
    onCompleted({ addSignature }) {
      if (addSignature.__typename === 'AddSignatureSuccess') {
        const s = addSignature.signature
        setSignatureFields({ ...s, agreements: [] })
      }
      if (addSignature.__typename !== 'AddSignatureSuccess') {
        setErrorNotifications({ data: addSignature })
      }
    },
    onError(err) {
      setFatalErrorNotification(err.message)
    },
  })

  const [resendEmail, resendEmailResult] = useMutation<ResendAcceptanceEmailResponse>(RESEND_ACCEPTANCE_EMAIL, {
    onCompleted({ resendOnlineAcceptanceEmail }) {
      if (resendOnlineAcceptanceEmail.__typename === 'ResendOnlineAcceptanceEmailSuccess') {
        const s = resendOnlineAcceptanceEmail.signature
        setSignatureFields({ ...s, agreements: [] })
      }
      if (resendOnlineAcceptanceEmail.__typename !== 'ResendOnlineAcceptanceEmailSuccess') {
        setErrorNotifications({ data: resendOnlineAcceptanceEmail })
      }
    },
    onError(err) {
      setFatalErrorNotification(err.message)
    },
  })

  const clearCanvas = (current: CanvasRefProps | null): void => {
    if (current) {
      current.clear()
    }
  }

  const handleDeleteSignature = () => {
    if (towingRecordId) {
      deleteExistingSignature({ variables: { input: { towingRecordId } } })
      setDeleteModal(false)
    }
  }

  const handleAddWrittenSignature = () => {
    const signatureIsEmpty = sigPadRef.current && sigPadRef.current.isEmpty()
    if (signatureIsEmpty || signature.signatorName.length < 1) {
      setError('Allekirjoitus ja nimenselvennys ovat pakollisia.')
    } else {
      const writtenSignatureData = sigPadRef.current ? resizeImage(sigPadRef.current.getCanvas()) : ''
      createSignature({
        variables: writtenSignatureToMutationVariables(towingRecordId, signature, writtenSignatureData),
      })
    }
  }

  const handleAddOnlineAcceptanceSignature = () => {
    if (signature.type === SignatureType.online_acceptance && signature.signatorEmail.length < 1) {
      setError('Sähköpostiosoite on pakollinen.')
      return;
    }
    
    const phoneRegex = /^\+?[\d\s]{8,14}\d$/;

    if(signature.type === SignatureType.online_acceptance_sms && !phoneRegex.test(signature.signatorPhone.replace(/\s+/g, ''))){
      setError('Puhelinnumero on virheellinen. (+, numerot ja välilyönnit ovat sallittuja)')
      return;
    }
    createSignature({ variables: onlineAcceptanceSignatureToMutationVariables(towingRecordId, signature) })
    
  }

  const handleResendAcceptanceEmail = () => {
    if (towingRecordId) {
      resendEmail({ variables: { input: { towingRecordId } } })
    }
  }

  const handleCopyOrdererName = () => {
    const name = getOrdererName(orderer)
    const values = signature

    values.signatorName = name
    setSignatureFields(values)
  }

  const handleCopyOrdererEmail = () => {
    const email = getOrdererEmail(orderer)
    const values = signature

    values.signatorEmail = email
    setSignatureFields(values)
  }

  const handleCopyOrdererPhone = () => {
    const phone = getOrdererPhone(orderer)
    const values = signature

    values.signatorPhone = phone
    setSignatureFields(values)
  }

  if (signature.id) {
    return (
      <>
        <Loading loading={deleteResult.loading || resendEmailResult.loading} />
        {deleteModal && (
          <Modal
            label="Vahvista allekirjoituksen poistaminen"
            message="Haluatko varmasti poistaa allekirjoituksen?"
            close={() => setDeleteModal(false)}
            onConfirm={() => handleDeleteSignature()}
            confirmLabel="OK"
            closeLabel="Peruuta"
          />
        )}
        {resendEmailModal && (
          <Modal
            label="Vahvista hyväksyntäpyynnön uudelleenlähetys"
            message="Haluatko varmasti lähettää hyväksyntäpyynnön uudelleen?"
            close={() => setresendEmailModal(false)}
            onConfirm={() => handleResendAcceptanceEmail()}
            confirmLabel="Lähetä"
            closeLabel="Peruuta"
          />
        )}
        <FormSubGroupHeader>
          <FormSubGroupHeading>Allekirjoitus</FormSubGroupHeading>
        </FormSubGroupHeader>
        <FormSubGroup>
          <Row>
            <Column>
              {signature.signedAt && (
                <span>{`Allekirjoitettu ${formatDate(signature.signedAt)} (${signature.signatorName})`}</span>
              )}
              {!signature.signedAt && signature.type === SignatureType.online_acceptance && (
                <span>{`Odottaa hyväksyntää (Lähetetty: ${
                  signature.sentAt ? formatDate(signature.sentAt) : '-'
                } osoitteeseen ${signature.signatorEmail})`}</span>
              )}
              {!signature.signedAt && signature.type === SignatureType.online_acceptance_sms && (
                <span>{`Odottaa hyväksyntää (Lähetetty: ${
                  signature.sentAt ? formatDate(signature.sentAt) : '-'
                } numeroon ${signature.signatorPhone})`}</span>
              )}
            </Column>
            {towingRecordId && (
              <ButtonRow>
                {(signature.type === SignatureType.online_acceptance || signature.type === SignatureType.online_acceptance_sms) && !signature.signedAt && (
                  <ButtonColumn>
                    <Button category="save" label="Lähetä uudelleen" onClick={() => setresendEmailModal(true)} />
                  </ButtonColumn>
                )}
                <ButtonColumn>
                  <Button category="cancel" label="Poista" onClick={() => setDeleteModal(true)} />
                </ButtonColumn>
              </ButtonRow>
            )}
          </Row>
        </FormSubGroup>
      </>
    )
  }

  if (!signature.id) {
    return (
      <>
        <Loading loading={createSignatureResult.loading} />
        <FormSubGroupHeader>
          <Row>
            <Column>
              <FormSubGroupHeading>Allekirjoitus</FormSubGroupHeading>
            </Column>
            <Column justify="flex-end">
              <RadioButtonGroup label={''} name="signature.type" options={signatureOptions}></RadioButtonGroup>
            </Column>
          </Row>
        </FormSubGroupHeader>
        {signature.type === SignatureType.written && (
          <>
            {showWrittenSignature && (
              <>
                <FormSubGroup>
                  <SignatureContainer>
                    <SignatureCanvas
                      ref={sigPadRef}
                      backgroundColor="#fff"
                      penColor="black"
                      canvasProps={{ className: 'sigCanvas' }}
                      onEnd={cacheSignature}
                    />
                  </SignatureContainer>
                  <Agreements agreements={signature.agreements} />
                  <SignatorFieldButtonRow>
                    <TextInput label="Tilaaja" placeholder="Nimenselvennys" name="signature.signatorName" />
                    <Button label="Tuo tilaaja" category="on" onClick={handleCopyOrdererName} size="s" />
                  </SignatorFieldButtonRow>
                  {error && <Notification type="error">{error}</Notification>}
                </FormSubGroup>
                <FormPadder>
                  <ButtonRow>
                    <ButtonColumn>
                      <Button category="cancel" label="Peruuta" onClick={() => setShowWrittenSignature(false)} />
                    </ButtonColumn>
                    <ButtonColumn>
                      <Button
                        category="clear"
                        label="Tyhjennä"
                        onClick={() => {
                          clearCanvas(sigPadRef.current)
                          setSignatureFields(null)
                        }}
                      />
                    </ButtonColumn>
                    {towingRecordId && (
                      <ButtonColumn>
                        <Button category="save" label="Tallenna" onClick={handleAddWrittenSignature} />
                      </ButtonColumn>
                    )}
                  </ButtonRow>
                </FormPadder>
              </>
            )}
            {!showWrittenSignature && (
              <FormPadder>
                <ButtonRow>
                  <ButtonColumn>
                    <Button category="regular" label="Allekirjoita" onClick={() => setShowWrittenSignature(true)} />
                  </ButtonColumn>
                </ButtonRow>
              </FormPadder>
            )}
          </>
        )}
        {signature.type === SignatureType.online_acceptance && (
          <>
            <FormSubGroup margin='0 0 1rem 0'>
              <Row>
                <Column>
                  <SignatorFieldButtonRow>
                    <TextInput type="email" label="Sähköposti" name="signature.signatorEmail" onChange={()=>{setError(null)}}/>
                    <Button label="Tuo tilaaja" category="on" onClick={handleCopyOrdererEmail} size="s" />
                  </SignatorFieldButtonRow>
                </Column>
                {towingRecordId && (
                  <ButtonColumn style={{alignSelf: 'flex-end'}}>
                    <Button
                      category="save"
                      size="s"
                      label="Lähetä hyväksyttäväksi"
                      onClick={handleAddOnlineAcceptanceSignature}
                      />
                  </ButtonColumn>
                )}
              </Row>
            {error && <Notification type="error">{error}</Notification>}
            </FormSubGroup>
          </>
        )}
        {signature.type === SignatureType.online_acceptance_sms && (
          <>
          <FormSubGroup margin='0 0 1rem 0'>
            <Row>
              <Column>
                <SignatorFieldButtonRow>
                  <TextInput type="phone" label="Puhelinnumero" name="signature.signatorPhone" onChange={()=>{setError(null)}}/>
                  <Button label="Tuo tilaaja" category="on" onClick={handleCopyOrdererPhone} size="s" />
                </SignatorFieldButtonRow>
              </Column>
              {towingRecordId && (
                <ButtonColumn style={{alignSelf: 'flex-end'}}>
                  <Button
                    category="save"
                    size="s"
                    label="Lähetä hyväksyttäväksi"
                    onClick={handleAddOnlineAcceptanceSignature}
                  />
                </ButtonColumn>
                )}
            </Row>
            {error && <Notification type="error">{error}</Notification>}
          </FormSubGroup>
          </>
        )}
      </>
    )
  }

  return null
}

const formatDate = (date: Date | null): string => {
  if (!date) return '-'
  return date.toLocaleString('fi-FI', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })
}

const getOrdererName = (orderer: Maybe<CustomerFormValues>) => {
  if (orderer && orderer.type === 'person') {
    if (!orderer.personCustomer) return ''

    const personCustomer = orderer.personCustomer
    const firstName = personCustomer.firstName ? personCustomer.firstName : ''
    const lastName = personCustomer.lastName ? personCustomer.lastName : ''

    return `${firstName} ${lastName}`.trim()
  }

  if (orderer && orderer.type === 'company') {
    if (!orderer.companyCustomer) return ''

    const companyCustomer = orderer.companyCustomer
    const companyName = companyCustomer.companyName ? companyCustomer.companyName : ''
    const contactPerson = companyCustomer.contactPerson ? companyCustomer.contactPerson : ''

    if (companyName === '' && contactPerson === '') return ''
    if (companyName === '') return contactPerson.trim()
    if (contactPerson === '') return companyName.trim()

    return `${companyName} / ${contactPerson}`.trim()
  }

  return ''
}

const getOrdererEmail = (orderer: Maybe<CustomerFormValues>) => {
  if (orderer && orderer.type === 'person') {
    if (!orderer.personCustomer) return ''

    const personCustomer = orderer.personCustomer
    const customerEmail = personCustomer.email ? personCustomer.email : ''

    return customerEmail.trim()
  }

  if (orderer && orderer.type === 'company') {
    if (!orderer.companyCustomer) return ''

    const companyCustomer = orderer.companyCustomer
    const contactPersonEmail = companyCustomer.contactPersonEmail ? companyCustomer.contactPersonEmail : ''

    return contactPersonEmail.trim()
  }

  return ''
}

const getOrdererPhone = (orderer: Maybe<CustomerFormValues>) => {
  if (orderer && orderer.type === 'person') {
    if (!orderer.personCustomer) return ''

    const personCustomer = orderer.personCustomer
    const customerPhone = personCustomer.phone ? personCustomer.phone : ''

    return customerPhone.trim()
  }

  if (orderer && orderer.type === 'company') {
    if (!orderer.companyCustomer) return ''

    const companyCustomer = orderer.companyCustomer
    const companyPhone = companyCustomer.phone ? companyCustomer.phone : ''

    return companyPhone.trim()
  }

  return ''
}
