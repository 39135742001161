import gql from 'graphql-tag'

export const GET_ITEM_GROUPS = gql`
  query {
    itemGroups {
      __typename
      ... on UnauthorizedError {
        error {
          code
          message
          details
          context
        }
      }
      ... on ItemGroupsSuccess {
        itemGroups {
          id
          name
        }
      }
    }
  }
`

export const GET_ALL_ITEMS = gql`
  query {
    items(searchString: "") {
      __typename
      ... on UnauthorizedError {
        error {
          code
          message
        }
      }
      ... on ItemsSuccess {
        items {
          id
          name
          unitPrice
          vat
          vatCategory
          unit
          limitedUsage
          isInventoryTracked
          groups
        }
      }
    }
  }
`

export const GET_SETTINGS = gql`
  query {
    settings {
      ... on UnauthorizedError {
        error {
          code
          message
          details
          context
        }
      }
      ... on GetSettingsSuccess {
        settings {
          operators {
            id
            name
            defaultStation
          }
          stations {
            id
            name
            shortName
            type
            address
            zipcode
            city
            coords {
              lat
              long
            }
          }
          towingReasons
          labels
          agreements {
            id
            text
            type
          }
          modules {
            sos
            autoliitto
            shifts
            useExtendedRoutes
          }
          notifications {
            enabled
            method
          }
          subcontracts {
            contractorId
            contractorName
            allowWeb
            allowApp
          }
          shiftGroup {
            id
            groupName
            currentStart
            currentEnd
            enableWait
            enableStandby
            enableOvertime
            enableNight
            enableEvening
            periodLength
            jobType
          }
          paymentTerminalsEnabled
          companyData {
            companyName
            address
            zipcode
            city
            email
            phone
          }
          AlDelayedTowingReasons {
            value
            delayedTowing
            label
          }
          defaultItemsIncludeVat
        }
      }
    }
  }
`

export const GET_STATIC_DATA = gql`
  query {
    staticData {
      ... on UnauthorizedError {
        error {
          code
          message
          details
          context
        }
      }
      ... on GetStaticDataSuccess {
        arcCodes
      }
    }
  }
`

export const GET_PREFERENCES = gql`
  query {
    preferences {
      ... on UnauthorizedError {
        error {
          code
          message
          details
          context
        }
      }
      ... on GetPreferencesSuccess {
        preferences {
          id
          filters
        }
      }
    }
  }
`

export const GET_NOTIFICATIONS = gql`
  query {
    notifications {
      ... on UnauthorizedError {
        error {
          code
          message
          details
          context
        }
      }
      ... on GetNotificationsSuccess {
        notifications {
          title
          message
          type
          actions {
            label
            url
            type
          }
        }
      }
    }
  }
`
