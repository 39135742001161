import gql from 'graphql-tag'

export const DIRECTIONS = gql`
  query Directions($waypoints: GetDirections!) {
    directions(data: $waypoints) {
      __typename
      ... on GetDirectionsSuccess {
        result {
          status
          geocoded_waypoints {
            geocoder_status
            place_id
            types
          }
          routes {
            summary
            legs {
              duration {
                value
                text
              }
              distance {
                value
                text
              }
              start_location {
                lat
                lng
              }
              end_location {
                lat
                lng
              }
              start_address
              end_address
              steps {
                distance {
                  value
                  text
                }
                duration {
                  value
                  text
                }
                end_location {
                  lat
                  lng
                }
                start_location {
                  lat
                  lng
                }
              }
            }
          }
        }
      }
    }
  }
`
