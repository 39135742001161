import { FieldProps, getIn } from 'formik'
import React, { useCallback, useState } from 'react'

import { Error, FormikField as Field, FieldContainer, Label } from '../../style'

interface Props {
  id: string
  label?: string
  name: string
  value: string
  placeholder?: string
}

export const PasswordField: React.FunctionComponent<Props & FieldProps> = ({
  id,
  field,
  form,
  label,
  name,
  placeholder,
}) => {
  const [value, setValue] = useState<string>(field.value || '')

  const handleChange = useCallback(
    event => {
      const targetValue = event.target.value

      setValue(targetValue)

      const trimmedValue = targetValue.trim()

      form.setFieldValue(field.name, trimmedValue === '' ? null : trimmedValue)
      form.setFieldTouched(field.name, true)
    },
    [field, form]
  )

  const fieldError = getIn(form.errors, field.name)
  const fieldTouched = getIn(form.touched, field.name)
  const hasErrors = Boolean(fieldError) && Boolean(fieldTouched)

  return (
    <FieldContainer>
      {label && <Label>{label}</Label>}
      <Field
        id={id}
        type="password"
        autoComplete="on"
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
      />

      {hasErrors && <Error className="error-tooltip">{fieldError}</Error>}
    </FieldContainer>
  )
}
