import React, { FunctionComponent } from 'react'
import { InjectedIntlProps, defineMessages, injectIntl } from 'react-intl'
import { toast } from 'react-toastify'

import { NotificationDelay } from '../../common/types'
import { ErrorNotification } from './errorNotification'
import { dismissToast } from '.'

type Key =
  | 'BadRequestError'
  | 'ForbiddenError'
  | 'InvalidStateError'
  | 'BillingAlreadyDefinedError'
  | 'TowingRecordAlreadyCreatedError'
  | 'InvalidCustomerIdentifierError'
  | 'FatalError'
  | 'ConcurrencyConflict'
  | 'NetworkError'

export const setGenericResponseErrorNotification = (key: Key) => {
  if (!toast.isActive(key)) {
    toast.error(<GenericResponseErrorNotification errorKey={key} />, {
      autoClose: false,
      closeButton: false,
      delay: 0,
      toastId: key,
    })
    dismissToast(key, NotificationDelay.genericErrorNotificationDelay)
  }
}

export const setGenericErrorNotification = (title: string, message: string) => {
  if (!toast.isActive('GenericError')) {
    const toastId = toast.error(<ErrorNotification title={title} message={message} />, {
      closeButton: false,
      delay: 0,
      toastId: 'GenericError',
    })
    dismissToast(toastId, NotificationDelay.genericErrorNotificationDelay)
  }
}

interface ErrorNotificationProps {
  errorKey: Key
}

export const GenericResponseErrorNotificationIntl: FunctionComponent<ErrorNotificationProps & InjectedIntlProps> = ({
  errorKey,
  intl,
}) => {
  const { formatMessage } = intl
  const title = formatMessage(messages[errorKey].title)
  const message = formatMessage(messages[errorKey].message)

  return <ErrorNotification title={title} message={message} />
}
const GenericResponseErrorNotification = injectIntl(GenericResponseErrorNotificationIntl)

const messages = {
  BadRequestError: defineMessages({
    title: {
      id: 'containers.graphql.bad_request_error.title',
      defaultMessage: 'Bad request',
    },
    message: {
      id: 'containers.graphql.bad_request_error.message',
      defaultMessage: ' ',
    },
  }),
  ForbiddenError: defineMessages({
    title: {
      id: 'containers.graphql.forbidden.title',
      defaultMessage: 'Forbidden',
    },
    message: {
      id: 'containers.graphql.forbidden.message',
      defaultMessage: ' ',
    },
  }),
  InvalidStateError: defineMessages({
    title: {
      id: 'containers.graphql.invalid_state.title',
      defaultMessage: 'InvalidState',
    },
    message: {
      id: 'containers.graphql.invalid_state.message',
      defaultMessage: ' ',
    },
  }),
  BillingAlreadyDefinedError: defineMessages({
    title: {
      id: 'containers.graphql.billing_already_defined.title',
      defaultMessage: 'Billing already defined',
    },
    message: {
      id: 'containers.graphql.billing_already_defined.message',
      defaultMessage: ' ',
    },
  }),
  TowingRecordAlreadyCreatedError: defineMessages({
    title: {
      id: 'containers.graphql.towing_record_already_created.title',
      defaultMessage: 'Towingrecord already created',
    },
    message: {
      id: 'containers.graphql.towing_record_already_created.message',
      defaultMessage: ' ',
    },
  }),
  InvalidCustomerIdentifierError: defineMessages({
    title: {
      id: 'containers.graphql.invalid_customer_identifier.title',
      defaultMessage: 'Invalid customer Identifier',
    },
    message: {
      id: 'containers.graphql.invalid_customer_identifier.message',
      defaultMessage: ' ',
    },
  }),
  FatalError: defineMessages({
    title: {
      id: 'containers.fatal_error.title',
      defaultMessage: 'There was unexpected error',
    },
    message: {
      id: 'containers.fatal_error.message',
      defaultMessage: ' ',
    },
  }),
  ConcurrencyConflict: defineMessages({
    title: {
      id: 'containers.graphql.concurrency_conflict.title',
      defaultMessage: 'Towingrecord cannot be saved',
    },
    message: {
      id: 'containers.graphql.concurrency_conflict.message',
      defaultMessage: ' ',
    },
  }),
  NetworkError: defineMessages({
    title: {
      id: 'containers.graphql.network_error.title',
      defaultMessage: 'Network error',
    },
    message: {
      id: 'containers.graphql.network_error.message',
      defaultMessage: ' ',
    },
  }),
}
