import {RepairShop, repairShops} from "../routes/subforms/FinishRoute/options";
import {SummaryTowingRecord} from "./types";

export function getRepairShop(towingRecord: SummaryTowingRecord): RepairShop | undefined {

  if(towingRecord.automobileAndTouringClubFinland && towingRecord.automobileAndTouringClubFinland.repairShopId){

    const repairShopId = towingRecord.automobileAndTouringClubFinland.repairShopId;
    return repairShops.find(shop => shop.id === repairShopId);
  }
}
