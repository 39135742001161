import { addHours, addMinutes, format, setHours, setMinutes } from 'date-fns'

import { Maybe, Time } from '../../common/types'

export function extractTime(dateTime: Maybe<Date>): Maybe<Time> {
  if (!dateTime) return null
  return (format(dateTime, 'HH:mm') as unknown) as Time
}

export function extractDate(dateTime: Maybe<Date>): Maybe<Date> {
  if (!dateTime) return null
  return new Date(format(dateTime, 'YYYY-MM-DD'))
}

export function addTimeToDate(date: Maybe<Date>, time: Maybe<Time>): Maybe<Date> {
  if (!date) return null
  if (!time) return date
  const validTimeFormat = /^(([0-9])|([0-1][0-9])|([2][0-3]))(:(([0-9])|([0-5][0-9])))?$/
  if (!time.match(validTimeFormat)) throw new Error(`${time} is not a valid HH:mm time string.`)
  return addMinutes(addHours(date, Number(time.slice(0, 2))), Number(time.slice(3, 5)))
}

export function setTimeToDate(date: Maybe<Date>, time: Maybe<Time>): Maybe<Date> {
  if (!date) return null
  if (!time) return date
  const validTimeFormat = /^(([0-9])|([0-1][0-9])|([2][0-3]))(:(([0-9])|([0-5][0-9])))?$/
  if (!time.match(validTimeFormat)) throw new Error(`${time} is not a valid HH:mm time string.`)
  return setMinutes(setHours(date, Number(time.slice(0, 2))), Number(time.slice(3, 5)))
}

export function toLocaleDateString(d: Maybe<string>): string {
  if (!d || d === '') return ''
  const date = new Date(d)
  return date.toLocaleDateString('fi')
}

export function isCorrectTimeFormat(time: Maybe<string> | Maybe<Time>): boolean {
  if (!time) {
    return false
  }
  const validTimeFormat = /^(([0-1][0-9])|([2][0-3]))(:(([0-5][0-9])))?$/
  if (!time.match(validTimeFormat)) {
    return false
  }
  return true
}
