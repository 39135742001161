import React from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { messages } from '../messages'

import { FormSubGroup, FormSubGroupWrapper, Section, SectionColumn } from '../../../components/layout'
import { TowingTypeInput, OperatorInput, StationInput, SelectInput } from '../../../components/layout/form/input'
import { Notifications } from '../../Parameters/types'
import { Maybe, UserRole } from '../../../common/types'
import { notificationOptions } from '../../../common/options'
import { acceptedRoles } from '../AddTowingOrder/TowingOrderSettingsForm'

interface Props {
  operatorDisabled: boolean
  isAl?: boolean
  stationRequired?: boolean
  notificationSettings: Notifications
  userRole: Maybe<UserRole>
  isSos: boolean
}

const TowingOrderSettingsFormIntl: React.FunctionComponent<Props & InjectedIntlProps> = ({
  intl,
  operatorDisabled,
  stationRequired,
  notificationSettings,
  userRole,
  isSos,
  isAl = false,
}) => {
  const { formatMessage } = intl

  if (!isSos && notificationSettings.enabled && userRole && acceptedRoles.includes(userRole)) {
    return (
      <FormSubGroupWrapper>
        <FormSubGroup>
          <Section marginBottom="0" margin="0.5rem 0" columns={2}>
            <SectionColumn>
              <TowingTypeInput label={formatMessage(messages.settings_type)} name="label" />
            </SectionColumn>
            <SectionColumn>
              <StationInput
                label={formatMessage(messages.settings_station)}
                name="stationId"
                required={stationRequired}
              />
            </SectionColumn>
          </Section>
          <Section columns={2}>
            <SectionColumn>
              <OperatorInput
                label={formatMessage(messages.settings_operator)}
                name="operatorId"
                disabled={operatorDisabled}
                clearable
                placeholder="Ei osoitettu"
              />
            </SectionColumn>
            <SectionColumn>
              <SelectInput
                label={'Seurantalinkki'}
                name="notificationMethod"
                placeholder="Älä lähetä"
                options={notificationOptions}
              />
            </SectionColumn>
          </Section>
        </FormSubGroup>
      </FormSubGroupWrapper>
    )
  }

  return (
    <FormSubGroupWrapper>
      <FormSubGroup>
        <Section marginBottom="0" margin="0.5rem 0" columns={3}>
          <SectionColumn>
            <TowingTypeInput label={formatMessage(messages.settings_type)} name="label" isAl={isAl} disabled={isAl} />
          </SectionColumn>
          <SectionColumn>
            <StationInput
              label={formatMessage(messages.settings_station)}
              name="stationId"
              required={stationRequired}
            />
          </SectionColumn>
          <SectionColumn>
            <OperatorInput
              label={formatMessage(messages.settings_operator)}
              name="operatorId"
              disabled={operatorDisabled}
              clearable
              placeholder="Ei osoitettu"
            />
          </SectionColumn>
        </Section>
      </FormSubGroup>
    </FormSubGroupWrapper>
  )
}

export const TowingOrderSettingsForm = injectIntl(TowingOrderSettingsFormIntl)
