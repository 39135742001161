import {
  toScalarEmail,
  toScalarPersonalIdentityCode,
  toScalarPhone,
  toScalarPostalCode,
} from '../../../util/convertToScalar'
import { PersonCustomerFormValues } from '../types'
import { MutationInput } from './types'
import { isStringNullOrEmpty } from '../../../util/common'

export const toMutationVariables = (id: number, values: PersonCustomerFormValues): MutationInput => {
  return {
    input: {
      id,
      customer: {
        lastName: values.lastName,
        firstName: values.firstName,
        ssn: isStringNullOrEmpty(values.ssn) ? null : toScalarPersonalIdentityCode(values.ssn),
        email: isStringNullOrEmpty(values.email) ? null : toScalarEmail(values.email),
        phone: isStringNullOrEmpty(values.phone) ? null : toScalarPhone(values.phone),
        address: values.address,
        additionalInfo: values.additionalInfo,
        city: values.city === '' ? null : values.city,
        co: values.co,
        identifier: values.identifier,
        deliveryMethod: values.deliveryMethod,
        eInvoiceAddress: values.eInvoiceAddress,
        eInvoiceOperator: values.eInvoiceOperator,
        extraInfo: values.extraInfo,
        zipcode: isStringNullOrEmpty(values.zipcode) ? null : toScalarPostalCode(values.zipcode),
        notificationMethod: values.notificationMethod,
      },
    },
  }
}
