import React from 'react'
import { InjectedIntl, InjectedIntlProps, injectIntl } from 'react-intl'
import { TowingOrderStatus, TowingOrderVisibility, TowingRecordJobStatus, TowingRecordStatus } from '../../common/types'

interface Props {
  status: TowingOrderStatus | TowingRecordStatus
  jobStatus?: TowingRecordJobStatus
  visibility?: TowingOrderVisibility
  operatorName: string
}

export const getStatus = (intl: InjectedIntl, status: string, operatorName: string, visibility?: string, jobStatus?: string, ): string => {
  const { formatMessage } = intl
  const translateMessage = (messageId: string) => formatMessage({ id: messageId })
  const operatorLabel = operatorName !== '' && typeof operatorName !== 'undefined' ? ` (${operatorName})` : ''

  if(jobStatus === 'cancelled' && status === 'billing'){
    return `${translateMessage('towingRecord.job_status_cancelled')}${operatorLabel} (Valmis laskutettavaksi)`
  }
  
  if(jobStatus === 'cancelled'){
    return `${translateMessage('towingRecord.job_status_cancelled')}${operatorLabel}`
  }
  
  if (status === 'open' && visibility === 'open') {
    return `${translateMessage('towingOrder.status_open_open')}${operatorLabel}`
  }

  if (status === 'open' && visibility === 'restricted') {
    return `${translateMessage('towingOrder.status_open_restricted')}${operatorLabel}`
  }

  if (status === 'assigned' && visibility === 'assigned') {
    return `${translateMessage('towingOrder.status_assigned_assigned')}${operatorLabel}`
  }

  if (status === 'accepted') {
    return `${translateMessage('towingOrder.status_accepted')}${operatorLabel}`
  }

  if (status === 'cancelled') {
    return `${translateMessage('towingOrder.status_cancelled')}${operatorLabel}`
  }

  if (status === 'pending_acceptance') {
    return `${translateMessage('towingOrder.status_pending_acceptance')}${operatorLabel}`
  }

  if (status === 'towing_record_created') {
    return `${translateMessage('towingOrder.status_towing_record_created')}${operatorLabel}`
  }

  if (status === 'unfinished') {
    return `${translateMessage('towingRecord.tr_status_unfinished')}${operatorLabel}`
  }

  if (status === 'complete') {
    return `${translateMessage('towingRecord.tr_status_complete')}${operatorLabel}`
  }

  if (status === 'deleted') {
    return `${translateMessage('towingRecord.tr_status_deleted')}${operatorLabel}`
  }

  if (status === 'billing') {
    return `${translateMessage('towingRecord.tr_status_billing')}${operatorLabel}`
  }

  if (status === 'aggregate') {
    return `${translateMessage('towingRecord.tr_status_aggregate')}${operatorLabel}`
  }

  return ''
}

const JobStatusIntl: React.FC<Props & InjectedIntlProps> = ({ intl, status, visibility, operatorName, jobStatus }) => {
  return <>{getStatus(intl, status, operatorName, visibility, jobStatus)}</>
}

export const JobStatus = injectIntl(JobStatusIntl)
