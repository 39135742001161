import { Field } from 'formik'
import React, { useCallback } from 'react'
import { CheckboxField } from '../../field'

interface Props {
  name: string
  label?: string
  required?: boolean
  disabled?: boolean
  margin?: string
}

export const CheckboxInput: React.FunctionComponent<Props> = ({ name, label, disabled, required, margin }) => {
  const validate = useCallback(
    (value: boolean): string | undefined => {
      if (required && !value) {
        return 'Kenttä on pakollinen'
      }
      return undefined
    },
    [required]
  )

  return (
    <Field
      component={CheckboxField}
      id={name}
      name={name}
      label={label}
      required={required}
      validate={validate}
      disabled={disabled}
      margin={margin}
    />
  )
}
