import { Maybe } from '../../common/types'
import { sosVariables } from './types'

export const translateDeliverable = (deliverable: Maybe<string>) => {
  const option = sosVariables.deliverable.find((d) => d.value === deliverable)

  if (typeof option !== 'undefined') {
    return option.label
  }

  return ''
}

export const translateCause = (cause: Maybe<string>) => {
  const option = sosVariables.cause.find((c) => c.value === cause)

  if (typeof option !== 'undefined') {
    return option.label
  }

  return ''
}
