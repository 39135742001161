import React from 'react'
import styled from 'styled-components'
import { CameraContainer } from '..'
import { ButtonContainer } from '../../../../containers/TowingRecordPage/components/routes/subforms/styled'
import { Button } from '../../../Button'

interface ImagePreviewProps {
  dataUri: string
  onDiscard: () => void
  onSelect: (dataUri: string) => void
}

export const ImagePreview: React.FC<ImagePreviewProps> = ({ dataUri, onDiscard, onSelect }) => {
  const handleSelect = () => {
    onSelect(dataUri)
  }
  return (
    <CameraContainer>
      <Preview src={dataUri} />
      <ButtonContainer floatAtBottomNoMenu>
        <DiscardButton category="cancel" size="l" label={'Hylkää'} maxWidth="100%" onClick={onDiscard} />
        <AcceptButton category="save" size="l" label={'Hyväksy'} maxWidth="100%" onClick={handleSelect} />
      </ButtonContainer>
    </CameraContainer>
  )
}

const DiscardButton = styled(Button)`
  margin-right: 0.25rem;
`

const AcceptButton = styled(Button)`
  margin-left: 0.25rem;
`

const Preview = styled.img`
  width: 100vw;
  height: 100vh;
`
