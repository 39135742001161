import styled from 'styled-components'

export const ComboInputWrapper = styled.div`
  display: flex;

  & > div:last-of-type {
    width: 40%;
  }

  & > div:first-of-type {
    width: 100%;
  }

  & div:last-of-type > input {
    border-radius: 0 5px 5px 0;
  }

  & div:first-of-type > input {
    border-radius: 5px 0 0 5px;
  }
`

export const DateTimeInputWrapper = styled.div`
  display: flex;
  width: 100%;

  & > div:last-of-type {
    width: 50%;
  }

  & > div:first-of-type {
    width: 50%;
  }

  & div:first-of-type > input {
    border-radius: 5px;
  }

  & div.react-date-picker {
    border-radius: 5px;
  }
`

export const ButtonInputWrapper = styled.div`
  display: flex;

  & > div:last-of-type {
    width: 35%;
    flex-direction: column-reverse;
  }

  & > div:first-of-type {
    width: 65%;
  }

  & div:last-of-type > div {
    border-radius: 0 ${props => props.theme.radius.medium} ${props => props.theme.radius.medium} 0;
  }

  & div:last-of-type > div > .Select__control {
    background-color: ${props => props.theme.colors['blue800']};
    border: 0;
  }

  & div:last-of-type > div > .Select__control--is-disabled {
    background-color: ${props => props.theme.colors.grey300};
  }

  & .Select__placeholder {
    color: #fff;
    left: 50%;
    font-weight: ${props => props.theme.fontWeight.bold};
    font-size: 1rem;
  }

  & .Select__indicators {
    visibility: hidden;
  }

  & div:first-of-type > input {
    border-radius: 5px 0 0 5px;
  }

  & .Select__menu {
    width: max-content;
    right: 0;
    border-radius: ${props => props.theme.radius.medium}!important;
  }
`
