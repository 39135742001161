import { ItemVatCategory, JobItemDiscountType, JobItemType } from '../../common/types'
import { Item } from '../../containers/Parameters/types'
import { JobItemInput } from './types'

export function convertItemToJobItem(item: Item): JobItemInput {
  
  return {
    id: null,
    itemId: item.id,
    type: JobItemType.normal,
    description: '',
    discount: 0,
    discountType: JobItemDiscountType.percentage,
    grossAmount: 0,
    name: item.name,
    netAmount: 0,
    quantity: 1,
    unit: item.unit,
    unitPrice: item.unitPrice,
    vat: 25.5,
    vatAmount: 0,
    unitPriceHasVatIncluded: false,
    vatCategory: item.vatCategory === ItemVatCategory.standard ? ItemVatCategory.standard_new : item.vatCategory,
    limitedUsage: item.limitedUsage,
  }
}
