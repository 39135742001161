import React from 'react'
import styled from 'styled-components'
import { Icon } from '../../../../components/icons/Icon'
import { TowingRecordJobStatus } from '../../../../common/types'
import { SubmitFormType } from './types'
import { AutoliittoSeparatorLine } from './subforms/styled'
import { ArrivalRouteAction } from '../../../../components/SosSubForm/ArrivalRoute/types'

interface RoutesHeaderProps {
  routeDone: boolean
  arrived: TowingRecordJobStatus[]
  finished: TowingRecordJobStatus[]
  jobStatus: TowingRecordJobStatus
  action: string
  setAction: (action: ArrivalRouteAction) => void
  selectedForm: SubmitFormType
  setSelectedForm: (value: SubmitFormType) => void
  children?: React.ReactNode
}

export const RoutesFormSelector: React.FunctionComponent<RoutesHeaderProps> = (props: RoutesHeaderProps) => {
  const { selectedForm, setSelectedForm, routeDone, arrived, finished, children, setAction } = props

  const handleSelectArrived = () => {
    const openArrivedForm = !routeDone && arrived.includes(props.jobStatus)
    if (openArrivedForm) setAction('edit-arrived')
    setSelectedForm('arrived')
  }

  const handleSelectFinished = () => {
    const openFinishedForm = routeDone && !finished.includes(props.jobStatus)
    if (openFinishedForm) setAction('edit-finished')
    setSelectedForm('finished')
  }

  return (
    <>
      <AutoliittoSeparatorLine marginTop="1.5rem" marginBottom="0" />
      <Wrapper>
        <RouteHeader
          label="Saapunut"
          completed={routeDone}
          selected={selectedForm === 'arrived'}
          onClick={handleSelectArrived}
        >
          <Icon marginRight="10px" size="medium" color={routeDone ? 'black' : 'grey400'} icon={'arrow-right'} />
        </RouteHeader>
        <RouteHeader
          label="Valmis"
          completed={routeDone && finished.includes(props.jobStatus)}
          selected={selectedForm === 'finished'}
          onClick={handleSelectFinished}
        />
      </Wrapper>
      <AutoliittoSeparatorLine marginTop="0" marginBottom="1.5rem" />
      {children}
    </>
  )
}

interface RouteHeaderProps {
  onClick: () => void
  completed: boolean
  selected: boolean
  label: string
  children?: React.ReactNode
}

export const RouteHeader: React.FunctionComponent<RouteHeaderProps> = ({
  onClick,
  completed,
  label,
  selected,
  children,
}) => {
  return (
    <NavigateToWrapper onClick={onClick}>
      <LabelWrapper color={completed ? 'black' : 'grey400'} selected={selected}>
        {label}
      </LabelWrapper>

      <Icon size="medium" color={completed ? 'green' : 'grey300'} icon={'done'} />
      {children}
    </NavigateToWrapper>
  )
}

const NavigateToWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -0.5rem;
  z-index: 1;
`

interface WrapperProps {
  backgroundColor?: string
}

const Wrapper = styled.section<WrapperProps>`
  display: flex;
  flex-direction: row;
  background-color: ${props =>
    props.backgroundColor ? props.theme.colors[props.backgroundColor] : props.theme.colors.white};
`

interface LabelWrapperProps {
  selected: boolean
}

const LabelWrapper = styled.div<LabelWrapperProps>`
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 35px;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    font-size: 25px;
  }
  font-weight: 600;
  text-decoration: ${props => (props.selected ? 'underline' : 'none')};
  color: ${props => (props.selected ? props.theme.colors.black : props.theme.colors[props.color])};
  margin-right: 5px;
`
