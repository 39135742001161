import gql from 'graphql-tag'

export const ADD_TOWING_RECORD_MUTATION = gql`
  mutation AddTowingRecord($input: AddTowingRecordInput!) {
    addTowingRecord(input: $input) {
      ... on AddTowingRecordSuccess {
        towingRecord {
          id
        }
      }
      ... on GenericError {
        genericError: error {
          code
          message
        }
      }
      ... on UnauthorizedError {
        error {
          code
          message
        }
      }
      ... on InvalidParameterError {
        error {
          code
          message
          details
          context
        }
      }
    }
  }
`
