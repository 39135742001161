import { Field } from 'formik'
import React, { useCallback } from 'react'
import { NumberField } from '../../field'

interface Props {
  name: string
  label?: string
  required?: boolean
  disabled?: boolean
  decimals?: number
}

export const NumberInput: React.FunctionComponent<Props> = ({ name, label, disabled, required, decimals }) => {
  const validate = useCallback(
    (value: number | null): string | undefined => {
      if (required && !value) {
        return 'Kenttä on pakollinen'
      }
      return undefined
    },
    [required]
  )

  return (
    <Field
      component={NumberField}
      id={name}
      name={name}
      label={label}
      required={required}
      validate={validate}
      disabled={disabled}
      decimals={decimals}
      placeholder="N/A"
    />
  )
}
