import styled, { css } from 'styled-components'

interface ImageWrapperProps {
  justifyContent?: string
  alignItems?: string
}

export const ImageWrapper = styled.div<ImageWrapperProps>`
  width: auto;
  max-width: 100%;
  display: flex;
  ${props =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `}
  ${props =>
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `}
    @media (max-width: ${props => props.theme.screenSize.mobile}) {
      justify-content: start;
      align-items: start;
    }
`
