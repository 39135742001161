import { useMutation } from '@apollo/react-hooks'
import React from 'react'

import { Container, ModalContainer, Page } from '../../../components/layout'
import { setErrorNotifications, setFatalErrorNotification } from '../../../components/responses/setErrorNotifications'
import { EditPersonResponse, PersonCustomerFormValues } from '../types'
import { EditPersonCustomerForm } from './EditPersonCustomerForm'
import { EDIT_PERSON_CUSTOMER } from './mutation'
import { toMutationVariables } from './toMutationVariables'
import { EditPersonCustomerResponse } from './types'

interface EditPersonCustomerProps {
  id: number
  personCustomer: PersonCustomerFormValues
  onCloseForm: (updatedPerson: EditPersonResponse) => void
  onCancelForm: () => void
}

export const EditPersonCustomerPage: React.FunctionComponent<EditPersonCustomerProps> = ({
  onCloseForm,
  onCancelForm,
  personCustomer,
  id,
}) => {
  const [editPersonCustomer, { loading }] = useMutation<EditPersonCustomerResponse>(EDIT_PERSON_CUSTOMER, {
    onCompleted({ editPersonCustomer }) {
      if (editPersonCustomer.__typename !== 'EditPersonCustomerSuccess') {
        setErrorNotifications({ data: editPersonCustomer })
      }
    },
    onError(err) {
      setFatalErrorNotification(err.message)
    },
  })

  return (
    <ModalContainer>
      <Container>
        <Page>
          <EditPersonCustomerForm
            onCancelForm={onCancelForm}
            onCloseForm={onCloseForm}
            initialValues={personCustomer}
            editPersonCustomer={values => editPersonCustomer({ variables: toMutationVariables(id, values) })}
            loading={loading}
          />
        </Page>
      </Container>
    </ModalContainer>
  )
}
