import React, { useEffect, useState } from 'react'
import { PoseGroup } from 'react-pose'
import styled from 'styled-components'

import { LockMessage, TowingOrderType, TypeIdentifier } from '../../common/types'
import { isActiveJob } from '../../containers/TowingJobsPage/countJobs'
import autoliitto from '../../images/autoliitto.png'
import lahitapiola from '../../images/lahitapiola.png'
import sos from '../../images/sos.png'
import { Icon } from '../icons/Icon'
import { JobStatus } from '../JobStatus'
import { JobTypeDescription, getDeliverable } from '../JobTypeDescription'
import {
  ExpandedList,
  Heading3,
  ImageWrapper,
  List,
  ListColumn,
  ListRowWrapper,
  ListStatus,
  SimpleRow,
  Text,
} from '../layout'
import { NavigateTo } from '../NavigateTo'
import { TowingOrderScheduling } from '../TowingOrderScheduling'
import { VehicleMarkAndModel } from '../VehicleMarkAndModel'
import { TowingOrderJobInterface } from './types'
import { useSocket } from '../../util/useSocket'
import { getStoredCompanyId } from '../../graphql-client/storedState'
import { parseAddress } from '../Parsers'
import { getRepairShop } from './getRepairShop'

const StyledIcon = styled(Icon)`
  svg {
    width: 1rem;
    height: 1rem;
    margin-left: -2px;
  }
`
interface OrderRowProps {
  active: boolean
}

const OrderRow = styled(ListRowWrapper)<OrderRowProps>`
  ${props => props.active && `background-color: ${props.color ? props.theme.colors[props.color] : props.theme.colors.orange50}`}
`

interface Props {
  towingOrder: TowingOrderJobInterface
  slug: string
  translateMessage: (msgId: string) => string
  expandedRow: number | null
  handleItemClick: (id: number) => void
  station: {
    name: string
  } | null
  operatorName: string
}

export const TowingOrderRow: React.FunctionComponent<Props> = ({
  expandedRow,
  handleItemClick,
  towingOrder,
  slug,
  station,
  operatorName,
}) => {
  const [locked, setLocked] = useState<string | null>(towingOrder.locked)
  const companyId = getStoredCompanyId()
  const repairShop = getRepairShop(towingOrder)

  const socket = useSocket()
  useEffect(() => {
    if (!companyId) {
      return
    }
    const lockedCallback = (message: string) => {
      const data: LockMessage = message ? JSON.parse(message) : null
      setLocked(data && data.userName ? data.userName : null)
    }
    const unlockedCallback = () => {
      setLocked(null)
    }
    socket.on(`company-${companyId}.towing_order-${towingOrder.id}.locked`, lockedCallback)
    socket.on(`company-${companyId}.towing_order-${towingOrder.id}.unlocked`, unlockedCallback)
    return () => {
      console.log('removing event listeners from towing order')
      socket.removeEventListener(`company-${companyId}.towing_order-${towingOrder.id}.locked`, lockedCallback)
      socket.removeEventListener(`company-${companyId}.towing_order-${towingOrder.id}.unlocked`, unlockedCallback)
    }
  }, [setLocked, companyId, socket, towingOrder.id])

  return (
    <OrderRow
      active={isActiveJob(towingOrder.status, towingOrder.operatorId)}
      onClick={() => handleItemClick(towingOrder.id)}
    >
      <List>
        <ListColumn justify="center">
          <Heading3>
            {towingOrder.vehicleDetails.registrationNumber ? towingOrder.vehicleDetails.registrationNumber : '-'}
          </Heading3>
          <VehicleMarkAndModel markAndModel={towingOrder.vehicleDetails.makeAndModel} />
          <TowingOrderScheduling jobDetails={towingOrder.jobDetails} />
        </ListColumn>
        <ListColumn justify="center">
          {towingOrder.typeIdentifier === TypeIdentifier.autoliitto && (
            <ImageWrapper justifyContent="center" alignItems="center">
              <img src={autoliitto} alt="Autoliitto" width="55rem" />
            </ImageWrapper>
          )}
          {towingOrder.typeIdentifier === TypeIdentifier.sos && (
            <ImageWrapper justifyContent="center" alignItems="center">
              <img src={sos} alt="SOS" width={`40rem`} />
            </ImageWrapper>
          )}
          {towingOrder.typeIdentifier === TypeIdentifier.lahitapiola && (
            <ImageWrapper justifyContent="center" alignItems="center">
              <img
                src={`${towingOrder.type === TowingOrderType.sos ? sos : lahitapiola}`}
                alt="Lähitapiola"
                width={`40rem`}
              />
            </ImageWrapper>
          )}
        </ListColumn>
        <ListColumn>
          <ListStatus status={towingOrder.status}>
            <JobStatus status={towingOrder.status} visibility={towingOrder.visibility} operatorName={operatorName} />
          </ListStatus>
          <Text color="grey400">
            <JobTypeDescription
              serviceType={towingOrder.jobDetails.serviceType}
              type={towingOrder.typeIdentifier}
              label={towingOrder.label}
              station={station}
              deliverable={getDeliverable(towingOrder.sosServiceOrder)}
            />
          </Text>
        </ListColumn>
        <ListColumn>
          <NavigateTo direction={locked ? 'locked' : 'forward'} slug={slug} />
        </ListColumn>
      </List>
      <PoseGroup>
        {towingOrder.id === expandedRow && (
          <ExpandedList key={expandedRow}>
            <ListColumn>
              <SimpleRow justify={'flex-start'} marginMobileBottom="0">
                <StyledIcon icon="map-marker" color="black" size="x-small" marginRight="0.3rem" />
                <Text>{`${towingOrder.from.address} ${towingOrder.from.city}`}</Text>
              </SimpleRow>
              {towingOrder.from.description !== '' && (
                <SimpleRow marginMobileBottom="0" margin="0.25rem 0 0.25rem 1.25rem">
                  <Text>{`(${towingOrder.from.description})`}</Text>
                </SimpleRow>
              )}

              {repairShop &&
              towingOrder.typeIdentifier === TypeIdentifier.autoliitto &&
              towingOrder.jobDetails.serviceType !== 'towing' ? (
                <>
                  <SimpleRow justify={'flex-start'} marginMobileBottom="0" margin="0.25rem 0 0 0">
                    <StyledIcon icon="map-marker" color="black" size="x-small" marginRight="0.3rem" />
                    <Text fontWeight="bold">{`Jos työ muuttuu hinaukseksi:`}</Text>
                  </SimpleRow>
                  <SimpleRow marginMobileBottom="0" margin="0.25rem 0 0 1.25rem">
                    <Text>{parseAddress(repairShop.location.address, repairShop.location.city)}</Text>
                  </SimpleRow>
                  <SimpleRow marginMobileBottom="0" margin="0 0 0.25rem 1.25rem">
                    <Text>{`(${repairShop.name})`}</Text>
                  </SimpleRow>
                </>
              ) : (
                <>
                  <SimpleRow justify={'flex-start'} marginMobileBottom="0" margin="0.25rem 0 0 0">
                    <StyledIcon icon="map-marker" color="black" size="x-small" marginRight="0.3rem" />
                    <Text>{`${towingOrder.to.address} ${towingOrder.to.city}`}</Text>
                  </SimpleRow>
                  {towingOrder.to.description !== '' && (
                    <SimpleRow marginMobileBottom="0" margin="0.25rem 0 0.25rem 1.25rem">
                      <Text>{`(${towingOrder.to.description})`}</Text>
                    </SimpleRow>
                  )}
                </>
              )}
            </ListColumn>
            <ListColumn />
            <ListColumn>
              <Text>{towingOrder.jobDetails.notes}</Text>
            </ListColumn>
            <ListColumn />
          </ExpandedList>
        )}
      </PoseGroup>
    </OrderRow>
  )
}
