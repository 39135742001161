import { toast } from 'react-toastify'

import { setErrorNotification } from './errorNotification'
import { setGenericResponseErrorNotification } from './genericResponseErrorNotification'
import { setGoogleAPIGeolocationErrorNotification } from './googleAPIGeolocationErrorNotification'
import { setInfoNotification } from './infoNotification'
import { setInvalidParamNotification } from './invalidParameterErrorNotification'
import { setInvalidVisibilitySettingsNotification } from './invalidVisibilitySettingsNotification'
import { setResourceNotFoundNotification } from './resourceNotFoundNotification'
import { setStatusChangeForbiddenNotification } from './statusChangeForbiddenNotification'
import { setSuccessNotification } from './successNotification'
import { setUnauthorizedNotification } from './unauthorizedNotification'

function dismissNotifications() {
  toast.dismiss()
  return
}

function setTimeOutToast(delay: number): Promise<void> { 
  console.log("setTimeOutToast")
  return new Promise( resolve => setTimeout(resolve, delay) )
}

const dismissToast: (toastId: any, delay: number) => void = async (toastId, delay) => {
    if (delay === 0) {
      return
    }
    await setTimeOutToast(delay)
    toast.dismiss(toastId)
}


export {
  dismissToast,
  dismissNotifications,
  setInfoNotification,
  setErrorNotification,
  setSuccessNotification,
  setStatusChangeForbiddenNotification,
  setUnauthorizedNotification,
  setGoogleAPIGeolocationErrorNotification,
  setResourceNotFoundNotification,
  setGenericResponseErrorNotification,
  setInvalidVisibilitySettingsNotification,
  setInvalidParamNotification,
}
