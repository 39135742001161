import React from 'react'
import { Heading2, Page, Text } from '../../components/layout'

export const SearchPage: React.FunctionComponent = () => {
  return (
    <Page>
      <Heading2>Tietojen haku</Heading2>
      <Text>Toistaiseksi voi tehdä Trafi- ja luottotietohakuja vain tilaus- ja kuormakirjalomakkeista käsin.</Text>
    </Page>
  )
}
