import React from 'react'
import styled from 'styled-components'
import { Button } from '../Button'
import { SectionColumn } from '../layout'

interface ThumbnailProps {
  src: string
  onDelete: () => void
}

export const Thumbnail: React.FC<ThumbnailProps> = ({ src, onDelete }) => {
  return (
    <>
      <ImageContainer flexDirection="column">
        <Img src={src} alt="" />
        <Button category="cancel" size="s" maxWidth="100%" onClick={onDelete}>
          Poista
        </Button>
      </ImageContainer>
    </>
  )
}

const ImageContainer = styled(SectionColumn)`
  border: 1px solid;
  border-radius: ${props => props.theme.radius.large};
  padding: 1rem;
`

const Img = styled.img`
  margin-bottom: 1rem;
`
