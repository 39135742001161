import React, { useEffect, useState } from 'react'
import { RouteColumn, RouteRow } from '../../../../../../../components/layout'
import { Time, TowingRecordJobStatus } from '../../../../../../../common/types'
import { LocationInputMap, toMapData } from '../../../../../../../components/layout/form/input/LocationInputMap'
import { ArrivalDateTimeInput } from '../../../../../../../components/layout/form/input/DateTimeInput'
import { format } from 'date-fns'

import { TimeSelectorInput } from '../../../../../../../components/layout/form/input'
import { CollapseContent } from '../../../../../../../components/Collapse/CollapseContent'
import { ALProps, ArrivedAtBreakdownLocationProps } from './ArrivedForm'

export const ArrivedAtBreakdownLocationFormAL: React.FunctionComponent<ArrivedAtBreakdownLocationProps & ALProps> = ({
  setValue,
  action,
  getValue,
  setRouteLocation,
  stationId = null,
  setDateField,
  clear,
}) => {
  const [edit, setEdit] = useState<boolean>(action === 'edit-finished')

  useEffect(() => {
    if (action === 'edit-arrived') {
      if (!edit) setEdit(true)
    } else {
      if (edit) setEdit(false)
    }
  }, [edit, action, setEdit])

  return (
    <CollapseContent>
      <RouteRow>
        <RouteColumn>
          <LocationInputMap
            addressLabel={`Osoite`}
            addressName={`arrivalRoute.address`}
            cityLabel={`Kaupunki`}
            cityName={`arrivalRoute.city`}
            coordsName={`arrivalRoute.coords`}
            zipcodeName={`arrivalRoute.zipcode`}
            required
            coordsRequired
            getValue={getValue}
            values={toMapData(
              getValue(`arrivalRoute.address`),
              getValue(`arrivalRoute.city`),
              getValue(`arrivalRoute.coords`),
              getValue(`arrivalRoute.zipcode`)
            )}
            setValue={setValue}
            disabled={!edit}
            setRouteLocation={setRouteLocation}
            stationId={stationId}
            clear={clear}
          />
        </RouteColumn>

        <RouteColumn>
          <ArrivalDateTimeInput
            label="Saapumisaika"
            name={`arrivalRoute`}
            customOnChange={() => {
              const value = (format(new Date(), 'HH:mm') as unknown) as Time
              setDateField('arrivalRoute.arrivalDate')
              setValue('arrivalRoute.arrivalTime', value)
            }}
            required
            disabled={!edit}
            showCurrentTimeBtn
          />
        </RouteColumn>
        <RouteRow columns="3fr">
          <RouteColumn>
            <TimeSelectorInput
              timeSelectorLabel="Arvioitu työnkesto"
              timeFieldLabel="Arvioitu valmistumisaika"
              name="automobileAndTouringClubFinland.estimatedTimeOfCompletion.time"
              required
              disabled={!edit}
              customOnChange={() => setDateField('automobileAndTouringClubFinland.estimatedTimeOfCompletion.date')}
            />
          </RouteColumn>
        </RouteRow>
      </RouteRow>
    </CollapseContent>
  )
}

export const finishedAtDestinationEditableJobStatuses = [TowingRecordJobStatus.finished_at_destination]
