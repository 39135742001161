import gql from 'graphql-tag'
import { MultifactorCodeFormValues } from '..'
import { User } from '../../../common/types'
import { ErrorsTypes } from '../../../components/responses/setErrorNotifications'

export interface MultifactorAuthenticationCodeMutationVariables
  extends Readonly<{
    input: MultifactorCodeFormValues
  }> {}

export interface MultifactorAuthenticationCodeResponse {
  submitCode: MultifactorAuthenticationCodeOutput
}

export interface SendSmsCodeResponse {
  sendSmsCode: SendSmsCodeOutput
}

export type MultifactorAuthenticationCodeOutput = MultifactorAuthenticationCodeSuccess | ErrorsTypes

export type SendSmsCodeOutput = SendSmsCodeSuccess | ErrorsTypes

interface MultifactorAuthenticationCodeSuccess
  extends Readonly<{
    __typename: 'MultifactorAuthenticationCodeSuccess'
    user: User
    accessToken: string
    success: true
  }> {}

interface SendSmsCodeSuccess
  extends Readonly<{
    __typename: 'SendSmsCodeSuccess'
    success: true
  }> {}

export const MULTIFACTOR_AUTHENTICATION_CODE_MUTATION = gql`
  mutation MultifactorAuthenticationCode($input: MultifactorAuthenticationCodeInput!) {
    submitCode(input: $input) {
      __typename
      ... on UnauthorizedError {
        error {
          code
          message
        }
      }
      ... on GenericError {
        genericError: error {
          code
          message
        }
      }
      ... on MultifactorAuthenticationError {
        error {
          code
          message
        }
      }
      ... on MultifactorAuthenticationCodeSuccess {
        user {
          companyId
          userId
          role
          username
          realName
          mfa {
            status
            active
            rememberMeForDays
            phone
          }
        }
        accessToken
      }
    }
  }
`

export const SEND_SMS_VERIFICATION_CODE = gql`
  mutation SendSmsCode {
    sendSmsCode {
      __typename
      ... on UnauthorizedError {
        error {
          code
          message
          details
          context
        }
      }
    }
  }
`
