import { useMutation } from '@apollo/react-hooks'
import { Formik } from 'formik'
import React from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'

import { Button } from '../../../../../components/Button'
import { Currency } from '../../../../../components/Currency'
import { DisplayDate } from '../../../../../components/DisplayDate'
import { ButtonColumn, ButtonRow, Heading1, Row, Text } from '../../../../../components/layout'
import { CheckboxInput } from '../../../../../components/layout/form/input'
import { ScrollToError } from '../../../../../components/layout/form/ScrollToError'
import { FormikForm as Form } from '../../../../../components/layout/form/style'
import { Loading } from '../../../../../components/responses'
import {
  setErrorNotifications,
  setFatalErrorNotification,
} from '../../../../../components/responses/setErrorNotifications'
import { TopNavigation } from '../../../../../components/TopNavigation/TopNavigation'
import { CONFIRM_BILLING } from './confirmMutation'
import { messages as billingConfirmationMessages } from './messages'
import { toConfirmBillingMutationVariables } from './toConfirmBillingMutationVariables'
import { Billing, ConfirmBillingFormValues } from './types'

interface ModalProps {
  billing: Billing
  towingRecordId: number
  onConfirmBilling: () => void
  close: () => void
  continueEditing: () => void
}

const BillingConfirmationIntl: React.FunctionComponent<ModalProps & InjectedIntlProps> = ({
  billing,
  onConfirmBilling,
  towingRecordId,
  close,
  continueEditing,
  intl,
}) => {
  const {
    type,
    paymentDetails: { vattedAmount, debtorName, receiptEmail, paymentDate },
  } = billing

  const [confirmBilling, { loading }] = useMutation(CONFIRM_BILLING, {
    onCompleted(result) {
      if (result.confirmBilling.__typename === 'ConfirmBillingSuccess') {
        onConfirmBilling()
      } else {
        setErrorNotifications({ data: result.confirmBilling })
      }
    },
    onError(err) {
      setFatalErrorNotification(err.message)
    },
  })

  return (
    <Formik
      initialValues={{ towingRecordId, sendReceipt: false }}
      onSubmit={(values: ConfirmBillingFormValues) =>
        confirmBilling({
          variables: toConfirmBillingMutationVariables(values),
        })
      }
      render={() => {
        return (
          <Form onKeyDown={e => e.key === 'Enter' && e.preventDefault()}>
            <TopNavigation />
            <Row justify="center">
              <Heading1>Kuittaa maksu</Heading1>
            </Row>
            <Row justify="center">
              <Text fontWeight="700" size="big">
                {intl.formatMessage(billingConfirmationMessages[type])} <Currency>{vattedAmount}</Currency>
              </Text>
            </Row>
            <Row justify="center">
              <Text size="big">{debtorName}</Text>
            </Row>
            <Row justify="center">
              <Text size="big">
                <DisplayDate date={paymentDate} />
              </Text>
            </Row>
            {receiptEmail != null && receiptEmail !== '' && (
              <Row justify="center">
                <CheckboxInput name="sendReceipt" label={`Lähetä kuitti ${receiptEmail}`} />
              </Row>
            )}

            <Row justify="center">
              <ButtonRow>
                <ButtonColumn>
                  <Button category="save" label="Merkitse maksetuksi" submit />
                </ButtonColumn>
                <ButtonColumn>
                  <Button category="new" label="Jätä avoimeksi" onClick={close} />
                </ButtonColumn>
                <ButtonColumn>
                  <Button category="new" label="Jatka muokkausta" onClick={continueEditing} />
                </ButtonColumn>
              </ButtonRow>
            </Row>
            <ScrollToError />
            <Loading loading={loading} />
          </Form>
        )
      }}
    />
  )
}

export const BillingConfirmation = injectIntl(BillingConfirmationIntl)
