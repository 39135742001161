import { ItemVatCategory, JobItemVatUnit } from '../../common/types'

interface VatSpecification {
  label: string
  vat: number
  vatUnit: JobItemVatUnit
}

// key: vat category name in items "catalogue" (item.vatCategory)
// label: what is shown in ui
// vat = vat percentage
// vatUnit: vat category when item added to towing record
export const vatMapping: Record<ItemVatCategory, VatSpecification> = {
  standard_new: { label: '25,5 %', vat: 25.5, vatUnit: JobItemVatUnit.standard_new },
  standard: { label: '24 %', vat: 24, vatUnit: JobItemVatUnit.standard },
  food_and_restaurants: { label: '14 %', vat: 14, vatUnit: JobItemVatUnit.food_and_restaurants },
  transports_and_cultural: { label: '10 %', vat: 10, vatUnit: JobItemVatUnit.transports_and_cultural },
  zero_tax_services: { label: '0 %', vat: 0, vatUnit: JobItemVatUnit.zero_tax_services },
  margin_tax_used_products: {
    label: '0 % (Marg.verotus - käytetyt tavarat)',
    vat: 0,
    vatUnit: JobItemVatUnit.margin_tax_used_products,
  },
  eu_tax_products: { label: '0 % (EU tavaramyynti)', vat: 0, vatUnit: JobItemVatUnit.eu_tax_products },
  eu_tax_services: { label: '0 % (EU palvelumyynti)', vat: 0, vatUnit: JobItemVatUnit.eu_tax_services },
  non_eu_tax: { label: '0 % (Myynti EU:n ulkopuolelle)', vat: 0, vatUnit: JobItemVatUnit.non_eu_tax },
  construction_tax_reverse_charge: {
    label: '0 % (Rakennusalan käännetty arvonlisäverovelvollisuus)',
    vat: 0,
    vatUnit: JobItemVatUnit.construction_tax_reverse_charge,
  },
}

export const vatCategoryToPercentage = (vatCategory: ItemVatCategory | null) => {
  // use "standard_new" vat category if missing (this due to current rest api bug 29.10.2019)
  const category = vatCategory ? vatCategory : ItemVatCategory.standard_new
  return vatMapping[category].vat / 100
}
