import { Maybe } from '../../common/types'
import { JobFilters, Preferences, Settings, Station } from './types'

export const getStationByIdFromSettings = (id: number | null, settings: Settings | null): Station | null => {
  if (!id) {
    return null
  }

  if (!settings || !settings.stations) {
    return null
  }

  const station = settings.stations.find(stationRow => {
    return stationRow.id === id
  })

  if (!station) {
    return null
  }

  return station
}

export const calculateStationCount = (stations: Maybe<Station[]>): number => {
  if (!stations) {
    return 0
  }

  if (stations.length === 0) {
    return 0
  }

  const filtered = stations.filter(station => station.type === 1 || station.type === 2)

  return filtered.length
}

export const getStationFilters = (
  settings: Maybe<Settings>,
  userId: Maybe<number>,
  preferences?: any
): Maybe<Preferences> => {
  if (!settings) {
    return null
  }

  const filters: JobFilters = { id: null, stations: [], jobTypes: [], visibility: null, operators: null }

  if (settings.stations) {
    filters.stations = settings.stations.map(s => {
      return { id: s.id, name: `${s.name} ${s.shortName !== '' ? `(${s.shortName})` : ''}`, include: true }
    })
  }

  if (settings.labels) {
    const {
      modules: { sos, autoliitto },
    } = settings
    let i = 1

    filters.jobTypes = settings.labels.map(l => {
      i = 1 + 1
      return { name: l, include: true, locked: false, id: i }
    })

    if (autoliitto) {
      filters.jobTypes.push({ name: 'AL', include: true, locked: true, id: 0 })
    }

    if (sos) {
      filters.jobTypes.push({ name: 'SOS', include: true, locked: true, id: 1 })
    }
  }

  if (userId) {
    filters.operators = [userId]
  }

  if (preferences && preferences.id) {
    if (preferences.filters) {
      const stationFilters = preferences.filters.stations
      const jobTypeFilters = preferences.filters.jobTypes

      const combinedJobTypes = []
      const combinedStations = []

      for (let item of filters.jobTypes) {
        const foundFilter = jobTypeFilters.find((type: { name: string }) => type.name === item.name)
        if (foundFilter) {
          combinedJobTypes.push(foundFilter)
        } else {
          combinedJobTypes.push(item)
        }
      }

      for (let item of filters.stations) {
        const foundFilter = stationFilters.find((type: { id: number }) => type.id === item.id)
        if (foundFilter) {
          foundFilter.name = item.name
          combinedStations.push(foundFilter)
        } else {
          combinedStations.push(item)
        }
      }

      preferences.filters.jobTypes = combinedJobTypes
      preferences.filters.stations = combinedStations

      const currentVisibility = (preferences.filters.visibility as unknown) as string
      let visibility = preferences.filters.visibility ? preferences.filters.visibility : null
      if (currentVisibility === 'ALL') {
        visibility = { open: true, own: true, others: true }
      }
      if (currentVisibility === 'OWN') {
        visibility = { open: true, own: true, others: false }
      }

      preferences.filters.visibility = visibility

      return preferences
    }
  }

  return { filters, id: filters.id ? filters.id : null }
}
