import ApolloClient from 'apollo-client'
import React from 'react'
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom'

import { getStoredLogin, getStoredLoginExpiration, storeLogin } from '../../graphql-client/storedState'
import { setUnauthorizedNotification } from '../notification'
import { RENEW_TOKEN_QUERY } from '../responses/token/query'
import { RenewResponse } from '../responses/token/types'

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
  authenticated: boolean
  client?: ApolloClient<any>
}

type RenderComponent = (props: RouteComponentProps) => React.ReactNode

class PrivateRoute extends Route<PrivateRouteProps> {
  public componentDidMount() {
    if (this.props.authenticated) {
      const exp = getStoredLoginExpiration()
      const time = new Date().getTime() / 1000
      const token = getStoredLogin()

      if (token && !exp) {
        storeLogin(token)
      }

      if (!exp) {
        setUnauthorizedNotification()
        return
      }

      if (exp - time <= 10800) {
        const client = this.props.client
        if (client) {
          const response = client.query<RenewResponse>({ query: RENEW_TOKEN_QUERY, fetchPolicy: 'network-only' })
          response.then(response => {
            if (response.data.renewToken.__typename === 'RenewSuccess') {
              storeLogin(response.data.renewToken.token)
            }

            if (response.data.renewToken.__typename === 'UnauthorizedError') {
              setUnauthorizedNotification()
            }
          })
        }
        return
      }
    }
  }
  public render() {
    const { authenticated, component: Component, ...rest }: PrivateRouteProps = this.props
    const renderComponent: RenderComponent = props =>
      authenticated ? <Component {...props} /> : <Redirect to="/login" />
    return <Route {...rest} render={renderComponent} />
  }
}

export default PrivateRoute
