import { ApolloLink } from 'apollo-link'
import {
  ScalarDate,
  ScalarDateTime,
  ScalarBusinessID,
  ScalarCustomerIdentifier,
  ScalarEmail,
  ScalarPersonalIdentityCode,
  ScalarPhone,
  ScalarTime,
  ScalarType,
  ScalarPostalCode,
  ScalarMunicipality,
} from './types'

export function createConvertScalarsLink(): ApolloLink {
  return new ApolloLink((operation, forward) => {
    if (!forward) {
      return null
    }

    return forward(operation).map(data => {
      return traverse(data)
    })
  })
}

function traverse(value: any): any {
  const isObject = typeof value === 'object' && value != null

  if (isObject && Object.keys(value).length === 2 && 'type' in value && 'value' in value) {
    return convertScalar(value)
  }

  if (Array.isArray(value)) {
    return value.map(traverse)
  }
  if (isObject) {
    return Object.keys(value).reduce<{ [key: string]: any }>((acc, key) => {
      acc[key] = traverse(value[key])

      return acc
    }, {})
  }

  return value
}

type ScalarObject =
  | ScalarDate
  | ScalarDateTime
  | ScalarTime
  | ScalarEmail
  | ScalarPhone
  | ScalarBusinessID
  | ScalarPersonalIdentityCode
  | ScalarCustomerIdentifier
  | ScalarPostalCode
  | ScalarMunicipality

type ConvertScalarReturn = Date | string

function convertScalar(scalarObject: ScalarObject): ConvertScalarReturn {
  const { type } = scalarObject
  switch (scalarObject.type) {
    case ScalarType.SHORTDATE:
      return new Date(scalarObject.value)
    case ScalarType.DATETIME:
      return new Date(scalarObject.value)
    case ScalarType.TIME:
      return scalarObject.value
    case ScalarType.EMAIL:
      return scalarObject.value
    case ScalarType.PHONE:
      return scalarObject.value
    case ScalarType.PERSONAL_IDENTITY_CODE:
      return scalarObject.value
    case ScalarType.BUSINESS_ID:
      return scalarObject.value
    case ScalarType.CUSTOMER_IDENTIFIER:
      return scalarObject.value
    case ScalarType.POSTAL_CODE:
      return scalarObject.value
    case ScalarType.MUNICIPALITY:
      return scalarObject.value
    default:
      throw new Error(`No scalar conversion found for type "${type}"`)
  }
}
