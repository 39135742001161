import React, { useCallback, useContext, useEffect, useState } from 'react'
import { getStoredCompanyId, getStoredUserId } from '../../graphql-client/storedState'
import { useSocket } from '../../util/useSocket'
import { Button } from '../Button'
import { useHistoryPush } from '../router'
import styled, { css } from 'styled-components'
import { ModalContainer, ModalHeader, ModalText, Text } from '../layout'
import { ButtonContainer } from '../../containers/TowingRecordPage/components/routes/subforms/styled'
import { Icon } from '../icons/Icon'
import { DispatchStore, StateStore } from '../../containers/StoreProvider'
import { hideSocketModal, setMessageCount, showSocketModal } from '../../containers/StoreProvider/actions'
import uuid from 'uuid'
import {
  persistentAddMessage,
  persistentDeleteMessage,
  persistentGetOrDefault,
  persistentSave,
} from '../../util/localStorage/localStorage'
import { parseMessage } from './util'
import { MessageDataType, MsgType, RecordChangedMessage } from './types'

export const SocketNotificationModal: React.FunctionComponent<any> = () => {
  const { state } = useContext(StateStore)
  const { dispatch } = useContext(DispatchStore)
  const socket = useSocket()
  const userId = getStoredUserId()
  const companyId = getStoredCompanyId()
  const [activePushMessage, setActivePushMessage] = useState<boolean>(false)
  const [messages, setMessages] = useState<MessageDataType[]>([])

  const getUserLocalMessages = useCallback(() => {
    const allItems = persistentGetOrDefault<any[]>('socketMessages', [])
    const userItems = allItems.filter(item => item.userId === userId)
    dispatch(setMessageCount(userItems.length))
    return userItems ? userItems : []
  }, [dispatch, userId])

  const deleteUserLocalMessages = useCallback<() => void>(() => {
    const items = persistentGetOrDefault<any[]>('socketMessages', [])
    const filtered = items.filter(item => item.userId !== userId)
    persistentSave('socketMessages', filtered)
    dispatch(setMessageCount(0))
  }, [dispatch, userId])

  const deleteUserMessage = useCallback(
    function(id: string) {
      persistentDeleteMessage(id)
      const items = getUserLocalMessages()
      setMessages(items)
    },
    [getUserLocalMessages, setMessages]
  )

  const emptyMessageList = function() {
    setMessages([])
    deleteUserLocalMessages()
  }

  useEffect(() => {
    if (!userId || !companyId) {
      socket.removeAllListeners()
      socket.close()
      console.log('closing socket connection')
      return
    }

    const items = getUserLocalMessages()
    setMessages(items)

    const callback = (message: string) => {
      const parsed: MessageDataType = JSON.parse(message)
      const event = parseMessage(parsed, userId)
      if (!event) {
        return
      }

      if (event.type === MsgType.RECORD_CHANGED && !event.data.values && !event.data.towingTargets) {
        return
      }

      if (event.recordId && event.type === MsgType.RECORD_CHANGED) {
        const localEvent = new CustomEvent<MessageDataType>(`record_${event.recordId}:changed`, { detail: event })
        document.dispatchEvent(localEvent)
      }

      // dont show messages that have no data. We still want to trigger the custom event so that if the user is on the record page then record is refetched and locked.
      if (
        event.type === MsgType.RECORD_CHANGED &&
        event.data.values &&
        event.data.values[0] &&
        event.data.values[0].name === 'noData'
      ) {
        return
      }

      persistentAddMessage(event)
      const items = getUserLocalMessages()

      dispatch(showSocketModal())
      setMessages(items)
    }

    socket.on(`user-${userId}.orderCancelled`, callback)
    socket.on(`user-${userId}.recordCancelled`, callback)
    socket.on(`user-${userId}.recordChanged`, callback)
    socket.on(`user-${userId}.orderChanged`, callback)

    return () => {
      console.log('UNMOUNTING AND REMOVING SOCKET LISTENERS')
      socket.removeListener(`user-${userId}.orderCancelled`, callback)
      socket.removeListener(`user-${userId}.recordCancelled`, callback)
      socket.removeListener(`user-${userId}.recordChanged`, callback)
      socket.removeListener(`user-${userId}.orderChanged`, callback)
    }
  }, [userId, companyId, socket, setMessages, setActivePushMessage, dispatch, getUserLocalMessages])

  const historyPush = useHistoryPush()
  const onConfirm = useCallback(
    (index: number) => {
      const current = messages[index]
      if (current.recordId) {
        historyPush(`/towing-record/${current.recordId}`)
        setActivePushMessage(false)
        dispatch(hideSocketModal())
      }
      if (current.orderId) {
        historyPush(`/towing-order/${current.orderId}`)
        setActivePushMessage(false)
        dispatch(hideSocketModal())
      }
    },
    [messages, historyPush, dispatch]
  )

  const onClose = useCallback(() => {
    setActivePushMessage(false)
    dispatch(hideSocketModal())
  }, [dispatch])

  const toggleOldData = useCallback(
    (evt: any, index: number) => {
      evt.preventDefault()
      setMessages(
        messages.map((message, ind) => {
          if (ind === index && message.type === MsgType.RECORD_CHANGED) {
            message.showOld = !message.showOld
          }
          return message
        })
      )
    },
    [setMessages, messages]
  )

  return (
    <>
      <ModalContainer
        style={{
          paddingBottom: '80px',
          transform: activePushMessage || state.showMessages ? 'none' : 'translate(0, -100vh)',
          transition: 'all',
          transitionDuration: '0.5s',
        }}
      >
        <ModalHeader
          style={{
            paddingLeft: '2rem',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <ModalText>Muutoksia tilauksiin</ModalText>
          <Button category="new" size="s" maxWidth="max-content" onClick={emptyMessageList}>
            Tyhjennä viestit
          </Button>
        </ModalHeader>
        <SocketMessageContainer>
          {messages.length !== 0 ? (
            messages.map((message, index) => {
              return (
                <SocketMessageListItem key={index}>
                  <MessageHeader category={message.category}>
                    <MessageLabel>{message.timeStamp + ' - ' + message.header}</MessageLabel>
                    <IconWrapper
                      onClick={() => {
                        deleteUserMessage(message.id)
                      }}
                    >
                      <Icon size="x-small" icon={'close'} />
                    </IconWrapper>
                  </MessageHeader>

                  {message.type === MsgType.ORDER_CHANGED && (
                    <MessageContainer>
                      <MessageItemWide>
                        <MessageContent>{message.msg}</MessageContent>
                      </MessageItemWide>
                      <MessageItemWide>
                        <MessageContent>
                          {'Tarkasta muuttuneet tiedot tilaukselta siirtymällä työnäkymään'}
                        </MessageContent>
                      </MessageItemWide>
                      {message.orderId && (
                        <MessageButtonItem>
                          <Button
                            category={'new'}
                            maxWidth="100%"
                            size="s"
                            onClick={() => {
                              onConfirm(index)
                            }}
                          >
                            {'Siirry työnäkymään'}
                          </Button>
                        </MessageButtonItem>
                      )}
                    </MessageContainer>
                  )}
                  {message.type === MsgType.RECORD_CHANGED && (
                    <ChangedDataContainer
                      message={message}
                      showOldData={(evt: any) => {
                        toggleOldData(evt, index)
                      }}
                      goToRecord={() => {
                        onConfirm(index)
                      }}
                    ></ChangedDataContainer>
                  )}
                  {(message.type === MsgType.ORDER_CANCELLED || message.type === MsgType.RECORD_CANCELED) && (
                    <MessageContainer>
                      <MessageItemWide>
                        <MessageContent>{message.msg}</MessageContent>
                      </MessageItemWide>
                      <MessageItem>{'Tilauksen ajankohta: '}</MessageItem>
                      <MessageData>{message.data.towingDate + ' ' + message.data.towingTime}</MessageData>
                      <MessageItem>{'Merkki ja malli: '}</MessageItem>
                      <MessageData>{message.data.vehicleMakeAndModel}</MessageData>
                      <MessageItem>{'Mistä: '}</MessageItem>
                      <MessageData>{message.data.from}</MessageData>
                      <MessageItem>{'Mihin: '}</MessageItem>
                      <MessageData>{message.data.to}</MessageData>
                      {message.recordId && (
                        <MessageItemWide>
                          <MessageWarning>Huom! Työ lopetettava työnäkymässä</MessageWarning>
                        </MessageItemWide>
                      )}
                      {message.recordId && (
                        <MessageButtonItem>
                          <Button
                            category={'new'}
                            maxWidth="100%"
                            size="s"
                            onClick={() => {
                              onConfirm(index)
                            }}
                          >
                            {'Siirry työnäkymään'}
                          </Button>
                        </MessageButtonItem>
                      )}
                    </MessageContainer>
                  )}
                </SocketMessageListItem>
              )
            })
          ) : (
            <Text style={{ margin: '4rem 0', fontSize: 'large', fontWeight: 'bold' }}>Ei lukemattomia viestejä</Text>
          )}
        </SocketMessageContainer>
        <ButtonContainer floatAtBottomNoMenu>
          <Button category="save" label="Sulje" size="m" maxWidth="100%" onClick={onClose} />
        </ButtonContainer>
      </ModalContainer>
      {/* <MessagesDragger onClick={showModalWindow} >{messages.length > 0 ? 'Viestit ('+messages.length+')': 'Viestit'}</MessagesDragger> */}
    </>
  )
}

const ChangedDataContainer: React.FunctionComponent<{
  message: RecordChangedMessage
  showOldData: (evt: any) => void
  goToRecord: () => void
}> = ({ message, showOldData, goToRecord }) => {
  return (
    <ChangeDataWrapper>
      {message.data.editedBy && (
        <MessageItemWide>
          <MessageContent>
            {'Käyttäjä ' + message.data.editedBy + ' on muokannut kuormakirjaa seuraavasti:'}
          </MessageContent>
        </MessageItemWide>
      )}
      {message.data.towingTargets && message.data.towingTargets.data.length > 0 && (
        <>
          <MessageItem>{message.data.towingTargets.name + ':'}</MessageItem>
          {message.data.towingTargets.data.map(val => {
            return (
              <ChangedTowingTargets
                key={uuid()}
                showOld={message.showOld}
                header={val.prefix ? val.prefix : ''}
                updated={val.newVal}
                old={val.oldVal}
              ></ChangedTowingTargets>
            )
          })}
        </>
      )}
      {message.data.values && message.data.values.length > 0 && (
        <>
          {message.data.values.map(val => {
            return (
              <ChangedDataRow
                key={uuid()}
                showOld={message.showOld}
                header={val.name ? val.name : ''}
                updated={val.new}
                old={val.old}
              ></ChangedDataRow>
            )
          })}
        </>
      )}
      {!message.showOld && (
        <div
          style={{
            fontWeight: 'bold',
            marginBottom: '1rem',
            textDecoration: 'underline',
            color: '#db7d02',
            cursor: 'pointer',
          }}
        >
          <div onClick={showOldData}>Näytä vanhat tiedot</div>
        </div>
      )}
      {message.showOld && (
        <div
          style={{
            fontWeight: 'bold',
            marginBottom: '1rem',
            textDecoration: 'underline',
            color: '#db7d02',
            cursor: 'pointer',
          }}
        >
          <div onClick={showOldData}>Piilota vanhat tiedot</div>
        </div>
      )}
      {message.recordId && (
        <MessageButtonItem>
          <Button category={'new'} maxWidth="300px" size="s" onClick={goToRecord}>
            {'Siirry työnäkymään'}
          </Button>
        </MessageButtonItem>
      )}
    </ChangeDataWrapper>
  )
}

const ChangedDataRow: React.FunctionComponent<{
  showOld: boolean
  header: string
  updated: string | null
  old: string | null
}> = ({ showOld, header, updated, old }) => {
  return (
    <ItemContainer>
      <MessageItem>{header}</MessageItem>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignContent: 'flex-start' }}>
        <MessageData>{!updated ? '(tyhjä)' : updated}</MessageData>
        <MessageDataSmall show={showOld}>{!old ? '(tyhjä)' : old}</MessageDataSmall>
      </div>
    </ItemContainer>
  )
}
const ChangedTowingTargets: React.FunctionComponent<{
  showOld: boolean
  header: string
  updated: string | null
  old: string | null
}> = ({ showOld, header, updated, old }) => {
  return (
    <ItemContainer>
      <MessageItem style={{ paddingLeft: '0.5rem' }}>{header + ':'}</MessageItem>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignContent: 'flex-start' }}>
        <MessageData>{!updated ? '(tyhjä)' : updated}</MessageData>
        <MessageDataSmall show={showOld}>{!old ? '(tyhjä)' : old}</MessageDataSmall>
      </div>
    </ItemContainer>
  )
}

interface HeaderProps {
  category?: 'alert' | 'warning' | 'info'
}

const MessageContainer = styled.div`
  display: grid;
  grid-template-columns: 150px auto;
  grid-row-gap: 0rem;
  grid-column-gap: 3rem;
  padding: 1rem 2rem;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    grid-template-columns: auto;
  }
`
const MessageHeader = styled.div<HeaderProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 1rem 1rem 1rem;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  ${props =>
    css`
      color: ${props.theme.colors.black};
    `}
  ${props =>
    props.category &&
    css`
      background-color: ${props.category === 'alert'
        ? props.theme.colors.red100
        : props.category === 'warning'
        ? props.theme.colors.yellow100
        : props.theme.colors.blue100};
    `}
`
const ItemContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 150px auto;
  grid-row-gap: 0rem;
  grid-column-gap: 3rem;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    grid-template-columns: auto;
  }
`
const ChangeDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  padding: 1rem 2rem;
`
const MessageItem = styled.div`
  color: ${props => props.theme.colors.black}
  padding-bottom: 0.2rem @media (max-width: ${props => props.theme.screenSize.mobile}) {
    font-weight: bold;
  }
`
const MessageData = styled.div`
  color: ${props => props.theme.colors.black}
  margin-right: 1rem;
  padding-bottom: 0.2rem
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    padding-left: 1rem;
    margin-right: 0;
  }
`

interface OldDataDivProps {
  show: boolean
}

const MessageDataSmall = styled.div<OldDataDivProps>`
  padding-bottom: 0.2rem
  ${props =>
    !props.show &&
    css`
      display: none;
    `}
  color: red;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    padding-left: 1rem;
  }
`
const MessageContent = styled.div`
  color: ${props => props.theme.colors.black}
  padding: 0.2rem 0 1rem 0;
`

const MessageItemWide = styled.div`
  grid-column: span 2;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    grid-column: span 1;
  }
`

const MessageButtonItem = styled.div`
  grid-column: span 2;
  padding-top: 1rem;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    grid-column: span 1;
  }
`

const MessageLabel = styled.div`
  font-size: large;
  font-weight: bold;
  margin-right: 1rem;
`

const MessageWarning = styled.div`
  color: ${props => props.theme.colors.black}
  font-weight: bold;
  padding: 0.8rem 0 0 0;
`

export const SocketMessageContainer = styled.div`
  display: flex;
  background-color: ${props => (props.color ? props.theme.colors[props.color] : props.theme.colors.white)};
  flex-direction: column;
  align-items: center;
`

export const SocketMessageListItem = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 95vw;
  border: solid 1px #d6d6d6;
  border-radius: 6px;
  box-shadow: -2px -2px 10px #d1d1d1;
  margin: 0.5rem;
`
const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
`
