import { EditTowingRecordFormValues } from '../../containers/TowingRecordPage/EditTowingRecord/types';

export function generateUrl(selectedTerminal: { [key: string]: any }) {
  return (
    'wss://' +
    selectedTerminal['userName'] +
    ':' +
    selectedTerminal['password'] +
    selectedTerminal['url'] +
    selectedTerminal['terminalId'] +
    '/jsonpos'
  );
}

export function generateAltUrl(selectedTerminal: { [key: string]: any }) {
  return (
    'wss://' +
    selectedTerminal['url'] +
    selectedTerminal['terminalId'] +
    '/jsonpos'
  );
}

export function getAltUrlData(selectedTerminal: { [key: string]: any }) {
  return [
    'jsonrpc2.0',
    'x-popla-auth-' +
      btoa(
        selectedTerminal['userName'] +
          ':' +
          selectedTerminal['password'],
      )
        .replace(/\//g, '_')
        .replace(/\+/g, '-')
        .replace(/=/g, ''),
  ];
}

export function getWsTransactionData(wsConnection: { [key: string]: any }) {
  var wsTransaction = wsConnection.response['result'];

  return {
    transaction_type: wsTransaction['transaction_type'],
    transaction_id: wsTransaction['transaction_id'],
    transaction_unique_id: wsTransaction['transaction_unique_id'],
    authorization_code: wsTransaction['authorization_code'],
    amount: wsTransaction['amount'],
    reference_number: wsTransaction['reference_number'],
    transaction_time: wsTransaction['transaction_time'],
    card_name: wsTransaction['card_name'],
    pan_masked_for_customer: wsTransaction['pan_masked_for_customer'],
    pan_masked_for_clerk: wsTransaction['pan_masked_for_clerk'],
    card_reading_method: wsTransaction['card_reading_method'],
    terminal_id: wsConnection.selectedTerminal['terminalId'],
    terminal_name: wsConnection.selectedTerminal['terminalName'],
    merchant_receipt: JSON.stringify(wsTransaction['merchant_receipt']),
    customer_receipt: JSON.stringify(wsTransaction['customer_receipt']['text']),
  };
}

export function getTransactionToRefund(values?: EditTowingRecordFormValues) {
  if (!values || !values.billing || !values.billing.paymentTerminalTransactions) return undefined;
  return values.billing.paymentTerminalTransactions.find(function(t) {
    return t.transactionStatus === 'success';
  });
}

export function getTransactionToheck(values?: EditTowingRecordFormValues) {
  if (!values || !values.billing || !values.billing.paymentTerminalTransactions) return undefined;
  return values.billing.paymentTerminalTransactions.find(function(t) {
    return t.transactionStatus === 'pending';
  });
}

export function generateReceiptImg(
  transactionData: { [key: string]: any },
  companyData: { [key: string]: any },
  wsConnection: { [key: string]: any },
) {
  var receiptData = transactionData['customer_receipt'];
  var images = [];
  // set default height and width, will be changed if got terminal specific values
  var maxWidth = 365;
  var maxHeight = 1000;
  var lineheight = 25;
  var font = 'bold 25px Courier New';
  var lines = receiptData.replace(/['"]+/g, '').split(/\r?\n|\\n/);
  lines = mergeWithCompanyData(lines, companyData);

  var splitLines: any[] = [];

  for (let i = 0; i < lines.length; i++) {
    var splitLine = fragmentText(lines[i], maxWidth, font);
    if (splitLine) splitLines.push.apply(splitLines, splitLine);
  }

  lines = splitLines;

  // if refund add empty line and signature line
  if (transactionData['transaction_type'] === 'Refund') {
    var signatureLine = getSignatureLine(maxWidth, font);
    lines.push('');
    lines.push(signatureLine);
  }

  if (wsConnection.terminalInfo['printer']) {
    var printerInfo = wsConnection.terminalInfo['printer'];
    if (printerInfo['max_height']) maxHeight = printerInfo['max_height'];
    if (printerInfo['max_width']) maxWidth = printerInfo['max_width'];
  }

  // set canvas height by lines length + some extra for padding
  var heightByLines = lineheight * (lines.length + 3);
  var img: string | undefined;
  if (heightByLines < maxHeight) {
    img = generateReceiptBase64(lines, heightByLines, maxWidth, lineheight, font);
    images.push(img);
  } else {
    var chunkSize = Math.round(maxHeight / lineheight);
    for (let i = 0; i < lines.length; i += chunkSize) {
      var linesChunk = lines.slice(i, i + chunkSize);
      img = generateReceiptBase64(linesChunk, heightByLines, maxWidth, lineheight, font);
      images.push(img);
    }
  }
  return images;
}

function mergeWithCompanyData(lines: any[], companyData: { [key: string]: any }) {
  var mergeLines: any[] = [];
  //company name and newline
  if ('companyName' in companyData && companyData['companyName'] !== '') {
    mergeLines.push(companyData['companyName']);
    mergeLines.push('');
  }
  //company address
  if ('address' in companyData && companyData['address'] !== '') {
    mergeLines.push(companyData['address']);
  }
  //company zipcode and city if both exist and newline
  if (
    'zipcode' in companyData &&
    companyData['zipcode'] !== '' &&
    'city' in companyData &&
    companyData['city'] !== ''
  ) {
    mergeLines.push(companyData['zipcode'] + ' ' + companyData['city']);
    mergeLines.push('');
  }
  //company email
  if ('email' in companyData && companyData['email'] !== '') {
    mergeLines.push(companyData['email']);
  }
  //company phone
  if ('phone' in companyData && companyData['phone'] !== '') {
    mergeLines.push(companyData['phone']);
    mergeLines.push('');
  }
  mergeLines.push.apply(mergeLines, lines);
  return mergeLines;
}

function fragmentText(text: string, maxWidth: number, font: string) {
  var lineCheckCanvas = document.createElement('canvas');
  var context = lineCheckCanvas.getContext('2d');
  if (!context) return;

  context.font = font;
  var words = text.split(' '),
    lines = [],
    line = '';
  if (context.measureText(text).width < maxWidth) {
    return [text];
  }
  while (words.length > 0) {
    var split = false;
    while (context.measureText(words[0]).width >= maxWidth) {
      var tmp = words[0];
      words[0] = tmp.slice(0, -1);
      if (!split) {
        split = true;
        words.splice(1, 0, tmp.slice(-1));
      } else {
        words[1] = tmp.slice(-1) + words[1];
      }
    }
    if (context.measureText(line + words[0]).width < maxWidth) {
      line += words.shift() + ' ';
    } else {
      lines.push(line);
      line = '';
    }
    if (words.length === 0) {
      lines.push(line);
    }
  }

  return lines;
}

function getSignatureLine(maxWidth: number, font: string) {
  var lineCheckCanvas = document.createElement('canvas');
  var context = lineCheckCanvas.getContext('2d');
  if (!context) return;
  context.font = font;
  var line = '_';
  while (context.measureText(line).width <= maxWidth) {
    line = line + line;
  }
  return line;
}

function generateReceiptBase64(
  data: any[],
  height: number,
  width: number,
  lineheight: number,
  font: string,
) {
  var canvas = document.createElement('canvas');
  canvas.height = height;
  canvas.width = width;
  var ctx = canvas.getContext('2d');
  if (!ctx) return;
  // set canvas background as white
  ctx.fillStyle = '#ffffff';
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  ctx.font = font;
  var x = 10;
  var y = 50;
  // set font color to black
  ctx.fillStyle = 'black';
  // loop throught receipt lines and them to canvas
  for (var i = 0; i < data.length; i++) ctx.fillText(data[i], x, y + i * lineheight);

  // set canvas base64 blob as image to return
  return canvas.toDataURL('image/png').split(';base64,')[1];
}
