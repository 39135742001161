import React, { useCallback, useState } from 'react'
import { Coords, Time } from '../../../../../../common/types'
import { ButtonCategory } from '../../../../../../components/Button'
import GetGeolocation from '../../../../../../components/Geolocation'
import { RouteColumn, RouteRow, RouteWrapper } from '../../../../../../components/layout/'
import { TextInput } from '../../../../../../components/layout/form/input'
import { LocationInputMap, toMapData } from '../../../../../../components/layout/form/input/LocationInputMap'
import { CustomButton, StationButtonGroup } from '../../../../../../components/StationButtonGroup'

import { GeoLocateAddressData } from '../../types'
import { RouteHeader } from '../RouteHeader'
import { ArrivalRouteArgs, ArrivalAddress, EditStartRouteFormValues } from '../StartRoute/types'
import { AutoliittoSeparatorLine } from '../styled'
import { useApolloClient } from 'react-apollo'
import { setErrorNotification } from '../../../../../../components/notification'
import { GeolocationType, Geolocation } from '../../../../../../components/Geolocation/types'
import { ArrivalDateTimeInput, DateTimeInput } from '../../../../../../components/layout/form/input/DateTimeInput'
import { format } from 'date-fns'
import { RouteNotes } from '../../RouteNotes'
import { BrowserLocationResponse, StyledButton } from '../../../../../../components/ReverseGeocodeButton'
import { REVERSE_GEOCODE } from '../../../../../../components/ReverseGeocodeButton/queries'
import { DIRECTIONS } from '../../../../../../components/layout/form/field/TimeField/queries'
import Maybe from 'graphql/tsutils/Maybe'
import { geolocationOptions } from '../../../../../GeolocationTracking/types'
import styled from 'styled-components'
import { RouteColumnAlignRight } from '../../../../../../components/layout/routesmenu'
import { Station } from '../../../../../Parameters/types'

export interface LocationOption {
  value: string
  label: string
}
export const startLocationOther = 'OTHER'

interface StartRouteProps {
  onCancel: () => void
  regNo: string
  isAutoliitto: boolean
  isSos: boolean
  useExtendedRoutes: boolean
  setAutoliittoDateField: (fieldName: string) => void
  setRouteLocation: (addressData: GeoLocateAddressData | null) => void
  updateRouteDate?: () => void
  //showCustomAddressInitially: boolean
  initialValues: EditStartRouteFormValues
  clear: () => void
  setGeolocatedField: (geolocated: boolean) => void
  setLoading: (loading: boolean) => void
  reportPosition: (location: Geolocation, name: string) => void
  getValue: any
  setValue: (field: string, value: any) => void
  stationId: Maybe<number>
  stations: Station[]
}

export const StartRoute: React.FunctionComponent<StartRouteProps> = ({
  onCancel,
  isAutoliitto,
  regNo,
  setAutoliittoDateField,
  setRouteLocation,
  initialValues,
  clear,
  setGeolocatedField,
  setLoading,
  isSos,
  reportPosition,
  getValue,
  setValue,
  stationId,
  stations,
}) => {
  const selectedBtnCategory: ButtonCategory = 'yellow'
  const unSelectedBtnCategory: ButtonCategory = 'new'

  const routeAddress = getStartRouteAddress(initialValues)
  const showStations = stations.length > 0
  const selectedStation = stations.find(station => `${station.address}, ${station.city}` === routeAddress) || null
  const selection = getInitialStationSelection(stations, selectedStation, routeAddress)

  const selectOption = (selectedOption: string) => {
    setCleared(false)
    setSelectedOption(selectedOption)
  }

  const [showCustomAddress, setShowCustomAddress] = useState<boolean>(routeAddress !== '')
  const [selectedOption, setSelectedOption] = useState<string>(selection)
  
  const [selectedStationId, setStation] = useState<number | null>(() => {
    return selectedStation ? selectedStation.id : null
  })
  const [cleared, setCleared] = useState<boolean>(false)

  const client = useApolloClient()

  const destination = arrivalRouteToQueryVariable({
    address: getValue(`arrivalRoute.address`) as string,
    city: getValue(`arrivalRoute.city`) as string,
    coords: getValue(`arrivalRoute.coords`) as Coords,
  })

  const { getPosition, loading } = useEstimateArrivalTime(destination, {
    onSuccess: eta => {
      setValue('automobileAndTouringClubFinland.estimatedTimeOfArrival.time', eta)
    },
    onError: error => {
      console.log(error)
    },
  })

  return (
    <RouteWrapper>
      <RouteHeader label="Lähtöpaikka" regNo={regNo} cancel={onCancel} />
      <>
        <AutoliittoSeparatorLine marginTop="1.5rem" marginBottom="1.5rem" />
        <RouteRow columns={showStations ? '1fr 1fr 1fr' : '1fr 1fr'}>
          {showStations && (
            <RouteColumn>
              <CustomButton
                label="Asemapaikka"
                category={selectedOption && selectedOption === 'station' ? selectedBtnCategory : unSelectedBtnCategory}
                onClick={() => {
                  selectOption('station')
                  setGeolocatedField(false)
                }}
                size="ml"
                maxWidth="100%"
              />
            </RouteColumn>
          )}
          <RouteColumn>
            <CustomButton
              label="Muu"
              category={selectedOption && selectedOption === 'manual' ? selectedBtnCategory : unSelectedBtnCategory}
              onClick={() => {
                setShowCustomAddress(true)
                selectOption('manual')
                setGeolocatedField(false)
              }}
              size="ml"
              maxWidth="100%"
            />
          </RouteColumn>
        </RouteRow>
      </>

      <AutoliittoSeparatorLine marginTop="1.5rem" marginBottom="1.5rem" />
      <RouteRow>
        <RouteColumn>
          {showCustomAddress && selectedOption !== 'station' && (
            <RouteColumn>
              <LocationInputMap
                addressLabel={`Osoite`}
                addressName={`startRoute.address`}
                cityLabel={`Kaupunki`}
                cityName={`startRoute.city`}
                coordsName={`startRoute.coords`}
                zipcodeName={`startRoute.zipcode`}
                required={isSos}
                coordsRequired={isSos}
                getValue={getValue}
                values={toMapData(
                  getValue(`startRoute.address`),
                  getValue(`startRoute.city`),
                  getValue(`startRoute.coords`),
                  getValue(`startRoute.zipcode`)
                )}
                setValue={setValue}
                setRouteLocation={setRouteLocation}
                stationId={stationId as number} // Fix
                clear={() => {
                  clear()
                  setStation(null)
                }}
              />
            </RouteColumn>
          )}
          {selectedOption === 'station' && (
            <StationButtonGroup
              stations={stations}
              label="Kuormakirjan lähtöpaikka"
              setRouteLocation={setRouteLocation}
              stationId={selectedStationId}
              setStation={stationId => {
                setStation(stationId)
              }}
              cleared={cleared}
              setLoading={setLoading}
              required={isSos}
            />
          )}
        </RouteColumn>
        <RouteColumn>
          <ArrivalDateTimeInput
            label="Aika"
            name="startRoute"
            required={isAutoliitto}
            customOnChange={() => {
              const value = (format(new Date(), 'HH:mm') as unknown) as Time
              setAutoliittoDateField('startRoute.arrivalDate')
              setValue('startRoute.arrivalTime', value)
            }}
            showCurrentTimeBtn
          />
        </RouteColumn>
      </RouteRow>

      {isAutoliitto && (
        <>
          <AutoliittoSeparatorLine marginTop="1.5rem" marginBottom="1.5rem" />
          <RouteRow>
            <RouteColumn>
              <TextInput disabled label="Kohdeosoite" name={`arrivalRoute.address`} />
            </RouteColumn>
            <RouteColumn>
              <DateTimeInput
                label="Arvioitu saapumisaika"
                name={'automobileAndTouringClubFinland.estimatedTimeOfArrival'}
                customOnChange={() => {
                  const value = (format(new Date(), 'HH:mm') as unknown) as Time
                  setAutoliittoDateField('automobileAndTouringClubFinland.estimatedTimeOfArrival.date')
                  setValue('automobileAndTouringClubFinland.estimatedTimeOfArrival.time', value)
                }}
                required={isAutoliitto}
                showCurrentTimeBtn
              />
            </RouteColumn>
          </RouteRow>
          <RouteRow>
            <RouteColumn />
            <RouteColumnAlignRight>
              <CalculateButton
                label="Laske"
                category="info"
                loading={loading}
                onClick={() => getPosition()}
                fontWeight="bold"
                maxWidth="50%"
                size="s"
              />
            </RouteColumnAlignRight>
          </RouteRow>
        </>
      )}

      {isSos && (
        <>
          <GetGeolocation
            client={client}
            setError={setErrorNotification}
            type={GeolocationType.reverseGeocode}
            reportGeolocation={reportPosition}
            fieldName={'sosServiceOrder.currentLocation'}
          />
          <AutoliittoSeparatorLine marginTop="1.5rem" marginBottom="1.5rem" />
          <RouteRow>
            <RouteColumn>
              <LocationInputMap
                addressLabel={'Yksikön sijainti'}
                addressName={`sosServiceOrder.currentLocation.address`}
                cityLabel={`Kaupunki`}
                cityName={`sosServiceOrder.currentLocation.city`}
                coordsName={`sosServiceOrder.currentLocation.coords`}
                zipcodeName={`sosServiceOrder.currentLocation.zipcode`}
                required={isSos}
                coordsRequired={isSos}
                getValue={getValue}
                values={toMapData(
                  getValue(`sosServiceOrder.currentLocation.address`),
                  getValue(`sosServiceOrder.currentLocation.city`),
                  getValue(`sosServiceOrder.currentLocation.coords`),
                  getValue(`sosServiceOrder.currentLocation.zipcode`)
                )}
                setValue={setValue}
                stationId={stationId as number}
                clear={clear}
              />
            </RouteColumn>
            <RouteColumn>
              <DateTimeInput
                label="Aloitusaika"
                name="sosServiceOrder.jobStarted"
                required={isSos}
                customOnChange={() => {
                  const value = (format(new Date(), 'HH:mm') as unknown) as Time
                  setAutoliittoDateField('sosServiceOrder.jobStarted.date')
                  setValue('sosServiceOrder.jobStarted.time', value)
                }}
                showCurrentTimeBtn
              />
            </RouteColumn>
          </RouteRow>
          <RouteRow></RouteRow>
        </>
      )}
      <RouteNotes removeLine />
    </RouteWrapper>
  )
}

const CalculateButton = styled(StyledButton)``

export function arrivalRouteToQueryVariable(arrivalRoute: Maybe<ArrivalAddress>): ArrivalRouteArgs {
  if (!arrivalRoute) {
    return {
      address: '',
      city: '',
      lat: null,
      long: null,
    }
  }

  const coords = arrivalRoute.coords ? arrivalRoute.coords : { lat: null, long: null }

  return {
    address: arrivalRoute.address,
    city: arrivalRoute.city ? arrivalRoute.city : '',
    lat: coords.lat,
    long: coords.long,
  }
}

interface Callback {
  onSuccess?: (value: string) => void
  onError?: (value: string) => void
}

const useEstimateArrivalTime = (arrivalRoute: ArrivalRouteArgs, { onSuccess, onError }: Callback) => {
  const [loading, setLoading] = useState(false)
  const client = useApolloClient()

  const getPosition = async () => {
    setLoading(true)
    window.navigator.geolocation.getCurrentPosition(
      async (position: { coords: { latitude: number; longitude: number } }) => {
        const { data }: BrowserLocationResponse = await client.query({
          variables: { location: { lat: position.coords.latitude, long: position.coords.longitude } },
          query: REVERSE_GEOCODE,
        })

        if (data.reverseGeocode.__typename === 'GoogleAPIGeolocationError') {
          console.error('getCurrentPosition returned with error')
          setLoading(false)
          onError && onError('getCurrentPosition returned with error')
        } else {
          const currentPosition = {
            address: data.reverseGeocode.result.address ? data.reverseGeocode.result.address : '',
            city: data.reverseGeocode.result.city ? data.reverseGeocode.result.city : '',
            lat: position.coords.latitude ? position.coords.latitude : null,
            long: position.coords.longitude ? position.coords.longitude : null,
          }
          const waypoints = {
            data: [currentPosition, arrivalRoute],
          }
          if (!currentPosition || !arrivalRoute) {
            setLoading(false)
            onError && onError('No current position or arrival route')
            return
          }
          getRouteTimeEstimate(waypoints)
        }
      },
      error => {
        console.error('TimeField::getPosition failed: ', error)
        setLoading(false)
        onError && onError(`TimeField::getPosition failed: ${JSON.stringify(error)}`)
      },
      geolocationOptions
    )
  }

  const getRouteTimeEstimate = useCallback(
    async (waypoints: any) => {
      const { data }: any = await client.query({
        variables: { waypoints },
        query: DIRECTIONS,
      })

      if (data.directions.__typename === 'GoogleAPIGeolocationError') {
        console.log('Route calculation returned an error')
        onError && onError('Route calculation returned an error')
      }

      if (
        data &&
        data.directions &&
        data.directions.result &&
        data.directions.result.routes[0].legs[0].duration.value
      ) {
        const seconds = data.directions.result.routes[0].legs[0].duration.value
        const estimatedTimeSeconds = new Date().getSeconds() + seconds
        const estimatedTime = new Date().setSeconds(estimatedTimeSeconds)
        const eta = new Date(estimatedTime)
          .toLocaleTimeString('fi')
          .slice(0, -3)
          .replace('.', ':')

        onSuccess && onSuccess(eta)
      }
      setLoading(false)
    },
    [client, onSuccess, onError]
  )
  return { getPosition, loading }
}

function getInitialStationSelection(stations: Station[], selectedStation: Maybe<Station>, routeAddress: string) {
  const selection = stations.length > 0 ? 'station' : 'manual'

  if (selectedStation) {
    return 'station'
  }

  if (!selectedStation && routeAddress) {
    return 'manual'
  }

  return selection
}

function getStartRouteAddress({ startRoute }: EditStartRouteFormValues) {
  if (!startRoute.address || !startRoute.city) return ''

  return `${startRoute.address}, ${startRoute.city}`
}
