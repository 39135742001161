import { Coords } from '../../common/types'
// import { GeolocationPosition } from '../../containers/StoreProvider/ActiveJobsGeolocation'

// export interface GeolocationWithAddress {
//   geolocation: GeolocationPosition
//   address: string
//   city: string
// }

export interface Geolocation {
  coords: Coords
  address: string
  city: string
  zipcode: string
}

export enum GeolocationType {
  'geolocation' = 'geolocation',
  'reverseGeocode' = 'reverseGeocode',
  'geocode' = 'geocode',
}

export interface GetAddressResponse {
  data: {
    reverseGeocode: ReverseGeocode
  }
}

interface ReverseGeocode {
  result: ReverseGeocodeResult
  __typename: string
}

export interface ReverseGeocodeResult {
  address: string
  city: string
  zipcode: string
  __typename: string
}
