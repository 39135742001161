import gql from 'graphql-tag'

export const GEOLOCATION_AUTOSUGGEST_ADDRESS = gql`
  query AutocompleteAddress($search: String!, $sessionToken: String!) {
    autocompleteAddress(search: $search, sessionToken: $sessionToken) {
      __typename
      ... on GetAutocompleteAddressSuccess {
        predictions {
          placeId
          main
          secondary
        }
      }
    }
  }
`

export const GEOLOCATION_AUTOSUGGEST_ADDRESS_DETAILS = gql`
  query AutocompleteAddressDetails($placeId: String!, $sessionToken: String!, $includeName: Boolean!) {
    autocompleteAddressDetails(placeId: $placeId, sessionToken: $sessionToken, includeName: $includeName) {
      __typename
      ... on GetAutocompleteAddressDetailsSuccess {
        details {
          address
          city
          zipcode
          name
          location {
            lat
            long
          }
        }
      }
    }
  }
`
