import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
html, body {
    height: 100%;
    width: 100%;
    overflow: auto;
}


  body {
    font-size: 16px;
    background-color: #f7f7f7;
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;

    * {
      box-sizing: border-box;
      -webkit-tap-highlight-color: transparent;
    }

  }
 
`
// .react-date-picker--disabled {
//   background-color: #e2e4e6!important;
// }