import React from 'react'
import styled from 'styled-components'
import { Maybe } from '../../../common/types'
import { Icon } from '../../icons/Icon'

interface WrapperProps {
  onClick?: () => void
}

const Wrapper = styled.div<WrapperProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  /* width: 2rem;
  height: 2rem;
  background-color: red;
  border: 2px solid #fff;
  border-radius: 100%; */
  user-select: none;
  transform: translate(-50%, -50%);
  cursor: 'pointer';
  &:hover {
    z-index: 1;
  }
`

const MarkerLabel = styled.div`
  position: absolute;
  top: calc(50% - 3.5rem);
  /* left: 50%; */
  display: inline-block;
  font-size: 1.5rem;
  width: max-content;
  opacity: 0.9;
  background-color: white;
  border-radius: 0.25rem;
  padding: 0.25rem;
`

interface MarkerProps {
  onClick?: () => void
  label?: Maybe<string>
  lat: number
  lng: number
}

export const Marker: React.FunctionComponent<MarkerProps> = ({ label }) => {
  // const [showLabel, setShowLabel] = useState<boolean>(false)
  // const handleClick = useCallback(() => {
  //   setShowLabel(!showLabel)
  // }, [showLabel, setShowLabel])
  return (
    <>
      {label && <MarkerLabel>{label}</MarkerLabel>}
      <Wrapper>
        <Icon icon="map-marker" color="red800" size="medium" />
      </Wrapper>
    </>
  )
}
