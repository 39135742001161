import gql from 'graphql-tag'
import { UnauthorizedError } from '../../../../../../errorTypes'
import { towingRecordFragment } from '../../../../../ViewTowingRecord/TowingRecordQuery'
import { TowingRecord } from '../../../../../ViewTowingRecord/types'
import { EndRouteInputMutation, UpdateEndedRouteInputMutation } from './types'

export interface EndRouteMutationVariables
  extends Readonly<{
    input: EndRouteInputMutation
  }> {}

export interface EditEndRouteResponse {
  editEndRoute: EndRouteOutput
}

type EndRouteOutput = EndRouteSuccess | UnauthorizedError

interface EndRouteSuccess
  extends Readonly<{
    __typename: 'EditTowingRecordSuccess'
    towingRecord: TowingRecord
  }> {}

export interface UpdateEndedRouteMutationVariables
  extends Readonly<{
    input: UpdateEndedRouteInputMutation
  }> {}


export const EDIT_END_ROUTE_MUTATION = gql`
  mutation EditEndRoute($input: EditEndRouteInput!) {
    editEndRoute(input: $input) {
      __typename
      ... on UnauthorizedError {
        error {
          code
          message
        }
      }
      ... on GenericError {
        genericError: error {
          code
          message
        }
      }
      ... on EditTowingRecordSuccess {
        towingRecord {
          ...TowingRecordFields
        }
      }
    }
  }
  ${towingRecordFragment.success}
`
