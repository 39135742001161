import React, { FunctionComponent } from 'react'
import Loader from 'react-loader-spinner'
import styled from 'styled-components'

interface Props {
  loading?: boolean
}

export const Loading: FunctionComponent<Props> = ({ loading = true }) => {
  if (!loading) {
    return null
  }
  return (
    <Container>
      <Loader type="RevolvingDot" color="#fabf40" width={100} height={100} />
    </Container>
  )
}

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
`

export const CenteredLoader: FunctionComponent<Props> = ({ loading = true }) => {
  if (!loading) {
    return null;
  }
  return (
    <Container>
      <Loading loading={loading}/>
    </Container>
  )
}
