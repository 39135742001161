import gql from 'graphql-tag'

export const RENEW_TOKEN_QUERY = gql`
  query RenewToken {
    renewToken {
      ... on RenewSuccess {
        __typename
        token
      }
      ... on UnauthorizedError {
        error {
          code
          message
        }
      }
    }
  }
`
