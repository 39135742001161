import gql from 'graphql-tag'
import { UnauthorizedError } from '../../errorTypes'
import { CurrentWorkShift } from '../types'

export const ADD_EVENT_MUTATION = gql`
  mutation AddEvent($input: AddEventInput!) {
    addEvent(input: $input) {
      __typename
      ... on UnauthorizedError {
        error {
          code
          message
        }
      }
      ... on GenericError {
        genericError: error {
          code
          message
        }
      }
      ... on AddEventSuccess {
        currentShift {
          shift {
            id
            userId
            companyId
            comment
            shiftStart
            shiftEnd
          }
          events {
            id
            userId
            companyId
            shiftId
            type
            timestamp
          }
        }
      }
    }
  }
`

export interface AddEventResponse {
  addEvent: AddEventOutput
}

type AddEventOutput = AddEventSuccess | UnauthorizedError

interface AddEventSuccess
  extends Readonly<{
    __typename: 'AddEventSuccess'
    currentShift: CurrentWorkShift
  }> {}
