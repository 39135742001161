import { Maybe, Coords } from '../../../../../common/types'

export enum LocationInputMapView {
  MAP = 'map',
  INPUT = 'input',
}

export interface LocationInputMapFormValues {
  address: Maybe<string>
  city: Maybe<string>
  zipcode: Maybe<string>
  coords: Coords
  description?: Maybe<string>
}
