import { JobFilters } from '../../containers/Parameters/types'
import { Maybe } from '../../common/types'
import { OpenTowingJobsParams } from './types'

export function getJobViewFilters(filters: Maybe<JobFilters>): OpenTowingJobsParams {
  const parameters: OpenTowingJobsParams = { stations: [], labels: [], visibility: [], operators: null }
  if (!filters) {
    return parameters
  }

  const stationsEnabled = filters.stations.find(s => {
    return !s.include
  })

  if (typeof stationsEnabled !== 'undefined') {
    filters.stations.forEach(s => {
      if (s.include) {
        parameters.stations.push(s.id)
      }
    })
  }

  const labelsEnabled = filters.jobTypes.find(j => {
    return !j.include
  })

  if (typeof labelsEnabled !== 'undefined') {
    filters.jobTypes.forEach(j => {
      if (j.include) {
        parameters.labels.push(j.name)
      }
    })
  }

  if (filters.visibility && parameters.visibility) {
    const { open, own, others } = filters.visibility
    if (open) parameters.visibility.push('open')
    if (own) parameters.visibility.push('own')
    if (others) parameters.visibility.push('others')
  }

  // if (filters.visibility === VisibilityFilter.OWN && filters.operators) {
  //   parameters.operators = filters.operators
  // }

  return parameters
}
