import { defineMessages } from 'react-intl'

export const jobFilterMessages = defineMessages({
  save_title_success: {
    id: 'settings.job_filters.title.success',
    defaultMessage: 'Success',
  },
  save_title_error: {
    id: 'settings.job_filters.title.error',
    defaultMessage: 'Error',
  },
  save_message_success: {
    id: 'settings.job_filters.message.success',
    defaultMessage: 'Success',
  },
  save_message_error: {
    id: 'settings.job_filters.message.error',
    defaultMessage: 'Error',
  },
})
