import React, { FunctionComponent } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { NotificationDelay } from '../../common/types';
import { Icon } from '../icons/Icon';
import { Container, Content, IconWrapper, Message, Title } from './styles';
import { dismissToast } from '.';

export const setSuccessNotification = (title: string, message: string) => {
  if (!toast.isActive(title)) {
    toast.success(<SuccessNotification title={title} message={message} />, {
      autoClose: false,
      closeButton: false,
      delay: 0,
      toastId: title,
    });
    dismissToast(title, NotificationDelay.successNotificationDelay);
  }
};

interface SuccessNotificationProps {
  title: string;
  message: string;
}

export const SuccessNotification: FunctionComponent<SuccessNotificationProps> = ({
  title,
  message,
}) => {
  return (
    <SuccessContainer>
      <IconWrapper>
        <Icon size="big" icon={'success'} color="green900" />
      </IconWrapper>
      <Content>
        <Title>{title}</Title>
        <Message>{message}</Message>
      </Content>
    </SuccessContainer>
  );
};

const SuccessContainer = styled(Container)`
  padding: 1em 4em 1em 4em;
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.theme.colors.green100};
  color: ${(props) => props.theme.colors.green900};
  border-bottom: 1px solid ${(props) => props.theme.colors.green500};
  @media (max-width: ${(props) => props.theme.screenSize.mobile}) {
    padding: 0;
  }
`;
