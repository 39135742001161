import gql from 'graphql-tag'
import { TowingOrderStatus } from '../../common/types'

export const TOWING_JOBS_OPEN_QUERY = gql`
  query TowingOrders {
    towingOrders(status: OPEN) {
      __typename
      ... on GetTowingOrdersSuccess {
        towingOrders {
          status
          operatorId
        }
      }
    }
  }
`

export interface TowingJobsResponse {
  towingOrders: TowingOrdersResult
}
export interface OpenTowingJob {
  status: TowingOrderStatus
  operatorId: number
}

interface TowingOrdersResult
  extends Readonly<{
    __typename: string
    towingOrders: OpenTowingJob[]
  }> {}

export const PING_QUERY = gql`
  query Ping {
    ping {
      __typename
      ... on PingSuccess {
        ping
      }
    }
  }
`

interface PingResult
  extends Readonly<{
    __typename: string
    ping: boolean
  }> {}

export interface PingResponse {
  ping: PingResult
}
