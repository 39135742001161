import { useEffect, useContext } from 'react'
// import io from 'socket.io-client'
import { SocketContext } from '../../containers/SocketProvider'


export const useSocket = () => {
  const socket = useContext(SocketContext)
  
  useEffect(() => {
    
    if (!socket) {
      return
    }
   
  }, [socket])
  return socket
  // return { socket, setSocket }
}
