import React from 'react'
import { FormSubGroup, FormSubGroupHeading, ImageWrapper, Section, SectionColumn} from '../../../components/layout'
import { SelectInput, TextAreaInput, TextInput } from '../../../components/layout/form/input'
import {
  DateSectionColumn,
  HalfDateInput,
  HalfTimeInput,
} from '../../../containers/TowingOrderPage/EditTowingOrder/TowingOrderDetailsSubFormContents'
import sos from '../../../images/sos.gif'
import { sosVariables } from '../../SosSubForm/types'

import { SosFormSubGroupHeader, SosFormSubGroupWrapper } from '../../SosSubForm/TowingOrderForm/EditTowingOrder'
import { useSosCauseOptions } from '../../../util/useSosCauseOptions'

interface FormContentsProps {
  getValue: any
  setValue: any
  isTowingRecord?: boolean
}

export const LahitapiolaSosOrderDetailsForm: React.FunctionComponent<FormContentsProps> = ({
  getValue,
  setValue,
  isTowingRecord,
}) => {
  const deliverable = getValue('sosServiceOrder.deliverable')

  const { causeOptions, onDeliverableChange } = useSosCauseOptions(deliverable, setValue)
 
  return (
    <SosFormSubGroupWrapper>
      <SosFormSubGroupHeader>
        <FormSubGroupHeading>
          <ImageWrapper>
            <img src={sos} alt="Lähitapiola" width={`95px`} />
          </ImageWrapper>
        </FormSubGroupHeading>
      </SosFormSubGroupHeader>
      <FormSubGroup>
        <Section columns={2} marginMobile="0">
          <DateSectionColumn>
            <HalfDateInput label={'Päivämäärä'} name="jobDetails.towingDate" required disabled={isTowingRecord} />
            <HalfTimeInput label={'Aika'} name="jobDetails.towingTime" disabled={isTowingRecord} />
          </DateSectionColumn>
          <SectionColumn />
        </Section>
        <Section columns={2}>
          <SectionColumn>
            <SelectInput
              label="Toimenpide"
              name="sosServiceOrder.deliverable"
              options={sosVariables.deliverable}
              onChange={value => onDeliverableChange(value)}
              disabled={isTowingRecord}
              required
            />
          </SectionColumn>
          <SectionColumn>
            <SelectInput
              label="Vika"
              name="sosServiceOrder.cause"
              options={causeOptions}
              disabled={isTowingRecord}
              required
            />
          </SectionColumn>
        </Section>
        <Section columns={2}>
          <SectionColumn>
            <TextInput label={'Viite'} name="automobileAndTouringClubFinland.caseNumber" disabled />
          </SectionColumn>
          <SectionColumn>
            <TextInput label="Laskutus" name="automobileAndTouringClubFinland.billingInfo" disabled />
          </SectionColumn>
        </Section>
        <Section columns={2}>
          <SectionColumn>
            <TextAreaInput label={'Lisäselvitykset'} name="jobDetails.additionalInfo" />
          </SectionColumn>
          <SectionColumn>
            <TextAreaInput label={'Muistiinpanot'} name="jobDetails.notes" />
          </SectionColumn>
        </Section>
        {/* <Section columns={2}>
          <SectionColumn>
            <TextAreaInput label="Tilannearvio" name="automobileAndTouringClubFinland.situationDescription" disabled />
          </SectionColumn>
          <SectionColumn />
        </Section> */}
      </FormSubGroup>
    </SosFormSubGroupWrapper>
  )
}
