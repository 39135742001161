import React from 'react'
import { FieldArray } from 'formik'
import { CheckboxInput } from '../layout/form/input'
import { AgreementInput } from './types'

interface Props {
  agreements: AgreementInput[] | null
}

export const Agreements: React.FC<Props> = ({ agreements }) => {
  if (!agreements || agreements.length < 1) return null
  return (
    <FieldArray
      name="signature.agreements"
      render={() =>
        agreements.map((agreement, index) => (
          <CheckboxInput key={index} label={agreement.text} name={`signature.agreements.${index}.isAgreed`} />
        ))
      }
    />
  )
}
