import { trackedSosStatuses, trackedStatuses } from '../../components/TrackedJobs/types'
import { TowingRecord } from '../../containers/TowingRecordPage/ViewTowingRecord/types'
import { Maybe, TowingRecordJobStatus, TowingRecordStatus, TowingRecordType, User, UserRole } from '../types'
import { ACSErrpr, ErrorEntity, TowingRecordActions, TowingRecordEvent } from './types'
import { shouldDisableFinishRoute } from '../../containers/TowingRecordPage/components/routes/RoutesMenu'

const initialActions: TowingRecordActions = { editRoutes: false, useJobButtons: false, trackLocation: false }

function canEditRoutes(towingRecord: TowingRecord) {
  return towingRecord.status === TowingRecordStatus.unfinished
}

export const getTowingRecordActions = (user: Maybe<User>, towingRecord: TowingRecord): TowingRecordActions => {
  const actions: TowingRecordActions = initialActions

  if (!user) {
    return actions
  }

  const recordActive = trackedStatuses.includes(towingRecord.jobStatus)
  const isAl = towingRecord.automobileAndTouringClubFinland !== null
  const isSos = towingRecord.sosServiceOrder !== null

  if (isSos && trackedSosStatuses.includes(towingRecord.jobStatus)) {
    actions.trackLocation = true
  }

  if (isAl && recordActive) {
    actions.trackLocation = true
  }

  if (!towingRecord.operatorId) {
    actions.editRoutes = canEditRoutes(towingRecord)
    actions.useJobButtons = recordActive
    // actions.trackLocation = recordActive && isAl
    return actions
  }

  if (towingRecord.operatorId === user.userId) {
    actions.editRoutes = canEditRoutes(towingRecord)
    actions.useJobButtons = recordActive
    // actions.trackLocation = recordActive && isAl
    return actions
  }

  if (user.role === UserRole.main_user || user.role === UserRole.duty_officer) {
    actions.editRoutes = false
    actions.useJobButtons = false
    // actions.trackLocation = false && isAl
    return actions
  }

  return actions
}

// export const canEditTowingRecord = (towingRecord: TowingRecord, ) => {

// }

export class ACS {
  private user: Maybe<User>
  private towingRecord: Maybe<TowingRecord>
  private error: Maybe<ACSErrpr>
  private useExtendedRoutes: boolean
  public constructor(user: Maybe<User>) {
    this.user = user
    this.towingRecord = null
    this.error = null
    this.useExtendedRoutes = false
  }

  public setTowingRecord(tr: TowingRecord) {
    this.towingRecord = tr
    return this
  }

  public setExtendedRoutes(value: boolean) {
    this.useExtendedRoutes = value
    return this
  }

  public setError(message: string, entity: ErrorEntity = 'TowingRecord') {
    this.error = { message, entity }
    return false
  }

  public getLatestErrorMessage() {
    if (!this.error) {
      return ''
    }

    const message = this.error.message
    this.error = null

    return message
  }

  public canTowingRecord(event: TowingRecordEvent) {
    if (!this.user || !this.towingRecord) {
      return false
    }
  
    if (this.towingRecord.type !== TowingRecordType.sos && this.useExtendedRoutes === false) {
      return true
    }
    
    const { jobStatus } = this.towingRecord
    
    switch (event) {
      case 'editArrivalRoute':
      case 'editArrivedAtBreakdownLocation':
        if (jobStatus === TowingRecordJobStatus.pending) {
          return this.setError('Täytä lähtöpaikka ennen kohdetta')
        }
        return true
      case 'editFinishedAtBreakdownLocation':
        if (jobStatus === TowingRecordJobStatus.pending) {
          return this.setError('Täytä lähtöpaikka ennen kohdetta')
        }
        if (jobStatus === TowingRecordJobStatus.started) {
          return this.setError('Valmis kohteessa ei voida tallentaa nykyisessä tilassa')
        }
        return true
      case 'editFinishRoute':
      case 'editArrivedAtDestination':
        if (jobStatus === TowingRecordJobStatus.pending) {
          return this.setError('Täytä lähtöpaikka ennen määränpäätä')
        }
        if (jobStatus === TowingRecordJobStatus.started) {
          return this.setError('Täytä kohde ennen määränpäätä')
        }
        if (jobStatus === TowingRecordJobStatus.arrived_at_breakdown_location) {
          return this.setError('Täytä valmis kohteessa ennen määränpäätä')
        }
        return true
      case 'editFinishedAtDestination':
       
        if (jobStatus === TowingRecordJobStatus.pending) {
          return this.setError('Täytä lähtöpaikka ennen määränpäätä')
        }
        if (jobStatus === TowingRecordJobStatus.started) {
          return this.setError('Täytä lähtöpaikka ennen määränpäätä')
        }
        if (jobStatus === TowingRecordJobStatus.arrived_at_breakdown_location) {
          return this.setError('Täytä valmis kohteessa ennen määränpäätä')
        }
        if (jobStatus === TowingRecordJobStatus.finished_at_breakdown_location) {
          return this.setError('Täytä saapunut määränpäähän ensin')
        }
        return true
      case 'finalize':
        const isDisabledFinishRoute = this.towingRecord.sosServiceOrder && this.towingRecord.sosServiceOrder.deliverable && shouldDisableFinishRoute(this.towingRecord.sosServiceOrder.deliverable);
        // Add here case when towing reason is hinaus and finished_at_breakdown_location
        if (jobStatus === TowingRecordJobStatus.pending) {
          return this.setError('Työtä ei voida viimeistellä nykyisessä tilassa')
        }
        if (jobStatus === TowingRecordJobStatus.started) {
          return this.setError('Työtä ei voida viimeistellä nykyisessä tilassa')
        }
        if (jobStatus === TowingRecordJobStatus.arrived_at_breakdown_location) {
          return this.setError('Työtä ei voida viimeistellä nykyisessä tilassa')
        }
        if (jobStatus === TowingRecordJobStatus.finished_at_breakdown_location && !isDisabledFinishRoute){
          return this.setError('Työtä ei voida viimeistellä nykyisessä tilassa')
        }
        if (jobStatus === TowingRecordJobStatus.arrived_at_destination && !isDisabledFinishRoute){
          return this.setError('Työtä ei voida viimeistellä nykyisessä tilassa')
        }
        return true
      default:
        return true
    }
  }
}
