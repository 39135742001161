import { Options } from './types'

// option values = translation message id

export const towingOrderVisibilityOptions: Options = {
  options: {
    open: 'towingOrder.to_visibility_open',
    restricted: 'towingOrder.to_visibility_restricted',
    assigned: 'towingOrder.to_visibility_assigned',
  },
}

export const towingOrderStatusOptions: Options = {
  options: {
    pending_acceptance: 'towingOrder.status_pending_acceptance',
    open: 'towingOrder.status_open',
    accepted: 'towingOrder.status_accepted',
    assigned: 'towingOrder.status_assigned',
    towing_record_created: 'towingOrder.status_towing_record_created',
    cancelled: 'towingOrder.status_cancelled',
  },
}

export const towingServiceTypeOptions: Options = {
  options: {
    towing: 'towing.servicetype_towing',
    road_service: 'towing.servicetype_road_service',
    battery_service: 'towing.servicetype_battery_service',
    phone_service: 'towing.servicetype_phone_service',
    other: 'towing.servicetype_other',
  },
}

export const ordererTypeOptions: Options = {
  options: {
    company: 'orderer.type_company',
    person: 'orderer.type_person',
  },
}

export const towingRecordStatusOptions: Options = {
  options: {
    unfinished: 'towingRecord.tr_status_unfinished',
    complete: 'towingRecord.tr_status_complete',
    deleted: 'towingRecord.tr_status_deleted',
    billing: 'towingRecord.tr_status_billing',
    aggregate: 'towingRecord.tr_status_aggregate',
  },
}

export const towingRecordServiceTypeOptions: Options = {
  options: {
    towing: 'towingRecord.tr_servicetype_towing',
    road_service: 'towingRecord.tr_servicetype_road_service',
    battery_service: 'towingRecord.tr_servicetype_battery_service',
    phone_service: 'towingRecord.tr_servicetype_phone_service',
    other: 'towingRecord.tr_servicetype_other',
  },
}

export const notificationOptions = [
  { value: 'disabled', label: 'Älä lähetä' },
  { value: 'email', label: 'Sähköpostitse' },
  { value: 'sms', label: 'Tekstiviestillä' },
]

export const customerNotificationOptions = [
  { value: 'default', label: 'Vakioasetus' },
  { value: 'disabled', label: 'Älä lähetä' },
  { value: 'email', label: 'Sähköpostitse' },
  { value: 'sms', label: 'Tekstiviestillä' },
]
