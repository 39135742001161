import { FieldProps, getIn } from 'formik'
import React, { useCallback, useState, useEffect } from 'react'
import { Maybe } from '../../../../../common/types'
import { maskSsn, validateSsn } from '../../../../../util/common'
import { Error, FieldContainer, Label, FormikField as Field } from '../../style'

type Props = OwnProps & FieldProps

interface OwnProps {
  id: string
  label: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
}

export function PersonalIdentityCodeField(props: Props): JSX.Element {
  const { field, form, id, label, placeholder, disabled = false } = props

  const fieldValue: Maybe<string> = field.value

  const [value, setValue] = useState<string>(() => {
    if (fieldValue) {
      return fieldValue.toString()
    }

    return ''
  })

  // function validateSsn(value: string){
  //   const regex = /^[0-9]{6}[+Aa-][0-9]{3}[A-z0-9]$/
  //   return regex.test(value)
  // }

  const handleChange = useCallback(
    event => {
      const targetValue = event.target.value

      setValue(targetValue)

      const trimmedValue = targetValue.trim()

      form.setFieldValue(field.name, trimmedValue === '' ? null : formatPersonalIdentityCode(trimmedValue))
      form.setFieldTouched(field.name, true)
    },
    [form, field]
  )

  const fieldError = getIn(form.errors, field.name)
  const fieldTouched = getIn(form.touched, field.name)
  const hasErrors = Boolean(fieldError) && Boolean(fieldTouched)

  useEffect(() => {
    if (field.value !== value || disabled) {
      const newValue = validateSsn(field.value) ? maskSsn(field.value) : field.value
      setValue(newValue)
    }
  }, [field.value, value, disabled])

  return (
    <FieldContainer>
      <Label htmlFor={field.name}>{label}</Label>

      <Field
        type="text"
        id={id}
        name={field.name}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        autoComplete="off"
      />

      {hasErrors && <Error className="error-tooltip">{fieldError}</Error>}
    </FieldContainer>
  )
}

function formatPersonalIdentityCode(value: string): string {
  return value.toUpperCase()
}
