import React from 'react'
import styled, { css } from 'styled-components'
import { CreditInfo } from '../SearchCustomerInfo/types'
import { FormSubGroup, FormSubGroupHeader, FormSubGroupHeading, Row, FormSubGroupWrapper } from '../layout'
import { InjectedIntlProps, injectIntl } from 'react-intl'
import { messages } from './messages'

const CreditFormSubGroupHeader = styled(FormSubGroupHeader)`
  margin-bottom: 0;
`

const CreditFormSubGroup = styled(FormSubGroupWrapper)<{ decision: 'granted' | 'declined' | null }>`
  width: 100%;
  margin: 0 1.5rem 1.5rem 1.5rem;

  ${({ decision, theme }) => {
    if (decision === null) {
      return
    }

    const color = decision === 'granted' ? theme.colors.green100 : theme.colors.red100
    const textColor = decision === 'granted' ? theme.colors.green600 : theme.colors.red600

    return css`
      border-color: ${color};

      ${CreditFormSubGroupHeader} {
        background-color: ${color};
      }

      ${Decision} {
        color: ${textColor};
      }
    `
  }}
`

const CreditContainer = styled.div`
  display: flex;
`

const CreditRow = styled(Row)`
  justify-content: flex-start;
  padding: 3px 0;
`

const Decision = styled.span``

interface CreditDecisionProps {
  creditInfo: CreditInfo
}

const getDecisionMessage = (value: string | null) => {
  switch (value) {
    case 'granted':
      return messages.creditDecisionGranted
    case 'denied':
      return messages.creditDecisionDenied
    default:
      return messages.creditDecisionMissing
  }
}

const CreditDecisionIntl: React.FunctionComponent<CreditDecisionProps & InjectedIntlProps> = ({ intl, creditInfo }) => {
  const { formatMessage } = intl

  return (
    <CreditContainer>
      {creditInfo.invoice && (
        <CreditFormSubGroup decision={creditInfo.invoice.creditDecision}>
          <CreditFormSubGroupHeader>
            <FormSubGroupHeading>{creditInfo.invoice.serviceName}</FormSubGroupHeading>
          </CreditFormSubGroupHeader>
          <FormSubGroup>
            <CreditRow>
              <b>
                Rahoituspäätös:{' '}
                <Decision>{formatMessage(getDecisionMessage(creditInfo.invoice.creditDecision))}</Decision>
              </b>
            </CreditRow>
            <CreditRow>{creditInfo.invoice.message}</CreditRow>
          </FormSubGroup>
        </CreditFormSubGroup>
      )}
      {creditInfo.partPayment && (
        <CreditFormSubGroup decision={creditInfo.partPayment.creditDecision}>
          <CreditFormSubGroupHeader>
            <FormSubGroupHeading>{creditInfo.partPayment.serviceName}</FormSubGroupHeading>
          </CreditFormSubGroupHeader>
          <FormSubGroup>
            <CreditRow>
              <b>
                Rahoituspäätös:{' '}
                <Decision>{formatMessage(getDecisionMessage(creditInfo.partPayment.creditDecision))}</Decision>
              </b>
            </CreditRow>
            <CreditRow>{creditInfo.partPayment.message}</CreditRow>
          </FormSubGroup>
        </CreditFormSubGroup>
      )}
    </CreditContainer>
  )
}

export const CreditDecision = injectIntl(CreditDecisionIntl)
