import { Field, FieldProps, getIn } from 'formik'
import React from 'react'
import styled from 'styled-components'
import { Button as ButtonElement, ButtonProps } from '../../../Button'
import { Error, FieldContainer } from '../style'

const ButtonField: React.FunctionComponent<ButtonProps & FieldProps> = ({
  alignLeft,
  children,
  size,
  category,
  disabled,
  label,
  onClick,
  submit,
  className,
  loading = false,
  iconSize = 'small',
  field,
  form,
}) => {
  const fieldError = getIn(form.errors, field.name)
  const hasErrors = Boolean(fieldError)

  return (
    <FieldContainer>
      <ButtonElement
        alignLeft={alignLeft}
        children={children}
        size={size}
        category={category}
        disabled={disabled}
        label={label}
        onClick={onClick}
        submit={submit}
        className={className}
        loading={loading}
        iconSize={iconSize}
        name={field.name}
      />
      {hasErrors && <FormButtonError className="error-tooltip">{fieldError}</FormButtonError>}
    </FieldContainer>
  )
}

export function FormButton(props: ButtonProps): JSX.Element {
  const {
    alignLeft,
    children,
    size,
    category,
    disabled,
    label,
    onClick,
    submit,
    className,
    loading,
    iconSize,
    name,
  } = props

  return (
    <Field
      component={ButtonField}
      alignLeft={alignLeft}
      children={children}
      size={size}
      category={category}
      disabled={disabled}
      label={label}
      onClick={onClick}
      submit={submit}
      className={className}
      loading={loading}
      iconSize={iconSize}
      name={name}
    />
  )
}

const FormButtonError = styled(Error)`
  top: -3rem;
`
