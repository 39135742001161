import React from 'react'
import styled from 'styled-components'
import { Text } from '../layout'

export const VehicleMarkAndModel: React.FunctionComponent<{ markAndModel: string }> = ({ markAndModel }) => {
  return (
    <Wrapper>
      <Column>
        <Text>{markAndModel}</Text>
      </Column>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
`

const Column = styled.div`
  display: flex;
  flex-direction: row;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
`
