import React, { createContext, useContext, useEffect, useState } from 'react'
import { Maybe } from '../../common/types'
import { darkTheme, theme } from '../../styled/theme'
import { ThemeProvider } from 'styled-components'
import { StateStore } from '../StoreProvider'

export type Theme = 'light' | 'dark'

interface ThemeContextType {
  currentTheme: Theme
  toggleTheme: () => void
}

const ThemeContext = createContext<Maybe<ThemeContextType>>(null)

export const useTheme = () => {
  const context = useContext(ThemeContext)
  if (!context) {
    throw new Error('useTheme must be used within a ThemeContextProvider')
  }
  return context
}

interface ThemeProvideProps {
  children: JSX.Element
}
export const Theme = ({ children }: ThemeProvideProps) => {
  const [currentTheme, setCurrentTheme] = useState<Theme>('light')
  const { state } = useContext(StateStore)
  const disableDarkmode = state.currentUser && state.currentUser.companyId !== 521

  const toggleTheme = () => {
    if (disableDarkmode) return

    const updatedTheme = currentTheme === 'dark' ? 'light' : 'dark'
    setCurrentTheme(updatedTheme)
    setStoredTheme(updatedTheme)
  }

  useEffect(() => {
    if(disableDarkmode) return 

    const toggleDarkMode = getStoredTheme() === 'dark'
    if (toggleDarkMode) {
      setCurrentTheme('dark')
    }
  }, [disableDarkmode])

  return (
    <ThemeContext.Provider value={{ currentTheme, toggleTheme }}>
      <ThemeProvider theme={currentTheme === 'dark' ? darkTheme : theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  )
}

const STORED_THEME = 'theme'

function setStoredTheme(theme: Theme) {
  window.localStorage.setItem(STORED_THEME, JSON.stringify(theme))
}

function getStoredTheme() {
  try {
    const result = window.localStorage.getItem(STORED_THEME)

    if (!result) {
      return null
    }

    return JSON.parse(result)
  } catch (error) {
    return null
  }
}
