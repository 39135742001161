import React from 'react'
import { TowingRecordServiceType } from '../../common/types'
import { FormSubGroup, FormSubGroupHeading, ImageWrapper, Section, SectionColumn } from '../../components/layout'
import { DateInput, NumberInput, SelectInput, TextAreaInput, TextInput } from '../../components/layout/form/input'
import { ALFormSubGroupHeader, ALFormSubGroupWrapper } from '../ALOrderSubForm'
import { arcComponentOptions, arcDescriptionOptions } from './options'
import autoliitto from '../../images/autoliitto.png'

interface ALSubFormProps {
  serviceTypeOptions: object[]
  serviceType?: TowingRecordServiceType
}

export const ALSubForm: React.FC<ALSubFormProps> = ({ serviceTypeOptions }) => {
  return (
    <ALFormSubGroupWrapper>
      <ALFormSubGroupHeader>
        <FormSubGroupHeading>
          <ImageWrapper>
            <img src={autoliitto} alt="AL" width={`95px`} />
          </ImageWrapper>
        </FormSubGroupHeading>
      </ALFormSubGroupHeader>
      <FormSubGroup>
        <Section>
          <SectionColumn>
            <DateInput label="Päivämäärä" name="jobDetails.towingDate" />
          </SectionColumn>
          <SectionColumn>
            <NumberInput label="Mittarilukema, km" name="automobileAndTouringClubFinland.vehicleMileage" decimals={0} />
          </SectionColumn>
          <SectionColumn>
            <TextInput label="Tilauksen viite" name="automobileAndTouringClubFinland.caseNumber" disabled />
          </SectionColumn>
          <SectionColumn>
            <TextInput label="Laskutus" name="automobileAndTouringClubFinland.billingInfo" disabled />
          </SectionColumn>
          <SectionColumn>
            <NumberInput label="Henkilömäärä" name="automobileAndTouringClubFinland.personCount" decimals={0} />
          </SectionColumn>
          <SectionColumn>
            <TextInput label="Palvelukanava" name="automobileAndTouringClubFinland.serviceChannel" disabled />
          </SectionColumn>

          {/*<SectionColumn>
            {serviceType === TowingRecordServiceType.TOWING && (
              <NumberInput label="Laskutettavat kilometrit" name="automobileAndTouringClubFinland.distanceTowed" />
            )}
          </SectionColumn>*/}
        </Section>
        <Section>
          <SectionColumn>
            <SelectInput label="Työn tyyppi" name="jobDetails.serviceType" options={serviceTypeOptions} />
          </SectionColumn>
          <SectionColumn>
            <SelectInput
              label="Komponentti (ARC-vikakoodi)"
              name="automobileAndTouringClubFinland.arcCodes.component.code"
              options={arcComponentOptions}
            />
          </SectionColumn>
          <SectionColumn>
            <SelectInput
              label="Vian kuvaus (ARC-vikakoodi)"
              name="automobileAndTouringClubFinland.arcCodes.description.code"
              options={arcDescriptionOptions}
            />
          </SectionColumn>
        </Section>
        <Section columns={2}>
          <SectionColumn>
            <TextAreaInput label="Työselvitys Autoliitolle" name="jobDetails.additionalInfo" />
          </SectionColumn>
          <SectionColumn>
            <TextAreaInput label="Muistiinpanot (sisäiseen käyttöön)" name="jobDetails.notes" />
          </SectionColumn>

          {/*<SectionColumn>
            <TextAreaInput
              label="Autoliiton tilannearvio"
              name="automobileAndTouringClubFinland.situationDescription"
              disabled
            />
          </SectionColumn>*/}
        </Section>
      </FormSubGroup>
    </ALFormSubGroupWrapper>
  )
}
