import { Maybe, Coords } from '../../common/types'

export enum MapType {
  FORM = 'form',
  MAP = 'map',
}

export interface MapData {
  address: Maybe<string>
  city: Maybe<string>
  zipcode: Maybe<string>
  coords: Coords
}

export interface FormData {
  addressName: string
  cityName: string
  coordsName: string
}

type GetFieldValue = (fieldName: string) => any
type GetFieldTouched = (fieldName: string) => any

export type GetValueType = GetFieldValue | GetFieldTouched
