import styled, { css } from 'styled-components'

interface Props {
  required?: boolean
  hideMobile?: boolean
}

export const Label = styled('label')<Props>`
  margin: 0 0 0.25rem 0;
  font-size: 1rem;
  color: ${props => (props.color ? props.theme.colors[props.color] : props.theme.colors.brlack)};
  ${props =>
    props.required &&
    css`
      ::after {
        content: ' *';
      }
    `}
  ${props =>
    props.hideMobile &&
    css`
      @media (max-width: ${props.theme.screenSize.mobile}) {
        display: none;
      }
    `}
  color: ${props => (props.color ? props.theme.colors[props.color] : props.theme.colors.black)};
`
