import React from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import styled from 'styled-components'

const PathNavLink: React.FunctionComponent<NavLinkProps> = props => {
  return (
    <NavLink
      // code below explained: make NavLink show as active if url up to first slash matches the NavLink "to" props value
      // example: /towing-jobs/5 will match /towing-jobs and the NavLink will be shown as active
      isActive={(_, { pathname }) => pathname.substr(1).match(/.*?(?=\/|$)/i)![0] === props.to.toString().substr(1)}
      {...props}
    />
  )
}

export const RouterNavLink = styled(PathNavLink)`
  padding: 0.25rem;
  text-decoration: none;
  color: ${props => props.theme.colors.grey500};
  &.active {
    color: ${props => props.theme.colors.black};
    text-decoration: none;

    svg * {
      fill: ${props => props.theme.colors.black};
    }
  }
  &:hover {
    color: ${props => props.theme.colors.black};
    text-decoration: underline;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
`
