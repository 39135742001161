import { useMutation, useQuery } from '@apollo/react-hooks';
import React, { useState, useCallback, useEffect, useContext } from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { FormSubGroup, Row, ButtonRow } from '../layout';
import { persistentDelete } from '../../util/localStorage/localStorage';
import { STORED_TOWING_RECORD } from '../../containers/StoreProvider/OfflineMode';
import { Maybe } from '../../common/types';
import { Button } from '../Button';
import { StateStore } from '../../containers/StoreProvider';
import { paymentTerminalMessages } from './messages';
import {
  LogPaymentTransactionResponse,
  PaymentTerminalsResponse,
  SetPaymentTerminalTransactionToPaymentResponse,
  UpdatePaymentTerminalTransactionOnPaymentResponse,
  PaymentTerminalTransaction,
  RemoveTransactionFromPaymentResponse,
  RevertTransactionFromPaymentResponse,
} from './types';
import { EditTowingRecordFormValues } from '../../containers/TowingRecordPage/EditTowingRecord/types';
import { toMutationVariables } from '../../containers/TowingRecordPage/EditTowingRecord/mutation/toMutationVariables';
import { toConfirmBillingMutationVariables } from '../../containers/TowingRecordPage/components/billing/BillingConfirmation/toConfirmBillingMutationVariables';
import { toDeleteBillingMutationVariables } from '../../containers/TowingRecordPage/components/billing/TowingRecordBilling/toDeleteBillingMutationVariables';
import {
  LOG_TRANSACTION,
  GET_PAYMENT_TERMINALS,
  SET_TRANSACTION_TO_PAYMENT,
  UPDATE_TRANSACTION_ON_PAYMENT,
  REMOVE_TRANSACTION_FROM_PAYMENT,
  REVERT_TRANSACTION_FROM_PAYMENT,
} from './mutations';
import { EDIT_TOWING_RECORD } from '../../containers/TowingRecordPage/EditTowingRecord/mutation/mutation';
import { CONFIRM_BILLING } from '../../containers/TowingRecordPage/components/billing/BillingConfirmation/confirmMutation';
import { DELETE_BILLING } from '../../containers/TowingRecordPage/components/billing/TowingRecordBilling/mutations';
import { EditTowingRecordResponse } from '../../containers/TowingRecordPage/EditTowingRecord/mutation/types';
import {
  setErrorNotifications,
  setFatalErrorNotification,
} from '../responses/setErrorNotifications';
import { setSuccessNotification, setErrorNotification } from '../notification';
import {
  generateUrl,
  generateAltUrl,
  getAltUrlData,
  getWsTransactionData,
  getTransactionToRefund,
  getTransactionToheck,
  generateReceiptImg,
} from './util';
import { useHistoryPush } from '../router';
import {
  PaymentTerminalPurchaseModal,
  PaymentTerminalRefundModal,
  PaymentTerminalCheckModal,
  PaymentConfirmationModal,
} from './modals';
//import { getInitialValues } from '../../containers/TowingRecordPage/EditTowingRecord/initialValues';

interface IPaymentTerminals {
  itemsTotal: Maybe<number>;
  values?: EditTowingRecordFormValues;
  hasFormChanged?: () => boolean
}

let wsConnection: { [key: string]: any } = {
  connected: false,
  connectionReason: '',
  status: '',
  msg: '',
  selectedTerminal: [],
  amountToPay: '',
  response: undefined,
  terminalInfo: [],
  sendReceipt: false,
};

let ws: any = null;
let sendMethod: Maybe<string> = null;
let receiptId = parseInt(String(+new Date())); //parseInt(String(self.towingRecordId()) + String(+new Date()));

export const PaymentTerminalsIntl: React.FunctionComponent<
  IPaymentTerminals & InjectedIntlProps
> = ({ itemsTotal, values, hasFormChanged, intl }) => {
  const historyPush = useHistoryPush();
  const { formatMessage } = intl;
  const {
    state: { settings },
  } = useContext(StateStore);
  const [paymentTerminalPurchase, showPaymentTerminalPurchaseModal] = useState<boolean>(false);
  const [paymentTerminalRefund, showPaymentTerminalRefundModal] = useState<boolean>(false);
  const [paymentTerminalCheck, showPaymentTerminalCheckModal] = useState<boolean>(false);
  const [showSaveConfirmationModal, setShowSaveConfirmationModal] = useState<boolean>(false);
  const [selectedPaymentTerminal, setSelectedTerminal] = useState<{ [key: string]: any }>({});
  const [wsConnectionData, setWsConnectionData] = useState<{ [key: string]: any }>(wsConnection);
  const [loading, setloading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [paymentTerminals, setPaymentTerminals] = useState<any[]>([]);
  const [paymentTerminalOptions, setPaymentTerminalOptions] = useState<any[]>([]);
  const [tempTransaction, setTempTransaction] = useState<PaymentTerminalTransaction>();
  const [checkTransaction, setCheckTransaction] = useState<PaymentTerminalTransaction>();
  const [refundTransaction, setRefundTransaction] = useState<{ [key: string]: any }>();
  let receiptEmail =
    values && values.billing.paymentDetails.receiptEmail
      ? values.billing.paymentDetails.receiptEmail
      : null;

  let showCheck = () => {
    if (values && values.billing && values.billing.paymentTerminalTransactions) {
      var hasPending =
        values.billing.paymentTerminalTransactions.filter(function(t) {
          return t.transactionStatus === 'pending';
        }).length > 0;
      if (hasPending) {
        setErrorNotification(
          formatMessage(paymentTerminalMessages.transaction_pending_error_title),
          formatMessage(paymentTerminalMessages.transaction_pending_error_message),
          true
        );
        return true;
      }
    }
    return false;
  };
  let showRefund = () => {
    if (!showCheck() && values && values.billing && values.billing.paymentTerminalTransactions) {
      return (
        values.billing.paymentTerminalTransactions.filter(function(t) {
          return t.transactionStatus === 'success';
        }).length > 0
      );
    }
    return false;
  };

  let showPurchase = () => {
    var disabled = values && values.status ? (values.status !== 'unfinished' && values.status !== 'billing') : false;

    return !showRefund() && !showCheck() && !disabled;
  };
  const [confirmBilling] = useMutation(CONFIRM_BILLING, {
    onCompleted(result) {
      if (result.confirmBilling.__typename === 'ConfirmBillingSuccess') {
        closeConnection();
        if (values) historyPush(`/towing-record/${values.id}/edit`);
        setSuccessNotification(
          formatMessage(paymentTerminalMessages.transaction_updated_success_title),
          formatMessage(paymentTerminalMessages.transaction_purchase_success_message),
        );
      } else {
        setErrorNotifications({ data: result.confirmBilling });
      }
    },
    onError(err) {
      setFatalErrorNotification(err.message);
    },
  });
  const [deleteBilling] = useMutation(DELETE_BILLING, {
    onCompleted(result) {
      if (result.deleteBilling.__typename === 'DeleteBillingSuccess') {
        closeConnection();
        if (values) historyPush(`/towing-record/${values.id}/edit`);
        setSuccessNotification(
          formatMessage(paymentTerminalMessages.transaction_updated_success_title),
          formatMessage(paymentTerminalMessages.transaction_refund_success_message),
        );
      } else {
        setErrorNotifications({ data: result.confirmBilling });
      }
    },
    onError(err) {
      setFatalErrorNotification(err.message);
    },
  });
  const [logPaymentTerminalTransaction, logPaymentTerminalTransactionResult] = useMutation<
    LogPaymentTransactionResponse
  >(LOG_TRANSACTION, {
    onCompleted() {},
    onError(err) {
      console.log(err);
    },
  });
  const [setTransactionToPayment] = useMutation<SetPaymentTerminalTransactionToPaymentResponse>(
    SET_TRANSACTION_TO_PAYMENT,
    {
      onCompleted(response) {
        if (
          response.setTransactionToPaymentRequest.__typename === 'SetTransactionToPaymentSuccess'
        ) {
          setTempTransaction(response.setTransactionToPaymentRequest.paymentTerminalTransaction);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );

  const [removeTransactionFromPayment] = useMutation<RemoveTransactionFromPaymentResponse>(
    REMOVE_TRANSACTION_FROM_PAYMENT,
    {
      onCompleted(response) {
        if (
          response.removeTransactionFromPaymentRequest.__typename ===
          'RemoveTransactionFromPaymentSuccess'
        ) {
          closeConnection();
          if (values) historyPush(`/towing-record/${values.id}/edit`);
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );

  const [revertTransactionFromPayment] = useMutation<RevertTransactionFromPaymentResponse>(
    REVERT_TRANSACTION_FROM_PAYMENT,
    {
      onCompleted(response) {
        if (
          response.revertTransactionFromPaymentRequest.__typename ===
          'RevertTransactionFromPaymentSuccess'
        ) {
          if (values) {
            var responseTransaction =
              response.revertTransactionFromPaymentRequest.paymentTerminalTransaction;
            var paymentTerminalTransactions = values.billing.paymentTerminalTransactions;
            var updatedTransactions = paymentTerminalTransactions.filter((t) => {
              return t.id !== responseTransaction.id;
            });
            values.billing.paymentTerminalTransactions = updatedTransactions;
          }
        }
      },
      onError(err) {
        console.log(err);
      },
    },
  );

  const [updateTransactionOnPayment] = useMutation<
    UpdatePaymentTerminalTransactionOnPaymentResponse
  >(UPDATE_TRANSACTION_ON_PAYMENT, {
    onCompleted(response) {
      if (
        response.updateTransactionOnPaymentRequest.__typename ===
        'UpdateTransactionOnPaymentSuccess'
      ) {
        setTempTransaction(response.updateTransactionOnPaymentRequest.paymentTerminalTransaction);
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  const [saveTowingRecord] = useMutation<EditTowingRecordResponse>(EDIT_TOWING_RECORD, {
    onCompleted({ editTowingRecord }) {
      if (
        editTowingRecord.__typename === 'EditTowingRecordSuccess' ||
        editTowingRecord.__typename === 'EditTowingRecordWithBillingUpdateSuccess'
      ) {
        if (editTowingRecord.towingRecord){
          persistentDelete(STORED_TOWING_RECORD);
          historyPush(`/towing-record/${editTowingRecord.towingRecord.id}/edit/billing`)
        }
     
      } else {
        setErrorNotifications({ data: editTowingRecord });
        setSaving(false);
      }
    },
    onError() {
      setSaving(false)
    },
  });

  useQuery<PaymentTerminalsResponse>(GET_PAYMENT_TERMINALS, {
    onCompleted(response) {
      if (response.activePaymentTerminals.__typename === 'PaymentTerminalsSuccess') {
        setPaymentTerminals(response.activePaymentTerminals.paymentTerminals);
        if (response.activePaymentTerminals.paymentTerminals.length > 0) {
          var options: any[] = [];
          response.activePaymentTerminals.paymentTerminals.forEach((paymentTerminal) => {
            options.push({
              value: paymentTerminal.terminalId,
              label: paymentTerminal.terminalName,
            });
          });
          setPaymentTerminalOptions(options);
        }
      }
      if (response.activePaymentTerminals.__typename !== 'PaymentTerminalsSuccess') {
        updateWsConnectionData({
          status: 'error',
          msg: 'Aktiivisten maksupäätteitten haku epäonnistui!',
        });
      }
    },
    onError(err) {
      console.log(err);
    },
  });

  let wsConnect = () => {
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    var url;
    if (isSafari) {
      url = generateAltUrl(wsConnectionData.selectedTerminal);
      var data = getAltUrlData(wsConnectionData.selectedTerminal);
      ws = new WebSocket(url, data);
    } else {
      url = generateUrl(wsConnectionData.selectedTerminal);
      ws = new WebSocket(url, ['jsonrpc2.0']);
    }

    ws.onopen = wsOnOpen;
    ws.onmessage = wsOnMessage;
    ws.onerror = wsOnError;
    ws.onclose = wsOnClose;
  };

  let connectPaymentTerminalRefund = () => {
    resetWsConnectionData();
    showPaymentTerminalRefundModal(true);
    setloading(true);
    var transaction = getTransactionToRefund(values);
    if (!transaction) {
      updateWsConnectionData({
        status: 'error',
        msg: 'Hyvitettävää maksutapahtumaa ei löytynyt, päivitä sivu ja yritä uudelleen!',
      });
    }
    var terminalData;
    terminalData = paymentTerminals.find(
      (terminal: any) => terminal.terminalId === transaction!.terminalId,
    );
    if (!terminalData) {
      updateWsConnectionData({
        status: 'error',
        msg: 'Maksupäätetapahtumaan liitetttyä maksupäätettä ei löytynyt!',
      });
      return;
    }

    setRefundTransaction(transaction);
    setSelectedTerminal(terminalData);
    updateWsConnectionData({
      selectedTerminal: terminalData,
      connectionReason: 'refund',
    });
    wsConnect();
  };

  let connectPaymentTerminalCheck = () => {
    resetWsConnectionData();
    showPaymentTerminalCheckModal(true);
    setloading(true);
    var transaction = getTransactionToheck(values);
    if (!transaction || transaction === undefined) {
      updateWsConnectionData({
        status: 'error',
        msg: 'Tarkistettavaa maksutapahtumaa ei löytynyt, päivitä sivu ja yritä uudelleen!',
      });
      return;
    }
    var terminalData;
    terminalData = paymentTerminals.find(
      (terminal: any) => terminal.terminalId === transaction!.terminalId,
    );
    if (!terminalData) {
      updateWsConnectionData({
        status: 'error',
        msg: 'Maksupäätetapahtumaan liitetttyä maksupäätettä ei löytynyt!',
      });
      return;
    }
    setCheckTransaction(transaction);
    setSelectedTerminal(terminalData);
    updateWsConnectionData({
      selectedTerminal: terminalData,
      connectionReason: 'check',
    });
    wsConnect();
  };

  let wsOnOpen = (): void => {
    setloading(false);
    if (wsConnectionData.connectionReason !== 'check') wsSend('TerminalInfo', {});
    updateWsConnectionData({ connected: true });
  };

  let wsOnMessage = (event: any): void => {
    var response = JSON.parse(event.data);
    if (response.method === '_Keepalive') {
      return ws.send(
        JSON.stringify({
          jsonrpc: '2.0',
          result: {},
          id: response.id,
        })
      );
    }
    if (sendMethod && response.response_to && response.response_to === sendMethod) {
      if (response.error) {
        var inputError: string = JSON.stringify({ method: sendMethod, error: response.error });
        logPaymentTerminalTransaction({ variables: { input: { response: inputError } } });
      }

      if (response.result) {
        var inputResult: string = JSON.stringify({ method: sendMethod, result: response.result });
        logPaymentTerminalTransaction({ variables: { input: { response: inputResult } } });
      }
      setResponseToWsConnectionData(response);
      setloading(false);
    }
  };

  let wsOnError = (event: any): void => {
    if (event) {
      var inputResponse: string = JSON.stringify({ error: event });
      logPaymentTerminalTransaction({ variables: { input: { response: inputResponse } } });
    }
    updateWsConnectionData({
      status: 'error',
      msg: 'Yhteyden luominen maksupäätelaitteeseen epäonnistui!',
    });
    setloading(false);
  };

  let wsOnClose = (): void => {
    if (loading) setloading(false);
  };
  const wsSend = useCallback(
    (method: string, params: any) => {
      if (method !== 'Print') {
        if (!loading) setloading(true);
        sendMethod = method;
      }

      var connectionId = String(+new Date());
      ws.send(
        JSON.stringify({
          jsonrpc: '2.0',
          method: method,
          id: connectionId,
          params: params,
        }),
      );
    },
    [loading],
  );

  const updateWsConnectionData = useCallback(
    (newValues: any) => {
      Object.entries(newValues).forEach(([key, value]) => (wsConnectionData[key] = value));
      setWsConnectionData(wsConnectionData);
    },
    [setWsConnectionData, wsConnectionData],
  );
  const resetWsConnectionData = useCallback(() => {
    updateWsConnectionData({
      connected: false,
      connectionReason: '',
      status: '',
      msg: '',
      selectedTerminal: [],
      amountToPay: '',
      response: undefined,
      terminalInfo: [],
      sendReceipt: false,
    });
  }, [updateWsConnectionData]);

  useEffect(() => {
    let wsSendPrint = (
      transactionData: { [key: string]: any },
      companyData: { [key: string]: any },
      wsConnectionData: { [key: string]: any },
    ) => {
      var receiptImages = generateReceiptImg(transactionData, companyData, wsConnectionData);
      receiptImages.forEach(function(receiptImg) {
        var sendParams = {
          eject: receiptImages.length === 1,
          image: {
            format: 'png',
            data: receiptImg,
          },
        };
        console.log('sendParams', sendParams);
        wsSend('Print', sendParams);
      });
    };
    if (wsConnectionData.status === 'error') {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.close();
        updateWsConnectionData({ connected: false });
      }
      setloading(false);
    }
    if (wsConnectionData.status === 'success' && !loading) {
      setloading(true);
      if (values && values.id) {
        var transactionData = getWsTransactionData(wsConnectionData);
        var updateTransactionData = {
          towingRecordId: values.id,
          origin: 'innohinaus-app',
          transactionData: transactionData,
        };
        if (wsConnectionData.connectionReason !== 'check' && settings && settings.companyData) {
          wsSendPrint(transactionData, settings.companyData, wsConnectionData);
        }
        updateTransactionOnPayment({ variables: { input: updateTransactionData } }).then(
          (response) => {
            if (
              response &&
              response.data &&
              response.data.updateTransactionOnPaymentRequest.__typename ===
                'UpdateTransactionOnPaymentSuccess'
            ) {
              if (wsConnectionData.connectionReason === 'purchase') {
                confirmBilling({
                  variables: toConfirmBillingMutationVariables({
                    towingRecordId: values.id,
                    sendReceipt: wsConnectionData.sendReceipt,
                  }),
                });
              }
              if (wsConnectionData.connectionReason === 'refund') {
                deleteBilling({
                  variables: toDeleteBillingMutationVariables({ towingRecordId: values.id }),
                });
              }
              if (wsConnectionData.connectionReason === 'check') {
                var transaction =
                  response.data.updateTransactionOnPaymentRequest.paymentTerminalTransaction;
                if (transaction.transactionType === 'Purchase') {
                  confirmBilling({
                    variables: toConfirmBillingMutationVariables({
                      towingRecordId: values.id,
                      sendReceipt: false,
                    }),
                  });
                }
                if (transaction.transactionType === 'Refund') {
                  deleteBilling({
                    variables: toDeleteBillingMutationVariables({ towingRecordId: values.id }),
                  });
                }
              }
            }
          },
        );
      }
    }
    if (wsConnectionData.connected && wsConnectionData.status === '' && !loading) {
      switch (wsConnectionData.connectionReason) {
        case 'purchase':
          try {
            setloading(true);
            if (!itemsTotal) throw new Error('itemsTotal is missing');
            if (!values || !values.id) throw new Error('values are missing');
            var amount = itemsTotal;
            amount = Math.round(100 * amount);
            var setPurchaseTransactionData = {
              towingRecordId: values.id,
              transactionType: 'Purchase',
              receiptId: receiptId,
              origin: 'innohinaus-app',
              terminalId: wsConnectionData.selectedTerminal.terminalId,
              amount: amount,
            };
            setTransactionToPayment({ variables: { input: setPurchaseTransactionData } }).then(
              (response) => {
                if (
                  response &&
                  response.data &&
                  response.data.setTransactionToPaymentRequest.__typename ===
                    'SetTransactionToPaymentSuccess'
                ) {
                  var sendParams = {
                    cashier_language: 'fi',
                    receipt_id: receiptId,
                    amount: amount,
                    currency: 'EUR',
                    no_timeout: false,
                  };
                  wsSend('Purchase', sendParams);
                } else {
                  throw new Error('failed in setTransactionToPayment');
                }
              },
            );
          } catch (error) {
            console.log(error);
            updateWsConnectionData({
              msg: 'Maksutietojen lähetys maksupäätteelle epäonnistui!',
              status: 'error',
            });
            setloading(false);
          }
          break;
        case 'refund':
          try {
            setloading(true);
            if (!values || !values.id) throw new Error('values are missing');
            if (!refundTransaction) throw new Error('refund transaction is missing');
            var setRefundTransactionData = {
              towingRecordId: values.id,
              transactionType: 'Refund',
              previousTransactionId: refundTransaction.id,
              receiptId: receiptId,
              origin: 'innohinaus-app',
              terminalId: refundTransaction.terminalId,
              amount: parseInt(refundTransaction.amount),
            };
            setTransactionToPayment({
              variables: { input: setRefundTransactionData },
            }).then((response) => {
              if (
                response &&
                response.data &&
                response.data.setTransactionToPaymentRequest.__typename ===
                  'SetTransactionToPaymentSuccess'
              ) {
                var sendParams = {
                  cashier_language: 'fi',
                  receipt_id: receiptId,
                  amount: parseInt(refundTransaction!.amount),
                  currency: 'EUR',
                };
                wsSend('Refund', sendParams);
              } else {
                throw new Error('failed in setTransactionToPayment');
              }
            });
          } catch (error) {
            console.log(error);
            updateWsConnectionData({
              msg: 'Hyvityksen lähetys Innovoicen palvelimelle epäonnistui!',
              status: 'error',
            });
            setloading(false);
          }
          break;
        case 'check':
          var sendParams = {
            receipt_id: parseInt(checkTransaction!.receiptId),
            amount: parseInt(checkTransaction!.amount),
            currency: 'EUR',
          };
          wsSend('Check', sendParams);
          break;
      }
    }
  }, [
    refundTransaction,
    checkTransaction,
    wsConnectionData,
    values,
    itemsTotal,
    setTransactionToPayment,
    updateWsConnectionData,
    paymentTerminalPurchase,
    updateTransactionOnPayment,
    historyPush,
    formatMessage,
    resetWsConnectionData,
    confirmBilling,
    deleteBilling,
    wsSend,
    loading,
    settings,
  ]);

  let wsSendAbort = () => {
    var method = 'Abort';
    var sendParams = {};
    wsSend(method, sendParams);
    if (values && tempTransaction) {
      var revertData = {
        transactionId: tempTransaction.id,
        towingRecordId: values.id,
        origin: 'innohinaus-app',
      };
      revertTransactionFromPayment({ variables: { input: revertData } });
    }
  };

  let closeConnection = () => {
    if (loading) setloading(false);
    sendMethod = null;
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.close();
    }
    resetWsConnectionData();
    showPaymentTerminalPurchaseModal(false);
    showPaymentTerminalRefundModal(false);
    showPaymentTerminalCheckModal(false);
  };

  let setResponseToWsConnectionData = (response: any) => {
    var message;
    var status;
    switch (response.response_to) {
      case 'Purchase':
        if (response.error) {
          status = 'error';
          message = {
            type: 'error',
            message: 'Maksu epäonnistui',
            result: response.error,
          };
        }
        if (response.result) {
          response.result['transaction_type'] = response.response_to;
          status = 'success';
          message = {
            type: 'success',
            message: 'Maksu onnistui',
            result: response.result,
          };
        }
        updateWsConnectionData({ response: message, status: status });
        break;
      case 'Refund':
        if (response.error) {
          status = 'error';
          message = {
            type: 'error',
            message: 'Hyvitys epäonnistui',
            result: response.error,
          };
        }
        if (response.result) {
          response.result['transaction_type'] = response.response_to;
          status = 'success';
          message = {
            type: 'success',
            message: 'Hyvitys onnistui',
            result: response.result,
          };
        }
        updateWsConnectionData({ response: message, status: status });
        break;
      case 'TerminalInfo':
        if (response.result) {
          updateWsConnectionData({ terminalInfo: response.result });
        }
        break;
      case 'Check':
        if (response.error) {
          status = 'error';
          message = {
            type: 'error',
            message: 'Maksupäätetapahtuma virheellinen!',
            result: response.error,
          };
        }
        if (response.result) {
          if (response.result.terminal_processing) {
            message = {
              type: 'success',
              message:
                'Maksupäätetapahtuma kesken, suorita maksu päätteellä loppuun ja hae tiedot uudelleen',
              result: '',
            };
          } else {
            response.result.transaction_type = response.response_to;
            status = 'success';
            message = {
              type: 'success',
              message: 'Maksupääteveloitus onnistunut (' + response.result.reference_number + ')',
              result: response.result,
            };
          }
        }
        updateWsConnectionData({ response: message, status: status });
        break;
      case 'Abort':
        closeConnection();
        break;
    }
  };

  const handleSaveRecord = useCallback(() => {
      if(!itemsTotal || itemsTotal === 0){
        setErrorNotification('Virhe', 'Kuormakirjalla ei ole yhtään maksuriviä.');
        return;
      }

      if(!saving){
        setSaving(true);
        
        (async () => {
          if (values) {
            await saveTowingRecord({ variables: toMutationVariables(values) });
          }
        })()
      }
  }, [saveTowingRecord, saving, values, itemsTotal]);

  const saveOrConnect = useCallback(()=>{
    if(hasFormChanged && hasFormChanged()){
      setShowSaveConfirmationModal(true);
      return;
    }
    showPaymentTerminalPurchaseModal(true);

  }, [hasFormChanged, setShowSaveConfirmationModal, showPaymentTerminalPurchaseModal])

  return (
    <>
      <FormSubGroup>
        <Row>
          <ButtonRow gap>
              {showPurchase() && (
                <Button category="save" label="Yhdistä maksupäätteeseen" onClick={saveOrConnect}/>
              )}
              {showRefund() && <Button category="cancel" label="Hyvitä" onClick={connectPaymentTerminalRefund} />}
              {showCheck() && (
                <Button category="danger" label="Tarkista/hae maksutiedot" onClick={connectPaymentTerminalCheck} />
              )}
          </ButtonRow>
        </Row>
      </FormSubGroup>
      {showSaveConfirmationModal && !paymentTerminalPurchase &&  (<PaymentConfirmationModal onConfirm={handleSaveRecord} onCancel={()=>{setShowSaveConfirmationModal(false)}} />)}
      {paymentTerminalPurchase && (
        <PaymentTerminalPurchaseModal
          wsConnectionData={wsConnectionData}
          wsSendAbort={wsSendAbort}
          closeConnection={closeConnection}
          paymentTerminals={paymentTerminals}
          paymentTerminalOptions={paymentTerminalOptions}
          loading={loading || logPaymentTerminalTransactionResult.loading}
          setSelectedTerminal={setSelectedTerminal}
          itemsTotal={itemsTotal}
          updateWsConnectionData={updateWsConnectionData}
          setloading={setloading}
          selectedPaymentTerminal={selectedPaymentTerminal}
          resetWsConnectionData={resetWsConnectionData}
          wsConnect={wsConnect}
          receiptEmail={receiptEmail}
        />
      )}
      {paymentTerminalRefund && (
        <PaymentTerminalRefundModal
          wsConnectionData={wsConnectionData}
          wsSendAbort={wsSendAbort}
          closeConnection={closeConnection}
          loading={loading || logPaymentTerminalTransactionResult.loading}
        />
      )}
      {paymentTerminalCheck && (
        <PaymentTerminalCheckModal
          wsConnectionData={wsConnectionData}
          closeConnection={closeConnection}
          loading={loading || logPaymentTerminalTransactionResult.loading}
          onClick={() => {
            if (!checkTransaction) return;
            var removeData = {
              transactionId: checkTransaction.id,
              origin: 'innohinaus-app',
            };
            removeTransactionFromPayment({ variables: { input: removeData } });
          }}
        />
      )}
    </>
  );
};

export const PaymentTerminals = injectIntl(PaymentTerminalsIntl);
