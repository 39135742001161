import { Formik, getIn } from 'formik'
import React from 'react'
import { injectIntl } from 'react-intl'
import { v4 as uuid } from 'uuid'
import { Maybe, User, UserRole } from '../../common/types'
import { JobFilters, JobTypeFilter, StationFilter } from '../../containers/Parameters/types'
import { Button } from '../Button'
import { RouteColumn, RouteRow, Text } from '../layout'
import { CheckboxInput } from '../layout/form/input'

interface FilterFormProps {
  filters: Maybe<JobFilters>
  user: Maybe<User>
  loading: boolean
  setLoading: (value: boolean) => void
  saveFilters: (values: any) => void
}

const FilterFormIntl: React.FunctionComponent<FilterFormProps> = ({
  filters,
  user,
  loading,
  setLoading,
  saveFilters,
}) => {
  if (!user) {
    if (!loading) {
      setLoading(true)
    }
    return null
  }

  console.log('FilterForm', filters)

  const initialValues = getInitialValues(filters, user)

  const stationFilters =
    filters && filters.stations && filters.stations.length > 0 ? (
      filters.stations.map(s => {
        return (
          <RouteColumn key={s.id}>
            <CheckboxInput name={`stations.${s.id}`} label={s.name} />
          </RouteColumn>
        )
      })
    ) : (
      <Text fontSize="1rem">Ei toimipisteitä</Text>
    )

  const typeFilters =
    filters && filters.jobTypes && filters.jobTypes.length > 0 ? (
      filters.jobTypes.map(j => {
        return (
          <RouteColumn key={uuid()}>
            <CheckboxInput name={`jobTypes.${maybeFormatName(j.name)}`} label={j.name} disabled={j.locked} />
          </RouteColumn>
        )
      })
    ) : (
      <Text fontSize="1rem">Ei työn tyyppejä</Text>
    )

  if (!initialValues) {
    return null
  }

  if (loading) {
    setLoading(false)
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values: any) => {
          console.log('values', values)
        }}
        render={({ values }) => {
          const setFilters = (values: any) => {
            if (!filters) {
              return false
            }

            const newStationFilters: StationFilter[] = []
            const newJobTypeFilters: JobTypeFilter[] = []

            if (filters.stations && filters.stations.length > 0) {
              filters.stations.map(s => {
                const value = getIn(values, `stations.${s.id}`)
                if (value !== null) {
                  newStationFilters.push({ name: s.name, id: s.id, include: value })
                }
                return null
              })
            }

            if (filters.jobTypes && filters.jobTypes.length > 0) {
              filters.jobTypes.map(j => {
                const value = getIn(values, `jobTypes.${maybeFormatName(j.name)}`)

                if (value !== null) {
                  newJobTypeFilters.push({ name: j.name, include: value, locked: j.locked })
                }
                return null
              })
            }

            saveFilters({
              id: null,
              filters: {
                stations: newStationFilters,
                jobTypes: newJobTypeFilters,
                visibility: values.visibility,
                operators: values.operators,
              },
            })
          }

          return (
            <>
              {user &&
                [UserRole.admin, UserRole.super_admin, UserRole.main_user, UserRole.duty_officer].includes(
                  user.role
                ) && (
                  <>
                    <RouteRow columns="1fr" mobileGridGap="0" gridGap="0.5rem">
                      <RouteColumn>
                        <Text fontSize="1rem" margin="0">
                          Töiden näkyvyys
                        </Text>
                      </RouteColumn>
                    </RouteRow>
                    <RouteRow columns="1fr 1fr" mobileGridGap="0" gridGap="0.5rem">
                      <RouteColumn>
                        <CheckboxInput name={`visibility.open`} label={'Avoimet'} />
                      </RouteColumn>
                      <RouteColumn>
                        <CheckboxInput name={`visibility.own`} label={'Omat'} />
                      </RouteColumn>
                      <RouteColumn>
                        <CheckboxInput name={`visibility.others`} label={'Muiden'} />
                      </RouteColumn>
                    </RouteRow>
                  </>
                )}
              <RouteRow columns="1fr" mobileGridGap="0" gridGap="0.5rem">
                <RouteColumn>
                  <Text fontSize="1rem" margin="0">
                    Toimipisteet
                  </Text>
                </RouteColumn>
              </RouteRow>
              <RouteRow columns="1fr 1fr" mobileGridGap="0" gridGap="0.5rem">
                {stationFilters}
              </RouteRow>
              <RouteRow columns="1fr" mobileGridGap="0" gridGap="0.5rem">
                <RouteColumn>
                  <Text fontSize="1rem" margin="0">
                    Työn tyypit
                  </Text>
                </RouteColumn>
              </RouteRow>
              <RouteRow columns="1fr 1fr" mobileGridGap="0" gridGap="0.5rem">
                {typeFilters}
              </RouteRow>
              {filters && (filters.jobTypes || filters.stations || filters.visibility) && (
                <RouteRow mobileGridGap="0">
                  <RouteColumn />
                  <RouteColumn>
                    <Button
                      label="Tallenna"
                      category="save"
                      maxWidth="100%"
                      onClick={() => {
                        setFilters(values)
                      }}
                    />
                  </RouteColumn>
                </RouteRow>
              )}
            </>
          )
        }}
      />
    </>
  )
}

export const FilterForm = injectIntl(FilterFormIntl)

function getInitialValues(filters: Maybe<JobFilters>, user: Maybe<User>) {
  if (!filters) {
    return { stations: null, jobTypes: null, visibility: null, operators: null }
  }

  const values: any = { stations: {}, jobTypes: {}, visibility: null }

  if (filters.stations) {
    filters.stations.forEach(s => {
      values.stations[s.id] = s.include
    })
  }

  if (filters.jobTypes) {
    filters.jobTypes.forEach(s => {
      values.jobTypes[maybeFormatName(s.name)] = s.include
    })
  }

  values.operators = filters.operators

  if (user) {
    if (user.role === 'main_user' || user.role === 'duty_officer') {
      const currentVisibility = (filters.visibility as unknown) as string
      let visibility = filters.visibility ? filters.visibility : null
      if (currentVisibility === 'ALL') {
        visibility = { open: true, own: true, others: true }
      }
      if (currentVisibility === 'OWN') {
        visibility = { open: true, own: true, others: false }
      }

      values.visibility = visibility
    }

    if (!values.operators) {
      values.operators = [user.userId]
    }
  }

  return values
}

function maybeFormatName(name: string): string {
  let formatted = name
  const lastChar = formatted.charAt(formatted.length - 1)

  if (lastChar === '.') {
    formatted = formatted.slice(0, -1)
  }
  return formatted
}
