import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  cancel_success_message: {
    id: 'towingRecord.cancel.success.notification.message',
    defaultMessage: 'Towing record canceled',
  },
  cancel_success_title: { id: 'towingRecord.cancel.success.notification.title', defaultMessage: 'Success' },
  cancel_error_message: {
    id: 'towingRecord.cancel.error.notification.message',
    defaultMessage: 'Towing record not canceled',
  },
  cancel_error_title: { id: 'towingRecord.cancel.error.notification.title', defaultMessage: 'Error' },
})
