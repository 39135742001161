import { useEffect, useState } from 'react'
import { Maybe } from '../../common/types'

export interface LocationData extends Coordinates {
  readonly timestamp: number | null
  readonly error: Maybe<GeolocationError>
}

enum GeolocationErrorCode {
  API_UNAVAILABLE,
  PERMISSION_DENIED,
  POSITION_UNAVAILABLE,
  TIMEOUT,
}

interface GeolocationError {
  readonly code: number
  readonly message: string
}

type GeolocationType = 'current' | 'watch' | 'disabled'

export const useGeolocation = (
  type: GeolocationType = 'disabled',
  { enableHighAccuracy = true, maximumAge = 0, timeout = 5000 }: PositionOptions = {
    enableHighAccuracy: true,
    maximumAge: 0,
    timeout: 5000,
  }
) => {
  const [coordinates, setCoordinates] = useState<LocationData>({
    accuracy: 0,
    altitude: null,
    altitudeAccuracy: null,
    heading: null,
    latitude: 0,
    longitude: 0,
    speed: null,
    timestamp: null,
    error: null,
  })

  useEffect(() => {
    let didCancel: boolean = false
    const updateCoordinates = ({ coords, timestamp }: Position) => {
      const { accuracy, altitude, altitudeAccuracy, heading, latitude, longitude, speed } = coords
      if (!didCancel) {
        setCoordinates({
          accuracy,
          altitude,
          altitudeAccuracy,
          heading,
          latitude,
          longitude,
          speed,
          timestamp,
          error: null,
        })
      }
    }

    const setError = (error: GeolocationError) => {
      if (!didCancel) {
        setCoordinates({
          accuracy: 0,
          altitude: null,
          altitudeAccuracy: null,
          heading: null,
          latitude: 0,
          longitude: 0,
          speed: null,
          timestamp: null,
          error,
        })
      }
    }

    let watchId: Maybe<number> = null
    if (type !== 'disabled') {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(updateCoordinates, setError, {
          enableHighAccuracy,
          maximumAge,
          timeout,
        })
        if (type === 'watch') {
          watchId = navigator.geolocation.watchPosition(updateCoordinates, setError, {
            enableHighAccuracy,
            maximumAge,
            timeout,
          })
        }
      } else {
        setError({ code: GeolocationErrorCode.API_UNAVAILABLE, message: 'No navigator.geolocation available' })
      }
    }
    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId)
      }
      didCancel = true
    }
  }, [enableHighAccuracy, maximumAge, timeout, type])

  return coordinates
}
