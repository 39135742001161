import styled, { css } from 'styled-components'

interface Props {
  type: 'info' | 'error' | 'warning'
}

export const Notification = styled.div<Props>`
  position: absolute;
  ${props =>
    props &&
    props.type === 'error' &&
    css`
      color: ${props.theme.colors.red700};
      font-weight: 700;
    `}
`
