import { format } from 'date-fns'
import { find } from 'lodash'

import { Maybe, Time, TowingRecordRouteType, TowingRecordType } from '../../../../../../common/types'
import { SosIncidentCause, SosServiceOrder, sosCauseFilters } from '../../../../../../components/SosSubForm/types'
import { createNewRoute } from '../../createNewRoute'
import { TowingRecordWithRoutes } from '../../types'
import { ArrivalRoute, EditArrivalRouteFormValues } from './types'

export function getInitialValues(
  towingRecord: TowingRecordWithRoutes,
  batteryOption: Maybe<string>
): EditArrivalRouteFormValues {
  const routes = towingRecord.routes
  const routeFound = find(routes, ['type', TowingRecordRouteType.arrival])

  const route =
    routeFound && routeFound.type === TowingRecordRouteType.arrival
      ? ({ ...routeFound } as ArrivalRoute)
      : (createNewRoute(TowingRecordRouteType.arrival, true) as ArrivalRoute)

  if (!route.arrivalDate) {
    route.arrivalDate = new Date()
  }

  if (!route.arrivalTime) {
    route.arrivalTime = (format(new Date(), 'HH:mm') as unknown) as Time
  }

  let alValues = null
  let sosValues = null

  if (towingRecord.automobileAndTouringClubFinland && towingRecord.type === TowingRecordType.autoliitto) {
    const estimatedTimeOfCompletion =
      towingRecord.automobileAndTouringClubFinland.jobTimestamps.estimatedTimeOfCompletion

    alValues = {
      estimatedTimeOfCompletion: {
        date: estimatedTimeOfCompletion ? estimatedTimeOfCompletion.date : null,
        time: estimatedTimeOfCompletion ? estimatedTimeOfCompletion.time : null,
      },
      arcCodes: towingRecord.automobileAndTouringClubFinland.arcCodes,
      vehicleMileage: towingRecord.automobileAndTouringClubFinland.vehicleMileage,
      batteryService: {
        deliveredBattery: towingRecord.automobileAndTouringClubFinland.batteryService.deliveredBattery,
        paymentType: towingRecord.automobileAndTouringClubFinland.batteryService.paymentType,
        battery: batteryOption,
      },
    }
  }

  if (towingRecord.sosServiceOrder && towingRecord.type === TowingRecordType.sos) {
    const completedAtBreakdownLocation = towingRecord.sosServiceOrder.jobTimestamps.completedAtBreakdownLocation
    const serviceOrder = towingRecord.sosServiceOrder

    sosValues = {
      actualTimeOfCompletion: {
        date: completedAtBreakdownLocation ? completedAtBreakdownLocation.date : new Date(),
        time: completedAtBreakdownLocation ? completedAtBreakdownLocation.time : null,
      },
      vehicleMileage: towingRecord.sosServiceOrder.vehicleMileage,
      vehicleRegNo: towingRecord.vehicleDetails.registrationNumber,
      additionalInfo: '',
      keyLocation: towingRecord.sosServiceOrder.vehicleKeyLocation,
      currentLocation: {
        coords: {
          lat: route && route.coords ? route.coords.lat : null,
          long: route && route.coords ? route.coords.long : null,
        },
        address: route && route.address ? route.address : '',
        city: route && route.city ? route.city : '',
        zipcode: route && route.zipcode ? route.zipcode : '',
      },
      cause: setCauseByDeliverable(serviceOrder),
      deliverable: serviceOrder.deliverable || serviceOrder.estimatedServiceType,
    }

    route.finishedTime = sosValues.actualTimeOfCompletion.time
    route.finishedDate = sosValues.actualTimeOfCompletion.date as Maybe<Date> // Fix
  }

  return {
    arrivalRoute: route,
    id: towingRecord.id,
    automobileAndTouringClubFinland: alValues,
    sosServiceOrder: sosValues,
    notes: towingRecord.jobDetails.notes,
    serviceType: towingRecord.jobDetails.serviceType,
    towingReason: towingRecord.jobDetails.towingReason,
    vehicleRegNo: towingRecord.vehicleDetails.registrationNumber,
    additionalInfo: towingRecord.sosServiceOrder ? '' : towingRecord.jobDetails.additionalInfo,
    deliverable: null,
    cause: null
  }
}
/**
 * Set the cause if the cause is consistent with sos tabel for serviceType-->serviceCause relation
 * @param serviceOrder
 */
const setCauseByDeliverable: (serviceOrder: SosServiceOrder) => Maybe<SosIncidentCause> = serviceOrder => {
  const cause = serviceOrder.cause || serviceOrder.estimatedCause
  const deliverable = serviceOrder.deliverable || serviceOrder.estimatedServiceType

  if (!deliverable) return null

  const relevantCauseList =
    deliverable && Object.keys(sosCauseFilters).includes(deliverable as string)
      ? sosCauseFilters[deliverable as string]
      : null

  if (cause && relevantCauseList && relevantCauseList.includes(cause as string)) {
    return cause
  }

  return null
}
