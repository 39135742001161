import React, { FunctionComponent } from 'react'
import { InjectedIntlProps, defineMessages, injectIntl } from 'react-intl'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { NotificationDelay } from '../../common/types'
import { Icon } from '../icons/Icon'
import { Container, Content, IconWrapper, Message, Title } from './styles'
import { dismissToast } from '.'

const toastId = 'status_change_forbidden'

const messages = defineMessages({
  statusChangeForbiddenTitle: {
    id: 'containers.graphql.status_change_forbidden.title',
    defaultMessage: 'Status change forbidden',
  },
  statusChangeForbiddenMessage: {
    id: 'containers.graphql.status_change_forbidden.message',
    defaultMessage: 'Status may have already changed',
  },
})

export const setStatusChangeForbiddenNotification = (message: string) => {
  if (!toast.isActive(toastId)) {
    toast.error(<StatusChangeForbiddenNotification message={message} />, {
      toastId,
      autoClose: false,
      closeButton: false,
      delay: 0,
    })
    dismissToast(toastId, NotificationDelay.statusChangeForbiddenNotificationDelay)
  }
}

interface Props {
  message: string
}

const StatusChangeForbiddenNotificationIntl: FunctionComponent<InjectedIntlProps & Props> = ({ intl, message }) => {
  const { formatMessage } = intl

  return (
    <StatusChangeForbiddenNotificationContainer>
      <IconWrapper>
        <Icon size="big" icon={'lock'} color="red900" />
      </IconWrapper>
      <Content>
        <Title>{formatMessage(messages.statusChangeForbiddenTitle)}</Title>
        <Message>{message}</Message>
      </Content>
    </StatusChangeForbiddenNotificationContainer>
  )
}

const StatusChangeForbiddenNotificationContainer = styled(Container)`
  background-color: ${props => props.theme.colors.red100};
  color: ${props => props.theme.colors.red900};
  border-bottom: 1px solid ${props => props.theme.colors.red500};
`

export const StatusChangeForbiddenNotification = injectIntl(StatusChangeForbiddenNotificationIntl)
