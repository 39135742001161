import { Billing } from '../../../EditTowingRecord/types'
import { TowingRecordPaymentType } from '../../../../../common/types'

export function shouldShowBillingConfirmation(billing: Billing[] | null): boolean {
  if (!billing) {
    return false
  }

  const payment: boolean[] = billing.map((billing: Billing) => {
    if (billing.type === TowingRecordPaymentType.card || billing.type === TowingRecordPaymentType.cash) {
      if (billing.paymentDetails && 'isConfirmed' in billing.paymentDetails) {
        return !billing.paymentDetails.isConfirmed
      }
    }
    return false
  })

  if (payment && payment.includes(true)) return true

  return false
}
