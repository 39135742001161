import React, { useEffect, useState } from 'react'
import { FinishRouteAlProps } from '../../../../../../../components/SosSubForm/FinishRoute/types'
import { CollapseContent } from '../../../../../../../components/Collapse/CollapseContent'
import { RouteColumn, RouteRow } from '../../../../../../../components/layout'
import { NumberInput, SelectInput, TextInput } from '../../../../../../../components/layout/form/input'
import { FinishedDateTimeInput } from '../../../../../../../components/layout/form/input/DateTimeInput'
import { format } from 'date-fns'
import { Time } from '@google/maps'
import { TowingRecordJobStatus } from '../../../../../../../common/types'
import {
  arcComponentOptions,
  arcDescriptionOptions,
  paymentTypeOptions,
} from '../../../../../../../components/ALSubForm/options'

export const FinishedAtDestinationFormAL: React.FunctionComponent<Omit<FinishRouteAlProps, 'autoliittoFields'>> = ({
  setValue,
  action,
  getValue,
  translatedServiceTypeOptions,
  batteryServiceOptions,
}) => {
  const [edit, setEdit] = useState<boolean>(action === 'edit-finished')

  useEffect(() => {
    if (action === 'edit-finished') {
      if (!edit) setEdit(true)
    } else {
      if (edit) setEdit(false)
    }
  }, [edit, action, setEdit])

  return (
    <>
      <CollapseContent>
        <RouteRow columns={`1fr 1fr 1fr`}>
          <RouteColumn>
            <SelectInput
              label="Toimenpide"
              name="serviceType"
              options={translatedServiceTypeOptions}
              disabled
              required
            />
          </RouteColumn>
          <RouteColumn>
            <SelectInput
              label="Vian syy (ARC-vikakoodi)"
              name="automobileAndTouringClubFinland.arcCodes.description.code"
              options={arcDescriptionOptions}
              disabled={!edit}
              required
            />
          </RouteColumn>
          <RouteColumn>
            <FinishedDateTimeInput
              name="finishRoute"
              label="Valmistumisaika"
              customOnChange={() => {
                const value = (format(new Date(), 'HH:mm') as unknown) as Time
                setValue('finishRoute.finishedTime', value)
                setValue('finishRoute.finishedDate', new Date())
              }}
              required
              disabled={!edit}
              showCurrentTimeBtn
            />
          </RouteColumn>
        </RouteRow>
        <RouteRow columns={'1fr 1fr 1fr'}>
          <RouteColumn>
            <SelectInput
              label="Komponentti (ARC-vikakoodi)"
              name="automobileAndTouringClubFinland.arcCodes.component.code"
              options={arcComponentOptions}
              required
              disabled={!edit}
            />
          </RouteColumn>
          <RouteColumn>
            <NumberInput
              label="Mittarilukema, km"
              name="automobileAndTouringClubFinland.vehicleMileage"
              decimals={0}
              required
              disabled={!edit}
            />
          </RouteColumn>
        </RouteRow>
        {getValue('serviceType') === 'battery_service' && (
          <>
            <RouteRow columns="1fr 1fr 1fr">
              <RouteColumn>
                <SelectInput
                  label="Toimitettu akku"
                  name="automobileAndTouringClubFinland.batteryService.battery"
                  options={batteryServiceOptions}
                  required
                  searchable
                  onChange={e => {
                    if (e !== 'other') {
                      setValue('automobileAndTouringClubFinland.batteryService.deliveredBattery', e)
                    } else {
                      setValue('automobileAndTouringClubFinland.batteryService.deliveredBattery', '')
                    }
                  }}
                />
              </RouteColumn>
              <RouteColumn>
                <SelectInput
                  label="Asiakkaan maksutapa"
                  name="automobileAndTouringClubFinland.batteryService.paymentType"
                  options={paymentTypeOptions}
                  required
                  disabled
                />
              </RouteColumn>
              {getValue('automobileAndTouringClubFinland.batteryService.battery') === 'other' && (
                <RouteColumn>
                  <TextInput
                    label="Toimitettu akku, muu"
                    name="automobileAndTouringClubFinland.batteryService.deliveredBattery"
                    required
                    disabled
                  />
                </RouteColumn>
              )}
            </RouteRow>
          </>
        )}
      </CollapseContent>
    </>
  )
}

export const finishedAtDestinationEditableJobStatuses = [TowingRecordJobStatus.finished_at_destination]
