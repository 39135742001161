import { Maybe } from '../../../../common/types'
import { CancelOrEndTowingRecordFormValues } from '../../components/CancelTowingRecord/types'
import { toALScalarTimestamp } from '../../components/routes/subforms/ArrivalRoute/mutation/toMutationVariables'
import { EndJobMutationVariables } from './types'

export const toMutationVariables = (
  inputValue: any,
  readyForPayment: boolean,
  values: Maybe<CancelOrEndTowingRecordFormValues>
): EndJobMutationVariables => {
  return {
    input: {
      readyForPayment,
      id: inputValue.id,
      jobEndedAt: values ? toALScalarTimestamp(values.jobEndedAt) : null,
    },
  }
}
