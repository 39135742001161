import React from 'react'
import { Maybe, TowingRecordJobStatus, TowingRecordType } from '../../../../../../../common/types'
import { FinishRouteAction } from '../../../../../../../components/SosSubForm/FinishRoute/types'
import { GeoLocateAddressData } from '../../../types'
import { ArrivedAtBreakdownLocationForm } from './ArrivedAtBreakdownLocationForm'
import { ArrivedAtBreakdownLocationFormAL } from './ArrivedAtBreakdownLocationFormAL'

export interface ArrivedAtBreakdownLocationProps {
  setValue: (field: string, value: any) => void
  getValue: any
  setDateField: (fieldName: string) => void
  setRouteLocation: (addressData: GeoLocateAddressData | null) => void
  clear: () => void
  action: FinishRouteAction
  setAction: (action: FinishRouteAction) => void
  jobStatus: TowingRecordJobStatus
  type: TowingRecordType
}

export interface defaultProps extends ArrivedAtBreakdownLocationProps {}

export interface SosProps extends ArrivedAtBreakdownLocationProps {}

export interface ALProps extends ArrivedAtBreakdownLocationProps {
  stationId: Maybe<number>
}

export const ArrivedForm = ({ type, ...props }: ArrivedAtBreakdownLocationProps | ALProps) => {
  if (type === TowingRecordType.sos) return <ArrivedAtBreakdownLocationForm {...(props as SosProps)} />
  if (type === TowingRecordType.autoliitto) return <ArrivedAtBreakdownLocationFormAL {...(props as ALProps)} />
  if (type === TowingRecordType.default) return <ArrivedAtBreakdownLocationForm {...(props as defaultProps)} />
  return null
}
