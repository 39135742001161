import React from 'react'
import { SimpleRow } from '../layout'
import { NavigateTo } from '../NavigateTo'
import styled from 'styled-components'

const StyledRow = styled(SimpleRow)`
  margin: 0.5rem 1rem 0 0.5rem;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    margin: 0.5rem 0.5rem 0 0;
  }
`

interface TopNavigationProps {
  editAction?: () => void
  address?: string
  slug?: string
  editLabel?: string
  editAddress?: string
  label?: string
  onNavigate?: () => void
  disableEdit?: boolean 
}

export const TopNavigation: React.FunctionComponent<TopNavigationProps> = ({
  address,
  editAction,
  editAddress,
  editLabel,
  label,
  onNavigate,
  slug = '',
  disableEdit
}) => {
  const completeSlug: string = `/towing-jobs/${slug}`
  return (
    <StyledRow justify="space-between">
      <NavigateTo
        direction="back"
        label={label ? label : 'Takaisin'}
        slug={address || completeSlug}
        onNavigate={onNavigate}
      />
      {editAction != null && editAddress && (disableEdit === false || typeof disableEdit === 'undefined') && (
        <NavigateTo direction="forward" label={editLabel ? editLabel : 'Muokkaa'} slug={editAddress} />
      )}
    </StyledRow>
  )
}
