import emailValidator from 'email-validator'
import { Field } from 'formik'
import React, { useCallback } from 'react'

import { EmailField } from '../../field'

interface Props {
  name: string
  label: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
}

export function EmailInput(props: Props): JSX.Element {
  const { name, label, placeholder, required, disabled } = props

  const validate = useCallback(
    (value: string | null): string | undefined => {
      if (required && (!value || !value.trim())) {
        return 'kenttä on pakollinen'
      }

      if (value && !emailValidator.validate(value)) {
        return 'Virheellinen sähköpostiosoite'
      }

      return undefined
    },
    [required]
  )

  return (
    <Field
      disabled={disabled}
      component={EmailField}
      id={name}
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      validate={validate}
    />
  )
}
