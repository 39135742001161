import { Maybe, User } from '../../common/types'
import { Operator } from './types'

export function getOperatorLabelFromId(
  operatorId: Maybe<number>,
  user: Maybe<User>,
  operators: Maybe<Operator[]>
): string {
  if (operatorId && user && operators) {
    if (operatorId !== user.userId) {
      const operator = operators.find(operator => operatorId === operator.id)
      return operator ? operator.name : ''
    } else {
      return 'Minä'
    }
  }

  return ''
}
