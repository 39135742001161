import React from 'react'
import styled from 'styled-components'

import { RadioInput } from '../index'
import { FieldContainer, Label } from '../../style'

interface Props {
  label: string
  name?: string
  options: Option[]
  disabled?: boolean
  justifyContent?: string
}

interface Option {
  label: string
  value: string
}

interface ButtonGroupProps {
  justifyContent?: string
}

const ButtonGroup = styled.div<ButtonGroupProps>`
  display: flex;
  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'flex-end')};
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    justify-content: flex-start;
    margin-top: 0.25rem;
  }
`

export function RadioButtonGroup(props: Props): JSX.Element {
  const { label, options, name, disabled, justifyContent } = props

  return (
    <FieldContainer>
      {label && <Label>{label}</Label>}
      <ButtonGroup justifyContent={justifyContent}>
        {options.map(option => {
          return (
            <RadioInput label={option.label} id={option.value} name={name} key={option.value} disabled={disabled} />
          )
        })}
      </ButtonGroup>
    </FieldContainer>
  )
}
