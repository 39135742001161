import { Field } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'


interface Props {
  label?: string
  inputValue: boolean
  onChange: (value: boolean) => void
}

export const CheckboxToggle: React.FunctionComponent<Props> = ({inputValue, label, onChange }) => {
  const [value, setValue] = useState<boolean>(inputValue)
  useEffect(() => {
    setValue(inputValue)
  }, [inputValue])
  const handleChange = useCallback(
    event => {
      const checked = event.target.checked
      setValue(checked);
      console.log('checked: ',checked);
      onChange(checked);
    },
    [onChange]
  )

  return (
    <CheckboxContainer>
      <Field
        component={Checkbox}
        type="checkbox"
        checked={value}
        onChange={handleChange}
      />
      {label && (
        <CheckboxLabel
          onClick={() => {
            handleChange({ target: { checked: !value } })
          }}
        >
          {label}
        </CheckboxLabel>
      )}
    </CheckboxContainer>
  )
}

const Checkbox = styled.input`
  appearance: none;
  margin: 0;
  padding: 0;
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  border: 1px solid ${props => props.theme.colors.grey300};
  border-radius: ${props => props.theme.radius.medium};
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  &:checked:after {
    content: '\\2714';
    font-size: 1.25rem;
    color: ${props => props.theme.colors.black};
  }
`

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
`

const CheckboxLabel = styled.div`
  margin: 0 0 0 1rem;
  padding: 0;
  font-size: 1rem;
  cursor: pointer;
`
