import gql from 'graphql-tag'

import { DateTime, Maybe, Options } from '../../common/types'
import { ScalarDate, ScalarTime } from '../../graphql-client/types'

interface ALTimestampInput {
  date: Maybe<ScalarDate>
  time: Maybe<ScalarTime>
}

export interface SosServiceOrder {
  jobId: Maybe<string>
  caseNumber: string
  priority: string
  agreementName: Maybe<string>
  estimatedCause: Maybe<SosIncidentCause>
  estimatedServiceType: Maybe<SosServiceOrderType>
  incidentDescription: string
  situationDescription: Maybe<string>
  personCount: Maybe<number>
  vehicleKeyLocation: string
  serviceType: Maybe<string>
  serviceDescription: Maybe<string>
  cause: Maybe<SosIncidentCause>
  detailedCause: Maybe<string>
  deliverable: Maybe<SosServiceOrderType>
  insuranceCompany: Maybe<string>
  isPreOrder: boolean
  vehicleMileage: Maybe<number>
  towingDateAndTime: Maybe<DateTime>
  orderStatus: Maybe<string>
  preOrder: {
    beginEarliestAt: Maybe<DateTime>
    beginLatestAt: Maybe<DateTime>
  }
  jobTimestamps: SosServiceOrderJobTimestamps
  originatorApp: Maybe<string>
}

export const initialSosServiceOrder = {
  jobId: null,
  caseNumber: '',
  priority: '',
  agreementName: null,
  estimatedCause: null,
  estimatedServiceType: null,
  incidentDescription: '',
  situationDescription: null,
  personCount: null,
  vehicleKeyLocation: '',
  serviceType: null,
  serviceDescription: null,
  cause: null,
  detailedCause: null,
  deliverable: null,
  insuranceCompany: null,
  isPreOrder: false,
  vehicleMileage: null,
  orderStatus: '',
  towingDateAndTime: {
    date: new Date(),
    time: null,
  },
  preOrder: {
    beginEarliestAt: null,
    beginLatestAt: null,
  },
  jobTimestamps: {
    onHoldUntil: null,
    estimatedTimeOfArrival: null,
    arrivedAtBreakdownLocation: null,
    arrivedAtDestination: null,
    completedAtBreakdownLocation: null,
    completedAtDestination: null,
  },
  originatorApp: 'InnoHinaus',
}

export interface SosServiceOrderMutationInput {
  jobId: Maybe<string>
  caseNumber: string
  priority: string
  agreementName: Maybe<string>
  estimatedCause: Maybe<SosIncidentCause>
  estimatedServiceType: Maybe<SosServiceOrderType>
  incidentDescription: string
  situationDescription: Maybe<string>
  personCount: Maybe<number>
  vehicleKeyLocation: string
  serviceType: Maybe<string>
  serviceDescription: Maybe<string>
  cause: Maybe<SosIncidentCause>
  detailedCause: Maybe<string>
  deliverable: Maybe<SosServiceOrderType>
  insuranceCompany: Maybe<string>
  isPreOrder: boolean
  vehicleMileage: Maybe<number>
  towingDateAndTime: Maybe<ALTimestampInput>
  jobTimestamps: {
    onHoldUntil: Maybe<ALTimestampInput>
    estimatedTimeOfArrival: Maybe<ALTimestampInput>
    arrivedAtBreakdownLocation: Maybe<ALTimestampInput>
    arrivedAtDestination: Maybe<ALTimestampInput>
    completedAtBreakdownLocation: Maybe<ALTimestampInput>
    completedAtDestination: Maybe<ALTimestampInput>
  }
  preOrder: {
    beginEarliestAt: Maybe<ALTimestampInput>
    beginLatestAt: Maybe<ALTimestampInput>
  }
  originatorApp: Maybe<string>
}

export interface SosServiceOrderJobTimestamps {
  onHoldUntil: Maybe<DateTime>
  estimatedTimeOfArrival: Maybe<DateTime>
  arrivedAtBreakdownLocation: Maybe<DateTime>
  arrivedAtDestination: Maybe<DateTime>
  completedAtBreakdownLocation: Maybe<DateTime>
  completedAtDestination: Maybe<DateTime>
  started?: Maybe<DateTime>
  stopped?: Maybe<DateTime>
}

export enum SosFinishRouteType {
  REPAIR_SHOP = 'repair_shop',
  ADDRESS = 'address',
}

export enum SosServiceOrderType {
  'Towage' = 'towage',
  'Salvage' = 'salvage',
  'Pull free' = 'pull_free',
  'Start car' = 'start_car',
  'Clean up' = 'clean_up',
  'Other' = 'other',
  'Repair' = 'repair',
  'Change of wheel' = 'change_of_wheel',
  'Opening of door' = 'opening_of_door',
  'Delivery of fuel' = 'delivery_of_fuel',
  'Emergency repair/Towing' = 'emergency_repair_towing',
  'Home Transport' = 'home_transport',
  'Scrapping' = 'scrapping',
  'Towing - 2. jumpstart' = 'jumpstart',
  'Key insurance' = 'key_insurance',
}

export enum SosIncidentCause {
  'Acute illness' = 'acute_illness',
  'Other' = 'other',
  'Red Light' = 'red_light',
  'Yellow Light' = 'yellow_light',
  'Battery defect' = 'battery_defect',
  'Bone fracture' = 'bone_fracture',
  'Brakes defect' = 'brakes_defect',
  'Chest region' = 'chest_region',
  'Building damage' = 'building_damage',
  'Tire exploded' = 'tire_exploded',
  'Door damaged' = 'door_damaged',
  'Electronic failure' = 'electronic_failure',
  'Epilepsy' = 'epilepsy',
  'Ethylene (alcohol)' = 'ethylene_alcohol',
  'Evacuation' = 'evacuation',
  'Traffic accident' = 'traffic_accident',
  'Fever' = 'fever',
  'Fence alarm' = 'fence_alarm',
  'Birth' = 'birth',
  'Burning' = 'burning',
  'Inquiry' = 'inquiry',
  'Poisoning' = 'poisoning',
  'Wrong fuel' = 'wrong_fuel',
  'Pollution' = 'pollution',
  'Found after theft' = 'found_after_theft',
  'Stalled while driving' = 'stalled_while_driving',
  'Gear defect' = 'gear_defect',
  'Glass damage' = 'glass_damage',
  'Vandalism' = 'vandalism',
  'Reference' = 'reference',
  'Heart/lung' = 'heart_lung',
  'Wheel/suspension defect' = 'wheel_suspension_defect',
  'Parked with light on ' = 'parked_with_light_on',
  'Parked with radio on' = 'parked_with_radio_on',
  'Hydraulic defect' = 'hydraulic_defect',
  'Burglary' = 'burglary',
  'Hospitalization' = 'hospitalization',
  'Can not start' = 'can_not_start',
  'Clutch defect' = 'clutch_defect',
  'Cooler defect' = 'cooler_defect',
  'Transportation of veterinary' = 'transportation_of_veterinary',
  'Transportation of medical records' = 'transportation_of_medical_records',
  'Transportation of organ' = 'transportation_of_organ',
  'Transportation of equipment' = 'transportation_of_equipment',
  'Stuck in snow' = 'stuck_in_snow',
  'Stuck on field/grass' = 'stuck_on_field_grass',
  'Stuck by road' = 'stuck_by_road',
  'Curbs collision' = 'curbs_collision',
  'Run out of fuel' = 'run_out_of_fuel',
  'Key missing' = 'key_missing',
  'Key broken' = 'key_broken',
  'Key locked in car' = 'key_locked_in_car',
  'Transportation of person' = 'transportation_of_person',
  'Flat tire' = 'flat_tire',
  'Skull/head' = 'skull_head',
  'Storm damage' = 'storm_damage',
  'Power Failure' = 'power_failure',
  'Steering defect' = 'steering_defect',
  'Pills/drugs' = 'pills_drugs',
  'Lost cargo' = 'lost_cargo',
  'Ignition lock defect' = 'ignition_lock_defect',
  'Dental problem' = 'dental_problem',
  'Call out' = 'call_out',
  'Water damage' = 'water_damage',
  'Engine failure' = 'engine_failure',
  'Fire' = 'fire',
  'Light/lenses' = 'light_lenses',
  'Exhaust' = 'exhaust',
  'Emergeny slaughter' = 'emergeny_slaughter',
  'Salvation of machinery' = 'salvation_of_machinery',
  'Transportation of animals' = 'transportation_of_animals',
  'Hometransport after repair' = 'hometransport_after_repair',
  'Nearest suitable workshop' = 'nearest_suitable_workshop',
  'Collision with animal' = 'collision_with_animal',
  'Further transport or overnight stay' = 'further_transport_or_overnight_stay',
  'Theft' = 'theft',
  'Mechanical damage' = 'mechanical_damage',
  'Call on old case' = 'call_on_old_case',
  'Damaged due to floow' = 'damaged_due_to_floow',
  'Flat tire with kit' = 'flat_tire_with_kit',
  'Flat tire with spare tire' = 'flat_tire_with_spare_tire',
  'Lost' = 'lost',
  'Lock defect' = 'lock_defect',
  'Frozen' = 'frozen',
  'Technical Inquiry ' = 'technical_inquiry',
  'Moving of vehicle' = 'moving_of_vehicle',
  'Reposessed' = 'reposessed',
  'Cleanup' = 'cleanup',
  'Traffic regulation' = 'traffic_regulation',
  'Oil spill cleanup' = 'oil_spill_cleanup',
  'Cleanup of road' = 'cleanup_of_road',
  'Emergency move' = 'emergency_move',
  'Parked at inconvenience' = 'parked_at_inconvenience',
  'Illness' = 'illness',
  'Stolen plate(s)' = 'stolen_plates',
  'Home transport' = 'home_transport',
  'Cancelled' = 'cancelled',
  'Tyre kit if possible - or tow' = 'tyre_kit_if_possible_or_tow',
  'Towing' = 'towing',
  'Cust. Referred to Servicemobil' = 'cust_referred_to_servicemobil',
  'No heat' = 'no_heat',
  'Hometransport delayed > 3days' = 'hometransport_delayed_3days',
  'Hometransport > 1 day (PH work)' = 'hometransport_1_day_ph_work',
  'Detour > 300km' = 'detour_300km',
  'Insurance company decision' = 'insurance_company_decision',
  'Flat tire without kit' = 'flat_tire_without_kit',
  'Repair over 3 hours' = 'repair_over_3_hours',
  'Cheapest travel home' = 'cheapest_travel_home',
  'Def. windshield wiper' = 'def_windshield_wiper',
  'Elevator defect' = 'elevator_defect',
  'Transport' = 'transport',
  'Collision with deer' = 'collision_with_deer',
  'Damaged' = 'damaged',
  'Help to use TMS' = 'help_to_use_tms',
  'Help with spare tyre' = 'help_with_spare_tyre',
  'Start of car (lacking electricity)' = 'start_of_car_lacking_electricity',
  'Open door/tailgate etc.' = 'open_door_tailgate_etc',
  'Adding oil or water' = 'adding_oil_or_water',
  'Driven into ditch' = 'driven_into_ditch',
  'Accident with counterpart and mors' = 'accident_with_counterpart_and_mors',
  'Solo accident with mors' = 'solo_accident_with_mors',
  'Accident with counterpart and injury' = 'accident_with_counterpart_and_injury',
  'Accident solo' = 'accident_solo',
  'Accident with counterpart' = 'accident_with_counterpart',
  'Fire Damage' = 'fire_damage',
  'Refund' = 'refund',
  'Emergency' = 'emergency',
  'Death' = 'death',
  'Burglary/theft' = 'burglary_theft',
  'Crash' = 'crash',
  'Accident' = 'accident',
  'Dont want to use TMS' = 'dont_want_to_use_tms',
  'Run out of electricity' = 'run_out_of_electricity',
  'Covered by mobility' = 'covered_by_mobility',
  'Case created in customers system' = 'case_created_in_customers_system',
  'Flat battery' = 'flat_battery',
  'Leaking coolant' = 'leaking_coolant',
  'Towing acc. to workshop' = 'towing_acc_to_workshop',
  'Engine shield' = 'engine_shield',
  'Natural perils' = 'natural_perils',
  'Refusal' = 'refusal',
  'External impact' = 'external_impact',
  'Generel Information' = 'generel_information',
  'Scrapping' = 'scrapping',
  'Change battery' = 'change_battery',
  'Grounded' = 'grounded',
  'Sunk' = 'sunk',
  'Theft of engine' = 'theft_of_engine',
  'Food/freezer' = 'food_freezer',
  'Liability' = 'liability',
  'Arrived late' = 'arrived_late',
  'Delayed' = 'delayed',
  'Electric vehicle for recharging' = 'electric_vehicle_for_recharging',
  'bCall - Transferred to mobility' = 'bcall_transferred_to_mobility',
  'Error' = 'error',
  'Accident - Transferred to 112' = 'accident_transferred_to_112',
  'Accident - Transferred to mobility' = 'accident_transferred_to_mobility',
  'Discovery call' = 'discovery_call',
  'Breakdown request' = 'breakdown_request',
  'Claims Report' = 'claims_report',
  'Chassis' = 'chassis',
  'Transmission fault' = 'transmission_fault',
  'Handling fee' = 'handling_fee',
  'Immobiliser defect' = 'immobiliser_defect',
  'Ventilation or Heating defect' = 'ventilation_or_heating_defect',
  'Fuel system defect' = 'fuel_system_defect',
  'Driven into ditch avoiding deer' = 'driven_into_ditch_avoiding_deer',
  'Driven into ditch avoiding other animal' = 'driven_into_ditch_avoiding_other_animal',
  'Workshop costs' = 'workshop_costs',
  'Storage fees' = 'storage_fees',
  'Travel illness' = 'travel_illness',
  'Travel injury' = 'travel_injury',
  'Interruption' = 'interruption',
  'Insurance certificate' = 'insurance_certificate',
}

export const serviceTypeOptions: Options = {
  options: {
    towage: 'sosServiceOrder.type.towage',
    salvage: 'sosServiceOrder.type.salvage',
    pull_free: 'sosServiceOrder.type.pull_free',
    start_car: 'sosServiceOrder.type.start_car',
    clean_up: 'sosServiceOrder.type.clean_up',
    other: 'sosServiceOrder.type.other',
    repair: 'sosServiceOrder.type.repair',
    change_of_wheel: 'sosServiceOrder.type.change_of_wheel',
    opening_of_door: 'sosServiceOrder.type.opening_of_door',
    delivery_of_fuel: 'sosServiceOrder.type.delivery_of_fuel',
    emergency_repair_towing: 'sosServiceOrder.type.emergency_repair_towing',
    home_transport: 'sosServiceOrder.type.home_transport',
    scrapping: 'sosServiceOrder.type.scrapping',
    jumpstart: 'sosServiceOrder.type.jumpstart',
    key_insurance: 'sosServiceOrder.type.key_insurance',
  },
}

export const incidentCauseOptions: Options = {
  options: {
    acute_illness: 'sosServiceOrder.cause.acute_illness',
    other: 'sosServiceOrder.cause.other',
    red_light: 'sosServiceOrder.cause.red_light',
    yellow_light: 'sosServiceOrder.cause.yellow_light',
    battery_defect: 'sosServiceOrder.cause.battery_defect',
    bone_fracture: 'sosServiceOrder.cause.bone_fracture',
    brakes_defect: 'sosServiceOrder.cause.brakes_defect',
    chest_region: 'sosServiceOrder.cause.chest_region',
    building_damage: 'sosServiceOrder.cause.building_damage',
    tire_exploded: 'sosServiceOrder.cause.tire_exploded',
    door_damaged: 'sosServiceOrder.cause.door_damaged',
    electronic_failure: 'sosServiceOrder.cause.electronic_failure',
    epilepsy: 'sosServiceOrder.cause.epilepsy',
    ethylene_alcohol: 'sosServiceOrder.cause.ethylene_alcohol',
    evacuation: 'sosServiceOrder.cause.evacuation',
    traffic_accident: 'sosServiceOrder.cause.traffic_accident',
    fever: 'sosServiceOrder.cause.fever',
    fence_alarm: 'sosServiceOrder.cause.fence_alarm',
    birth: 'sosServiceOrder.cause.birth',
    burning: 'sosServiceOrder.cause.burning',
    inquiry: 'sosServiceOrder.cause.inquiry',
    poisoning: 'sosServiceOrder.cause.poisoning',
    wrong_fuel: 'sosServiceOrder.cause.wrong_fuel',
    pollution: 'sosServiceOrder.cause.pollution',
    found_after_theft: 'sosServiceOrder.cause.found_after_theft',
    stalled_while_driving: 'sosServiceOrder.cause.stalled_while_driving',
    gear_defect: 'sosServiceOrder.cause.gear_defect',
    glass_damage: 'sosServiceOrder.cause.glass_damage',
    vandalism: 'sosServiceOrder.cause.vandalism',
    reference: 'sosServiceOrder.cause.reference',
    heart_lung: 'sosServiceOrder.cause.heart_lung',
    wheel_suspension_defect: 'sosServiceOrder.cause.wheel_suspension_defect',
    parked_with_light_on: 'sosServiceOrder.cause.parked_with_light_on',
    parked_with_radio_on: 'sosServiceOrder.cause.parked_with_radio_on',
    hydraulic_defect: 'sosServiceOrder.cause.hydraulic_defect',
    burglary: 'sosServiceOrder.cause.burglary',
    hospitalization: 'sosServiceOrder.cause.hospitalization',
    can_not_start: 'sosServiceOrder.cause.can_not_start',
    clutch_defect: 'sosServiceOrder.cause.clutch_defect',
    cooler_defect: 'sosServiceOrder.cause.cooler_defect',
    transportation_of_veterinary: 'sosServiceOrder.cause.transportation_of_veterinary',
    transportation_of_medical_records: 'sosServiceOrder.cause.transportation_of_medical_records',
    transportation_of_organ: 'sosServiceOrder.cause.transportation_of_organ',
    transportation_of_equipment: 'sosServiceOrder.cause.transportation_of_equipment',
    stuck_in_snow: 'sosServiceOrder.cause.stuck_in_snow',
    stuck_on_field_grass: 'sosServiceOrder.cause.stuck_on_field_grass',
    stuck_by_road: 'sosServiceOrder.cause.stuck_by_road',
    curbs_collision: 'sosServiceOrder.cause.curbs_collision',
    run_out_of_fuel: 'sosServiceOrder.cause.run_out_of_fuel',
    key_missing: 'sosServiceOrder.cause.key_missing',
    key_broken: 'sosServiceOrder.cause.key_broken',
    key_locked_in_car: 'sosServiceOrder.cause.key_locked_in_car',
    transportation_of_person: 'sosServiceOrder.cause.transportation_of_person',
    flat_tire: 'sosServiceOrder.cause.flat_tire',
    skull_head: 'sosServiceOrder.cause.skull_head',
    storm_damage: 'sosServiceOrder.cause.storm_damage',
    power_failure: 'sosServiceOrder.cause.power_failure',
    steering_defect: 'sosServiceOrder.cause.steering_defect',
    pills_drugs: 'sosServiceOrder.cause.pills_drugs',
    lost_cargo: 'sosServiceOrder.cause.lost_cargo',
    ignition_lock_defect: 'sosServiceOrder.cause.ignition_lock_defect',
    dental_problem: 'sosServiceOrder.cause.dental_problem',
    call_out: 'sosServiceOrder.cause.call_out',
    water_damage: 'sosServiceOrder.cause.water_damage',
    engine_failure: 'sosServiceOrder.cause.engine_failure',
    fire: 'sosServiceOrder.cause.fire',
    light_lenses: 'sosServiceOrder.cause.light_lenses',
    exhaust: 'sosServiceOrder.cause.exhaust',
    emergeny_slaughter: 'sosServiceOrder.cause.emergeny_slaughter',
    salvation_of_machinery: 'sosServiceOrder.cause.salvation_of_machinery',
    transportation_of_animals: 'sosServiceOrder.cause.transportation_of_animals',
    hometransport_after_repair: 'sosServiceOrder.cause.hometransport_after_repair',
    nearest_suitable_workshop: 'sosServiceOrder.cause.nearest_suitable_workshop',
    collision_with_animal: 'sosServiceOrder.cause.collision_with_animal',
    further_transport_or_overnight_stay: 'sosServiceOrder.cause.further_transport_or_overnight_stay',
    theft: 'sosServiceOrder.cause.theft',
    mechanical_damage: 'sosServiceOrder.cause.mechanical_damage',
    call_on_old_case: 'sosServiceOrder.cause.call_on_old_case',
    damaged_due_to_floow: 'sosServiceOrder.cause.damaged_due_to_floow',
    flat_tire_with_kit: 'sosServiceOrder.cause.flat_tire_with_kit',
    flat_tire_with_spare_tire: 'sosServiceOrder.cause.flat_tire_with_spare_tire',
    lost: 'sosServiceOrder.cause.lost',
    lock_defect: 'sosServiceOrder.cause.lock_defect',
    frozen: 'sosServiceOrder.cause.frozen',
    technical_inquiry: 'sosServiceOrder.cause.technical_inquiry',
    moving_of_vehicle: 'sosServiceOrder.cause.moving_of_vehicle',
    reposessed: 'sosServiceOrder.cause.reposessed',
    cleanup: 'sosServiceOrder.cause.cleanup',
    traffic_regulation: 'sosServiceOrder.cause.traffic_regulation',
    oil_spill_cleanup: 'sosServiceOrder.cause.oil_spill_cleanup',
    cleanup_of_road: 'sosServiceOrder.cause.cleanup_of_road',
    emergency_move: 'sosServiceOrder.cause.emergency_move',
    parked_at_inconvenience: 'sosServiceOrder.cause.parked_at_inconvenience',
    illness: 'sosServiceOrder.cause.illness',
    stolen_plates: 'sosServiceOrder.cause.stolen_plates',
    home_transport: 'sosServiceOrder.cause.home_transport',
    cancelled: 'sosServiceOrder.cause.cancelled',
    tyre_kit_if_possible_or_tow: 'sosServiceOrder.cause.tyre_kit_if_possible_or_tow',
    towing: 'sosServiceOrder.cause.towing',
    cust_referred_to_servicemobil: 'sosServiceOrder.cause.cust_referred_to_servicemobil',
    no_heat: 'sosServiceOrder.cause.no_heat',
    hometransport_delayed_3days: 'sosServiceOrder.cause.hometransport_delayed_3days',
    hometransport_1_day_ph_work: 'sosServiceOrder.cause.hometransport_1_day_ph_work',
    detour_300km: 'sosServiceOrder.cause.detour_300km',
    insurance_company_decision: 'sosServiceOrder.cause.insurance_company_decision',
    flat_tire_without_kit: 'sosServiceOrder.cause.flat_tire_without_kit',
    repair_over_3_hours: 'sosServiceOrder.cause.repair_over_3_hours',
    cheapest_travel_home: 'sosServiceOrder.cause.cheapest_travel_home',
    def_windshield_wiper: 'sosServiceOrder.cause.def_windshield_wiper',
    elevator_defect: 'sosServiceOrder.cause.elevator_defect',
    transport: 'sosServiceOrder.cause.transport',
    collision_with_deer: 'sosServiceOrder.cause.collision_with_deer',
    damaged: 'sosServiceOrder.cause.damaged',
    help_to_use_tms: 'sosServiceOrder.cause.help_to_use_tms',
    help_with_spare_tyre: 'sosServiceOrder.cause.help_with_spare_tyre',
    start_of_car_lacking_electricity: 'sosServiceOrder.cause.start_of_car_lacking_electricity',
    open_door_tailgate_etc: 'sosServiceOrder.cause.open_door_tailgate_etc',
    adding_oil_or_water: 'sosServiceOrder.cause.adding_oil_or_water',
    driven_into_ditch: 'sosServiceOrder.cause.driven_into_ditch',
    accident_with_counterpart_and_mors: 'sosServiceOrder.cause.accident_with_counterpart_and_mors',
    solo_accident_with_mors: 'sosServiceOrder.cause.solo_accident_with_mors',
    accident_with_counterpart_and_injury: 'sosServiceOrder.cause.accident_with_counterpart_and_injury',
    accident_solo: 'sosServiceOrder.cause.accident_solo',
    accident_with_counterpart: 'sosServiceOrder.cause.accident_with_counterpart',
    fire_damage: 'sosServiceOrder.cause.fire_damage',
    refund: 'sosServiceOrder.cause.refund',
    emergency: 'sosServiceOrder.cause.emergency',
    death: 'sosServiceOrder.cause.death',
    burglary_theft: 'sosServiceOrder.cause.burglary_theft',
    crash: 'sosServiceOrder.cause.crash',
    accident: 'sosServiceOrder.cause.accident',
    dont_want_to_use_tms: 'sosServiceOrder.cause.dont_want_to_use_tms',
    run_out_of_electricity: 'sosServiceOrder.cause.run_out_of_electricity',
    covered_by_mobility: 'sosServiceOrder.cause.covered_by_mobility',
    case_created_in_customers_system: 'sosServiceOrder.cause.case_created_in_customers_system',
    flat_battery: 'sosServiceOrder.cause.flat_battery',
    leaking_coolant: 'sosServiceOrder.cause.leaking_coolant',
    towing_acc_to_workshop: 'sosServiceOrder.cause.towing_acc_to_workshop',
    engine_shield: 'sosServiceOrder.cause.engine_shield',
    natural_perils: 'sosServiceOrder.cause.natural_perils',
    refusal: 'sosServiceOrder.cause.refusal',
    external_impact: 'sosServiceOrder.cause.external_impact',
    generel_information: 'sosServiceOrder.cause.generel_information',
    scrapping: 'sosServiceOrder.cause.scrapping',
    change_battery: 'sosServiceOrder.cause.change_battery',
    grounded: 'sosServiceOrder.cause.grounded',
    sunk: 'sosServiceOrder.cause.sunk',
    theft_of_engine: 'sosServiceOrder.cause.theft_of_engine',
    food_freezer: 'sosServiceOrder.cause.food_freezer',
    liability: 'sosServiceOrder.cause.liability',
    arrived_late: 'sosServiceOrder.cause.arrived_late',
    delayed: 'sosServiceOrder.cause.delayed',
    electric_vehicle_for_recharging: 'sosServiceOrder.cause.electric_vehicle_for_recharging',
    bcall_transferred_to_mobility: 'sosServiceOrder.cause.bcall_transferred_to_mobility',
    error: 'sosServiceOrder.cause.error',
    accident_transferred_to_112: 'sosServiceOrder.cause.accident_transferred_to_112',
    accident_transferred_to_mobility: 'sosServiceOrder.cause.accident_transferred_to_mobility',
    discovery_call: 'sosServiceOrder.cause.discovery_call',
    breakdown_request: 'sosServiceOrder.cause.breakdown_request',
    claims_report: 'sosServiceOrder.cause.claims_report',
    chassis: 'sosServiceOrder.cause.chassis',
    transmission_fault: 'sosServiceOrder.cause.transmission_fault',
    handling_fee: 'sosServiceOrder.cause.handling_fee',
    immobiliser_defect: 'sosServiceOrder.cause.immobiliser_defect',
    ventilation_or_heating_defect: 'sosServiceOrder.cause.ventilation_or_heating_defect',
    fuel_system_defect: 'sosServiceOrder.cause.fuel_system_defect',
    driven_into_ditch_avoiding_deer: 'sosServiceOrder.cause.driven_into_ditch_avoiding_deer',
    driven_into_ditch_avoiding_other_animal: 'sosServiceOrder.cause.driven_into_ditch_avoiding_other_animal',
    workshop_costs: 'sosServiceOrder.cause.workshop_costs',
    storage_fees: 'sosServiceOrder.cause.storage_fees',
    travel_illness: 'sosServiceOrder.cause.travel_illness',
    travel_injury: 'sosServiceOrder.cause.travel_injury',
    interruption: 'sosServiceOrder.cause.interruption',
    insurance_certificate: 'sosServiceOrder.cause.insurance_certificate',
  },
}

export const routeTypeOptions: Options = {
  options: {
    address: 'sosServiceOrder.routeType.address',
    repair_shop: 'sosServiceOrder.routeType.repair_shop',
  },
}

export const sosServiceOrderFragment = {
  success: gql`
    fragment SosServiceOrderFields on SosServiceOrder {
      jobId
      caseNumber
      priority
      agreementName
      estimatedCause
      estimatedServiceType
      incidentDescription
      situationDescription
      vehicleKeyLocation
      serviceType
      serviceDescription
      cause
      orderStatus
      detailedCause
      deliverable
      insuranceCompany
      isPreOrder
      vehicleMileage
      towingDateAndTime {
        date
        time
      }
      preOrder {
        beginEarliestAt {
          date
          time
        }
        beginLatestAt {
          date
          time
        }
      }
      jobTimestamps {
        onHoldUntil {
          date
          time
        }
        estimatedTimeOfArrival {
          date
          time
        }
        arrivedAtBreakdownLocation {
          date
          time
        }
        arrivedAtDestination {
          date
          time
        }
        completedAtBreakdownLocation {
          date
          time
        }
        completedAtDestination {
          date
          time
        }
        started {
          date
          time
        }
        stopped {
          date
          time
        }
      }
      originatorApp
    }
  `,
}

export const sosVariables = {
  insuranceCompany: [
    { value: 'If', label: 'If' },
    { value: 'Pohjola', label: 'Pohjola' },
    { value: 'LähiTapiola', label: 'LähiTapiola' },
    { value: 'Suomen Vahinkovakuutus', label: 'Suomen Vahinkovakuutus' },
    { value: 'Fennia', label: 'Fennia' },
    { value: 'Pohjantähti', label: 'Pohjantähti' },
    { value: 'Protector Forsikring ASA', label: 'Protector Forsikring ASA' },
    { value: 'A-vakuutus', label: 'A-vakuutus' },
    { value: 'Turva', label: 'Turva' },
    { value: 'Euro Insurances', label: 'Euro Insurances' },
    { value: 'Greenval Insurance', label: 'Greenval Insurance' },
    { value: 'Volvia', label: 'Volvia' },
    { value: 'Axa', label: 'Axa' },
    { value: 'Valtiokonttori', label: 'Valtiokonttori' },
    { value: 'Ålands ömsesidiga försäkringsbolag', label: 'Ålands ömsesidiga försäkringsbolag' },
    { value: 'Tapiola', label: 'Tapiola' },
    { value: 'Fennia Skadeförsäkring', label: 'Fennia Skadeförsäkring' },
    { value: 'Ups', label: 'Ups' },
    { value: 'Ei vakuutusta', label: 'Ei vakuutusta' },
  ],
  deliverable: [
    { value: 'Towage', label: 'Hinaus' },
    // { value: 'Salvage', label: 'Pelastus' },
    { value: 'Transport', label: 'Kuljetus' },
    { value: 'Pull free', label: 'Tielle nosto' },
    { value: 'Start car', label: 'Apukäynnistys' },
    // { value: 'Clean up', label: 'Onnettomuuspaikan siivous' },
    // { value: 'Other', label: 'Muu' },
    { value: 'Repair', label: 'Korjaus paikanpäällä' },
    { value: 'Change of wheel', label: 'Rengasapu' },
    { value: 'Opening of door', label: 'Ovien aukaisu' },
    { value: 'Delivery of fuel', label: 'Polttoaineen toimitus' },
    // { value: 'Empty ride', label: 'Turha lähtö' },
    // { value: 'Emergency repair/Towing', label: 'Apu paikanpäällä / Hinaus' },
    // { value: 'Home Transport', label: 'Kotiinkuljetus' },
    // { value: 'Scrapping', label: 'Romutus' },
    // { value: 'Towing - 2. jumpstart', label: 'Hinaustarve - 2. apuvirta' },
    // { value: 'Key insurance', label: 'Avainturva' },
  ],
  towingDeliverables: ['Towage', 'Transport'],
  cause: [
    { value: 'Immobiliser defect', label: 'Ajonestovika' },
    { value: 'Battery defect', label: 'Akkuvika' },
    { value: 'Key missing', label: 'Avain hävinnyt' },
    { value: 'Key broken', label: 'Avain rikki' },
    { value: 'Key locked in car', label: 'Avain lukitussa autossa' },
    { value: 'Can not start', label: 'Ei käynnisty' },
    { value: 'Hydraulic defect', label: 'Hydrauliikkavika' },
    { value: 'Vandalism', label: 'Ilkivalta' },
    { value: 'Ventilation or Heating defect', label: 'Ilmastointi- tai lämmitysvika' },
    { value: 'Brakes defect', label: 'Jarruvika' },
    { value: 'Transport', label: 'Jatkohinaus/Kuljetus' },
    { value: 'Stuck in snow', label: 'Jumissa lumessa' },
    { value: 'Stuck on field/grass', label: 'Jumissa pellolla/nurmikolla' },
    { value: 'Leaking coolant', label: 'Jäähdytinnestevuoto' },
    { value: 'Cooler defect', label: 'Jäähdytysjärjestelmän vika' },
    { value: 'Frozen', label: 'Jäätynyt' },
    { value: 'Moving of vehicle', label: 'Kiireetön siirto' },
    { value: 'Chassis', label: 'Kori' },
    { value: 'Clutch defect', label: 'Kytkinvika' },
    { value: 'Glass damage', label: 'Lasivaurio' },
    { value: 'Lock defect', label: 'Lukkovika' },
    { value: 'Engine failure', label: 'Moottorivika' },
    { value: 'Burglary', label: 'Murto' },
    { value: 'Steering defect', label: 'Ohjauslaitteen vika' },
    { value: 'Driven into ditch', label: 'Ojaanajo' },
    { value: 'Driven into ditch avoiding deer', label: 'Ojaanajo, hirvieläimen väistö' },
    { value: 'Driven into ditch avoiding other animal', label: 'Ojaanajo, muun eläimen väistö' },
    { value: 'Door damaged', label: 'Ovi vaurioitunut' },
    { value: 'Exhaust', label: 'Pakoputkiston vika' },
    { value: 'Fire Damage', label: 'Palovahinko' },
    { value: 'Fuel system defect', label: 'Polttainejärjestelmävika' },
    { value: 'Run out of fuel', label: 'Polttoaine loppu' },
    { value: 'Red Light', label: 'Punainen vikavalo palaa' },
    { value: 'Wheel/suspension defect', label: 'Pyörän tai ripustuksen vaurioituminen' },
    { value: 'Flat tire with kit', label: 'Rengasrikko, paikkaussarja' },
    { value: 'Flat tire with spare tire', label: 'Rengasrikko, vararengas' },
    { value: 'Flat tire', label: 'Rengasvaurio' },
    { value: 'Stalled while driving', label: 'Sammunut ajosta' },
    { value: 'Electronic failure', label: 'Sähkövika' },
    { value: 'Stuck by road', label: 'Tieltä suistuminen' },
    { value: 'Flat battery', label: 'Tyhjä akku' },
    { value: 'Accident solo', label: 'Törmäys, ei toista ajoneuvoa' },
    { value: 'Collision with deer', label: 'Törmäys, hirvikolari' },
    { value: 'Accident', label: 'Törmäys, liikenneonnettomuus' },
    { value: 'Collision with animal', label: 'Törmäys, muu eläin' },
    { value: 'Transmission fault', label: 'Vaihteistovika' },
    { value: 'Parked with light on', label: 'Valot unohtuneet päälle' },
    { value: 'Theft', label: 'Varkaus' },
    { value: 'Def. windshield wiper', label: 'Vioittunut tuulilasinpyyhkijä' },
    { value: 'Ignition lock defect', label: 'Virtalukon vika' },
    { value: 'Gear defect', label: 'Voimansiirron vika' },
    { value: 'Parked at inconvenience', label: 'Väärin pysäköinti' },
    { value: 'Wrong fuel', label: 'Väärintankkaus' },
    { value: 'Empty ride', label: 'Turha lähtö' },
    // { value: 'Tire exploded', label: 'Rengasapu' },
    // { value: 'Acute illness', label: 'Akuutti sairastuminen' },
    // { value: 'Other', label: 'Muu' },
    // { value: 'Yellow Light', label: 'Keltainen varoitusvalo' },
    // { value: 'Bone fracture', label: 'Luun murtuma' },
    // { value: 'Chest region', label: 'Rintakehän alue' },
    // { value: 'Building damage', label: 'Muu rakennusvahinko' },
    // { value: 'Epilepsy', label: 'Epilepsia' },
    // { value: 'Ethylene (alcohol)', label: 'Eteeni (alkoholi)' },
    // { value: 'Evacuation', label: 'Evakuointi' },
    // { value: 'Traffic accident', label: 'Kolari toisen ajoneuvon kanssa' },
    // { value: 'Fever', label: 'Kuume' },
    // { value: 'Fence alarm', label: 'Aitahälytys' },
    // { value: 'Birth', label: 'Synnytys' },
    // { value: 'Burning', label: 'Poltto' },
    // { value: 'Inquiry', label: 'Tiedustelu' },
    // { value: 'Poisoning', label: 'Myrkytys' },
    // { value: 'Pollution', label: 'Saastuminen' },
    // { value: 'Found after theft', label: 'Löydetty varkauden jälkeen' },
    // { value: 'Reference', label: 'Lähete' },
    // { value: 'Heart/lung', label: 'Sydän/keuhkot' },
    // { value: 'Parked with radio on', label: 'Radio unohtunut päällle' },
    // { value: 'Hospitalization', label: 'Sairaalahoitoon' },
    // { value: 'Transportation of veterinary', label: 'Eläinlääkärin kuljetus' },
    // { value: 'Transportation of medical records', label: 'Potilastietojen kuljetus' },
    // { value: 'Transportation of organ', label: 'Elimen kuljetus' },
    // { value: 'Transportation of equipment', label: 'Välineiden kuljetus' },
    // { value: 'Curbs collision', label: 'Osuma tien kanttiin' },
    // { value: 'Transportation of person', label: 'Kotiinkuljetus' },
    // { value: 'Skull/head', label: 'Pääkallo/pää' },
    // { value: 'Storm damage', label: 'Myrsky' },
    // { value: 'Power Failure', label: 'Sähkökatkos' },
    // { value: 'Pills/drugs', label: 'Pillereitä/huumausaineita' },
    // { value: 'Lost cargo', label: 'Kadonnut kuorma' },
    // { value: 'Dental problem', label: 'Hammassairaus' },
    // { value: 'Call out', label: 'Puhelu' },
    // { value: 'Water damage', label: 'Vesivahinko' },
    // { value: 'Fire', label: 'Palovahinko' },
    // { value: 'Light/lenses', label: 'Lamppu/Linssit' },
    // { value: 'Emergeny slaughter', label: 'Hätäteurastus' },
    // { value: 'Salvation of machinery', label: 'Koneiston pelastaminen' },
    // { value: 'Transportation of animals', label: 'Eläinten kuljetus' },
    // { value: 'Hometransport after repair', label: 'Kotiinkuljetus korjausten jälkeen' },
    // { value: 'Nearest suitable workshop', label: 'Lähin osaava korjaamo' },
    // { value: 'Further transport or overnight stay', label: 'Matkanjatko tai yöpyminen' },
    // { value: 'Mechanical damage', label: 'Mekaaninen vahinko' },
    // { value: 'Call on old case', label: 'Soitto vanhaan tapaukseen' },
    // { value: 'Damaged due to floow', label: 'Kaatosateen aiheuttama vahinko' },
    // { value: 'Lost', label: 'Kadonnut' },
    // { value: 'Technical Inquiry', label: 'Tekninen tiedustelu' },
    // { value: 'Reposessed', label: 'Ulosotettu' },
    // { value: 'Cleanup', label: 'Siivous' },
    // { value: 'Traffic regulation', label: 'Liikennesäännökset' },
    // { value: 'Oil spill cleanup', label: 'Öljyvuodon siivous' },
    // { value: 'Cleanup of road', label: 'Tien siivous' },
    // { value: 'Emergency move', label: 'Hätämuutto' },
    // { value: 'Illness', label: 'Sairaus' },
    // { value: 'Stolen plate(s)', label: 'Rekisterikilvet varastettu' },
    // { value: 'Home transport', label: 'Kotiinkuljetus' },
    // { value: 'Cancelled', label: 'Peruuntuminen' },
    // { value: 'Tyre kit if possible - or tow', label: 'Paikkaussetti tai hinaus' },
    // { value: 'Towing', label: 'Hinaus' },
    // { value: 'Cust. Referred to Servicemobil', label: 'Asiakas ohjattu Servicemobiliin' },
    // { value: 'No heat', label: 'Lämmitysvika' },
    // { value: 'Hometransport delayed > 3days', label: 'Kotimatka viivästynyt yli 3vrk' },
    // { value: 'Hometransport > 1 day (PH work)', label: 'Kotimatka > 1 vrk (työ)' },
    // { value: 'Detour > 300km', label: 'Kiertotie > 300km' },
    // { value: 'Insurance company decisionc', label: 'Vakuutusyhtiön päätös' },
    // { value: 'Flat tire without kit', label: 'Rengasrikko ilman paikkaussettiä' },
    // { value: 'Repair over 3 hours', label: 'Korjaus yli 3 tuntia' },
    // { value: 'Cheapest travel home', label: 'Edullisin matkanjatko' },
    // { value: 'Elevator defect', label: 'Hissivika' },
    // { value: 'Damaged', label: 'Vaurioitunut' },
    // { value: 'Help to use TMS', label: 'Apu paikkaussetin kanssa' },
    // { value: 'Help with spare tyre', label: 'Apu renkaan vaihdossa' },
    // { value: 'Start of car (lacking electricity)', label: 'Ajoneuvo ei käynnisty' },
    // { value: 'Open door/tailgate etc.', label: 'Oven/takakontin avaaminen' },
    // { value: 'Adding oil or water', label: 'Öljyn tai veden lisääminen' },
    // { value: 'Accident with counterpart and mors', label: 'Onnettomuus vastapuolen kanssa, kuolonkolari' },
    // { value: 'Solo accident with mors', label: 'Törmäys, kuolonkolari' },
    // { value: 'Accident with counterpart and injury', label: 'Onnettomuus vastapuolen kanssa, loukkaantuminen' },
    // { value: 'Accident with counterpart', label: 'Onnettemuus vastapuolen kanssa' },
    // { value: 'Refund', label: 'Maksun palautus' },
    // { value: 'Emergency', label: 'eCall, hätä' },
    // { value: 'Death', label: 'Kuolema' },
    // { value: 'Burglary/theft', label: 'Murto' },
    // { value: 'Crash', label: 'eCall, törmäys' },
    // { value: 'Dont want to use TMS', label: 'Ei halua käyttää paikka-ainetta' },
    // { value: 'Run out of electricity', label: 'Virran loppuminen' },
    // { value: 'Covered by mobility', label: 'Liikkumisturva korvaa' },
    // { value: 'Case created in customers system', label: 'Tapaus luotu asiakkaan järjestelmään' },
    // { value: 'Towing acc. to workshop', label: 'Hinaus korjaamon mukaan' },
    // { value: 'Engine shield', label: 'Moottorin suoja' },
    // { value: 'Natural perils', label: 'Luontovahinko' },
    // { value: 'Refusal', label: 'Kieltäytyminen' },
    // { value: 'External impact', label: 'Ulkoinen isku' },
    // { value: 'Generel Information', label: 'Neuvonta' },
    // { value: 'Scrapping', label: 'Romutus' },
    // { value: 'Change battery', label: 'Akun vaihto' },
    // { value: 'Grounded', label: 'Karilleajo' },
    // { value: 'Sunk', label: 'Uppoaminen' },
    // { value: 'Theft of engine', label: 'Moottorin varkaus' },
    // { value: 'Food/freezer', label: 'Ruoka/pakastin' },
    // { value: 'Liability', label: 'Vastuu' },
    // { value: 'Arrived late', label: 'Myöhästyminen' },
    // { value: 'Delayed', label: 'Viivästyminen' },
    // { value: 'Electric vehicle for recharging', label: 'eCall, sähköinen ajoneuvo ladattavaksi' },
    // { value: 'bCall - Transferred to mobility', label: 'bCall, välitetty liikkumisturvalle' },
    // { value: 'Error', label: 'eCall, vika' },
    // { value: 'Accident - Transferred to 112', label: 'Onnettomuus, välitetty 112' },
    // { value: 'Accident - Transferred to mobility', label: 'Onnettomuus, välitetty liikkumisturvalle' },
    // { value: 'Discovery call', label: 'eCall, paikannussoitto' },
    // { value: 'Breakdown request', label: 'eCall, Hinaus' },
    // { value: 'Claims Report', label: 'Vahinkoilmoitus' },
    // { value: 'Handling fee', label: 'Partnein käsittelykulu' },
    // { value: 'Workshop costs', label: 'Korjaamokustannukset' },
    // { value: 'Storage fees', label: 'Säilytyskulut' },
    // { value: 'Travel illness', label: 'Matkasairastuminen' },
    // { value: 'Travel injury', label: 'Matkatapaturma' },
    // { value: 'Interruption', label: 'Keskeytyminen' },
    // { value: 'Insurance certificate', label: 'Vakuutustodistus' },
  ],
}

export const sosCauseFilters: { [key: string]: string[] } = {
  Towage: [
    'Red Light',
    'Battery defect',
    'Brakes defect',
    'Electronic failure',
    'Traffic accident',
    'Wrong fuel',
    'Stalled while driving',
    'Gear defect',
    'Glass damage',
    'Vandalism',
    'Wheel/suspension defect',
    'Hydraulic defect',
    'Can not start',
    'Clutch defect',
    'Cooler defect',
    'Stuck in snow',
    'Stuck on field/grass',
    'Stuck by road',
    'Run out of fuel',
    'Key missing',
    'Key broken',
    'Key locked in car',
    'Flat tire',
    'Steering defect',
    'Ignition lock defect',
    'Engine failure',
    'Fire',
    'Exhaust',
    'Collision with animal',
    'Theft',
    'Flat tire with kit',
    'Flat tire with spare tire',
    'Lock defect',
    'Frozen',
    'Parked at inconvenience',
    'Def. windshield wiper',
    'Transport',
    'Collision with deer',
    'Driven into ditch',
    'Accident solo',
    'Burglary/theft',
    'Accident',
    'Leaking coolant',
    'Chassis',
    'Transmission fault',
    'Immobiliser defect',
    'Ventilation or Heating defect',
    'Fuel system defect',
    'Driven into ditch, avoiding deer',
    'Driven into ditch, avoiding other animal',
  ],
  'Pull free': [
    'Stuck in snow',
    'Stuck on field/grass',
    'Stuck by road',
    'Accident solo',
    'Accident',
    'Driven into ditch, avoiding deer',
    'Driven into ditch, avoiding other animal',
  ],
  'Start car': ['Battery defect', 'Electronic failure', 'Parked with light on ', 'Can not start', 'Flat battery'],
  Repair: [
    'Red Light',
    'Battery defect',
    'Brakes defect',
    'Door damaged',
    'Electronic failure',
    'Wrong fuel',
    'Stalled while driving',
    'Gear defect',
    'Glass damage',
    'Vandalism',
    'Wheel/suspension defect',
    'Hydraulic defect',
    'Can not start',
    'Clutch defect',
    'Cooler defect',
    'Stuck in snow',
    'Stuck on field/grass',
    'Stuck by road',
    'Run out of fuel',
    'Key missing',
    'Key broken',
    'Key locked in car',
    'Flat tire',
    'Steering defect',
    'Ignition lock defect',
    'Engine failure',
    'Fire',
    'Exhaust',
    'Collision with animal',
    'Theft',
    'Flat tire with kit',
    'Flat tire with spare tire',
    'Lock defect',
    'Frozen',
    'Parked at inconvenience',
    'Def. windshield wiper',
    'Transport',
    'Collision with deer',
    'Driven into ditch',
    'Accident solo',
    'Burglary/theft',
    'Accident',
    'Leaking coolant',
    'Chassis',
    'Transmission fault',
    'Fuel system defect',
    'Driven into ditch, avoiding deer',
    'Driven into ditch, avoiding other animal',
  ],
  'Change of wheel': ['Flat tire', 'Flat tire with kit', 'Flat tire with spare tire'],
  'Opening of door': ['Door damaged', 'Key missing', 'Key broken', 'Key locked in car', 'Lock defect'],
  'Delivery of fuel': ['Wrong fuel', 'Run out of fuel'],
  Transport: ['Moving of vehicle', 'Transport'],
  'Empty ride': ['Empty ride'],
}
