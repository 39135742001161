import { Maybe, TowingRecordPaymentType } from '../../common/types'
import { Item, ItemGroup } from '../../containers/Parameters/types'
import { TowingRecord } from '../../containers/TowingRecordPage/ViewTowingRecord/types'
import { default as raw_arc_codes } from './arc_codes_utf8.json'
import { ArcCodesFile } from './types'

const arcCodes = raw_arc_codes as ArcCodesFile

export const arcComponentOptions = arcCodes.components.map(component => {
  return {
    value: component.code,
    label: `${component.code} ${component.translation} (${component.description})`,
  }
})

export const arcDescriptionOptions = arcCodes.descriptions.map(description => {
  return {
    value: description.code,
    label: `${description.code} ${description.translation} (${description.description})`,
  }
})

export const getComponentLabel = (code: string | null): string => {
  if (!code) return ''
  const displayLabel = arcComponentOptions.find(option => option.value === code)
  return displayLabel ? displayLabel.label : ''
}

export const getDescriptionLabel = (code: string | null): string => {
  if (!code) return ''
  const displayLabel = arcDescriptionOptions.find(option => option.value === code)
  return displayLabel ? displayLabel.label : ''
}

export const getBatteryServiceOptions = (itemGroups: Maybe<ItemGroup[]>, items: Maybe<Item[]>) => {
  const options = [{ value: 'other', label: 'Muu' }]

  if (!itemGroups || !items) {
    return options
  }

  const batteryGroup = itemGroups.find(group => group.name === 'AL-Akkupalvelu')

  if (batteryGroup) {
    items.forEach(item => {
      if (item.groups.includes(batteryGroup.id)) {
        options.unshift({ value: item.name, label: item.name })
      }
    })
  }

  return options
}

export const getDeliveredBattery = (towingRecord: TowingRecord) => {
  return towingRecord.automobileAndTouringClubFinland &&
    towingRecord.automobileAndTouringClubFinland.batteryService &&
    towingRecord.automobileAndTouringClubFinland.batteryService.deliveredBattery
    ? towingRecord.automobileAndTouringClubFinland.batteryService.deliveredBattery
    : null
}

export const getBatteryServiceBattery = (
  itemGroups: Maybe<ItemGroup[]>,
  items: Maybe<Item[]>,
  battery: Maybe<string>
) => {
  const options = getBatteryServiceOptions(itemGroups, items)

  const batteryFound = options.find(option => option.value === battery)

  if (batteryFound) {
    return batteryFound.value
  }

  return 'other'
}

export const paymentTypeOptions = [
  { value: 'invoice', label: 'Lasku' },
  {
    value: TowingRecordPaymentType.card,
    label: 'Korttimaksu',
  },
  { value: TowingRecordPaymentType.cash, label: 'Käteismaksu' },
]
