import gql from 'graphql-tag'
import { towingRecordFragment } from '../../ViewTowingRecord/TowingRecordQuery'

const editTowingRecordFragment = {
  editTowingRecordSuccess: gql`
    fragment EditTowingRecordSuccessFields on TowingRecord {
      id
      recordNumber
      type
      status
      jobStatus
      paymentType
      operatorId
      stationId
      label
      notificationMethod
      lastModified
      vehicleDetails {
        registrationNumber
        vehicleClass
        makeAndModel
        insuranceCompany
      }
      jobDetails {
        orderReference
        serviceType
        towingDate
        towingReason
        additionalInfo
        notes
        incidentCaseNo
      }
      jobItems {
        id
        type
        name
        description
        quantity
        unitPrice
        unitPriceHasVatIncluded
        unit
        discount
        discountType
        vat
        vatAmount
        grossAmount
        netAmount
        vatUnit
        vatCategory
      }
      signature {
        type
        signatorName
        signatorEmail
        signatorPhone
        signedAt
        createdAt
        sentAt
        agreements {
          text
          isAgreed
        }
        id
      }
      billing {
        type
        status
        paymentDetails {
          id
          vatAmount
          vatExcludedAmount
          vattedAmount
          isConfirmed
          debtorName
          receiptEmail
          paymentDate
        }
      }
      routes {
        id
        arrivalDate
        arrivalTime
        finishedDate
        finishedTime
        type
        ordering
        address
        city
        zipcode
        description
        coords {
          lat
          long
        }
        displayTimestampOnPrintables
        displayFinishedTimestampOnPrintables
      }
      orderer {
        __typename
        id
        identifier
        type
        phone
        ... on CompanyCustomer {
          companyName
          businessId
          contactPerson
          contactPersonEmail
        }
        ... on PersonCustomer {
          firstName
          lastName
          ssn
          email
        }
      }
      automobileAndTouringClubFinland {
        jobId
        caseNumber
        serviceChannel
        billingInfo
        personCount
        vehicleMileage
        arcCodes {
          component {
            code
            label
          }
          description {
            code
            label
          }
          completion {
            code
            label
          }
        }
        repairShopId
        distanceTowed
        towingInsistedByCustomer
        delayedTowing
        jobTimestamps {
          estimatedTimeOfArrival {
            date
            time
          }
          actualTimeOfArrival {
            date
            time
          }
          estimatedTimeOfCompletion {
            date
            time
          }
          actualTimeOfCompletion {
            date
            time
          }
        }
        situationDescription
      }
    }
  `,
}

export const EDIT_TOWING_RECORD = gql`
  mutation EditTowingRecord($input: EditTowingRecordInput!) {
    editTowingRecord(input: $input) {
      __typename
      ... on UnauthorizedError {
        error {
          code
          message
        }
      }
      ... on GenericError {
        genericError: error {
          code
          message
        }
      }
      ... on EditTowingRecordSuccess {
        towingRecord {
          ...EditTowingRecordSuccessFields
        }
      }
      ... on EditTowingRecordWithBillingUpdateSuccess {
        towingRecord {
          ...EditTowingRecordSuccessFields
        }
        addBilling {
          ... on AddBillingSuccess {
            billing {
              type
              paymentDetails {
                vattedAmount
                debtorName
                receiptEmail
                paymentDate
              }
            }
          }
          __typename
        }
      }
      ... on InvalidParameterError {
        error {
          code
          message
        }
      }
    }
  }
  ${editTowingRecordFragment.editTowingRecordSuccess}
`

export const END_TOWING_RECORD = gql`
  mutation EndJob($input: EndJobInput!) {
    endJob(input: $input) {
      __typename
      ... on UnauthorizedError {
        error {
          code
          message
        }
      }
      ... on ResourceNotFoundError {
        error {
          code
          message
        }
      }
      ... on StatusChangeForbiddenError {
        error {
          code
          message
        }
      }
      ... on EndJobSuccess {
        towingRecord {
          ...TowingRecordFields
        }
      }
    }
  }
  ${towingRecordFragment.success}
`

export const CONVERT_TO_SOS_RECORD = gql`
  mutation ConvertToSosRecord($input: ConvertToSosRecordInput!) {
    convertToSosRecord(input: $input) {
      __typename
      ... on UnauthorizedError {
        error {
          code
          message
        }
      }
      ... on GenericError {
        genericError: error {
          code
          message
        }
      }
      ... on ConvertToSosRecordSuccess {
        towingRecord {
          id
        }
      }
      ... on InvalidParameterError {
        error {
          code
          message
        }
      }
    }
  }
`
