import React from 'react'
import styled, { css } from 'styled-components'

import { Maybe } from '../../common/types'

interface Props {
  address: Maybe<string>
  size?: Size
}

type Size = 'big' | 'normal' | 'medium'

export const MapLink: React.FunctionComponent<Props> = ({ address, size }) => {
  if (!address) return null
  return (
    <StyledHyperlink size={size} href={`https://www.google.com/maps/search/?api=1&query=${address}`} target="_blank" rel="nofollow noreferrer noopener">
      {address}
    </StyledHyperlink>
  )
}

interface StyledHyperlinkProps {
  size?: Size
}

const StyledHyperlink = styled.a<StyledHyperlinkProps>`
  font-size: ${(props) => (props.size && props.size === 'big' ? '1.5rem' : '1rem')};
  ${(props) =>
    props.size === 'medium' &&
    css`
      font-size: 1.25rem;
    `}
  text-decoration: underline;
  color: ${(props) => props.theme.colors.black};
`
