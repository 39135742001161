import { Maybe, Time } from '../../common/types'
import { EditTowingOrder } from '../../containers/TowingOrderPage/EditTowingOrder/types'
import { TowingDateType } from '../../containers/TowingOrderPage/types'
import { TowingOrder } from '../../containers/TowingOrderPage/ViewTowingOrder/types'

export interface DateTimeChangeInterface {
  date: Maybe<Date>
  time: Maybe<Time>
}

export interface ChangeTimeModalFormValues {
  id: number
  jobDetails: {
    startTime: DateTimeChangeInterface
    endTime: DateTimeChangeInterface
    delayedTowing: boolean
    delayedTowingReason: string
    towingDateType: TowingDateType
  }
}

// Locally used
export function getInitialValuesQuery(towingOrder: TowingOrder | EditTowingOrder): ChangeTimeModalFormValues {
  return {
    id: towingOrder.id,
    jobDetails: {
      startTime:{
        date: towingOrder.jobDetails.towingDate,
        time: towingOrder.jobDetails.towingTime,
      },
      endTime: {
        date: towingOrder.jobDetails.towingEndDate,
        time: towingOrder.jobDetails.towingEndTime,
      },
      delayedTowing: towingOrder.automobileAndTouringClubFinland
        ? towingOrder.automobileAndTouringClubFinland.delayedTowing
        : false,
      delayedTowingReason: towingOrder.automobileAndTouringClubFinland
        ? towingOrder.automobileAndTouringClubFinland.delayedTowingReason
        : '',
      towingDateType: towingOrder.jobDetails.towingDateType
    },
  }
}
