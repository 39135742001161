import styled from 'styled-components'

export const MenuContainer = styled.div`
  background-color: ${props => props.theme.colors.yellow};
  position: fixed;
  width: 100%;
  max-width: 100vw;
  bottom: 0;
  left: 50%;
  z-index: 8;
  transform: translateX(-50%);
  height: auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const MenuList = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
`

export const MenuItem = styled.div`
  position: relative;
  margin: 0 2rem;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    h3 {
      font-size: 1rem;
    }
    margin: 0 1rem 0 0;
    :last-child {
      margin-right: 0;
    }
  }
  `

interface CircleProps {
  smallFont?: boolean
}

export const CountCircle = styled.div<CircleProps>`
  position: absolute;
  top: -1.3rem;
  right: -0.5rem;
  background-color: ${props => props.theme.colors.orange800};
  font-weight: ${props => props.theme.fontWeight.bold};
  box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.5);
  border-radius: 100%;
  font-size: ${props => (props.smallFont ? '1rem' : '1.25rem')};
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.white};
  line-height: 2rem;
  text-align: center;
`
