import { Preferences } from '../../../containers/Parameters/types'
import { PreferencesMutationVariables } from './types'

export const toMutationVariables = (values: Preferences): PreferencesMutationVariables => {
  return {
    input: {
      id: values.id,
      filters: values.filters,
    },
  }
}
