import React from 'react'
import styled, { css } from 'styled-components'
import { Heading2 } from '../../layout'
import { Icon } from '../../icons/Icon'
import { WorkShiftEvent, FormattedEvent, FormattedEventType } from '../../../containers/Shifts/types'
import { format } from 'date-fns'
import { Time } from '../../../common/types'

export const TableHeader: React.FunctionComponent = () => {
  return (
    <TableRowSection type="header">
      <Th>Tapahtuma</Th>
      <Th>Pvm</Th>
      <Th>Klo</Th>
      <Th>Lisätiedot</Th>
    </TableRowSection>
  )
}

interface TableRowProps {
  event: WorkShiftEvent
  isMobile?: boolean
}

export const TableRow: React.FunctionComponent<TableRowProps> = ({ event }) => {
  const formattedEvent = toFormattedEvent(event)
  return (
    <TableRowSection type="row">
      <Td>{formattedEvent.type}</Td>
      <Td>{formattedEvent.date.toLocaleDateString('fi')}</Td>
      <Td>{formattedEvent.time}</Td>
      <Td>{formattedEvent.info}</Td>
    </TableRowSection>
  )
}

function toFormattedEvent(event: WorkShiftEvent): FormattedEvent {
  const formattedType = FormattedEventType[event.type]
  return {
    id: event.id,
    type: formattedType,
    date: new Date(event.timestamp),
    time: (format(new Date(event.timestamp), 'HH:mm') as unknown) as Time,
    info: event.info ? event.info : '',
  }
}

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
`

interface TableRowSectionProps {
  padding?: string
  type?: 'header' | 'row'
}

export const TableRowSection = styled.tr<TableRowSectionProps>`
  padding: ${props => (props.padding ? props.padding : '0.5rem 0')};
  ${props =>
    props.type === 'header' &&
    css`
      border-radius: ${props => props.theme.radius.small};
      border-color: ${props => props.theme.colors.grey100};
      background-color: ${props => (props.color ? props.theme.colors[props.color] : props.theme.colors.grey100)};
    `}
  ${props =>
    props.type === 'row' &&
    css`
      border-bottom: 0.5px solid;
      border-color: ${props => props.theme.colors.grey300};
    `}
`

const Th = styled.th`
  text-align: start;
  padding: 0.5rem;
`

const Td = styled.td`
  padding: 0.5rem;
  border-bottom: 0.5px solid;
  border-color: ${props => props.theme.colors.grey300};
  vertical-align: top;
`

interface ShiftHeaderProps {
  color?: string
}

export const ShiftHeader = styled.div<ShiftHeaderProps>`
  background-color: ${props => (props.color ? props.theme.colors[props.color] : props.theme.colors.grey300)};
  padding: 3rem 1rem 1rem 1rem;
  text-align: center;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    padding: 3.5rem 1rem 1rem 1rem;
  }
`

interface ShiftHeadingProps {
  vehicleName?: string
  vehicleRegNo?: string
  label: string
}

export const ShiftHeading: React.FunctionComponent<ShiftHeadingProps> = ({ label, vehicleName, vehicleRegNo }) => {
  return (
    <HeadingContainer>
      <Heading2 color="black">{label}</Heading2>
      {vehicleName && vehicleRegNo && (
        <Heading2 color="black">
          {vehicleName} ({vehicleRegNo.toUpperCase()}){' '}
          <HeadingIcon icon="edit" inline={true} size="shift-heading" color="black" />
        </Heading2>
      )}
    </HeadingContainer>
  )
}

const HeadingContainer = styled.div`
  margin-bottom: 1rem;
`

const HeadingIcon = styled(Icon)`
  vertical-align: middle;
`
