import { TowingRecordType, TypeIdentifier } from '../../common/types'
import { EditTowingRecord } from '../../containers/TowingRecordPage/EditTowingRecord/types'
import { TowingRecord } from '../../containers/TowingRecordPage/ViewTowingRecord/types'
import { getStoredTrackedRecords, storeTrackedRecords } from '../../graphql-client/storedState'
import { GeolocationPosition, trackedSosStatuses, trackedStatuses } from './types'

export function toGeolocationPosition(coords: Coordinates): GeolocationPosition {
  return {
    coords,
  }
}

export const maybeStopTrackingRecord = (towingRecord: TowingRecord | EditTowingRecord) => {
  let records = getStoredTrackedRecords()
  if (records) {
    const index = records.findIndex(value => value === towingRecord.id)
    if (index !== -1) {
      records.splice(index, 1)
      storeTrackedRecords(records)
    }
  }
}

export const maybeStartTrackingRecord = (towingRecord: TowingRecord | EditTowingRecord) => {
  if (shouldTrack(towingRecord)) {
    let records = getStoredTrackedRecords()
    if (records) {
      const index = records.findIndex(value => value === towingRecord.id)
      if (index === -1) {
        records.push(towingRecord.id)
      }
    } else {
      records = [towingRecord.id]
    }
    storeTrackedRecords(records)
  }
}

export function shouldTrack(towingRecord: TowingRecord | EditTowingRecord) {
  if (towingRecord.type !== TowingRecordType.autoliitto && towingRecord.type !== TowingRecordType.sos) return false
  if (towingRecord.typeIdentifier === TypeIdentifier.lahitapiola) return false
  if (towingRecord.type === TowingRecordType.sos) {
    return trackedSosStatuses.includes(towingRecord.jobStatus)
  }

  return trackedStatuses.includes(towingRecord.jobStatus)
}
