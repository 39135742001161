import { FormikProps } from 'formik'
import React, { useCallback } from 'react'
import { TextInput } from '../'

interface Props {
  label: string
  name: string
  coordsName: string
  required?: boolean
  disabled?: boolean
  onBlur?: (form: FormikProps<any>) => void
  shrink?: boolean
}

export const CityInput: React.FunctionComponent<Props> = ({ coordsName, ...rest }) => {
  const handleChange = useCallback(
    (_, form: FormikProps<any>) => {
      form.setFieldValue(coordsName, { long: null, lat: null })
    },
    [coordsName]
  )

  return <TextInput {...rest} onChange={handleChange} />
}
