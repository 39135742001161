import styled from 'styled-components'

export const Container = styled.div`
  padding: 1em 4em 1em 4em;
  display: flex;
  flex-direction: row;
  align-self: left;
  min-width: 100%;
`

export const IconWrapper = styled.div`
  align-self: center;
  padding: 1em;
`

export const Content = styled.div`
  align-self: center;
`

export const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
`

export const Message = styled.p`
  font-size: 1.25rem;
  margin: 0;
  padding: 0;
`
