export const dataHasError = (data: any, typeName: string) => {
  if (data.__typename && data.__typename === typeName) {
    return true
  }

  return Object.keys(data).find((result: string) => {
    return data[result].__typename === typeName
  })
    ? true
    : false
}
