import { FormikContext, FormikErrors, connect } from 'formik'
import { Component } from 'react'

interface IProps {
  formik: FormikContext<any>
}

const BreakException = {}

class ScrollToErrorWithConnect extends Component<IProps> {
  public componentDidUpdate(prevProps: IProps) {
    const { isSubmitting, errors } = prevProps.formik

    const keyify = (obj: FormikErrors<any>, prefix = ''): string[] =>
      Object.keys(obj).reduce(
        (res, el) => {
          const currentObject = obj[el]
          if (Array.isArray(currentObject)) {
            return res
          }
          if (typeof currentObject === 'object' && currentObject !== null) {
            return [...res, ...keyify(currentObject, `${prefix}${el}.`)]
          }
          return [...res, prefix + el]
        },
        [] as string[]
      )

    const keys = keyify(errors)

    try {
      if (keys.length > 0 && isSubmitting) {
        keys.forEach(key => {
          // Use input name as selector unless it's datepicker, then use react-date-picker class
          const selector = !(key.includes('date') || key.includes('Date')) ? `[name="${key}"]` : `.react-date-picker`
          let errorElement = document.querySelector(selector) as HTMLElement

          // If no element found, try if it's select field
          if (!errorElement) {
            const labelElement = document.querySelector(`[for="${key}"]`) as HTMLElement
            if (labelElement) {
              errorElement = labelElement
            }
          }

          if (errorElement) {
            // Get element's container and try to find error tooltip
            const container = errorElement.closest('div:not(.react-date-picker)') as HTMLElement
            const errorMessage = container ? (container.querySelector('span.error-tooltip') as HTMLElement) : null

            let scrollToElement = errorElement
            if (container) scrollToElement = container
            if (errorMessage) scrollToElement = errorMessage

            // Scroll view to selected element and focus to it
            // Then break out of the loop so only 1 scroll happens
            scrollToElement.scrollIntoView({ behavior: 'smooth', inline: 'start' })
            errorElement.focus({ preventScroll: true })
            throw BreakException
          }
        })
      }
    } catch (e) {
      if (e !== BreakException) throw e
    }
  }

  public render = () => null
}

export const ScrollToError = connect<{}>(ScrollToErrorWithConnect)
