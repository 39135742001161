import React from 'react'
import ReactDOM from 'react-dom'
import { AppContainer } from './containers/AppContainer/AppContainer'

if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

ReactDOM.render(<AppContainer />, document.getElementById('root'))
