import { FieldProps, getIn } from 'formik'
import React, { useEffect, useState } from 'react'
import DatePicker, { DatePickerProps } from 'react-date-picker'
import styled, { css } from 'styled-components'
import { Error, FieldContainer, FieldStyles, FormikField as Field, Label } from '../../style'

interface Props {
  id: string
  label?: string
  name: string
  disabled?: boolean
  placeholder?: string
  required?: boolean
  className?: string
}

const DateFieldContainer = styled(FieldContainer)`
  justify-content: flex-end;
`

const StyledDatePicker = styled(DatePicker)`
  ${FieldStyles}
  background-color: ${props => props.theme.colors.white};
    
  height: ${props => props.theme.inputFieldHeight};

  .react-date-picker {
    &__wrapper {
      border: 0;
      width: 100%;
    }
    &__inputGroup__input {
      color: ${props => props.theme.colors.black};
      ${props =>
        props.disabled &&
        css`
          color: ${props => props.theme.colors.greyDisabled};
        `};
    }
    &__button {
      display: none;
    }
  }
 
  &.react-date-picker--disabled {
    background-color: ${props => props.theme.colors.grey200} !important;
    & > input {
      color: red;
    }
     
  
  }
`

export const DateField: React.FunctionComponent<Props & FieldProps> = ({
  id,
  field,
  form,
  label,
  disabled = false,
  required,
  ...rest
}) => {
  const [, setValue] = useState<string>(field.value || '')

  useEffect(() => {
    setValue(field.value)
  }, [field.value])

  const fieldError = getIn(form.errors, field.name)
  const fieldTouched = getIn(form.touched, field.name)
  const hasErrors = Boolean(fieldError) && Boolean(fieldTouched)
  return (
    <DateFieldContainer {...rest}>
      {label && <Label required={required}>{label}</Label>}
      <Field id={id} component={CustomDatePickerInput} type="date" name={field.name} disabled={disabled} />

      {hasErrors && <Error className="error-tooltip">{fieldError}</Error>}
    </DateFieldContainer>
  )
}

type CustomDatePickerProps = DatePickerProps & FieldProps

const CustomDatePickerInput: React.FunctionComponent<CustomDatePickerProps> = ({ field, form, disabled }) => {
  const changeField = (day: Date | Date[]): void => {
    form.setFieldValue(field.name, day)
    form.setFieldTouched(field.name)
  }
  return <StyledDatePicker onChange={changeField} locale="fi" value={field.value} disabled={disabled} />
}
