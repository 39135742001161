import React from 'react'

import {
  DateSectionColumn,
  HalfTimeInput,
} from '../../../containers/TowingOrderPage/EditTowingOrder/TowingOrderDetailsSubFormContents'
import sos from '../../../images/sos.gif'
import {
  Column,
  FormSubGroup,
  FormSubGroupHeading,
  ImageWrapper,
  Row,
  Section,
  SectionColumn,
} from '../../layout'
import { DateInput, SelectInput, TextAreaInput, TextInput } from '../../layout/form/input'
import { sosVariables } from '../types'
import { SosFormSubGroupHeader, SosFormSubGroupWrapper } from './EditTowingOrder'
import { useSosCauseOptions } from '../../../util/useSosCauseOptions'

interface SosServiceOrderDetailsFormProps {
  getValue: any
  setValue: any
}

export const SosServiceOrderDetailsForm: React.FunctionComponent<SosServiceOrderDetailsFormProps> = ({
  getValue,
  setValue,
}) => {
  const deliverable = getValue('sosServiceOrder.estimatedServiceType')
  const { causeOptions, onDeliverableChange } = useSosCauseOptions(deliverable, setValue)

  const deliverables = sosVariables.deliverable

  return (
    <SosFormSubGroupWrapper>
      <SosFormSubGroupHeader>
        <Row>
          <Column>
            <FormSubGroupHeading>
              <ImageWrapper>
                <img src={sos} alt="SOS" width={`95px`} />
              </ImageWrapper>
            </FormSubGroupHeading>
          </Column>
          {/* <Column justify="flex-end">
            <RadioButtonGroup
              label={''}
              name="jobDetails.towingDateType"
              options={translatedOrderScheduleOptions}
            ></RadioButtonGroup>
          </Column> */}
        </Row>
      </SosFormSubGroupHeader>
      <FormSubGroup>
        <Section columns={2} marginMobile="0">
          <DateSectionColumn>
            <DateInput label={'Työn pvm'} name="jobDetails.towingDate" required />
          </DateSectionColumn>
          <DateSectionColumn>
            <HalfTimeInput label={'Aika'} name="jobDetails.towingTime" />
            <HalfTimeInput setTimeButton={false} label={'Lopetusaika'} name="jobDetails.towingEndTime" />
          </DateSectionColumn> 
          <SectionColumn />
        </Section>
        <Section columns={2}>
          <SectionColumn>
            <TextInput label={'SOS Viite'} name="sosServiceOrder.caseNumber" disabled />
          </SectionColumn>
          <SectionColumn>
            <SelectInput
              label="Vakuutusyhtiö"
              name="sosServiceOrder.insuranceCompany"
              options={sosVariables.insuranceCompany}
              required
            />
          </SectionColumn>
        </Section>
        <Section columns={2}>
          <SectionColumn>
            <SelectInput
              label="Arvioitu toimenpide"
              name="sosServiceOrder.estimatedServiceType"
              options={deliverables}
              onChange={value => onDeliverableChange(value)}
              required
            />
          </SectionColumn>
          <SectionColumn>
            <SelectInput label="Arvioitu vika" name="sosServiceOrder.estimatedCause" options={causeOptions} required />
          </SectionColumn>
        </Section>

        <Section columns={2}>
          <SectionColumn>
            <TextAreaInput label={'Työn lisätiedot'} name="jobDetails.additionalInfo" />
          </SectionColumn>
          <SectionColumn>
            <TextAreaInput label={'Muistiinpanot'} name="jobDetails.notes" />
          </SectionColumn>
        </Section>
        <Section columns={2}>
          <SectionColumn>
            <TextAreaInput label="SOS Merkinnät" name="sosServiceOrder.situationDescription" disabled />
          </SectionColumn>
          <SectionColumn />
        </Section>
      </FormSubGroup>
    </SosFormSubGroupWrapper>
  )
}
