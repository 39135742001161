import React, { useContext } from 'react'
import styled from 'styled-components'

import { FormSubGroup, FormSubGroupHeading, ImageWrapper, Section, SectionColumn } from '../../../components/layout'
import { SelectInput, TextAreaInput, TextInput } from '../../../components/layout/form/input'
import { StateStore } from '../../../containers/StoreProvider'
import {
  DateSectionColumn,
  HalfDateInput,
  HalfTimeInput,
} from '../../../containers/TowingOrderPage/EditTowingOrder/TowingOrderDetailsSubFormContents'
import lahitapiola from '../../../images/lahitapiola.png'
import { LahitapiolaFormSubGroupHeader, LahitapiolaFormSubGroupWrapper } from '../components'

interface FormContentsProps {
  isTowingRecord?: boolean
}

const HalfTextInput = styled(TextInput)`
  width: calc(50% - 1rem);
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    width: calc(50% - 0.125rem);
  }
`

export const LahitapiolaDefaultOrderDetailsForm: React.FunctionComponent<FormContentsProps> = ({ isTowingRecord }) => {
  const { state } = useContext(StateStore)

  if (!state.settings) {
    return null
  }

  return (
    <LahitapiolaFormSubGroupWrapper>
      <LahitapiolaFormSubGroupHeader>
        <FormSubGroupHeading>
          <ImageWrapper>
            <img src={lahitapiola} alt="Lähitapiola" width={`95px`} />
          </ImageWrapper>
        </FormSubGroupHeading>
      </LahitapiolaFormSubGroupHeader>
      <FormSubGroup>
        <Section columns={2} marginMobile="0">
          <DateSectionColumn>
            <HalfDateInput label={'Päivämäärä'} name="jobDetails.towingDate" required disabled={isTowingRecord} />
            <HalfTimeInput label={'Aika'} name="jobDetails.towingTime" disabled={isTowingRecord} />
          </DateSectionColumn>
          <SectionColumn />
        </Section>
        <Section columns={2}>
          <SectionColumn>
            <SelectInput
              label="Hinauksen syy"
              name="jobDetails.towingReason"
              options={state.settings.towingReasons.map((label: string) => ({
                label,
                value: label,
              }))}
            />
          </SectionColumn>
          <DateSectionColumn>
            <HalfTextInput label={'Viite'} name="automobileAndTouringClubFinland.caseNumber" disabled />
            <HalfTextInput label="Laskutus" name="automobileAndTouringClubFinland.billingInfo" disabled />
          </DateSectionColumn>
        </Section>
        <Section columns={2}>
          <SectionColumn>
            <TextAreaInput label={'Lisäselvitykset'} name="jobDetails.additionalInfo" />
          </SectionColumn>
          <SectionColumn>
            <TextAreaInput label={'Muistiinpanot'} name="jobDetails.notes" />
          </SectionColumn>
        </Section>
        {/* <Section columns={2}>
          <SectionColumn>
            <TextAreaInput label="Tilannearvio" name="automobileAndTouringClubFinland.situationDescription" disabled />
          </SectionColumn>
          <SectionColumn />
        </Section> */}
      </FormSubGroup>
    </LahitapiolaFormSubGroupWrapper>
  )
}
