import { useMutation } from '@apollo/react-hooks'
import { Formik, getIn } from 'formik'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { InjectedIntlProps, injectIntl } from 'react-intl'

import { CustomerType, Maybe, TowingOrderType, TypeIdentifier, User, UserRole } from '../../../common/types'
import { Page } from '../../../components/layout'
import { setErrorNotification, setSuccessNotification } from '../../../components/notification'
import { Loading } from '../../../components/responses'
import { setErrorNotifications, setFatalErrorNotification } from '../../../components/responses/setErrorNotifications'
import { useHistoryPush } from '../../../components/router'
import { initialSosServiceOrder } from '../../../components/SosSubForm/types'
import { VehicleInfo } from '../../../components/VehicleSearchModal/types'
import { getStoredUserId } from '../../../graphql-client/storedState'
import {
  GenericStorageRetrieveFn,
  persistentDelete,
  persistentGet,
  persistentSave,
} from '../../../util/localStorage/localStorage'
import { CustomerFormValues } from '../../Customer/types'
import { DispatchStore, StateStore } from '../../StoreProvider'
import { STORED_EDIT_TOWING_ORDER, handleNetworkError } from '../../StoreProvider/OfflineMode'
import { OwnersHoldersTrafi } from '../../../components/VehicleDetails/types'
import { messages } from '../messages'
import { VisibilityType } from '../types'
import { getInitialValues, initialVehicleDetails } from './initialValues'
import { ADD_TOWING_ORDER_MUTATION, CONVERT_TO_SOS_ORDER_MUTATION } from './mutation/mutation'
import { toMutationVariables } from './mutation/toMutationVariables'
import { ConvertToSosOrderResponse, EditTowingOrderResponse } from './mutation/types'
import { TowingOrderFormContents } from './TowingOrderFormContents'
import { EditTowingOrder, EditTowingOrderFormValues } from './types'
import { setOwnersHoldersData } from '../../../components/VehicleDetails/functions'

const TOWING_ORDER = STORED_EDIT_TOWING_ORDER

interface FormProps {
  towingOrder: EditTowingOrder
  scrollTo: string | undefined
}

export const EditTowingOrderFormIntl: React.FunctionComponent<FormProps & InjectedIntlProps> = ({
  towingOrder,
  scrollTo,
  intl,
}) => {
  const [storeFormValues, setStoreFormValues] = useState<boolean>(true)
  const [ownersHolders, setOwnersHolders] = useState<OwnersHoldersTrafi[]>([])
  const [assign, setAssign] = useState<boolean>(false)
  const [useConvertMutation, setUseConvertMutation] = useState<boolean>(false)
  const historyPush = useHistoryPush()
  const { formatMessage } = intl
  const {
    state: { offlineMode, unSavedJobCount, currentUser, settings },
  } = useContext(StateStore)
  const { dispatch } = useContext(DispatchStore)

  const closeForm = () => {
    persistentDelete(TOWING_ORDER)
    historyPush('/')
  }

  const persistenGetTowingOrder: GenericStorageRetrieveFn<EditTowingOrderFormValues> = persistentGet
  const initialEditTowingOrderFormValues = getInitialValues(towingOrder)

  const storedTowingOrder = persistenGetTowingOrder(TOWING_ORDER)
  if (storedTowingOrder) {
    storedTowingOrder.jobDetails.towingDate = storedTowingOrder.jobDetails.towingDate
      ? new Date(storedTowingOrder.jobDetails.towingDate)
      : null
  }

  const [convertToSosOrder, { loading: convertLoading }] = useMutation<ConvertToSosOrderResponse>(
    CONVERT_TO_SOS_ORDER_MUTATION,
    {
      onCompleted({ convertToSosOrder }) {
        if (convertToSosOrder.__typename === 'ConvertToSosOrderSuccess') {
          setSuccessNotification(
            formatMessage(messages.convertToSosOrderSuccessTitle),
            formatMessage(messages.convertToSosOrderSuccessMessage)
          )
          persistentDelete(TOWING_ORDER)
          historyPush(`/towing-order/${towingOrder.id}`)
          return
        }
        setErrorNotifications({ data: convertToSosOrder })
      },
      onError(error) {
        setFatalErrorNotification(error.message)
      },
    }
  )

  const [editTowingOrder, { loading }] = useMutation<EditTowingOrderResponse>(ADD_TOWING_ORDER_MUTATION, {
    onCompleted(editTowingOrderResponse) {
      if (editTowingOrderResponse.editTowingOrder.__typename === 'EditTowingOrderSuccess') {
        const url = !assign ? '/' : `/towing-order/${towingOrder.id}`
        historyPush(url)
        setSuccessNotification(
          formatMessage(messages.editTowingOrderSuccessTitle),
          formatMessage(messages.editTowingOrderSuccessMessage)
        )
        persistentDelete(TOWING_ORDER)
        return
      }
      if (editTowingOrderResponse.editTowingOrder.__typename === 'AddTowingRecordSuccess') {
        historyPush(`/towing-record/${editTowingOrderResponse.editTowingOrder.towingRecord.id}`)
        setSuccessNotification(
          formatMessage(messages.editTowingOrderSuccessTitle),
          formatMessage(messages.editTowingOrderSuccessMessage)
        )
        persistentDelete(TOWING_ORDER)
        return
      }
      setErrorNotifications({ data: editTowingOrderResponse.editTowingOrder })
    },
    onError(error) {
      if (!offlineMode) {
        return handleNetworkError(dispatch, unSavedJobCount + 1)
      }

      setFatalErrorNotification(error.message)
    },
  })

  const handleSave = useCallback(
    async (values: EditTowingOrderFormValues, operatorId: Maybe<number>) => {
      try {
        const id = operatorId ? operatorId : null

        if (useConvertMutation && towingOrder.type !== TowingOrderType.autoliitto) {
          if (towingOrder.type === TowingOrderType.sos) {
            throw new Error('Alreary SOS order')
          }
          await convertToSosOrder({ variables: toMutationVariables(values, id) })
          setUseConvertMutation(false)
          return
        }
        await editTowingOrder({ variables: toMutationVariables(values, id) })
      } catch (err) {
        console.log('Error saving towing order', err)
      }
    },
    [editTowingOrder, convertToSosOrder, towingOrder.type, useConvertMutation]
  )

  useEffect(() => {
    setOwnersHoldersData(towingOrder.vehicleInfoCheck, setOwnersHolders)
  }, [towingOrder.vehicleInfoCheck, setOwnersHolders])

  const operatorDisabled = shouldDisableOperator(currentUser)

  useEffect(() => {
    if (useConvertMutation) {
    }
  }, [useConvertMutation])

  console.log('towingOrder', towingOrder)

  return (
    <Page noPadding>
      <Loading loading={loading || convertLoading} />
      <Formik
        initialValues={initialEditTowingOrderFormValues}
        onSubmit={(values: EditTowingOrderFormValues) => handleSave(values, getStoredUserId())}
        render={({ values, setValues, isSubmitting, setFieldValue, handleSubmit }) => {
          const onSetOrderer = (o: CustomerFormValues) => {
            const currentOrdererType = values.orderer.type

            values.orderer = Object.assign({}, o)
            values.orderer.type = currentOrdererType

            if (settings && settings.notifications.enabled) {
              switch (o.type) {
                case CustomerType.person:
                  if (o.personCustomer) {
                    values.notificationMethod = o.personCustomer.notificationMethod
                  }
                  break
                case CustomerType.company:
                  if (o.companyCustomer) {
                    values.notificationMethod = o.companyCustomer.notificationMethod
                  }
                  break
              }
            }

            setValues(values)
          }
          const setOrdererFromTrafi = (o: CustomerFormValues) => {
            values.orderer = Object.assign({}, o)
            setValues(values)
          }

          const onHandleVehicleResults = (res: VehicleInfo) => {
            if (res) {
              setOwnersHoldersData(res, setOwnersHolders)
              const owner = res.ownersHolders.find(ownerHolder => ownerHolder.ownershipType === 1) || null
              const holder = res.ownersHolders.find(ownerHolder => ownerHolder.ownershipType === 3) || null
              values.vehicleDetails.registrationNumber = res.vehicle.registrationNumber
              values.vehicleDetails.insuranceCompany = res.insurance.insuranceCompany
              values.vehicleDetails.makeAndModel = res.vehicle.makeAndModel
              values.vehicleDetails.owner = owner && owner.customer && owner.customer.name ? owner.customer.name : ''
              values.vehicleDetails.vehicleClass = res.vehicle.vehicleClass
              values.vehicleDetails.holder =
                holder && holder.customer && holder.customer.name ? holder.customer.name : ''
              values.vehicleInfoCheckId = res.id

              setValues(values)
            }
          }

          const onCancelVehicleDetails = () => {
            const t = 'vehicleDetails'
            values[t] = Object.assign({}, initialVehicleDetails)
            values.vehicleInfoCheckId = null
            setValues(values)
          }

          const onSetSosServiceOrder = () => {
            const t = 'sosServiceOrder'
            values[t] = Object.assign({}, initialSosServiceOrder)
            setValues(values)
          }

          /* const onEditTime = (delayedTowing: boolean) => {
            if (values.automobileAndTouringClubFinland) {
              setFieldValue('automobileAndTouringClubFinland.delayedTowing', delayedTowing)    SIIRRETTY SISEMPÄÄN KOMPONENTTIIN
            }
          } */

          /*const onChangeVisibility = (value: string | number | undefined) => {
            if (value !== TowingOrderVisibility.ASSIGNED) {
              setFieldValue('operatorId', null)
            }
          }*/

          if (isSubmitting ? false : storeFormValues ? true : false) {
            persistentSave(TOWING_ORDER, values)
          }

          const getValue = (fieldName: string) => {
            const value = getIn(values, fieldName)
            return value
          }

          const handleSubmitForm = (assign: boolean, operatorId: Maybe<number>, visibility: Maybe<VisibilityType>) => {
            const userId = currentUser ? currentUser.userId : null

            if (operatorId) {
              values.operatorId = operatorId
            } else {
              values.operatorId = assign && userId ? userId : values.operatorId
            }

            if (visibility) {
              values.visibility = visibility
            }

            if (assign || operatorId !== null) {
              setAssign(true)
            }

            if (assign) {
              values.assignOnSave = assign
            }

            // if (stationRequired && !values.stationId) {
            //   scrollTop()
            //   return setErrorNotification('Virhe', 'Ole hyvä ja valitse toimipiste')
            // }

            if (values.sosServiceOrder) {
              const { coords: fromCoords } = values.from
              // const { coords: toCoords, address } = values.to
              const { insuranceCompany, deliverable, cause } = values.sosServiceOrder
              let errFields = ''
              let coordsErr = false

              if (!insuranceCompany) {
                errFields += 'Vakuutusyhtiö'
              }

              if (!deliverable) {
                errFields += errFields.length > 0 ? `, Arvioitu toimenpide` : 'Arvioitu toimenpide'
              }

              if (!cause) {
                errFields += errFields.length > 0 ? `, Arvioitu vika` : 'Arvioitu vika'
              }

              if (!fromCoords.lat || !fromCoords.long) {
                coordsErr = true
                errFields +=
                  errFields.length > 0
                    ? `. Kohteen koordinaatteja ei saatu haettua`
                    : 'Kohteen koordinaatteja ei saatu haettua'
              }

              // if (deliverable && sosVariables.towingDeliverables.includes(deliverable) && address) {
              //   if (!toCoords.lat || !toCoords.long) {
              //     coordsErr = true
              //     errFields +=
              //       errFields.length > 0
              //         ? `. Määränpään koordinaatteja ei saatu haettua`
              //         : 'Määränpään koordinaatteja ei saatu haettua'
              //   }
              // }

              if (coordsErr) {
                errFields += '. Hae osoite kartalta tai valitse osoite osoitehaun ehdotuksista.'
              }

              if (errFields.length > 0) {
                return setErrorNotification('Virhe', `Tarkista seuraavat tiedot: ${errFields}`)
              }
            }

            persistentSave(TOWING_ORDER, values)

            handleSubmit()
          }

          console.log('values', values)

          return (
            <TowingOrderFormContents
              closeForm={closeForm}
              formatMessage={formatMessage}
              onSetOrderer={onSetOrderer}
              onHandleVehicleResults={onHandleVehicleResults}
              onCancelVehicleDetails={onCancelVehicleDetails}
              showTowingDateSpan={values.jobDetails.towingDateType === 'span'}
              orderer={values.orderer}
              ssn={
                values.orderer.personCustomer && values.orderer.personCustomer.ssn
                  ? values.orderer.personCustomer.ssn
                  : ''
              }
              businessId={
                values.orderer.companyCustomer && values.orderer.companyCustomer.businessId
                  ? values.orderer.companyCustomer.businessId
                  : ''
              }
              registrationNumber={values.vehicleDetails.registrationNumber}
              setFieldValue={setFieldValue}
              operatorDisabled={operatorDisabled}
              setStoreFormValues={setStoreFormValues}
              typeIdentifier={useConvertMutation ? TypeIdentifier.sos : towingOrder.typeIdentifier}
              isSos={towingOrder.type === TowingOrderType.sos || useConvertMutation}
              onSubmit={handleSubmitForm}
              getValue={getValue}
              towingOrder={towingOrder}
              //onEditTime={onEditTime}     SIIIRRETTY SISEMPÄÄN KOMPONENTTIIN
              convertToSosOrder={() => {
                setUseConvertMutation(true)
                onSetSosServiceOrder()
                // handleSubmit()
              }}
              scrollToSos={useConvertMutation}
              ownersHolders={ownersHolders}
              setOrdererFromTrafi={setOrdererFromTrafi}
              scrollTo={scrollTo}
            />
          )
        }}
      />
    </Page>
  )
}

export const EditTowingOrderForm = injectIntl(EditTowingOrderFormIntl)

export function shouldDisableOperator(user: Maybe<User>): boolean {
  if (!user) {
    return true
  }

  switch (user.role) {
    case UserRole.admin:
    case UserRole.main_user:
    case UserRole.super_admin:
    case UserRole.duty_officer:
      return false
    default:
      return true
  }
}
