import { Maybe, Time } from '../../common/types'
import { ShiftGroup } from '../Parameters/types'

type InactivePage = 'inactive'

type InReservePage = 'in-reserve'

type ActivePage = 'active'

type BreakPage = 'break'

type WaitingPage = 'waiting'

export type ShiftSubPages = InactivePage | InReservePage | ActivePage | BreakPage | WaitingPage

export enum EventTypeOption {
  'work_start' = 'work_start', // Aloita työ
  'work_end' = 'work_end', // Lopeta työ
  'break_start' = 'break_start', // Aloita tauko
  'break_end' = 'break_end', // Lopeta tauko
  'standby_start' = 'standby_start', // Aloita varallaolo
  'standby_end' = 'standby_end', // Lopeta varallaolo
  'wait_start' = 'wait_start', // Aloita odotus
  'wait_end' = 'wait_end', // Lopeta odotus
  'standby_work_start' = 'standby_work_start',
  'standby_work_end' = 'standby_work_end',
}

export enum NoShiftPageViews {
  'default' = 'default',
  'work_start_form' = 'work_start_form',
  'standby_start_form' = 'standby_start_form',
}

export enum WorkShiftPageViews {
  'default' = 'default',
  'break_start_form' = 'break_start_form',
  'wait_start_form' = 'wait_start_form',
  'work_end_form' = 'work_end_form',
}

export enum WaitingShiftPageViews {
  'default' = 'default',
  'wait_end_form' = 'wait_end_form',
}

export enum BreakShiftPageViews {
  'default' = 'default',
  'break_end_form' = 'break_end_form',
}

export enum StandbyShiftPageViews {
  'default' = 'default',
  'start_standby_work' = 'standby_work_start_form',
  'standby_end_form' = 'standby_end_form',
}

export enum FormattedEventType {
  'work_start' = 'Työ aloitettu', // Aloita työ
  'work_end' = 'Työ lopetettu', // Lopeta työ
  'break_start' = 'Tauko aloitettu', // Aloita tauko
  'break_end' = 'Tauko lopetettu', // Lopeta tauko
  'standby_start' = 'Varallaolo aloitettu', // Aloita varallaolo
  'standby_end' = 'Varallaolo lopetettu', // Lopeta varallaolo
  'wait_start' = 'Odotus aloitettu', // Aloita odotus
  'wait_end' = 'Odotus lopetettu', // Lopeta odotus
  'standby_work_start' = 'Työ (varalla) aloitettu',
  'standby_work_end' = 'Työ (varalla) lopetettu',
}

export interface WorkShift {
  id: number
  userId: Maybe<number>
  companyId: Maybe<number>
  comment: Maybe<string>
  shiftStart: Maybe<string>
  shiftEnd: Maybe<string>
  userConfirmation: Maybe<string>
  adminConfirmation: Maybe<string>
  adminId: Maybe<number>
}

export interface WorkShiftEvent {
  id: number
  userId: Maybe<number>
  companyId: Maybe<number>
  shiftId: Maybe<number>
  type: EventTypeOption
  timestamp: string
  info: string
}

export interface FormattedEvent {
  id: number
  type: FormattedEventType
  date: Date
  time: Time
  info: string
}

export interface CurrentWorkShift {
  shift: Maybe<WorkShift>
  events: Maybe<WorkShiftEvent[]>
}

export interface ShiftPageProps {
  currentShift: CurrentWorkShift
  refetch: () => void
  vehicles?: any
  shiftGroup?: Maybe<ShiftGroup>
}
