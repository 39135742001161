import ApolloClient from 'apollo-client'
import React from 'react'
import { GeolocatedProps, geolocated, GeolocatedConfig } from 'react-geolocated'
import { GeolocationType, GetAddressResponse, Geolocation, ReverseGeocodeResult } from './types'
import { REVERSE_GEOCODE } from '../ReverseGeocodeButton/queries'
import { GET_COORDS } from '../RouteAddressSelector/query'
import { ApolloClientGeocodeResponse, GeocodeResponse } from '../RouteAddressSelector/types'

interface GetGeolocationProps extends GeolocatedProps {
  client: ApolloClient<any>
  setError: (title: string, message: string) => void
  type: GeolocationType
  fieldName: string
  reportGeolocation?: (location: Geolocation, name: string) => void
  address?: string
  city?: string
  zipcode?: string
}

interface GetGeolocationState {
  time: number | null
}

class GetGeolocation extends React.Component<GetGeolocationProps, GetGeolocationState> {
  public errorCount: number = 0
  public geolocated: boolean = false
  public timerId: number | null = null

  public constructor(props: GetGeolocationProps) {
    super(props)
    this.state = { time: null }
  }

  public tick() {
    this.setState({ time: new Date().getSeconds() })
  }

  public componentDidMount() {
    if (this.props.type === GeolocationType.geocode) {
      return
    }

    // this.timerId = setInterval(() => this.tick(), 1000)

    if (!this.props.isGeolocationAvailable) {
      return this.props.setError('Virhe', 'Laitteesi ei tue sijaintitiedon hakemista, syötä tiedot käsin')
    }

    if (!this.props.isGeolocationEnabled) {
      return this.props.setError('Virhe', 'Laitteen sijaintitietoa ei saatavilla, sijainti ei ole käytössä')
    }
  }

  public async componentDidUpdate() {
    if (this.geolocated) return

    if (!this.props.isGeolocationAvailable) {
      this.errorCount = this.errorCount + 1
      if (this.errorCount >= 10) {
        this.clearInterval()
        return this.props.setError('Virhe', 'Laitteesi ei tue sijaintitiedon hakemista, syötä tiedot käsin')
      }
    }

    if (!this.props.isGeolocationEnabled) {
      this.errorCount = this.errorCount + 1

      if (this.errorCount >= 10) {
        this.clearInterval()
        return this.props.setError('Virhe', 'Laitteen sijaintitietoa ei saatavilla, sijainti ei ole käytössä')
      }
    }

    this.geolocated = this.props.coords ? true : false

    if (this.props.coords && this.props.type === GeolocationType.reverseGeocode) {
      const locationData = await this.reverseGeocode(this.props.coords)

      if (this.props.reportGeolocation && locationData) {
        this.props.reportGeolocation(
          toGeolocationResponse(this.props.coords, locationData.reverseGeocode.result),
          this.props.fieldName
        )
        return
      }
    }

    if (this.props.coords && this.props.type === GeolocationType.geolocation) {
      if (this.props.reportGeolocation) {
        this.props.reportGeolocation(toGeolocationResponse(this.props.coords, null), this.props.fieldName)
        return
      }
    }

    if (this.props.type === GeolocationType.geocode && this.props.address && this.props.city) {
      const { address, city, fieldName, zipcode } = this.props
      const locationData = await this.geocode(address, city)

      if (this.props.reportGeolocation && locationData) {
        this.props.reportGeolocation(
          this.geocodeToGeolocationResponse(locationData, address, city, zipcode || ''),
          fieldName
        )
        return
      }
    }
  }

  public componentWillUnmount() {
    this.clearInterval()
  }

  public clearInterval() {
    if (!this.timerId) return
    clearInterval(this.timerId)
  }

  public async reverseGeocode(coords: Coordinates | undefined) {
    if (!coords) return null
    const { data }: GetAddressResponse = await this.props.client.query({
      variables: { location: { lat: coords.latitude, long: coords.longitude } },
      query: REVERSE_GEOCODE,
    })

    if (data.reverseGeocode.__typename === 'GoogleAPIGeolocationError') {
      return this.props.setError('Virhe', 'Sijainnin paikannus epännistui')
    }

    return data
  }

  public async geocode(address: string, city: string) {
    const { data }: ApolloClientGeocodeResponse = await this.props.client.query({
      variables: { address: `${address}, ${city}` },
      query: GET_COORDS,
    })

    if (data.geocode.__typename === 'GoogleAPIGeolocationError') {
      console.error(data.geocode.error)
      return null
    }

    return data
  }

  public geocodeToGeolocationResponse(
    response: GeocodeResponse,
    address: string,
    city: string,
    zipcode: string
  ): Geolocation {
    const { geocode } = response

    if (geocode.__typename === 'GetGeocodeSuccess') {
      const { lat, long } = geocode.result

      return {
        address,
        city,
        zipcode,
        coords: {
          lat,
          long,
        },
      }
    }

    return {
      address,
      city,
      zipcode,
      coords: {
        lat: null,
        long: null,
      },
    }
  }

  public render() {
    return null
  }
}

const geolocatedOptions: GeolocatedConfig = {
  positionOptions: {
    enableHighAccuracy: process.env.NODE_ENV === 'development' ? false : true,
    maximumAge: 0,
    timeout: 10000,
  },
  watchPosition: true,
  userDecisionTimeout: 10000,
  isOptimisticGeolocationEnabled: true,
}

export default geolocated(geolocatedOptions)(GetGeolocation)

function toGeolocationResponse(coords: Coordinates, result: ReverseGeocodeResult | null): Geolocation {
  const { latitude, longitude } = coords
  const city = result ? result.city : ''
  const address = result ? result.address : ''
  const zipcode = result && result.zipcode ? result.zipcode : ''

  return {
    address,
    city,
    zipcode,
    coords: {
      lat: latitude,
      long: longitude,
    },
  }
}
