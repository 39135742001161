import { toScalarShortDate, toScalarTime } from '../../../util/convertToScalar'
import { AddEventFormValues } from '../AddEvent/types'
import { CurrentWorkShift, EventTypeOption } from '../types'
import { AddEventMutationVariables, EventInput, ShiftInput } from './types'

export function toMutationVariables(
  values: AddEventFormValues,
  type: EventTypeOption,
  shift: CurrentWorkShift
): AddEventMutationVariables {
  return {
    input: {
      event: toEventMutationVariables(values, type),
      shift: toShiftMutationVariables(values, shift, type),
    },
  }
}

function toEventMutationVariables(values: AddEventFormValues, type: EventTypeOption): EventInput {
  return {
    type: type,
    timestamp: {
      date: values.event.date ? toScalarShortDate(values.event.date) : null,
      time: values.event.time ? toScalarTime(values.event.time) : null,
    },
    estimatedEnd: (type === EventTypeOption.work_start || type === EventTypeOption.standby_start) 
    ? {
      date: values.event.estimatedEnd.date ? toScalarShortDate(values.event.estimatedEnd.date) : null,
      time: values.event.estimatedEnd.time ? toScalarTime(values.event.estimatedEnd.time) : null,
    }
    : null
  }
}

function toShiftMutationVariables(values: AddEventFormValues, shift: CurrentWorkShift, type: EventTypeOption): ShiftInput {
  return {
    id: shift.shift ? shift.shift.id : null,
    type: null,
    comment: (type === EventTypeOption.work_start || type === EventTypeOption.standby_start) && values.event.estimatedEnd.date && values.event.estimatedEnd.time
      ? `${values.event.comment} (Arvioitu lopetusaika: ${values.event.estimatedEnd.date.toLocaleDateString('fi')} klo. ${values.event.estimatedEnd.time})`
      : values.event.comment
  }
}
