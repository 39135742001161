import { Maybe } from './common'
import { ShortDate, Time } from './scalarTypes'

export enum ServiceType {
  TOWING = 'towing',
  ROAD_SERVICE = 'road_service',
  BATTERY_SERVICE = 'battery_service',
  PHONE_SERVICE = 'phone_service',
  OTHER = 'other',
}

export type TowingDateType = 'single' | 'span'

export interface TowingOrderJobDetails {
  orderReference: string
  serviceType: ServiceType
  towingDateType: TowingDateType
  towingDate: Maybe<ShortDate>
  towingEndDate: Maybe<ShortDate>
  towingTime: Maybe<Time>
  towingEndTime: Maybe<Time>
  towingReason: string
  additionalInfo: string
  notes: string
  incidentCaseNo: Maybe<string>
}

export interface TowingOrderJobDetailsInput {
  orderReference: string
  serviceType: ServiceType
  towingDateType: TowingDateType
  towingDate: Maybe<ShortDate>
  towingEndDate: Maybe<ShortDate>
  towingTime: Maybe<Time>
  towingEndTime: Maybe<Time>
  towingReason: string
  additionalInfo: string
  notes: string
  incidentCaseNo: Maybe<string>
}

export interface TowingRecordJobDetails {
  serviceType: ServiceType
  towingDate: Maybe<ShortDate>
  towingReason: string
  orderReference: string
  notes: string
  additionalInfo: string
  incidentCaseNo: Maybe<string>
}

export interface TowingRecordJobDetailsInput {
  serviceType: ServiceType
  towingDate: Maybe<ShortDate>
  towingReason: string
  orderReference: string
  notes: string
  additionalInfo: string
  incidentCaseNo: Maybe<string>
}
