import React from 'react'
import styled from 'styled-components'
import { Maybe, Time } from '../../common/types'
import { DisplayDate } from '../DisplayDate'
import { DisplayTime } from '../DisplayTime'

type TowingDateType = 'single' | 'span'

export interface SchedulingJobDetails {
  towingDateType: TowingDateType
  towingDate: Maybe<Date>
  towingEndDate: Maybe<Date>
  towingTime: Maybe<Time>
  towingEndTime: Maybe<Time>
}

export const TowingOrderScheduling: React.FunctionComponent<{ jobDetails: SchedulingJobDetails }> = ({
  jobDetails,
}) => {
  const { towingDateType, towingDate, towingEndDate, towingTime, towingEndTime } = jobDetails
  return (
    <DatesWrapper>
      <DateWrapperColumn>
        <DisplayDate date={towingDate} format="full" />
        {towingEndDate && towingDateType === 'span' && towingDate && towingDate.getTime() !== towingEndDate.getTime() && (
          <>
            &nbsp;—&nbsp;
            <DisplayDate date={towingEndDate} format="full" />
          </>
        )}
        &nbsp;
      </DateWrapperColumn>

      <DateWrapperColumn>
        <DisplayTime time={towingTime} />
        {towingEndTime && towingTime !== towingEndTime && (
          <>
            &nbsp;—&nbsp;
            <DisplayTime time={towingEndTime} />
          </>
        )}
      </DateWrapperColumn>
    </DatesWrapper>
  )
}

const DatesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
`

const DateWrapperColumn = styled.div`
  display: flex;
  flex-direction: row;
  width: max-content;
  color: ${props => (props.color ? props.theme.colors[props.color] : props.theme.colors.black)};
`
