import React from 'react'
import styled from 'styled-components'
import { Button } from '../Button'
import { ComboInputWrapper, RouteColumn, RouteRow } from '../layout'
import { LocationInput } from '../layout/form/input'
import { CityInput } from '../layout/form/input/CityInput'

interface Props {
  empty: () => void
  includeNameInAddress: boolean
}

export const MapForm: React.FunctionComponent<Props> = ({ empty, includeNameInAddress }) => {
  return (
    <>
      <RouteRow columns="2fr 1fr" mobileGridGap="0.5rem">
        <RouteColumn>
          <ComboInputWrapper>
            <LocationInput
              addressLabel={`Osoite`}
              addressName="address"
              cityName="city"
              coordsName="coords"
              zipcodeName="zipcode"
              includeNameInAddress={includeNameInAddress}
            />
            <CityInput label={`Kaupunki`} name="city" coordsName="coords" />
          </ComboInputWrapper>
        </RouteColumn>
        <RouteColumn>
          <ClearButton
            label="Tyhjennä"
            category="cancel"
            maxWidth="100%"
            mobileWidth="100%"
            size="s"
            onClick={() => {
              empty()
            }}
          />
        </RouteColumn>
      </RouteRow>
    </>
  )
}

const ClearButton = styled(Button)`
  margin-top: 1.35rem;
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    margin-top: 0.5rem;
  }
`
