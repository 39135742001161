import styled from 'styled-components'

const BaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 100vw;
  position: relative;
  border-top: 8px solid ${props => props.theme.colors.yellow};
`

export const Container = styled(BaseContainer).attrs({})`
  .Toastify {
    z-index: 100000;
  }
  .Toastify__toast-container {

  }
  .Toastify__toast {
    width: 100%;
  }
  
  .Toastify__toast--warning {
    background-color: ${props => props.theme.colors.yellow100};
  }
  .Toastify__toast--success {

  .Toastify__toast-body {
    font-size: 1.25em;
  }
  .Toastify__progress-bar {
  }
  .Toastify__toast--error {
    background-color: ${props => props.theme.colors.red100};
    position: fixed;
    z-index: 1;
  }
  .Toastify__toast--warning {
    background-color: ${props => props.theme.colors.yellow100};
  }
  .Toastify__toast--success {

  .Toastify__toast-body {
    font-size: 1.25em;
  }
  .Toastify__progress-bar {
  }
`

// @media (min-width: ${props => props.theme.screenSize.mobile}) {
//   width: ${props => props.theme.screenSize.mobile};
// }
// @media (min-width: ${props => props.theme.screenSize.tablet}) {
//   width: ${props => props.theme.screenSize.tablet};
// }
// @media (min-width: ${props => props.theme.screenSize.computer}) {
//   width: ${props => props.theme.screenSize.computer};
// }
