import { Field } from 'formik'
import React, { ReactNode, useCallback, useContext } from 'react'
import { StateStore } from '../../../../../containers/StoreProvider'
import { SelectField } from '../../field'

interface Props {
  name: string
  label?: string
  onChange?: (arg?: string | number) => void
  required?: boolean
  clearable?: boolean
  searchable?: boolean
  placeholder?: string
  children?: ReactNode
  disabled?: boolean
}

export const StationInput: React.FC<Props> = ({
  clearable,
  name,
  label,
  onChange,
  placeholder,
  required,
  searchable,
  disabled = false,
}) => {
  const validate = useCallback(
    (value: string | number | null): string | undefined => {
      if (required && !value) {
        return 'Kenttä on pakollinen'
      }
      return undefined
    },
    [required]
  )

  const { state } = useContext(StateStore)

  if (!state.settings) {
    return null
  }

  const stations: any[] = []

  state.settings.stations.forEach(station => {
    if (station.type === 1 || station.type === 2) {
      stations.push({
        label: station.name,
        value: station.id,
      })
    }
  })

  return (
    <Field
      disabled={disabled}
      clearable={clearable}
      component={SelectField}
      id={name}
      name={name}
      label={label}
      required={required}
      validate={validate}
      onChange={onChange}
      searchable={searchable}
      placeholder={placeholder}
      options={stations}
      autoSelectOnlyOption={true}
    />
  )
}
