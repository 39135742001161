import React, { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

type Props = StyleProps & {}

interface StyleProps {
  color?: string
  noPadding?: boolean
  justify?: string
  padding?: string
  mobilePadding?: string
}

export const Page: FunctionComponent<Props> = props => {
  return <PageContainer {...props}>{props.children}</PageContainer>
}

const PageContainer = styled.div<StyleProps>`
  box-sizing: border-box;

  margin: 0;
  padding: ${props => (props.noPadding ? '0 0 9.5rem 0' : '0.5rem 1.5rem 9.5rem 1.5rem')};
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  min-height: 100vh;
  background-color: ${props => (props.color ? props.theme.colors[props.color] : props.theme.colors.white)};
  display: flex;
  flex-direction: column;
  ${props =>
    props.justify &&
    css`
      justify-content: ${props.justify};
    `}
    @media (max-width: ${props => props.theme.screenSize.mobile}) {
      padding:${props => (props.noPadding ? '0 0 9.5rem 0' : '0.5rem 0.5rem 9.5rem 0.5rem')};
      ${props =>
        props.mobilePadding &&
        css`
          padding: ${props.mobilePadding};
        `}
    }
`
