import styled, { css } from 'styled-components'

interface ButtonRowProps {
  justify?: string
  padding?: string
  gap?: boolean
}

export const LocationButtonRow = styled.div<ButtonRowProps>`
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
`
export const ButtonRow = styled.div<ButtonRowProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  ${props =>
    props.justify &&
    css`
      justify-content: ${props.justify};
    `}
  padding: ${props => (props.padding ? props.padding : '0 0 1rem 0')};
  margin: 0;
  ${props =>
    props.gap &&
    css`
      gap: 1rem;
    `}
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    justify-content: space-between;
    align-items: stretch;
    margin: 0.5rem 0;
    flex-direction: column;
  }
`

interface ButtonColumnProps {
  width?: string
  margin?: string
  mobileMargin?: string
}

export const ButtonColumn = styled.div<ButtonColumnProps>`
  :not(:first-child) {
    margin: ${props => (props.margin ? props.margin : '0 0 0 1rem')}
    }
  ${props =>
    props.width &&
    css`
      min-width: ${props.width};
    `}
  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    margin: 0 0 0 0;
    :not(:last-child), :not(:first-child) {
      margin: 0 0 1rem 0;
    }
    ${props =>
      props.mobileMargin &&
      css`
        margin: ${props.mobileMargin}!important;
      `}
  }
`

interface FieldButtonRowProps {
  sameBorderRadius?: boolean
  fullWidth?: boolean
}

export const FieldButtonRow = styled.div<FieldButtonRowProps>`
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;

  & > div {
    width: ${props => (props.fullWidth ? '100%' : '65%')};
  }
  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  & > button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 0 0.2rem;
    width: 35%;
  }

  ${props =>
    props.sameBorderRadius &&
    css`
      & > button {
        border-top-right-radius: ${props => props.theme.radius.medium};
        border-bottom-right-radius: ${props => props.theme.radius.medium};
      }
    `}
`

export const SignatorFieldButtonRow = styled.div`
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;

  & > div {
    width: 100%;
  }
  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  & > button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 0 1rem;
    width: auto;
  }
`

interface BigButtonSectionProps {
  justifyContent?: string
  justifyItems?: string
}

export const BigButtonSection = styled.div<BigButtonSectionProps>`
  display: grid;
  grid-gap: 1rem;
  align-items: end;
  justify-items: ${props => (props.justifyItems ? props.justifyItems : 'center')};
  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'center')};
  margin: 0 0 1rem 0;

  grid-template-columns: repeat(auto-fit, minmax(120px, 0.5fr));

  @media (max-width: ${props => props.theme.screenSize.mobile}) {
    grid-template-columns: minmax(120px, 1fr);
  }
`

export const BigButtonContent = styled.div`
  display: flex;
  flex-direction: column;
`
