import React from 'react'
import styled from 'styled-components'
import { Icon } from '../icons/Icon'
import { FieldContainer, Label } from '../layout/form/style'

interface Props {
  onClick: () => void
  disabled?: boolean
}
export const Delete: React.FC<Props> = ({ onClick, disabled = false }) => {

  return (
    <FieldContainer>
      <Label>&nbsp;</Label>
      <Align>
        <StyledIcon disabled={disabled} onClick={() => {
          if (disabled) {
            return
          }
          onClick()
        }} >
          < Icon icon = "trash" size = "x-small" color = "white" / >
        </StyledIcon>
      </Align>
    </FieldContainer >
  )
}

const Align = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

interface IconProps {
  disabled: boolean
}

const StyledIcon = styled.div<IconProps>`
  padding: 0.5rem;
  width: ${props => props.theme.inputFieldHeight};
  border-radius: ${props => props.theme.radius.medium};
  background-color: ${props => props.disabled ? props.theme.colors.grey400 : props.theme.colors.red800};
`
