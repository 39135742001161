import { TowingOrderStatus } from '../../common/types'
import { getStoredUserId } from '../../graphql-client/storedState'

export const isActiveJob = (status: string, operatorId: number) => {
  if (status === TowingOrderStatus.assigned && operatorId === getStoredUserId()) {
    return true
  }

  return status === TowingOrderStatus.open || status === TowingOrderStatus.pending_acceptance
}

