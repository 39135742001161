import React, { useContext } from 'react'
import { Page } from '../../../components/layout'
import { StateStore } from '../../StoreProvider'
import { BreakPage } from '../BreakPage'
import { NoShiftPage } from '../NoShiftPage'
import { StandbyPage } from '../StandbyPage'
import { CurrentWorkShift } from '../types'
import { hasWorkEnded, inStandby, isWaiting, isWorking, onBreak, resolveShiftStatus, standbyWork } from '../util'
import { WaitingPage } from '../WaitingPage'
import { WorkPage } from '../WorkPage'

interface ViewShiftsPageProps {
  currentShift: CurrentWorkShift
  refetch: () => void
}

export const ViewShiftsPage: React.FunctionComponent<ViewShiftsPageProps> = ({ currentShift, refetch }) => {
  console.log('currentShift', currentShift)
  const shiftStatus = resolveShiftStatus(currentShift)
  const standByWorking = standbyWork(shiftStatus)
  const {
    state: { settings },
  } = useContext(StateStore)
  console.log('shiftStatus', shiftStatus)
  console.log('settings', settings)
  return (
    <Page noPadding={true}>
      {hasWorkEnded(shiftStatus) && (
        <NoShiftPage
          currentShift={currentShift}
          refetch={refetch}
          shiftGroup={settings && settings.shiftGroup ? settings.shiftGroup : null}
        />
      )}
      {inStandby(shiftStatus) && <StandbyPage currentShift={currentShift} refetch={refetch} />}
      {isWorking(shiftStatus) && (
        <WorkPage
          currentShift={currentShift}
          refetch={refetch}
          standByWork={standByWorking}
          shiftGroup={settings && settings.shiftGroup ? settings.shiftGroup : null}
        />
      )}
      {onBreak(shiftStatus) && <BreakPage currentShift={currentShift} refetch={refetch} />}
      {isWaiting(shiftStatus) && <WaitingPage currentShift={currentShift} refetch={refetch} />}
    </Page>
  )
}
