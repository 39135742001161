import { ShiftPageProps, StandbyShiftPageViews, EventTypeOption } from '../types'
import React, { useState } from 'react'
import { BigButtonSection } from '../../../components/layout'
import { Button } from '../../../components/Button'
import { AddEventPage } from '../AddEvent'
import { ShiftTable } from '../../../components/ShiftTable'
import { ShiftHeader, ShiftHeading } from '../../../components/ShiftTable/layout'

export const StandbyPage: React.FunctionComponent<ShiftPageProps> = ({ currentShift, refetch }) => {
  const [view, setView] = useState<StandbyShiftPageViews>(StandbyShiftPageViews.default)
  return (
    <>
      {view === StandbyShiftPageViews.default && (
        <>
          <ShiftHeader color="orange300">
            <ShiftHeading label="Varalla" />
            <BigButtonSection>
              <Button
                category="new"
                label="Aloita työ"
                onClick={() => setView(StandbyShiftPageViews.start_standby_work)}
                size="m"
                fontWeight="normal"
                maxWidth="100%"
              />
              <Button
                category="new"
                label="Lopeta varallaolovuoro"
                size="m"
                fontWeight="normal"
                maxWidth="100%"
                onClick={() => setView(StandbyShiftPageViews.standby_end_form)}
              />
            </BigButtonSection>
          </ShiftHeader>
          <ShiftTable shift={currentShift} />
        </>
      )}

      {view === StandbyShiftPageViews.start_standby_work && (
        <AddEventPage
          type={EventTypeOption.standby_work_start}
          onClose={() => setView(StandbyShiftPageViews.default)}
          shift={currentShift}
          refetch={refetch}
        />
      )}

      {view === StandbyShiftPageViews.standby_end_form && (
        <AddEventPage
          type={EventTypeOption.standby_end}
          onClose={() => setView(StandbyShiftPageViews.default)}
          shift={currentShift}
          refetch={refetch}
        />
      )}
    </>
  )
}
