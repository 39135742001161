import { cloneDeep } from 'lodash'

import { toScalarShortDate, toScalarTime } from '../../../../../../../util/convertToScalar'
import {
  ArrivalRouteMutationVariables,
  UpdateArrivalAtBreakdownRouteMutationVariables,
  UpdateFinishedAtBreakdownRouteMutationVariables,
} from './mutation'
import { EditArrivalRouteFormValues } from '../types'
import { ArrivalRoute, ArrivalRouteMutationInput } from './types'
import { DateTime } from '../../../../../../../common/types'
import {
  batteryServiceToMutationVariables,
  resolveALCompletionARC,
} from '../../FinishRoute/mutation/toMutationVariables'

export const toMutationVariables = (inputValues: EditArrivalRouteFormValues): ArrivalRouteMutationVariables => {
  const values = cloneDeep(inputValues)

  const estimatedTimeOfArrival = values.automobileAndTouringClubFinland
    ? values.automobileAndTouringClubFinland.estimatedTimeOfCompletion
    : null

  return {
    input: {
      id: values.id,
      arrivalRoute: {
        arrivalRoute: routeToMutationVariables(values.arrivalRoute),
        estimatedTimeOfCompletion: estimatedTimeOfArrival ? toALScalarTimestamp(estimatedTimeOfArrival) : null,
      },
      notes: values.notes,
      vehicleRegNo: values.vehicleRegNo,
    },
  }
}

export function updateArrivedAtBreakdownLocationToMutationVariables(
  inputValues: EditArrivalRouteFormValues,
  towingRecordId: number
): UpdateArrivalAtBreakdownRouteMutationVariables {
  const values = cloneDeep(inputValues)

  const estimatedTimeOfArrival = values.automobileAndTouringClubFinland
    ? values.automobileAndTouringClubFinland.estimatedTimeOfCompletion
    : null

  return {
    input: {
      id: towingRecordId,
      arrivedAtBreakdownLocationDetails: {
        additionalInfo: values.additionalInfo,
      },
      arrivalRoute: {
        arrivalRoute: routeToMutationVariables(values.arrivalRoute),
        estimatedTimeOfCompletion: estimatedTimeOfArrival ? toALScalarTimestamp(estimatedTimeOfArrival) : null,
        additionalInfo: values.sosServiceOrder ? '' : values.additionalInfo
      },
      notes: values.notes,
      vehicleRegNo: values.vehicleRegNo,
    },
  }
}

export function updateFinishedAtBreakdownLocationToMutationVariables(
  inputValues: EditArrivalRouteFormValues,
  towingRecordId: number
): UpdateFinishedAtBreakdownRouteMutationVariables {
  const values = cloneDeep(inputValues)

  if (values.automobileAndTouringClubFinland) {
    return {
      input: {
        id: towingRecordId,
        finishedAtBreakdownLocationDetails: {
          additionalInfo: '',
          keyLocation: null,
          cause: null,
          deliverable: null
         
        },
        arrivalRoute: {
          arrivalRoute: routeToMutationVariables(values.arrivalRoute),
          serviceType: values.serviceType ? values.serviceType : null,
          towingReason: null,
          componentCode: values.automobileAndTouringClubFinland.arcCodes.component.code,
          descriptionCode: values.automobileAndTouringClubFinland.arcCodes.description.code,
          completionCode: resolveALCompletionARC(values.serviceType),
          vehicleMileage: values.automobileAndTouringClubFinland.vehicleMileage,
          batteryService:
            values.serviceType === 'battery_service'
              ? batteryServiceToMutationVariables(values.automobileAndTouringClubFinland.batteryService)
              : null,
          additionalInfo: values.additionalInfo
        },
        notes: values.notes,
        vehicleRegNo: values.vehicleRegNo,
      },
    }
  }

  if (values.sosServiceOrder) {
    return {
      input: {
        id: towingRecordId,
        finishedAtBreakdownLocationDetails: {
          additionalInfo: values.additionalInfo,
          keyLocation: values.sosServiceOrder.keyLocation,
          cause: values.sosServiceOrder.cause,
          deliverable: values.sosServiceOrder.deliverable
        },
        arrivalRoute: {
          arrivalRoute: routeToMutationVariables(values.arrivalRoute),
          serviceType: values.serviceType ? values.serviceType : null,
          towingReason: null,
          componentCode: null,
          descriptionCode: null,
          completionCode: null,
          vehicleMileage: null,
          batteryService: null,
          additionalInfo: '',
        },
        notes: values.notes,
        vehicleRegNo: values.vehicleRegNo,
      },
    }
  }

  return {
    input: {
      id: towingRecordId,
      finishedAtBreakdownLocationDetails: {
        additionalInfo: '',
        keyLocation: null,
        cause: values.cause ? values.cause : null,
        deliverable: values.deliverable ? values.deliverable : null
      },
      arrivalRoute: {
        arrivalRoute: routeToMutationVariables(values.arrivalRoute),
        serviceType: values.serviceType ? values.serviceType : null,
        towingReason: values.towingReason ? values.towingReason : null,
        componentCode: null,
        descriptionCode: null,
        completionCode: null,
        vehicleMileage: null,
        batteryService: null,
        additionalInfo: values.additionalInfo,
      },
      notes: values.notes,
      vehicleRegNo: values.vehicleRegNo,
      
    },
  }
}

const routeToMutationVariables = (route: ArrivalRoute): ArrivalRouteMutationInput => {
  return {
    id: route.id,
    ordering: 1,
    address: route.address,
    city: route.city,
    zipcode: route.zipcode,
    coords: {
      lat: route.coords.lat,
      long: route.coords.long,
    },
    description: route.description,
    displayTimestampOnPrintables: route.displayTimestampOnPrintables,
    displayFinishedTimestampOnPrintables: route.displayFinishedTimestampOnPrintables,
    type: route.type,
    geolocated: route.geolocated,
    arrivalDate: route.arrivalDate && route.arrivalDate ? toScalarShortDate(route.arrivalDate) : null,
    arrivalTime: route.arrivalTime ? toScalarTime(route.arrivalTime) : null,
    finishedDate: route.finishedDate && route.finishedDate ? toScalarShortDate(route.finishedDate) : null,
    finishedTime: route.finishedTime ? toScalarTime(route.finishedTime) : null,
  }
}

export const toALScalarTimestamp = (eta: DateTime) => {
  if (!eta.date) {
    eta.date = new Date()
  }

  return {
    date: eta.date && eta.date instanceof Date ? toScalarShortDate(eta.date) : null,
    time: eta.time ? toScalarTime(eta.time) : null,
  }
}

export { routeToMutationVariables as arrivalRouteToMutationVariables }
